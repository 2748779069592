import * as React from "react"
import Toggle from "react-toggle"
import * as styles from "./styles.scss"
import "react-toggle/style.css"
import * as cx from "classnames"

class Toggler extends React.PureComponent<TogglerProps> {
  render() {
    const { className,id, ...rest } = this.props

    return (
      <Toggle
        {...rest}
        className={cx(styles.toggler, className)}
        icons={false}
        id={id}
      />
    )
  }
}

interface TogglerProps {
  className?: string
  checked: boolean
  name?: string
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  id?: string
}

export default Toggler
