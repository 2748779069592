import * as React from "react"
import { WithTranslation, withTranslation } from "react-i18next"
import ReactTable from "react-table-6"
import * as styles from "./styles.scss"
import Status from "components/Status"
import { GetCaseFolders_findCaseFoldersByCurrentCustomer_caseFolders } from "schema"
import { notEmpty } from "utils"
import cx from "classnames"
import { withRouter, RouteComponentProps } from "react-router-dom"
import Warning from "components/Warning"
import { DateTime } from "luxon"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faClipboardCheck,
  faExternalLinkAlt,
  faLink,
} from "@fortawesome/free-solid-svg-icons"
const MEDIA_COLUMN_WIDTH = 350

class PackageTable extends React.PureComponent<
  PackageTableProps & WithTranslation & RouteComponentProps
> {
  static defaultProps = { showPackageHeaders: true }

  initializeTableData = () =>
    this.props.selectedCaseFolder.productPackages
      .filter(notEmpty)
      .reduce((acc, pack) => {
        const packageContent: ArchiveExpandedTableDataItem[] = pack.documents
          .filter(notEmpty)
          .reduce(
            (content, doc) =>
              content
                .concat([
                  {
                    type: "document",
                    name: doc.documentFormat.documentType.friendlyName,
                    internalId: doc.internalId.toString(),
                    id: doc.id!,
                  } as ArchiveExpandedTableDataItem,
                ])
                .concat(
                  doc.orders.filter(notEmpty).map((order) => {
                    const publishTime = order.mediaOrder!.publishTimes[0]
                    return {
                      id: order.id,
                      internalId: order.customerFriendlyId,
                      type: "order",
                      name: order.mediaOrder!.media.friendlyName,
                      documentFormat: order.mediaOrder!.documentFormat
                        .mediaAdaptedName,
                      publishTime: publishTime
                        ? DateTime.fromJSDate(new Date(publishTime.time))
                            .toFormat(
                              this.props.t("dateFormats:dateFullWithWeekNames")
                            )
                            .toString()
                        : undefined,
                      isTimeBookingWarning: order.mediaOrder!
                        .isCriticalTimeBookingWarning,
                      status: order.mediaOrder!.status,
                    } as ArchiveExpandedTableDataItem
                  })
                ),
            [] as ArchiveExpandedTableDataItem[]
          )
        return acc
          .concat([
            {
              id: pack.productPackage.id!,
              type: "package",
              name: pack.productPackage.name,
              internalId: "ID",
              documentFormat: "Format",
              publishTime: "Införande",
              status: "Status",
            },
          ])
          .concat(packageContent)
      }, [] as ArchiveExpandedTableDataItem[])

  redirectToEdit = (orderId: string) => {
    if (this.props.selectedCaseFolder.id) {
      this.props.onClickTouchCaseFolder(this.props.selectedCaseFolder.id)
    }
    this.props.history.push(
      `/editOrder/c/${this.props.selectedCaseFolder.id}/o/${orderId}`
    )
  }

  renderCell = (row: any) => {
    const { showPackageHeaders } = this.props
    const format = this.props.t("dateFormats:dateFullWithWeekNames")

    if (row.column.id === "name") {
      switch (row.original.type) {
        case "package":
          return <b className={styles.packageName}></b>
        case "document":
          return <b>{row.value}</b>
        default:
          return row.value
      }
    }

    if (row.original.type === "package" && showPackageHeaders)
      return <b>{row.value}</b>

    if (row.original.type === "package" && !showPackageHeaders) return null

    if (row.column.id === "publishTime" && row.original.isTimeBookingWarning)
      return (
        <Warning>
          {DateTime.fromJSDate(new Date(row.value)).toFormat(format).toString()}
        </Warning>
      )

    if (row.column.id === "status" && row.original.type === "order")
      return (
        <Status variant={row.value.color}>
          {this.props.t(`statuses:${row.value.status}`)}
        </Status>
      )

    return row.value ? row.value : null
  }

  handleSendToDashboard = () => {
    this.props.history.push(`/dashboard/c/${this.props.selectedCaseFolder.id}`)
  }

  render() {
    const { t, hideHeader, showPackageHeaders } = this.props
    const data = this.initializeTableData()

    const columns = [
      {
        Header: t("media"),
        accessor: "name",
        Cell: this.renderCell,
        width: MEDIA_COLUMN_WIDTH,
      },
      {
        Header: t("ID"),
        accessor: "internalId",
        Cell: this.renderCell,
      },
      {
        Header: t("format"),
        accessor: "documentFormat",
        Cell: this.renderCell,
      },
      {
        Header: t("common:publishDate"),
        accessor: "publishTime",
        Cell: this.renderCell,
      },
      {
        Header: t("status"),
        accessor: "status",
        Cell: this.renderCell,
      },
    ]

    return (
      <ReactTable
        columns={columns}
        className=""
        data={data}
        sortable={false}
        getTrProps={(state: any, rowInfo: any) => {
          let props: any = { className: styles.tableRow }

          if (rowInfo && rowInfo.original.type === "order") {
            props = {
              className: styles.tableRowOrder,
              onClick: () => this.redirectToEdit(rowInfo.original.id),
            }
          }

          return props
        }}
        getTbodyProps={() => ({
          className: cx({ [styles.margin]: showPackageHeaders }),
        })}
        getTheadProps={() => ({
          className: hideHeader ? styles.hidden : undefined,
        })}
        showPagination={false}
        noDataText={t("common:noResults")}
        NoDataComponent={(props) => {
          return (
            <div className={styles.noData} onClick={this.handleSendToDashboard}>
              {/* <p>{t("common:noResults")}</p> */}
              <span>
                <p>Gå till startsida</p>
                <FontAwesomeIcon icon={faExternalLinkAlt} />
              </span>
            </div>
          )
        }}
        pageSize={data.length}
      />
    )
  }
}

interface ArchiveExpandedTableDataItem {
  id: string
  internalId?: string
  type: "package" | "document" | "order"
  name: string
  documentFormat?: string
  publishTime?: string
  status?: any
  isTimeBookingWarning?: boolean
}

interface PackageTableProps {
  selectedCaseFolder: GetCaseFolders_findCaseFoldersByCurrentCustomer_caseFolders
  hideHeader?: boolean
  showPackageHeaders?: boolean
  onClickTouchCaseFolder: (caseFolderId: string) => void
}

export default withTranslation(["dashboard", "statuses", "common"])(
  withRouter(PackageTable)
)
