import * as React from "react"
import * as styles from "./styles.scss"
import cx from "classnames"

function ColumnIcon(props: ColumnIconProps) {
  function isActive(index: number) {
    const { columns, activeColumn } = props
    if (!activeColumn) return undefined

    if (columns === 1) return true

    switch (activeColumn) {
      case "left":
        return index === 0
      case "middle":
        return columns === 3 && index === 1
      case "right":
        return (columns === 2 && index === 1) || (columns === 3 && index === 2)
      default:
        return undefined
    }
  }

  function renderColumns() {
    let columns = []

    for (let i = 0; i < props.columns; i++)
      columns.push(
        <div
          key={i}
          className={cx(styles.column, { [styles.activeColumn]: isActive(i) })}
        />
      )

    return columns
  }

  return (
    <div
      onClick={props.onClick ? props.onClick : undefined}
      className={cx(styles.icon, props.className, {
        [styles.active]: props.isActive,
        [styles.hasActiveColumn]: props.activeColumn,
      })}
    >
      {renderColumns()}
    </div>
  )
}

interface ColumnIconProps {
  columns: number
  isActive?: boolean
  activeColumn?: "left" | "middle" | "right"
  className?: string
  onClick?: () => void
}

export default ColumnIcon
