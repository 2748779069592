import * as React from "react"
import Symbol from "../Symbol"
import {
  GetGalleryImages_galleryImagesByTags_images,
  GetGalleryImageInformation_galleryImages,
  AddOrRemoveTagsFromSymbols,
} from "schema"
import { Mutation } from "react-apollo"
import ADD_OR_REMOVE_TAGS_FROM_SYMBOLS from "graphql/mutations/addOrRemoveTagsFromSymbols"
import { AppContext } from "App"

class SymbolContainer extends React.PureComponent<SymbolContainerProps> {
  render() {
    const { selectedFavoriteTag, selectedSymbol, ...rest } = this.props

    return (
      <AppContext.Consumer>
        {({ currentUserSession }) => {
          
          const companyId = currentUserSession.customer!.office.company.id

          const handleFetchSymbolInfo = (preventSelect: boolean) => {
            this.props.onFetchSymbolInfo(
              this.props.symbol.id!,
              preventSelect,
              this.props.symbol
            )
          }

          const onClick = () => {
            handleFetchSymbolInfo(false)
          }

          return (
            <Mutation<AddOrRemoveTagsFromSymbols>
              mutation={ADD_OR_REMOVE_TAGS_FROM_SYMBOLS}
              variables={{
                input: {
                  tagGroupId: companyId,
                  imageIds: [this.props.symbol.id],
                  tags: [selectedFavoriteTag],
                  isRemove: true,
                },
                filterTagGroupId: companyId,
              }}
              onCompleted={() =>
                handleFetchSymbolInfo(
                  this.props.symbol.id !== selectedSymbol.id
                )
              }
            >
              {(deleteTag) => (
                <Symbol {...rest} onTagDelete={deleteTag} onClick={onClick} isSymbolGallery={this.props.isSymbolGallery} isFEUser={this.props.isFEUser!}/>
              )}
            </Mutation>
          )
        }}
      </AppContext.Consumer>
    )
  }
}

interface SymbolContainerProps {
  symbol: GetGalleryImages_galleryImagesByTags_images
  isActive: boolean
  isFavorite: boolean
  selectedFavoriteTag?: string
  selectedSymbol: GetGalleryImageInformation_galleryImages
  onSave: (id: string) => void
  onFetchSymbolInfo: (
    symbolId: string,
    preventSelect?: boolean,
    symbol?: any
  ) => void
  onOpenAddToFavoritesModal: (
    symbol: GetGalleryImages_galleryImagesByTags_images
  ) => void;
  isSymbolGallery: boolean;
  isFEUser?: boolean
}

export default SymbolContainer
