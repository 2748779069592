import * as React from "react"
import * as styles from "./styles.scss"
import Whiteframe from "components/Whiteframe"
import Order from "./Order"

function AllOrders() {
  return (
    <Whiteframe>
      <p className={styles.title}>Samtliga produkter:</p>
      <Order isActive={true} name="Media 1" id="1" />
    </Whiteframe>
  )
}

export default AllOrders
