import * as React from "react"
import HtmlEditorTaps from "components/HtmlEditorTaps"
import { EditModelAdvertAreaContentEditorBehaviours } from "schema"

export interface IEditorViewBaseOnUserAndFields {
  isSupportUser: boolean,
  isFields: boolean
}

const ColumnContainer: React.FC<{
  isValueDemo?: boolean
  inputValue: string
  pStyles: any
  rowItems?: any
  symbols?: any
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  blockStyles: any[]
  onSelectBlockStyle: (value: string | null) => void
  isVerseGalleryEnabled: boolean
  isExpanded?: boolean
  onEnterColumnEdit: () => void
  isEnabledMultiColumns: boolean | undefined
  isLocked: boolean
  editorBehaviour: EditModelAdvertAreaContentEditorBehaviours
  isFEUser: boolean
  editorViewBaseOnUserAndFields: IEditorViewBaseOnUserAndFields
  isXmlTextReadonly: boolean
}> = (props) => {
  const htmlEditorTapsRef: React.RefObject<HtmlEditorTaps> | any = React.useRef(
    null
  )

  React.useEffect(() => {
    if (props.isExpanded) {
      if (htmlEditorTapsRef.current)
        setTimeout(() => {
          const targetElement =
            htmlEditorTapsRef.current?.htmlEditorRef.current?.htmlEl
          if (targetElement) targetElement.focus()
        }, 300)
    }
  }, [props.isExpanded])

  const onSpecialCharacter = (e: any) => {
    e.preventDefault()
    if (htmlEditorTapsRef.current) {
      let targetElement: HTMLElement = e.target
      while (targetElement != null && targetElement?.getAttribute("data-insert") == null) {
        targetElement = targetElement?.parentElement as HTMLElement
      }
      if(targetElement) {
        const insertHtml = targetElement.getAttribute("data-insert") as string
        htmlEditorTapsRef.current.pasteContent(`${insertHtml}`, false)
      }
    }
  }

  const setVerseInExpandedArea = ({ textXml, author }: any) => {
    if (htmlEditorTapsRef.current && textXml) {
      let html = textXml
      if (author) html += `<p class="verseSignature">${author}</p>`
     return htmlEditorTapsRef.current.pasteContent(html, true)
    }
  }

  return (
    <HtmlEditorTaps
      ref={htmlEditorTapsRef}
      // countStepBack={props.countStepBack}
      isVerseGalleryEnabled={props.isVerseGalleryEnabled}
      value={props.inputValue}
      onChange={props.onChange}
      pStyles={props.pStyles}
      onOpenSpecialCharacters={onSpecialCharacter}
      symbols={props.symbols}
      isValueDemo={props.isValueDemo}
      rowItems={props.rowItems}
      blockStyles={props.blockStyles}
      onSelectBlockStyle={props.onSelectBlockStyle}
      isExpanded={props.isExpanded}
      setVerseInExpandedArea={setVerseInExpandedArea}
      onEnterColumnEdit={props.onEnterColumnEdit}
      isEnabledMultiColumns={props.isEnabledMultiColumns}
      isLocked={props.isLocked}
      editorBehaviour={props.editorBehaviour}
      isFEUser={props.isFEUser}
      editorViewBaseOnUserAndFields={props.editorViewBaseOnUserAndFields}
      isXmlTextReadonly={props.isXmlTextReadonly}
    />
  )
}

export default ColumnContainer
