import * as React from "react"
import * as styles from "./styles.scss"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const CloseIcon: React.FC<{
  onClick: (props?: any) => void
}> = (props) => {
  return (
    <div className={styles.closeContainer} onClick={props.onClick}>
      <FontAwesomeIcon icon="times" className={styles.closeIcon} />
    </div>
  )
}

export default CloseIcon
