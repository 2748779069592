import { cloneDeep } from "lodash"
import styles from "./export.scss"

const tempTheme = {}
Object.keys(styles).forEach((key) => {
  tempTheme[`--${key}`] = styles[key]
})

// console.log("tempTheme", tempTheme)

export const theme = cloneDeep(tempTheme)
