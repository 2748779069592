import gql from "graphql-tag"

const LOGIN = gql`
  mutation Login($userCredentialInput: UserCredentialInput!) {
    loginByCredential(credential: $userCredentialInput) {
      id
      isAuthenticated
      authorizationToken
      user {
        customers {
          id
        }
      }
    }
  }
`

export default LOGIN
