import React from "react"
import styles from "./styles.scss"
import {
  DeleteGalleryTextItem,
  DeleteGalleryTextItemVariables,
  SaveGalleryTextItem,
  SaveGalleryTextItemVariables,
  getGalleryTextGroupsByTags_galleryTextGroupsByTags_texts,
} from "schema"
import {
  faCheckCircle,
  faEdit,
  faTrash,
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import cx from "classnames"
import produce from "immer"
import { MutationFn, useMutation } from "react-apollo-hooks"
import DELETE_GALLERY_TEXT_ITEM from "graphql/mutations/deleteGalleryTextItem"
import { faBookmark } from "@fortawesome/free-regular-svg-icons"
import SAVE_GALLERY_TEXT_ITEM from "graphql/mutations/saveGalleryTextItem"
import Spinner from "components/Spinner"
import { IMessage } from ".."
import { useTranslation } from "react-i18next"
import { faBookmark as bookmarkSolid } from "@fortawesome/free-solid-svg-icons"
interface IText
  extends getGalleryTextGroupsByTags_galleryTextGroupsByTags_texts {
  isExpanded: boolean
}

const Verses: React.FC<{
  texts: getGalleryTextGroupsByTags_galleryTextGroupsByTags_texts[] | undefined
  cloneText: string
  versesRefetch: () => void
  setVerseInExpandedArea: any
  onPressClose: () => void
  templateAreaId: string | null
  documentFormatId: string | undefined
  mediaId: string | null | undefined
  message: IMessage
  setMessage: (arg: IMessage) => void
  filterTextInputTouched: boolean
  filterGalleryPrivate: boolean
  isValueDemo: boolean
  isFEUser: boolean
}> = (props) => {
  const [t] = useTranslation()
  const [texts, setTexts] = React.useState<IText[]>([])
  const [isTextSaved, setTextSaved] = React.useState<boolean>(false)
  const [isCloneExpanded, setCloneExpanded] = React.useState<boolean>(false)

  React.useEffect(() => {
    const newTexts =
      props.texts && props.texts != undefined
        ? props.texts.map((item) => ({
            ...item,
            isExpanded: false,
          }))
        : []

    setTexts([...newTexts])

    return () => setTexts([])
  }, [props.texts])
  const handleToggleExpanded = (id: string) => {
    const selectedIndex = texts.findIndex((item) => item.id === id)
    setTexts(
      produce(texts, (draft) => {
        draft.map((txt, index) => {
          if (index === selectedIndex) {
            draft[selectedIndex].isExpanded = !texts[selectedIndex].isExpanded
          } else {
            draft[index].isExpanded = false
          }
        })
      })
    )
  }

  const onPressSelectItem = (id: any, name: any, textXml: any, author: any) => {
    props.setVerseInExpandedArea({ id, name, textXml, author })
    setTimeout(() => props.onPressClose(), 300)
  }

  const handleSelectVerseByDoubleClick = (
    id: any,
    name: any,
    textXml: any,
    author: any
  ) => {
    props.setVerseInExpandedArea({ id, name, textXml, author })
    setTimeout(() => props.onPressClose(), 300)
  }

  const strippedCloneText = props.cloneText.replace(/<[^>]*>?/gm, "")

  const handleToggleCloneExpanded = () => setCloneExpanded(!isCloneExpanded)

  const mutateAddTextItemToGalley = useMutation<
    SaveGalleryTextItem,
    SaveGalleryTextItemVariables
  >(SAVE_GALLERY_TEXT_ITEM)
  return (
    <div
      className={cx(styles.versesWrapper, {
        [styles.FEView__versesWrapper]: props.isFEUser,
      })}
    >
      <div className={styles.verses}>
        {!props.filterTextInputTouched &&
          !isTextSaved &&
          strippedCloneText.length > 0 &&
          props.filterGalleryPrivate &&
          !props.isValueDemo && (
            <div
              className={cx(styles.cloneText, {
                [styles.expanded]: isCloneExpanded,
              })}
              onClick={handleToggleCloneExpanded}
            >
              <div className={styles.textWrapper}>
                <p dangerouslySetInnerHTML={{ __html: props.cloneText }} />
              </div>

              <div className={styles.addButtonWrapper}>
                <AddVerseButton
                  templateAreaId={props.templateAreaId}
                  documentFormatId={props.documentFormatId}
                  mediaId={props.mediaId}
                  editorTextValue={props.cloneText}
                  refetchVerses={props.versesRefetch}
                  mutateAddTextItemToGalley={mutateAddTextItemToGalley}
                  message={props.message}
                  setMessage={props.setMessage}
                  setTextSaved={setTextSaved}
                />
              </div>
            </div>
          )}

        {texts &&
          texts.map(
            (
              {
                name,
                id,
                textXml,
                author,
                tags,
                internalId,
                isEditable,
                isExpanded,
              },
              index
            ) => {
              return (
                <div
                  className={cx(styles.verseText, {
                    [styles.expanded]: isExpanded,
                  })}
                  onClick={() => handleToggleExpanded(id)}
                  key={index}
                >
                  {isEditable && (
                    <div className={styles.tools}>
                      <>
                        <RemoveVerseButton
                          itemId={id}
                          refetchVerses={props.versesRefetch}
                          message={props.message}
                          setMessage={props.setMessage}
                        />
                      </>
                    </div>
                  )}

                  <div className={styles.textWrapper}>
                    <p dangerouslySetInnerHTML={{ __html: textXml }} />
                  </div>
                  <div
                    className={cx(styles.verseBottom, {
                      [styles.isPrivateTextView]: props.filterGalleryPrivate,
                    })}
                  >
                    <div className={styles.textWrapper}>
                      <span className={styles.author}>{author}</span>
                      <div className={styles.alisAndId}>
                        {tags.map((t, i) =>
                          t.includes("@VERIFIED") ? (
                            <span className={styles.alis} key={i}>
                              ALIS
                            </span>
                          ) : null
                        )}
                        {!isEditable && (
                          <span className={styles.id}>Nr. {internalId}</span>
                        )}
                      </div>
                    </div>

                    <div className={styles.bottomAddButtonWrapper}>
                      <button
                        onClick={() =>
                          onPressSelectItem(id, name, textXml, author)
                        }
                        className={styles.addButton}
                      >
                        <FontAwesomeIcon icon={faCheckCircle} />
                        <span>{t("common:select")}</span>
                      </button>
                    </div>
                  </div>
                </div>
              )
            }
          )}
      </div>
    </div>
  )
}

const AddVerseButton: React.FC<{
  templateAreaId: string | null
  documentFormatId: string | undefined
  mediaId: string | null | undefined
  editorTextValue: string
  refetchVerses: () => void
  mutateAddTextItemToGalley: MutationFn<
    SaveGalleryTextItem,
    SaveGalleryTextItemVariables
  >
  message: IMessage
  setMessage: (arg: IMessage) => void
  setTextSaved: (arg: boolean) => void
}> = ({
  templateAreaId,
  documentFormatId,
  mediaId,
  editorTextValue,
  refetchVerses,
  mutateAddTextItemToGalley,
  message,
  setMessage,
  setTextSaved,
}) => {
  const [loading, setLoading] = React.useState<boolean>(false)
  const [t] = useTranslation()
  const [isButtonClicked, setButtonClicked] = React.useState<boolean>(false)

  const handleAddNewTextItem = (e: React.MouseEvent<HTMLElement>) => {
    // e.stopPropagation()
    setLoading(true)
    mutateAddTextItemToGalley({
      variables: {
        input: {
          galleryTextItemId: null,
          templateAreaId: templateAreaId,
          documentFormatId: documentFormatId,
          mediaId: mediaId,
          content: editorTextValue,
        },
      },
    }).then((res) => {
      if (res && res.data && res.data?.saveGalleryTextItem) {
        setButtonClicked(true)
        setLoading(false)
        refetchVerses()
        setMessage(
          produce(message, (draft) => {
            ;(draft.addItem = `${t("common:textIsSaved")}`),
              (draft.removeItem = null)
            draft.updateItem = null
          })
        )
        setTextSaved(true)
      }
    })
  }

  return (
    <>
      <button
        className={styles.button}
        onClick={handleAddNewTextItem}
        title={t("editOrder:clickToSave")}
      >
        {loading ? (
          <Spinner size={10} innerBallSize={3} />
        ) : (
          <FontAwesomeIcon
            icon={isButtonClicked ? bookmarkSolid : faBookmark}
          />
        )}

        <span>{t("common:saveTextButton")}</span>
      </button>
    </>
  )
}

const UpdateVerseButton: React.FC<{
  itemId: string | null
  refetchVerses: () => void
  mutateAddTextItemToGalley: MutationFn<
    SaveGalleryTextItem,
    SaveGalleryTextItemVariables
  >
  templateAreaId: string | null
  documentFormatId: string | undefined
  mediaId: string | null | undefined
  editorTextValue: string
  message: IMessage
  setMessage: (arg: IMessage) => void
  onPressClose: () => void
}> = ({
  templateAreaId,
  documentFormatId,
  mediaId,
  refetchVerses,
  mutateAddTextItemToGalley,
  editorTextValue,
  itemId,
  message,
  setMessage,
  onPressClose,
}) => {
  const [t] = useTranslation()
  const handleUpdatenewTextItem = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation()
    mutateAddTextItemToGalley({
      variables: {
        input: {
          galleryTextItemId: itemId,
          templateAreaId: templateAreaId,
          documentFormatId: documentFormatId,
          mediaId: mediaId,
          content: editorTextValue,
        },
      },
    }).then((res) => {
      if (res && res.data && res.data?.saveGalleryTextItem) {
        setMessage(
          produce(message, (draft) => {
            draft.removeItem = null
            draft.addItem = null
            draft.updateItem = `${t("common:textUpdateded")}`
          })
        )
      }
    })
    refetchVerses()
  }

  return (
    <button className={styles.button} onClick={handleUpdatenewTextItem}>
      <FontAwesomeIcon icon={faEdit} />
    </button>
  )
}

const RemoveVerseButton: React.FC<{
  itemId: string | null
  refetchVerses: () => void
  message: IMessage
  setMessage: (arg: IMessage) => void
}> = ({ itemId, refetchVerses, message, setMessage }) => {
  const [loading, setLoading] = React.useState(false)
  const [t] = useTranslation()
  const mutateDeleteGalleyTextItem = useMutation<
    DeleteGalleryTextItem,
    DeleteGalleryTextItemVariables
  >(DELETE_GALLERY_TEXT_ITEM)

  const handleClickVersItem = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation()
    if (itemId === null) return
    setLoading(true)
    mutateDeleteGalleyTextItem({
      variables: {
        id: itemId,
      },
    }).then((res) => {
      if (res && res.data && res.data?.deleteGalleryTextItem) {
        setMessage(
          produce(message, (draft) => {
            ;(draft.removeItem = `${t("common:textRemoved")}`),
              (draft.addItem = null)
            draft.updateItem = null
          })
        )
      }
      setLoading(false)
    })
    refetchVerses()
  }
  return (
    <button
      className={styles.button}
      onClick={handleClickVersItem}
      title={t("common:delete")}
    >
      {loading ? (
        <Spinner size={10} innerBallSize={3} />
      ) : (
        <FontAwesomeIcon icon={faTrash} />
      )}
    </button>
  )
}

export default Verses
