import gql from "graphql-tag"

const UPDATE_ORDER_PRICE = gql`
  mutation UpdateOrderPrice($input: MutationUpdateOrderPriceInput!) {
    updateOrderPrice(input: $input) {
      order {
        id
        mediaOrder {
          preview {
            price(
              withVAT: true
              withRounding: STANDARD
              priceView: WITH_SERVICE_ORDER
            ) {
              currency
              customer {
                priceContract
                priceContractOverride
              }
              endCustomer {
                pricePayRounded
              }
            }
          }
        }
      }
    }
  }
`

export default UPDATE_ORDER_PRICE
