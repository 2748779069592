import * as React from "react"
import { Query } from "react-apollo"
import Spinner from "components/Spinner"
import GET_ALL_NEWS from "graphql/queries/getAllNews"
import ErrorMessage from "components/ErrorMessage"
import { GetAllNews } from "schema"
import { notEmpty } from "utils"
import { useApolloClient, useQuery } from "react-apollo-hooks"

const NewsProvider: React.FC<{
  render: (props: NewsItem[]) => React.ReactNode
  onChange?: (news: NewsItem[]) => void
}> = (props) => {
  const client = useApolloClient()
  const { loading, data, error } = useQuery<GetAllNews>(GET_ALL_NEWS, {
    client,
  })

  React.useEffect(() => {
    if (props.onChange) {
      if (
        data === undefined ||
        data === null ||
        data.news === undefined ||
        data.news === null
      ) {
        props.onChange([])
      } else {
        props.onChange(
          data.news.filter(notEmpty).map((x) => ({
            id: x.id,
            text: x.text,
            summary: x.summary,
            title: x.header,
            timeFrom: new Date(x.timeFrom),
            isPopup: x.isPopup,
            isNewslist: x.isNewslist,
            isFullContent: x.isFullContent,
            timeTo: new Date(x.timeTo),
          }))
        )
      }
    }
  }, [data])

  if (loading) {
    return <Spinner />
  }

  if (error) {
    return <ErrorMessage message={error.message} />
  }

  if (!data) {
    return <Spinner />
  }

  const news = data.news.filter(notEmpty).map((x) => ({
    id: x.id,
    text: x.text,
    summary: x.summary,
    title: x.header,
    timeFrom: new Date(x.timeFrom),
    isPopup: x.isPopup,
    isNewslist: x.isNewslist,
    isFullContent: x.isFullContent,
    timeTo: new Date(x.timeTo), 
  }))

  return <>{props.render(news)}</>
}

export interface NewsItem {
  id: number
  text: string
  summary: string
  isPopup: boolean
  isNewslist: boolean
  timeTo: Date
  title: string
  timeFrom: Date
  isFullContent: boolean
}

export default NewsProvider
