import gql from "graphql-tag"

const DELETE_CASE_FOLDER = gql`
  mutation DeleteCaseFolder($input: MutationDeleteCaseFolderInput!) {
    deleteCaseFolder(input: $input) {
      isError
      errorReason
    }
  }
`

export default DELETE_CASE_FOLDER
