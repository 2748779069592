import * as React from "react"
import * as styles from "./styles.scss"
import { useTranslation } from "react-i18next"
import { GetPackages_orderInitiatorPackagesByCurrentUser } from "schema"
import { blurOnEnter } from "utils"

function CommonPrice(props: CommonPriceProps) {
  const [t] = useTranslation("agencySettings")
  const price = props.package.commonPrice
  const notSpecified = t("common:notSpecified")

  const updatePackageCommonPrice = (e: React.FocusEvent) => {
    const value = e.target.innerHTML
    const commonPrice = value === "" ? NaN : Number(value)

    if (commonPrice === price) return

    if ((value === "" || value === notSpecified) && price === null)
      return (e.target.innerHTML = notSpecified)

    if (value !== "" && isNaN(commonPrice) && price !== null)
      return (e.target.innerHTML = price.toString())

    if (isNaN(commonPrice) && price === null)
      return (e.target.innerHTML = notSpecified)

    return props.onUpdatePackage({
      ...props.package,
      commonPrice: isNaN(commonPrice) ? null : commonPrice,
    })
  }

  return (
    <div className={styles.price}>
      <p>
        {t("commonPrice")}:
        <span>
          <span
            contentEditable={true}
            suppressContentEditableWarning={true}
            onKeyDown={blurOnEnter}
            onBlur={updatePackageCommonPrice}
            className={props.isBlur ? styles.blur  : ""}
          >
            {price !== null ? price : notSpecified}
          </span>
          {price !== null && <span> kr</span>}
        </span>
      </p>
    </div>
  )
}

interface CommonPriceProps {
  package: GetPackages_orderInitiatorPackagesByCurrentUser
  onUpdatePackage: (packageInput: any) => Promise<any>
  isBlur?: boolean
}

export default CommonPrice
