import * as React from "react"
import Modal from "components/Modal"
import { createGlobalStyle } from "styled-components"
import { useHistory, useLocation } from "react-router"
import "@brainhubeu/react-carousel/lib/style.css"
import { ModalContent } from "./ModalContent"
import { SelectOrderInitiatorView_selectOrderInitiatorView_orderInitiatorId } from "schema"
import queryString from "query-string"
import cx from "classnames"
import useWindowSize from "useHook/useWindowSize"
import { AppContext } from "App"

export const SEARCH_KEY_VALUE_GROUP = "add_product_group"
export const SEARCH_KEY_VALUE_PRODUCT = "add_product_casefolder"
export const SEARCH_KEY = "action"
export const SELECT_PRODUCT = "/selectProduct"

export const AddProductGroupOrderModal: React.FC<{
  caseFolderId?: string
  documentId?: any
  orderId?: string
  onSelectOrderInitiatorView?: (
    value: SelectOrderInitiatorView_selectOrderInitiatorView_orderInitiatorId
  ) => void
}> = (props) => {
  const window_dimensions = useWindowSize()
  const history = useHistory()
  const location = useLocation()
  const variableActionValue = queryString.parse(location.search).action
  const isSelectProductPath = location.pathname.includes(SELECT_PRODUCT)
  const isFEViewEnabled = isSelectProductPath 

  const params = new URLSearchParams(location.search)
  const action = params.get(SEARCH_KEY)
  const defaultshowModal =
    action !== undefined &&
    action === (SEARCH_KEY_VALUE_GROUP || SEARCH_KEY_VALUE_PRODUCT)

  const [showModal, setShowModal] = React.useState<boolean>(defaultshowModal)
  const onCloseModal = (
    event: React.MouseEvent<Element, MouseEvent>,
    reloadPage: boolean = false,
    redirectUrl: string | undefined = undefined
  ) => {
    setShowModal(false)
    const urlParams = new URLSearchParams(location.search)
    urlParams.delete(SEARCH_KEY)
    const newSearch = urlParams.toString()
    history.push({
      search: newSearch,
    })
    if (reloadPage) {
      if (redirectUrl !== undefined) {
        window.location.href = redirectUrl
      } else {
        window.location.reload()
      }
    }
  }

  React.useEffect(() => {
    const _params = new URLSearchParams(location.search)
    const _action = _params.get(SEARCH_KEY)
    const _newShowModal =
      (_action !== undefined && _action === SEARCH_KEY_VALUE_GROUP) ||
      _action === SEARCH_KEY_VALUE_PRODUCT
    setShowModal(_newShowModal)
  }, [location.search])

  if (showModal === false) {
    return null
  }

  const OptionsContainer = () => {
    return (
      <React.Fragment>
        <GlobalModalStyle />
        <ModalContent
          caseFolderId={props.caseFolderId}
          documentId={
            params.get(SEARCH_KEY) === SEARCH_KEY_VALUE_PRODUCT
              ? null
              : props.documentId
          }
          orderId={props.orderId}
          shouldCreateOrder={true}
          onCloseModal={onCloseModal}
          onSelectOrderInitiatorView={props.onSelectOrderInitiatorView}
          isFEViewEnabled={isFEViewEnabled!}
        />
      </React.Fragment>
    )
  }

  return isFEViewEnabled ? (
    <div className={"adstateView"}>{OptionsContainer()}</div>
  ) : (
    <Modal
      onRequestClose={onCloseModal}
      isOpen={showModal}
      isLoading={false}
      isModalInModal={false}
      className={cx("add_product_group_modal", {
        ["add-product-modal"]:
          variableActionValue === "add_product_group" &&
          window_dimensions.height <= 700,
      })}
    >
      <GlobalModalStyle />
      <ModalContent
        caseFolderId={props.caseFolderId}
        documentId={
          params.get(SEARCH_KEY) === SEARCH_KEY_VALUE_PRODUCT
            ? null
            : props.documentId
        }
        orderId={props.orderId}
        shouldCreateOrder={true}
        onCloseModal={onCloseModal}
        onSelectOrderInitiatorView={props.onSelectOrderInitiatorView}
      />
    </Modal>
  )
}

const GlobalModalStyle = createGlobalStyle`
  .add_product_group_modal {
    overflow: visible;
    min-height: 450px;
    max-width: 75vw;
    top: -90px !important;
    &.add-product-modal {
      overflow-y: auto;
    }
  }
  .adstateView {
    width: 100%;
    height: 100%;
    margin: 0 auto;
    max-height: 80vh;
    .wrapper {
      padding: 10px;
    }
    .layout-IMAGE_TEXT_THUMBNAIL__AdstateView {
      height: 100% !important;
      max-height: none !important;
    }
   .layout-IMAGE_TEXT_THUMBNAIL {
      max-height: none !important;
   }
  }
`
