import * as React from "react"
import * as styles from "./styles.scss"
import cx from "classnames"
import { OrderPreviewRectangle } from "../../.."

class Rectangle extends React.Component<RectangleProps> {
  shouldComponentUpdate = (nextProps: RectangleProps) => {
    const { isHovered, rectangle } = this.props

    return (
      nextProps.isHovered !== isHovered ||
      nextProps.rectangle.id !== rectangle.id ||
      nextProps.rectangle.h !== rectangle.h ||
      nextProps.rectangle.w !== rectangle.w ||
      nextProps.rectangle.x !== rectangle.x ||
      nextProps.rectangle.y !== rectangle.y
    )
  }

  handleClick = () => this.props.onClick(this.props.rectangle.id)

  render() {
    const { rectangle, isHovered } = this.props

    return (
      <div
        className={cx(styles.rectangle, {
          [styles.hovered]: isHovered,
          [styles.feView__rectangle]: this.props.isFEUser,
        })}
        onClick={this.handleClick}
        style={{
          left: rectangle.x,
          width: rectangle.w,
          top: rectangle.y,
          height: rectangle.h,
        }}
      />
    )
  }
}

interface RectangleProps {
  rectangle: OrderPreviewRectangle
  isHovered: boolean
  onClick: (id: string) => void
  isFEUser: boolean
}

export default Rectangle
