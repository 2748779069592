import gql from "graphql-tag"

const GET_ARCHIVE_CASE_FOLDERS = gql`
  query GetArchiveCaseFolders(
    $scope: CaseFolderCustomerScope
    $filterByOrderStatus: OrderStatus
    $textFilter: String
    $startindex: Int!
    $pageLength: Int!
  ) {
    findCaseFoldersByCurrentCustomer(
      scope: $scope
      textFilter: $textFilter
      filterByOrderStatus: $filterByOrderStatus
      startindex: $startindex
      limit: $pageLength
    ) {
      count
      startIndex
      caseFolders {
        id
        name
        internalId

        propertiesFuneralStandard {
          externalId
        }

        customer {
          id
          name
        }

        productPackages {
          productPackage {
            id
            name
          }
          documents {
            id
            internalId
            documentFormat {
              id
              documentType {
                id
                friendlyName
              }
            }
            orders {
              id
              customerFriendlyId
              alerting {
                smallText
                text
                type
              }
              mediaOrder {
                media {
                  id
                  friendlyName
                }
                documentFormat {
                  id
                  mediaAdaptedName: friendlyName(isShownWithMedia: true)
                }
                publishTimes {
                  time
                }
                status {
                  color
                  status
                }
              }
            }
          }
        }
        summary {
          countOrders
          mostCriticalStatus
          mostCriticalColor
          isMostCriticalTimeBookingWarning
        }
      }
    }
  }
`

export default GET_ARCHIVE_CASE_FOLDERS
