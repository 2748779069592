import * as React from "react"
import LoginPage from "pages/LoginPage"
import { Switch, Redirect, Route } from "react-router-dom"
import LiveView from "pages/LiveView"
import AutoLoginTempSessionToken from "pages/LoginPage/AutoLogin/autoLoginTempSessionToken"

export const UnathorizedRoutes = () => {
  return (
    <Switch>
      <Route path="/login" component={LoginPage} />
      <Route path="/live/:liveViewId" component={LiveView} />
      <Route
        path="/autologintempsessiontoken/:token"
        component={AutoLoginTempSessionToken}
      />
      <Redirect to="/login" />
    </Switch>
  )
}
