import * as React from "react"
import Button from "components/Button"
import { DashboardContext } from "pages/DashboardPage"
import { withTranslation, WithTranslation } from "react-i18next"
import HeaderRow from "components/HeaderRow"

class PackageTableHeaderRow extends React.Component<WithTranslation & Iprops> {
  render() {
    return (
      <DashboardContext.Consumer>
        {({ selectedCaseFolder, enterEditMode }) => (
          <HeaderRow
            funeralDetails={this.props.isShowFuneralDetails ? {personBorn: selectedCaseFolder.propertiesFuneralStandard.personBorn, personDied: selectedCaseFolder.propertiesFuneralStandard.personDied} : null}
            title={selectedCaseFolder.name}
            renderActionButton={() => (
              <Button onClick={enterEditMode}>
                {this.props.t("editCaseFolder")}
              </Button>
            )}
          />
        )}
      </DashboardContext.Consumer>
    )
  }
}

interface Iprops {
  isShowFuneralDetails?: boolean
}

export default withTranslation("dashboard")(PackageTableHeaderRow)
