import React from "react"
import * as styles from "./styles.scss"
import Modal from "components/Modal"
import { ImageSelectDialogFile } from "customSchema.interface"
import produce from "immer"
import cx from "classnames"
import ImagePreview from "./ImagePreview"
import ImageEditor from "./ImageEditor"
import Spinner from "components/Spinner"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router"
import { GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_image_adjustment } from "schema"

export interface Istate {
  image_color: number
  rotate: number
  scale: number
  saturation: number
  lightness: number
  isEditMode: boolean
  imageHeight: number | null
  transparent: number
  isOriginal_image: boolean
  contrast: number
  orderId?: string
  visibleEditor_width: number
  visibleEditor_height: number
}

export interface IState_Avatar {
  width: number
  height: number
}

enum TemplateName {
  Toning = "Toning",
}

const ImageModificationDialog: React.FC<{
  isOpen: boolean
  onClose: () => void
  imageDetails: ImageSelectDialogFile | null
  onSave?: any
  errorMsg?: string | null
  onSymbolUploadHeight: (value: any, callback?: () => void) => void
  onOriginalImageUpload: (
    data: any,
    filename?: string,
    comment?: string,
    callback?: () => void
  ) => void
  maskImageUrl: string | undefined | null
  isOrderImage: boolean
  loading: boolean
  documentCollectionName: string | undefined
  isEditButtonClicked: boolean
  selectedOrderId: string
  adjustmentValues:
    | GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_image_adjustment
    | null
    | undefined;
  isStandardAdvert: boolean
  isFEUser: boolean

}> = (props) => {
  const { orderId } = useParams<{ orderId: string }>()
  const [t] = useTranslation(["editOrder"])
  const { isOpen, onClose, imageDetails } = props
  //const isToningAdvert = props.documentCollectionName!
  const [avatarSize, setAvatarSize] = React.useState<IState_Avatar>({
    width: 380,
    height: 400,
  })
  const [comment, setComment] = React.useState<string>("")

  const [state, setState] = React.useState<Istate>({
    image_color: 1,
    saturation: 40,
    lightness: 1,
    rotate: 0,
    scale: 1,
    isEditMode: props.isOrderImage,
    imageHeight: null,
    transparent: 1,
    isOriginal_image: true,
    contrast: 0,
    visibleEditor_width: 400,
    visibleEditor_height: 400,
  })

  const editorRef = React.useRef<any>(null)

  const canvas: any = editorRef.current && editorRef.current

  React.useEffect(() => {
    Object.keys(sessionStorage).forEach((key) => {
      if (key.includes(`${orderId}@editorValues`)) {
        const strippedKey = key.split("@")[0]
        const editorValue: Istate = JSON.parse(sessionStorage.getItem(key)!)

        if (
          editorValue &&
          props.isEditButtonClicked &&
          props.selectedOrderId === strippedKey
        ) {
          setState(
            produce(state, (draft) => {
              draft.contrast = editorValue.contrast
              draft.lightness = editorValue.lightness
              draft.scale = editorValue.scale
              draft.rotate = editorValue.rotate
            })
          )
        }
      }
    })
  }, [])

  const handleScale = (value: number) => {
    setState(
      produce(state, (draft) => {
        draft.scale = value
      })
    )
  }

  const handleRotate = (direction: string) => {
    if (direction === "right") {
      setState(
        produce(state, (draft) => {
          draft.rotate = state.rotate + 90
        })
      )
    }
    if (direction === "left") {
      setState(
        produce(state, (draft) => {
          draft.rotate = state.rotate - 90
        })
      )
    }
  }

  const handleFlip = () => {}

  const handleImageColor = (value: number) => {
    setState(
      produce(state, (draft) => {
        draft.image_color = value
      })
    )
  }

  const handleSaturation = (value: number) => {
    setState(
      produce(state, (draft) => {
        draft.saturation = value
      })
    )
  }

  const handleLightness = (value: number) => {
    setState(
      produce(state, (draft) => {
        draft.lightness = value
      })
    )
  }

  const handleContrast = (value: number) => {
    setState(
      produce(state, (draft) => {
        draft.contrast = value
      })
    )
  }

  const handleScaleByRange = (value: number) => {
    setState(
      produce(state, (draft) => {
        draft.rotate = value
      })
    )
  }

  const handleReset = () => {
    setState(
      produce(state, (draft) => {
        draft.rotate = 0
        draft.lightness = 0
        draft.contrast = 0
        draft.scale = 1
      })
    )
  }

  const storageData_LS = () => {
    const value = produce(state, (draft) => {
      draft.contrast = state.contrast
      draft.rotate = state.rotate
      draft.scale = state.scale
      draft.lightness = state.lightness
      draft.orderId = orderId
    })

    sessionStorage.setItem(
      `${orderId}@editorValues`,
      JSON.stringify(value, null, 2)
    )
  }

  const onSaveCanvasImage = () => {
    storageData_LS()
    if (state.isEditMode) {
      if (canvas !== null) {
        canvas.getImageScaledToCanvas().toBlob((blob: any) => {
          if (props.onSave) {
            onSaveOriginalImage()
            props.onSave(blob)
          }
        })
      }
    }
  }

  const resizeOriginalImage = (imageSrc: any) => {
    return new Promise((resolve, reject) => {
      const WIDTH_LIMIT = 1000
      const img = new Image() as HTMLImageElement
      img.src = imageSrc
      img.crossOrigin = "Anonymous"
      img.onload = () => {
        const canvas = document.createElement("canvas")
        const ctx = canvas.getContext("2d")
        canvas.width = img.naturalWidth <=  WIDTH_LIMIT  ? img.naturalWidth : WIDTH_LIMIT
        canvas.height =  canvas.width *  (img.naturalHeight / img.naturalWidth)
        ctx?.drawImage(img, 0, 0, canvas.width, canvas.height)
        const resizedImageDataURL  = canvas.toDataURL()
        resolve(resizedImageDataURL)
      }
      //reject("Field to resize image")
    })
  }

  const onSaveOriginalImage = async () => {
  const imgSrc:any = await resizeOriginalImage(imageDetails?.preview)
    .then((resizedImageDataURL) => resizedImageDataURL)
    .catch((err) => console.log(err))

    const blob: Blob | any = await fetch(imgSrc)
      .then((r) => r.blob())
      .catch((err) => console.log("blob", err))

    const reader = new FileReader()
    reader.onloadend = () => {
      const base64data = reader.result?.toString() ?? undefined
      if (!base64data) return;
      props.onOriginalImageUpload(base64data, props.imageDetails?.name, comment)
    }
    reader.readAsDataURL(blob)
  }

  const handleChangeImageHeight = (e: any) => {
    const heightValue = Number(e.target.value)
    setState(
      produce(state, (draft) => {
        draft.imageHeight = heightValue ? heightValue : null
      })
    )
  }

  // const handleSwitchEditMode = () => {
  //   setState(
  //     produce(state, (draft) => {
  //       draft.isEditMode = !state.isEditMode
  //     })
  //   )
  // }

  const handleToggleToOriginal_image = () => {
    setState(
      produce(state, (draft) => {
        draft.isOriginal_image = !state.isOriginal_image
      })
    )
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      large={true}
      className={cx(styles.imageEditorModal, {
        [styles.largeModal]: state.isEditMode,
        [styles.extraLargeModal]: state.isEditMode && avatarSize.height > 500,
        [styles.feView__imageEditorModal]:props.isFEUser
      })}
      isFEUser={props.isFEUser}
    >
      <div className={styles.container}>
        <div className={styles.header}>
          {state.isEditMode ? (
            <span>{t("editSymbol")}</span>
          ) : (
            <span>{t("uploadSymbol")}</span>
          )}
        </div>

        <div className={cx(styles.content,{
          [styles.feView__content]:props.isFEUser
        })}>
          {props.loading && (
            <div
              style={{
                position: "absolute",
                left: "50%",
                top: "50%",
                transform: "translate(-50%, -50%)",
                backgroundColor: "#f0f0f052",
                width: "150px",
                height: "150px",
                borderRadius: "8px",
              }}
            >
              <Spinner />
            </div>
          )}

          <ImageEditor
            state={state}
            handleImageColor={handleImageColor}
            handleSaturation={handleSaturation}
            handleScaleByRange={handleScaleByRange}
            handleScale={handleScale}
            imageDetails={imageDetails}
            editorRef={editorRef}
            handleRotate={handleRotate}
            handleFlip={handleFlip}
            handleToggleToOriginal_image={handleToggleToOriginal_image}
            handleReset={handleReset}
            onSaveCanvasImage={onSaveCanvasImage}
            canvas={canvas}
            mask={props.maskImageUrl}
            handleLightness={handleLightness}
            handleContrast={handleContrast}
            avatarSize={avatarSize}
            setAvatarSize={setAvatarSize}
            adjustmentValues={props.adjustmentValues}
            comment={comment}
            setComment={setComment}
            isStandardAdvert={props.isStandardAdvert}
            isFEUser={props.isFEUser}
          />

          <ImagePreview
            state={state}
            imageDetails={imageDetails}
            handleChangeImageHeight={handleChangeImageHeight}
            onSaveOriginalImage={onSaveOriginalImage}
            canvas={canvas}
          />
        </div>

        <div className={styles.modalFooter}>
          {/* {props.isOrderImage && (
            <CheckboxField
              label={t("editMode")}
              checked={state.isEditMode}
              onChange={handleSwitchEditMode}
            />
          )} */}
          {props.errorMsg && (
            <div className={styles.errorWrapper}>
              <span>{props.errorMsg}</span>
            </div>
          )}
        </div>
      </div>
    </Modal>
  )
}

export default ImageModificationDialog
