import * as React from "react"
import * as styles from "./styles.scss"
import Downshift from "downshift"
import Button from "components/Button"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import cx from "classnames"

class Dropdown extends React.PureComponent<DropdownProps> {
  static defaultProps = {
    renderToggler: () => (
      <Button variant="primary" color="primary">
        <FontAwesomeIcon icon="bars" />
      </Button>
    ),
  }

  buttonContainer: React.RefObject<HTMLDivElement> = React.createRef()

  getContentStyles = () => {
    const { direction } = this.props
    const togglerHeight = this.buttonContainer.current!.clientHeight

    return direction === "up" ? { bottom: togglerHeight + 1 } : undefined
  }

  render() {
    const {
      children,
      renderToggler,
      contentClassName,
      dropdownClassName,
      closeAfterClick = true,
      render,
    } = this.props

    return (
      <Downshift>
        {({ getToggleButtonProps, isOpen, setState }) => {
          const close = () => setState({ isOpen: false })
          const toggle = () => setState({ isOpen: !isOpen })

          return (
            <div className={cx(styles.dropdown, dropdownClassName)}>
              <div
                {...getToggleButtonProps()}
                className={styles.buttonContainer}
                ref={this.buttonContainer}
              >
                {renderToggler({ isOpen, onClick: toggle })}
              </div>
              {isOpen && (
                <div
                  className={cx(styles.content, contentClassName)}
                  onClick={closeAfterClick ? close : undefined}
                  style={this.getContentStyles()}
                >
                  {render ? render({ closeMenu: close }) : children}
                </div>
              )}
            </div>
          )
        }}
      </Downshift>
    )
  }
}

interface DropdownProps {
  renderToggler: (props: RenderTogglerProps) => JSX.Element
  contentClassName?: string
  dropdownClassName?: string
  direction?: DropdownDirections
  closeAfterClick?: boolean
  render?: (props: RenderProps) => any
}

export interface RenderTogglerProps {
  isOpen: boolean
  onClick?: any
}

interface RenderProps {
  closeMenu: () => void
}

export default Dropdown
