import * as React from "react"
import * as styles from "./styles.scss"
import cx from "classnames"
import { AvailableViews } from "../.."
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { IconProp } from "@fortawesome/fontawesome-svg-core"

function ViewItem(props: ViewItemProps) {
  const handleClick = () => props.onChangeCurrentView(props.view)

  return (
    <div
      onClick={handleClick}
      className={cx(styles.item, {
        [styles.selected]: props.currentView === props.view,
      })}
    >
      <FontAwesomeIcon icon={props.icon} /> <span>{props.label}</span>
    </div>
  )
}

interface ViewItemProps {
  view: AvailableViews
  currentView: AvailableViews
  icon: IconProp
  label: string
  onChangeCurrentView: (view: AvailableViews) => void
}

export default ViewItem
