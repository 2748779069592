import * as React from "react"
import AdminMargin from "../AdminMargin"
import AdminSection from "../AdminSection"
import * as styles from "./styles.scss"
import Button from "components/Button"
import { FontSizeButtons } from "./FontSizeButtons"
import FontFamilyDropdown from "./FontFamilyDropdown"
import LineHeightDropdown from "./LineHeightDropdown"
import LetterSpacingInput from "./LetterSpacingInput"
import ScaleInput from "./ScaleInput"
import { OrderEditorAreaContext } from ".."
import { useTranslation } from "react-i18next"
import { AppContext } from "App"
import { EditModelAdvertAreaContentEditorTypes, FontWeights } from "schema"
import BlockMeasurement from "./BlockMeasurement"
import LineHeightEmpty from "./LineHeightEmpty"
import FontStyleDropdown from "./FontStyleDropdown"
import FontWeightDropdown from "./FontWeightDropdown"

export const HtmlInputAdminSection: React.FC<{
  isExpanded: boolean
  type?: EditModelAdvertAreaContentEditorTypes
}> = (props) => {
  const [t] = useTranslation("editOrder")

  return (
    <AppContext.Consumer>
      {({ currentUserSession }) => {
        const canAccesAdmin =
          currentUserSession?.user?.isSupportUser === true ?? false

        if (canAccesAdmin === false) {
          return null
        }

        return (
          <OrderEditorAreaContext.Consumer>
            {({ contentRows, toggleNoWrap, toggleIsTextEditorReadOnly }) => {
              let isLocked = false
              let isXmlTextReadonly: boolean
              let isFields: boolean
              // SIC! @TODO: convert to array optional
              if (
                contentRows &&
                contentRows[0] &&
                contentRows[0].columns &&
                contentRows[0].columns[0] &&
                contentRows[0].columns[0].item &&
                contentRows[0].columns[0].item.text &&
                contentRows[0].columns[0].item.text.textNoWrap !== undefined &&
                contentRows[0].columns[0].item.text.textNoWrap === true
              ) {
                isLocked = true
              }
              if (
                contentRows &&
                contentRows[0] &&
                contentRows[0].columns &&
                contentRows[0].columns[0] &&
                contentRows[0].columns[0].item &&
                contentRows[0].columns[0].item.text &&
                contentRows[0].columns[0].item.text.isXmlTextReadonly !==
                  undefined &&
                contentRows[0].columns[0].item.text.isXmlTextReadonly === true
              ) {
                isXmlTextReadonly = true
              }
              if (
                contentRows &&
                contentRows[0] &&
                contentRows[0].columns &&
                contentRows[0].columns[0] &&
                contentRows[0].columns[0].item &&
                contentRows[0].columns[0].item.fields &&
                contentRows[0].columns[0].item.fields !== undefined
              ) {
                isFields =
                  contentRows[0].columns[0].item.fields.length > 0 &&
                  contentRows[0].columns[0].item.fields != null
              }
              const {
                CUSTOM,
                GALLERY_IMAGE,
                NONE,
                SEPARATOR,
                TEXT_HTML,
              } = EditModelAdvertAreaContentEditorTypes

              let fontFamilyVisible: boolean = true
              let textEditSectionVisible: boolean = true
              if (props.type !== undefined) {
                if (props.type === SEPARATOR) {
                  fontFamilyVisible = false
                  textEditSectionVisible = false
                }
              }

              return (
                <AdminSection isExpanded={props.isExpanded}>
                  <div className={styles.row1}>
                    {fontFamilyVisible === true ? (
                      <FontFamilyDropdown />
                    ) : (
                      <React.Fragment />
                    )}

                    <Button
                      variant="tertiary"
                      icon={isLocked ? "lock" : "unlock"}
                      className={styles.lockRowsButton}
                      onClick={toggleNoWrap}
                    >
                      {isLocked
                        ? t("editOrder:unlockRows")
                        : t("editOrder:lockRows")}
                    </Button>
                    {isFields! && (
                      <Button
                        className={styles.lockRowsButton}
                        // className={styles.show_hide_button}
                        variant={"tertiary"}
                        onClick={() => {
                          toggleIsTextEditorReadOnly(!isXmlTextReadonly)
                        }}
                      >
                        {isXmlTextReadonly!
                          ? t("common:show") + " editor"
                          : t("common:hide") + " editor"}
                      </Button>
                    )}
                  </div>

                  {textEditSectionVisible ? (
                    <React.Fragment>
                      <div className={styles.row2}>
                        <FontSizeButtons />
                        <div className={styles.lineHeightWrapper}>
                          <LineHeightDropdown />
                          <LineHeightEmpty />
                        </div>

                        <LetterSpacingInput />

                        <ScaleInput />
                        <FontStyleDropdown />
                        <FontWeightDropdown />
                      </div>
                      <hr className={styles.divider} />
                    </React.Fragment>
                  ) : (
                    <React.Fragment />
                  )}

                  <AdminMargin />
                  <hr className={styles.divider} />
                  <BlockMeasurement />
                </AdminSection>
              )
            }}
          </OrderEditorAreaContext.Consumer>
        )
      }}
    </AppContext.Consumer>
  )
}
