import React from "react"
import styles from "./styles.scss"
import BackgroundImage from "components/BackgroundImage"
import MainLayout from "layouts/MainLayout"
import bg from "images/image.png"
import GET_SYSTEM_INFO from "graphql/queries/getSystemInfo"
import { useQuery } from "react-apollo-hooks"
import { getSystemInfo } from "schema"
import Spinner from "components/Spinner"
import ErrorMessage from "components/ErrorMessage"
import moment from "moment"

const AboutTapsPage: React.FC = () => {
  const { loading, data, error } = useQuery<getSystemInfo>(GET_SYSTEM_INFO)

  if (error) {
    return <ErrorMessage message={error.message} />
  }

  const buildTime =
    data && data.systemInfo && data.systemInfo
      ? moment.unix(Number(data?.systemInfo?.build?.time)).format("HH:mm:ss")
      : "missing"

  return (
    <MainLayout showUtilityBar={false}>
      <BackgroundImage src={bg} />
      <section className={styles.container}>
        {loading && <Spinner />}
        <div className={styles.frontend}>
          <h4>Frontend</h4>
          <ul className={styles.parentList}>
            <li>
              <span>build</span>
              <ol className={styles.childList}>
                <li>
                  <span>branch: </span>
                  {process.env.REACT_APP_TAPS5_BRANCH_NAME
                    ? process.env.REACT_APP_TAPS5_BRANCH_NAME
                    : "missing"}
                </li>
                <li>
                  <span>Tag: </span>
                  {process.env.REACT_APP_TAPS5_BUILD_TAG
                    ? process.env.REACT_APP_TAPS5_BUILD_TAG
                    : "missing"}
                </li>
              </ol>
            </li>
            <li>
              <span>environment</span>
              <ol className={styles.childList}>
                <li>
                  {process.env.REACT_APP_ENVIRONMENT
                    ? process.env.REACT_APP_ENVIRONMENT
                    : "missing"}
                </li>
              </ol>
            </li>
          </ul>
        </div>
        <div className={styles.backend}>
          <h4>Backend</h4>
          {data && data.systemInfo && data.systemInfo ? (
            <ul className={styles.parentList}>
              <li>
                <span> build</span>
                <ol className={styles.childList}>
                  <li>
                    <span>branch: </span> {data.systemInfo.build.branch}
                  </li>
                  <li>
                    <span>tag: </span> {data.systemInfo.build.tag}
                  </li>
                  <li>
                    <span>time: </span>
                    {buildTime}
                  </li>
                </ol>
              </li>
              <li>
                <span>configration</span>
                <ol className={styles.childList}>
                  <li>
                    <span>websocketEnabled: </span>
                    {data.systemInfo.configuration.websocketEnabled
                      ? "true"
                      : "false"}
                  </li>
                </ol>
              </li>
              <li>
                <span>environment</span>
                <ol className={styles.childList}>
                  <li>{data.systemInfo.environment.name}</li>
                </ol>
              </li>
            </ul>
          ) : (
            <div>Backend data not available</div>
          )}
        </div>

        <div></div>
      </section>
    </MainLayout>
  )
}

export default AboutTapsPage
