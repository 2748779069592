import * as React from "react"
import { Switch, Redirect, Route } from "react-router-dom"
import EditOrderPage from "pages/EditOrderPage"

export interface EditOrderPageParams {
  caseFolderId: string
  action?: string
  orderId?: string
}

const EditOrderPageRoutes:React.FC<EditOrderPageParams> = () => {
    return (
      <Switch>
        <Route
          component={EditOrderPage}
          path="/editOrder/c/:caseFolderId/o/:orderId/a/:action"
        />
        <Route
          component={EditOrderPage}
          path="/editOrder/c/:caseFolderId/o/:orderId"
        />
        <Route
          component={EditOrderPage}
          path="/editOrder/c/:caseFolderId/a/:action"
        />
        <Route
          component={EditOrderPage}
          path="/editOrder/c/:caseFolderId"
          exact={true}
        />
        <Redirect
          to="/editOrder/c/:caseFolderId"
          from="/editOrder/c/:caseFolderId"
        />
        <Redirect to="/dashboard" />
      </Switch>
    )
  
}

export default EditOrderPageRoutes
