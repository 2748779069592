import * as React from "react"
// import { Switch, Route, Redirect } from "react-router-dom"
import { Redirect, Route, Switch } from "react-router-dom"
import OfficeSelectionPage from "pages/OfficeSelectionPage"
import NewsPage from "pages/NewsPage"
import DashboardRoutes from "routes/DashboardRoutes"
import EditOrderPageRoutes from "routes/EditOrderPageRoutes"
import NewCaseFolderRoutes from "routes/NewCaseFolderRoutes"
import ArchiveRoutes from "routes/ArchiveRoutes"
import ReviewPage from "pages/ReviewPage"
import AgencySettings from "pages/AgencySettings"
import LiveView from "pages/LiveView"
import { LogOutPage } from "components/Navbar/NavbarUser/NavbarUserDropdown/LogoutButton"
import VersionBox from "components/DisplayVersionBox"
import SupportPage from "pages/SupportPage"
import SelectProduct from "pages/Adstate/SelectProduct"
import AboutTapsPage from "pages/AboutTapsPage"

const AppRoutes: React.FC = () => {
  return (
    <>
      <Switch>
        <Route path="/selectOffice" component={OfficeSelectionPage} />
        <Route path="/news" component={NewsPage} />
        <Route path="/support" component={SupportPage} />
        <Route path="/logout" component={LogOutPage} />
        <Route path="/dashboard" component={DashboardRoutes} />
        <Route path="/editOrder" component={EditOrderPageRoutes} />
        <Route path="/newCaseFolder" component={NewCaseFolderRoutes} />
        <Route path="/archive" component={ArchiveRoutes} />
        <Route path="/review/c/:caseFolderId" component={ReviewPage} />
        <Route path="/agencySettings" component={AgencySettings} />
        <Route path="/agencySettings/:tabName" component={AgencySettings} />
        <Route path="/live/:liveViewId" component={LiveView} />
        <Route path="/about-Taps" component={AboutTapsPage} />
        <Route
            component={SelectProduct}
            path={"/selectProduct"}
          />
        <Redirect to="/dashboard" />
      </Switch>
      <VersionBox />
    </>
  )
}

export default AppRoutes
