export default [
  "agencySettings",
  "archive",
  "common",
  "dashboard",
  "dateFormats",
  "editOrder",
  "errorMessages",
  "liveView",
  "login",
  "navbar",
  "newCaseFolder",
  "news",
  "nextStep",
  "offices",
  "proof",
  "review",
  "statuses",
  "supportView",
  "utilityBar",
]
