import * as React from "react"
import * as styles from "./styles.scss"
import Button from "components/Button"
import { OrderEditorAreaContext } from "../.."
import icon from "images/line-height-icon.svg"
import OptionDropdown from "components/OptionDropdown"
import { DropdownOption } from "components/DropdownButton"
import AdminSectionTooltip from "../AdminSectionTooltip"
import { useTranslation } from "react-i18next"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faMinusCircle } from "@fortawesome/free-solid-svg-icons"
import UnitValueInput from "../UnitValueInput"

const LineHeightDropdown: React.FC<{}> = () => {
  const { editor, setLineHeight, contentRows , area} = React.useContext(
    OrderEditorAreaContext
  )
  const [t] = useTranslation("editOrder")
  const [inputValue, setInputValue] = React.useState<number | null>(null)

  
  let defaultLineHeight: number | undefined = undefined
  if (area && area?.style && area.style.text && area.style.text.lineHeight) {
    defaultLineHeight = Math.round(area.style.text.lineHeight.value)
  }

  let lineHeightValue: number | null = null
  if (
    contentRows !== undefined &&
    contentRows[0] !== undefined &&
    contentRows[0].columns !== undefined &&
    contentRows[0].columns[0] !== undefined &&
    contentRows[0].columns[0].item !== undefined &&
    contentRows[0].columns[0].item.text !== undefined &&
    contentRows[0].columns[0].item.text !== null &&
    contentRows[0].columns[0].item.text.lineHeight !== undefined &&
    contentRows[0].columns[0].item.text.lineHeight !== null &&
    contentRows[0].columns[0].item.text.lineHeight.value !== undefined &&
    contentRows[0].columns[0].item.text.lineHeight.value !== null
  ) {
    lineHeightValue = contentRows[0].columns[0].item.text.lineHeight.value
  }

  const LineHightArr: number[] = []
  const options: { label: string; value: number }[] = []
  if (
    editor &&
    editor.text !== undefined &&
    editor.text !== null &&
    editor.text.allowedLineHeights !== undefined &&
    editor.text.allowedLineHeights !== null &&
    editor.text.allowedLineHeights.values !== undefined &&
    editor.text.allowedLineHeights.values !== null
  ) {
    editor.text.allowedLineHeights.values.forEach((num) => {
      if (num !== undefined && num !== null && Number.isNaN(num) === false) {
        options.push({
          label: num.toString(),
          value: num,
        })
        LineHightArr.push(num)
      }
    })
  }

  const findClosestNr = (arr: Array<number>, num: any) => {
    if (arr === null) {
      return
    }
    return arr.reduce((prev, current) =>
      Math.abs(current - num) < Math.abs(prev - num) ? current : prev
    )
  }

  const absolutCurrentValue = findClosestNr(LineHightArr, defaultLineHeight)





  const handleDropdownChange = (option: DropdownOption) => {
    setInputValue(option.value)
    setLineHeight(option.value)
  }

  const handleInputChange = (value: string | undefined) => {
    if (value === undefined) {
      setInputValue(null)
      setLineHeight(null)
    } else {
      const newValue = isNaN(Number(value)) ? 0 : Number(value)
      setInputValue(newValue)
      // setLineHeight(newValue)
    }
  }

  const updateValue = () => {
    if(inputValue === null) return;
    return setLineHeight(inputValue)
  }

  const handleClick = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.code === "Enter" || e.code === "NumpadEnter") {
     return updateValue()
    }
  }

  const onClear = () => {
    setInputValue(null)
    setLineHeight(null)
  }

  const renderToggler = () => (
    <AdminSectionTooltip id="lineHeight" text={t("lineHeight")}>
            {lineHeightValue !== null ? (
        <div className={styles.removeIcon} onClick={onClear}>
          <FontAwesomeIcon icon={faMinusCircle} color={"#e6e6e6"} />
        </div>
      ) : null}
      <Button
        variant="primary"
        className={styles.lineHeightButton}
        color={lineHeightValue === null ? "mediumGray" : "secondary"}
      >
        <img src={icon} alt="" className={styles.svg} />
      </Button>
    </AdminSectionTooltip>
  )

  return (
    <div className={styles.wrapper}>
      <UnitValueInput 
        jsx={
          <OptionDropdown
          options={options}
          onChange={handleDropdownChange}
          renderToggler={renderToggler}
          optionListClassName={styles.contentList}
          defaultValue={absolutCurrentValue}
          selectedValue={lineHeightValue}
        />
        }
        placeholder={absolutCurrentValue}
        onChange={handleInputChange}
        unit={"px"}
        value={
          inputValue === undefined || inputValue === null ? lineHeightValue : inputValue
        }
        active={inputValue === null}
        onBlur={updateValue}
        onKeyDown={handleClick}
      />
    </div>
  )
}

export default LineHeightDropdown
