import * as React from "react"
import * as styles from "./styles.scss"

function InfoItem(props: InfoItemProps) {
  return (
    <div className={styles.infoItem}>
      <b>{props.label}</b>
      <span>{props.text}</span>
    </div>
  )
}

interface InfoItemProps {
  label: string
  text: string
}

export default InfoItem
