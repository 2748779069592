import * as React from "react"
import * as styles from "./styles.scss"
import Button from "components/Button"
import { useTranslation } from "react-i18next"
import { AppContext } from "App"
import { useMutation } from "react-apollo-hooks"
import CREATE_IMAGE_TAG from "graphql/mutations/createGalleryImageTags"
import GET_GALLERY_IMAGE_TAGS_BY_TAG_GROUPS from "graphql/queries/getGalleryImageTagsByTagGroup"
import ADD_OR_REMOVE_TAGS_FROM_SYMBOLS from "graphql/mutations/addOrRemoveTagsFromSymbols"
import { GetGalleryImages_galleryImagesByTags_images } from "schema"
import { containsOnlyWhitespace } from "utils"
import cx from "classnames"

function ControlBar(props: ControlBarProps) {
  const [inNewListMode, setInNewListMode] = React.useState(false)
  const [newListName, setNewListName] = React.useState("")
  const { currentUserSession } = React.useContext(AppContext)
  const companyId = currentUserSession.customer!.office.company.id
  const [t] = useTranslation()
  const createTag = useMutation(CREATE_IMAGE_TAG, {
    variables: {
      input: {
        galleryImageTagGroupId: companyId,
        tags: [newListName],
      },
    },
    refetchQueries: [
      {
        query: GET_GALLERY_IMAGE_TAGS_BY_TAG_GROUPS,
        variables: {
          input: {
            tagGroupIds: [companyId],
          },
        },
      },
    ],
  })


 const symbol_id = Object.values(props.symbolsToAdd[0]).map((  s) =>  s.id)


  const addSybmolsToTag = useMutation(ADD_OR_REMOVE_TAGS_FROM_SYMBOLS, {
    variables: {
      input: {
        tagGroupId: companyId,
        imageIds: symbol_id,
        tags: [props.selectedFavorite],
      },
      filterTagGroupId: companyId,
    },
  })

  const handleSetNewListName = (e: React.ChangeEvent<HTMLInputElement>) =>
    setNewListName(e.target.value)

  const enterNewListMode = () => setInNewListMode(true)

  const exitNewListMode = () => {
    setInNewListMode(false)
    setNewListName("")
  }

  const handleSubmit = () =>
    inNewListMode
      ? createTag().then(exitNewListMode)
      : addSybmolsToTag().then(() => {
          props.onFetchSymbolInfo()
          props.onClose()
        })

  const submitButtonIsDisabled = () =>
    (inNewListMode && containsOnlyWhitespace(newListName)) ||
    (!inNewListMode && !props.selectedFavorite)

  return (
    <div className={styles.controlBar}>
      {inNewListMode ? (
        <input
          value={newListName}
          onChange={handleSetNewListName}
          className={cx(styles.newListNameInput,{
            [styles.FEView__newListNameInput]: props.isFEUser
          })}
          placeholder={t("newListName")}
        />
      ) : (
        <Button
          variant={props.isFEUser ? "feView" : "tertiary"}
          icon="plus-circle"
          onClick={enterNewListMode}
        >
          {t("createNewList")}
        </Button>
      )}
      <Button
        variant={props.isFEUser ? "feView" : "primary"}
        color={
          inNewListMode || props.selectedFavorite ? "primary" : "secondary"
        }
        disabled={submitButtonIsDisabled()}
        onClick={handleSubmit}
      >
        {t("save")}
      </Button>
      {inNewListMode && (
        <Button
          variant={props.isFEUser ? "feView" :"primary"}
          color="secondary"
          onClick={exitNewListMode}
          className={styles.cancelButton}
        >
          {t("cancel")}
        </Button>
      )}
    </div>
  )
}

interface ControlBarProps {
  selectedFavorite?: string
  symbolsToAdd: GetGalleryImages_galleryImagesByTags_images[]
  onFetchSymbolInfo: () => void
  onClose: () => void
  isFEUser: boolean
}

export default ControlBar
