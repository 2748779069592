import React from "react"
import * as styles from "./styles.scss"
import cx from "classnames"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faCheckCircle,
  faExclamationTriangle,
  faQuestionCircle,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons"
import { PublishOrderContext } from "../.."

const ResultOrders: React.FC<{}> = (props) => {
  const { resultOrders } = React.useContext(PublishOrderContext)
  return (
    <>
        <div className={cx(styles.resultOrdersContainer)}>
          {resultOrders && (
            <div
              className={cx(styles.msgBox, {
                [styles.ERROR]:
                  resultOrders.resultIcon && resultOrders.resultIcon === MessageBoxIcons.ERROR,
                [styles.WARNING]:
                  resultOrders.resultIcon && resultOrders.resultIcon === MessageBoxIcons.WARNING,
                [styles.INFORMATION]:
                  resultOrders.resultIcon && resultOrders.resultIcon === MessageBoxIcons.INFORMATION,
                [styles.NONE]: resultOrders.resultIcon && resultOrders.resultIcon === MessageBoxIcons.NONE,
              })}
            >
              <FontAwesomeIcon
                icon={
                  (resultOrders.resultIcon && resultOrders.resultIcon === MessageBoxIcons.ERROR &&
                    faTimesCircle) ||
                  (resultOrders.resultIcon && resultOrders.resultIcon === MessageBoxIcons.WARNING &&
                    faExclamationTriangle) ||
                  (resultOrders.resultIcon && resultOrders.resultIcon === MessageBoxIcons.INFORMATION &&
                    faQuestionCircle) ||
                  faCheckCircle
                }
              />
              <p>{resultOrders.resultText}</p>
            </div>
          )}
        </div>

    </>
  )
}

enum MessageBoxIcons {
  NONE = "NONE",
  INFORMATION = "INFORMATION",
  WARNING = "WARNING",
  ERROR = "ERROR",
}

export default ResultOrders
