import * as React from "react"
import * as styles from "./styles.scss"
import {
  GetPackages_orderInitiatorPackagesByCurrentUser_items,
  // GetOrderInitiatorRootNode_orderInitiatorRootNode_children,
} from "schema"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import AddOrderModal from "components/AddOrderModal"
import { WithTranslation, withTranslation } from "react-i18next"
import MediaItem from "./MediaItem"

class MediaItems extends React.Component<
  MediaItemsProps & WithTranslation,
  MediaItemsState
> {
  state = { modalIsOpen: false }

  openModal = () => this.setState({ modalIsOpen: true })

  closeModal = () => this.setState({ modalIsOpen: false })

  // handleAddOrder = (
  //   node: GetOrderInitiatorRootNode_orderInitiatorRootNode_children
  // ) => {
  //   this.props.onAddOrder(node, this.props.orderPackageId)
  //   this.closeModal()
  // }

  handleDelete = (index: number) =>
    this.props.onDeleteOrder(index, this.props.orderPackageId)

  render() {
    // const { modalIsOpen } = this.state

    return (
      <React.Fragment>
        <div className={styles.editableMediaItemsContainer}>
          <div className={styles.mediaItems}>
            {this.props.mediaItems.map((mediaItem, index) => (
              <MediaItem
                key={index}
                index={index}
                mediaItem={mediaItem}
                onDeleteOrder={this.handleDelete}
              />
            ))}
          </div>
          {/* <p className={styles.addButton} onClick={this.openModal}>
            <FontAwesomeIcon icon="plus-circle" />{" "}
            <span>{this.props.t("add")}</span>
          </p> */}
        </div>
        {/* <AddOrderModal
          isOpen={modalIsOpen}
          onAddOrder={this.handleAddOrder}
          onRequestClose={this.closeModal}
        /> */}
      </React.Fragment>
    )
  }
}

interface MediaItemsState {
  modalIsOpen: boolean
}

interface MediaItemsProps {
  mediaItems: GetPackages_orderInitiatorPackagesByCurrentUser_items[]
  orderPackageId: string
  // onAddOrder: (
  //   node: GetOrderInitiatorRootNode_orderInitiatorRootNode_children,
  //   orderPackageId: string
  // ) => void
  onDeleteOrder: (index: number, orderPackageId: string) => void
}

export default withTranslation()(MediaItems)
