import * as React from "react"
import * as styles from "./styles.scss"
import TogglerField from "./TogglerField"
import { useTranslation } from "react-i18next"
import ContactSettings from "../ContactSettings"

function SettingsTab({
  changeSetting,
  settings,
}: {
  changeSetting: any
  settings: any
}) {
  const [t] = useTranslation("agencySettings")
  return (
    <div className={styles.tabContainer}>
      <div className={styles.settingsContainer}>
        <ContactSettings settings={settings} changeSetting={changeSetting} />
      </div>

      <div className={styles.tabContent}>
        {/* <FavoriteMedias /> */}
        <div className={styles.content}>
          <div className={styles.firstCol}>
            <b className={styles.label}>{t("settingsForWebPublishing")}</b>
            <TogglerField
              label={t("memoryRoom")}
              name="isDefaultParallelPublishInMinnesrummet"
            />
            <TogglerField
              label={t("familyPage")}
              name="isDefaultParallelPublishInFamiljesidan"
            />
            <TogglerField
              label={t("agencyWebsite")}
              name="isDefaultParallelPublishInAgencyHomepage"
            />
            <TogglerField label={t("myPage")} name="isDefaultMyPageEnabled" />
          </div>
          <div className={styles.secondCol}>
            <b className={styles.label}>{t("suggestionThankAdvert")}</b>
            <TogglerField
              label={t("showOnProof")}
              name="isDefaultProofSuggestionThanksAdvert"
            />
            <div className={styles.subCol}>
            <b className={styles.label}>{t("ProofOption")}</b>
            <TogglerField  
              // label={t("proof:casePrise") + " " + "ALIS och" + " " + `<span class="proofCheckboxText" title="Familjesidan.se">FS*</span>` + " " +t("proof:widthAllAdverts")}
              label={t("proof:caseFeeIncludedInOrders")}
              name={"isDefaultProofCaseFeeAddOnOrders"}
            />
            <TogglerField  
              //label={t("proof:casePrise") + " " + "ALIS och" + " " + `<span class="proofCheckboxText" title="Familjesidan.se">FS*</span>` + " " +t("proof:withFirstAdvert")}
              label={t("proof:caseFeeWithMainOrder")}
              name={"isDefaultProofCaseFeeAddOnFirstOrder"}
            />
            <TogglerField  
              //label={t("proof:exclude") + " " + t("proof:theCasePrise") + " " + "ALIS och <span class='proofCheckboxText' title='Familjesidan.se'>FS*</span>" }
              label={t("proof:excludeCasePrice")}
              name={"isDefaultProofCaseFeeHide"}
            />
            </div>
          </div>
          
          {/* <div className={styles.thiredCol}>
            <b className={styles.label}>{t("editAdPrise")}</b>
            <TogglerField label={t("showOrHide")} name={"isAllowUpdateAddOn"} />
          </div> */}
        </div>
      </div>
    </div>
  )
}

export default SettingsTab
