import * as React from "react"
import * as styles from "./styles.scss"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useTranslation } from "react-i18next"

function Advanced(props: AdvancedProps) {
  const [t] = useTranslation("supportView")

  return (
    <div className={styles.advancedToggler} onClick={props.onClick}>
      <FontAwesomeIcon icon={props.showAdvanced ? "minus" : "plus"} />{" "}
      {t("advanced")}
    </div>
  )
}

interface AdvancedProps {
  showAdvanced: boolean
  onClick: () => void
}

export default Advanced
