import * as React from "react"
import * as styles from "./styles.scss"
import Tab from "./Tab"
import { IconProp } from "@fortawesome/fontawesome-svg-core"
import cx from "classnames"

function TabContainer(props: TabContainerProps) {
  const selectedTab = props.tabs.find(tab => tab.id === props.selectedTabId)
  if (!selectedTab) return null
  const Component = selectedTab.component

  return (
    <div className={cx(styles.tabContainer, props.className)}>
      <div className={cx(styles.tabRow, props.tabRowClassName)}>
        {props.tabs.map(tab => (
          <Tab
            key={tab.id}
            id={tab.id}
            label={tab.label}
            icon={tab.icon}
            disabled={tab.disabled}
            className={props.tabClassName}
            isSelected={props.selectedTabId === tab.id}
            onClick={props.onSelectTab}
          />
        ))}
        <div className={styles.filler} />
      </div>
      <Component {...selectedTab.tabProps} />
    </div>
  )
}

interface TabContainerProps {
  tabs: TabContainerTab[]
  selectedTabId?: string
  className?: string
  tabClassName?: string
  tabRowClassName?: string
  onSelectTab: (id: string) => void
}

interface TabContainerTab {
  id: string
  label: string
  icon?: IconProp
  component: React.StatelessComponent
  disabled?: boolean
  tabProps?: any
}

export default TabContainer
