import * as React from "react"
import {
  OrderInitiatorSelectLayout,
  OrderInitiatorSelectTypes,
  SelectOrderInitiatorView_selectOrderInitiatorView,
  SelectOrderInitiatorView_selectOrderInitiatorView_options,
  SelectOrderInitiatorView_selectOrderInitiatorView_subViews,
} from "schema"
import { LayoutMultiDropdownLine } from "./LayoutMultiDropdownLine"
import { LayoutTextboxLine } from "./LayoutTextboxLine"
import { LayoutImageTextWithDropdownThumbnail } from "./LayoutImageTextWithDropdownThumbnail"
import { LayoutImageTextThumbnail } from "./LayoutImageTextThumbnail"
import { LayoutImageTextThumbnailWithSlider } from "./LayoutImageTextThumbnailWithSlider"
import LayoutTable from "./LayoutTable"

export const Options: React.FC<{
  caseFolderId: string
  orderId?: string
  options: SelectOrderInitiatorView_selectOrderInitiatorView_options[]
  subViews: SelectOrderInitiatorView_selectOrderInitiatorView_subViews[]
  layout: OrderInitiatorSelectLayout
  onCloseModal: any
  onSelectItem: (selector: string) => void
  selectOrderInitiatorView?: SelectOrderInitiatorView_selectOrderInitiatorView
  isFEViewEnabled?: boolean
  selectType?:OrderInitiatorSelectTypes
}> = (props) => {
  const {
    IMAGE_TEXT_WITH_DROPDOWN_THUMBNAIL,
    MULTI_DROPDOWN_LINE,
    TEXTBOX_LINE,
    DROP_DOWN_LINE,
    IMAGE_TEXT_THUMBNAIL,
    IMAGE_TEXT_THUMBNAIL_WITH_SLIDER,
    SUB_DROPDOWN_LINE,
    SUB_VIEWS,
    TABLE,
  } = OrderInitiatorSelectLayout

  if (props.layout === IMAGE_TEXT_WITH_DROPDOWN_THUMBNAIL) {
    return (
      <LayoutImageTextWithDropdownThumbnail
        caseFolderId={props.caseFolderId}
        orderId={props.orderId}
        options={props.options}
        onCloseModal={props.onCloseModal}
        onSelectItem={props.onSelectItem}
      />
    )
  }
  if (props.layout === TEXTBOX_LINE) {
    return (
      <LayoutTextboxLine
        caseFolderId={props.caseFolderId}
        orderId={props.orderId}
        options={props.options}
        onCloseModal={props.onCloseModal}
        onSelectItem={props.onSelectItem}
      />
    )
  }

  if (
    props.layout === MULTI_DROPDOWN_LINE ||
    props.layout === SUB_DROPDOWN_LINE ||
    props.layout === DROP_DOWN_LINE
  ) {
    return (
      <LayoutMultiDropdownLine
        caseFolderId={props.caseFolderId}
        orderId={props.orderId}
        options={props.options}
        onCloseModal={props.onCloseModal}
        onSelectItem={props.onSelectItem}
      />
    )
  }

  if (props.layout === IMAGE_TEXT_THUMBNAIL_WITH_SLIDER) {
    return (
      <LayoutImageTextThumbnailWithSlider
        caseFolderId={props.caseFolderId}
        orderId={props.orderId}
        options={props.options}
        onCloseModal={props.onCloseModal}
        onSelectItem={props.onSelectItem}
      />
    )
  }

  if (props.layout === IMAGE_TEXT_THUMBNAIL) {
    return (
      <LayoutImageTextThumbnail
        caseFolderId={props.caseFolderId}
        orderId={props.orderId}
        options={props.options}
        onCloseModal={props.onCloseModal}
        onSelectItem={props.onSelectItem}
        isFEViewEnabled={props.isFEViewEnabled!}
        selectType={props.selectType!}
      />
    )
  }

  if (props.layout === TABLE) {
    return (
      <LayoutTable
        columns={props.selectOrderInitiatorView?.columns!}
        options={props.options}
        onSelectItem={props.onSelectItem}
      />
    )
  }

  if (props.layout === SUB_VIEWS) {
    return (
      <div>
        {props.subViews.map((subView) => {
          return (
            <div>
              <div className={"header-wrapper"}>{subView.header}</div>
              <Options
                subViews={[]}
                options={
                  subView.options as SelectOrderInitiatorView_selectOrderInitiatorView_options[]
                }
                layout={
                  subView.layout ??
                  OrderInitiatorSelectLayout.IMAGE_TEXT_WITH_DROPDOWN_THUMBNAIL
                }
                caseFolderId={props.caseFolderId ?? ""}
                onCloseModal={props.onCloseModal}
                orderId={props.orderId}
                onSelectItem={props.onSelectItem}
              />
            </div>
          )
        })}
      </div>
    )
  }

  return <div>UNSUPORTED LAYOUT: {props.layout}</div>
}
