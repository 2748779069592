import * as React from "react"
import * as styles from "./styles.scss"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { withTranslation, WithTranslation } from "react-i18next"

class NoNews extends React.Component<WithTranslation> {
  render() {
    return (
      <div className={styles.noNewsContainer}>
        <FontAwesomeIcon
          icon={["far", "newspaper"]}
          className={styles.newsPaperIcon}
        />
        <span className={styles.description}>{this.props.t("noNews")}</span>
      </div>
    )
  }
}

export default withTranslation("news")(NoNews)
