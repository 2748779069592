import * as React from "react"
import * as styles from "./styles.scss"
import Dropdown from "components/Dropdown"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import OptionsList from "./OptionsList"
import { DropdownOption } from "components/DropdownButton"
import cx from "classnames"

class OptionDropdown extends React.PureComponent<OptionDropdownProps> {
  renderDefaultToggler = () => (
    <div className={cx(styles.dropdownToggler, this.props.togglerClassName)}>
      {this.props.selectedLabel}
      <FontAwesomeIcon icon="caret-down" className={styles.icon} />
    </div>
  )

  render() {
    const {
      className,
      contentClassName,
      optionListClassName,
      options,
      onChange,
      direction,
      onOptionHover,
      defaultValue,
      selectedValue,
      renderToggler = this.renderDefaultToggler,
      renderBottomButton: BottomButton,
    } = this.props

  
    return (
      <Dropdown
        dropdownClassName={className}
        contentClassName={contentClassName}
        renderToggler={renderToggler}
        direction={direction}
      >
        <OptionsList
          options={options}
          onOptionHover={onOptionHover}
          onClick={onChange}
          className={optionListClassName}
          defaultValue={defaultValue}
          selectedValue={selectedValue}
          listWidth={this.props.listWidth}
        />
        {BottomButton && <BottomButton />}
      </Dropdown>
    )
  }
}

interface OptionDropdownProps {
  selectedLabel?: string
  className?: string
  direction?: DropdownDirections
  togglerClassName?: string
  contentClassName?: string
  optionListClassName?: string
  options: DropdownOption[]
  renderBottomButton?: React.ComponentClass | React.StatelessComponent
  renderToggler?: () => JSX.Element
  onOptionHover?: (id?: string) => void
  onChange: (option: DropdownOption) => void
  defaultValue?: any
  selectedValue?: any
  listWidth?: string
}

export default OptionDropdown
