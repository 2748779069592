import * as React from "react"
import * as styles from "./styles.scss"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import cx from "classnames"
import { IconProp } from "@fortawesome/fontawesome-svg-core"
import Slider from "react-rangeslider"
import styled from "styled-components"
import { AppContext } from "App"

const RangeSlider: React.FC<RangeSliderProps> = (props) => {
  const {featureFlags} = React.useContext(AppContext)
  const formatTooltipValue = (value: number | null) => {
    if (value === null) {
      return "-"
    }
    return `${value} mm`
  }

  const {
    leftIcon,
    rightIcon,
    leftIconStyle,
    rightIconStyle,
    className,
    value,
    ...rest
  } = props

  const isNotSet = props.value === null || props.value === undefined

  const diff = props.max - props.min
  const mid = Math.round(diff / 2)
  const defaultValue =
    props.defaultHeightMM === null || props.defaultHeightMM === undefined
      ? mid
      : props.defaultHeightMM

  return (
    <SliderStyles>
      <div className={cx(styles.container, className, {
        [styles.feView__container]: featureFlags?.isFEUser!
      })}>
        {leftIcon && (
          <FontAwesomeIcon
            icon={leftIcon}
            className={
              isNotSet
                ? "left-icon-inactive"
                : cx(styles.leftIcon, leftIconStyle)
            }
          />
        )}
        <div className={`slider-wrapper ${isNotSet ? "inactive" : "active"}`}>
          <Slider
            {...rest}
            value={isNotSet ? Number(defaultValue) : Number(props.value!)}
            format={formatTooltipValue}
            step={props.step}
            tooltip={props.tooltip}
          />
        </div>
        {rightIcon && (
          <FontAwesomeIcon
            icon={rightIcon}
            className={
              isNotSet
                ? "right-icon-inactive"
                : cx(styles.rightIcon, rightIconStyle)
            }
          />
        )}
      </div>
    </SliderStyles>
  )
}

export const SliderStyles = styled.div`
  .slider-wrapper {
    width: 100%;
  }
  .right-icon-inactive path {
    fill: ${(props) => props.theme["--medium-gray"]};
  }
  .left-icon-inactive path {
    fill: ${(props) => props.theme["--medium-gray"]};
  }
  .slider-wrapper.inactive {
    .rangeslider.rangeslider-horizontal .rangeslider__fill {
      background-color: ${(props) => props.theme["--slider-color-inactive"]};
    }
    .rangeslider__handle {
      background-color: ${(props) => props.theme["--slider-color-inactive"]};
    }
    .rangeslider.rangeslider-horizontal {
      border: 1px solid ${(props) => props.theme["--slider-color-inactive"]};
    }
  }
`

interface RangeSliderProps {
  leftIcon?: IconProp
  leftIconStyle?: string
  rightIcon?: IconProp
  rightIconStyle?: string
  min: number
  max: number
  value: number | null
  className?: string
  onChange: (value: number | null) => void
  onChangeComplete?: () => void
  color?: string
  defaultHeightMM?: number | null
  step?: number
  tooltip?: boolean
}

export default RangeSlider
