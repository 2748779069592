import * as React from "react"
import UPDATE_ALL_ORDERS_BY_FIELD from "graphql/mutations/updateAllOrdersByField"
import { omitTypename } from "utils"
import { OrderEditorAreaContext } from "../AreasContainer/Areas/AreaContainer"
import UpdateAllFieldsButton from "../AreasContainer/Areas/UpdateAllFeldsButton"
import { useTranslation } from "react-i18next"
import {
  UpdateAllOrdersByField,
  UpdateAllOrdersByFieldVariables,
  EditModelAdvertAreaContentEditorTypes,
  GetEditOrderPageCaseFolder_caseFolder_editTabs_orders,
} from "schema"
import { useParams } from "react-router-dom"
import { useApolloClient, useMutation } from "react-apollo-hooks"
import { ErrorText } from "components/ErrorMessage"

export const UpdateAllOrdersByFieldContainer: React.FC<Props> = (props) => {
  const { t } = useTranslation("editOrder")
  const orderId = useParams<any>().orderId
  const orders = props.orders && props.orders.map((order) => order)
  const updateOrderText = orders.map((order) =>
    order.map(
      (o) =>
        o.id === orderId &&
        t(`updateAllOrdersByField`) +
          o.mediaOrder?.documentFormat.documentType.name
    )
  )

  return <ButtonWrapper text={updateOrderText.toString()} type={props.type} />
}

const ButtonWrapper: React.FC<{
  text: string
  type: EditModelAdvertAreaContentEditorTypes
}> = (props) => {
  const [error, setError] = React.useState(undefined)
  const client = useApolloClient()
  const updateAllOrdersByField = useMutation<
    UpdateAllOrdersByField,
    UpdateAllOrdersByFieldVariables
  >(UPDATE_ALL_ORDERS_BY_FIELD, {
    client,
    context: {},
  })
  const { GALLERY_IMAGE, SEPARATOR } = EditModelAdvertAreaContentEditorTypes


  return (
    <OrderEditorAreaContext.Consumer>
      {({
        contentValues,
        selectedOrderId,
        contentRows,
        // triggerContentValues,
      }) => {
        const onClick = async () => {
          setError(undefined)
          return updateAllOrdersByField({
            variables: {
              input: {
                orderId: selectedOrderId,
                editModel: {
                  editModelAdvertAreaContentValues: [
                    {
                      ...omitTypename(contentValues),
                      // rows: omitTypename(contentRows),
                    },
                  ],
                },
              },
            },
          }).catch((err) => {
            setError(err.toString())
          })
          // return new Promise<void>((resolve, reject) => {
          //   setError(undefined)
          //   triggerContentValues(
          //     () => {
          //       resolve()
          //     },
          //     (err) => {
          //       resolve()
          //       setError(err.toString())
          //     }
          //   )
          // })
        }
        const onClickWithMutation = () => {
          setError(undefined)
          return updateAllOrdersByField({
            variables: {
              input: {
                orderId: selectedOrderId,
                editModel: {
                  editModelAdvertAreaContentValues: [
                    {
                      ...omitTypename(contentValues),
                      rows: omitTypename(contentRows),
                    },
                  ],
                },
              },
            },
          }).catch((err) => {
            setError(err.toString())
          })
        }
        return (
          <React.Fragment>
            <ButtonContent
              type={props.type}
              onClick={
                props.type === SEPARATOR || props.type === GALLERY_IMAGE
                  ? onClick
                  : onClickWithMutation
              }
              text={props.text}
            />
            {error ? <ErrorText>{error}</ErrorText> : null}
          </React.Fragment>
        )
      }}
    </OrderEditorAreaContext.Consumer>
  )
}

const ButtonContent: React.FC<{
  onClick: any
  text: any
  type: EditModelAdvertAreaContentEditorTypes
}> = (props) => {
  return (
    <UpdateAllFieldsButton
      onClick={props.onClick}
      text={props.text}
      isTooltipActive={true}
      isAreaInner={props.type === "GALLERY_IMAGE" ? false : true}
    />
  )
}

interface Props {
  type: EditModelAdvertAreaContentEditorTypes
  orders: GetEditOrderPageCaseFolder_caseFolder_editTabs_orders[][]
}

export default UpdateAllOrdersByFieldContainer
