import * as React from "react"
import InputField from "../../AdvancedContainer/InputField"
import { useTranslation } from "react-i18next"

function MaxResultsField(props: MaxResultsFieldProps) {
  const [t] = useTranslation("supportView")
  const [value, setValue] = React.useState(props.initialValue.toString())

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setValue(e.target.value)

  const setValueOnBlur = (numberVal: number) => {
    props.setMaxResults(numberVal)
    setValue(numberVal.toString())
  }

  const handleBlur = (e: React.ChangeEvent<HTMLInputElement>) => {
    const numberVal = Number(e.target.value)

    if (isNaN(numberVal) || e.target.value === "")
      return setValue(props.initialValue.toString())

    if (numberVal > 100) return setValueOnBlur(100)
    if (numberVal < 1) return setValueOnBlur(1)

    setValueOnBlur(numberVal)
  }

  return (
    <InputField
      label={t("maxResultsLabel")}
      onBlur={handleBlur}
      placeholder={t("maxResults")}
      value={value}
      onChange={handleChange}
    />
  )
}

interface MaxResultsFieldProps {
  initialValue: number
  setMaxResults: (value: number) => void
}

export default MaxResultsField
