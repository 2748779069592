import * as React from 'react';
import { PublishOrderContext } from "../.."
import { useTranslation } from "react-i18next"
import * as styles from "./styles.scss"


const AdvertPrices: React.FC = () => {
    const { publishOrder } = React.useContext(PublishOrderContext)
    const [t] = useTranslation('review')

    const advertPrice = publishOrder.order.mediaOrder?.preview.price?.endCustomer.pricePayRounded;
    const currency  = publishOrder.order.mediaOrder?.preview.price?.currency;
    return (
        <div className={styles.price}>
            <div> <b>{t("price")}: </b> </div>
            <span>{advertPrice ? Math.round(advertPrice) : 'NaN'}  </span>
            <span>{ advertPrice && (currency === 'SEK') &&  <span style={{fontSize: "13px", margin: "0"}}>kr</span> }</span>
        </div>
    );
}

export default AdvertPrices;
