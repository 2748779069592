import * as React from "react"
import * as styles from "./styles.scss"
import { useTranslation } from "react-i18next"
import FieldWrapper from "components/FieldWrapper"
import TextInput from "components/TextInput"
import cx from "classnames"


function PriceContainer(props: PriceContainerProps) {
  const [t] = useTranslation("agencySettings")

  return (
    <div className={styles.container}>
      <b className={styles.label}>{props.label}</b>
      <div className={styles.priceContainer}>
        <div className={ cx(styles.baseCost , {
          [styles.notStored]:!props.isStoredPrice
        }) } title={ !props.isStoredPrice ? "Contract is missing" : "" }>
          {t("basePrice")} {props.isStoredPrice && <span>{props.customerPrice}</span>}:- {!props.isStoredPrice && (<span>{/*TODO*/} </span>)}
        </div>        

        <div className={styles.additionContainer}>
          <span>{t("endCustomerPrice")}</span>
          <FieldWrapper
            component={TextInput}
            name={props.name}
            className={styles.fieldWrapper}
            placeholder={"..."}             
          />
          :-
        </div>
      </div>
    </div>
  )
}

interface PriceContainerProps {
  label: string
  customerPrice: string
  customerEndPrice: string
  name: string
  isStoredPrice:boolean
  isStoredEndPrice:boolean
}

export default PriceContainer
