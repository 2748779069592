import * as React from "react"
import * as styles from "./styles.scss"
import ReactTable from "react-table-6"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Toggler from "components/Toggler"
import Button from "components/Button"
import cx from "classnames"
import UserModal, { UserFormData } from "./UserModal"
import { useTranslation } from "react-i18next"
import { useMutation, useQuery } from "react-apollo-hooks"
import { DropdownOption } from "components/DropdownButton"
import UPDATE_CUSTOMER_USER from "graphql/mutations/updateCustomerUser"
import { AppContext } from "App"
import MUTATION_DELETE_USER from "graphql/mutations/deleteUser"
import {
  MutationDeleteUserInput,
  MutationDeleteUser,
  CustomerScope,
  GetCurrentCustomer,
  GetCurrentCustomer_currentCustomer_colleagues,
  MutationDeleteUserVariables,
} from "schema"
import ConfirmationModal from "components/ConfirmationModal"
// import GET_CURRENT_CUSTOMER_COLLEAGUES from "graphql/queries/getCurrentCustomerColleagues"
import Spinner from "components/Spinner"
import GET_CURRENT_CUSTOMER from "graphql/queries/getCurrentCustomer"

function UsersTab() {
  const [t] = useTranslation(["agencySettings", "common"])
  const [userModalIsOpen, setUserModalIsOpen] = React.useState(false)
  const [initialValues, setInitialValues] = React.useState<
    UserFormData | undefined
  >(undefined)
  const updateUser = useMutation(UPDATE_CUSTOMER_USER, {
    refetchQueries: [{ query: GET_CURRENT_CUSTOMER }],
  })
  const deleteUser = useMutation<
    MutationDeleteUser,
    MutationDeleteUserVariables
  >(MUTATION_DELETE_USER, { refetchQueries: [{ query: GET_CURRENT_CUSTOMER }] })
  const openUserModal = () => setUserModalIsOpen(true)
  const closeUserModal = () => setUserModalIsOpen(false)
  const [deleteUserModalIsOpen, setDeleteUserModalIsOpen] = React.useState(
    false
  )
  const openModalDeleteUser = () => setDeleteUserModalIsOpen(true)
  const closeModalDeleteUser = () => setDeleteUserModalIsOpen(false)
  const [deleteUserInfo, setDeleteUserInfo] = React.useState<
    UserRow | undefined
  >(undefined)
  const { currentCustomer } = React.useContext(AppContext)
  const { data, loading } = useQuery<GetCurrentCustomer>(GET_CURRENT_CUSTOMER)
  const handleAddUserClick = () => {
    setInitialValues(undefined)
    openUserModal()
  }
  const handleEditUserClick = (user: UserRow) => {
    // const { name, ...rest } = user
    setInitialValues({
      active: user.active ?? true,
      firstName: user.firstName ?? "",
      lastName: user.lastName ?? "",
      office: user.office.value,
      officeLabel: user.office.label,
      phone: user.phone ?? "",
      username: user.username ?? "",
      password: user.password ?? "",
      id: user.id,
      primaryUserId: user.primaryUserId ?? undefined,
    })
    openUserModal()
  }
  const onClickDeleteRow = (user: UserRow) => {
    setDeleteUserInfo(user)
    openModalDeleteUser()
  }
  const handleDeleteUser = () => {
    if (deleteUserInfo) {
      deleteUser({
        variables: {
          input: {
            userId: deleteUserInfo.primaryUserId!,
          },
        },
      }).then(() => {
        closeModalDeleteUser()
      })
    }
  }
  function toggleActive(user: UserRow) {
    updateUser({
      variables: {
        input: {
          customer: {
            id: user.id,
            firstName: user.firstName,
            lastName: user.lastName,
            officeId: user.office.value,
            primaryUser: {
              id: user.primaryUserId,
              username: user.username,
              password: user.password,
              isEnabled: !user.active,
            },
          },
        },
      },
    })
  }

  return (
    <div className={styles.userSettingsContainer}>
      <div className={styles.buttonContainer}>
        <Button
          variant="tertiary"
          onClick={handleAddUserClick}
          icon="plus-circle"
        >
          {t("addUser")}
        </Button>
      </div>

      {loading === true ? <Spinner /> : null}

      {loading === false && data !== undefined ? (
        <RenderUsers
          data={data}
          toggleActive={toggleActive}
          handleEditUserClick={handleEditUserClick}
          onClickDeleteRow={onClickDeleteRow}
        />
      ) : null}

      <UserModal
        isOpen={userModalIsOpen}
        onRequestClose={closeUserModal}
        initialValues={initialValues}
        customerId={currentCustomer.id!}
        availableOffices={currentCustomer.office.company.offices}
      />
      <ConfirmationModal
        title={t("deleteUser")}
        okButtonText={t("common:delete")}
        isOpen={deleteUserModalIsOpen}
        onRequestClose={closeModalDeleteUser}
        onOKClick={handleDeleteUser}
      />
    </div>
  )
}

const RenderUsers: React.FC<{
  data: GetCurrentCustomer
  toggleActive: any
  handleEditUserClick: any
  onClickDeleteRow: any
}> = ({ data, toggleActive, handleEditUserClick, onClickDeleteRow }) => {
  const [t] = useTranslation(["agencySettings", "common"])
  const columns = [
    {
      Header: t("name"),
      accessor: "name",
    },
    {
      Header: t("username"),
      accessor: "username",
    },
    {
      Header: t("active"),
      width: 60,
      accessor: "active",
      Cell: (row: any) => (
        <div className={styles.togglerContainer}>
          <Toggler
            checked={row.original.active}
            onChange={() => toggleActive(row.original)}
          />
        </div>
      ),
    },
    {
      Header: t("office"),
      accessor: "office.label",
      width: 250,
    },
    {
      width: 128,
      Cell: (props: any) => (
        <div>
          <span
            className={styles.edit}
            onClick={() => handleEditUserClick(props.original)}
          >
            {t("edit")}
          </span>
          <span onClick={() => onClickDeleteRow(props.original)}>
            <FontAwesomeIcon className={styles.deleteIcon} icon="trash-alt" />
          </span>
        </div>
      ),
    },
  ]
  let colleagues: GetCurrentCustomer_currentCustomer_colleagues[] = []
  if (
    data.currentCustomer !== undefined &&
    data.currentCustomer !== null &&
    data.currentCustomer.colleagues !== undefined &&
    data.currentCustomer.colleagues !== null
  ) {
    if (data.currentCustomer.colleagues) {
      colleagues = data.currentCustomer.colleagues as any
    }
  }
  const users: UserRow[] = colleagues.map((colleague) => {
    return {
      id: colleague.id ?? undefined,
      firstName: colleague.firstName,
      lastName: colleague.lastName,
      name: colleague.name,
      office: {
        value: colleague.office.id,
        label: colleague.office.name,
      },
      phone: colleague.inheritSettings.contactPhone ?? undefined,
      username: colleague.primaryUser?.username ?? undefined,
      active: colleague.primaryUser?.enabled ?? undefined,
      primaryUserId: colleague.primaryUser?.id ?? undefined,
    }
  })
  return (
    <ReactTable
      columns={columns}
      className="-striped -highlight"
      getTdProps={(state: any, rowInfo: any, columnInfo: any) => ({
        className:
          columnInfo.id === "office"
            ? cx(styles.column, styles.officeColumn)
            : styles.column,
      })}
      data={users}
      pageSize={users.length}
      sortable={false}
      showPagination={false}
    />
  )
}

interface UserRow {
  id?: string
  firstName: string
  lastName: string
  name: string
  phone?: string
  username?: string
  password?: string
  active?: boolean
  primaryUserId?: string
  office: DropdownOption
}

export default UsersTab
