import React from "react"
import styles from "./styles.scss"
import { useApolloClient, useMutation, useQuery } from "react-apollo-hooks"
import GET_AVAILIABLE_DOCUMENT_FORMATS from "graphql/queries/GetAvailableDocumentFormats"
import {
  GetAvaiableDocumentFormats,
  GetAvaiableDocumentFormatsVariables,
  GetOrderById_orders_mediaOrder_documentFormat_documentFormatCollection,
  GetAvaiableDocumentFormats_orders_mediaOrder_availableDocumentFormats,
  UpdateDocumentFormat,
  UpdateDocumentFormatVariables,
} from "schema"

import Button from "components/Button"
import Templates from "./CollectionTemplates"
import Collections from "./Collections"
import {  faArrowLeft } from "@fortawesome/free-solid-svg-icons"
import UPDATE_DOCUMENT_FORMAT from "graphql/mutations/updateDocumentFormat"
import ErrorMessage from "components/ErrorMessage"
import { useTranslation } from "react-i18next"
import Modal from "components/Modal"

const FormatModal: React.FC<{
  isOpen: boolean
  onClose: () => void
  formatCollection: GetOrderById_orders_mediaOrder_documentFormat_documentFormatCollection
  orderId: string;
  currentFormatName: string
  selectedDocumentFormatId: string | undefined
}> = (props) => {
  const [t] = useTranslation("editOrder")
  const client = useApolloClient()
  const [isCollectionMode, setIsCollectionMode] = React.useState(false)
  const [collectionId, setCollectionId] = React.useState<string | null>(null)

  const { loading, error, data } = useQuery<
    GetAvaiableDocumentFormats,
    GetAvaiableDocumentFormatsVariables
  >(GET_AVAILIABLE_DOCUMENT_FORMATS, {
    client,
    context: {
      debounceKey: "document-format",
      debounceTimeout: 750,
    },
    variables: {
      orderId: props.orderId!,
      documentFormatCollectionId: collectionId ?? props.formatCollection.id,
    },
  })

  const getCollectionId = (collectionID: string) => {
    if (collectionID) {
      setCollectionId(collectionID)
      setIsCollectionMode(false)
    }
  }

  const updateDocumentFormat = useMutation<
    UpdateDocumentFormat,
    UpdateDocumentFormatVariables
  >(UPDATE_DOCUMENT_FORMAT, { client })

  let templates:
    | undefined
    | GetAvaiableDocumentFormats_orders_mediaOrder_availableDocumentFormats[]

  if (data && data.orders) {
    templates = data!.orders[0].mediaOrder?.availableDocumentFormats
  }


  if (error) {
    return(<ErrorMessage message={error.message}  />)
  }

  const handleChangeMode = () => setIsCollectionMode(true)


  return (
    <Modal
      isOpen={props.isOpen}
      onRequestClose={props.onClose}
      isLoading={loading}
      large={true}
      className={styles.templateModal}
    >
      <div className={styles.modalContainer}>
        {!isCollectionMode && (
          <>
            <Button
              icon={faArrowLeft}
              variant={"tertiary"}
              onClick={handleChangeMode}
            >
              {t("moreTemplateAdvert")}
            </Button>

            <hr className={styles.separator} />

            <Templates
              templates={templates}
              orderId={props.orderId}
              onSelectTemplate={updateDocumentFormat as any}
              closeModal={props.onClose}
              currentFormatName={props.currentFormatName}
              selectedDocumentFormatId={props.selectedDocumentFormatId}
            />
          </>
        )}

        {isCollectionMode && (
          <Collections
            orderId={props.orderId}
            getCollectionId={getCollectionId}
          />
        )}
      </div>
    </Modal>
  )
}

export default FormatModal
