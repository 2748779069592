import gql from "graphql-tag"

const GET_CURRENT_CUSTOMER_COLLEAGUES = gql`
  query GetCurrentCustomerColleagues($input: CustomerScope) {
    currentCustomer {
      id
      colleagues(customerScope: $input) {
        id
        name
        office {
          id
          name
        }
      }
    }
  }
`

export default GET_CURRENT_CUSTOMER_COLLEAGUES
