import * as React from "react"
import * as styles from "./styles.scss"
import Modal from "components/Modal"
import { GetReviewCaseFolder_caseFolder_documents_orders } from "schema"
import ProofSlider from "./ProofSlider"
import Header from "components/Header"
import { WithTranslation, withTranslation } from "react-i18next"

class CompareModal extends React.Component<
  CompareModalProps & WithTranslation
> {
  render() {
    const { isOpen, orders, onRequestClose, t } = this.props

    return (
      <Modal
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        large={true}
        className={styles.compareModal}
      >
        <Header title={t("compareOrders")} />
        <ProofSlider orders={orders} />
      </Modal>
    )
  }
}

interface CompareModalProps {
  isOpen: boolean
  orders: GetReviewCaseFolder_caseFolder_documents_orders[]
  onRequestClose: () => void
}

export default withTranslation("review")(CompareModal)
