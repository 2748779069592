import gql from "graphql-tag"
import DASHBOARD_CASE_FOLDER_FRAGMENT from "graphql/fragments/DashboardCaseFolderFragment"

const CREATE_CASE_FOLDER = gql`
  mutation CreateCaseFolder($input: MutationCreateCaseFolderInput!) {
    createCaseFolder(input: $input) {
      isError
      errorReason
      caseFolder {
        ...DashboardCaseFolderFragment
      }
    }
  }
  ${DASHBOARD_CASE_FOLDER_FRAGMENT}
`

export default CREATE_CASE_FOLDER
