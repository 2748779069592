import * as React from "react"
import * as styles from "./styles.scss"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { withTranslation, WithTranslation } from "react-i18next"

class ErrorMessage extends React.PureComponent<
  ErrorMessageProps & WithTranslation,
  ErrorMessageState
> {
  state = { extraInfoIsShown: false }

  showExtraInfo = () => this.setState({ extraInfoIsShown: true })

  render() {
    const { t, message } = this.props

    return (
      <div className={styles.errorWrapper}>
        <div className={styles.errorContainer}>
          <div className={styles.iconContainer}>
            <div onDoubleClick={this.showExtraInfo}>
              <FontAwesomeIcon
                icon="exclamation-circle"
                className={styles.icon}
              />
            </div>
            <span>OOPS!</span>
          </div>
          <div className={styles.message}>
            <span>{t("errorMessage")}</span>
            {this.state.extraInfoIsShown && <p>{message}</p>}
          </div>
        </div>
      </div>
    )
  }
}

interface ErrorMessageState {
  extraInfoIsShown: boolean
}

interface ErrorMessageProps {
  message: string
}

export const ErrorText: React.FC<{}> = (props) => {
  return <div className={styles.errorText}>{props.children}</div>
}

export default withTranslation()(ErrorMessage)
