import { Styles } from "react-select/lib/styles"

export const selectStyles: Partial<Styles> = {
  menuList: (base: any) => ({
    ...base,
    maxHeight: 186,
  }),
  valueContainer: (base: any) => ({
    ...base,
    cursor: "text",
  }),
  dropdownIndicator: (base: any) => ({
    ...base,
    cursor: "pointer",
  }),
  control: (base: any, { isFocused }: any) => ({
    ...base,
    boxShadow: "none",
    borderColor: isFocused ? "#1289a7" : "#CCC",
    ":hover": {
      borderColor: "#1289a7",
    },
  }),
  option: (base: any, { isFocused }: any) => ({
    ...base,
    backgroundColor: isFocused && "#1289a7",
    color: isFocused && "#FFF",
    cursor: "pointer",
    ":hover": {
      backgroundColor: "#1289a7",
      color: "#FFF",
    },
  }),
  clearIndicator: (base: any) => ({ ...base, cursor: "pointer" }),
  multiValueRemove: (base: any) => ({
    ...base,
    cursor: "pointer",
    ":hover": {
      backgroundColor: "#1289a7",
      color: "#FFF",
    },
  }),
}



export const FEselectStyles: Partial<Styles> = {
  menuList: (base: any) => ({
    ...base,
    maxHeight: 186,
  }),
  valueContainer: (base: any) => ({
    ...base,
    cursor: "text",
  }),
  dropdownIndicator: (base: any) => ({
    ...base,
    cursor: "pointer",
  }),
  control: (base: any, { isFocused }: any) => ({
    ...base,
    boxShadow: "none",
    borderColor: isFocused ? "#3C5E25" : "#CCC",
    ":hover": {
      borderColor: "#3C5E25",
    },
  }),
  option: (base: any, { isFocused }: any) => ({
    ...base,
    backgroundColor: isFocused && "#3C5E25",
    color: isFocused && "#FFF",
    cursor: "pointer",
    ":hover": {
      backgroundColor: "#3C5E25",
      color: "#FFF",
    },
  }),
  clearIndicator: (base: any) => ({ ...base, cursor: "pointer" }),
  multiValueRemove: (base: any) => ({
    ...base,
    cursor: "pointer",
    ":hover": {
      backgroundColor: "#3C5E25",
      color: "#FFF",
    },
  }),
}

