import * as React from "react"
import * as styles from "./styles.scss"
import Button from "components/Button"

function CommandBar(props: CommandBarProps) {
  return (
    <div className={styles.commandBar}>
      <Button onClick={props.onClick} variant="primary">
        OK
      </Button>
    </div>
  )
}

interface CommandBarProps {
  onClick: () => void
}

export default CommandBar
