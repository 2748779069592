import * as React from "react"
import * as styles from "./styles.scss"
import { Field } from "react-final-form"
import { DropdownOption } from "components/DropdownButton"
import cx from "classnames"
import { motion, AnimatePresence } from "framer-motion"
import { Translation, useTranslation } from "react-i18next"
import { Schema } from "yup"
import { set, get } from "lodash"

interface FieldWrapperProps {
  label?: string
  renderLabel?: () => JSX.Element
  className?: string
  name: string
  hint?: string
  placeholder?: string
  type?: string
  marginTop?: boolean
  autoFocus?: boolean
  showFieldWrapperLabel?: boolean
  parseNullAsWhitespace?: boolean
  dropdownOptions?: DropdownOption[]
  includeDates?: Date[]
  onDateSelect?: (date: Date) => void
  component: React.ComponentClass<any, any> | React.StatelessComponent<any>;
  id?: string
}

export const FieldWrapper: React.FC<FieldWrapperProps> = (props) => {
  const {
    component: Component,
    name,
    label,
    type,
    showFieldWrapperLabel,
    className,
    marginTop,
    parseNullAsWhitespace,
    renderLabel,
    ...rest
  } = props

  const [t] = useTranslation("errorMessages")

  return (
    <Field
      name={name}
      type={type}
      parse={
        parseNullAsWhitespace ? (val) => (val === null ? "" : val) : undefined
      }
      render={(renderProps) => (
        <FieldInputWrapper marginTop={marginTop} className={className}>
          {showFieldWrapperLabel !== undefined &&
          showFieldWrapperLabel === false &&
          label ? (
            <></>
          ) : (
            <FieldLabel text={label} />
          )}
          {renderLabel !== undefined ? renderLabel() : null}

          <Component {...renderProps} {...rest} type={type} label={label} id={props.id} />

          {renderProps.meta.submitError && (
            <FieldError text={t(renderProps.meta.submitError)} />
          )}
        </FieldInputWrapper>
      )}
    />
  )
}

interface FieldWrapperFormikProps {
  value: any
  onChange: any
  errors: any
  label?: string
  className?: string
  name: string
  hint?: string
  placeholder?: string
  type?: string
  marginTop?: boolean
  autoFocus?: boolean
  showFieldWrapperLabel?: boolean
  parseNullAsWhitespace?: boolean
  dropdownOptions?: DropdownOption[]
  includeDates?: Date[]
  onDateSelect?: (date: Date) => void
  component?: React.ComponentClass<any, any> | React.FC<any>
  id?: string
}

export const FieldWrapperFormik: React.FC<FieldWrapperFormikProps> = (
  props
) => {
  const {
    // component: Component,
    errors,
    label,
    showFieldWrapperLabel,
    className,
    marginTop,
    ...rest
  } = props

  const [t] = useTranslation("errorMessages")
  const showLabel =
    showFieldWrapperLabel !== undefined &&
    showFieldWrapperLabel === false &&
    label

  const childrenWithProps = React.Children.map(props.children, (child) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, {
        label: showLabel ? label : undefined,
        id: props.id,
        ...rest,
      })
    }
    return child
  })

  return (
    <FieldInputWrapper marginTop={marginTop} className={className}>
      {showLabel ? <></> : <FieldLabel text={label} />}

      <>
        {props.component !== undefined
          ? React.createElement(props.component, {
              label: showLabel ? label : undefined,
              id: props.id,
              ...rest,
            })
          : null}

        {childrenWithProps}
      </>

      {/* <Component {...rest} label={showLabel ? label : undefined} /> */}

      {errors && <FieldError text={t(errors)} />}
    </FieldInputWrapper>
  )
}

export const FieldInputWrapper: React.FC<{
  marginTop?: boolean
  className?: string
}> = (props) => {
  return (
    <div
      className={cx(styles.fieldWrapper, props.className, {
        [styles.marginTop]: props.marginTop,
      })}
    >
      {props.children}
    </div>
  )
}

export const FieldLabel: React.FC<{ text?: string; className?: any }> = (
  props
) => {
  if (!props.text) {
    return <></>
  }
  return <p className={cx(styles.label, props.className)}>{props.text}</p>
}

export const FieldError: React.FC<{
  text: string | string[] | undefined
}> = (props) => {
  return (
    <AnimatePresence>
      {props.text && props.text.length > 0 ? (
        <motion.div
          className={styles.errors}
          key="error-message"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -20 }}
        >
          {Array.isArray(props.text) ? props.text.join(",") : props.text}
        </motion.div>
      ) : (
        <></>
      )}
    </AnimatePresence>
  )
}

export const FieldSuccess: React.FC<{
  text: string | string[] | undefined
}> = (props) => {
  return (
    <AnimatePresence>
      {props.text && props.text.length > 0 ? (
        <motion.div
          className={styles.success}
          key="success-message"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -20 }}
        >
          {Array.isArray(props.text) ? props.text.join(",") : props.text}
        </motion.div>
      ) : (
        <></>
      )}
    </AnimatePresence>
  )
}

export function makeValidate<T>(validator: Schema<T>) {
  return async (values: T) => {
    try {
      await validator.validate(values, { abortEarly: false })
    } catch (err) {
      return err.inner.reduce((errors: any, { path, message }: any) => {
        if (errors.hasOwnProperty(path)) {
          set(errors, path, get(errors, path) + " " + message)
        } else {
          set(errors, path, message)
        }
        return errors
      }, {})
    }
  }
}

export default FieldWrapper
