import * as React from "react"
import * as styles from "./styles.scss"
import { WithTranslation, withTranslation } from "react-i18next"
import ReactTable from "react-table-6"
import {
  GetCalendarOrders_calendarOrdersByCurrentCustomer_orders,
  GetCalendarOrders_calendarOrdersByCurrentCustomer,
} from "schema"
import Status from "components/Status"
import { withRouter, RouteComponentProps } from "react-router-dom"
import * as cx from "classnames"
import SelectedDate from "./SelectedDate"
import ReactTooltip from "components/Tooltip"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { divide } from "lodash"
import moment from "moment"
import Marquee from "react-fast-marquee";
import 'moment/locale/sv';
import 'moment/locale/nb';
import i18n from "i18n"

class PublishingsByDateTable extends React.PureComponent<
  RouteComponentProps & PublishingsByDateTableProps & WithTranslation
> {
  selectCaseFolderId = (caseFolderId: string, orderId?: string) =>
    this.props.history.push(`/editOrder/c/${caseFolderId}/o/${orderId}`)

  render() {
    const { selectedWeek, isWeekSelected, orders, t } = this.props

    return (
      <div className={styles.publishingsByDateTableContainer}>
        <SelectedDate
          selectedWeek={selectedWeek}
          selectDay={this.props.selectDay}
          isWeekSelected={isWeekSelected}
        />
        <ReactTable
          className={cx("", styles.table)}
          data={orders ?? []}
          columns={[
            {
              Header: t("name"),
              accessor: "document.caseFolder",
              Cell: (props) => {
                const date = moment(props.original.orderDate)

                return (
                  <div className={styles.nameColumn}>
                    <span>{props.value.name}</span> <br />
                    <div>
                      <FontAwesomeIcon icon={"clock"} />
                      <span>
                        {t(`dateFormats:${date.locale(i18n.language).format("dddd")}`)} {" "}
                        {date.format("DD")}{" "}
                        {t(`dateFormats:${date.locale(i18n.language).format("MMM")}`)}{" "}
                      </span>
                    </div>
                  </div>
                )
              },
            },
            {
              Header: t("media"),
              accessor: "mediaOrder.media.friendlyName",
              Cell: (props) => {

                return (
                  <div className={styles.mediaWrapper}>
                    <ReactTooltip
                      text={
                        props.original.alerting.text.length > 0
                          ? props.original.alerting.text
                          : ""
                      }
                      id={"publishColum"}
                    >
                      {props.original.alerting.text.length > 0 && (
                        <div style={{ color: "#fe6b0b", marginRight: "3px" }}>
                          <FontAwesomeIcon icon="exclamation-triangle" />
                        </div>
                      )}
                    </ReactTooltip>

                    {props.value.length > 20  ? (
                      <Marquee
                        speed={90}
                        gradient={false}
                        style={{
                           height: "auto", 
                           display: "flex", 
                           alignItems:"flex-start",
                          }}
                        play={false}
                      >
                       <span className={styles.marquee}>{props.value && props.value + new Array(1 + 1).join("  __  ")}</span>
                      </Marquee>
                    ) : (
                      <span>{props.value}</span>
                    )}
                  </div>
                )
              },
            },
            {
              Header: t("status"),
              accessor: "mediaOrder.status",
              Cell: (props) => {
                return (
                  <>
                    <ReactTooltip id={"tableStatus"} text={t(`statuses:${props.value.status}`)}>
                        <Status variant={props.value.color!}></Status>
                    </ReactTooltip>
                  </>
                )
              },
              style: {
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "flex-start",
                paddingTop: "15px",
                marginLeft: 0,
              },
              width: 60,
            },
            // {
            //   id: "editLink",
            //   Cell: props => (
            //     <ShowCaseFolderLink
            //       caseFolderId={props.original.document.caseFolder.id}
            //       orderId={props.original.id}
            //     />
            //   ),
            //   width: 110,
            // },
          ]}
          getTdProps={(state: any, rowInfo: any, column: any) => {
            let props: any = {}
            if (column.id !== "editLink") {
              props.onClick = () =>
                this.selectCaseFolderId(
                  rowInfo.original.document.caseFolder.id,
                  rowInfo.original.id
                )
              props.style = { cursor: "pointer" }
            }

            return props
          }}
          getTbodyProps={() => ({ className: styles.tbody })}
          getTrGroupProps={() => ({ className: styles.tRow })}
          resizable={false}
          showPagination={false}
          noDataText={t("common:noResults")}
          pageSize={orders.length}
        />
      </div>
    )
  }
}

interface PublishingsByDateTableProps {
  selectedWeek?: number | any
  orders: GetCalendarOrders_calendarOrdersByCurrentCustomer_orders[]
  selectDay?: Date
  isWeekSelected: boolean
}

export default withTranslation(["dashboard", "statuses"])(
  withRouter(PublishingsByDateTable)
)
