import * as React from "react"
import CaseFolderForm from "components/CaseFolderForm"
import HeaderRow from "./HeaderRow"
import { MutationFn } from "react-apollo"
import { GetCaseFolders_findCaseFoldersByCurrentCustomer_caseFolders } from "schema"
import { useApolloClient, useMutation } from "react-apollo-hooks"
import { DataProxy } from "apollo-cache"
import GET_DASHBOARD_CASE_FOLDERS from "graphql/queries/getDashboardCaseFolders"
import UPDATE_CASE_FOLDER from "graphql/mutations/updateCaseFolder"
import DELETE_CASE_FOLDER from "graphql/mutations/deleteCaseFolder"
import produce from "immer"



const EditCaseFolderForm: React.FC<EditCaseFolderFormProps> = (props) => {
  const client = useApolloClient()

  // const updateCacheAfterDelete = (cache: DataProxy) => {
  //   const { findCaseFoldersByCurrentCustomer }: any = cache.readQuery({
  //     query: GET_DASHBOARD_CASE_FOLDERS,
  //   })
  //   const caseFolders: GetCaseFolders_findCaseFoldersByCurrentCustomer_caseFolders[] =
  //     findCaseFoldersByCurrentCustomer.caseFolders
  //   cache.writeQuery({
  //     query: GET_DASHBOARD_CASE_FOLDERS,
  //     data: {
  //       findCaseFoldersByCurrentCustomer: {
  //         ...findCaseFoldersByCurrentCustomer,
  //         caseFolders: caseFolders.filter(
  //           (caseFolder) => caseFolder.id !== props.selectedCaseFolder.id
  //         ),
  //       },
  //     },
  //   })
  // }

  const updateCaseFolder = useMutation(UPDATE_CASE_FOLDER, {
    client
  })

  // const deleteCaseFolder = useMutation(DELETE_CASE_FOLDER, {
  //   client,
  //   update: updateCacheAfterDelete,
  // })

  const renderHeaderRow = () => {
    return (
      <HeaderRow
        onDelete={props.deleteCaseFolder as any}
        selectedCaseFolderId={props.selectedCaseFolder.id!}
        isLoading={false}
      />
    )
  }

  return (
    <CaseFolderForm
      renderHeaderRow={renderHeaderRow}
      isActive={true}
      onComplete={props.onCompleted}
      selectedCaseFolder={props.selectedCaseFolder}
      onSubmit={updateCaseFolder as any}
      columnCount={2}
    />
  )
}

interface EditCaseFolderFormProps {
  // onSubmit: MutationFn
  // onDelete: MutationFn
  onCompleted: any
  selectedCaseFolder: GetCaseFolders_findCaseFoldersByCurrentCustomer_caseFolders
  // isLoading: boolean
  // onToggleLoadingOn: () => void
  // onToggleLoadingOff: () => void
  deleteCaseFolder: any
}

export default EditCaseFolderForm


// class EditCaseFolderForm extends React.PureComponent<EditCaseFolderFormProps> {
//   renderHeaderRow = () => {
//     const { selectedCaseFolder, onDelete } = this.props

//     return (
//       <HeaderRow
//         onDelete={onDelete}
//         selectedCaseFolderId={selectedCaseFolder.id!}
//         isLoading={false}
//       />
//     )
//   }

//   render() {
//     const {
//       onDelete,
//       // onToggleLoadingOn, isLoading,
//       onSubmit,
//     } = this.props

//     return (
//       <CaseFolderForm
//         renderHeaderRow={this.renderHeaderRow}
//         isActive={true}
//         onComplete={}
//         onSubmit={onSubmit}
//         columnCount={2}
//       />
//     )
//   }
// }