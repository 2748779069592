import * as React from "react"
import * as styles from "./styles.scss"
import { OrderEditorAreaContext } from "../.."
import { useTranslation } from "react-i18next"
import { DropdownOption } from "components/DropdownButton"
import OptionDropdown from "components/OptionDropdown"
import {
  faMinusCircle,
  faPlusCircle,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useFormik } from "formik"
import Button from "components/Button"

const FontFamilyDropdown: React.FC<{}> = (props) => {
  const [t] = useTranslation("editOrder")
  const { editor, setFontFamily, contentRows } = React.useContext(
    OrderEditorAreaContext
  )
  const [isCustomOption, setIsCustomOption] = React.useState(false)

  const handleSetFontFamily = (option: DropdownOption) => {
    if (option.value === "Custom") setIsCustomOption(true)
    else setFontFamily(option.value)
  }

  let fontFamilyValue: string | null = null
  if (
    contentRows !== undefined &&
    contentRows[0] !== undefined &&
    contentRows[0].columns !== undefined &&
    contentRows[0].columns[0] !== undefined &&
    contentRows[0].columns[0].item !== undefined &&
    contentRows[0].columns[0].item.text !== undefined &&
    contentRows[0].columns[0].item.text !== null &&
    contentRows[0].columns[0].item.text.fontFamily !== undefined &&
    contentRows[0].columns[0].item.text.fontFamily !== null &&
    contentRows[0].columns[0].item.text.fontFamily.length > 0
  ) {
    fontFamilyValue = contentRows[0].columns[0].item.text.fontFamily
  }

  const allowedFontFamilies: { label: string; value: string }[] = []
  if (
    editor &&
    editor.text !== undefined &&
    editor.text !== null &&
    editor.text.allowedFontFamilies !== undefined &&
    editor.text.allowedFontFamilies !== null
  ) {
    editor.text.allowedFontFamilies.forEach((val) => {
      if (val !== undefined && val !== null && val.length > 0) {
        allowedFontFamilies.push({
          label: val.toString(),
          value: val,
        })
      }
    })
  }

  let initialValues: { font: string } = {
    font: fontFamilyValue ?? "",
  }

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    onSubmit: (values) => {
      setFontFamily(values.font!)
      setIsCustomOption(false)
    },
  })

  const onClear = () => {
    setFontFamily(null)
    setIsCustomOption(false)
  }

  return (
    <div className={styles.fontFamilyDropdown}>
      {fontFamilyValue !== null ? (
        <div className={styles.removeIcon} onClick={onClear}>
          <FontAwesomeIcon icon={faMinusCircle} color={"#e6e6e6"} />
        </div>
      ) : null}

      {isCustomOption ? (
        <form onSubmit={formik.handleSubmit} className={styles.fontFormWrapper}>
          <input
            type="text"
            value={formik.values.font}
            name="font"
            onChange={formik.handleChange}
          />
          <div className={styles.btnWrapper}>
            <Button
              onClick={() => setIsCustomOption(false)}
              variant={"tertiary"}
            >
              Tillback
            </Button>
            <Button
              disabled={formik.values.font!.length === 0}
              color={"gray"}
              icon={faPlusCircle}
              variant={"tertiary"}
              type="submit"
            >
              Lägg till
            </Button>
          </div>
        </form>
      ) : (
        <>
          <b>{t("font")}</b>
          <OptionDropdown
            options={allowedFontFamilies}
            togglerClassName={styles.toggler}
            className={styles.dropdown}
            onChange={handleSetFontFamily}
            optionListClassName={styles.options}
            selectedLabel={fontFamilyValue ?? undefined}
          />
        </>
      )}
    </div>
  )
}

export default FontFamilyDropdown
