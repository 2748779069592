import * as React from "react"
import * as styles from "./styles.scss"
import Whiteframe from "components/Whiteframe"
import Spinner from "components/Spinner"

class Loading extends React.Component {
  render() {
    return (
      <div className={styles.loadingContainer}>
        <Whiteframe className={styles.whiteframe}>
          <Spinner />
        </Whiteframe>
        <div className={styles.filler} />
      </div>
    )
  }
}

export default Loading
