import * as React from "react"
import * as styles from "./styles.scss"
import { Tab as TabInterface } from "../../"
import cx from "classnames"
import { StatusCircle } from "components/Status"
import styled from "styled-components"
import {
  GetEditOrderPageCaseFolder_caseFolder_editTabs_orders_alerting,
  OrderAlertingType,
} from "schema"
import ReactTooltip from "react-tooltip"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const Tab: React.FC<TabProps> = (props) => {
  const handleClick = () => {
    props.onClick(props.tab.value)
  }

  const {
    tab,
    isSelected,
    className,
    selectedClassName,
    variant,
    showStatusDots,
    alerting,
  } = props

  React.useEffect(() => {
    ReactTooltip.rebuild()
  }, [props])

  return (
    <div>
      <div
        className={cx(styles.tab, className, styles[`variant-${variant}`], {
          [styles.selected]: isSelected,
          [selectedClassName!]: isSelected && selectedClassName,
        })}
        style={{ paddingRight: showStatusDots === true ? 34 : undefined }}
        onClick={handleClick}
        data-tip={JSON.stringify({ alerting, status: tab.status })}
        data-for={"tab-alerts"}
        data-effect={"solid"}
        data-place={"top"}
        data-type={"light"}
        data-offset="{'top': -10}"
        data-border={true}
        data-background-color={"#fff"}
        data-text-color={"#FF5327"}
        data-border-color={"#D8D8D8"}
        data-arrow-color={"#D8D8D8"}
        data-delay-update="1000"
        data-tip-disable={
          alerting === undefined ||
          alerting?.type === undefined ||
          alerting?.type === OrderAlertingType.NONE
        }
      >
        {tab.label}
        {alerting?.type != OrderAlertingType.NONE && showStatusDots ? (
          <div style={{position: 'absolute', right: '12px', bottom:'35%'}}>
            <FontAwesomeIcon icon="exclamation-triangle" color={"#c57070"} size={"sm"}/>
          </div>
        ) : showStatusDots ? (
          <>
            {tab.statusColor ? (
              <Styles>
                <div className={"dot"}>
                  <StatusCircle variant={tab.statusColor} withBorder={true} />
                </div>
              </Styles>
            ) : null}
          </>
        ) : null}
        

        {}
      </div>
    </div>
  )
}

const Styles = styled.div`
  .dot {
    position: absolute;
    top: 0;
    right: 12px;
    width: 9px;
    height: 100%;
    display: grid;
    align-items: center;
    span {
      display: table;
      width: 9px;
      height: 9px;
      margin-right: 0;
    }
  }
`

interface TabProps {
  tab: TabInterface
  onClick: (value: any) => void
  isSelected: boolean
  className?: string
  selectedClassName?: string
  variant: string
  showStatusDots?: boolean
  alerting?: GetEditOrderPageCaseFolder_caseFolder_editTabs_orders_alerting
}

export default Tab
