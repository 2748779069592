import React from "react"
import styles from "./styles.scss"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Button from "components/Button"
import { faColumns } from "@fortawesome/free-solid-svg-icons"
import FormatModal from "./FormatModal"
import { GetOrderById_orders_mediaOrder_documentFormat_documentFormatCollection } from "schema"
import cx from "classnames"
import { useTranslation } from "react-i18next"

const FormatButton: React.FC<{
  formatCollection: GetOrderById_orders_mediaOrder_documentFormat_documentFormatCollection
  orderId: string
  currentFormatName: string
  className?: string
  selectedDocumentFormatId: string | undefined
}> = (props) => {
  const [t] = useTranslation("editOrder")
  const [isOpenFormatModal, setIsOpenFormatModal] = React.useState<boolean>(
    false
  )

  const onOpenModal = () => setIsOpenFormatModal(true)
  const isCloseModal = () => setIsOpenFormatModal(false)

  return (
    <>
      <div className={cx(styles.formatButtonContainer, props.className)}>
        <button onClick={onOpenModal} className="button">
          <span>{t("changeTemplate")}</span>
          <FontAwesomeIcon icon={faColumns} />
        </button>
      </div>
      {isOpenFormatModal && (
        <FormatModal
          isOpen={isOpenFormatModal}
          onClose={isCloseModal}
          formatCollection={props.formatCollection}
          orderId={props.orderId}
          currentFormatName={props.currentFormatName}
          selectedDocumentFormatId={props.selectedDocumentFormatId}
        />
      )}
    </>
  )
}

export default FormatButton
