import Modal from "components/Modal"
import GET_PROOF_MEMORIZ_CANDLES_THEME from "graphql/mutations/getProofMemorizCandlesThemes"
import GET_PROOF_MEMORIZ_CANDLES_URl from "graphql/mutations/getProofMemorizCandlesUrl"
import React from "react"
import { useApolloClient, useMutation } from "react-apollo-hooks"
import { useTranslation } from "react-i18next"
import {
  GetProofMemorizCandlesThemes,
  GetProofMemorizCandlesThemesVariables,
  GetProofMemorizCandlesUrl,
  GetProofMemorizCandlesUrlVariables,
} from "schema"
import * as styles from "./styles.scss"


const CandlesModal: React.FC<{
  isOpen: boolean
  onClose: () => void
  orderId: string
}> = ({ isOpen, onClose, orderId }) => {
  const client = useApolloClient()
  const [themes, setThemes] = React.useState<Array<IGetTheme>>()
  const [isLoading, setIsloading] = React.useState<boolean>(false)
  const [mutationError, setMutationError] = React.useState<string>()
  const { t } = useTranslation()

  const mutationGetTheme = useMutation<
    GetProofMemorizCandlesThemes,
    GetProofMemorizCandlesThemesVariables
  >(GET_PROOF_MEMORIZ_CANDLES_THEME, { client })

  const mutationGetUrl = useMutation<
    GetProofMemorizCandlesUrl,
    GetProofMemorizCandlesUrlVariables
  >(GET_PROOF_MEMORIZ_CANDLES_URl, { client })

  React.useEffect(() => {
    setIsloading(true)
    mutationGetTheme({
      variables: {
        orderID: orderId,
      },
    })
      .then((res) => {
        const data = res.data?.getProofMemorizCandlesThemes
        if (!data) {
          setMutationError(t("errorMessages:unknowError"))
          return
        }
        if (data) {
          setThemes(data)
          setIsloading(false)
        }
      })
      .catch((error) => {
        setMutationError(error.toString())
      })
  }, [])

  const handleGetCandleUrl = (theme: string) => {
    setIsloading(true)
    mutationGetUrl({
      variables: {
        orderID: orderId,
        theme: theme,
      },
    })
      .then((res) => {
        const pdfUrl = res.data?.getProofMemorizCandlesUrl
        if (!pdfUrl) {
          setMutationError(t("errorMessages:unknowError"))
          return
        }
        if (res.errors) {
          setMutationError(res.errors.map((e) => e.message).join(", "))
        }
        if (pdfUrl) {
          window.open(pdfUrl, "_blank")
          setIsloading(false)
        }
      })
      .catch((error) => {
        setMutationError(error.toString())
      })
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      isLoading={isLoading}
      isMemoryroom={true}
    >
      <h3>Välj utseende</h3>
      <div className={styles.container}>
        
        <div className={styles.content}>
          <ul>
            {themes
              ? themes.map((theme , i) => {
                  return (
                    <li
                      key={i}
                      className={styles.card}
                      onClick={() => handleGetCandleUrl(theme.id)}
                    >
                      <img src={theme.imageUrl} alt="" />
                    </li>
                  )
                })
              : null}
          </ul>
        </div>
        <span className={styles.errorMsg}>{mutationError}</span>
      </div>
    </Modal>
  )
}

interface IGetTheme {
  id: string
  name: string
  imageUrl: any
  description: string | null
}

export default CandlesModal

{
  /* <div className={styles.cardFooter}>
                        <span>{theme.name}</span>
                        <span
                          className={styles.downloadIcon}
                          onClick={() => handleGetCandleUrl(theme.id)}
                        >
                          <FontAwesomeIcon icon={faDownload} />
                        </span>
                      </div> */
}
