import * as React from "react"
import * as styles from "./styles.scss"
import Slider from "components/Slider"
import { GetReviewCaseFolder_caseFolder_documents_orders } from "schema"
import { chunk } from "utils"
import OrderCard from "./OrderCard"

const ProofSlider: React.FC<ProofSliderProps> = (props) => {
  const [currentSlideIndex, setCurrentSlideIndex] = React.useState(0)
  const [orderLength] = React.useState(props.orders.length)


  const nextSlide = () => setCurrentSlideIndex(currentSlideIndex + 1)

  const prevSlide = () => setCurrentSlideIndex(currentSlideIndex - 1)

  const isNextButtonDisabled = (slideCount: number) =>
    slideCount === currentSlideIndex + 1

  const orderChunks: GetReviewCaseFolder_caseFolder_documents_orders[][] = chunk(
    props.orders,
    3
  )

  return (
    <>
      <Slider
        nextSlide={nextSlide}
        prevSlide={prevSlide}
        currentSlideIndex={currentSlideIndex}
        disableDots={true}
        isNextButtonDisabled={isNextButtonDisabled}
        orderLength={orderLength}
      >
        {orderChunks.map((orderChunk) => (
          <div
            key={orderChunk[0].id!}
            className={styles.slideContainer}
            // ref={elRef}
          >
            {orderChunk.map((order) => (
              <OrderCard
                key={order.id!}
                order={order}
                chunkLength={orderChunk.length}
              />
            ))}
            {orderChunk.length === 1 && <div className={styles.filler} />}
          </div>
        ))}
      </Slider>

      {/* <div className={styles.backbtn} >
        <span onClick={this.props.onRequestClose}>
        <FontAwesomeIcon icon={faArrowAltCircleLeft} />
          <span>Tillbaka Till Publicera</span>  
        </span>
      </div> */}
    </>
  )
}

// interface ProofSliderState {
//   currentSlideIndex: number
// }

interface ProofSliderProps {
  orders: GetReviewCaseFolder_caseFolder_documents_orders[]
  // onRequestClose: () => void
}

export default ProofSlider
