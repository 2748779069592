import * as React from "react"
import * as styles from "./styles.scss"
import { DropdownOption } from "components/DropdownButton"
import cx from "classnames"

class Option extends React.PureComponent<OptionProps> {
  handleClick = () => this.props.onClick(this.props.option)

  handleMouseOver = () => {
    if (this.props.onHover) this.props.onHover(this.props.option.value)
  }

  handleMouseLeave = () => {
    if (this.props.onHover) this.props.onHover(undefined)
  }

  render() {
    const { defaultValue, option, selectedValue } = this.props

    const isBold = selectedValue ? selectedValue === option.value : defaultValue === option.value 



    return (
      <li
        onClick={this.handleClick}
        className={cx(styles.option, {
          [styles.selected]: isBold,
          [styles.feView__option]: this.props.isFEUser
        })}
        onMouseOver={this.handleMouseOver}
        onMouseLeave={this.handleMouseLeave}
      >
        {this.props.option.label}
      </li>
    )
  }
}

interface OptionProps {
  option: DropdownOption
  onClick: (option: DropdownOption) => void
  onHover?: (id?: string) => void
  currentDefaultValue?: any
  defaultValue?: number
  isFontSize?: boolean
  selectedValue?: number | null
  isFEUser?: boolean
}

export default Option
