import * as React from "react"
import * as styles from "./styles.scss"
import Offices from "components/Offices"
import Header from "components/Header"
import { WithTranslation, withTranslation } from "react-i18next"
import { AppContext } from "App"

class OfficeSelectionForm extends React.Component<WithTranslation> {
  render() {
    const { t } = this.props

    return (
      <AppContext.Consumer>
        {({ currentUserSession }) => (
          <div className={styles.container}>
            <Header
              title={`${t("welcome")} ${currentUserSession.customer!.name}!`}
              subtitle={t("whichOffice")}
            />
            <Offices redirectToAfterClick="/dashboard" />
          </div>
        )}
      </AppContext.Consumer>
    )
  }
}

export default withTranslation("offices")(OfficeSelectionForm)
