import * as React from "react"
import * as styles from "./styles.scss"
import Status from "components/Status"
import { SupportViewContext, OpenTab } from "components/SupportView"
import {
  GetAdminCaseFolders_findCaseFoldersByConditions_caseFolders_documents_orders,
  GetAdminCaseFolders_findCaseFoldersByConditions_caseFolders,
} from "schema"
import Tooltip from "components/Tooltip"
import { useTranslation } from "react-i18next"
import { copyToClipboard } from "utils"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

function OrderRow(props: OrderRowProps) {
  const { openTab, selectedOpenTab, openOrderDetails } = React.useContext(
    SupportViewContext
  )
  const [t] = useTranslation("statuses")

  // const {
  //   order: { idString, customerFriendlyId, mediaOrder },
  //   caseFolder,
  // } = props
  // const { status, media } = mediaOrder!

  const newTab: OpenTab = {
    caseFolderId: props.caseFolderId,
    documentId: props.documentId,
    orderId: props.orderId,
    customerFriendlyId: props.customerFriendlyId,
    customerId: props.customerId,
    type: "order",
    status:
      props.order && props.order.mediaOrder && props.order.mediaOrder.status
        ? props.order.mediaOrder.status
        : undefined,
  }

  const handleOpenTab = () => {
    openOrderDetails(newTab)
    openTab(newTab, props.caseFolder.customer.tempSessionToken)
  }

  // const handleCopyToClipboard = (e: React.MouseEvent) =>
  //   copyToClipboard("tapmin_order_id")

  const handleOpenDetails = () => {
    openOrderDetails(newTab)
  }

  return (
    <div className={styles.orderRow}>
      <div className={styles.flexContainer}>
        <span
          className={styles.orderId}
          id="tapmin_order_id"
          // onClick={handleCopyToClipboard}
          onClick={handleOpenDetails}
        >
          {selectedOpenTab &&
            selectedOpenTab.orderId === props.order.idString && (
              <FontAwesomeIcon icon="caret-right" className={styles.icon} />
            )}
          {props.customerFriendlyId}
        </span>

        {props.order &&
        props.order.mediaOrder &&
        props.order.mediaOrder.status ? (
          <Tooltip
            text={t(props.order.mediaOrder.status.status)}
            id={props.order.idString!}
          >
            <Status variant={props.order.mediaOrder.status.color} />
          </Tooltip>
        ) : null}
      </div>
      <div className={styles.mediaNameContainer}>
        <span onClick={handleOpenDetails} className={styles.mediaName}>
          {props.order.mediaOrder ? props.order.mediaOrder.media.friendlyName : ""}
        </span>
      </div>
      <div
        // onClick={handleOpenDetails}
        onClick={handleOpenTab}
      >
        <FontAwesomeIcon
          icon="folder-open"
          className={styles.openDetailsIcon}
        />
      </div>
    </div>
  )
}

interface OrderRowProps {
  documentId: string
  caseFolderId: string
  orderId: string
  customerId: string
  customerFriendlyId: string
  order: GetAdminCaseFolders_findCaseFoldersByConditions_caseFolders_documents_orders
  caseFolder: GetAdminCaseFolders_findCaseFoldersByConditions_caseFolders
}

export default OrderRow
