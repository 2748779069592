import * as React from "react"
import CheckboxField from "components/CheckboxField"

class Product extends React.PureComponent<ProductProps> {
  handleChange = () => this.props.onToggleCheckbox(this.props.id)

  render() {
    const { products, id } = this.props

    return (
      <CheckboxField
        label={products[id].label}
        checked={products[id].checked}
        onChange={this.handleChange}
      />
    )
  }
}

interface ProductProps {
  id: string
  products: any
  onToggleCheckbox: (id: string) => void
}

export default Product
