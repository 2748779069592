import gql from "graphql-tag"

const MUTATION_DELETE_USER = gql`
  mutation MutationDeleteUser($input: MutationDeleteUserInput!) {
    deleteUser(input: $input) {
      isError
    }
  }
`

export default MUTATION_DELETE_USER
