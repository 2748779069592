import gql from "graphql-tag"
import ORDER_EDITOR_CONTENT_VALUE_FRAGMENT from "graphql/fragments/OrderEditorContentValueFragment"
import PREVIEW_IMAGE_FRAGMENT from "graphql/fragments/PreviewImageFragment"
import RECTANGLE_FRAGMENT from "graphql/fragments/RectangleFragment"

const UNDO_CHANGE = gql`
  mutation UndoChange($input: MutatonUpdateOrdersContentFromHistoryInput!) {
    updateOrdersContentFromHistory(input: $input) {
      orders {
        id
        mediaOrder {
          edit {
            editModelAdvert {
              basePage {
                areas {
                  id
                  content {
                    value {
                      ...OrderEditorContentValueFragment
                    }
                  }
                  ...RectangleFragment
                }
              }
            }
            history {
              countStepBack
            }
          }
          preview {
            ...PreviewImageFragment
          }
        }
      }
    }
  }
  ${PREVIEW_IMAGE_FRAGMENT}
  ${ORDER_EDITOR_CONTENT_VALUE_FRAGMENT}
  ${RECTANGLE_FRAGMENT}
`

export default UNDO_CHANGE
