import * as React from "react"
import "react-datepicker/dist/react-datepicker-cssmodules.css"
import Calendar from "components/Calendar"
import { FieldRenderProps } from "react-final-form"

class DateField extends React.PureComponent<
  DateFieldProps & FieldRenderProps<any, any>
> {
  handleChange = (date: Date) => {
    const { onDateSelect, input } = this.props

    input.onChange(date)
    if (onDateSelect) onDateSelect(date)
  }

  render() {
    const { includeDates, onDateSelect, ...rest } = this.props
    return (
      <Calendar
        id={this.props.id}
        fieldRenderProps={rest}
        onChange={this.handleChange}
        includeDates={includeDates}
        selectedDate={
          rest.input.value === "" ? null : new Date(rest.input.value)
        }
      />
    )
  }
}

interface DateFieldProps {
  includeDates?: Date[]
  onDateSelect?: (date: Date) => void
  id?:string
}

export default DateField
