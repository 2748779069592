import * as React from "react"
import * as styles from "./styles.scss"
import Modal from "components/Modal"
import Offices from "components/Offices"
import { withTranslation, WithTranslation } from "react-i18next"

class NavbarChangeOfficeModal extends React.Component<
  NavbarChangeOfficeModalProps & WithTranslation
> {
  render() {
    const { onRequestClose, isOpen, t } = this.props

    return (
      <Modal
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        className={styles.modal}
      >
        <h2 className={styles.header}>{t("changeOffice")}</h2>
        <Offices
          afterClick={onRequestClose}
          redirectToAfterClick="/dashboard/reload/"
        />
      </Modal>
    )
  }
}

interface NavbarChangeOfficeModalProps {
  isOpen: boolean
  onRequestClose: () => void
}

export default withTranslation("navbar")(NavbarChangeOfficeModal)
