import * as React from "react"
import * as styles from "./styles.scss"
import Modal from "components/Modal"
import Header from "components/Header"
import ControlBar from "./ControlBar"
import { useTranslation } from "react-i18next"
import Favorite from "./Favorite"
import {
  GetGalleryImages_galleryImagesByTags_images,
  GetGalleryImageInformation_galleryImages,
} from "schema"

function AddToFavoriteModal(props: AddToFavoriteModalProps) {
  const [selectedFavorite, setSelectedFavorite] = React.useState(
    undefined as any
  )
  const [t] = useTranslation()

  React.useEffect(() => {
    if (!props.isOpen) setSelectedFavorite(undefined)
  }, [props.isOpen])

  const handleSetSelectedFavorite = (favorite: string) =>
    setSelectedFavorite(favorite)

  const handleFetchSymbolInfo = () =>
    props.onFetchSymbolInfo(props.selectedSymbol.id!)

  return (
    <Modal
      isOpen={props.isOpen}
      onRequestClose={props.onClose}
      isModalInModal={true}
      isFEUser={props.isFEUser}
    >
      <Header title={t("saveToList")} />
      <div className={styles.favorites}>
        {props.companyTags.map(tag => (
          <Favorite
            key={tag}
            name={tag}
            onSelect={handleSetSelectedFavorite}
            isSelected={tag === selectedFavorite}
            isFEUser={props.isFEUser}
          />
        ))}
      </div>
      <ControlBar
        selectedFavorite={selectedFavorite}
        symbolsToAdd={props.symbolsToAdd}
        onFetchSymbolInfo={handleFetchSymbolInfo}
        onClose={props.onClose}
        isFEUser={props.isFEUser}
      />
    </Modal>
  )
}

interface AddToFavoriteModalProps {
  isOpen: boolean
  symbolsToAdd: GetGalleryImages_galleryImagesByTags_images[]
  companyTags: string[]
  selectedSymbol: GetGalleryImageInformation_galleryImages
  onClose: () => void
  onFetchSymbolInfo: (symbolId: string, preventSelect?: boolean) => void
  isFEUser: boolean
}

export default AddToFavoriteModal
