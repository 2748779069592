import * as React from "react"
import ConversationButton from "../ConversationButton"
import ConversationInput from "../ConversationInputs"
import { useTranslation } from "react-i18next"

const LateCodeButton: React.FC<{
  codeLate: string
  isEdit: boolean
}> = (props) => {
  const [t] = useTranslation("review")
  const [value, setValue] = React.useState("")
  const [isOpen, setIsOpen] = React.useState(false)

  const toggleOpen = () => {
    setIsOpen(!isOpen)
  }

  return (
    <div>
      {isOpen ? (
        <ConversationInput
          placeholder={t(props.isEdit ? "editCode" : "addCode")}
          value={value}
          setValue={setValue}
          name={"codeLate"}

          toggleOpen={toggleOpen}
          codeLate={props.codeLate}
        />
      ) : value.length > 0 ? (
        <ConversationButton icon="edit" label={value} onClick={toggleOpen} />
      ) : (
        <ConversationButton
          icon="plus"
          label={t(props.isEdit ? "editCode" : "addCodeShort")}
          onClick={toggleOpen}
        />
      )}
    </div>
  )
}

export default LateCodeButton
