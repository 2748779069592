import * as React from "react"
import * as styles from "./styles.scss"
import { GetReviewCaseFolder_caseFolder_documents_orders } from "schema"
import Info from "./Info"

const OrderCard: React.FC<OrderCardProps>  = (props) => {
    const { order, chunkLength } = props

    return (
      <div
        className={styles.orderCard}
        style={{
          flex:
            chunkLength > 1
              ? order.mediaOrder!.documentFormat.sizeColumns!
              : undefined,
        }}
      >
        <div className={styles.imageContainer}>
        <Info
          name={order.mediaOrder!.media.friendlyName}
          price={order.mediaOrder!.preview.price?.endCustomer?.pricePayRounded}
          size={order.mediaOrder!.preview.material.basePage.size}
        />
          <img
            src={order.mediaOrder!.preview.material.pages[0]!.png.url}
            className={styles.image}
            alt=""
          />

        </div>

      </div>
    )
  
}

interface OrderCardProps {
  order: GetReviewCaseFolder_caseFolder_documents_orders
  chunkLength: number
}

export default OrderCard
