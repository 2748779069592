import * as React from "react"
import LoginForm from "./LoginForm"
import LoginLayout from "layouts/LoginLayout"

const LoginPage: React.FC<{}> = () => {
  return (
    <LoginLayout>
      <LoginForm />
    </LoginLayout>
  )
}

export default LoginPage
