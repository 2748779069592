import * as React from "react"
import * as styles from "./styles.scss"
import logo from "images/timecut-logo.png"

function Footer() {
  return (
    <footer className={styles.footer}>
      <div className={styles.logoContainer}>
        <img src={logo} className={styles.logo} />
      </div>
    </footer>
  )
}

export default Footer
