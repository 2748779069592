import * as React from "react"
import produce from "immer"
import {
  useContext,
  createContext,
  useContextSelector,
} from "use-context-selector"

export interface VerseEditingContextValue {
  isModalVisible: boolean
  searchButtonRect?: DOMRect
  selectedVerseText?: string | undefined
  selectedVerseId?: string | undefined
}
export const initialData: VerseEditingContextValue = {
  isModalVisible: false,
  searchButtonRect: undefined,
  selectedVerseText: undefined,
  selectedVerseId: undefined,
}
export const initalState: State = {
  data: { ...initialData },
}
type Action =
  | {
      type: "toggleModal"
    }
  | {
      type: "toggleModalWithRect"
      payload: { rect?: DOMRect }
    }
  | { type: "closeModal" }
  | {
      type: "selectVerseText"
      payload: { textXml: string; id: string; name: string }
    }
  | { type: "openModal" }

type Dispatch = (action: Action) => void
type State = {
  data: VerseEditingContextValue
}
type ContextProviderProps = { children: React.ReactNode }
export const StateContext = createContext<State | undefined>(undefined)
const DispatchContext = createContext<Dispatch | undefined>(undefined)

function reducer(state: State, action: Action): State {
  // console.log("VerseEditingContext log", action.type, state, action)
  switch (action.type) {
    case "toggleModal": {
      return produce(state, (draft) => {
        draft.data.isModalVisible = !draft.data.isModalVisible
      })
    }
    case "toggleModalWithRect": {
      return produce(state, (draft) => {
        draft.data.isModalVisible = !draft.data.isModalVisible
        draft.data.searchButtonRect = action.payload.rect
      })
    }
    case "closeModal": {
      return produce(state, (draft) => {
        draft.data.isModalVisible = false
      })
    }
    case "openModal": {
      return produce(state, (draft) => {
        draft.data.isModalVisible = true
      })
    }
    case "selectVerseText": {
      return produce(state, (draft) => {
        draft.data.selectedVerseText = action.payload.textXml
        draft.data.selectedVerseId = action.payload.id
      })
    }

    default: {
      throw new Error(`Unhandled action type: ${(action as any).toString()}`)
    }
  }
}

function VerseEditingContextProvider({ children }: ContextProviderProps) {
  const [state, dispatch] = React.useReducer(reducer as any, initalState)
  return (
    <StateContext.Provider value={state as any}>
      <DispatchContext.Provider value={dispatch as Dispatch}>
        {children}
      </DispatchContext.Provider>
    </StateContext.Provider>
  )
}

function useVerseEditingContextState(selector?: (state: State) => any) {
  const defaultSelector = (state: State) => state
  return useContextSelector(
    StateContext,
    selector === undefined ? defaultSelector : selector
  )
}

function useVerseEditingContextDispatch() {
  const context = useContext(DispatchContext)
  if (context === undefined) {
    throw new Error("useContextDispatch must be used within a Provider")
  }
  return context
}
export {
  VerseEditingContextProvider,
  useVerseEditingContextState,
  useVerseEditingContextDispatch,
}
