import * as React from "react"
import * as styles from "./styles.scss"
import logo from "images/timecut-logo.png"

class Logo extends React.PureComponent {
  render() {
    return (
      <div className={styles.logoContainer}>
        <img src={logo} alt="logo" className={styles.logo} />
        <p>TAPS 5.0</p>
      </div>
    )
  }
}

export default Logo
