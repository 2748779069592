import gql from "graphql-tag"

const GET_PACKAGES = gql`
query GetPackages {
  orderInitiatorPackageImages {
    id
    name
    imageUrl
  }
  orderInitiatorPackagesByCurrentUser {
    id
    name
    accessCustomerScope
    commonPrice
    imageId
    imageUrl
    items {
      media {
        id
        friendlyName
      }
      documentFormat {
        id
        documentType {
          id
          name
        }
      }      
      
      documentFormatInitiator {
        id
        name
      }
      customerDocumentFormatInitiator {
        id
        name
      }
      inputId {
        customerDocumentFormatInitiatorId
        documentFormatInitiatorId
        mediaId
      }
    }
  }
}
`

export default GET_PACKAGES
