import React from "react"
import styles, { templateModal } from "../styles.scss"
import {
  GetAvaiableDocumentFormats_orders_mediaOrder_availableDocumentFormats,
  documentFormat,
  documentFormatVariables,
} from "schema"
import { MutationFn } from "react-apollo"
import Spinner from "components/Spinner"
import Carousel from "nuka-carousel"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faArrowCircleLeft,
  faArrowCircleRight,
} from "@fortawesome/free-solid-svg-icons"
import useWindowSize from "useHook/useWindowSize"
import { useApolloClient, useQuery } from "react-apollo-hooks"
import DOCUMENT_FORMAT from "graphql/queries/documentFormat"
import { useLazyQuery } from "@apollo/react-hooks"
import ErrorMessage from "components/ErrorMessage"
import cx from "classnames"
import { useTranslation } from "react-i18next"

interface ISize {
  width: number | undefined
  height: number | undefined
}

const Templates: React.FC<{
  templates:
    | undefined
    | GetAvaiableDocumentFormats_orders_mediaOrder_availableDocumentFormats[]
  onSelectTemplate: MutationFn
  orderId: string
  closeModal: () => void
  currentFormatName: string
  selectedDocumentFormatId: string | undefined
}> = (props) => {
  const screenSize: ISize = useWindowSize()
  const [t] = useTranslation("editOrder")
  const client = useApolloClient()
  const [isLoading, setIsLoading] = React.useState<boolean>(false)
  const [sliderIndex, setSliderIndex] = React.useState<number>(3)
  const [templateSelectedId, setTemplateSelectedId] = React.useState<
    string | null
  >(null)

  const [getColor, { loading, data, error }] = useLazyQuery<
    documentFormat,
    documentFormatVariables
  >(DOCUMENT_FORMAT, {
    client,
    context: {
      debounceKey: "template-color",
      debounceTimeout: 350,
    },
  })

  const templatesHeight: any =
    props.templates && props.templates.map((temp) => temp.thumbnailHeight)
  const largestTemplateHeight = props.templates && Math.max(...templatesHeight)
  const colors =
    data && data.documentFormat && data?.documentFormat.availableStyleTemplates2

  React.useLayoutEffect(() => {
    if (screenSize) {
      if (screenSize.width! <= 1200) {
        setSliderIndex(2)
      } else if (screenSize.width! >= 1700) {
        setSliderIndex(4)
      } else {
        setSliderIndex(3)
      }
    }
  }, [screenSize])

  const handleSelectTemplateId = (tempId: string) => {
    setTemplateSelectedId(tempId)
    const availableStyleTemplates2 =
      props.templates &&
      props.templates.filter((temp) => temp.id === tempId)[0]
        .availableStyleTemplates2
    getColor({ variables: { id: tempId } })
    if (availableStyleTemplates2!.length <= 0) {
      handleSelectTemplate(tempId, 0)
    }
  }

  const handleSetTemplateColor = (colorId: number) => {
    if (templateSelectedId && colorId) {
      handleSelectTemplate(templateSelectedId, colorId)
    }
  }

  const handleSelectTemplate = (tempId: string, colorId: number | null) => {
    setIsLoading(true)
    props
      .onSelectTemplate({
        variables: {
          input: {
            documentFormatId: tempId,
            orderId: props.orderId,
            styleTemplate2Id: colorId,
          },
        },
      })
      .then((res) => {
        if (res) {
          props.closeModal()
          setIsLoading(false)
        }
      })
  }

  const handleTemplateSelect = (id: string) => {
    handleSelectTemplateId(id)
    setTimeout(() => {
      window.location.reload()
    }, 500)
  }

  if (error) {
    return <ErrorMessage message={error.message} />
  }

  return (
    <div className={styles.template}>
      <div className={styles.label}>
        <h3>{t("selectTemplate")}</h3>
      </div>
      <div className={styles.content}>
        {loading && (
          <div className={styles.spinner}>
            <Spinner />
          </div>
        )}
        {isLoading && (
          <div className={styles.spinner}>
            <Spinner />
          </div>
        )}
        {props.templates && props.templates?.length > 3 ? (
          <Carousel
            className={styles.carousel}
            slidesToShow={sliderIndex}
            slidesToScroll={sliderIndex}
            renderCenterLeftControls={({ previousSlide, previousDisabled }) => {
              if (previousDisabled) {
                return null
              }
              return (
                <div
                  onClick={previousSlide}
                  style={{
                    marginLeft: "15px",
                  }}
                  className={"arrowPrev"}
                >
                  <FontAwesomeIcon icon={faArrowCircleLeft} />
                </div>
              )
            }}
            renderCenterRightControls={({ nextSlide, nextDisabled }) => {
              if (nextDisabled) {
                return null
              }

              return (
                <div
                  onClick={nextSlide}
                  style={{
                    marginRight: "15px",
                  }}
                  className={"arrowNext"}
                >
                  <FontAwesomeIcon icon={faArrowCircleRight} />
                </div>
              )
            }}
            renderBottomCenterControls={null}
          >
            {props.templates.map((temp, index) => {
              return (
                <div key={index} onClick={() => handleTemplateSelect(temp.id)}>
                  <div
                    className={cx(styles.slider, {
                      [styles.selected]: temp.id === templateSelectedId,
                    })}
                  >
                    <img
                      src={temp.thumbnailUrl}
                      alt={temp.name}
                      onError={(
                        event: React.SyntheticEvent<HTMLImageElement, Event>
                      ) =>
                        (event.currentTarget.src =
                          "https://via.placeholder.com/250x500?text=Trasigt+bild")
                      }
                      style={{
                        width: temp.thumbnailWidth!,
                        height: largestTemplateHeight!,
                      }}
                    />
                    <span
                      className={cx("", {
                        [styles.currentAd]:
                          props.currentFormatName === temp.name,
                      })}
                    >
                      {temp.name}
                    </span>
                  </div>
                </div>
              )
            })}
          </Carousel>
        ) : (
          <div className={styles.templateBlock}>
            {props.templates &&
              props.templates.map((temp, index) => {
                return (
                  <div
                    className={styles.templateItems}
                    key={index}
                    onClick={() => handleTemplateSelect(temp.id)}
                  >
                    <div
                      className={cx("templateWrapper", {
                        [styles.selected]:
                          temp.id === props.selectedDocumentFormatId,
                      })}
                    >
                      <img
                        src={temp.thumbnailUrl}
                        alt={temp.name}
                        style={{
                          width: temp.thumbnailWidth!,
                          height: largestTemplateHeight!,
                        }}
                      />
                      <span
                        className={cx("", {
                          ["currentAd"]: props.currentFormatName === temp.name,
                        })}
                      >
                        {temp.name}
                      </span>
                    </div>
                  </div>
                )
              })}
          </div>
        )}
      </div>
      {colors && colors.length > 0 && colors !== undefined && (
        <div className={styles.colorSelectBlock}>
          <div title={styles.title}>
            <h3>{t("selectColor")}</h3>
          </div>
          <ul>
            {colors &&
              colors?.map((color, index) => {
                return (
                  <li
                    key={"_" + color.id + index}
                    style={{ backgroundColor: color.color! }}
                    onClick={() => handleSetTemplateColor(color.id)}
                  ></li>
                )
              })}
          </ul>
        </div>
      )}
    </div>
  )
}

export default Templates
