import React from "react"
import * as styles from "../styles.scss"
import startSidaImage from "images/support/startsida.png"
import AktOchAnnonsImage from "images/support/aktAnnons.png"
import memmoryroomImage from "images/support/memoryroom.png"
import towColAnnonsImage from "images/support/twoColAd.png"
import uploadAnImage from "images/support/ladda_upp_bild.png"
import SaveTextImage from "images/support/spara_text.png"
import EditTextImage from "images/support/redigera_text.png"
import proofSuggestionImage from "images/support/korrekturForslag.png"

import liveStream from "images/support/livestream.png"
import advanceTwoColAnnons from "images/support/advanceTwoColAd.png"
import VideoModal from "../VideoModal"
import produce from "immer"
import { useTranslation } from "react-i18next"

interface IVideoDetails {
  url: string | null
  title: string | null
}
const LeftCol:React.FC<{
  isMediaUser: boolean
}> = (props) => {
  const {t} = useTranslation("supportView")
  const [isVideoModalVisible, setIsVideoModalVisible] = React.useState<boolean>(
    false
  )
  const [videoDetails, setVideoDetails] = React.useState<IVideoDetails>({
    url: null,
    title: null,
  })

  const handleOpenModal = (url: string, title: string) => {
    setVideoDetails(
      produce(videoDetails, (draft) => {
        draft.url = url
        draft.title = title
      })
    )
    setIsVideoModalVisible(true)
  }

  const handleCloseModal = () => {
    setVideoDetails(
      produce(videoDetails, (draft) => {
        draft.url = null
        draft.title = null
      })
    )
    setIsVideoModalVisible(false)
  }

  const videoList = [
    {
      title: t("startPage"),
      image: startSidaImage,
      link: "https://youtu.be/dCUGMpn0se8",
      isVisible: true
    },
    {
      title: t("FileAndAd"),
      image: AktOchAnnonsImage,
      link: "https://youtu.be/cSVyBUMM9UE",
      isVisible: true
    },
    {
      title: t("memoryRoom"),
      image: memmoryroomImage,
      link: "https://youtu.be/g3lImZCtM2Q",
      isVisible: !props.isMediaUser
    },
    {
      title: t("TwoColumnAd"),
      image: towColAnnonsImage,
      link: "https://youtu.be/UtkKrOMP6K4",
      isVisible: true
    },
    {
      title: t("advanceTwoColumnAd"),
      image: advanceTwoColAnnons,
      link: "https://youtu.be/je-G83tFERk",
      isVisible: true
    },
    {
      title: t("liveStream"),
      image: liveStream,
      link: "https://youtu.be/glqI6qmSa5Y",
      isVisible: !props.isMediaUser
    },
  ]

  const newFunctionVideoList = [
    {
      title: t("uploadOwnImage"),
      image: uploadAnImage,
      link: "https://youtu.be/Z4XwK9a2sj8",
      isVisible: !props.isMediaUser
    },
    {
      title: t("saveYourOwnText"),
      image: SaveTextImage,
      link: "https://youtu.be/2hAqrxo24u0",
      isVisible: !props.isMediaUser
    },
    {
      title: t("editText"),
      image: EditTextImage,
      link: "https://youtu.be/Hsc_SrH90Ck",
      isVisible: !props.isMediaUser
    },
    {
      title: t("proofSuggestion"),
      image: proofSuggestionImage,
      link: "https://youtu.be/ofnUN1yhSwk",
      isVisible: !props.isMediaUser
    }
  ]


  return (
    <>
      <div className={styles.leftCol}>
        <h4>{t("videoTitle")}</h4>
        <ul className={styles.videoList}>
          {videoList.map((v, i) => {
            return (
              <li key={i} style={v.isVisible ? {display: "block"} : {display: "none"}}>
                <div
                  className={styles.innerItem}
                  onClick={() => handleOpenModal(v.link, v.title)}
                >
                  <div
                    className={styles.img}
                    style={{ backgroundImage: `url("${v.image}")` }}
                  ></div>

                </div>
              </li>
            )
          })}
        </ul>
        <h4>{t("NewFunctions")}</h4>
        <ul className={styles.videoList}>
          {newFunctionVideoList.map((v, i) => {
            return (
              <li key={i} style={v.isVisible ? {display: "block"} : {display: "none"}}>
                <div
                  className={styles.innerItem}
                  onClick={() => handleOpenModal(v.link, v.title)}
                >
                  <div
                    className={styles.img}
                    style={{ backgroundImage: `url("${v.image}")` }}
                  ></div>

                </div>
              </li>
            )
          })}
        </ul>
      </div>
      <VideoModal
        inOpen={isVideoModalVisible}
        onClose={handleCloseModal}
        videoDetails={videoDetails}
      />
    </>
  )
}

export default LeftCol
