import gql from "graphql-tag"

const DELETE_PACKAGE = gql`
  mutation DeletePackage($input: MutationDeleteOrderInitiatorPackagesInput!) {
    deleteOrderInitiatorPackages(input: $input) {
      isError
      errorReason
    }
  }
`

export default DELETE_PACKAGE
