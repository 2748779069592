import * as React from "react"
import * as styles from "./styles.scss"
import CheckboxColumn from "components/CheckboxColumn"
import { withTranslation, WithTranslation } from "react-i18next"
import Product from "./Product"

class Products extends React.PureComponent<ProductsProps & WithTranslation> {
  render() {
    const { t, products, onToggleCheckbox } = this.props

    return (
      <CheckboxColumn title={t("products")} className={styles.products}>
        {Object.keys(this.props.products).map(order => (
          <Product
            key={order}
            id={order}
            products={products}
            onToggleCheckbox={onToggleCheckbox}
          />
        ))}
      </CheckboxColumn>
    )
  }
}

interface ProductsProps {
  products: any
  onToggleCheckbox: (id: string) => void
}

export default withTranslation("proof")(Products)
