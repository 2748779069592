import * as React from "react"
import * as styles from "./styles.scss"
import ErrorMessage from "components/ErrorMessage"
import { notEmpty } from "utils"
import Publishings from "./Publishings"
import Spinner from "components/Spinner"
import Header from "components/Header"
import { useTranslation, WithTranslation, withTranslation } from "react-i18next"
import { DateTime } from "luxon"
import { useApolloClient } from "react-apollo-hooks"
import { Query } from "react-apollo"
import { GetCalendarOrders } from "schema"
import GET_CALENDAR_ORDERS from "graphql/queries/getCalendarOrders"
import moment, { locale } from "moment"

class PublishingsContainer extends React.PureComponent<
  WithTranslation,
  PublishingsContainerState
> {
  state = {
    selectedDate: new Date(),
    firstDayInWeek: null,
    lastDayInWeek: null,
    isWeekSelected: false,
    weekNumber: undefined,
  }

  componentDidMount() {
    let year = Number(DateTime.fromJSDate(new Date()).toFormat("yyyy"))
    let month = Number(DateTime.fromJSDate(new Date()).toFormat("LL"))
    let day = Number(DateTime.fromJSDate(new Date()).toFormat("dd"))
    let weekNumber = DateTime.local(year, month, day).startOf("week").weekNumber
    const firstDateOfWeek = new Date(
      DateTime.local().setLocale("sv_SE").startOf("week").toISO()
    )
    this.handleWeekSelect(firstDateOfWeek, weekNumber)
  }


  resetWeekColor = () => {
    const parentEl = document.querySelectorAll(
      ".react-datepicker__month .react-datepicker__week"
    ) as any
    for (let i = 0; i < parentEl.length; i++) {
      parentEl[i]?.classList.remove("selectedWeek")
    }
  }

  setSelectedDate = (date: Date) => {
    if (date) {
      this.resetWeekColor()
      this.setState({
        selectedDate: date,
        firstDayInWeek: null,
        lastDayInWeek: null,
        isWeekSelected: false,
      })
    }
  }

  handleWeekSelect = (
    firstDayOfWeek?: Date,
    weekNumber?: number,
    event?: React.SyntheticEvent<HTMLDivElement, Event> | any
  ) => {
    event?.persist()
    let last = Number(moment(firstDayOfWeek).endOf("week").toDate()) + 1
    let lastDayOfWeek: any = moment(last)

    setTimeout(() => {
      const el = event?.target.parentNode
      this.resetWeekColor()
      el?.classList.add("selectedWeek")
    }, 350)

    this.setState({
      firstDayInWeek: firstDayOfWeek,
      lastDayInWeek: lastDayOfWeek._d,
      weekNumber: weekNumber,
      isWeekSelected: true,
      selectedDate: undefined,
    })
  }

  render() {
    const { selectedDate } = this.state
    const { t } = this.props

    return (
      <React.Fragment>
        {/* <Header className={styles.title} title={t("publishings")} /> */}

        <Query<GetCalendarOrders>
          query={GET_CALENDAR_ORDERS}
          fetchPolicy="network-only"
        >
          {({ loading, error, data }) => {
            if (loading) return <Spinner />

            if (error) return <ErrorMessage message={error.message} />

            const dates = data!.calendarOrdersByCurrentCustomer.filter(notEmpty)

            const ordersByWeekSelectedRange = dates.filter((item) => {
              const f = "yyyy-MM-dd"
              const startDay = DateTime.fromJSDate(
                new Date(this.state.firstDayInWeek!)
              ).toFormat(f)
              const endDay = DateTime.fromJSDate(
                new Date(this.state.lastDayInWeek!)
              ).toFormat(f)
              const dates = DateTime.fromJSDate(new Date(item.date)).toFormat(f)
              const ordersList = []
              if (dates >= startDay && dates <= endDay) {
                const orders = item
                const ordersRange = ordersList.push(orders)
                return ordersRange
              }
              return null
            })

            const ordersBySelectedDate = dates.find((date) => {
              const f = "yyyy-MM-dd"
              const a = DateTime.fromJSDate(new Date(date.date)).toFormat(f)
              const b = DateTime.fromJSDate(selectedDate).toFormat(f)
              return a === b
            })

            const highlightDates = [
              {
                "react-datepicker__day--highlighted-blue-1": dates.map((b) =>
                  b.summary.mostCriticalColor === "BLUE" ? new Date(b.date) : 0
                ),
              },
              {
                "react-datepicker__day--highlighted-yellow-1": dates.map((y) =>
                  y.summary.mostCriticalColor === "YELLOW"
                    ? new Date(y.date)
                    : 0
                ),
              },
              {
                "react-datepicker__day--highlighted-red-1": dates.map((r) =>
                  r.summary.mostCriticalColor === "RED" ? new Date(r.date) : 0
                ),
              },
              {
                "react-datepicker__day--highlighted-green-1": dates.map((g) =>
                  g.summary.mostCriticalColor === "GREEN" ? new Date(g.date) : 0
                ),
              },
              {
                "react-datepicker__day--highlighted-grey-1": dates.map((g) =>
                  g.summary.mostCriticalColor === "GRAY" ? new Date(g.date) : 0
                ),
              },
            ]

            return (
              <Publishings
                orders={ordersBySelectedDate ? ordersBySelectedDate.orders : []}
                selectedDate={selectedDate}
                highlightDates={highlightDates}
                onCalendarDateChange={this.setSelectedDate}
                handleWeekSelect={this.handleWeekSelect}
                firstDayInWeek={this.state.firstDayInWeek}
                lastDayInWeek={this.state.lastDayInWeek}
                isWeekSelected={this.state.isWeekSelected}
                calendarOrders={ordersByWeekSelectedRange}
                weekNumber={this.state.weekNumber}
              />
            )
          }}
        </Query>
      </React.Fragment>
    )
  }
}

interface PublishingsContainerState {
  selectedDate: Date | any
  firstDayInWeek: Date | any
  lastDayInWeek: Date | any
  isWeekSelected: boolean
  weekNumber: number | undefined
}

export default withTranslation("dashboard")(PublishingsContainer)

// const PublishingsContainer: React.FC<{}> = props => {
//   const [ t ] = useTranslation("dashboard")
//   return (
//     <React.Fragment>
//       <Header className={styles.title} title={t("publishings")} />
//       <CalendarQuery />
//     </React.Fragment>
//   )
// }

// const CalendarQuery: React.FC<{}> = props => {
//   const [selectedDate, setSelectedDate] = React.useState<Date>(new Date())
//   const onCalendarDateChange = (date: Date) => {
//     setSelectedDate(date)
//   }
//   const client = useApolloClient()
//   const { loading, data, error } = useGetCalendarOrdersQuery({
//     fetchPolicy: "network-only",
//     client
//   })

//   if (loading) {
//     return <Spinner />
//   }

//   if (error) {
//     return <ErrorMessage message={error.message} />
//   }

//   if(!data){
//     return <ErrorMessage message={""} />
//   }

//   const dates = data.calendarOrdersByCurrentCustomer.filter(notEmpty)

//   const ordersBySelectedDate = dates.find(date => {
//     const f = "yyyy-MM-dd"
//     const a = DateTime.fromJSDate(new Date(date.date)).toFormat(f)
//     const b = DateTime.fromJSDate(selectedDate).toFormat(f)
//     return a === b
//   }) as any

//   const highlightDates:any = []

//   console.log('co przyszlo?', ordersBySelectedDate, highlightDates, selectedDate)
//   return (
//     <Publishings
//       orders={ordersBySelectedDate ?? []}
//       selectedDate={selectedDate}
//       highlightDates={highlightDates}
//       onCalendarDateChange={onCalendarDateChange}
//     />
//   )
// }

// export default PublishingsContainer
