import * as React from "react"
import MainLayout from "layouts/MainLayout"
import Whiteframe from "components/Whiteframe"
import Header from "components/Header"
import Searchbar from "./Searchbar"
import { DropdownOption } from "components/DropdownButton"
import ArchiveTableContainer from "./ArchiveTableContainer"
import { useTranslation } from "react-i18next"
import BackgroundImage from "components/BackgroundImage"
import image from "images/image.png"
import produce from "immer"
import { AppContext } from "App"

const CaseFolderArchivePage: React.FC<{}> = (props) => {
  const caseFoldersFilterOptions: DropdownOption[] = [
    { value: "Default", label: "default" },
    { value: "Customer", label: "myCaseFolders" },
    { value: "Office", label: "officesCaseFolders" },
    { value: "Company", label: "companyCaseFolders" },
  ]

  const statusFilterOptions: DropdownOption[] = [
    { value: "NONE", label: "NONE" },
    { value: "EDIT", label: "EDIT" },
    { value: "FAILED", label: "FAILED" },
    { value: "PRODUCTION", label: "PRODUCTION" },
    { value: "PUBLISHED", label: "PUBLISHED" },
    { value: "DELETED", label: "DELETED" },
    { value: "CANCELED", label: "CANCELED" },
    { value: "WAIT_APPROVAL", label: "WAIT_APPROVAL" },
  ]

  const {isShowModeShow, toggleShowModeView} = React.useContext(AppContext)
  const { t } = useTranslation(["archive", "statuses"])

  const [state, setState] = React.useState<CaseFolderArchivePageState>({
    selectedStatusOption: statusFilterOptions[0],
    selectedCaseFolderOption: caseFoldersFilterOptions[0],
    searchValue: "",
    expandedRowIndex: undefined,
  })

  const onStatusFilterChange = (option: DropdownOption) => {
    setState(
      produce(state, (draft) => {
        draft.selectedStatusOption = option
      })
    )
  }

  const onCaseFolderFilterChange = (option: DropdownOption) => {
    setState(
      produce(state, (draft) => {
        draft.selectedCaseFolderOption = option
      })
    )
  }

  const onSearchFieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState(
      produce(state, (draft) => {
        draft.searchValue = event.target.value
      })
    )
  }

  const translateOptionLabel = (
    options: DropdownOption[],
    isStatuses = false
  ) => {
    return options.map((option) => ({
      ...option,
      label: t(isStatuses ? `statuses:${option.label}` : option.label),
    }))
  }

  const handleExpandRow = (_: any, index: number[]) => {
    setState(
      produce(state, (draft) => {
        draft.expandedRowIndex =
          draft.expandedRowIndex === index[0] ? undefined : index[0]
      })
    )
  }


  return (
    <MainLayout showUtilityBar={true}>
      <BackgroundImage src={image} />
      {/* <Header title={t("caseFolderArchive")} /> */}
      <Whiteframe>
        <Searchbar
          onSearchFieldChange={onSearchFieldChange}
          caseFoldersFilterOptions={translateOptionLabel(
            caseFoldersFilterOptions
          )}
          onStatusFilterChange={onStatusFilterChange}
          onCaseFolderFilterChange={onCaseFolderFilterChange}
          statusOptions={translateOptionLabel(statusFilterOptions, true)}
          selectedCaseFolderOption={state.selectedCaseFolderOption}
          selectedStatusOption={state.selectedStatusOption}
        />
        <ArchiveTableContainer
          searchValue={state.searchValue}
          selectedCaseFolderOption={state.selectedCaseFolderOption}
          selectedStatusOption={state.selectedStatusOption}
          expandedRowIndex={state.expandedRowIndex}
          handleExpandRow={handleExpandRow}
          isShowModeView={isShowModeShow}
          toggleShowModeView={toggleShowModeView}
        />
      </Whiteframe>
    </MainLayout>
  )
}

interface CaseFolderArchivePageState {
  selectedStatusOption: DropdownOption
  selectedCaseFolderOption: DropdownOption
  searchValue: string
  expandedRowIndex: undefined | number
}

export default CaseFolderArchivePage
