import * as React from "react"
import * as styles from "./styles.scss"
import cx from "classnames"
import { IconProp } from "@fortawesome/fontawesome-svg-core"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

function Tab(props: TabProps) {
  const handleClick = () => props.onClick(props.id)

  return (
    <div
      className={cx(styles.tab, props.className, {
        [styles.selected]: props.isSelected,
        [styles.disabled]: props.disabled,
      })}
      onClick={props.disabled ? undefined : handleClick}
    >
      {props.icon && (
        <FontAwesomeIcon icon={props.icon} className={styles.icon} />
      )}
      <span>{props.label}</span>
    </div>
  )
}

interface TabProps {
  id: string
  label: string
  icon?: IconProp
  onClick: React.Dispatch<React.SetStateAction<any>>
  isSelected: boolean
  className?: string
  disabled?: boolean
}

export default Tab
