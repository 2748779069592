import * as React from "react"
import Button from "components/Button"
import { WithTranslation, withTranslation } from "react-i18next"
import { fetchDownloadPng } from "utils"

class DownloadButton extends React.PureComponent<
  DownloadButtonProps & WithTranslation
> {
  handleClick = () => fetchDownloadPng(this.props.selectedSymbolUrl!, "symbol.png")

  render() {
    const { selectedSymbolUrl, t } = this.props

    return (
      <Button
        variant="tertiary"
        onClick={this.handleClick}
        disabled={!selectedSymbolUrl}
        icon="download"
      >
        {t("download")}
      </Button>
    )
  }
}

interface DownloadButtonProps {
  selectedSymbolUrl?: string
}

export default withTranslation("editOrder")(DownloadButton)
