import * as React from "react"
import Calendar from "components/Calendar"
import { MutationFn } from "react-apollo"
import Tooltip from "components/Tooltip"
import moment from "moment"

const InnerPublishTimeSelector: React.FC<InnerPublishTimeSelectorProps> = (
  props
) => {
  const handleDateSelect = (date: Date) => {
    if (props.disabled || !date) {
      return
    }

    const newDate = props.selectedDate
      ? date
      : new Date(date.setUTCMinutes(-date.getTimezoneOffset()))

    props.onUpdatePublishTime({
      variables: {
        input: {
          orderId: props.orderId,
          publishTimes: [newDate],
        },
      },
    })
  }

  const [dayTooltip, setDayTooltip] = React.useState({})

  React.useEffect(() => {
    const result = {}
    props.includeDates.forEach((day) => {
      if (day.timeBooking === undefined || day.timeBooking === null) {
        return
      }

      if (day.timeBooking.length === 0) {
        return
      }

      if (day.isEnabled === false) {
        return
      }

      const dateKey = moment(day.timeDay).format("YYYY_MM_DD")

      result[dateKey] = moment(day.timeBooking).format("YYYY-MM-DD HH:mm:ss")
    })
    setDayTooltip(result)
  }, [props.includeDates])

  const renderDayContents = (dayOfMonth: number, date: string) => {
    const dateKey = moment(date).format("YYYY_MM_DD")
    const tooltipText = dayTooltip[dateKey]

    if (tooltipText === undefined) {
      return <span>{dayOfMonth}</span>
    }

    return (
      <Tooltip id={`calendarTooltip-${dayOfMonth}`} text={tooltipText}>
        {dayOfMonth}
      </Tooltip>
    )
  }

  return (
    <Calendar
      onChange={handleDateSelect}
      includeDates={props.includeDates
        .filter((day) => day.isEnabled)
        .map((d) => new Date(d.timeDay))}
      selectedDate={props.selectedDate}
      disabled={props.disabled}
      className={props.className}
      renderDayContents={renderDayContents as any}
    />
  )
}

interface InnerPublishTimeSelectorProps {
  onUpdatePublishTime: MutationFn
  selectedDate: Date | undefined
  orderId: string
  includeDates: any[]
  disabled?: boolean
  className?: string
}

export default InnerPublishTimeSelector
