import * as React from "react"
import Rectangle from "./Rectangle"
import { OrderEditorContext } from "./../../../OrderEditoryAdvert"
import { useContextSelector } from "use-context-selector"
import { OrderPreviewRectangle } from "../.."

const Rectangles: React.FC<{
  isFEUser: boolean
}> = (props) => {
  const {
    hoveredAreaId,
    setExpandedAreaId,
    expandedAreaId,
    previewImageRectangles,
  } = useContextSelector(OrderEditorContext, (s) => {
    return {
      hoveredAreaId: s.hoveredAreaId,
      setExpandedAreaId: s.setExpandedAreaId,
      expandedAreaId: s.expandedAreaId,
      previewImageRectangles: s.previewImageRectangles,
    }
  })

  function keyUpHandlerFn(
    e: any,
    areas: OrderPreviewRectangle[],
    areaId: string
  ) {
    let evt: any = event as any
    if (areaId) {
      const index =
        areaId != undefined ? areas.findIndex((q) => q.id == areaId) : -1
      if ((evt?.key == "ArrowUp" && evt?.ctrlKey)  || (evt?.key == "Tab" && evt?.shiftKey) || evt?.key == "PageUp") {
        evt.preventDefault()
        if (index > 0) setExpandedAreaId(areas[index - 1].id)
        else return
      }
      if ((evt?.key == "ArrowDown" && evt?.ctrlKey) || (evt?.key == "Tab" && !evt?.shiftKey) || evt?.key == "PageDown") {
        evt.preventDefault()
        if (index < areas.length - 1)  setExpandedAreaId(areas[index + 1].id)
        else return
      }
    }
    
  }

  React.useEffect(() => {
    const keyUpHandlerWithBind = keyUpHandlerFn.bind(
      null,
      event,
      previewImageRectangles.filter((q) => q.h > 0.1),
      expandedAreaId
    )
    document.addEventListener("keydown", keyUpHandlerWithBind as (e: any) => {}, false)
    return () => {
      // Clean up
      if (keyUpHandlerWithBind) {
        document.removeEventListener(
          "keydown",
          keyUpHandlerWithBind as (e: any) => {}
          ,false
        )
      }
    }
  }, [expandedAreaId])

  return (
    <React.Fragment>
      {previewImageRectangles.map((rectangle) => (
        <Rectangle
          key={rectangle.id}
          rectangle={rectangle}
          isHovered={rectangle.id === hoveredAreaId}
          onClick={setExpandedAreaId}
          isFEUser={props.isFEUser}
        />
      ))}
    </React.Fragment>
  )
}

export default Rectangles
