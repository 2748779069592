import gql from "graphql-tag"

const GET_CURRENT_USER_SESSION = gql`
query GetCurrentUserSession {
  currentUserSession {
    id
    isAuthenticated
    cultureCode
    user {
      id
      isFEUser
      username
      cultureCode
      isDemoUser
      isPowerUserOffice
      isPowerUserCompany
      isAssistentUser
      isSupportUser
      isSuperUser
      isMediaUser
      isFEUser
      isBitnetUser
      customers {
        id
        office {
          id
          name
        }
      }
    }
    customer {
      id
      name
      settings {
        emailCustomer
      	contactPhone
      }
      colleagues {
        id
        firstName
        lastName
        office {id name}
      }
      office {
        id
        name
        company {
          id
        }
      }
    }
  }
}

`

export default GET_CURRENT_USER_SESSION
