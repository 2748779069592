import * as React from "react"
// tslint:disable-next-line:max-line-length
// import posed from "react-pose1"
import { motion } from "framer-motion"
import {
  EditModelAdvertAreaContentEditorTypes,
  GetEditOrderPageCaseFolder_caseFolder_editTabs,
} from "schema"
import { UpdateAllOrdersByFieldContainer } from "./../../../../../UpdateAllOrdersByFieldContainer/index"
import { OrderEditorAreaContext } from "pages/EditOrderPage/OrderEditorContainer/OrderEditoryAdvert/AreasContainer/Areas/AreaContainer"

// const PosedContainer = posed.div({
//   enter: { height: "auto", transition: { duration: 200 } },
//   exit: { height: 0, transition: { duration: 200 } },
// })

const variants = {
  enter: { height: "auto" },
  exit: { height: 0 },
}

const ExpandedContent: React.FC<ExpandedContentProps> = (props) => {
  const { children, type, isExpanded, editTabs: documents } = props

  return (
    <motion.div
      // initial="exit"
      animate={isExpanded ? { height: "auto" } : { height: 0 }}
      style={props.isOverflowHidden ?  {overflow: "hidden"} : {}}
      // transition={{ duration: 200 }}
      // variants={variants}
    >
      {children}

      <OrderEditorAreaContext.Consumer>
        {({ editor }) => {
          return editor.common?.isEnabledUpdateOrdersContent ? (
            <UpdateAllOrdersByFieldContainer
              type={type}
              orders={documents.map((doc) => doc.orders)}
            />
          ) : (
            <></>
          )
        }}
      </OrderEditorAreaContext.Consumer>
    </motion.div>
  )
}

interface ExpandedContentProps {
  // component: React.ComponentClass<any> | React.StatelessComponent<any>
  isExpanded: boolean
  type: EditModelAdvertAreaContentEditorTypes
  editTabs: GetEditOrderPageCaseFolder_caseFolder_editTabs[]
  isFEUser: boolean;
  isOverflowHidden: boolean
}

export default ExpandedContent
