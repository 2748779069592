import * as React from "react"
import * as styles from "./styles.scss"
import Spinner from "components/Spinner"
import GET_ARCHIVE_CASE_FOLDERS from "graphql/queries/getArchiveCaseFolders"
import {
  GetArchiveCaseFolders,
  GetArchiveCaseFoldersVariables,
  TouchCaseFolder,
  TouchCaseFolderVariables,
} from "schema"
import { DropdownOption } from "components/DropdownButton"
import ErrorMessage from "components/ErrorMessage"
import { useApolloClient, useQuery, useMutation } from "react-apollo-hooks"
import PackageTable from "components/PackageTable"
import cx from "classnames"
import Status from "components/Status"
import Warning from "components/Warning"
import ReactTable from "react-table-6"
import { useTranslation } from "react-i18next"
import styled, { css } from "styled-components"
import TOUCH_CASE_FOLDER from "graphql/mutations/touchCaseFolder"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faFolderOpen,
  faArrowCircleLeft,
  faArrowCircleRight,
} from "@fortawesome/free-solid-svg-icons"

const ArchiveTableContainer: React.FC<ArchiveTableContainerProps> = (props) => {
  const { t } = useTranslation(["common", "dashboard"])
  const {
    selectedCaseFolderOption,
    selectedStatusOption,
    expandedRowIndex,
  } = props
  const [page, setPage] = React.useState(0)
  const [pageLength, setPageLength] = React.useState(10)
  const client = useApolloClient()
  const triggerTouchCaseFolder = useMutation<
    TouchCaseFolder,
    TouchCaseFolderVariables
  >(TOUCH_CASE_FOLDER, {
    client,
    variables: { id: "" },
    context: {
      skipErrorModal: true,
    },
  })
  const onClickTouchCaseFolder = (caseFolderId: string) => {
    triggerTouchCaseFolder({
      variables: {
        id: caseFolderId,
      },
    })
  }

  const { loading, data, error } = useQuery<
    GetArchiveCaseFolders,
    GetArchiveCaseFoldersVariables
  >(GET_ARCHIVE_CASE_FOLDERS, {
    client,
    variables: {
      textFilter: props.searchValue,
      scope: selectedCaseFolderOption.value,
      filterByOrderStatus: selectedStatusOption.value,
      pageLength: pageLength,
      startindex: page * pageLength,
    },
    context: {
      debounceKey: "archive-table-search",
      debounceTimeout: 1000,
    },
    fetchPolicy: "network-only",
  })

  const maxCount = data?.findCaseFoldersByCurrentCustomer?.count ?? 0

  const onPageChange = (p: number) => {
    setPage(p)
  }

  const isShowModeView_withSearch =
    props.isShowModeView && props.searchValue.length > 0

  return (
    <div className={styles.tableContainer}>
      {loading ? (
        <div className={styles.loadingContainer}>
          <Spinner />
        </div>
      ) : null}

      {error ? <ErrorMessage message={error.message} /> : null}

      {data !== undefined &&
      data !== null &&
      data.findCaseFoldersByCurrentCustomer &&
      data.findCaseFoldersByCurrentCustomer?.caseFolders ? (
        <>
          {/* <TableArchive
            data={data.findCaseFoldersByCurrentCustomer.caseFolders.filter(
              notEmpty
            )}
          /> */}

          <Table6Archive
            data={Array.from(
              { length: Math.max(page * pageLength) },
              (_, i) => i
            ).concat(data.findCaseFoldersByCurrentCustomer.caseFolders as any)}
            expandedRowIndex={props.expandedRowIndex}
            handleExpandRow={props.handleExpandRow}
            onClickTouchCaseFolder={onClickTouchCaseFolder}
            onPageChange={onPageChange}
            maxCount={maxCount}
            pageLength={
              props.isShowModeView
                ? isShowModeView_withSearch
                  ? pageLength
                  : 0
                : pageLength
            }
            page={page}
            loading={loading}
            showPaginationBtn={props.isShowModeView ? false : true}
          />
          { props.isShowModeView && props.searchValue.length <= 0 && (
            <div className={styles.showHideTextWrapper} >
              {/* Dolt läge aktiverat. <br /> Sök på den akt du vill arbeta i, systemet
              filtrerar automatiskt efter dina sökord. */}
              <p> {t("common:show_hide_descText")}</p>
             
            </div>
          )}
        </>
      ) : null}
    </div>
  )
}

const Table6Archive: React.FC<{
  data: any[]
  expandedRowIndex: any
  handleExpandRow: any
  onPageChange: (page: number) => void
  maxCount: number
  pageLength: number
  page: number
  loading: boolean
  onClickTouchCaseFolder: (caseFolderId: string) => void
  showPaginationBtn: boolean
}> = (props) => {
  const { t } = useTranslation(["common", "dashboard"])
  return (
    <>
      <ReactTable
        className={"archive"}
        data={props.data}
        expanded={{
          [props.expandedRowIndex as any]: true,
        }}
        onExpandedChange={props.handleExpandRow}
        columns={[
          {
            Header: t("archive:name"),
            accessor: "name",
            className: styles.caseFolderColumn,
            Cell: (x: any) => {
              return (
                <>
                  <FontAwesomeIcon icon={faFolderOpen} className="folder" />
                  <span style={{ marginLeft: "10px" }}>{x.value}</span>
                </>
              )
            },
          },
          // {
          //   Header: "",
          //   sortable: false,
          // },
          // {
          //   sortable: false,
          //   accessor: "summary",
          //   Cell: (aaa: any) =>
          //     aaa.value.isMostCriticalTimeBookingWarning && (
          //       <Warning>{t("dashboard:bookingStop")}</Warning>
          //     ),
          // },

          {
            Header: t("archive:caseNumber"),
            accessor: "propertiesFuneralStandard.externalId",
          },
          {
            Header: t("archive:administrator"),
            accessor: "customer.name",
          },
          {
            Header: t("dashboard:orders"),
            accessor: "summary.countOrders",
          },
          {
            sortable: false,
            Header: t("dashboard:status"),
            accessor: "summary",
            Cell: (aaa: any) => (
              <Status variant={aaa.value.mostCriticalColor}>
                {/* {t(`statuses:${aaa.value.mostCriticalStatus}`)} */}
              </Status>
            ),
          },
        ]}
        getTrGroupProps={(_: any, b: any) => ({
          className: cx(styles.trGroup, {
            [styles.expanded]: b && b.index === props.expandedRowIndex,
          }),
        })}
        SubComponent={(row: any) => (
          <PackageTable
            selectedCaseFolder={row.original}
            hideHeader={true}
            onClickTouchCaseFolder={props.onClickTouchCaseFolder}
          />
        )}
        // nextText={t("common:next")}
        // previousText={t("common:previous")}
        ofText={t("common:of")}
        pageText={t("common:page")}
        loadingText={t("common:loading")}
        rowsText={t("common:rows")}
        noDataText={t("common:noResults")}
        showPagination={true}
        showPaginationBottom={props.showPaginationBtn}
        showPaginationTop={false}
        showPageSizeOptions={false}
        loading={props.loading}
        pageSize={props.pageLength}
        PreviousComponent={(page: any) => {
          return (
            <>
              <span onClick={page.onClick}>
                <FontAwesomeIcon icon={faArrowCircleLeft} />
              </span>
            </>
          )
        }}
        NextComponent={(page: any) => {
          return (
            <>
              <span onClick={page.onClick}>
                <FontAwesomeIcon icon={faArrowCircleRight} />
              </span>
            </>
          )
        }}
        onPageChange={(page: number) => {
          props.onPageChange(page)
        }}
        page={props.page}
        pages={
          props.maxCount === undefined ||
          props.maxCount === 0 ||
          props.maxCount === null
            ? 1
            : Math.floor(props.maxCount / props.pageLength) + 1
        }
      />
    </>
  )
}

export const TableStyles = styled.div`
  .table {
    width: 100%;
    border-spacing: 0;
  }

  .table .rt-tbody {
    flex: 99999 1 auto;
    display: flex;
    flex-direction: column;
    overflow: auto;
  }

  .table .rt-tbody .rt-td {
    border-right: 0;
  }

  .table .rt-thead {
    box-shadow: none;
    border-bottom: #ccc 2px solid;
    display: flex;
    width: 100%;
  }

  .table .rt-thead .rt-tr {
    /* text-align: left; */
    transition: 150ms all;
    /* display: flex; */
    width: 100%;
    flex: 1 0 auto;
    display: inline-flex;
  }

  .table .rt-th,
  .table .rt-td {
    white-space: normal;
    padding: 10.66667px;
  }

  .table .rt-tbody .rt-tr:hover {
    background: rgba(0, 0, 0, 0.05);
  }

  .table .rt-thead .rt-tr {
    display: grid;
    grid-template-columns: auto 150px 90px 70px 200px;
    border-bottom: solid 1px rgba(0, 0, 0, 0.05);
    transition: 150ms all;
  }

  .table .rt-tbody .rt-tr {
    display: grid;
    grid-template-columns: auto 150px 90px 70px 200px;
    transition: 150ms all;
    border-bottom: solid 1px rgba(0, 0, 0, 0.05);
  }

  .table .rt-tbody .rt-tr.odd {
    background: #f8f8f8;
  }

  .table .rt-tbody .rt-tr.row-3 {
    width: 100%;
  }

  .table .rt-tbody .rt-td {
    border-right: 0;
    white-space: normal;
    padding: 10.66667px;
  }

  .table .rt-thead .rt-th {
    display: flex;
    align-items: center;
    outline: none;
    padding: 5px 5px;
    line-height: normal;
    position: relative;
    border-right: 1px solid rgba(0, 0, 0, 0.05);
    transition: box-shadow 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    box-shadow: inset 0 0 0 0 transparent;
  }

  .table .rt-thead .rt-tr .rt-th::last-child {
    border-right: 0;
  }

  .table .rt-thead .rt-th > div {
    outline: none;
    padding: 0 $spacing / 2;
    font-weight: bold;
  }

  .table .rt-tbody .rt-expandable {
    width: 100% !important;
    position: absolute;
    max-width: none !important;
    height: 43px;
    z-index: 1;
  }

  .table .rt-tbody .rt-tr[data-selected="true"] svg[data-icon="trash"] {
    color: #fff;
  }

  .table .rt-tbody .rt-tr[data-selected="true"] svg[data-icon="trash"]:hover {
    color: rgba(255, 255, 255, 0.5);
  }

  .table .rt-tbody .rt-tr svg[data-icon="trash"]:hover {
    color: #1289a7;
  }

  .table .rt-tbody .rt-tr[data-selected="true"] svg[data-icon="trash"]:hover {
    color: #fff;
  }

  pre {
    background: #eee;
  }
`

interface ArchiveTableContainerProps {
  searchValue: string
  selectedCaseFolderOption: DropdownOption
  selectedStatusOption: DropdownOption
  expandedRowIndex: undefined | number
  handleExpandRow: any
  isShowModeView?: boolean
  toggleShowModeView?: () => void
}

export default ArchiveTableContainer

// const TableArchive: React.FC<{ data: any[] }> = (props) => {
//   const { t } = useTranslation(["common", "dashboard"])
//   const tableInstance = useTable(
//     {
//       columns: [
//         // {
//         //   id: "expander", // Make sure it has an ID
//         //   Header: ({
//         //     getToggleAllRowsExpandedProps,
//         //     isAllRowsExpanded,
//         //   }: any) => (
//         //     <span {...getToggleAllRowsExpandedProps()}>
//         //       {isAllRowsExpanded ? "👇" : "👉"}
//         //     </span>
//         //   ),
//         //   Cell: ({ row }: any) =>
//         //     row.canExpand ? (
//         //       <span
//         //         {...row.getToggleRowExpandedProps({
//         //           style: {
//         //             paddingLeft: `${row.depth * 2}rem`,
//         //           },
//         //         })}
//         //       >
//         //         {row.isExpanded ? "👇" : "👉"}
//         //       </span>
//         //     ) : null,
//         // },
//         {
//           id: "caseFolder",
//           Header: t("common:caseFolder"),
//           accessor: "name",
//           minWidth: 100,
//         },
//         {
//           id: "isMostCriticalTimeBookingWarning",
//           accessor: "summary",
//           minWidth: 100,
//           Cell: (aaa: any) =>
//             aaa.value.isMostCriticalTimeBookingWarning && (
//               <Warning>{t("dashboard:bookingStop")}</Warning>
//             ),
//         },
//         {
//           id: "caseFolderId",
//           Header: t("common:caseFolderId"),
//           accessor: "internalId",
//           minWidth: 100,
//         },
//         {
//           id: "countOrders",
//           Header: t("dashboard:orders"),
//           accessor: "summary.countOrders",
//           minWidth: 100,
//         },

//         {
//           id: "mostCriticalColor",
//           Header: t("dashboard:status"),
//           accessor: "summary",
//           minWidth: 100,
//           Cell: (aaa: any) => (
//             <Status variant={aaa.value.mostCriticalColor}>
//               {t(`statuses:${aaa.value.mostCriticalStatus}`)}
//             </Status>
//           ),
//         },
//       ],
//       data: props.data,
//     },
//     useExpanded,
//     usePagination
//   )

//   const {
//     getTableProps,
//     getTableBodyProps,
//     headerGroups,
//     // rows,
//     prepareRow,
//   } = tableInstance

//   const {
//     page,
//     canPreviousPage,
//     canNextPage,
//     pageOptions,
//     pageCount,
//     gotoPage,
//     nextPage,
//     previousPage,
//     setPageSize,
//     state: { pageIndex, pageSize },
//   } = tableInstance as any

//   return (
//     <div>
//       <TableStyles>
//         {/* <pre>
//           <code>
//             {JSON.stringify(
//               {
//                 pageIndex,
//                 pageSize,
//                 pageCount,
//                 canNextPage,
//                 canPreviousPage,
//               },
//               null,
//               2
//             )}
//           </code>
//         </pre> */}

//         <div {...getTableProps()} className="table">
//           <div className="rt-thead">
//             {headerGroups.map((headerGroup, i) => (
//               // tslint:disable-next-line: jsx-key
//               <div
//                 {...headerGroup.getHeaderGroupProps()}
//                 className={`rt-tr row-${i}`}
//               >
//                 {headerGroup.headers.map((column, j) => (
//                   // tslint:disable-next-line: jsx-key
//                   <div {...column.getHeaderProps()} className="rt-th">
//                     <div>{column.render("Header")}</div>
//                   </div>
//                 ))}
//               </div>
//             ))}
//           </div>

//           <div {...getTableBodyProps()} className="rt-tbody">
//             {page.map((row: any, i: number) => {
//               prepareRow(row)
//               return (
//                 <div
//                   key={i}
//                   {...row.getRowProps()}
//                   className={`rt-tr rt-tr-group ${
//                     i % 2 === 0 ? "odd" : ""
//                   } row-${i}`}
//                 >
//                   {row.cells.map((cell: any, j: number) => {
//                     return (
//                       <div
//                         key={`${i}__${j}`}
//                         {...cell.getCellProps()}
//                         className={`rt-td column-${cell.column.id} column-${j}`}
//                       >
//                         {cell.render("Cell")}
//                       </div>
//                     )
//                   })}
//                 </div>
//               )
//             })}
//           </div>
//         </div>

//         {/*
//         Pagination
//       */}
//         <div className="pagination">
//           <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
//             {"<<"}
//           </button>{" "}
//           <button onClick={() => previousPage()} disabled={!canPreviousPage}>
//             {"<"}
//           </button>{" "}
//           <button onClick={() => nextPage()} disabled={!canNextPage}>
//             {">"}
//           </button>{" "}
//           <button
//             onClick={() => gotoPage(pageCount - 1)}
//             disabled={!canNextPage}
//           >
//             {">>"}
//           </button>{" "}
//           <span>
//             Page{" "}
//             <strong>
//               {pageIndex + 1} of {pageOptions.length}
//             </strong>{" "}
//           </span>
//           <span>
//             | Go to page:{" "}
//             <input
//               type="number"
//               defaultValue={pageIndex + 1}
//               onChange={(e) => {
//                 const newPageValue = e.target.value
//                   ? Number(e.target.value) - 1
//                   : 0
//                 gotoPage(newPageValue)
//               }}
//               style={{ width: "100px" }}
//             />
//           </span>{" "}
//           <select
//             value={pageSize}
//             onChange={(e) => {
//               setPageSize(Number(e.target.value))
//             }}
//           >
//             {[10, 20, 30, 40, 50].map((_pageSizeValue) => (
//               <option key={_pageSizeValue} value={_pageSizeValue}>
//                 Show {_pageSizeValue}
//               </option>
//             ))}
//           </select>
//         </div>
//       </TableStyles>
//     </div>
//   )
// }
