import * as React from "react"
import ConversationButton from "../ConversationButton"
import ConversationInput from "../ConversationInputs"
import { useTranslation } from "react-i18next"
import styled from "styled-components"

function OrderNumberButton(props: OrderNumberButtonProps) {
  const [value, setValue] = React.useState("")
  const [isOpen, setIsOpen] = React.useState(false)
  const [t] = useTranslation("review")

  const toggleOpen = () => setIsOpen(!isOpen)

  return (
    <CodeBtn>
      {isOpen ? (
        <ConversationInput
          placeholder={t(
            props.isEdit ? "editOrderNumber" : "addOrderNumber"
          )}
          value={value}
          setValue={setValue}
          name="mediaSystemOrderNumber"
          toggleOpen={toggleOpen}
        />
      ) : value.length > 0 ? (
        <ConversationButton icon="edit" label={value} onClick={toggleOpen} />
      ) : (
        <ConversationButton
          icon="plus"
          label={t(props.isEdit ? "editOrderNumber" : "addOrderNumberShort")}
          onClick={toggleOpen}
        />
      )}
    </CodeBtn>
  )
}

interface OrderNumberButtonProps {
  isEdit: boolean
}

const CodeBtn = styled.div`
  .input {
    width: 130px;
  }
`

export default OrderNumberButton
