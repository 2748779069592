import React from "react"
import * as styles from "./styles.scss"
import BackgroundImage from "components/BackgroundImage"
import MainLayout from "layouts/MainLayout"
import * as Background from "images/image.png"
import RightCol from "./RightCol"
import LeftCol from "./LeftCol"
import { AppContext } from "App"
import { useTranslation } from "react-i18next"

//test

const SupportPage = () => {
  const {t} = useTranslation("supportView");
  const isMediaUser = React.useContext(AppContext).currentUserSession.user?.isMediaUser
  return (
    <MainLayout showUtilityBar={false}>
      <BackgroundImage src={Background} />
      <div className={styles.supportContainer}>
        <div className={styles.header}>
        <h3>{t("support")}</h3>
        </div>
        
        <div className={styles.container}>
          <LeftCol isMediaUser={isMediaUser!} />
          <div className={styles.fill}></div>
          <RightCol isMediaUser={isMediaUser!}  />
        </div>
      </div>
    </MainLayout>
  )
}

export default SupportPage
