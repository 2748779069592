import React from "react"

const TextInput: React.FC<{
  className?: string
  type?: string
  name?: string
  value: string
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  disabled: boolean
}> = (props) => {
  return (
    <input
      className={props.className}
      type={props.type}
      name={props.name}
      value={props.value!}
      onChange={props.onChange}
      disabled={props.disabled}
    />
  )
}

export default TextInput
