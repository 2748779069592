import * as React from "react"
import * as styles from "./styles.scss"
import * as cx from "classnames"

class Slide extends React.PureComponent<SlideProps> {
  render() {
    const { isActive, isNext, isPrev, slide } = this.props

    return (
      <div
        className={cx(styles.slide, {
          [styles.isActive]: isActive,
          [styles.isNext]: isNext,
          [styles.isPrev]: isPrev,
        })}
      >
        {slide}
      </div>
    )
  }
}

interface SlideProps {
  slide: React.ReactNode
  isActive: boolean
  isNext: boolean
  isPrev: boolean
}

export default Slide
