import gql from "graphql-tag"

const ADD_OR_REMOVE_TAGS_FROM_SYMBOLS = gql`
mutation AddOrRemoveTagsFromSymbols(
  $input: MutationSelectGalleryImageTagOnImageItemsInput!,$filterTagGroupId:ID!
) {
  selectGalleryImageTagOnImageItems(input: $input) {
    errorReason
    images {
      id
      tagGroups(filter:[{tagGroupId:$filterTagGroupId, tags:[]}]) {
        tags
      }
    }
  }
}
`

export default ADD_OR_REMOVE_TAGS_FROM_SYMBOLS
