
import React from 'react'
import styles from "../FontStyleDropdown/styles.scss"
import { useTranslation } from 'react-i18next'
import { OrderEditorAreaContext } from '../..'
import AdminSectionTooltip from '../AdminSectionTooltip'
import UnitValueInput from '../UnitValueInput'
import { faBold, faMinusCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Button from 'components/Button'
import { DropdownOption } from 'components/DropdownButton'
import OptionDropdown from 'components/OptionDropdown'

const FontWeightDropdown:React.FC = () => {
    const [t] = useTranslation("editOrder")
    const { setFontWeight, contentRows, area, editor } = React.useContext(
      OrderEditorAreaContext
    )
  
    let defaultFontWeight: string | undefined = undefined
  
    if (area && area?.style && area.style.text && area.style.text.fontWeight) {
      defaultFontWeight = area.style.text.fontWeight
    }
  
    let fontWeightValue: string | null = null
  
    if (
      contentRows !== undefined &&
      contentRows[0] !== undefined &&
      contentRows[0].columns !== undefined &&
      contentRows[0].columns[0] !== undefined &&
      contentRows[0].columns[0].item !== undefined &&
      contentRows[0].columns[0].item.text !== undefined &&
      contentRows[0].columns[0].item.text !== null &&
      contentRows[0].columns[0].item.text.fontWeight !== undefined &&
      contentRows[0].columns[0].item.text.fontWeight !== null
    ) {
      fontWeightValue = contentRows[0].columns[0].item.text.fontWeight
    }
  
    let fontWeights: string[] = []
    const options: { label: string; value: string }[] = []
    if (
      editor &&
      editor.text !== undefined &&
      editor.text !== null &&
      editor.text.allowedFontWeights !== undefined &&
      editor.text.allowedFontWeights !== null
    ) {
      editor.text.allowedFontWeights.forEach((val) => {
        if (val !== undefined && val !== null) {
          options.push({
            label: val.toString(),
            value: val,
          })
          fontWeights.push(val)
        }
      })
    }
  
    const handleDropdownChange = (option: DropdownOption) => {
        setFontWeight(option.value)
    }
  
    const onClear = () => {
        setFontWeight(null)
    }
  
    const renderToggler = () => (
      <AdminSectionTooltip id="fontStyle" text={t("fontWeight")}>
              {fontWeightValue !== null ? (
          <div className={styles.removeIcon} onClick={onClear}>
            <FontAwesomeIcon icon={faMinusCircle} color={"#e6e6e6"} />
          </div>
        ) : null}
        <Button
          variant="primary"
          className={styles.fontStyleButton}
          color={fontWeightValue === null ? "mediumGray" : "secondary"}
        >
          <FontAwesomeIcon icon={faBold} />
        </Button>
      </AdminSectionTooltip>
    )
  
    return (
      <div className={styles.wrapper}>
        <UnitValueInput
          jsx={
            <OptionDropdown
              options={options}
              onChange={handleDropdownChange}
              renderToggler={renderToggler}
              optionListClassName={styles.contentList}
              defaultValue={defaultFontWeight!}
              selectedValue={fontWeightValue}
              listWidth={"100px"}
            />
          }
          isInputDisable={true}
          inputType={"string"}
          onChange={() => console.log}
          value={fontWeightValue}
          placeholder={defaultFontWeight}
         className={styles.fontStyleInput}
        />
      </div>
    )
}

export default FontWeightDropdown