import * as React from "react"
import * as styles from "./styles.scss"
import Modal from "components/Modal"
import ErrorHeader from "./ErrorHeader"
import CommandBar from "./CommandBar"

function InvokeActionResultModal(props: InvokeActionResultModalProps) {
  const { text, isError, ...rest } = props

  return (
    <Modal
      {...rest}
      className={styles.resultModal}
      shouldCloseOnOverlayClick={false}
      showCloseIcon={false}
    >
      <div className={styles.content}>
        {isError && <ErrorHeader />}
        <p className={styles.text}>{text}</p>
      </div>
      <CommandBar onClick={props.onRequestClose} />
    </Modal>
  )
}

interface InvokeActionResultModalProps {
  text: string
  isOpen: boolean
  isError: boolean
  onRequestClose: () => void
}

export default InvokeActionResultModal
