import * as React from "react"
import useOutsideClick from "../../pages/EditOrderPage/useOutsideClick"
import cx from "classnames"
import styled from "styled-components"
import ReactTooltip from "react-tooltip"
export const DropdownWithOptions: React.FC<{
  options: any[]
  onClose: any
  onSelect: any
  isScreenHightTooShort: boolean
}> = (props) => {
  const ref = React.useRef<HTMLDivElement | null>(null)
  const itemsSizes = props.options.length * -60

  useOutsideClick(ref, () => {
    props.onClose()
  })

  return (
    <DivContainer>
      <div
        className={cx("title-dropdown", "custom-dropdown", {})}
        ref={(e) => (ref.current = e)}
        style={props.isScreenHightTooShort ? {top: `${itemsSizes - 60}px`} : {}}
      >
        <div className={"options"}>
          {props.options.map((item, index) => {
            const id = item.id
            const onClick = () => {
              props.onSelect(id)
            }
            return (
              <a
                data-tip={item.hoverText && item.hoverText}
                key={item.id}
                data-testid={item.label}
              >
                <div
                  className={cx("option", {
                    ["disable"]: !item.isEnabled,
                  })}
                  key={id}
                  onClick={onClick}
                >
                  {item.label}
                </div>
              </a>
            )
          })}
        </div>
      </div>
      <ReactTooltip place="left" type="info" effect="solid" />
    </DivContainer>
  )
}

const DivContainer = styled.div`
  .options {
    overflow: hidden !important;
  }
  .disable {
    pointer-events: none;
    color: gray !important;
  }
`
