import * as React from "react"
import CheckboxField from "components/CheckboxField"

function Attachment(props: AttachmentProps) {
  const { onChange, name, ...rest } = props

  const handleChange = () => onChange(name)

  return <CheckboxField {...rest} onChange={handleChange} />
}

interface AttachmentProps {
  label: string
  name: string
  checked: boolean
  onChange: (name: string) => void
}

export default Attachment
