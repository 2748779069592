import gql from "graphql-tag"

const UPDATE_ORDER_LOCK = gql`
  mutation UpdateOrderLock($input: MutationLockOrderInput!) {
    lockOrder(input: $input) {
      isError
      errorReason
      order {
        id
        mediaOrder {
          isLocked
          status {color status}
          __typename
        }
      }
    }
  }
`

export default UPDATE_ORDER_LOCK
