import * as React from "react"
import * as styles from "./styles.scss"
import Button from "components/Button"
import { useTranslation } from "react-i18next"

const SubmitButton: React.FC<SubmitButtonProps> = (props) => {
  const [t] = useTranslation()
  const text =
    props.text !== undefined && props.text !== null && props.text.length > 0
      ? props.text
      : t("save")

  return (
    <div className={styles.buttonContainer}>
      <Button
        id={props.id}
        variant="primary"
        color="primary"
        type="submit"
        isWorking={props.isWorking}
      >
        {text}
      </Button>
    </div>
  )
}

interface SubmitButtonProps {
  isWorking?: boolean
  text?: string
  id?: string
}

export default SubmitButton
