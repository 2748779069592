import * as React from "react"
import * as styles from "./styles.scss"
import OptionDropdown from "components/OptionDropdown"
import { useTranslation } from "react-i18next"
import cx from "classnames"
import { DropdownOption } from "components/DropdownButton"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function FavoritesDropdown(props: FavoritesDropdownProps) {
  const [t] = useTranslation("editOrder")
  const selectFavorite = t("selectFavorite")

  const handleOpenFavoriteModal = () => {
    if (props.saveSearchResultDisabled) return
    props.onOpenAddToFavoritesModal()
  }

  const getDropdownOptions = () => [
    { value: undefined, label: selectFavorite },
    ...props.companyTags.map(tag => ({
      value: tag,
      label: tag,
    })),
  ]

  return (
    <div className={cx(styles.favorites, {
      [styles.feView__favorites]: props.isFEUser
    })}>
      {
        props.isSymbolModal ? (
          <div className={styles.headerContainer} onClick={handleOpenFavoriteModal}>
            <FontAwesomeIcon  icon={"star"}/>
          <div>
          <b>{t("favorites")}</b>
          </div>
        </div>

        ): (
          <div className={styles.header} onClick={handleOpenFavoriteModal}>
          <b>{t("favorites")}</b>
          <span
            className={cx(styles.saveSearchResult, {
              [styles.disabled]: props.saveSearchResultDisabled,
            })}
          >
            {t("saveSearchResultToFavorites")}
          </span>
        </div>
        )
      }
      <OptionDropdown
        options={getDropdownOptions()}
        selectedLabel={
          props.selectedFavoriteTag ? props.selectedFavoriteTag : selectFavorite
        }
        onChange={props.onSetSelectedFavoriteTag}
        togglerClassName={styles.favoritesToggler}
      />
    </div>
  )
}

interface FavoritesDropdownProps {
  companyTags: string[]
  saveSearchResultDisabled: boolean
  selectedFavoriteTag?: string
  onSetSelectedFavoriteTag: (option: DropdownOption) => void
  onOpenAddToFavoritesModal: () => void
  isSymbolModal:boolean
  isFEUser: boolean
}

export default FavoritesDropdown
