import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import i18nNameSpaces from "./i18nNameSpaces"
import Backend from "i18next-xhr-backend"
// import LanguageDetector from "i18next-browser-languagedetector"
// import i8n from 'i18next'

i18n
  .use(Backend)
  .use(initReactI18next)
  // .use(LanguageDetector)
  .init({
    debug: false,
    initImmediate: false,
    fallbackLng: "en",
    lng: "sv",
    // initImmediate: true,
    interpolation: {
      escapeValue: false
    },
    ns: i18nNameSpaces,
    defaultNS: "common",
    preload: ["sv", "en", "nb"],
  })

export default i18n
