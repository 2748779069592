import { faMinusCircle } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"
import * as styles from "./styles.scss"
import cx from "classnames"
import { MeasurementInputTYPE } from "customSchema.interface"

const MeasurementInput: React.FC<{
  label?: string
  unit: string
  onChange: (e: any) => void
  placeholder?: number | null
  value?: any
  onClear: (id:string) => void
  type: MeasurementInputTYPE
  setBlockSize: (value: number| null, type: MeasurementInputTYPE) => void
}> = (props) => {

  const handleClick = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.code === "Enter" || e.code === "NumpadEnter") {
      updateValue()
    }
  }

  const handleBlur = () => {
    updateValue()
  }

  const handleFocus = () => {}

  const updateValue = () => {
    props.setBlockSize(props.value, props.type)
  }

  return (
    <div className={styles.measurementInputWrapper}>
      <div className={styles.inputWrapper}>
        <div className={styles.label}>
          {props.label ? <span>{props.label}</span> : <span>Rubrik</span>}
        </div>
        <input
          type={"number"}
          className={styles.measurementInput}
          onChange={props.onChange}
          onKeyDown={(e)=>handleClick(e)}
          onFocus={handleFocus}
          onBlur={handleBlur}
          placeholder={props.placeholder?.toString()}
          value={props.value || ""}
          step={0.5}      
        />

        <div className={styles.unit}>
          <span>{props.unit}</span>
        </div>

        {(props.value !== null && props.value > 0) && (
          <div className={cx(styles.reset)} onClick={() => props.onClear(props.type)}>
            <FontAwesomeIcon icon={faMinusCircle} />
          </div>
        )}
      </div>
    </div>
  )
}

export default MeasurementInput
