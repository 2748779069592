import * as React from "react"
import MainLayout from "layouts/MainLayout"
import * as styles from "./styles.scss"
import TabContainer from "components/TabContainer"
import BackgroundImage from "components/BackgroundImage"
import bg from "images/image.png"
import SettingsTab from "./SettingsTab"
import UsersTab from "./UsersTab"
import { useTranslation } from "react-i18next"
import { omitTypename } from "utils"
import PricesTab from "./PricesTab"
import PackagesTab from "./PackagesTab"
import StatisticsTab from "./StatisticsTab"
// import { useQuery } from "@apollo/react-hooks"
import { useQuery } from "react-apollo-hooks"
import GET_CURRENT_CUSTOMER from "graphql/queries/getCurrentCustomer"
import { SessionStorageKeys } from "environmentUtils"

export const AgencySettingsContext = React.createContext(
  {} as AgencySettingsContextValue
)

function AgencySettings() {
  // const { currentCustomer } = React.useContext(AppContext)
  const [selectedTabId, setSelectedTabId] = React.useState("settings")
  const [t] = useTranslation("agencySettings")
  const token = sessionStorage.getItem(SessionStorageKeys.AUTH_TOKEN)
  const { data: currentCustomerData, refetch } = useQuery(
    GET_CURRENT_CUSTOMER,
    {
      skip: !token,
    }
  )

  // const [settings, setSettings] = React.useState(
  //   omitTypename({
  //     office: currentCustomerData.currentCustomer.office.inheritSettings,
  //     company:
  //       currentCustomerData.currentCustomer.office.company.inheritSettings,
  //   })
  // )

  function handleSetSettings(setting: string, scope: Scope, newValue: any) {
    refetch()
    // setSettings({
    //   ...settings,
    //   [scope]: {
    //     ...settings[scope],
    //     [setting]: newValue,
    //   },
    // })
  }

  const handleTabClick = (id: string) => setSelectedTabId(id)

  return (
    <MainLayout showUtilityBar={false}>
      <BackgroundImage src={bg} />
      <AgencySettingsContext.Provider
        value={{
          settings: omitTypename({
            office: currentCustomerData.currentCustomer.office.inheritSettings,
            company:
              currentCustomerData.currentCustomer.office.company
                .inheritSettings,
          }),
          changeSetting: handleSetSettings,
        }}
      >
        <div className={styles.agencySettingsContainer}>
          {/* <ContactSettings settings={settings} /> */}
          <TabContainer
            tabs={[
              {
                id: "settings",
                label: t("companySettings"),
                component: () => (
                  <SettingsTab
                    settings={omitTypename({
                      office:
                        currentCustomerData.currentCustomer.office
                          .inheritSettings,
                      company:
                        currentCustomerData.currentCustomer.office.company
                          .inheritSettings,
                    })}
                    changeSetting={handleSetSettings}
                  />
                ),
              },
              {
                id: "statistik",
                label: t("StatisticsTab_header"),
                component: StatisticsTab,
              },
              { id: "users", label: t("users"), component: UsersTab },
              {
                id: "agencyPackages",
                label: t("agencyPackages"),
                component: PackagesTab,
              },
              { id: "prices", label: t("prices"), component: PricesTab },
            ]}
            selectedTabId={selectedTabId}
            onSelectTab={handleTabClick}
          />
        </div>
      </AgencySettingsContext.Provider>
    </MainLayout>
  )
}

interface AgencySettingsContextValue {
  settings: Settings
  changeSetting: (setting: string, scope: Scope, newValue: any) => void
}

type Scope = "office" | "company"

interface Settings {
  office: any
  company: any
}

export default AgencySettings
