import * as React from "react"
import * as styles from "./styles.scss"
import MediaItem from "./MediaItem"
import { GetPackages_orderInitiatorPackagesByCurrentUser } from "schema"

function MediaItems(props: MediaItemsProps) {
  return (
    <div className={styles.PackagesMediaItems}>
      {props.package.items!.map((item, index) => (
        <MediaItem
          key={`${index}_${item.documentFormatInitiator}`}
          item={item}
          package={props.package}
          onUpdatePackage={props.onUpdatePackage}
        />
      ))}
    </div>
  )
}

interface MediaItemsProps {
  package: GetPackages_orderInitiatorPackagesByCurrentUser
  onUpdatePackage: (packageInput: any) => Promise<any>
}

export default MediaItems
