import * as React from "react"
import * as styles from "./styles.scss"
import Modal from "components/Modal"
import Header from "components/Header"
import { useTranslation } from "react-i18next"
import { Form } from "react-final-form"
import FieldWrapper from "components/FieldWrapper"
import TextInput from "components/TextInput"
import Button from "components/Button"
import { useApolloClient, useMutation } from "react-apollo-hooks"
import UPDATE_PACKAGE from "graphql/mutations/updatePackage"
import GET_PACKAGES from "graphql/queries/getPackages"
import {
  CustomerScope,
  OrderInitiatorPackageInput,
  UpdatePackage,
  UpdatePackageVariables,
} from "schema"

function NewPackageModal(props: NewPackageModalProps) {
  const [t] = useTranslation("agencySettings")
  const client = useApolloClient()
  const updateOrder = useMutation<UpdatePackage, UpdatePackageVariables>(
    UPDATE_PACKAGE,
    {
      client,
      refetchQueries: [{ query: GET_PACKAGES }],
    }
  )

  const validate = (values: any) => {
    const errors: { [key: string]: string } = {}

    if (!values.name) {
      errors.name = "Paketet måste ha ett namn"
    }
    if (values.commonPrice && isNaN(values.commonPrice)) {
      errors.commonPrice = "Priset måste vara ett nummer"
    }

    return errors
  }

  type FormValues = { name: string; commonPrice: string }
  const submit = (values: FormValues) => {
    const errors = validate(values)

    if (Object.keys(errors).length) {
      return errors
    }

    const orderInitiatorPackages: OrderInitiatorPackageInput[] = [
      {
        commonPrice: values.commonPrice,
        name: values.name,
        accessCustomerScope: CustomerScope.OFFICE,
        items: [],
      },
    ]

    updateOrder({
      variables: {
        input: {
          orderInitiatorPackages,
        },
      },
    }).then(props.onRequestClose)

    return
  }

  return (
    <Modal
      {...props}
      className={styles.newPackageModal}
      loadingText="Lägger till paket"
    >
      <Header title={t("addPackage")} />
      <Form
        onSubmit={submit}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <FieldWrapper
              component={TextInput}
              label={t("packageName")}
              name="name"
            />
            <FieldWrapper
              component={TextInput}
              marginTop={true}
              label={t("commonPrice")}
              name="commonPrice"
            />
            <div className={styles.buttonContainer}>
              <Button type="submit" variant="primary">
                {t("createPackage")}
              </Button>
            </div>
          </form>
        )}
      />
    </Modal>
  )
}

interface NewPackageModalProps {
  isOpen: boolean
  onRequestClose: () => void
}

export default NewPackageModal
