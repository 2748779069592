import * as React from "react"
import * as styles from "./styles.scss"
import { Query } from "react-apollo"
import GET_PACKAGES from "graphql/queries/getPackages"
import Spinner from "components/Spinner"
import Header from "components/Header"
import { GetPackages } from "schema"
import Packages from "./Packages"
import { omitTypename } from "utils"
import { WithTranslation, withTranslation } from "react-i18next"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import * as cx from "classnames"
import { AddProductGroupOrderModal } from "components/AddProductGroupOrderModal/AddProductGroupOrderModal"
import Button from "components/Button"
import { useHistory } from "react-router-dom"

const PackagesContainer: React.FC<PackagesContainerProps & WithTranslation> = (
  props
) => {
  const { t, isActive } = props
  const history = useHistory()

  const openModal = () => {
    const { search } = window.location
    const urlParams = new URLSearchParams(search)
    urlParams.set("action", "add_product_casefolder")
    const newSearch = urlParams.toString()
    history.push({
      search: newSearch,
    })
  }

  return (
    <section
      className={cx(styles.packagesContainer, {
        [styles.isShowBorder]: props.isShowBorder,
      })}
      id="packagesContainer"
    >
      <div
        className={cx(styles.mainWrapper, {
          [styles.inactive]:
            isActive === undefined ? false : isActive === false ? true : false,
        })}
      >
        <div className={styles.headerPackages}>
          <Button onClick={openModal} dataTestId={"addProduct"}>
            <FontAwesomeIcon icon="plus-circle" />
            <span>{t("common:addProduct")}</span>
          </Button>

          <Header
            className={styles.header}
            title={t("selectPackage")}
            subtitle={t("")}
          />
        </div>

        <div className={styles.packages}>
          <Query<GetPackages> query={GET_PACKAGES}>
            {({ loading, data }) => {
              if (loading) return <Spinner />
              return (
                <Packages
                  caseFolderId={props.caseFolderId}
                  packages={omitTypename(
                    data!.orderInitiatorPackagesByCurrentUser
                  )}
                />
              )
            }}
          </Query>
        </div>
        <AddProductGroupOrderModal caseFolderId={props.caseFolderId} />
      </div>
    </section>
  )
}

interface PackagesContainerProps {
  caseFolderId?: string
  isActive?: boolean
  showBackButton?: boolean
  onClickBackButton?: () => void
  isShowBorder?: boolean
}

export default withTranslation("newCaseFolder")(PackagesContainer)

{
  /* {this.props.showBackButton ? (
              <BackButton onClick={this.props.onClickBackButton} />
            ) : null} */
}
// const BackButton: React.FC<{
//   onClick?: () => void
// }> = (props) => {
//   const { t } = useTranslation(["common"])

//   return (
//     <div className={styles.backBtnContainer}>
//       <Button
//         variant="secondary"
//         color="primary"
//         type="submit"
//         disabled={false}
//         isWorking={false}
//         onClick={props.onClick}
//       >
//         {t("common:back")}
//       </Button>
//     </div>
//   )
// }
