import * as React from "react"
import * as styles from "./styles.scss"
import Grayframe from "components/Grayframe"
import * as cx from "classnames"
import BaseRow from "./BaseRow"
import ExpandedContent from "./ExpandedContent"
import {
  EditModelAdvertAreaContentEditorTypes,
  GetEditOrderPageCaseFolder_caseFolder_editTabs,
  GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_help,
  GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_validateErrors,
} from "schema"
import { OrderEditorContext } from "./../../../../../OrderEditoryAdvert"
import { useContextSelector } from "use-context-selector"
import Help from "./Help"

const Area: React.FC<Props> = (props) => {
  const {
    label,
    onClick,
    onMouseEnter,
    onMouseLeave,
    isExpanded,
    type,
    children,
  } = props

  const { isLocked } = useContextSelector(OrderEditorContext, (s) => {
    return {
      isLocked: s.isLocked,
    }
  })

  return (
    <Grayframe
      className={cx(styles.item, {
        [styles.adstateviews__item]: props.isFEUser,
        [styles.expanded]: isExpanded,
        [styles.disabled]: isLocked,
      })}
    >
      <BaseRow
        label={label}
        onClick={onClick}
        isExpanded={isExpanded}
        disabled={isLocked}
        validationError={props.validateErrors}
        isFEUser={props.isFEUser}
        help={props.help}
      />
      {children && (
        <ExpandedContent
          isExpanded={isExpanded}
          type={type}
          editTabs={props.editTabs}
          isFEUser={props.isFEUser}
          isOverflowHidden={props.isOverflowHidden}
        >
          <Help help={props.help} isFEuser={props.isFEUser} />
          {children}
        </ExpandedContent>
      )}
    </Grayframe>
  )
}

interface Props {
  label: string
  isExpanded: boolean
  // component: React.ComponentClass<any> | React.StatelessComponent<any> | null
  onClick: () => void
  onMouseEnter: () => void
  onMouseLeave: () => void
  type: EditModelAdvertAreaContentEditorTypes
  editTabs: GetEditOrderPageCaseFolder_caseFolder_editTabs[]
  validateErrors: GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_validateErrors[]
  isFEUser: boolean
  isOverflowHidden: boolean
  help:  GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_help | null
}
export default Area
