import "icons"
import * as React from "react"
import "customTypes"
import "react-table-6/react-table.css"
import "styles/globals.scss"
import "normalize.css"
import { DragDropContext } from "react-dnd"
import HTML5Backend from "react-dnd-html5-backend"
import MagicLinkAuthenticator from "components/MagicLinkAuthenticator"
import AppContextDataProvider, {
  TFeatureFlag,
} from "components/AppContextDataProvider"
import {
  GetCurrentUserSession_currentUserSession,
  GetCurrentCustomer_currentCustomer,
} from "schema"
import { LoggedinProviders } from "./LoggedinProviders"
import { UnathorizedRoutes } from "./UnathorizedRoutes"
import { log } from "appLog"
import { setLocale } from "yup"
import * as sv from "./yupLocaleSv"
import { LocalStorageKeys } from "environmentUtils"
import { ThemeProvider } from "styled-components"
import { theme } from "styles/themeStyled"
import { Route, Switch } from "react-router-dom"
import LoginPage from "pages/LoginPage"
import AutoLoginTempSessionToken from "pages/LoginPage/AutoLogin/autoLoginTempSessionToken"

export const AppContext = React.createContext<AppContextValue>({} as any)

class App extends React.Component<{}, AppState> {
  state = {
    showSupportView:
      localStorage.getItem(LocalStorageKeys.SHOW_SUPPORT_VIEW) === "true",
    showMode: localStorage.getItem(LocalStorageKeys.SHOW_MODE_VIEW) === "true",
  }

  toggleShowSupportView = () => {
    const showSupportView = localStorage.getItem(
      LocalStorageKeys.SHOW_SUPPORT_VIEW
    )
    this.setState({ showSupportView: !this.state.showSupportView })
    localStorage.setItem(
      LocalStorageKeys.SHOW_SUPPORT_VIEW,
      showSupportView === "true" ? "false" : "true"
    )
  }

  toggleShowModeView = () => {
    const showModeView = localStorage.getItem(LocalStorageKeys.SHOW_MODE_VIEW)

    this.setState({ showMode: !this.state.showMode })
    localStorage.setItem(
      LocalStorageKeys.SHOW_MODE_VIEW,
      showModeView === "true" ? "false" : "true"
    )
  }

  componentDidMount() {
    setLocale(sv)
  }

  render() {
    log(`App: rerendered`)
    return (
      <ThemeProvider theme={theme}>
        <Switch>
          <Route path="/login" component={LoginPage} />
          <Route
            path="/autologintempsessiontoken/:token"
            component={AutoLoginTempSessionToken}
          />
          <Route>
            <AppContextDataProvider>
              {({ currentUserSession, currentCustomer, featureFlags }) => {
                const isAuthenticated =
                  currentUserSession !== undefined &&
                  currentUserSession !== null &&
                  currentUserSession.isAuthenticated === true
                return (
                  <React.Fragment>
                    <MagicLinkAuthenticator />
                    {isAuthenticated === true ? (
                      <LoggedinProviders
                        currentCustomer={currentCustomer}
                        currentUserSession={currentUserSession}
                        toggleShowSupportView={this.toggleShowSupportView}
                        showSupportView={this.state.showSupportView}
                        isShowModeShow={this.state.showMode}
                        toggleShowModeView={this.toggleShowModeView}
                        featureFlags={featureFlags}
                      />
                    ) : (
                      <UnathorizedRoutes />
                    )}
                  </React.Fragment>
                )
              }}
            </AppContextDataProvider>
          </Route>
        </Switch>
      </ThemeProvider>
    )
  }
}

export const useAppStoreContextState = () => {
  const context = React.useContext(AppContext)
  if (context === undefined) {
    throw new Error("useContextState must be used within a Provider")
  }
  return context
}

interface AppState {
  showSupportView: boolean
  showMode: boolean
}

interface AppContextValue {
  currentUserSession: GetCurrentUserSession_currentUserSession
  currentCustomer: GetCurrentCustomer_currentCustomer
  showSupportView: boolean
  toggleShowSupportView: () => void
  isShowModeShow: boolean
  toggleShowModeView: () => void
  featureFlags: TFeatureFlag
}

export default DragDropContext(HTML5Backend)(App)
