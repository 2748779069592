import React from "react"
import styles from "../styles.scss"
import GET_AVAILIABLE_DOCUMENT_FORMATS_COLLECTIONS from "graphql/queries/getAvaiableDocumentFormatCollections"
import { useApolloClient, useQuery } from "react-apollo-hooks"
import {
  GetAvaiableDocumentFormatCollections,
  GetAvaiableDocumentFormatCollectionsVariables,
  GetAvaiableDocumentFormatCollections_orders_mediaOrder_availableDocumentFormatCollections,
} from "schema"
import Spinner from "components/Spinner"
import ErrorMessage from "components/ErrorMessage"

const Collections: React.FC<{
  orderId: string
  getCollectionId: (collectionId: string) => void
}> = (props) => {
  const client = useApolloClient()

  const { data, loading, error } = useQuery<
    GetAvaiableDocumentFormatCollections,
    GetAvaiableDocumentFormatCollectionsVariables
  >(GET_AVAILIABLE_DOCUMENT_FORMATS_COLLECTIONS, {
    client,
    context: {
      debounceKey: "document-format",
      debounceTimeout: 750,
    },
    variables: {
      orderId: props.orderId!,
    },
  })

  let collections:
    | undefined
    | GetAvaiableDocumentFormatCollections_orders_mediaOrder_availableDocumentFormatCollections[]

  if (data && data.orders) {
    collections = data.orders[0].mediaOrder?.availableDocumentFormatCollections
  }

  if (error) {
    return (<ErrorMessage message={error.message} />)
  }

  return (
    <div className={styles.collection}>
      <div className={styles.label}>
        <h3>Välj utseende</h3>
      </div>
      <div className={styles.content}>
        {loading && (
          <div className={styles.spinner}>
            <Spinner />
          </div>
        )}
        <ul>
          {collections &&
            collections.map((collection, index) => {
              return (
                <li
                  key={index}
                  onClick={() => props.getCollectionId(collection.id)}
                >
                  <img src={collection.thumbnailUrl!} alt="" />
                  <div className={styles.footer}>
                    <span>{collection.name}</span>
                  </div>
                </li>
              )
            })}
        </ul>
      </div>
    </div>
  )
}

export default Collections
