import * as React from "react"
import * as styles from "./styles.scss"
import Toolbar from "./Toolbar"
import ColumnEditor from "./ColumnEditor"
import {
  EditModelAdvertAreaContentEditorBehaviours,
  GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_text_allowedParagraphClasses,
  GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows,
} from "schema"
import produce from "immer"
import { HtmlInputAdminSection } from "../HtmlInputAdminSection"
import ColumnContainer from "./ColumnView/ColumnContainer"
import InfoRow from "./ColumnView/ColumnContainer/InfoRow"
import { AppContext } from "App"
import _ from "lodash"

type TParagraphClasses = GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_text_allowedParagraphClasses

const HtmlInput: React.FC<{
  contentRows: GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows[]
  isVerseGalleryEnabled: boolean
  manuallySetContentRows: any
  onXmlTextChange: any
  editorBehaviour: EditModelAdvertAreaContentEditorBehaviours
  blockStyles: any[]
  isExpanded?: boolean
  isEnabledMultiColumns: boolean | undefined
  isLocked: boolean
  onSelectBlockStyle: (
    value: string | null,
    rowIndex: number,
    columnIndex: number
  ) => void
  symbols?: any
  paragraphClasses: TParagraphClasses[]
  isFEUser: boolean
}> = (props) => {


  const {isSupportUser} = React.useContext(AppContext).currentUserSession.user!

  const columnEditorSettings = props.paragraphClasses.reduce(
  
    (obj: any, item: TParagraphClasses) => {
      let short:string;

      return Object.assign(obj, {
        [item.id]: {
          name: item.name,
          short: item.name.charAt(0),
        },
      })
    },
    {})



  const [editorRows, setEditorRows] = React.useState<
    GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows[]
  >([])
  const [inEditColumnsMode, setInEditColumnsMode] = React.useState(false)
  const [inEditColumnsRows, setInEditColumnsRows] = React.useState<
    GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows[]
  >([])
  const [adminSectionIsExpanded, setAdminSectionIsExpanded] = React.useState(
    false
  )

  const getNewRowItem = () => {
    const { xmlText, ...rest } = editorRows[0].columns[0].item.text!

    return {
      item: {
        text: { xmlText: " ", ...rest },
        image: null,
        separator: null,
        common: null,
      },
    }
  }

  const enterColumnEditMode = () => {
    setInEditColumnsMode(true)
  }

  const exitColumnEditMode = () => {
    setInEditColumnsRows(editorRows)
    setInEditColumnsMode(false)
  }

  const addRow = () => {
    const contentRows = produce(inEditColumnsRows, (draft) => {
      draft.push({
        columns: [getNewRowItem() as any],
      })
    })
    setInEditColumnsRows(contentRows)
  }

  const removeRow = (index: number) => {
    const contentRows = produce(inEditColumnsRows, (draft) => {
      draft.splice(index, 1)
    })
    setInEditColumnsRows(contentRows)
  }

  const saveEditorRows = () => {
    setEditorRows(inEditColumnsRows)
    props.manuallySetContentRows(inEditColumnsRows)
    setInEditColumnsMode(false)
  }

  const changeColumn = (index: number, columns: number) => {
    const contentRows = produce(inEditColumnsRows, (draft) => {
      const row = draft[index]

      if (row.columns.length < columns) {
        const difference = columns - row.columns.length
        for (let i = 0; i < difference; i++) {
          row.columns.push(getNewRowItem() as any)
        }
      } else if (row.columns.length > columns) {
        const difference = row.columns.length - columns
        for (let i = 0; i < difference; i++) {
          row.columns.pop()
        }
      }
    })
    setInEditColumnsRows(contentRows)
  }

  const toggleAdminSectionIsExpanded = () => {
    setAdminSectionIsExpanded(!adminSectionIsExpanded)
  }

  React.useEffect(() => {
    // Changes Kenneth 20210510 : All changes in contentRows will be stored in contentRows.
    const contentRows = produce(props.contentRows, (draft) => {
      //
    })
    setInEditColumnsRows(contentRows)
    setEditorRows(contentRows)
  }, [props.contentRows])

  if (!editorRows || editorRows.length < 0) {
    return null
  }

  const isXmlTextReadonly =
  props.contentRows[0]?.columns[0]?.item.text?.isXmlTextReadonly !=
    undefined &&
  props.contentRows[0]?.columns[0]?.item.text?.isXmlTextReadonly

  return (
    <div className={styles.container}>
      {inEditColumnsMode ? (
        <ColumnEditor
          onAdd={addRow}
          rows={inEditColumnsRows}
          onRemove={removeRow}
          onClose={exitColumnEditMode}
          onSave={saveEditorRows}
          onChangeColumn={changeColumn}
        />
      ) : (
        <React.Fragment>
          <React.Fragment>
            <div className={styles.rows}>
              {inEditColumnsRows.map((row, i) => (
                <div key={`HtmlInput_row_${i}`} className={styles.row}>
                  {row.columns.map((column: any, j: number) => {
                    const onChange = (
                      event: React.ChangeEvent<HTMLInputElement>
                    ) => {
                      const rowIndex = i
                      const colIndex = j
                      const newText = event.target.value

                      const newState = props.onXmlTextChange(
                        newText,
                        rowIndex,
                        colIndex
                      )
                      // Changes Kenneth 202100510 : Make changes in the state both in parent and in this component
                      setInEditColumnsRows(newState.contentRows)
                      setEditorRows(newState.contentRows)
                    }

                    const onSelectBlockStyle = (value: string | null) => {
                      props.onSelectBlockStyle(value, i, j)
                    }

                    const showInfoRow =
                      inEditColumnsRows.length > 1 || row.columns.length > 1

                    const isValueDemo =
                      props.contentRows[0]?.columns[0]?.item.isDemoValue !=
                        undefined &&
                      props.contentRows[0]?.columns[0]?.item.isDemoValue

                    return (
                      <div
                        key={`HtmlInput_column_${j}`}
                        className={styles.columnContainer}
                      >
                        {showInfoRow ? (
                          <InfoRow
                            columnCount={row.columns.length}
                            rowIndex={i}
                            index={j}
                          />
                        ) : null}

                        <ColumnContainer
                          isVerseGalleryEnabled={props.isVerseGalleryEnabled}
                          symbols={props.symbols}
                          inputValue={column.item.text.xmlText}
                          pStyles={columnEditorSettings}
                          onChange={onChange}
                          isValueDemo={isValueDemo}
                          blockStyles={props.blockStyles}
                          rowItems={props.contentRows[0]?.columns}
                          onSelectBlockStyle={onSelectBlockStyle}
                          isExpanded={props.isExpanded}
                          onEnterColumnEdit={enterColumnEditMode}
                          isEnabledMultiColumns={props.isEnabledMultiColumns}
                          isLocked={props.isLocked}
                          editorBehaviour={props.editorBehaviour}
                          isFEUser={props.isFEUser}
                          editorViewBaseOnUserAndFields={{
                            isSupportUser: isSupportUser,
                            isFields: (column.item.fields?.length ?? 0) > 0 || !_.isEmpty(column.item.fields)
                          }}
                          isXmlTextReadonly={isXmlTextReadonly!}
                        />
                      </div>
                    )
                  })}
                </div>
              ))}
            </div>
          </React.Fragment>
          <Toolbar
            // onEnterColumnEdit={enterColumnEditMode}
            toggleAdminSectionIsExpanded={toggleAdminSectionIsExpanded}
            adminSectionIsExpanded={adminSectionIsExpanded}
          />
          <HtmlInputAdminSection isExpanded={adminSectionIsExpanded} />
        </React.Fragment>
      )}
    </div>
  )
}
  // const {
  //   TEXT_HTML,
  //   TEXT_HTML_FOOTER,
  //   TEXT_HTML_INGRESS,
  //   TEXT_HTML_PERSON,
  //   TEXT_HTML_PERSON_BORN_DATE,
  //   TEXT_HTML_PERSON_BORN_NAME,
  //   TEXT_HTML_PERSON_DIED_DATE,
  //   TEXT_HTML_RELATIVES,
  //   TEXT_HTML_VERSE,
  // } = EditModelAdvertAreaContentEditorBehaviours
  // const delayUpdate = React.useRef(
  //   debounce(
  //     (triggerAction, args) => {
  //       return triggerAction(args.newText, args.rowIndex, args.colIndex)
  //     },
  //     750,
  //     {
  //       trailing: true,
  //     }
  //   )
  // )

  // debugger
  // const columnEditorSettings = HTML_EDITOR_SETTINGS[CULTURE][props.editorBehaviour] ?? {}

// const HTML_EDITOR_CONFIG_TEXT_HTML = {}

// const {
//   TEXT_HTML,
//   TEXT_HTML_FOOTER,
//   TEXT_HTML_INGRESS,
//   TEXT_HTML_PERSON,
//   TEXT_HTML_PERSON_BORN_DATE,
//   TEXT_HTML_PERSON_BORN_NAME,
//   TEXT_HTML_PERSON_DIED_DATE,
//   TEXT_HTML_RELATIVES,
//   TEXT_HTML_VERSE,
// } = EditModelAdvertAreaContentEditorBehaviours

// const HTML_EDITOR_SETTINGS = {
//   "sv-SE": {
//     [TEXT_HTML]: HTML_EDITOR_CONFIG_TEXT_HTML,
//     [TEXT_HTML_FOOTER]: HTML_EDITOR_CONFIG_TEXT_HTML,
//     [TEXT_HTML_INGRESS]: HTML_EDITOR_CONFIG_TEXT_HTML,
//     [TEXT_HTML_PERSON]: HTML_EDITOR_CONFIG_TEXT_HTML,
//     [TEXT_HTML_PERSON_BORN_DATE]: HTML_EDITOR_CONFIG_TEXT_HTML,
//     [TEXT_HTML_PERSON_BORN_NAME]: HTML_EDITOR_CONFIG_TEXT_HTML,
//     [TEXT_HTML_PERSON_DIED_DATE]: HTML_EDITOR_CONFIG_TEXT_HTML,
//     [TEXT_HTML_RELATIVES]: {
//       normal: {
//         name: "Normal",
//         short: "N",
//       },
//       personRelative1: {
//         name: "Make/Maka",
//         short: "M",
//       },
//       personRelative2: {
//         name: "Barn",
//         short: "B",
//       },
//       personRelative3: {
//         name: "Barnbarn",
//         short: "B",
//       },
//       personRelative4: {
//         name: "Barnbarnsbarn",
//         short: "B",
//       },
//       personRelative5: {
//         name: "Alternativ stil",
//         short: "A",
//       },
//     },
//     [TEXT_HTML_VERSE]: {
//       normal: {
//         name: "Normal",
//         short: "N",
//       },
//       verseSignature: {
//         name: "Signatur",
//         short: "S",
//       },
//     },
//   },
// }

// const CULTURE = "sv-SE"

export default HtmlInput
