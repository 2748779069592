import { WebSocketLink } from "apollo-link-ws"
import { getMainDefinition } from "apollo-utilities"
import {
  getGQLEndpoint,
  getWSGQLEndpoint,
  SessionStorageKeys,
} from "environmentUtils"
import { ApolloClient } from "apollo-client"
import { createHttpLink } from "apollo-link-http"
import { InMemoryCache, NormalizedCacheObject } from "apollo-cache-inmemory"
import { ApolloLink, split } from "apollo-link"
import { onError } from "apollo-link-error"
import { log, error, info, trace, debug } from "appLog"
import { Observable } from "apollo-client/util/Observable"
import DebounceLink from "./DebounceLink"
import { GraphQLError } from "graphql"
import { ServerError, ServerParseError } from 'apollo-link-http-common';

const GQL_ENDPOINT = `${getGQLEndpoint()}/graphql`
const WS_ENDPOINT = `${getWSGQLEndpoint()}/graphql`

// tslint:disable-next-line: no-unnecessary-initializer
let showErrorMessage: any | undefined = undefined
// tslint:disable-next-line: no-unnecessary-initializer
let client: ApolloClient<NormalizedCacheObject> | undefined = undefined

const getClientSessionId = (): string => {
  let id = sessionStorage.getItem(SessionStorageKeys.CLIENT_SESSION_ID)
  if (!id) {
    id = Math.random().toString(36).substr(2, 9)
    sessionStorage.setItem(SessionStorageKeys.CLIENT_SESSION_ID, id)
  }
  return id
}

const httpLink = createHttpLink({ uri: GQL_ENDPOINT })
// const httpLink = ApolloLink.from([
//   new DebounceLink(350) as any,
//   createHttpLink({ uri: GQL_ENDPOINT }),
// ])

const wsLink = new WebSocketLink({
  options: {
    connectionParams: async () => ({
      Authorization: sessionStorage.getItem(SessionStorageKeys.AUTH_TOKEN),
      ClientSessionId: getClientSessionId(),
    }),
    reconnect: true,
  },
  uri: WS_ENDPOINT,
})



const authLink = new ApolloLink((operation, forward) => {
  operation.setContext({
    headers: {
      Authorization: sessionStorage.getItem(SessionStorageKeys.AUTH_TOKEN),
      ClientSessionId: getClientSessionId(),
    },
  })

  return forward ? forward(operation) : null
})

const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
  if (graphQLErrors) {
    const context = operation.getContext()

    if (showErrorMessage !== undefined) {
      if (
        context &&
        (context.skipErrorModal === true || context.skipError === true)
      ) {
        console.log("Skip error modal")
      } else {

        let tapsMessage : GraphQLError|null = null
        if(graphQLErrors.some(q=>q.message == "TapsError")) {
          tapsMessage = graphQLErrors.filter(q=>q.message == "TapsError")[0];
        } else {
          tapsMessage = graphQLErrors[0]
        }
        if(tapsMessage) {
          const message = tapsMessage.extensions?.data?.errorMessage ?? tapsMessage.extensions?.data?.errorMessageEN ?? tapsMessage.message;
          const errorDetail = tapsMessage?.extensions?.data?.errorDetail != null ? JSON.parse(tapsMessage.extensions.data.errorDetail) : tapsMessage
          const errorAll = tapsMessage

          showErrorMessage({ message, error: { errorDetail, errorAll } })
        }        
      }
    }



  } else  if (networkError) {
    if(showErrorMessage) {
      var serverError = networkError as ServerError;
      var serverParseError = networkError as ServerParseError;
      if(serverError)
        showErrorMessage({message:"Server error", error:{ errorAll : {statusCode : serverError.statusCode, message:serverError.message, stack:serverError.stack, name:serverError.name }} })
      else if(serverParseError)
        showErrorMessage({message:"Server error", error:{ errorAll : {statusCode : serverParseError.statusCode, message:serverParseError.message, bodyText:serverParseError.bodyText, stack:serverParseError.stack, name:serverParseError.name }} })
      else 
        showErrorMessage({message:"Network error", error:{ errorAll : {message : networkError.message, stack:networkError.stack, name:networkError.name }} })
    }
    // error(`[Network error]: ${networkError}`)
  }
})

const link = split(
  ({ query }) => {
    const mainDef = getMainDefinition(query)
    return (
      mainDef.kind === "OperationDefinition" &&
      mainDef.operation === "subscription"
    )
  },
  wsLink,
  httpLink
)

export const getApolloClient = () => {
  return client
}

const ENV = process.env.REACT_APP_ENVIRONMENT

export const createApolloClient = ({ showMessage }: { showMessage: any }) => {
  log("createApolloClient - triggered...")

  if (client === undefined) {
    log(`index: try to create instance`)
    client = new ApolloClient({
      link: ApolloLink.from([
        new DebounceLink(350),
        errorLink as any,
        authLink,
        cancelRequestLink,
        link,
      ]),
      cache: new InMemoryCache(),
      connectToDevTools: ENV !== "prod",
      queryDeduplication: false,
      defaultOptions: {
        query: {
          fetchPolicy: "network-only",
        },
      },
    })
    log("index: apollo client created")
  }

  // INFO: add to query/mutation context field {skipErrorModal: true}
  if (showMessage !== undefined) {
    showErrorMessage = showMessage
  }

  return client
}

const connections: { [key: string]: any } = {}

// src: https://github.com/nirus/fullstack-tutorial/blob/master/final/client/src/index.tsx
const cancelRequestLink = new ApolloLink(
  (operation, forward) =>
    new Observable((observer) => {
      // Set x-CSRF token (not related to abort use case)
      const context = operation.getContext()
      /** Final touch to cleanup */
      const connectionHandle = forward?.(operation).subscribe({
        next: (...arg) => observer.next(...arg),
        error: (...arg) => {
          cleanUp()
          observer.error(...arg)
        },
        complete: (...arg) => {
          cleanUp()
          observer.complete(...arg)
        },
      })

      const cleanUp = () => {
        connectionHandle?.unsubscribe()
        delete connections[context.requestTrackerId]
      }

      if (context.requestTrackerId) {
        const controller = new AbortController()
        controller.signal.onabort = cleanUp
        operation.setContext({
          ...context,
          fetchOptions: {
            signal: controller.signal,
            ...context?.fetchOptions,
          },
        })

        if (connections[context.requestTrackerId]) {
          // If a controller exists, that means this operation should be aborted.
          connections[context.requestTrackerId]?.abort()
        }

        connections[context.requestTrackerId] = controller
      }

      return connectionHandle
    })
)
