import * as React from "react"
import * as styles from "./styles.scss"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
// import Dropdown, { RenderTogglerProps } from "components/Dropdown"
import LogoutButton from "./LogoutButton"
import { useTranslation } from "react-i18next"
import * as cx from "classnames"
import { Link } from "react-router-dom"
import SupportViewToggler from "./SupportViewToggler"
import { useApolloClient } from "react-apollo-hooks"
import LanguageItem from "./LanguageItem"


const NavbarUserDropdown: React.FunctionComponent<NavbarUserProps> = (
  props
) => {
  const { t } = useTranslation("navbar")
  const [isLanguageItemHovered, setLanguageItemHovered] = React.useState<
    boolean
  >(false)

  const handleMouseOver = () => setLanguageItemHovered(true)
  const handleMouseLeave = () => setLanguageItemHovered(false)

  const {
    onOpenOfficeModal,
    username,
    officeName,
    canChangeOffice,
    canToggleSupportView,
    canChangeOfficeSettings,
    canLogout,
    isSupportUser,
  } = props
  const buttonRef = React.useRef<HTMLDivElement>(null)
  const dropdownRef = React.useRef<HTMLDivElement>(null)
  const [offsetRight, setOffsetRight] = React.useState(0)
  const [offsetTop, setOffsetTop] = React.useState(0)
  const [isDropdownOpen, setIsOpen] = React.useState(false)
  const onMouseEnter = () => {
    setIsOpen(true)
    if (buttonRef.current) {
      const btnRect = buttonRef.current.getBoundingClientRect()
      const btnRight = window.innerWidth - btnRect.right
      setOffsetTop(btnRect.height + btnRect.top - 3)
      setOffsetRight(btnRight)
      if (dropdownRef.current) {
        const dropdownWidth = 200
        const buttonCenter =
          window.innerWidth - btnRect.right + btnRect.width / 2
        setOffsetRight(buttonCenter - dropdownWidth / 2)
      }
    }
  }
  const onMouseLeave = () => {
    setIsOpen(false)
  }

  const client = useApolloClient()

  const usernameText = `${username}`
  const officeText = officeName === undefined ? "" : `(${officeName})`
  const profileText = `${usernameText} ${officeText}`

  return (
    <div
      className={styles.dropdownContainer}
      onMouseLeave={onMouseLeave}
      id={"NavbarUserDropdown"}
    >
      <div
        className={cx(styles.userBtn, {
          [styles.isOpen]: isDropdownOpen,
        })}
        ref={buttonRef}
        onMouseEnter={onMouseEnter}
      >
        <FontAwesomeIcon className={styles.userIcon} icon="user" />
        <FontAwesomeIcon className={styles.arrowIcon} icon="caret-down" />
        <span>{profileText}</span>
        {isDropdownOpen === true ? (
          <span className={styles.dotIcon}>&nbsp;</span>
        ) : null}
      </div>

      <div
        className={styles.dropdownWrapper}
        style={{
          top: offsetTop,
          right: offsetRight,
          display: isDropdownOpen === true ? "block" : "none",
        }}
        ref={dropdownRef}
      >
        <ul className={styles.dropdownList}>
          {canChangeOfficeSettings && (
            <li>
              <Link to="/agencySettings">{t("agencySettings:account")}</Link>
            </li>
          )}
          {canToggleSupportView && <SupportViewToggler />}
          {canChangeOffice && (
            <li onClick={onOpenOfficeModal}>{t("changeOffice")}</li>
          )}
          {/* <li>
            <div className={styles.toggleShowModeItem}>
              <div>Visningsläge</div> 
              <Toggler  onChange={toggleShowModeView} checked={isShowModeShow} />
            </div>
          </li> */}
          {isSupportUser && (
            <li onMouseOver={handleMouseOver} onMouseLeave={handleMouseLeave}>
              <LanguageItem isHover={isLanguageItemHovered} />
            </li>
          )}
          {/* <li>{t("agencySettings:account")}</li> */}
          {canLogout && <LogoutButton client={client} />}
        </ul>
      </div>
    </div>
  )
}

interface NavbarUserProps {
  onOpenOfficeModal: () => void
  username: string
  officeName?: string
  canChangeOffice: boolean
  canToggleSupportView: boolean
  canChangeOfficeSettings: boolean
  canLogout: boolean
  isSupportUser?: boolean
}

// export default withTranslation("navbar")(NavbarUserDropdown)
export default NavbarUserDropdown
