import gql from "graphql-tag"

const UPDATE_PACKAGE = gql`
  mutation UpdatePackage($input: MutationUpdateOrderInitiatorPackagesInput!) {
    updateOrderInitiatorPackages(input: $input) {
      isError
      errorReason
      orderInitiatorPackages {
        id
        name
        accessCustomerScope
        commonPrice
        imageId
        items {
          media {
            id
            friendlyName
          }
          inputId {
            documentFormatInitiatorId
            customerDocumentFormatInitiatorId
            mediaId
          }
        }
      }
    }
  }
`

export default UPDATE_PACKAGE
