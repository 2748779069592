import * as React from "react"
import * as styles from "./styles.scss"
import CheckboxField from "components/CheckboxField"
import { useTranslation } from "react-i18next"
import { GetReviewCaseFolder_caseFolder_documents_orders_mediaOrder_publishChannels } from "schema"
import { useMutation } from "react-apollo-hooks"
import UPDATE_PUBLISH_CHANNEL from "graphql/mutations/updatePublishChannel"
import cx from "classnames"
import GET_ORDER_BY_ID from "graphql/queries/getOrderById"
import GET_ORDER_BY_ID_LIVE_STREAM from "graphql/queries/getOrderByIdLivestream"

type keysInPublish = keyof GetReviewCaseFolder_caseFolder_documents_orders_mediaOrder_publishChannels

const ParallelPublishing: React.FC<{
  caseFolderId: string | null
  orderId: string
  publishChannels: GetReviewCaseFolder_caseFolder_documents_orders_mediaOrder_publishChannels
  isLocked: boolean
  isLiveStream?: boolean
}> = (props) => {
  const [t] = useTranslation(["common"])
  const updatePublishChannel = useMutation(UPDATE_PUBLISH_CHANNEL)

  const handleChange = (type: keysInPublish) => {
    if (props.isLocked) {
      return
    }

    if (props.isLiveStream) {
      updatePublishChannel({
        refetchQueries: [
          {
            query: GET_ORDER_BY_ID,
            variables: { id: props.orderId },
          },
          {
            query: GET_ORDER_BY_ID_LIVE_STREAM,
            variables: { id: props.orderId },
            context: {
              debounceKey: "livestreamPublishChannel",
              debounceTimeout: 200,
            },
          },
        ],
        variables: {
          input: {
            orderId: props.orderId,
            publishChannels: {
              [type]: !props.publishChannels[type],
            },
          },
        },
      })
    }

    updatePublishChannel({
      refetchQueries: [
        {
          query: GET_ORDER_BY_ID,
          variables: { id: props.orderId },
        },
      ],
      variables: {
        input: {
          orderId: props.orderId,
          publishChannels: {
            [type]: !props.publishChannels[type],
          },
        },
      },
    })
  }

  const handleChangeinAgency = () => handleChange("inAgency")
  const handleChangeinMinnesrummet = () => handleChange("inMinnesrummet")
  const handleChangeinFamiljesidan = () => handleChange("inFamiljesidan")

  return (
    <React.Fragment>
      <b className={styles.title}>{t("common:parallelPublishing")}</b>
      <div
        className={cx(styles.parallelPublish, {
          [styles.locked]: props.isLocked,
        })}
      >
        <CheckboxField
          label={t("common:officeWebsite")}
          onChange={handleChangeinAgency}
          disabled={props.isLocked}
          className={styles.checkboxField}
          checked={props.publishChannels.inAgency}
        />
        {!props.isLiveStream && (
          <CheckboxField
            label={t("common:memorialRoom")}
            onChange={handleChangeinMinnesrummet}
            disabled={props.isLocked}
            className={styles.checkboxField}
            checked={props.publishChannels.inMinnesrummet}
          />
        )}

        {!props.isLiveStream && (
          <CheckboxField
            label={t("common:familyPage")}
            onChange={handleChangeinFamiljesidan}
            disabled={props.isLocked}
            className={styles.checkboxField}
            checked={props.publishChannels.inFamiljesidan}
          />
        )}
      </div>
    </React.Fragment>
  )
}

export default ParallelPublishing
