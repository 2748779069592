import * as React from "react"
import * as styles from "./styles.scss"
import RemoveIcon from "./RemoveIcon"
import ColumnIcon from "components/ColumnIcon"

function Row(props: RowProps) {
  const { row, onChange, index, showRemoveIcon } = props

  return (
    <div className={styles.row}>
      <span className={styles.rowName}>Rad {Number(index) + 1}</span>
      <ColumnIcon
        columns={1}
        isActive={row.columns.length === 1}
        onClick={() => onChange(index, 1)}
      />
      <ColumnIcon
        columns={2}
        className={styles.twoColumns}
        isActive={row.columns.length === 2}
        onClick={() => onChange(index, 2)}
      />
      <ColumnIcon
        columns={3}
        isActive={row.columns.length === 3}
        onClick={() => onChange(index, 3)}
      />
      {showRemoveIcon && <RemoveIcon onClick={() => props.onRemove(index)} />}
    </div>
  )
}

interface RowProps {
  index: number
  row: any
  showRemoveIcon: boolean
  onRemove: (index: number) => void
  onChange: (inedx: number, columns: number) => void
}

export default Row
