import * as React from "react"
import * as styles from "./styles.scss"
import Card from "components/Card"
import Button from "components/Button"
import Header from "components/Header"
import Price from "./Price"
import {
  GetPackages_orderInitiatorPackagesByCurrentUser,
  // GetOrderInitiatorRootNode_orderInitiatorRootNode_children,
} from "schema"
import MediaItems from "./MediaItems"
import { MutationFn } from "react-apollo"
import { notEmpty } from "utils"
import { WithTranslation, withTranslation } from "react-i18next"
import { withRouter, RouteComponentProps } from "react-router-dom"
import * as cx from "classnames"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faQuestionCircle,
  faExclamationCircle,
  faChevronRight
} from "@fortawesome/free-solid-svg-icons"
import defaultIamge from 'images/packageDefault.jpg'



class PackageCard extends React.PureComponent<
  PackageCardProps & WithTranslation & RouteComponentProps, {isExpanded: boolean, isHover:boolean, replacedImage: string}
> {
  state = {
    isExpanded: true,
    isHover: false,
    replacedImage: defaultIamge
  }

  redirectAfterSelect = (res: any) => {
    if (this.props.caseFolderId) {
      const folderId = this.props.caseFolderId
      const orderId =
        res.data?.initiateCaseFolderOrders?.caseFolder?.editTabs[0]?.orders[0]
          ?.id ?? ""
      const url = `/editOrder/c/${folderId}/o/${orderId}`
      this.props.history.push(url)
    }
  }

  handleSelectPackage = () => {
    const { orderPackage, onSetPackageIdLoading, onSelectPackage } = this.props

    if (this.props.caseFolderId) {
      onSetPackageIdLoading(orderPackage.id!)
      onSelectPackage({
        variables: {
          input: {
            caseFolderId: this.props.caseFolderId,
            orderInitiators: orderPackage.items!.map((order) => order.inputId),
            orderInitiatorPackageId: orderPackage.id,
          },
        },
      }).then(this.redirectAfterSelect)
    }
  }

  renderCardButton = () => {
    const { packageIdLoading, orderPackage, t } = this.props
    return (
      <Button
        variant="primary"
        className={cx(styles.button, "selectPackageButton")}
        disabled={packageIdLoading !== undefined}
        isWorking={
          packageIdLoading !== undefined && packageIdLoading === orderPackage.id
        }
        onClick={this.handleSelectPackage}
      >
        {t("common:select")}
        <FontAwesomeIcon icon={faChevronRight} />
      </Button>
    )
  }

  handleCardExpand = () => {
    this.setState((prevState) =>({isExpanded: !prevState.isExpanded}))
    
  }

  onHoverOver = () => this.setState({isHover: true})
  onHoverLeave = () => this.setState({isHover: false})
  

  render() {
    const { orderPackage, onDeleteOrder } = this.props

    return (
      <div className={cx(styles.packageCardContainer, {
        [styles.expandWrapper]: !this.state.isExpanded
      })}>
        <div
          className={styles.cardContent}
          // renderButton={this.renderCardButton}
        >
          <div className={styles.packageContent} >
            <div className={styles.cardHeader} >
              <div
                className={styles.img}
                style={{backgroundImage: `url(${this.props.orderPackage.imageUrl ? this.props.orderPackage.imageUrl : this.state.replacedImage })`}}
                onMouseEnter={this.onHoverOver} 
              ></div>
              <div className={styles.title}>
                <span>{orderPackage.name}</span>
                <span className={cx(styles.icon,{
                  [styles.activeIcon]: !this.state.isExpanded
                })} onClick={this.handleCardExpand}>
                  
                  <FontAwesomeIcon icon={faExclamationCircle} />
                  
                
                </span>
                
              </div>
            </div>
            <div
              className={cx(styles.cardBodyWrapper, {
                [styles.expanded]: this.state.isExpanded,
              })}
            >
              <div className={cx(styles.mediaItems)}>
                <MediaItems
                  mediaItems={orderPackage.items!.filter(notEmpty)}
                  // onAddOrder={onAddOrder}
                  orderPackageId={orderPackage.id!}
                  onDeleteOrder={onDeleteOrder}
                />
              </div>
              <Price price={orderPackage.commonPrice} />
            </div>
            <div className={cx(styles.submitBtn,{
              [styles.isHover]: this.state.isHover
            })} onMouseLeave={this.onHoverLeave} >{this.renderCardButton()}  </div>  
          </div>
        </div>
      </div>
    )
  }
}

interface PackageCardProps {
  orderPackage: GetPackages_orderInitiatorPackagesByCurrentUser
  caseFolderId?: string
  onSelectPackage: MutationFn
  packageIdLoading?: string
  onSetPackageIdLoading: (packageId: string) => void
  // onAddOrder: (
  //   node: GetOrderInitiatorRootNode_orderInitiatorRootNode_children,
  //   orderPackageId: string
  // ) => void
  onDeleteOrder: (index: number, orderPackageId: string) => void
}

export default withTranslation(["newCaeFolder", "common"])(
  withRouter(PackageCard)
)

{
  /* <Header title={orderPackage.name} />
            <h4 style={{color: '#1289A7', marginTop: 0}}>Tjänster och produkter</h4> */
}
