import * as React from "react"
import * as styles from "./styles.scss"
import DraggableNumberInput from "components/DraggableNumberInput"
import { OrderEditorAreaContext } from "../.."
import { UnitTypes } from "schema"

const MarginField: React.FC<{
  direction: "top" | "bottom" | "left" | "right"
  value: null | number
  type: UnitTypes
  defaultValue: any
}> = (props) => {
  const { setMargin } = React.useContext(OrderEditorAreaContext)

  const handleSetMargin = (value: number) => {
    setMargin(props.direction, value)
  }

  return (
    <div className={styles.field}>
      <DraggableNumberInput
        initialValue={props.value}
        min={Number.MIN_SAFE_INTEGER}
        max={Number.MAX_SAFE_INTEGER}
        onChangeComplete={handleSetMargin}
        className={styles.input}
        disabled={props.value === null}
        type={props.type}
        defaultValue={props.defaultValue?.toString() ?? ""}
      />
    </div>
  )
}

export default MarginField
