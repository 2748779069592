import * as React from "react"
import * as styles from "./styles.scss"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import ConfirmationModal from "components/ConfirmationModal"
import { useTranslation } from "react-i18next"
import { useMutation } from "react-apollo-hooks"
import DELETE_TAG from "graphql/mutations/deleteTag"
import { AppContext } from "App"
import { ApolloQueryResult } from "apollo-client"
import { DropdownOption } from "components/DropdownButton"

function SelectedFavoriteRow(props: SelectedFavoriteRowProps) {
  const [t] = useTranslation("editOrder")
  const [modalIsOpen, setModalIsOpen] = React.useState(false)
  const { currentUserSession } = React.useContext(AppContext)
  const deleteTag = useMutation(DELETE_TAG, {
    variables: {
      input: {
        tags: [props.tagName],
        tagGroupId: currentUserSession.customer!.office.company.id,
      },
    },
  })

  const openModal = () => setModalIsOpen(true)

  const closeModal = () => setModalIsOpen(false)

  const deleteFavorite = () => {
    closeModal()
    deleteTag().then(() => {
      props.onFetchSymbolInfo(props.selectedSymbolId)
      props.onSetSelectedFavoriteTag({ value: undefined, label: "" })
      props.onRefetchCompanyTags()
    })
  }

  return (
    <div className={styles.selectedFavoriteRow}>
      <b>{props.tagName}</b>
      <div className={styles.deleteContainer} onClick={openModal}>
        <FontAwesomeIcon icon="trash" />
      </div>
      <ConfirmationModal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        title={t("deleteFavorite")}
        okButtonText={t("deleteFavorite")}
        onOKClick={deleteFavorite}
        text={t("deleteFavoriteModalDescription")}
        isModalInModal={true}
      />
    </div>
  )
}

interface SelectedFavoriteRowProps {
  tagName: string
  selectedSymbolId: string
  onFetchSymbolInfo: (symbolId: string) => void
  onRefetchCompanyTags: () => Promise<ApolloQueryResult<any>>
  onSetSelectedFavoriteTag: (option: DropdownOption) => void
}

export default SelectedFavoriteRow
