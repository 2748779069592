import * as React from "react"
import { SelectOrderInitiatorView_selectOrderInitiatorView_options } from "schema"
import { ErrorText } from "components/ErrorMessage"
import { useTranslation } from "react-i18next"
import { ItemWithImageAndDropdown } from "./ItemWithImageAndDropdown"

export const LayoutImageTextWithDropdownThumbnail: React.FC<{
  caseFolderId: string
  orderId?: string
  options: SelectOrderInitiatorView_selectOrderInitiatorView_options[]
  onCloseModal: any
  onSelectItem: (selector: string) => void
}> = (props) => {
  const [t] = useTranslation(["common"])
  return (
    <div className={"layout-IMAGE_TEXT_WITH_DROPDOWN_THUMBNAIL"}>
      {props.options.length === 0 ? (
        <div className={"error-wrapper"}>
          <ErrorText>{t("common:noResults")}</ErrorText>
        </div>
      ) : null}

      <div
        className={"items"}
        style={{
          gridTemplateColumns: `repeat(${props.options.length}, 1fr)`,
        }}
      >
        {props.options.map((option, index) => {
          if (!option) {
            return
          }
          const onSelectItem = (selector: string) => {
            props.onSelectItem(selector)
          }
          return (
            <ItemWithImageAndDropdown
              key={`${index}_${option.selector}`}
              caseFolderId={props.caseFolderId}
              id={option.selector}
              img={option.imageUrl ?? ""}
              title={option.name ?? " [NO TITLE] "}
              haveOptions={option.subView.options.length > 0}
              hoverText={option.hoverText}
              options={(option.subView.options ?? []).map((e) => {
                return { id: e.selector ?? "", label: e.name ?? "",  isEnabled: e.isEnabled && e.isEnabled, hoverText: e.hoverText }
              })}
              onSelectItem={onSelectItem}
            />
        
          )
        })}
      </div>
    </div>
  )
}
