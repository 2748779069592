import gql from "graphql-tag"

const GET_GALLERY_IMAGES = gql`
  query GetGalleryImages($input: GalleryImageAvailableImagesInput!,$filterTagGroupId:ID!) {
    galleryImagesByTags(input: $input) {
      isSupportTags
      totalImageCount
      startIndex
      totalImageCount
      actuallyPageLength
      pageLength
      images {
        id
        publicId
        description
        thumbnail100x80Url
        repository
        tagGroups(filter:[{tagGroupId:$filterTagGroupId, tags:[]}]) {
          tags
        }
      }
    }
  }
`

export default GET_GALLERY_IMAGES
