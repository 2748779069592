import * as React from "react"
import SymbolSearchColumn from "../SymbolSearchColumn"
import { AppContext } from "App"
import {
  GetGalleryImageInformation_galleryImages,
  GetGalleryImageInformation,
  GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor,
  GetGalleryImages,
  GetGalleryImagesVariables,
  PrintGalleryImages,
  PrintGalleryImagesVariables,
  EditModelAdvertAreaContentRepository,
} from "schema"
import GET_GALLERY_IMAGES from "graphql/queries/getGalleryImages"
import { DropdownOption } from "components/DropdownButton"
import { useQuery, useApolloClient, useMutation } from "react-apollo-hooks"
import GET_GALLERY_IMAGE_INFORMATION from "graphql/queries/getGalleryImageInformation"
import produce from "immer"
import { IPaginationsOptions } from "customSchema.interface"
import PRINT_SYMBOLS from "graphql/mutations/PrintGalleryImages"


function SymbolSearchColumnContainer(props: SymbolSearchColumnContainerProps) {

  const {
    onUpdateSelectedSymbol,
    tagsToSearchBy,
    tagsInSelect,
    handleToggleOnlyColors,
    searchOnlyColors,
    ...rest
  } = props
  const { currentUserSession } = React.useContext(AppContext)
  const companyId = currentUserSession.customer!.office.company.id
  const client = useApolloClient()
  const tagIdShowOnlyColors = "ATTR-COLOR"
  const groupIdColors = "00000000-0000-0000-0000-000000000010"
  const groupIdKeywords = "00000000-0000-0000-0000-000000000100"
  const [state, setState] = React.useState<IPaginationsOptions>({
    startIndex: 0,
    PAGE_LENGTH: 100,
    totalImageCount: 0,
    actuallyPageLength: 0,
  })


  const getTagGroupIds = () => {
    let ids = [companyId]
    if (tagsInSelect.length) {
      ids = ids.concat([groupIdColors, groupIdKeywords])
    } else if (searchOnlyColors === true) {
      ids = ids.concat([groupIdColors])
    }
    return ids
  }

  const fetchSymbolInfo = (
    symbolId: string,
    preventSelect?: boolean,
    _symbol?: any
  ) => {
    client
      .query({
        query: GET_GALLERY_IMAGE_INFORMATION,
        variables: { ids: [symbolId] },
        fetchPolicy: "network-only",
        context: {},
      })
      .then((res) => {
        if (preventSelect) {
          return
        }
        const data = res.data as GetGalleryImageInformation
        const symbol = data.galleryImages.find((image) => image.id === symbolId)
        onUpdateSelectedSymbol(symbol!)
      })
  }
  const { data: symbolData, loading } = useQuery<
    GetGalleryImages,
    GetGalleryImagesVariables
  >(GET_GALLERY_IMAGES, {
    variables: {
      input: {
        tags: props.tagsToSearchBy
          .map((tag) => tag.value)
          .concat(searchOnlyColors === true ? [tagIdShowOnlyColors] : []),
        tagGroupIds: getTagGroupIds(),
        filterGalleryImageAttributeInternalIds:
          props.editor?.image?.filterGalleryImageAttributeInternalIds,
        filterGalleryImageAttributeNotInternalIds: [],
        documentFormatId: props.documentFormatId,
        templateAreaId: props.templateAreaId,
        mediaId: props.mediaId,
        galleryImageOption: "",
        startIndex: state.startIndex,
        pageLength: state.PAGE_LENGTH,        
      },

      filterTagGroupId: companyId,
    },
    context: {
      skipErrorModal: true,
    },
    fetchPolicy: "network-only",
  })


  const printMutation = useMutation<PrintGalleryImages, PrintGalleryImagesVariables>(PRINT_SYMBOLS, {
    client,
    context: {
      debounceKey: "print-symboles",
      debounceTimeout: 1000,
    },
    variables: {
      input: {
        tags: props.tagsToSearchBy
          .map((tag) => tag.value)
          .concat(searchOnlyColors === true ? [tagIdShowOnlyColors] : []),
        tagGroupIds: getTagGroupIds(),
        filterGalleryImageAttributeInternalIds:
          props.editor?.image?.filterGalleryImageAttributeInternalIds,
        filterGalleryImageAttributeNotInternalIds: [],
        galleryImageDocumentFormatId: props.editor?.image?.documentFormatId,
        galleryImageOption: "",
        startIndex: state.startIndex,
        pageLength: state.PAGE_LENGTH,
      }
    }
  })

  const customData = () =>
    symbolData!.galleryImagesByTags?.images.map((item: any) => ({
      ...item,
      isSelected: false,
    }))


  

  React.useEffect(() => {
      if (
        (symbolData?.galleryImagesByTags &&
          symbolData?.galleryImagesByTags.actuallyPageLength &&
          symbolData?.galleryImagesByTags.actuallyPageLength !== undefined) ||
        (symbolData?.galleryImagesByTags &&
          symbolData?.galleryImagesByTags.totalImageCount &&
          symbolData?.galleryImagesByTags.totalImageCount !== undefined)
      ) {
        setState(
          produce(state, (draft) => {
            draft.actuallyPageLength =
              symbolData?.galleryImagesByTags.actuallyPageLength
            draft.totalImageCount =
              symbolData?.galleryImagesByTags.totalImageCount
          })
        )
      }
    
  }, [symbolData?.galleryImagesByTags])


  return (

    <SymbolSearchColumn
      {...rest}
      tags={tagsInSelect}
      symbols={loading ? [] : customData()}
      isLoading={loading}
      onFetchSymbolInfo={fetchSymbolInfo}
      searchOnlyColors={searchOnlyColors}
      handleToggleOnlyColors={handleToggleOnlyColors}
      setPaginationsOptions={setState}
      paginationsOptions={state}
      printMutation={printMutation as any}
      templateAreaId={props.templateAreaId!}
      documentFormatId={props.editor?.image?.documentFormatId!}
      repository={props.repository}
      mediaId={props.mediaId}
      isFEUser={props.isFEUser}
      
    />
    
  )
}
  

interface SymbolSearchColumnContainerProps {
  editor?: GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor
  selectedSymbol: GetGalleryImageInformation_galleryImages
  tagsToSearchBy: DropdownOption[]
  tagsInSelect: DropdownOption[]
  searchOnlyColors: boolean
  handleToggleOnlyColors: () => void
  onSave: (id: string) => void
  onTagUpdate: (
    tagsToSearchBy: DropdownOption[],
    tagsInSelect: DropdownOption[]
  ) => void
  onUpdateSelectedSymbol: (
    symbol: GetGalleryImageInformation_galleryImages
  ) => void
  templateAreaId: string | null
  documentFormatId: string | undefined
  repository?: EditModelAdvertAreaContentRepository | null
  mediaId: string | null | undefined
  isFEUser: boolean
}

export default SymbolSearchColumnContainer
