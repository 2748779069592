import gql from "graphql-tag"
import ORDER_FRAGMENT from "graphql/fragments/OrderFragment"

// UpdateAllOrdersByField
const UPDATE_ORDER_CONTENT = gql`
  mutation UpdateOrderContent($input: MutationUpdateOrderContentInput!) {
    updateOrderContent(input: $input) {
      isError
      errorReason
      order {
        ...OrderFragment
      }
    }
  }
  ${ORDER_FRAGMENT}
`

export default UPDATE_ORDER_CONTENT
