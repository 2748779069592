import * as React from "react"
import BaseTabContainer from "components/TabContainer"
import SearchTab from "./SearchTab"
import { IconProp } from "@fortawesome/fontawesome-svg-core"
import * as styles from "./styles.scss"
import { useTranslation } from "react-i18next"

function TabContainer() {
  const [selectedTabId, setSelectedTabId] = React.useState("search")
  const [t] = useTranslation("supportView")

  const selectTab = (id: string) => setSelectedTabId(id)

  return (
    <BaseTabContainer
      onSelectTab={selectTab}
      selectedTabId={selectedTabId}
      tabClassName={styles.tab}
      className={styles.container}
      tabs={[
        {
          id: "search",
          component: SearchTab,
          label: `${t("search")}...`,
          icon: "search" as IconProp,
        },
        {
          id: "users",
          component: SearchTab,
          label: t("users"),
          icon: "users" as IconProp,
          disabled: true,
        },
        {
          id: "symbols",
          component: SearchTab,
          label: t("symbols"),
          icon: "images" as IconProp,
          disabled: true,
        },
        {
          id: "statistics",
          component: SearchTab,
          label: t("statistics"),
          icon: "chart-area" as IconProp,
          disabled: true,
        },
      ]}
    />
  )
}

export default TabContainer
