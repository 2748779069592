import * as React from "react"
import Navbar from "./Navbar"
import * as styles from "./styles.scss"
import Footer from "./Footer"
import OrderInfo from "./OrderInfo"
import AllOrders from "./AllOrders"

function LiveView() {
  return (
    <div className={styles.liveViewPage}>
      <div>
        <Navbar />
        <div className={styles.content}>
          <img
            src="http://taps5-backend-webapi.timecut.se/resource/temp?key=fb1c5c0b-1e3e-1c44-ea21-e4f2ccc921cd"
            className={styles.previewImage}
          />
          <div className={styles.rightContainer}>
            <OrderInfo />
            <AllOrders />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default LiveView
