import React from 'react';
import * as styles from "./styles.scss"


const Overlay: React.FC<{
    isVisible: boolean;
    description?: string;
}> = ({ isVisible, description }) => {
    
    if (!isVisible) return null;

    return (
        <div className={isVisible ? styles.overlay : ""}>
            {description && description.length > 0 && (
                <div className={styles.description}>{ description }</div>
            )}
        </div>
    );
};

export default Overlay;