import gql from "graphql-tag"
import PREVIEW_IMAGE_FRAGMENT from "graphql/fragments/PreviewImageFragment"

const UPDATE_PUBLISH_TIME = gql`
  mutation UpdatePublishTime($input: MutationUpdateOrderPublishTimesInput!) {
    updateOrderPublishTimes(input: $input) {
      isError
      errorReason
      order {
        id
        alerting {
          smallText
          text
          type
        }
        mediaOrder {
          preview {
            ...PreviewImageFragment
            price(withVAT: true, withRounding:STANDARD, priceView: WITH_SERVICE_ORDER) {
              currency
              endCustomer {
                pricePayRounded
              }
            }
          }
          publishConversation {
            messageFromMedia
            codeLate
            isCodeLate
            isMessageToMedia
            isMediaSystemOrderNumber
            isMediaCustomerSubriberNumber
            publishReadyStatus
          }
          publishChannels {
            inFamiljesidan
            inMinnesrummet
            inAgency
          }
          publishTimes {
            time
          }
        }
      }
    }
  }
  ${PREVIEW_IMAGE_FRAGMENT}
`

export default UPDATE_PUBLISH_TIME
