import * as React from "react"
import * as styles from "./styles.scss"
import { GetAdminCustomers_customerTreesByFreeSearch_companies } from "schema"
import Company from "./Company"

function Companies(props: CompaniesProps) {
  return (
    <div className={styles.companies}>
      {props.companies.map(company => (
        <Company company={company} key={company.idString!} />
      ))}
    </div>
  )
}

interface CompaniesProps {
  companies: GetAdminCustomers_customerTreesByFreeSearch_companies[]
}

export default Companies
