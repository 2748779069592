
import React from "react"
import * as styles from "./styles.scss"
import { faMinusCircle, faTextHeight } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useTranslation } from "react-i18next"
import Button from "components/Button"
import { OrderEditorAreaContext } from "../.."
import AdminSectionTooltip from "../AdminSectionTooltip"
import icon from "images/line-height-icon.svg"
import { DropdownOption } from "components/DropdownButton"
import UnitValueInput from "../UnitValueInput"
import OptionDropdown from "components/OptionDropdown"

const LineHeightEmpty: React.FC = () => {
  const [t] = useTranslation("editOrder")
  const { editor, setLineHeightEmpty, contentRows, area } = React.useContext(
    OrderEditorAreaContext
  )
  const [inputValue, setInputValue] = React.useState<number | null>(null)

  let defaultLineHeightEmpty: number | undefined = undefined

  if (
    area &&
    area.style &&
    area.style.text &&
    area.style.text.lineHeightEmpty
  ) {
    defaultLineHeightEmpty = Math.round(area.style.text.lineHeightEmpty.value)
  }

  let lineHeightEmptyValue: number | null = null

  if (
    contentRows !== undefined &&
    contentRows[0] !== undefined &&
    contentRows[0].columns !== undefined &&
    contentRows[0].columns[0] !== undefined &&
    contentRows[0].columns[0].item !== undefined &&
    contentRows[0].columns[0].item.text !== undefined &&
    contentRows[0].columns[0].item.text !== null &&
    contentRows[0].columns[0].item.text.lineHeightEmpty !== undefined &&
    contentRows[0].columns[0].item.text.lineHeightEmpty !== null &&
    contentRows[0].columns[0].item.text.lineHeightEmpty.value !== undefined &&
    contentRows[0].columns[0].item.text.lineHeightEmpty.value !== null
  ) {
    lineHeightEmptyValue = contentRows[0].columns[0].item.text.lineHeightEmpty.value
  }

  const LineHightEmptyArr: number[] = []
  const options: { label: string; value: number }[] = []
  if (
    editor &&
    editor.text !== undefined &&
    editor.text !== null &&
    editor.text.allowedLineHeights !== undefined &&
    editor.text.allowedLineHeights !== null &&
    editor.text.allowedLineHeights.values !== undefined &&
    editor.text.allowedLineHeights.values !== null
  ) {
    editor.text.allowedLineHeights.values.forEach((num) => {
      if (num !== undefined && num !== null && Number.isNaN(num) === false) {
        options.push({
          label: num.toString(),
          value: num,
        })
        LineHightEmptyArr.push(num)
      }
    })
  }

  const findClosestNr = (arr: Array<number>, num: any) => {
    if (arr === null) {
      return
    }
    return arr.reduce((prev, current) =>
      Math.abs(current - num) < Math.abs(prev - num) ? current : prev
    )
  }

  const absolutCurrentValue = findClosestNr(
    LineHightEmptyArr,
    defaultLineHeightEmpty
  )

  const handleDropdownChange = (option: DropdownOption) => {
    setInputValue(option.value)
    setLineHeightEmpty(option.value)
  }

  const handleInputChange = (value: string | undefined) => {
    if (value === undefined) {
      setInputValue(null)
      setLineHeightEmpty(null)
    } else {
      const newValue = isNaN(Number(value)) ? 0 : Number(value)
      setInputValue(newValue)
      // setLineHeightEmpty(newValue)
    }
  }

  const updateValue = () => {
    if(inputValue === null) return;
    return setLineHeightEmpty(inputValue)
  }

  
  const handleClick = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.code === "Enter" || e.code === "NumpadEnter") {
     return updateValue()
    }
  }

  const onClear = () => {
    setInputValue(null)
    setLineHeightEmpty(null)
  }

  const renderToggler = () => (
    <AdminSectionTooltip id="lineHeightEmpty" text={t("lineHeightEmpty")}>
      {lineHeightEmptyValue !== null ? (
        <div className={styles.removeIcon} onClick={onClear}>
          <FontAwesomeIcon icon={faMinusCircle} color={"#e6e6e6"} />
        </div>
      ) : null}
      <Button
        variant="primary"
        className={styles.lineHeightButton}
        color={lineHeightEmptyValue === null ? "mediumGray" : "secondary"}
      >
        <img src={icon} alt="" className={"styles.svg"} />
      </Button>
    </AdminSectionTooltip>
  )

  return (
    <div className={styles.wrapper}>
      <UnitValueInput
        jsx={
          <OptionDropdown
            options={options}
            onChange={handleDropdownChange}
            renderToggler={renderToggler}
            optionListClassName={styles.contentList}
            defaultValue={absolutCurrentValue}
            selectedValue={lineHeightEmptyValue}
          />
        }
        placeholder={absolutCurrentValue}
        onChange={handleInputChange}
        unit={"px"}
        value={
          inputValue === undefined || inputValue === null
            ? lineHeightEmptyValue
            : inputValue
        }
        active={inputValue === null}
        onBlur={updateValue}
        onKeyDown={handleClick}
      />
    </div>
  )
}

export default LineHeightEmpty
