import * as React from "react"
import * as styles from "./styles.scss"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

function Item(props: ItemProps) {
  return (
    <div>
      <FontAwesomeIcon icon="check" className={styles.icon} /> {props.text}
    </div>
  )
}

interface ItemProps {
  text: string
}

export default Item
