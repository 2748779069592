import gql from "graphql-tag"
import EDIT_ORDER_PAGE_CASE_FOLDER_FRAGMENT from "graphql/fragments/EditOrderPageCaseFolderFragment"

const SELECT_PACKAGE = gql`
  mutation SelectPackage($input: MutationInitiateCaseFolderOrdersInput!) {
    initiateCaseFolderOrders(input: $input) {
      isError
      errorReason
      caseFolder {
        ...EditOrderPageCaseFolderFragment
      }
    }
  }
  ${EDIT_ORDER_PAGE_CASE_FOLDER_FRAGMENT}
`

export default SELECT_PACKAGE
