import * as React from "react"
import * as styles from "./styles.scss"
import { useTranslation } from "react-i18next"

function NoResults() {
  const [t] = useTranslation()

  return <p className={styles.noResults}>{t("noResults")}...</p>
}

export default React.memo(NoResults)
