import * as React from "react"
import * as styles from "./styles.scss"
import Status from "components/Status"
import { GetReviewCaseFolder_caseFolder_documents_orders_mediaOrder } from "schema"
import { WithTranslation, withTranslation } from "react-i18next"

class MediaOrderInfo extends React.Component<
  MediaOrderInfoProps & WithTranslation
> {
  render() {
    const { mediaOrder, customerOrderId } = this.props

    return (
      <div className={styles.mediaOrderInfo}>
        <Status variant={mediaOrder.status.color} showStatusLabel={true}>
          {this.props.t(mediaOrder.status.status)}
        </Status>
        <div className={styles.customerOrderId}>{customerOrderId},</div>
        <h3>{mediaOrder.media.friendlyName}</h3>
      </div>
    )
  }
}

interface MediaOrderInfoProps {
  mediaOrder: GetReviewCaseFolder_caseFolder_documents_orders_mediaOrder
  customerOrderId: string
}

export default withTranslation("statuses")(MediaOrderInfo)
