import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Tooltip from "components/Tooltip"
import React from "react"
import { GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_validateErrors } from "schema"
import styled from "styled-components"
import cx from "classnames"

const ValidateErrorText: React.FC<{
  validationError?: GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_validateErrors[]
  validateErrorTexts?: string[] | undefined
}> = (props) => {
  const errorTexts = props.validateErrorTexts
    ? props.validateErrorTexts?.filter((error) => error !== null)
    : []

  return (
    <ValidateAreaErrorStyles>
      <div className={cx("content", {
        ["feView__content"]: true 
      })}>
        {props.validationError && props.validationError.length > 0 && (
          <Tooltip
            id={"baseRowTooltip"}
            delayShow={500}
            text={props.validationError.map((error) => {
              return error.validationText + "\n"
            })}
          >
            <div className={"validationErrorContainer"}>
              <FontAwesomeIcon
                className={"errorIcon"}
                icon={faExclamationTriangle}
                size={"1x"}
              />
            </div>
          </Tooltip>
        )}

        {props.validateErrorTexts && props.validateErrorTexts.length > 0 && (
          <Tooltip
            id={"baseRowTooltip"}
            className={"baseRowTooltip"}
            delayShow={500}
            text={errorTexts.map((error) => {
              return `- ${error}\n`
            })}
          >
            <div
              className={"validationErrorContainer"}
            >
              <FontAwesomeIcon
                className={"errorIcon"}
                icon={faExclamationTriangle}
                size={"1x"}
              />
            </div>
          </Tooltip>
        )}
      </div>
    </ValidateAreaErrorStyles>
  )
}

const ValidateAreaErrorStyles = styled.div`
  .content {
    .baseRowTooltip {
      color: ${(props) => props.theme["--error-red"]};
    }
    .validationErrorContainer {
      margin-right: 10px;
      .errorIcon {
        color: ${(props) => props.theme["--error-red"]};
      }
    }
    &.feView__content {
      .baseRowTooltip {
        color: ${(props) => props.theme["--fe_primary"]};
      }
      .validationErrorContainer {
        .errorIcon {
          color: ${(props) => props.theme["--fe_primary"]};
        }
      }
    }
  }
`

export default ValidateErrorText
