import * as React from "react"
import { SelectOrderInitiatorView_selectOrderInitiatorView_options } from "schema"
import { ErrorText } from "components/ErrorMessage"
import { useTranslation } from "react-i18next"
import produce from "immer"
import Carousel, { Dots, slidesToShowPlugin } from "@brainhubeu/react-carousel"

export const LayoutImageTextThumbnailWithSlider: React.FC<{
  caseFolderId: string
  orderId?: string
  options: SelectOrderInitiatorView_selectOrderInitiatorView_options[]
  onCloseModal: any
  onSelectItem: (selector: string) => void
}> = (props) => {
  const [t] = useTranslation(["common"])
  const [slider, setSlider] = React.useState<{
    currentSlideIndex: number
    maxIndex: number
  }>({
    currentSlideIndex: 0,
    maxIndex: props.options.length,
  })
  const nextSlide = () => {
    setSlider(
      produce(slider, (draft) => {
        draft.currentSlideIndex = draft.currentSlideIndex + 1
      })
    )
  }
  const prevSlide = () => {
    setSlider(
      produce(slider, (draft) => {
        draft.currentSlideIndex = draft.currentSlideIndex - 1
      })
    )
  }
  const goToSlide = (index: number) => {
    setSlider(
      produce(slider, (draft) => {
        draft.currentSlideIndex = index
      })
    )
  }
  const isNextButtonDisabled = (slideCount: number) => {
    return slideCount === slider.currentSlideIndex + 1
  }

  return (
    <div className={"layout-IMAGE_TEXT_THUMBNAIL_WITH_SLIDER"}>
      {props.options.length === 0 ? (
        <div className={"error-wrapper"}>
          <ErrorText>{t("common:noResults")}</ErrorText>
        </div>
      ) : null}

      <div className={"items"}>
        <Carousel
          value={slider.currentSlideIndex}
          onChange={goToSlide}
          plugins={[
            "infinite",
            "arrows",
            {
              resolve: slidesToShowPlugin,
              options: {
                numberOfSlides: 2,
              },
            },
          ]}
        >
          {props.options.map((option, index) => {
            if (!option) {
              return
            }
            const onSelectItem = (event: any) => {
              props.onSelectItem(option.selector)
            }

            return (
              <div
                className={"item"}
                key={`${index}_${option.selector}`}
                onClick={onSelectItem}
              >
                {option.imageUrl ? (
                  <div className={"imageContainer"}>
                    <img
                      className={"image"}
                      src={option.imageUrl}
                      alt={option.name ?? ""}
                    />
                  </div>
                ) : null}

                <div className={"name"}>{option.name ?? ""}</div>
              </div>
            )
          })}
        </Carousel>

        {props.options.length > 1 ? (
          <Dots
            value={slider.currentSlideIndex}
            onChange={goToSlide}
            className={"dots-wrapper"}
            thumbnails={props.options.map((_, index) => (
              <div key={index} className="dot" />
            ))}
          />
        ) : null}
      </div>
    </div>
  )
}
