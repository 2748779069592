import * as React from "react"
import {
  EditModelAdvertAreaContentEditorBehaviours,
} from "schema"
import { HtmlEditor } from "./HtmlEditor"
import * as styles from "./styles.scss"
import cx from "classnames"
import { VerseEditingModal } from "pages/EditOrderPage/OrderEditorContainer/OrderEditoryAdvert/VerseEditingModal"
import { faCopy} from "@fortawesome/free-solid-svg-icons"
import CopyValue from "components/CopyValue"
import { IEditorViewBaseOnUserAndFields } from "pages/EditOrderPage/OrderEditorContainer/OrderEditoryAdvert/AreasContainer/Areas/AreaContainer/HtmlInput/ColumnView/ColumnContainer"
import Tools from "./Tools"
import FieldsContainer from "./FieldsContainer"

interface Istate {
  isVisible?: boolean
  isBlur?: boolean
  isVersModalOpen: boolean
}

interface HtmlEditorTapsProps {
  value: string
  onChange: (event: React.SyntheticEvent<any>) => void
  onOpenSpecialCharacters?: (event?: React.SyntheticEvent<any>) => void
  pStyles: any
  symbols?: any
  isValueDemo?: boolean
  rowItems?: any
  blockStyles: any[]
  onSelectBlockStyle: (value: string | null) => void
  isVerseGalleryEnabled?: boolean
  isExpanded?: boolean
  setVerseInExpandedArea?: (arg?: any) => void
  onEnterColumnEdit: () => void
  isEnabledMultiColumns: boolean | undefined
  isLocked: boolean
  editorBehaviour: EditModelAdvertAreaContentEditorBehaviours
  isFEUser: boolean
  editorViewBaseOnUserAndFields: IEditorViewBaseOnUserAndFields
  isXmlTextReadonly: boolean
}

export class HtmlEditorTaps extends React.PureComponent<
  HtmlEditorTapsProps,
  Istate
> {
  htmlEditorRef: React.RefObject<HtmlEditor>

  constructor(props: HtmlEditorTapsProps) {
    super(props)
    this.state = {
      isVisible: false,
      isBlur: false,
      isVersModalOpen: false,
    }
    this.htmlEditorRef = React.createRef()
    this.handleClick = this.handleClick.bind(this)
    this.handleOpenVerse = this.handleOpenVerse.bind(this)
    this.handleCloseVerse = this.handleCloseVerse.bind(this)
  }

  handleClick(e: React.SyntheticEvent<any>) {
    e.preventDefault()
    this.setState((prevState) => ({ isVisible: !prevState.isVisible }))
  }

  public pasteContent(content: string, replaceAll: boolean) {
    this.htmlEditorRef.current!.pasteContent(content, replaceAll)
  }

  componentDidMount() {
    document.addEventListener<any>("onClick", this.handleClick)
  }

  componentWillUnmount() {
    document.addEventListener<any>("onClick", this.handleClick)
  }

  handleOpenVerse = () => {
    this.setState({ isVersModalOpen: true })
  }

  handleCloseVerse = () => {
    this.setState({ isVersModalOpen: false })
  }

  render() {
    const {
      value,
      onChange,
      onOpenSpecialCharacters,
      isVerseGalleryEnabled,
      isExpanded,
      setVerseInExpandedArea,
    } = this.props

    /*
      @Todo
      # isXmlTextReadonly true only fields shows on both user view & support view
      # isXmlTextReadonly false & fields is defined htmlEditor shows on support view while fields will be still visible in user view but disabled
      # in order to fields text change the text in htmlEditor should update
      # test test test
    */

    return (
      <div>
        {!this.props.editorViewBaseOnUserAndFields.isFields &&
          !this.props.isXmlTextReadonly && (
            <Tools
              isLocked={this.props.isLocked}
              isFEUser={this.props.isFEUser}
              isVerseGalleryEnabled={isVerseGalleryEnabled!}
              editorBehaviour={this.props.editorBehaviour}
              handleOpenVerse={this.handleOpenVerse}
              isVersModalOpen={this.state.isVersModalOpen!}
              isEnabledMultiColumns={this.props.isEnabledMultiColumns!}
              onEnterColumnEdit={this.props.onEnterColumnEdit}
              onToggleSymbolMenu={this.handleClick}
              isMenuVisible={this.state.isVisible!}
              blockStyles={this.props.blockStyles}
              onSelectBlockStyle={this.props.onSelectBlockStyle}
              editorViewBaseOnUserAndFields={
                this.props.editorViewBaseOnUserAndFields
              }
              isXmlTextReadonly={this.props.isXmlTextReadonly}
            />
          )}

        <Menu
          symbols={this.props.symbols}
          onOpenSpecialCharacters={onOpenSpecialCharacters}
          isVisible={this.state.isVisible}
          isFEUser={this.props.isFEUser}
        />
        {this.props.editorViewBaseOnUserAndFields.isFields &&
        this.props.editorViewBaseOnUserAndFields.isSupportUser &&
        !this.props.isXmlTextReadonly ? (
          <HtmlEditor
            ref={this.htmlEditorRef}
            html={value}
            onChange={onChange}
            pStyles={this.props.pStyles}
            isValueDemo={this.props.isValueDemo}
            rowItems={this.props.rowItems}
            disabled={this.props.isLocked}
            editorBehaviour={this.props.editorBehaviour}
            isFEUser={this.props.isFEUser}
          />
        ) : (
          !this.props.editorViewBaseOnUserAndFields.isFields && (
            <HtmlEditor
              ref={this.htmlEditorRef}
              html={value}
              onChange={onChange}
              pStyles={this.props.pStyles}
              isValueDemo={this.props.isValueDemo}
              rowItems={this.props.rowItems}
              disabled={this.props.isLocked}
              editorBehaviour={this.props.editorBehaviour}
              isFEUser={this.props.isFEUser}
            />
          )
        )}

        <div className={styles.fieldsContainer}>
          <FieldsContainer
            isXmlTextReadonly={this.props.isXmlTextReadonly}
            isFEUser={this.props.isFEUser}
          />
        </div>

        {this.props.isLocked && (
          <CopyValue icon={faCopy} title={"Kopiera fältet"} CopyValue={value} />
        )}

        {isExpanded && isVerseGalleryEnabled && (
          <VerseEditingModal
            setVerseInExpandedArea={setVerseInExpandedArea}
            isOpen={this.state.isVersModalOpen!}
            onClose={this.handleCloseVerse}
            editorTextValue={value}
            editorBehaviour={this.props.editorBehaviour}
            isValueDemo={this.props.isValueDemo!}
            isFEUser={this.props.isFEUser}
          />
        )}
      </div>
    )
  }
}

export const Menu: React.FC<{
  isVisible?: boolean
  symbols: any
  isFEUser: boolean
  onOpenSpecialCharacters?: (event: React.SyntheticEvent<any>) => any
}> = (props) => {
  return (
    <>
      {props.isVisible && (
        <div
          className={cx(styles.contentMenu, {
            [styles.feView__contentMenu]: props.isFEUser,
          })}
          style={{ userSelect: "none" }}
        >
          <ul>
            {props.symbols &&
              props.symbols.map((item: any, index: number) => {
                return (
                  <li
                    id={item.id}
                    key={index}
                    onMouseDown={props.onOpenSpecialCharacters}
                  >
                    <span>
                      <p
                        title={item.title}
                        dangerouslySetInnerHTML={{
                          __html: item.preview ?? item.html,
                        }}
                        data-insert={item.html}
                      ></p>
                    </span>
                  </li>
                )
              })}
          </ul>
        </div>
      )}
    </>
  )
}

// const BlockStylesDropdown: React.FC<{
//   blockStyles: GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_common_blockStyles[]
//   onSelectBlockStyle: (value: string | null) => void
// }> = (props) => {
//   const [t] = useTranslation(["editOrder"])
//   if (props.blockStyles.length === 0) {
//     return <React.Fragment />
//   }
//   return (
//     <DropdownButton
//       rightIcon="angle-down"
//       label={t("editOrder:blockStyles")}
//       options={props.blockStyles.map((e) => {
//         return {
//           label: e.name,
//           value: e.id,
//         }
//       })}
//       contentClassName={styles.dropdownContentStyles}
//       togglerClassName={styles.dropdownStyles}
//       variant={"none"}
//       onOptionClick={(option) => {
//         props.onSelectBlockStyle(option.value.replace(/<[^>]*>?/gm, ""))
//       }}
//     />
//   )
// }

// const MagnifyingGlassWithBookmarkSvg = () => (
//   <div className={styles.MagnifyingClassIcon}>
//     {React.cloneElement(<MagnifyingGlassWithBookmark />, {
//       size: "22px",
//       color: "#ACACAC",
//     })}
//   </div>
// )

// const SearchVerseButton: React.FC<{
//   onClickOpen: () => void
//   isModalOpen: boolean
//   isFEUser: boolean
// }> = (props) => {
//   const ref = React.useRef<HTMLDivElement | null>(null)
//   const isModalVisible = useVerseEditingContextState(
//     (state) => state.data.isModalVisible
//   )
//   const dispatch = useVerseEditingContextDispatch()
//   const onPressSearchVerse = () => {
//     if (ref.current) {
//       const rect = ref.current.getBoundingClientRect()
//       dispatch({ type: "toggleModalWithRect", payload: { rect } })
//     } else {
//       dispatch({ type: "toggleModal" })
//     }
//   }

//   const onOpen = () => {
//     props.onClickOpen()
//     onPressSearchVerse()
//   }
//   return (
//     <div
//       className={cx(styles.buttonSearchWrapper, {
//         [styles.feView__adstateEnabled]: props.isFEUser,
//       })}
//       ref={(e) => (ref.current = e)}
//     >
//       <Button
//         variant="primary"
//         onClick={onOpen}
//         //icon="search"
//         className={cx(styles.buttonSearch, {
//           ["styles.buttonSearchActive"]:
//             props.isModalOpen === true && isModalVisible === true,
//         })}
//       >
//         {" "}
//         {MagnifyingGlassWithBookmarkSvg()}
//       </Button>
//     </div>
//   )
// }

{
  /* <div
          className={cx(styles.rowWrapper, {
            [styles.isLockedActive]: this.props.isLocked,
            [styles.feView__rowWrapper]: this.props.isFEUser,
          })}
        >
          {isVerseGalleryEnabled === true ? (
            <Tooltip
              id={"ScalaTextInput"}
              text={
                this.props.editorBehaviour === TEXT_HTML_VERSE
                  ? "Sök och spara verser/texter"
                  : "Sök och spara texter"
              }
              bgColor={"#fff"}
              className={styles.tooltip}
              delayShow={750}
            >
              <SearchVerseButton
                onClickOpen={this.handleOpenVerse}
                isModalOpen={this.state.isVersModalOpen!}
                isFEUser={this.props.isFEUser}
              />
            </Tooltip>
          ) : null}
          <TextAlignSelector isFEUser={this.props.isFEUser} />

          {this.props.isEnabledMultiColumns && (
            <ColumnsButton onClick={this.props.onEnterColumnEdit} />
          )}

          <Tooltip
            id={"ScalaTextInput"}
            text={"Lägg till ikon"}
            bgColor={"#fff"}
            className={styles.tooltip}
            delayShow={750}
          >
            <button
              onMouseDown={this.handleClick}
              className={cx(styles.iconsButton, {
                [styles.isActive]: this.state.isVisible,
              })}
            >
              <FontAwesomeIcon icon={faCross} />
            </button>
          </Tooltip>

          <BlockStylesDropdown
            blockStyles={this.props.blockStyles}
            onSelectBlockStyle={this.props.onSelectBlockStyle}
          />
          <ScalaTextInput isFEUser={this.props.isFEUser} />
          <MarginLeftRightInput isFEUser={this.props.isFEUser} />
        </div> */
}

export default HtmlEditorTaps
