import * as React from "react"
import * as styles from "./styles.scss"
import DropdownButton, { DropdownOption } from "components/DropdownButton"
import { WithTranslation, withTranslation } from "react-i18next"
import cx from "classnames"

class ZoomDropdown extends React.PureComponent<
  ZoomDropdownProps & WithTranslation
> {



  render() {
    const { onSetPreviewZoom, previewZoom, t , localZoom, isFEUser} = this.props

  

    return (
      <div className={cx(styles.zoomDropdownContainer,{
        [styles.feView__zoomDropdownContainer]: isFEUser
      })}>
        <DropdownButton
          onOptionClick={onSetPreviewZoom}
          dropdownClassName={styles.zoomDropdown}
          label={`${t("size")}:`}
          sizeValue={localZoom ? localZoom.lable : previewZoom.label}
          rightIcon="search"
          options={[
            { value: "auto", label: "Auto" },
            { value: 0.75, label: "75%" },
            { value: 1, label: "100%" },
            { value: 1.25, label: "125%" },
            { value: 1.5, label: "150%" },
          ]}
        />
      </div>
    )
  }
}

interface ZoomDropdownProps {
  previewZoom: DropdownOption
  onSetPreviewZoom: (newZoom: DropdownOption) => void
  localZoom: any
  isFEUser: boolean
}

export default withTranslation()(ZoomDropdown)
