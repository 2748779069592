import React from "react"
import * as styles from "./styles.scss"

import { DataProxy } from "apollo-cache"
import GET_DASHBOARD_CASE_FOLDERS from "graphql/queries/getDashboardCaseFolders"
import CREATE_CASE_FOLDER from "graphql/mutations/createCaseFolder"
import { useApolloClient, useMutation } from "react-apollo-hooks"
import { CreateCaseFolder, CreateCaseFolderVariables } from "schema"
// import CaseFolderHeaderRow from "pages/NewCaseFolderPage/CaseFolderHeaderRow"
// import Header from "./Header"

import CaseFolderForm from "components/CaseFolderForm"
import { useHistory } from "react-router-dom"

const updateCache = (cache: DataProxy, { data }: any) => {
  const { findCaseFoldersByCurrentCustomer }: any = cache.readQuery({
    query: GET_DASHBOARD_CASE_FOLDERS,
  })

  cache.writeQuery({
    query: GET_DASHBOARD_CASE_FOLDERS,
    data: {
      findCaseFoldersByCurrentCustomer: {
        ...findCaseFoldersByCurrentCustomer,
        caseFolders: findCaseFoldersByCurrentCustomer.caseFolders.concat([
          data.createCaseFolder.caseFolder,
        ]),
      },
    },
  })
}

const CreateCaseFolder: React.FC<{
  isActive: boolean
  onComplete: (arg: any) => void
  exitCreateCaseFolder: () => void
  isCreateMode: boolean
  queryValue: { action: string }
  isMediaUser: boolean
}> = (props) => {
  const history = useHistory()
  const [selectedCaseFolder, setselectedCaseFolder] = React.useState(undefined)
  const client = useApolloClient()
  const runCreateCaseFolder = useMutation<
    CreateCaseFolder,
    CreateCaseFolderVariables
  >(CREATE_CASE_FOLDER, {
    client,
    update: updateCache,
  })

  const openProductsModal = () => {
    const { search } = window.location
    const urlParams = new URLSearchParams(search)
    urlParams.set("action", "add_product_casefolder")
    const newSearch = urlParams.toString()
    history.push({
      search: newSearch,
    })
  }

  const onComplete = (result: any) => {
    setselectedCaseFolder(result.data.createCaseFolder.caseFolder)
    if (props.onComplete) {
      props.onComplete(result.data.createCaseFolder.caseFolder.id)      
    }
  }

  return (
    <div className={styles.main}>
      <CaseFolderForm
        columnCount={3}
        isActive={props.isActive}
        selectedCaseFolder={selectedCaseFolder}
        onSubmit={runCreateCaseFolder as any}
        onComplete={onComplete}
        exitCreateCaseFolder={props.exitCreateCaseFolder}
        isCreateMode={props.isCreateMode}
        queryValue={props.queryValue}
        isMediaUser={props.isMediaUser}
        openProductsModal={openProductsModal}
      />
    </div>
  )
}

export default CreateCaseFolder
