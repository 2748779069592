import * as React from "react"
import * as styles from "./styles.scss"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { NewsItem } from "./NewsProvider"
import * as cx from "classnames"
import { DateTime } from "luxon"
import { useTranslation } from "react-i18next"
import Modal from "components/Modal"
import { useLocation } from "react-router-dom"

const NewsPreview: React.FC<NewsItem & NewsPreviewProps> = (props) => {
  const { t } = useTranslation(["dateFormats"])

  const formatedDate = DateTime.fromJSDate(props.timeFrom).toFormat(
    t("dateFullMonth")
  )
  const maxText = 100
  const [isOpen, setModalVisible] = React.useState<boolean>(false)
  const location = useLocation()

  const onClick = () => {
    setModalVisible(true)
  }
  const onRequestClose = () => {
    setModalVisible(false)
  }

  const isNewsPage = () => location.pathname == "/news"

  const ModalCustomStyle: ReactModal.Styles = props.isFullContent ? {
    content: {
      width: "auto",
      padding: 0,
    },
  } : {}

  return (
    <>
      <div
        className={cx(styles.newsPreview, props.className)}
        onClick={onClick}
      >
        <div
          className={styles.titleRow}
          style={
            isNewsPage()
              ? {
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                }
              : {}
          }
        >
          <a href="#">{props.title}</a>
          <span className={styles.date}>
            <FontAwesomeIcon
              icon={["far", "clock"]}
              style={isNewsPage() ? { marginRight: "15px" } : {}}
            />{" "}
            den {formatedDate}
          </span>
        </div>
        <div className={styles.textContainer}>
          <p className={styles.text}>
            {props.summary.length >= maxText
              ? props.summary.substring(0, maxText) + "..."
              : props.summary}
          </p>
        </div>
      </div>
      



      <Modal
        isOpen={isOpen}
        isLoading={false}
        isModalInModal={false}
        large={false}
        onRequestClose={onRequestClose}
        modalStyles={ModalCustomStyle}
        isPopUp={props.isFullContent ? true : false}
      >
        <div className={props.isFullContent ? styles.imageNewsModal : styles.textNewsModal}>
        
          {!props.isFullContent && (
            <div className={styles.titleRow}>
              <a href="#">{props.title}</a>
              <span className={styles.date}>
                <FontAwesomeIcon icon={["far", "clock"]} /> {formatedDate}
              </span>
            </div>
          )}

          {props.isFullContent ? (
            <div
              dangerouslySetInnerHTML={{
                __html: props.text ? props.text : props.summary,
              }}
            ></div>
          ) : (
            <div className={styles.textContainer} style={{ borderBottom: 0 }}>
              <p
                dangerouslySetInnerHTML={{
                  __html: props.text ? props.text : props.summary,
                }}
                className={styles.text}
              ></p>
            </div>
          )}
        </div>
      </Modal>
    </>
  )
}

interface NewsPreviewProps {
  className?: string
}

export default NewsPreview
