import * as React from "react"
import * as styles from "./styles.scss"
import cx from "classnames"
import AdminSectionTooltip from "../AdminSectionTooltip"

const UnitValueInput: React.FC<UnitValueInputProps> = (props) => {

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    props.onChange(e.target.value === "" ? undefined : e.target.value)
  }

  const handleBlur = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (props.onBlur) {
      props.onBlur()
    }
  }

  return (
    <div className={cx(styles.unitValueInput, props.className)}>
      <AdminSectionTooltip
        id={`unit-${props.tooltipText}`}
        text={props.tooltipText!}
      >
        {props.svg && (
          <div
            className={cx(
              styles.iconContainer,
              props.active === false ? styles.inactive : styles.active
            )}
          >
            <img className={styles.icon} src={props.svg} alt="" />
          </div>
        )}
        {props.jsx && <div className={""}>{props.jsx}</div>}
      </AdminSectionTooltip>

      <input
        className={styles.input}
        onBlur={handleBlur}
        value={props.value === 0 ? "0" : props.value ? props.value : ""}
        onChange={handleChange}
        onKeyDown={props.onKeyDown}
        disabled={props.isInputDisable}
        type={props.inputType ?? "number"}
        placeholder={props.placeholder}
      />
      {props.unit && (
        <div className={styles.unitContainer}>
          <span className={styles.unit}>{props.unit}</span>
        </div>
      )}
    </div>
  )
}

interface UnitValueInputProps {
  svg?: any
  jsx?: JSX.Element
  value?: any
  unit?: string
  className?: string
  tooltipText?: string
  onChange: (value: string | undefined) => void
  onBlur?: () => void
  active?: boolean
  placeholder?: any
  isInputDisable?: boolean
  inputType?: "number" | "string"
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void
}

export default UnitValueInput
