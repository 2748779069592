import * as React from "react"
import * as styles from "./styles.scss"
import * as cx from "classnames"

class Whiteframe extends React.PureComponent<WhiteframeProps> {
  static defaultProps = { padding: true }

  render() {
    const { children, className, padding, onClick, style } = this.props

    return (
      <div
        className={cx(styles.whiteframe, className, {
          [styles.padding]: padding,
        })}
        onClick={onClick}
        style={style}
      >
        {children}
      </div>
    )
  }
}

interface WhiteframeProps {
  padding?: boolean
  className?: string
  style?: any
  onClick?: (props: any) => any
}

export default Whiteframe
