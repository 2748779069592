import * as React from "react"
import Areas from "./Areas"
import {
  GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas,
  GetEditOrderPageCaseFolder_caseFolder_editTabs,
  GetOrderById_orders,
} from "schema"

const AreasContainer: React.FC<{
  areas: GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas[]
  selectedOrderId: string
  callbackAfterUpdate?: () => void
  editTabs: GetEditOrderPageCaseFolder_caseFolder_editTabs[]
  order: GetOrderById_orders
  isFEUser: boolean
}> = (props) => {
  return (
    <Areas
      areas={props.areas}
      callbackAfterUpdate={props.callbackAfterUpdate}
      editTabs={props.editTabs}
      order={props.order}
      isFEUser={props.isFEUser}
    />
  )
}

export default AreasContainer
