import * as React from "react"
import * as styles from "./styles.scss"
import { motion } from "framer-motion"
import * as cx from "classnames"
import Button from "components/Button"
import { useTranslation } from "react-i18next"
import Status from "components/Status"
// import PublishTimeSelector from "components/PublishTimeSelector"
import Spinner from "components/Spinner"
import { useQuery, useApolloClient, useMutation } from "react-apollo-hooks"
import GET_REVIEW_CASE_FOLDER from "graphql/queries/getReviewCaseFolder"
import {
  GetAvailablePublishTimes,
  GetAvailablePublishTimesVariables,
  GetOrderById,
  GetOrderById_orders,
  OrderAlertingType,
  StatusColors,
  UpdatePublishTime,
  UpdatePublishTimeVariables,
} from "schema"
import { useParams } from "react-router-dom"
import { EditOrderPageParams } from "routes/EditOrderPageRoutes"
import { PriceContent } from "pages/ReviewPage/ReviewContainer/DocumentTypeCard/OrderInformation/Price"
import { SizeContent } from "pages/ReviewPage/ReviewContainer/DocumentTypeCard/OrderInformation/Size"
import ParallelPublishing from "pages/ReviewPage/ReviewContainer/DocumentTypeCard/OrderInformation/ParallelPublishing"
import OrdersModal from "pages/ReviewPage/ReviewContainer/DocumentTypeCard/PublishOrdersModal"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import styled from "styled-components"
import GET_AVAILABLE_PUBLISH_TIMES from "graphql/queries/getAvailablePublishTimes"
import UPDATE_PUBLISH_TIME from "graphql/mutations/updatePublishTime"
import moment from "moment"
import Tooltip from "components/Tooltip"
import Calendar from "components/Calendar"
import { FieldError, FieldSuccess } from "components/FieldWrapper"
import GET_ORDER_BY_ID from "graphql/queries/getOrderById"
import ProofModal from "components/ProofModal"
import produce from "immer"
import ReactTooltip from "components/Tooltip"
import { AppContext } from "App"
import EditPriceForm from "./EditPriceForm"
import { faEdit, faPen } from "@fortawesome/free-solid-svg-icons"
import { useContext } from "use-context-selector"
import ValidateErrorText from "components/ValidateAreaError"
import Overlay from "components/Overlay"

interface HamburgerState {
  proofModalIsOpen: boolean
}

export const BottomPublishOrderBlock: React.FC<{
  editTabs: any[]
  documentId: string
  orderId: string
  order: GetOrderById_orders
  caseFolderId: string
}> = (props) => {
  const params = useParams<EditOrderPageParams>()

  // const caseFolderId = params.caseFolderId
  // const action = params.action ?? ""
  // const orderId = params.orderId ?? ""

  // const client = useApolloClient()
  // const { data, loading, error } = useQuery<
  //   GetReviewCaseFolder,
  //   GetReviewCaseFolderVariables
  // >(GET_REVIEW_CASE_FOLDER, {
  //   client,
  //   variables: {
  //     id: caseFolderId,
  //   },

  //   context: {
  //     skipErrorModal: true,
  //   },
  //   fetchPolicy: "network-only",
  // })

  // const document = props.documents.filter((e) => e?.id === props.documentId)

  return (
    <>
      {/* {loading && loading === true ? (
        <div className={styles.container}>
          <div className={styles.whiteframe}>
            <div className={styles.loadingContainer}>
              <Spinner size={25} innerBallSize={5} />
            </div>
          </div>
        </div>
      ) : null} */}

      {/* {error ? (
        <div className={styles.container}>
          <div className={styles.whiteframe}>
            <div>{error.message}</div>
          </div>
        </div>
      ) : null} */}

      <BlockContent
        caseFolderId={props.caseFolderId}
        editTabs={props.editTabs}
        order={props.order}
        documentId={props.documentId}
      />
    </>
  )
}

const BlockContent: React.FC<{
  editTabs: any[]
  // order: GetReviewCaseFolder_caseFolder_documents_orders
  order: GetOrderById_orders
  caseFolderId: string
  documentId: string
}> = (props) => {
  const [state, setState] = React.useState<HamburgerState>({
    proofModalIsOpen: false,
  })
  const [t] = useTranslation(["common", "review", "editOrder"])
  const [detailsIsOpen, setDetailsIsOpen] = React.useState(false)
  const [isLoading, setIsLoading] = React.useState(false)
  const [
    publishOrdersModalIsOpen,
    setpublishOrdersModalIsOpen,
  ] = React.useState(false)
  // const { validateErrorTexts } =
  //   props.order.mediaOrder?.publishConversation ?? {}

  // const [modalCompareAdvertsOpen, setModalCompareAdvertsOpen] = React.useState(
  //   false
  // )

  const onToggleDetails = () => {
    setDetailsIsOpen(!detailsIsOpen)
  }
  const onPressPublish = () => {
    setpublishOrdersModalIsOpen(true)
  }
  // const onPressModalCompareAdvertsOpen = () => {
  //   setModalCompareAdvertsOpen(!modalCompareAdvertsOpen)
  // }
  // const onPressModalCompareAdvertsClose = () => {
  //   setModalCompareAdvertsOpen(false)
  // }
  const closePublishOrdersModal = () => {
    setpublishOrdersModalIsOpen(false)
  }

  const openProofModal = () =>
    setState(
      produce(state, (draft) => {
        draft.proofModalIsOpen = true
      })
    )

  const closeProofModal = () =>
    setState(
      produce(state, (draft) => {
        draft.proofModalIsOpen = false
      })
    )

  const alerting = props.order.alerting

  // const stripNullValues = (arr: Array<any>) => {
  //   return arr.filter((e) => e !== null && e !== undefined)
  // }


  return (
    <div>
      <div className={styles.container}>
        <div className={styles.whiteframe}>
          <Overlay isVisible={props.order.isDeleted} />
          <div className={styles.level1}>
            <div className={styles.leftBlock}>
              {/* <div className={styles.buttonCompare}>
                <Button
                  onClick={onPressModalCompareAdvertsOpen}
                  variant={"tertiary"}
                  icon={"search-plus"}
                >
                  {t("review:buttonCompare")}
                </Button>
              </div> */}

              <div>
                <div className={styles.buttonDetails}>
                  <Button
                    onClick={onToggleDetails}
                    variant={"tertiary"}
                    icon={detailsIsOpen ? "chevron-up" : "chevron-down"}
                  >
                    {t("editOrder:showDetails")}
                  </Button>
                </div>
              </div>

              <div className={styles.buttonCompare}>
                <Button
                  onClick={openProofModal}
                  variant={"tertiary"}
                  // icon={"list"}
                  dataTestId={"proof-module-open-button"}
                >
                  {t("editOrder:proof")}
                </Button>
              </div>
            </div>
            <div className={styles.rightBlock}>
              <div className={styles.buttonStatus}>
                {alerting.type === OrderAlertingType.WARNING ||
                alerting.type === OrderAlertingType.CRITICAL ? (
                  <AlertStyles>
                    <span className={"alert-messages"}>
                      {alerting.type === OrderAlertingType.CRITICAL ? (
                        <div className={"icon-wrapper"}>
                          <FontAwesomeIcon
                            icon="exclamation-triangle"
                            color={"#FF5327"}
                          />
                        </div>
                      ) : null}

                      {alerting.type === OrderAlertingType.WARNING ? (
                        <div className={"icon-wrapper"}>
                          <FontAwesomeIcon
                            icon="exclamation-triangle"
                            color={"#fea15e"}
                          />
                        </div>
                      ) : null}
                      <ReactTooltip text={alerting.text} id="alert">
                        <p className={`text-color-${alerting.type}`}>
                          {alerting.smallText}
                        </p>
                      </ReactTooltip>
                    </span>

                    {/* <span className={"alert-messages"}>
                      {alerting.messages.map((e) => {

                        return (
                          <span key={e.text} className={`text-color-${e.type}`}>
                            {e.text}
                          </span>
                        )
                      })}
                    </span> */}
                  </AlertStyles>
                ) : null}
              </div>

              <div className={styles.buttonDate}>
                <b>{t("common:publishDate")}</b>
                <PublishTimeSelector
                  orderId={props.order.id}
                  publishTime={
                    props.order.mediaOrder!.publishTimes[0] ?? undefined
                  }
                  disabled={props.order.mediaOrder?.isLocked}
                  className={styles.publishTime}
                  setIsLoading={setIsLoading}
                />
              </div>

              <div className={styles.buttonPublish}>
                <Button
                  onClick={onPressPublish}
                  color={"primary"}
                  variant={"primary"}
                  // disabled={validateErrorTexts && validateErrorTexts?.length > 0}
                  dataTestId={"publish-button"}
                >
                  {/* <ValidateErrorText validateErrorTexts={validateErrorTexts!} /> */}
                  {isLoading ? (
                    <Spinner size={12} innerBallSize={2.5} />
                  ) : (
                   props.order.mediaOrder?.edit.userInterface.publishButtonText
                  )}
                </Button>
              </div>
            </div>
          </div>
          <PublishOrderDetailsBlock
            isOpen={detailsIsOpen}
            // document={props.document}
            order={props.order}
            caseFolderId={props.caseFolderId}
          />
        </div>
        {/* <div className={styles.sidebar}>&nbsp;</div> */}
      </div>

      {/* {modalCompareAdvertsOpen === true ? (
        <ModalCompareAdverts
          isOpen={modalCompareAdvertsOpen}
          onClose={onPressModalCompareAdvertsClose}
          orders={props.documents.filter(notEmpty)}
        />
      ) : null} */}

      {publishOrdersModalIsOpen === true ? (
        <OrdersModal
          isOpen={publishOrdersModalIsOpen}
          onClose={closePublishOrdersModal}
          documentId={props.documentId}
          setIsLoading={setIsLoading}
        />
      ) : null}

      <ProofModal
        isOpen={state.proofModalIsOpen}
        onRequestClose={closeProofModal}
      />
    </div>
  )
}

const AlertStyles = styled.div`
  .alert-content-wrapper {
    display: flex;
  }
  .icon-wrapper {
    margin-right: 3px;
  }
  .alert-messages {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .text-color-CRITICAL {
    color: #ff5327;
  }
  .text-color-WARNING {
    color: #fea15e;
  }
  .text-color-NONE {
    color: #000;
  }
  .__react_component_tooltip {
    color: #ff5327 !important;
    background-color: white !important;
  }
`

const PublishOrderDetailsBlock: React.FC<{
  isOpen: boolean
  caseFolderId: string
  // document: GetReviewCaseFolder_caseFolder_documents
  order: GetOrderById_orders
}> = (props) => {
  const [isEditPriceVisible, setEditPriceVisible] = React.useState<boolean>(
    false
  )
  const { isAllowUpdateAddOn } = React.useContext(
    AppContext
  ).currentCustomer.inheritSettings
  const isMediaUser = React.useContext(AppContext).currentUserSession.user
    ?.isMediaUser
  const animationStates = {
    open: { opacity: 1, height: "auto" },
    closed: { opacity: 0, height: 0 },
  }
  const { t } = useTranslation(["common", "review", "supportView"])
  const statusColor: StatusColors =
    props.order.mediaOrder?.status.color ?? StatusColors.YELLOW
  const statusName = props.order.mediaOrder?.status.status ?? ""
  return (
    <motion.div
      initial={"closed"}
      variants={animationStates}
      animate={props.isOpen ? "open" : "closed"}
    >
      <div className={styles.level2}>
        <div className={styles.leftBlock}>
          <div className={styles.columnRow}>
            <strong>{t("supportView:order")}</strong>
            <div>{props.order.customerFriendlyId}</div>
          </div>

          <div className={styles.columnRow}>
            <strong>{t("common:size")}</strong>
            <SizeContent
              width={
                props.order.mediaOrder!.preview.material.basePage.size?.width
              }
              height={
                props.order.mediaOrder!.preview.material.basePage.size?.height
              }
            />
          </div>

          <div
            className={cx(styles.columnRow, styles.priceWrapper)}
            onClick={() => setEditPriceVisible(true)}
          >
            {isEditPriceVisible && isAllowUpdateAddOn && (
              <EditPriceForm
                isEditPriceVisible={isEditPriceVisible}
                setEditPriceVisible={setEditPriceVisible}
                doClose={() => setEditPriceVisible(false)}
                orderId={props.order.id}
                priceContract={
                  props.order.mediaOrder?.preview.price?.customer.priceContract
                }
                priceContractOverride={
                  props.order.mediaOrder?.preview.price?.customer
                    .priceContractOverride
                }
              />
            )}
            {isAllowUpdateAddOn ? (
              <div className={styles.priceTitle}>
                <strong>{t("review:price")}</strong>
                <FontAwesomeIcon icon={faPen} />
              </div>
            ) : (
              <div className={styles.priceTitle}>
                <strong>{t("review:price")}</strong>
              </div>
            )}
            <PriceContent
              price={
                props.order.mediaOrder!.preview.price?.endCustomer
                  .pricePayRounded
              }
              currency={
                props.order.mediaOrder!.preview.price?.currency ?? "SEK"
              }
            />
          </div>
          <div className={styles.columnRow}>
            <strong>{t("review:status")}</strong>
            <Status
              showStatusLabel={false}
              variant={statusColor}
              className={styles.status}
              statusValue={statusName}
              circleFirst={true}
            />
          </div>
        </div>

        <div className={styles.rightBlock}>
          {!isMediaUser && (
            <div className={styles.publishBlock}>
              <ParallelPublishing
                orderId={props.order.id!}
                caseFolderId={props.caseFolderId}
                isLocked={false}
                publishChannels={props.order.mediaOrder!.publishChannels}
              />
            </div>
          )}
        </div>
      </div>
    </motion.div>
  )
}

const PublishTimeSelector: React.FC<{
  orderId: any
  publishTime?: any
  disabled?: boolean
  className?: string
  setIsLoading: (arg: boolean) => void
}> = (props) => {
  // const match = useRouteMatch<{ caseFolderId: any }>()
  // const selectedOrder: GetOrderById_orders = props.selectedOrder
  // const publishTime = selectedOrder.mediaOrder!.publishTimes[0]
  const client = useApolloClient()
  const { loading, error, data } = useQuery<
    GetAvailablePublishTimes,
    GetAvailablePublishTimesVariables
  >(GET_AVAILABLE_PUBLISH_TIMES, {
    variables: { orderId: props.orderId },
    client,
  })

  const updatePublishTime = useMutation<
    UpdatePublishTime,
    UpdatePublishTimeVariables
  >(UPDATE_PUBLISH_TIME, {
    client,
    update: (cache, result) => {
      const query = GET_ORDER_BY_ID
      const variables = {
        id: props.orderId,
      }
      const cacheRead = cache.readQuery({
        query,
        variables,
      })
      const _data = produce(cacheRead as GetOrderById, (draft) => {
        if (draft.orders[0].mediaOrder && draft.orders[0].mediaOrder.preview) {
          draft.orders[0].mediaOrder.preview =
            (result.data?.updateOrderPublishTimes.order.mediaOrder
              ?.preview as any) ?? {}
        }
        if (
          draft.orders[0].mediaOrder &&
          draft.orders[0].mediaOrder.publishTimes
        ) {
          draft.orders[0].mediaOrder.publishTimes =
            (result.data?.updateOrderPublishTimes.order.mediaOrder
              ?.publishTimes as any) ?? {}
        }
      })
      cache.writeQuery({
        query,
        variables,
        data: _data,
      })
    },
  })

  const { t } = useTranslation(["common", "errorMessages"])
  const [
    mutationOrderInfoLoading,
    setMutationOrderInfoLoading,
  ] = React.useState<boolean>(false)
  props.setIsLoading(mutationOrderInfoLoading)
  const [mutationOrderInfoError, setMutationOrderInfoError] = React.useState<
    string
  >("")
  const [mutationOrderInfoStatus, setMutationOrderInfoStatus] = React.useState<
    string
  >("")

  const [includeDates, setIncludeDates] = React.useState<any[]>([])
  // const includeDates = (data?.mediaPublishCalendarDays ?? [])
  //   .filter((day) => day.isEnabled)
  //   .map((d) => new Date(d.timeDay))
  const [dayTooltip, setDayTooltip] = React.useState({})

  React.useEffect(() => {
    const _includeDates = (data?.mediaPublishCalendarDays ?? [])
      .filter((day) => day.isEnabled)
      .map((d) => new Date(d.timeDay.substring(0, d.timeDay.length - 1)))
    setIncludeDates(_includeDates)
    const result = {}
    _includeDates.forEach((dayDate: Date) => {
      // get dayInfo
      const day = data?.mediaPublishCalendarDays?.filter(
        (q) =>
          new Date(q.timeDay.substring(0, q.timeDay.length - 1)).getTime() ===
          dayDate.getTime()
      )[0]
      if (!day) {
        return
      }

      const dateKey = moment(day.timeDay).format("YYYY_MM_DD")
      if (
        day.isEnabled === false ||
        day.timeBooking === undefined ||
        day.timeBooking === null ||
        day.timeBooking.length === 0
      ) {
        result[dateKey] = day.comment
        return
      }

      result[dateKey] =
        t("bookingStop") +
        ": " +
        moment(day.timeBooking).format("YYYY-MM-DD HH:mm") +
        (day.comment == "" ? "" : "<br />" + day.comment)
    })
    setDayTooltip(result)
  }, [data])

  const handleDateSelect = (date: Date) => {
    setMutationOrderInfoLoading(true)
    if (props.disabled === true) {
      return
    }
    if (!date) {
      return
    }

    // Get only the date part of the date (for unclear reasons I get hour as well)
    let newDate = new Date(date.getFullYear(), date.getMonth(), date.getDate())

    updatePublishTime({
      variables: {
        input: {
          orderId: props.orderId,
          publishTimes: [newDate],
        },
      },
    })
      .then((response) => {
        if (response.errors) {
          setMutationOrderInfoError(
            response.errors.map((e) => e.message).join(", ")
          )
          return
        }

        if (!response.data) {
          setMutationOrderInfoError(t("errorMessages:unknowError"))
          return
        }

        if (response.data.updateOrderPublishTimes.isError === true) {
          setMutationOrderInfoError(
            response.data.updateOrderPublishTimes.errorReason ??
              t("errorMessages:unknowError")
          )
          return
        }
        setMutationOrderInfoLoading(false)
      })
      .catch((err) => {
        setMutationOrderInfoLoading(false)
        setMutationOrderInfoError(err.toString())
      })
  }

  const renderDayContents = (dayOfMonth: number, date: string) => {
    const dateKey = moment(date).format("YYYY_MM_DD")
    const tooltipText = dayTooltip[dateKey]

    if (tooltipText === undefined) {
      return <span>{dayOfMonth}</span>
    }

    return (
      <Tooltip id={`calendarTooltip-${dayOfMonth}`} text={tooltipText}>
        {dayOfMonth}
      </Tooltip>
    )
  }

  return (
    <div>
      {loading ? <span>...</span> : null}

      {/* {mutationOrderInfoLoading ? <span>...</span> : null} */}

      {error ? <FieldError text={error.message} /> : null}

      {mutationOrderInfoStatus ? (
        <FieldSuccess text={mutationOrderInfoStatus} />
      ) : null}

      {mutationOrderInfoError ? (
        <FieldError text={mutationOrderInfoError} />
      ) : null}

      {data ? (
        <Calendar
          id="advert-publish-date"
          onChange={handleDateSelect}
          includeDates={includeDates}
          selectedDate={
            props.publishTime && props.publishTime.time
              ? new Date(props.publishTime.time)
              : undefined
          }
          disabled={props.disabled}
          className={props.className}
          renderDayContents={renderDayContents as any}
        />
      ) : null}
    </div>
  )

  // return (
  //   <InnerPublishTimeSelector
  //     onUpdatePublishTime={updatePublishTime as any}
  //     orderId={props.selectedOrderId}
  //     disabled={props.disabled}
  //     selectedDate={
  //       props.publishTime && props.publishTime.time
  //         ? new Date(props.publishTime.time)
  //         : undefined
  //     }
  //     includeDates={data?.mediaPublishCalendarDays ?? []}
  //     className={props.className}
  //   />
  // )
}
