import React from "react"
import styled from "styled-components"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Spinner from "components/Spinner"

const LockerBox: React.FC<{
  text: string
  className?: string
  icon?: string
  ShowIcon?: boolean
  color?: string
  loading?: boolean
}> = (props) => {
  return (
    <DivContainer>
      <div className={`container ${props.className} ${props.color}`}>
        <div>
        {props.loading? (
            <Spinner size={15} innerBallSize={2.5} color={"#fff"}/>
        ): (
          <FontAwesomeIcon
          icon={props.icon === "UNLOCKED" ? "unlock" : "lock"}
        />
        )}

          <span>{props.text}</span>
          
        </div>
      </div>
    </DivContainer >
  )
}

export default LockerBox

const DivContainer = styled.div`
  .container {
    border-radius: 4px;
    padding: 5px;
    div {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 5px;
      color: white;
      span {
        font-weight: 500;
        font-size: 14px;
      }
    }
    &.GREEN {
      border: 1px solid #1fcf8c;
      background-color: #1fcf8c;
    }
    &.BLUE {
      border: 1px solid #1778e4;
      background-color: #1778e4;
    }       
    &.YELLOW {
      border: 1px solid #fea15e;
      background-color: #fea15e;
    }    
    &.RED {
      border: 1px solid #f15e5e;
      background-color: #f15e5e;
    }
    &.BLACK {
      border: 1px solid #13012b;
      background-color: #13012b;
    }
  }
`
