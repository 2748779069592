import React from "react"
import * as styles from "./styles.scss"
import ReactModal from "components/Modal"
import { useQuery, useApolloClient } from "react-apollo-hooks"
import { UserLogs, UserLogsVariables, UserLogs_userLogs } from "schema"
import USER_LOGS from "graphql/queries/userLogs"
import ReactTable from "react-table-6"
import moment from "moment"
import { useTranslation } from "react-i18next"
import cx from "classnames"
import ExpandedTable from "./ExpandedTable"



const UserLogModal: React.FC<{
  isOpen: boolean
  onClose: () => void
  orderId: string
}> = (props) => {
  const client = useApolloClient()
  const { t } = useTranslation(["editOrder", "common"])

  const { error, loading, data } = useQuery<UserLogs, UserLogsVariables>(
    USER_LOGS,
    {
      client,
      fetchPolicy: "network-only",
      variables: {
        orderId: props.orderId,
      },
    }
  )


  return (
    <ReactModal isOpen={props.isOpen} onRequestClose={props.onClose} large={true}>
      <ReactTable
        className={cx("-highlight logTable", styles.logTable)}
        data={data?.userLogs}
        columns={[
          {
            id:"expander",
            Header: "",
            width: 10
          },
          {
            id: "time",
            Header: "Tid",
            accessor: (v) =>
              moment(moment.utc(v.time).toDate()).local().format("YYYY-MM-DD HH:mm:ss"),
          },
          {
            id: "des",
            Header: "Beskrivning",
            accessor: (v) => v.summeryText,
          },
          {
            id: "period",
            Header: "Period",
            accessor: (v) =>  Math.round(v.period) 
          },
          {
            id: "userName",
            Header: "Användarnamn",
            accessor: (v) => v.userName,
          },
        ]}
        loading={loading}
        rowsText={"radar"}
        noDataText={t("common:noResults")}
        showPagination={false}
        pageSize={data?.userLogs?.length! + 1}
        SubComponent={(row) => {
          return <ExpandedTable data={row.original} />
        }}

        />
    </ReactModal>
  )
}

export default UserLogModal
