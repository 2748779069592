import * as React from "react"
import * as styles from "./styles.scss"
import { DropdownOption } from "components/DropdownButton"
import Option from "./Option"
import cx from "classnames"
import { AppContext } from "App"

const OptionsList:React.FC<OptionsListProps> = (props) => {
    const {featureFlags} = React.useContext(AppContext)
    return (
      <ul className={cx(styles.list, props.className)} style={{width: props.listWidth}}>
        {props.options.map(option => (
          <Option
            key={option.value + option.label}
            option={option}
            onHover={props.onOptionHover}
            onClick={props.onClick}
            defaultValue={props.defaultValue}
            selectedValue={props.selectedValue}
            isFEUser={featureFlags?.isFEUser!}
          />
        ))}
      </ul>
    )
}

interface OptionsListProps {
  options: DropdownOption[]
  className?: string
  onClick: (option: DropdownOption) => void
  onOptionHover?: (id?: string) => void
  defaultValue?:number
  selectedValue?: number | null
  listWidth?: string

}

export default OptionsList
