import React from "react"
import * as styles from "./styles.scss"
import { useVerseEditingContextDispatch } from "./VerseModalContext"
import Button from "components/Button"
import { useQuery } from "react-apollo-hooks"
import GET_GALLERY_TEXT_GROUPS_BY_TAGS from "graphql/queries/getGalleryTextGroupsByTags"
import {
  EditModelAdvertAreaContentEditorBehaviours,
  getGalleryTextGroupsByTags,
  getGalleryTextGroupsByTagsVariables,
} from "schema"
import { useDebouncedCallback } from "use-debounce"
import ErrorMessage from "components/ErrorMessage"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSearch, faTimes } from "@fortawesome/free-solid-svg-icons"
import { OrderEditorAreaContext } from "../AreasContainer/Areas/AreaContainer"
import Modal from "components/Modal"
import { faBookmark as bookmarkRegular } from "@fortawesome/free-regular-svg-icons"
import { faBookmark as bookmarkSolid } from "@fortawesome/free-solid-svg-icons"
import Verses from "./Verses"
import produce from "immer"
import Pagination from "components/Pagination"
import { IPaginationsOptions } from "customSchema.interface"
import Spinner from "components/Spinner"
import { useTranslation } from "react-i18next"
import i18next from "i18next"
import cx from "classnames"

export const VerseEditingModal: React.FC<{
  setVerseInExpandedArea?: any
  isOpen: boolean
  onClose?: () => void
  editorTextValue: string
  editorBehaviour: EditModelAdvertAreaContentEditorBehaviours
  isValueDemo: boolean
  isFEUser: boolean
}> = (props) => {
  const { templateAreaId, documentFormatId, mediaId } = React.useContext(
    OrderEditorAreaContext
  )

  if (props.isOpen === false) {
    return <></>
  }
  return (
    <ModalContainer
      setVerseInExpandedArea={props.setVerseInExpandedArea}
      isOpen={props.isOpen!}
      onClose={props.onClose!}
      templateAreaId={templateAreaId}
      documentFormatId={documentFormatId}
      mediaId={mediaId}
      editorTextValue={props.editorTextValue}
      editorBehaviour={props.editorBehaviour}
      isValueDemo={props.isValueDemo}
      isFEUser={props.isFEUser}
    />
  )
}

export interface IState {
  count: number
  filterText: string
  startIndex: number
  filterTextInputTouched: boolean
}

export interface IMessage {
  addItem: string | null
  removeItem: string | null
  updateItem: string | null
}

const ModalContainer: React.FC<{
  setVerseInExpandedArea?: any
  isOpen: boolean
  onClose: () => void
  templateAreaId: string | null
  documentFormatId: string | undefined
  mediaId: string | null | undefined
  editorTextValue: string
  editorBehaviour: EditModelAdvertAreaContentEditorBehaviours
  isValueDemo: boolean
  isFEUser: boolean
}> = (props) => {
  const defaultSearchText = ""
  const MessageDelay = 1000 * 3
  const { TEXT_HTML_VERSE } = EditModelAdvertAreaContentEditorBehaviours
  const dispatch = useVerseEditingContextDispatch()
  let timer = React.useRef<any>(null)

  const isVersesGallery = props.editorBehaviour === TEXT_HTML_VERSE
  const [filterGalleryPrivate, setFilterGalleryPrivate] = React.useState<
  boolean
>(isVersesGallery ? false : true)
  const [filterGalleryPublic, setFilterGalleryPublic] = React.useState<boolean>(
     true
  )
  const filterText = defaultSearchText
  const [filterTextInputTouched, setFilterTextInputTouched] = React.useState<
    boolean
  >(false)
  const [currentPage, setCurrentPage] = React.useState(1)

  const [state, setState] = React.useState<IPaginationsOptions>({
    startIndex: 0,
    totalImageCount: 0,
    PAGE_LENGTH: 8,
    actuallyPageLength: 0,
  })

  const [t] = useTranslation()

  const [message, setMessage] = React.useState<IMessage>({
    addItem: null,
    removeItem: null,
    updateItem: null,
  })

  const onPressClose = () => {
    dispatch({ type: "closeModal" })
    props.onClose()
  }

  const { loading, data, error, refetch } = useQuery<
    getGalleryTextGroupsByTags,
    getGalleryTextGroupsByTagsVariables
  >(GET_GALLERY_TEXT_GROUPS_BY_TAGS, {
    variables: {
      input: {
        tags: [],
        filterText: filterText,
        count: state.PAGE_LENGTH,
        startIndex: state.startIndex,
        mediaId: props.mediaId,
        templateAreaId: props.templateAreaId,
        documentFormatId: props.documentFormatId,
        filterGalleryPrivate,
        filterGalleryPublic,
      },
    },
  })

  if (error) {
    return <ErrorMessage message={error.message} />
  }

  const delayMessageBeforeHide = (
    key: "addItem" | "removeItem" | "updateItem"
  ) => {
    timer.current = setTimeout(() => {
      setMessage(
        produce(message, (draft) => {
          draft[key] = null
        })
      )
    }, MessageDelay)
  }
  const renderMessage = () => {
    clearTimeout(timer.current)
    if (message.addItem != null) {
      delayMessageBeforeHide("addItem")
      return <span>{message.addItem}</span>
    }
    if (message.updateItem != null) {
      delayMessageBeforeHide("updateItem")
      return <span>{message.updateItem}</span>
    }
    if (message.removeItem != null) {
      delayMessageBeforeHide("removeItem")
      return <span>{message.removeItem}</span>
    }
    return null
  }

  React.useEffect(() => {
    if (
      data?.galleryTextGroupsByTags &&
      data?.galleryTextGroupsByTags.count &&
      data?.galleryTextGroupsByTags.count !== undefined
    ) {
      setState(
        produce(state, (draft) => {
          draft.totalImageCount = data?.galleryTextGroupsByTags.count
        })
      )
    }
  }, [data, state])

  const onPaginationIndxChange = (page: number) => {
    setCurrentPage(page)
  }

  const resetPagination = () => {
    setState(
      produce(state, (draft) => {
        draft.startIndex = 0
      })
    )
    setCurrentPage(1)
  }

  return (
    <Modal
      isOpen={props.isOpen}
      onRequestClose={onPressClose}
      large={true}
      className={cx(styles.modal, {
        [styles.feView__modal]: props.isFEUser
      }) }
      showCloseIcon={false}
    >
      <div className={cx(styles.modalContainer,{
        [styles.feView__modalContainer]: props.isFEUser
      })}>
        <div className={styles.modalHeader}>
          <div className={styles.messageWrapper}>{renderMessage()}</div>
          <div />
          <h3> {isVersesGallery && !filterGalleryPrivate ? t("editOrder:verses") : t("editOrder:savedTexts")}</h3>
          <div className={styles.closeButton} onClick={onPressClose}>
            <FontAwesomeIcon icon={faTimes} />
          </div>
        </div>

        <div className={styles.content}>
          <ContentHeader
            state={state}
            refetch={refetch}
            mediaId={props.mediaId}
            templateAreaId={props.templateAreaId}
            documentFormatId={props.documentFormatId}
            setFilterGalleryPrivate={setFilterGalleryPrivate}
            filterGalleryPrivate={filterGalleryPrivate}
            filterTextInputTouched={filterTextInputTouched}
            setFilterTextInputTouched={setFilterTextInputTouched}
            resetPagination={resetPagination}
            filterGalleryPublic={filterGalleryPublic}
            setFilterGalleryPublic={setFilterGalleryPublic}
            t={t}
            isVerseEditor={isVersesGallery}
            isFEUser={props.isFEUser}
          />
          {loading && <Spinner />}

          <Verses
            cloneText={props.editorTextValue}
            texts={
              data &&
              data.galleryTextGroupsByTags &&
              data?.galleryTextGroupsByTags.texts
            }
            versesRefetch={() => refetch()}
            setVerseInExpandedArea={props.setVerseInExpandedArea}
            onPressClose={onPressClose}
            templateAreaId={props.templateAreaId}
            documentFormatId={props.documentFormatId}
            mediaId={props.mediaId}
            message={message}
            setMessage={setMessage}
            filterTextInputTouched={filterTextInputTouched}
            filterGalleryPrivate={filterGalleryPrivate}
            isValueDemo={props.isValueDemo}
            isFEUser={props.isFEUser}
          />
          <div className={styles.paginationWrapper}>
            <Pagination
              isVerse={true}
              className={styles.pagination}
              paginationsOptions={state}
              setPaginationsOptions={setState}
              currentPage={currentPage}
              totalCount={state.totalImageCount}
              pageSize={state.PAGE_LENGTH}
              onPageChange={onPaginationIndxChange}
            />
          </div>
        </div>
      </div>
    </Modal>
  )
}

const ContentHeader: React.FC<{
  state: IPaginationsOptions
  refetch: any
  templateAreaId: string | null
  documentFormatId: string | undefined
  mediaId: string | null | undefined
  setFilterGalleryPrivate: (arg: boolean) => void
  filterGalleryPrivate: boolean
  filterTextInputTouched: boolean
  setFilterTextInputTouched: (arg: boolean) => void
  resetPagination: () => void
  filterGalleryPublic: boolean
  setFilterGalleryPublic: (arg: boolean) => void
  t: i18next.TFunction
  isVerseEditor: boolean
  isFEUser: boolean
}> = ({
  state,
  refetch,
  templateAreaId,
  documentFormatId,
  mediaId,
  setFilterGalleryPrivate,
  filterGalleryPrivate,
  setFilterTextInputTouched,
  filterTextInputTouched,
  resetPagination,
  filterGalleryPublic,
  setFilterGalleryPublic,
  isVerseEditor,
  isFEUser,
  t,
}) => {
  const DEBOUNCE_TIME = 500
  const [inputValue, setInputValue] = React.useState<string>("")
  const [debouncedCallback] = useDebouncedCallback((value) => {
    if (value.length < 1) {
      setFilterTextInputTouched(false)
    }
    setInputValue(value)
    refetch({
      input: {
        tags: [],
        filterText: value,
        count: state.PAGE_LENGTH,
        startIndex: state.startIndex,
        mediaId: mediaId,
        templateAreaId: templateAreaId,
        documentFormatId: documentFormatId,
      },
    })
  }, DEBOUNCE_TIME)

  const handleFilterSavedText = () => {
    resetPagination()
    setFilterGalleryPrivate(!filterGalleryPrivate)
    setFilterGalleryPublic(!filterGalleryPublic)
    refetch()
  }

  // const handleToggleFilterGalleryPrivate = () => {
  //   resetPagination()
  //   setFilterGalleryPrivate(!filterGalleryPrivate)
  //   refetch()
  // }

  return (
    <div className={styles.headerContent}>
      <div className={cx(styles.searchWrapper, {
        [styles.FEView__searchWrapper]: isFEUser
      })}>
        <FontAwesomeIcon className={styles.searchIcon} icon={faSearch} />
        <input
          placeholder={t("common:search")}
          className={styles.searchInput}
          type="text"
          name={"search"}
          autoFocus={true}
          value={inputValue}
          onChange={({ target: { value } }) => {
            if (filterTextInputTouched === false) {
              setFilterTextInputTouched(true)
            }
            setInputValue(value)
            debouncedCallback(value)
          }}
        />
      </div>
        <div className={styles.buttonWrapper}>
          {/* {filterGalleryPublic && (
            <Button
              onClick={handleToggleFilterGalleryPrivate}
              icon={!filterGalleryPrivate ? bookmarkSolid : bookmarkRegular}
            >
              {"visa/dölj sparade texter"}
            </Button>
          )} */}
          {isVerseEditor && (
                      <Button
                      onClick={handleFilterSavedText}
                      icon={!filterGalleryPrivate ? bookmarkRegular : bookmarkSolid}
                      variant={isFEUser  ?"feView" : "secondary"}
                    >
                       {!filterGalleryPrivate ?  t("editOrder:showSavedTexts") :  t("editOrder:showVerses")   }
                      {/* {t("editOrder:savedTexts")} */}
                    </Button>
          )}

        </div>
    </div>
  )
}
