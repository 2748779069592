import * as React from "react"
import * as styles from "./styles.scss"
import Header from "components/Header"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useTranslation } from "react-i18next"
import AddPackageButton from "./AddPackageButton"
import { useQuery, useMutation, useApolloClient } from "react-apollo-hooks"
import GET_PACKAGES from "graphql/queries/getPackages"
import { omitTypename, blurOnEnter } from "utils"
import update from "immutability-helper"
import {
  GetPackages_orderInitiatorPackagesByCurrentUser,
  GetPackages,
  UpdatePackageVariables,
  UpdatePackage,
  SelectOrderInitiatorView_selectOrderInitiatorView_orderInitiatorId,
  OrderInitiatorPackageInput,
  GetPackages_orderInitiatorPackageImages,
} from "schema"
import CommonPrice from "./Packages/PackageCardContainer/PackageCard/CommonPrice"
import UPDATE_PACKAGE from "graphql/mutations/updatePackage"
import MediaItems from "./Packages/PackageCardContainer/PackageCard/MediaItems"
import AddProductButton from "./Packages/PackageCardContainer/PackageCard/AddProductButton"
import DeletePackageButton from "./Packages/PackageCardContainer/PackageCard/DeletePackageButton"
import { produce } from "immer"
import { ErrorText } from "components/ErrorMessage"
import Spinner from "components/Spinner"
import { AddProductGroupOrderModalWithoutRouting } from "components/AddProductGroupOrderModal/AddProductGroupOrderModalWithoutRouting"
import Modal from "components/Modal"
import defaultIamge from "images/packageDefault.jpg"
import { image } from "components/Card/styles.scss"

const PackagesTab: React.FC<{}> = (props) => {
  const [t] = useTranslation(["agencySettings"])
  const client = useApolloClient()
  const { data, loading } = useQuery<GetPackages>(GET_PACKAGES, {
    client,
    fetchPolicy: "network-only",
  })

  return (
    <div className={styles.container}>
      {/* <Header
        title={t("agencySettings:yourPackages")}
        renderSubtitle={() => <Subtitle />}
        className={styles.header}
      /> */}

      <AddPackageButton />

      {/* <Packages
        packages={
          loading
            ? []
            : omitTypename(data.orderInitiatorPackagesByCurrentUser)
        }
        onAddProduct={onAddProduct}
      /> */}

      {loading === false && data !== undefined ? (
        <PackagesListWrapper
          initPackages={data.orderInitiatorPackagesByCurrentUser}
          initPackageImage={data.orderInitiatorPackageImages}
        />
      ) : null}
    </div>
  )
}

const Subtitle: React.FC<{}> = () => {
  const [t] = useTranslation(["agencySettings"])
  return (
    <span className={styles.subtitle}>
      {t("agencySettings:packageTabHeaderPartOne")}
      <FontAwesomeIcon icon="arrows-alt" />
      {t("agencySettings:packageTabHeaderPartTwo")}
    </span>
  )
}

const PackagesListWrapper: React.FC<{
  initPackages: GetPackages_orderInitiatorPackagesByCurrentUser[]
  initPackageImage: GetPackages_orderInitiatorPackageImages[]
}> = (props) => {
  // const [packages, setPackages] = React.useState<any[]>(
  //   omitTypename(props.initPackages)
  // )

  // const packages = omitTypename(
  //   props.initPackages
  // ) as GetPackages_orderInitiatorPackagesByCurrentUser[]

  return (
    <div className={styles.packageContainer}>
      {props.initPackages.map((agencyPackage, index) => (
        <PackageCard
          key={agencyPackage.id!}
          package={agencyPackage}
          packages={props.initPackages}
          imagesGallery={props.initPackageImage}
          isDragging={false}
          index={index}
        />
      ))}
    </div>
  )
}

const PackageCard: React.FC<{
  isDragging: boolean
  index: number
  package: GetPackages_orderInitiatorPackagesByCurrentUser
  packages: GetPackages_orderInitiatorPackagesByCurrentUser[]
  imagesGallery: GetPackages_orderInitiatorPackageImages[]
}> = (props) => {
  const [t] = useTranslation(["agencySettings", "common", "errorMessages"])
  const [isLoading, setIsLoading] = React.useState(false)
  const [openGallery, setOpenGallery] = React.useState(false)
  const [replacedImage] = React.useState(defaultIamge)
  const [query, setQuery] = React.useState<{
    loading: boolean
    error: string | undefined
    data: null
  }>({
    loading: false,
    error: undefined,
    data: null,
  })
  const [isBlur, setIsBlur] = React.useState<boolean>(false)
  const client = useApolloClient()
  const updatePackage = useMutation<UpdatePackage, UpdatePackageVariables>(
    UPDATE_PACKAGE,
    {
      client,
      context: {
        skipError: true,
      },
      refetchQueries: [{ query: GET_PACKAGES }],
    }
  )

  const handleUpdatePackage = (packageInput: any) => {
    const packages = [...props.packages]
    const index = packages.findIndex((pack) => pack.id === packageInput.id)

    packages[index] = packageInput as any

    setQuery(
      produce(query, (draft) => {
        draft.loading = true
        draft.error = undefined
      })
    )

    return updatePackage({
      variables: {
        input: {
          orderInitiatorPackages: packages.map((pack) => ({
            ...omitTypename(pack),
            items: pack.items!.map((item) => ({
              ...omitTypename(item.inputId),
            })),
          })),
        },
      },
    })
      .then((response) => {
        if (response.errors) {
          setQuery(
            produce(query, (draft) => {
              draft.loading = false
              draft.error = response.errors?.map((e) => e.message).join(", ")
            })
          )
          return null
        }

        if (!response.data) {
          setQuery(
            produce(query, (draft) => {
              draft.loading = false
              draft.error = t("errorMessages:unknowError")
            })
          )
          return null
        }
        return response
      })
      .catch((err) => {
        setQuery(
          produce(query, (draft) => {
            draft.loading = false
            draft.error = err.toString()
          })
        )
      })
  }

  const updatePackageName = (e: React.FocusEvent) => {
    if (e.target.innerHTML === props.package.name) return

    handleUpdatePackage({
      ...props.package,
      name: e.target.innerHTML,
    })
    setIsBlur(false)
  }

  const moveLeft = () => {
    const packages = update([...props.packages], {
      $splice: [
        [props.index, 1],
        [props.index - 1, 0, props.package],
      ],
    })
    updatePackage({
      variables: {
        input: {
          orderInitiatorPackages: packages.map((pack) => ({
            ...omitTypename(pack),
            items: pack.items!.map((item) => ({
              ...omitTypename(item.inputId),
            })),
          })),
        },
      },
    })
  }

  const moveRight = () => {
    const packages = update([...props.packages], {
      $splice: [
        [props.index, 1],
        [props.index + 1, 0, props.package],
      ],
    })
    updatePackage({
      variables: {
        input: {
          orderInitiatorPackages: packages.map((pack) => ({
            ...omitTypename(pack),
            items: pack.items!.map((item) => ({
              ...omitTypename(item.inputId),
            })),
          })),
        },
      },
    })
  }
  const canMoveLeft = props.index !== 0
  const canMoveRight = props.index !== props.packages.length - 1

  const [caseFolderId, setCaseFolderId] = React.useState<string | undefined>(
    undefined
  )
  const [showModal, setShowModal] = React.useState<boolean>(false)


  const onClickAdd = () => {
    setCaseFolderId(props.package.id)
    setShowModal(true)
  }
  const onSelectProduct = (
    node: SelectOrderInitiatorView_selectOrderInitiatorView_orderInitiatorId
  ) => {
    console.log("dodano package...", node, { ...omitTypename(node) })
    handleUpdatePackage({
      ...props.package,
      items: [
        ...props.package.items!,
        {
          inputId: {
            documentFormatInitiatorId: node.documentFormatInitiatorId,
            customerDocumentFormatInitiatorId : node.customerDocumentFormatInitiatorId,
            mediaId: node.mediaId,
          },
        },
      ],
    })
    setShowModal(false)
  }
  const onCloseModal = () => {
    setShowModal(false)
  }

  const isOpen = () => {
    setOpenGallery(true)
  }

  const updateImage = (e: any) => {
    const imageId = e.target.id
    setIsLoading(true)
    return new Promise((resovle) => {
      setTimeout(() => {
        handleUpdatePackage({
          ...props.package,
          id: props.package.id,
          imageId,
        }).then(() => {
          setOpenGallery(false)
          setIsLoading(false)
        })
      }, 300)
    })
  }
  const onClose = () => {
    setOpenGallery(false)
  }

  const BlurEditableFields = () => {
    setIsBlur(!isBlur)
  } 
  return (
    <div className={styles.packageCardContainer}>
      <div className={styles.packageCard}>
        {query.loading ? (
          <div className={styles.packageCardLoading}>
            <Spinner size={50} />
          </div>
        ) : null}

        <div
          className={styles.img}
          style={{
            backgroundImage: `url(${
              props.package.imageUrl ? props.package.imageUrl : replacedImage
            })`,
          }}
        >
          <div className={styles.buttonHolder}>
            <button onClick={isOpen}>{t("changeImage")}</button>
          </div>
        </div>
        <ImageModal
          onClose={onClose}
          openGallery={openGallery}
          updateImage={updateImage}
          imagesGallery={props.imagesGallery}
          isloading={isLoading}
        />
        <div className={styles.packageContent}>
          <div className={styles.packageContentHeader}>
            <div className={styles.dragSourceContainer}>
              {canMoveLeft ? (
                <div onClick={moveLeft} className={styles.dragArrow}>
                  <FontAwesomeIcon icon={"arrow-left"} />
                </div>
              ) : null}

              {canMoveRight ? (
                <div onClick={moveRight} className={styles.dragArrow}>
                  <FontAwesomeIcon icon={"arrow-right"} />
                </div>
              ) : null}
            </div>

            <div className={styles.nameBlock}>
              <h3
                contentEditable={true}
                suppressContentEditableWarning={true}
                onKeyDown={blurOnEnter}
                onBlur={updatePackageName}
                className={isBlur ? styles.blur : ""}
              >
                {props.package.name}
              </h3>
              <span onClick={BlurEditableFields} className={isBlur ? styles.blur : ""}>
                <FontAwesomeIcon icon={"edit"} />
              </span>
            </div>
          </div>

          <CommonPrice
            onUpdatePackage={handleUpdatePackage}
            package={props.package}
            isBlur={isBlur}
          />
          <b>{t("productsAndServices")}</b>
          {props.package.items && (
            <MediaItems
              package={props.package}
              onUpdatePackage={handleUpdatePackage}
            />
          )}
          <AddProductButton
            // package={props.package}
            // onUpdatePackage={handleUpdatePackage}
            onClick={onClickAdd}
          />
        </div>
        <DeletePackageButton id={props.package.id!} />

        {query.error ? <ErrorText>{query.error}</ErrorText> : null}
      </div>

      <AddProductGroupOrderModalWithoutRouting
        caseFolderId={caseFolderId}
        onSelectOrderInitiatorView={onSelectProduct}
        showModal={showModal}
        onCloseModal={onCloseModal}
      />
    </div>
  )
}

const ImageModal: React.FC<{
  onClose: () => void
  openGallery: boolean
  imagesGallery: GetPackages_orderInitiatorPackageImages[]
  updateImage: (e: any) => void
  isloading: boolean
}> = (props) => {
  const { t } = useTranslation("agencySettings")

  return (
    <Modal
      isOpen={props.openGallery}
      onRequestClose={props.onClose}
      isModalInModal={false}
      className={styles.galleryModal}
      large={false}
      isLoading={props.isloading}
    >
      <h3>{t("selectImage")}</h3>
      <div className={styles.gallery}>
        {props.imagesGallery.map((img) => {
          return (
            <div key={img.id} onClick={(event) => props.updateImage(event)}>
              <img src={img.imageUrl} alt={img.name} id={img.id} />
            </div>
          )
        })}
      </div>
    </Modal>
  )
}

// a little function to help us with reordering the result
const reorder = (list: any[], startIndex: number, endIndex: number): any[] => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

export default PackagesTab
