import * as React from "react"
import * as styles from "./styles.scss"
import { OrderEditorContext } from "./../../../OrderEditoryAdvert"
import { useContextSelector } from "use-context-selector"
import cx from "classnames"

const Loading: React.FC<{
  className?: string
}> = (props) => {
  const { previewIsUpdating } = useContextSelector(OrderEditorContext, (s) => {
    return {
      previewIsUpdating: s.previewIsUpdating,
    }
  })
  return (
    <div className={cx(styles.loader, props.className)}>
      {previewIsUpdating && (
        <div className={styles.innerLoader}>
          <label> ●</label>
          <label> ●</label>
          <label> ●</label>
          <label> ●</label>
          <label> ●</label>
          <label> ●</label>
        </div>
      )}
    </div>
  )
}

export default Loading
