import React from "react"
import * as styles from "./styles.scss"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faCheckCircle,
  faCopy,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons"
import { divide } from "lodash"
import ErrorMessage from "components/ErrorMessage"

const CopyValue: React.FC<{
  icon: any
  title: string
  CopyValue: any
  className?: any
  isTextDesc?: boolean
}> = (props) => {
  const [isCopied, setIsCopied] = React.useState(false)
  const [errorMsg, setErrorMsg] = React.useState<null | string>(null)

  React.useEffect(() => {
    if (isCopied) {
      setTimeout(() => {
        setIsCopied(false)
      }, 2000)
    }
    if (errorMsg !== null) {
      setTimeout(() => {
        setErrorMsg(null)
      }, 2000)
    }
  }, [isCopied, errorMsg])

  const handleCopyValue = async (html: any) => {
    const temDiv = document.createElement("div") as HTMLDivElement
    temDiv.innerHTML = html
    const pureValue = temDiv.textContent || temDiv.innerText || ""
    if (
      navigator !== undefined &&
      navigator !== null &&
      navigator.clipboard !== undefined &&
      navigator.clipboard !== null &&
      navigator.clipboard.writeText !== undefined &&
      navigator.clipboard.writeText !== null &&
      pureValue
    ) {
      return navigator.clipboard
        .writeText(pureValue)
        .then((res) => {
          setIsCopied(true)
        })
        .catch((err) => {
          setIsCopied(false)
        })
    } else {
      setErrorMsg("Kopiering Misslyckades")
    }
  }

  return (
    <div className={props.className ? props.className : styles.copyWrapper}>
      {isCopied ? (
        <div className={styles.msg}>
          <FontAwesomeIcon icon={faCheckCircle} />
          {props.isTextDesc && (<span>Kopierad</span>)}
        </div>
      ) : (
        <div
          className={styles.copyBtn}
          onClick={() => handleCopyValue(props.CopyValue)}
        >
          {errorMsg === null ? (
            <>
              <FontAwesomeIcon icon={props.icon} />
              <span>{props.title}</span>
            </>
          ) : (
            <>
              <FontAwesomeIcon icon={faTimesCircle} style={{ color: "red" }} />
              <span style={{ color: "red" }}>{errorMsg}</span>
            </>
          )}
        </div>
      )}
    </div>
  )
}

export default CopyValue
