import * as React from "react"
import { EditModelAdvertAreaContentEditorBehaviours } from "schema"
import {  Caret, clean, getCaret, setCaret } from "../htmlEditorClean"
// import * as $ from 'jquery';
// import {equal} from 'fast-deep-equal';

export interface ISelection {
  anchorNode : Node | null
  anchorOffset : number | null
  range : Range
}
export interface IState {
  isFixed: boolean
}

export class HtmlEditor extends React.Component<Iprops,IState> {
  private lastHtml: string
  private htmlEl: HTMLElement | null = null
  private styleEl: Element | null = null
  private selection : ISelection | null = null

  // tslint:disable-next-line: member-ordering
  constructor(props: Iprops) {
    super(props)
    this.emitChange = this.emitChange.bind(this)
    this.onFocus = this.onFocus.bind(this)
    this.lastHtml = props.html
    this.handleToggleIsFixed = this.handleToggleIsFixed.bind(this)
    this.state = {
      isFixed: false,
    }
  }

  shouldComponentUpdate(nextProps: Iprops): boolean {
    const { props, htmlEl } = this

    // We need not rerender if the change of props simply reflects the user's edits.
    // Rerendering in this case would make the cursor/caret jump

    // Rerender if there is no element yet... (somehow?)

    let result=false;
    let resultReason = "";

    if (!htmlEl) {
      result = true
      resultReason ="No htmlEl"
    } else {
      if (
        this.cleanCompare(nextProps.html) !==
        this.cleanCompare(this.getXmlValue())
      ) {
        result = true
        resultReason =`Html changes prop=${this.normalizeHtml(nextProps.html)}  <=>  editor=${this.normalizeHtml(this.getXmlValue())}`
      } else {
        result = (
          props.disabled !== nextProps.disabled ||
          props.tagName !== nextProps.tagName ||
          props.className !== nextProps.className
          // props.countStepBack > nextProps.countStepBack
        )
        if(result)
          resultReason ="Other properties changes"
      }
    }


    // Handle additional properties
    // if(debug) console.log(`htmlEdit shouldComponentUpdate change ${result}:${resultReason}`)

    return result;
  }

  public getXmlValue(): string {
    if (this.htmlEl) {
      const xmlSerializer = new XMLSerializer()
      const outerHtml = xmlSerializer.serializeToString(this.htmlEl)

      const parser = new DOMParser()

      const node = parser.parseFromString(outerHtml, "text/xml")

      let resultXml = ""
      const childNodes = node.childNodes[0].childNodes

      for (const i in childNodes) {
        if (childNodes.hasOwnProperty(i)) {
          const xmlNode = xmlSerializer
            .serializeToString(childNodes[i])
            .replace(/( |)xmlns="[^"]*"/gi, "")
          resultXml += xmlNode
        }
      }
      return resultXml
    }
    return ""
  }

  // Handle changes
  public emitChange(originalEvt: React.SyntheticEvent<any> | null, caret : Caret | null=null) {
    const debug=true;
    clean(this.htmlEl as HTMLObjectElement, this.getSelection(), caret)
    if (!this.htmlEl) {
      return
    }
    const html = this.getXmlValue()
    if (this.props.onChange && html !== this.lastHtml) {
      // Changes has been done since last time.
      const evt = Object.assign({}, originalEvt, {
        target: {
          value: html,
        },
      })
      // console.log(evt.target);

      this.lastHtml = html
      this.updateRowStyles()
      // if(debug) console.log("emit changes " + html)
      this.props.onChange(evt)
    }

    //this.saveSelection()
  }

  public normalizeHtml(str: string): string {
    return str && str.replace(/&nbsp;|\u202F|\u00A0/g, " ")
  }

  public cleanCompare(str:string):string {
    str = this.normalizeHtml(str) ?? ""
    str = str.replace(/\sxml\:preserve\=\"whitespace\"/gi,"")
    str = str.replace(/\sxml\:space\=\"preserve\"/gi,"")
    str = str.replace(/\sdata\-ptype\=\"[^"]*\"/gi, "")
    return str;
  }

  public clear() {
    var child = this.htmlEl?.firstChild;
    while(child != null) {
      var item = child;
      child = item.nextSibling;
      item.remove();
    }
      clean(this.htmlEl as HTMLObjectElement, this.getSelection())
  }

  public onFocus(originalEvt: React.SyntheticEvent<any> | null) {
    let columns = this.props.rowItems
    let isDemo = this.props.isValueDemo

    if (isDemo) {
      if (columns[0].item.isDemoValue) {
        this.clear()
      }
    }
    this.selectLast();
    return false
  }

  handleToggleIsFixed() {
    this.setState({ isFixed: !this.state.isFixed })
  }

  createFixButton() {
    const { TEXT_HTML_RELATIVES } = EditModelAdvertAreaContentEditorBehaviours
    const LOCK = "&#128274;"
    const UNLOCK = "&#128275;"
    if (this.props.editorBehaviour != TEXT_HTML_RELATIVES) return
    const buttonElement = document.createElement("button")
    buttonElement.innerHTML = this.state.isFixed ? LOCK : UNLOCK
    buttonElement.setAttribute("class", "fixedButton")
    if(!this.props.isFEUser) {
      buttonElement.classList.add("invisible")
    }
    buttonElement.addEventListener("click", () => {
      this.handleToggleIsFixed()
      buttonElement.classList.toggle("isActive", this.state.isFixed)
      buttonElement.innerHTML = !this.state.isFixed ? UNLOCK : LOCK
      buttonElement.parentElement?.classList.toggle("fixed", this.state.isFixed)
    })
    this.styleEl?.appendChild(buttonElement)
  }

  createArrowUp(index1:number, index2:number) {
    const { TEXT_HTML_RELATIVES } = EditModelAdvertAreaContentEditorBehaviours
    if (this.props.editorBehaviour != TEXT_HTML_RELATIVES) return
    const ARROW_UP = "&#8593;"
    const row1 = this.htmlEl!.childNodes[index1 - 1]
    const row2 = this.htmlEl!.childNodes[index2]
   
    const arrowContainer = document.createElement("div")
    arrowContainer.setAttribute("class", "arrowContainer")

    const arrowUp = document.createElement("span")
    arrowUp.innerHTML = ARROW_UP
    arrowUp.addEventListener("click", () => {
      row2.parentElement?.insertBefore(row2, row1)
      this.updateRowStyles()
      this.emitChange(null, null)
    })
    arrowContainer.appendChild(arrowUp)

    this.styleEl?.append(arrowContainer)
  }


  public updateRowStyles() {
    while (this.styleEl && this.styleEl.firstChild) {
      this.styleEl.removeChild(this.styleEl.firstChild)
    }
    if(Object.keys(this.props.pStyles).length > 0) 
    this.styleEl?.setAttribute("class",`pStyleEdit options  ${this.state.isFixed ? "fixed" : ""}`)

    if (this.htmlEl && this.styleEl) {
      this.createFixButton()
      for (const i in this.htmlEl.childNodes) {
        if (this.htmlEl.childNodes.hasOwnProperty(i)) {
          this.createArrowUp(Number(i),Number(i))
          const pElement = this.htmlEl.childNodes[i] as HTMLElement
          if (pElement.nodeName === "P") {
            pElement.parentElement!.setAttribute("class", this.props.editorBehaviour === "TEXT_HTML_VERSE" ? "htmlEditorVerse" : "htmlEditor")
            pElement.parentElement!.setAttribute("data-testid", this.props.editorBehaviour + "_htmlEditor",)
            const stylesDiv = document.createElement("div")
            stylesDiv.setAttribute("class", "divSelect" );
            stylesDiv.setAttribute(
              "style",
              "position:absolute;left:0px; top:" +
                pElement.offsetTop +
                "px; height:" +
                pElement.offsetHeight +
                "px; "+
                
                `${this.props.disabled ? "display : none" : "display: block"}`
            )
            this.styleEl.appendChild(stylesDiv)

            const pStyle = pElement.getAttribute("class")
            const pStyleSetting = this.props.pStyles[
              (pElement && pElement.getAttribute("class")) || ""
            ]

            if (Object.getOwnPropertyNames(this.props.pStyles).length !== 0) {
              //this.styleEl.setAttribute("style", "width:150px")
              const selectElement = document.createElement(
                "select"
              ) as HTMLSelectElement
              stylesDiv.setAttribute("class", "divSelect " + pStyle)
              if (pStyle) selectElement.value = pStyle //  pStyleSetting && pStyleSetting.short
              selectElement.setAttribute(
                "style",
                "position:absolute;left:20px; top:0px; height:" +
                  pElement.offsetHeight +
                  "px; "
              )
              stylesDiv.appendChild(selectElement)

              stylesDiv.addEventListener("change", () => {
                pElement.setAttribute("class", selectElement.value)
                stylesDiv.setAttribute("class", "divSelect " + selectElement.value)
                this.emitChange(null)
              })

              let styleIndex: number = 0
              for (const pClass in this.props.pStyles) {
                if (this.props.pStyles.hasOwnProperty(pClass)) {
                  const pStyleItemSetting = this.props.pStyles[pClass]
                  const pStyleItemName = pStyleItemSetting
                    ? pStyleItemSetting.name
                    : ""

                  const styleOption = document.createElement(
                    "option"
                  ) as HTMLOptionElement
                  styleOption.setAttribute("style", "")
                  styleOption.value = pClass
                  styleOption.selected = pClass === pStyle
                  styleOption.setAttribute("class", pClass)
                  styleOption.setAttribute("title", pStyleItemName)
                  styleOption.innerText = pStyleItemName
                  selectElement.appendChild(styleOption)

                  styleIndex++
                }
              }
            }
          }
        }
      }
    }
  }

  public initEditor() {
    clean(this.htmlEl as HTMLObjectElement, this.getSelection())
    this.lastHtml = this.getXmlValue()
  }

  public getSelection(force:boolean = false):ISelection|null {
    const selection = this.getSelectionFromWindow(force)
    if(selection != null) {
      this.selection = selection;
      return selection;
    }
    return this.selection;
  }

  private getSelectionFromWindow(force:boolean = false): ISelection|null {
    const selection = window.getSelection()
    let anchorNode = selection?.anchorNode
    let anchorOffset = selection?.anchorOffset ?? 0;
    if(anchorNode != undefined && selection != undefined && (force || (anchorNode != this.htmlEl && this.htmlEl?.contains(anchorNode)))) {
      // console.debug("getSelectionFromWindow windowSelection", anchorNode, anchorOffset)
      if(selection.anchorNode?.nodeType != Node.TEXT_NODE) {
        // test if not textnod exists. 
        if(anchorOffset > 0) {
          // Find textnode. Needed for Firefox
          let currNode = anchorNode.childNodes[anchorOffset-1] as Node;
          while(currNode != null && currNode?.nodeType != Node.TEXT_NODE && currNode.nodeName != "BR") {
            currNode = currNode?.firstChild as Node;
          }
          if(currNode != null) {
            anchorNode = currNode;
            anchorOffset = anchorNode.textContent?.length ?? 0
          }
        } else {
          // if((anchorNode.firstChild as Node).nodeName == "BR") {
          //   anchorNode = anchorNode.firstChild;
          //   anchorOffset = 0;
          // }
        }
        // console.debug("getSelectionFromWindow windowSelection new", anchorNode, anchorOffset)
      }
      // const range = document.createRange();
      // if(anchorNode != null) {
      //   range.setStart(anchorNode, anchorOffset);
      //   range.setEnd(anchorNode, anchorOffset);
      //   range.collapse(true);
      // }

      return {
        anchorNode : anchorNode, 
        anchorOffset : anchorOffset,
        range : selection.getRangeAt(0),
      }        
    }
    return null;
  }

  public saveSelection():void {
    const selection = this.getSelectionFromWindow();
    if(selection != undefined) {
      // console.log("saveSelection saved")
      this.selection = selection
    } else {
      // console.log("saveSelection notSaved")
    }
  }

  selectAll():void {
    const selection = this.getSelection()
    if(selection == null) return
    if (!selection) return
    const range = selection.range
    range.selectNodeContents(this.htmlEl as Node)
    const winSelection = window.getSelection()
    if (winSelection) {
      winSelection.removeAllRanges()
      winSelection.addRange(range);
    } 
  }

  selectLast():void {
    const selection = this.getSelection()
    if(selection == null) return
    if (!selection) return
    const range = selection.range
    range.selectNodeContents(this.htmlEl as Node)    
    const winSelection = window.getSelection()
    if (winSelection) {
      winSelection.removeAllRanges()
      winSelection.addRange(range);
      winSelection.collapseToEnd();
    } 

  }

  pasteContent(content: string, replaceAll:boolean): void {
    if(!this.htmlEl) return;

    this.htmlEl.focus()
      let selection = this.getSelection()

      if(selection == null) return
      if (!selection) return

      if(replaceAll) this.selectAll()

      if (document.queryCommandSupported('insertText')) {
        document.execCommand('insertHTML', false, content);
      } else {
        document.execCommand('paste', false, content);
      } 

      /*
      const range = selection.range
      range.deleteContents()
      const insertFragement = range.createContextualFragment(content)      
      range.insertNode(insertFragement)

      // Maybe unnessisary
      if(insertFragement.lastChild) 
        range.selectNodeContents(insertFragement.lastChild) 
      range.collapse(false);
 

      const winSelection = window.getSelection();
      if(winSelection) {
        winSelection.removeAllRanges();
        winSelection.addRange(range);
      }
           */
 
      this.emitChange(null, null)
  }  

  public render() {
    const { tagName, html } = this.props

    const editableDiv = React.createElement(
      tagName || "div",
      {
        key: "editable",
        className: "htmlEditor",
        contentEditable: !this.props.disabled,
        dangerouslySetInnerHTML: { __html: html },
        // onBlur: this.props.onBlur || this.emitChange,
        onInput: this.emitChange,
        onFocus: this.onFocus,
        disabled: true,
        spellCheck: true,
        
      },
      null
    )

    const styleDiv = React.createElement(
      "div",
      {
        key: "stylePanel",
        className: "pStyleEdit",
        style: {
         
        },
      },
      null
    )

    const mainDiv = React.createElement(
      "div",
      {
        
        key: "htmlEditor",
        className: "htmlEditorTaps",
        style: { position: "relative" , color: this.props.disabled ? "gray" : "black"},
        onChange: () => {
          // console.log("OnChange")
        },
        ref: (e) => {
          if (e) {
            const isNew = this.htmlEl != (e as HTMLElement).children[0];
            this.htmlEl = ((e as HTMLElement).children[0] as HTMLElement)
            this.styleEl = (e as HTMLElement).children[1]
            if(isNew) {
              this.initEditor()
  
              // Listen to selection-change
              document.addEventListener('selectionchange', () => {
                this.saveSelection();
              });

 
              let range = document.createRange();
              range.setStart(this.htmlEl, 0);
              range.setEnd(this.htmlEl, 0);
              this.selection = {
                anchorNode : (e as HTMLElement),
                anchorOffset: 0,
                range : range
              }

              this.htmlEl.setAttribute("spellcheck","false")

              this.htmlEl.addEventListener("paste", e => {
                // cancel paste
                e.preventDefault();
            
                // get text representation of clipboard
                var text = ((e as any).originalEvent || e).clipboardData.getData('text/plain') as string;

                var encodeHTMLEntities = (text:string):string => {
                  let textArea = document.createElement('textarea');
                  textArea.innerText = text;
                  let encodedOutput=textArea.innerHTML;
                  return encodedOutput;
                }

                var html = text.split(/\r\n\r\n|\r\r|\n\n/).map(l => "<p>" + l.split(/\r\n|\r|\n/).map((t,i,a)=> (i==0 ? "":"<br />") + encodeHTMLEntities(t)).join("") + "</p>").join("");
                
                if (document.queryCommandSupported('insertText')) {
                  document.execCommand('insertHTML', false, html);
                } else {
                  document.execCommand('paste', false, text);
                } 
                           
              });

            }
            this.updateRowStyles()
          }
        },
      },
      [editableDiv, styleDiv]
    )


    return mainDiv
  }
}

export interface Iprops {
  disabled?: boolean
  className?: string
  html: string
  onBlur?: (event: React.SyntheticEvent<any>) => void
  onChange?: (event: React.SyntheticEvent<any>) => void
  // onOpenSpecialCharacters?: () => void
  style?: any
  tagName?: string
  pStyles: { [key: string]: { name: string; short: string } }
  // countStepBack: number
  isValueDemo?: boolean
  rowItems?: any
  editorBehaviour: EditModelAdvertAreaContentEditorBehaviours
  isFEUser: boolean
}
