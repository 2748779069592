import * as React from "react"
import * as styles from "./styles.scss"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import AddOrderButton from "components/AddOrderButton"
import {
  // GetOrderInitiatorRootNode_orderInitiatorRootNode_children,
  GetPackages_orderInitiatorPackagesByCurrentUser,
} from "schema"
import { useTranslation } from "react-i18next"
import { omitTypename } from "utils"

const AddProductButton: React.FC<{
  onClick: any
  // package: GetPackages_orderInitiatorPackagesByCurrentUser
  // onUpdatePackage: (packageInput: any) => Promise<any>
}> = (props) => {
  const [t] = useTranslation(["common"])

  // const handleAddOrderToPackage = (
  //   node: GetOrderInitiatorRootNode_orderInitiatorRootNode_children
  // ) => {
  //   return props.onUpdatePackage({
  //     ...props.package,
  //     items: [
  //       ...props.package.items!,
  //       omitTypename({ inputId: node.orderInitiator!.inputId }),
  //     ],
  //   })
  // }

  // const renderButtonHtml = () => (
  //   <div className={styles.addProductButton}>
  //     <FontAwesomeIcon icon="plus-circle" /> <span>{t("addProduct")}</span>
  //   </div>
  // )


  return (
    <AddOrderButton
      // renderHtml={renderButtonHtml}
      onClick={props.onClick}
      // onAddOrder={handleAddOrderToPackage}
      className={styles.addProductButtonContainer}
    >
      <div className={styles.addProductButton}>
        <FontAwesomeIcon icon="plus-circle" /> <span>{t("add")}</span>
      </div>
    </AddOrderButton>
  )
}

export default AddProductButton
