import * as React from "react"
import * as styles from "./styles.scss"
import * as cx from "classnames"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { GetGalleryImages_galleryImagesByTags_images } from "schema"
import { MutationFn } from "react-apollo"
import { Custom_GetGalleryImages_galleryImagesByTags_images } from "customSchema.interface"

class Symbol extends React.PureComponent<SymbolProps> {
  handleFavoriteIconClick = (e: React.MouseEvent) => {
    e.stopPropagation()
    if (this.props.isFavorite) this.props.onTagDelete()
    else {
      this.props.symbol.isSelected = true
      this.props.onOpenAddToFavoritesModal(this.props.symbol)
    }
  }

  handleDoubleClick = () =>
    this.props.onSave(this.props.symbol.id!, this.props.symbol)

  render() {
    const { isActive, symbol, isFavorite, onClick, isFEUser } = this.props
    return (
      <div
        id={"symbolContainer"}
        className={cx(styles.symbolContainer, {
          [styles.active]: isActive,
          [styles.favorite]: isFavorite,
          [styles.isSelected]: symbol.isSelected,
          [styles.isRecentSelected]: symbol.tagGroups.length > 0,
          [styles.feView__symbolContainer]: isFEUser,
        })}
        onClick={onClick}
        onDoubleClick={this.handleDoubleClick}
        title={symbol.description}
      >
        <div className={styles.iconRow}>
          {this.props.isSymbolGallery && (
            <div onClick={this.handleFavoriteIconClick}>
              <FontAwesomeIcon icon="star" className={styles.starIcon} />
              <FontAwesomeIcon icon="minus" className={styles.minusIcon} />
            </div>
          )}
        </div>
        <img
          src={symbol.thumbnail100x80Url}
          alt=""
          className={cx(styles.symbol, {
            [styles.feView__symbol]: isFEUser,
          })}
        />
        <p>{symbol.publicId}</p>
      </div>
    )
  }
}

interface SymbolProps {
  isActive: boolean
  symbol: Custom_GetGalleryImages_galleryImagesByTags_images
  isFavorite: boolean
  onTagDelete: MutationFn
  onClick: () => void
  onSave: (id: string, symbol?: any) => void
  onOpenAddToFavoritesModal: (
    symbol: GetGalleryImages_galleryImagesByTags_images
  ) => void
  isSymbolGallery: boolean
  isFEUser: boolean
}

export default Symbol
