import gql from "graphql-tag"

const Get_Suggest_Document_Formats = gql`
  query GetSuggestionDocumentFormats($id: ID!) {
    orders(ids: [$id]) {
      id
      mediaOrder {
        suggestionDocumentFormats {
          id
          name:friendlyName(isShownWithMedia:false)
          previewUrl
          thumbnailWidth
          thumbnailHeight
        }
      }
    }
  }
`
export default Get_Suggest_Document_Formats
