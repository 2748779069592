import * as React from "react"
import * as styles from "./styles.scss"
import ZoomDropdown from "./ZoomDropdown"
import FormatDropdownContainer from "./FormatDropdownContainer"
import cx from "classnames"
import { DropdownOption } from "components/DropdownButton"
import UndoButton from "./UndoButton"
import Hamburger from "./Hamburger"
import LockerBox from "components/LockerBox"
import {
  GetOrderById_orders_mediaOrder_documentFormat_documentFormatCollection,
  GetOrderById_orders_mediaOrder_edit_userInterface,
} from "schema"
import SuggestButton from "./SuggestButton"

const Toolbar: React.FC<ToolbarProps> = (props) => {

  return (
    <div className={styles.toolbar}>
      <div className={styles.toolbarTogglerWrapper}>
        {!props.isFEUser && (
          <Hamburger
            isVisible={props.isVisible}
            onClick={props.onToggleVisibility}
            userInterface={props.userInterface}
          />
        )}
      </div>
      <div className="boxContainer">
        {props.userInterface?.editStatusText && (
          <LockerBox
            text={props.userInterface?.editStatusText}
            className={"adLockerBox"}
            icon={props.userInterface.editStatusIcon}
            color={props.userInterface.editStatusColor}
          />
        )}
      </div>
      <div
        className={cx(styles.toggleableRow, {
          [styles.visible]: props.isVisible,
        })}
      >
        <div
          className={cx(styles.buttons, {
            [styles.zoomFirst]: props.isLocked,
          })}
        >
          {!props.isLocked && <UndoButton isFEUser={props.isFEUser} />}

          <ZoomDropdown
            onSetPreviewZoom={props.onSetPreviewZoom}
            previewZoom={props.previewZoom}
            localZoom={props.localZoom && props.localZoom}
            isFEUser={props.isFEUser!}
          />
          {!props.isFEUser && (
            <React.Fragment>
              {!props.isLocked &&
                (props.selectedDocumentFormat ? (
                  <FormatDropdownContainer
                    selectedDocumentFormat={props.selectedDocumentFormat}
                    togglePreviewIsUpdatingOn={props.togglePreviewIsUpdatingOn}
                    togglePreviewIsUpdatingOff={
                      props.togglePreviewIsUpdatingOff
                    }
                    selectedOrderId={props.selectedOrderId}
                    formatCollection={props.formatCollection}
                    selectedDocumentFormatId={props.selectedDocumentFormatId}
                  />
                ) : null)}
              {!props.isLocked && (
                <SuggestButton orderId={props.selectedOrderId} />
              )}
            </React.Fragment>
          )}
        </div>
      </div>
    </div>
  )
}

interface ToolbarProps {
  isVisible: boolean
  onToggleVisibility: () => void
  onSetPreviewZoom: (option: DropdownOption) => void
  previewZoom: DropdownOption
  selectedDocumentFormat?: string
  isLocked: boolean
  selectedCaseFolderId: string
  togglePreviewIsUpdatingOn: any
  togglePreviewIsUpdatingOff: any
  selectedOrderId: string
  userInterface: GetOrderById_orders_mediaOrder_edit_userInterface | undefined
  localZoom: { lable: string; value: string | number } | any
  formatCollection: GetOrderById_orders_mediaOrder_documentFormat_documentFormatCollection
  selectedDocumentFormatId: string | undefined
  isFEUser: boolean
}

export default Toolbar
