import * as React from "react"
import UPDATE_ALL_ORDERS from "graphql/mutations/updateAllOrders"
import { OrderEditorContext } from "../../.."
import UpdateAllFieldsButton from "../UpdateAllFeldsButton"
import { useTranslation } from "react-i18next"
import { UpdateAllOrders, UpdateAllOrdersVariables } from "schema"
import { useContextSelector } from "use-context-selector"
import { useApolloClient, useMutation } from "react-apollo-hooks"
import { ErrorMessage } from "formik"
import { ErrorText } from "components/ErrorMessage"

const UpdateAllContainer: React.FC<{
  isEnabledUpdate: any
}> = (props) => {
  const { t } = useTranslation(["editOrder"])

  const { isLocked, orderId } = useContextSelector(OrderEditorContext, (s) => {
    return {
      isLocked: s.isLocked,
      orderId: s.selectedOrder.id ?? "",
    }
  })

  const [error, setError] = React.useState(undefined)
  const client = useApolloClient()
  const updateAllOrders = useMutation<
    UpdateAllOrders,
    UpdateAllOrdersVariables
  >(UPDATE_ALL_ORDERS, {
    client,
    variables: {
      input: {
        orderId,
      },
    },
    context: {

    },
  })

  const onClick = () => {
    setError(undefined)
    return updateAllOrders().catch((err) => {
      setError(err.toString())
    })
  }

  if (props.isEnabledUpdate === false) {
    return <React.Fragment />
  }

  return (
    <React.Fragment>
      <UpdateAllFieldsButton
        onClick={onClick}
        text={t("updateAllOrders")}
        disabled={isLocked}
        type="primary"
        isTooltipActive={false}
        isAreaInner={false}
      />
      {error ? <ErrorText>{error}</ErrorText> : null}
    </React.Fragment>
  )
}

export default UpdateAllContainer
