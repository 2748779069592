import * as React from "react"
import CaseFolderForm from "components/CaseFolderForm"
import { Mutation } from "react-apollo"
import CREATE_CASE_FOLDER from "graphql/mutations/createCaseFolder"
import GET_DASHBOARD_CASE_FOLDERS from "graphql/queries/getDashboardCaseFolders"
import { DataProxy } from "apollo-cache"
import CaseFolderHeaderRow from "../CaseFolderHeaderRow"
import SubmitButton from "./SubmitButton"
import { withRouter, RouteComponentProps, useHistory } from "react-router-dom"
import { CreateCaseFolder, CreateCaseFolderVariables } from "schema"
import Whiteframe from "components/Whiteframe"
import { useApolloClient, useMutation } from "react-apollo-hooks"



const updateCache = (cache: DataProxy, { data }: any) => {
  const { findCaseFoldersByCurrentCustomer }: any = cache.readQuery({
    query: GET_DASHBOARD_CASE_FOLDERS,
  })

  cache.writeQuery({
    query: GET_DASHBOARD_CASE_FOLDERS,
    data: {
      findCaseFoldersByCurrentCustomer: {
        ...findCaseFoldersByCurrentCustomer,
        caseFolders: findCaseFoldersByCurrentCustomer.caseFolders.concat([
          data.createCaseFolder.caseFolder,
        ]),
      },
    },
  })
}

const CreateCaseFolderForm: React.FC<{
  isActive: boolean
  onComplete: (args: any) => void
}> = (props) => {

  const [selectedCaseFolder, setselectedCaseFolder] = React.useState(undefined)
  const client = useApolloClient()


  const runCreateCaseFolder = useMutation<
    CreateCaseFolder,
    CreateCaseFolderVariables
  >(CREATE_CASE_FOLDER, {
    client,
    update: updateCache,
  })

  const onComplete = (result: any) => {
    setselectedCaseFolder(result.data.createCaseFolder.caseFolder)
    if (props.onComplete) {
      props.onComplete(result.data.createCaseFolder.caseFolder.id)
    }
  }

  return (
    <Whiteframe>
      <CaseFolderForm
        columnCount={4}
        renderHeaderRow={CaseFolderHeaderRow}
        isActive={props.isActive}
        selectedCaseFolder={selectedCaseFolder}
        onSubmit={runCreateCaseFolder as any}
        onComplete={onComplete}
      />
    </Whiteframe>
  )
}

export default CreateCaseFolderForm

// export default withRouter(CreateCaseFolderForm)
// class CreateCaseFolderForm extends React.Component<
//   RouteComponentProps & CreateCaseFolderFormProps
// > {
//   handleCompleted = (result: CreateCaseFolder) => {
//     this.props.onToggleMutationInProgressOff()
//     this.props.history.push(
//       `/newCaseFolder/c/${result.createCaseFolder.caseFolder.id}`
//     )
//   }

//   renderBottomRightSubmitButton = () => (
//     <SubmitButton mutationInProgress={this.props.mutationInProgress} />
//   )

//   render() {
//     return (
//       <Mutation<CreateCaseFolder>
//         mutation={CREATE_CASE_FOLDER}
//         update={updateCache}
//         onCompleted={this.handleCompleted}
//       >
//         {createCaseFolder => (
//           <Whiteframe>
//             <CaseFolderForm
//               renderHeaderRow={CaseFolderHeaderRow}
//               columnCount={4}
//               onToggleLoadingOn={this.props.onToggleMutationInProgressOn}
//               renderBottomRightSubmitButton={this.renderBottomRightSubmitButton}
//               onSubmit={createCaseFolder}
//             />
//           </Whiteframe>
//         )}
//       </Mutation>
//     )
//   }
// }

// interface CreateCaseFolderFormProps {
//   isActive: boolean
//   mutationInProgress: boolean
//   onToggleMutationInProgressOn: () => void
//   onToggleMutationInProgressOff: () => void
// }