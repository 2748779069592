import * as React from "react"
import MainLayout from "layouts/MainLayout"
import BackgroundImage from "components/BackgroundImage"
import image from "images/image.png"
import CreateCaseFolderForm from "./CreateCaseFolderForm"
import {
  RouteComponentProps,
  useHistory,
  useParams,
  withRouter,
} from "react-router-dom"
import EditCaseFolderForm from "./EditCaseFolderForm"
import { useRouteMatch } from "react-router"


const NewCaseFolderPage: React.FC<{
  mutationInProgress: boolean
}> = (props) => {
  enum PageStates {
    addOrEditCase = "addOrEditCase",
    selectPackage = "selectPackage",
  }
  const history = useHistory()
  const { caseFolderId } = useParams<{ caseFolderId?: string }>()

  const [pageState, setPageState] = React.useState<PageStates>(
    caseFolderId === undefined
      ? PageStates.addOrEditCase
      : PageStates.selectPackage
  )
  const onCompleteSaveCase = (caseFolderId: any) => {
    history.replace(`/newCaseFolder/c/${caseFolderId}`)
    setPageState(PageStates.selectPackage)
  }
  const onBackFromSelectPackage = () => {
    setPageState(PageStates.addOrEditCase)
  }
  const onCompleteSelectPackage = () => {
    // setPageState(PageStates.addOrEditCase)
  }
  return (
    <MainLayout showUtilityBar={false}>
      <BackgroundImage src={image} />

      <CreateCaseFolderForm
        isActive={pageState === PageStates.addOrEditCase}
        onComplete={onCompleteSaveCase}
      />

      <EditCaseFolderForm
        isActive={pageState === PageStates.selectPackage}
        caseFolderId={caseFolderId ?? undefined}
        onBack={onBackFromSelectPackage}
        onComplete={onCompleteSelectPackage}
      />
    </MainLayout>
  )
}

export default NewCaseFolderPage
