import * as React from "react"
import * as styles from "./styles.scss"
import Grayframe from "components/Grayframe"
import Button from "components/Button"
import { WithTranslation, withTranslation } from "react-i18next"

class TotalPrice extends React.Component<TotalPriceProps & WithTranslation> {
  render() {
    const { price, onClick, t } = this.props
    
    
    return (
      <Grayframe className={styles.grayframe}>
        <span>
          <b>{t("total")}: </b>
          {price ? `${Math.round(price)}:-` : "N/A"}
        </span>
        <div>
          <Button
            variant="primary"
            color="primary"
            className={styles.sendButton}
            onClick={onClick}
          >
            {t("common:send")}
          </Button>
        </div>
      </Grayframe>
    )
  }
}

interface TotalPriceProps {
  price: number | null
  onClick: () => void
}

export default withTranslation(["review", "common"])(TotalPrice)
