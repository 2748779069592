import React from "react"
import styled from "styled-components"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import RangeSlider from "components/RangeSlider"
import cx from "classnames"

const RangeInput: React.FC<{
  icon?: any
  title?: string
  value: any
  step?: number
  max: number
  min: number
  onChange: (arg: number) => void
  isFEUser: boolean
}> = (props) => {
  return (
    <RangeContainer>
      <div className={cx("lvl1",{
        ["feView__lvl1"]: props.isFEUser
      })}>
        <FontAwesomeIcon icon={props.icon} />
        <span>{props.title}</span>
      </div>

      <RangeSlider
        min={props.min}
        max={props.max}
        value={props.value}
        onChange={props.onChange}
        className="slider"
        step={props.step}
      />
    </RangeContainer>
  )
}

export default RangeInput

const RangeContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    min-width: 400px;
    padding: 0 20px;
    .lvl1 {
        display:flex;
        align-items: center;
        column-gap: 5px;
        padding: 0 9px;
        span {
            font-family: Roboto;
            font-weight: bold;
            font-size: 15px
        }
        svg {
            color: ${(props) => props.theme["--secondary"]};
            font-size: 18px;
        }
      &.feView__lvl1 {
        svg {
            color: ${(props) => props.theme["--fe_secondary"]};
            font-size: 18px;
        }
      }
    }
    .slider {
        background-color: transparent;
        padding-left: 0;
    }
`
