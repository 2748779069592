import * as React from "react"
import * as styles from "./styles.scss"
import Modal from "components/Modal"

class EnlargeImageModal extends React.Component<EnlargeImageModalProps> {
  render() {
    const { isOpen, imageUrl, onClick, onRequestClose } = this.props

    return (
      <Modal
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        className={styles.enlargeModal}
      >
        <img
          src={imageUrl}
          alt=""
          className={styles.proofImageEnlarged}
          onClick={onClick}
        />
      </Modal>
    )
  }
}

interface EnlargeImageModalProps {
  isOpen: boolean
  imageUrl: string
  onClick: () => void
  onRequestClose: () => void
}

export default EnlargeImageModal
