import * as React from "react"
import * as styles from "./styles.scss"
import Spinner from "components/Spinner"

class ModalLoadingOverlay extends React.PureComponent<
  ModalLoadingOverlayProps
> {
  render() {
    const { text } = this.props

    return (
      <div className={styles.overlay}>
        <div>
          <Spinner />
        </div>
        {text && <span className={styles.loadingText}>{text}</span>}
      </div>
    )
  }
}

interface ModalLoadingOverlayProps {
  text?: string
}

export default ModalLoadingOverlay
