import React, { useContext } from "react"
import { PublishOrderContext } from "../.."
import { useTranslation } from "react-i18next"
import * as styles from "./styles.scss"

// interface IStyle {
//   cellStyle: any
// }

const AdvertHeight: React.FC = () => {
  const { publishOrder } = useContext(PublishOrderContext)
  const [t] = useTranslation('review')
  const orderHeight: any =
    publishOrder.order.mediaOrder?.preview.material.basePage.size?.height

  return (
    <div className={styles.heightCell}>
      <div><b>{t("height")}:</b></div>
      <div><span> {orderHeight ? orderHeight.toFixed(2) : "N/A"}</span>  mm</div>
    </div>
  )
}

export default AdvertHeight

// export const cellStyle = styled.div`
//   .cell {
//     display: flex;
//     align-items: center;
//     flex: 0.5;
//   }
//   .cell span {
//     opacity: 0.7;
//     margin-right: 2px;
//   }
//   .cell div {
//     font-weight: 600;
//   }
// `
