import * as loglevel from "loglevel"

const logger = loglevel.getLogger("client_tap5")

export const enableAll = () => {
  logger.enableAll()
}
export const disableAll = () => {
  logger.disableAll()
}
export const setLevel = (value: any) => {
  logger.setLevel(value)
}
export const getLevel = () => {
  return logger.getLevel()
}
export const trace = (...args: any) => logger.trace(...args)
export const log = (...args: any) => logger.debug(...args)
export const debug = (...args: any) => logger.debug(...args)
export const info = (...args: any) => logger.info(...args)
export const warn = (...args: any) => logger.warn(...args)
export const error = (...args: any) => logger.error(...args)
