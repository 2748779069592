import React from "react"
import * as styles from "../styles.scss"
import ReactModal from "components/Modal"
import ReactPlayer from "react-player"

interface IVideoDetails {
  url: string | null
  title: string | null
}

const VideoModal: React.FC<{
  inOpen: boolean
  onClose: () => void
  videoDetails: IVideoDetails
}> = (props) => {
  return (
    <ReactModal isOpen={props.inOpen} onRequestClose={props.onClose} className={styles.videoModalContainer} >
        <div className={styles.titleBlock}>
        <h4>{props.videoDetails.title}</h4>
        </div>
    
      <div>
        <ReactPlayer url={props.videoDetails.url || ""} width={"100%"} height={"35vh"} controls={true} />
      </div>
    </ReactModal>
  )
}

export default VideoModal
