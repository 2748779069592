import * as React from "react"
import AppRoutes from "routes/AppRoutes"
import SupportView from "components/SupportView"
import { AppContext } from "./App"
import { withApollo } from "react-apollo"
import { TFeatureFlag } from "components/AppContextDataProvider"

export const LoggedinProviders = withApollo(
  ({
    currentCustomer,
    currentUserSession,
    toggleShowSupportView,
    showSupportView,
    isShowModeShow,
    toggleShowModeView,
    featureFlags,
  }: {
    currentCustomer: any
    currentUserSession: any
    toggleShowSupportView: any
    showSupportView: boolean
    isShowModeShow: boolean
    toggleShowModeView: () => void
    featureFlags: TFeatureFlag
  }) => {
    const isSupportUser = currentUserSession!.user!.isSupportUser

    return (
      <AppContext.Provider
        value={{
          currentUserSession,
          currentCustomer: currentCustomer!,
          toggleShowSupportView,
          showSupportView,
          isShowModeShow,
          toggleShowModeView,
          featureFlags,
        }}
      >
        {isSupportUser ? <SupportView /> : <AppRoutes />}
      </AppContext.Provider>
    )
  }
)
