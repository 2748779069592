import React from "react"
import Modal from "components/Modal"
import styles from "./styles.scss"
import Button from "components/Button"
import { ReducerAction } from ".."
import { useTranslation } from "react-i18next"



const WarningModal:React.FC<{
  isOpen: boolean
  doClose: () => void
  onDispatch: React.Dispatch<ReducerAction>
  updateData: () => void
}> = (props) => {
  const [t] = useTranslation("editOrder")
  const handleClick = () => {
    props.onDispatch({ type: "toggleSelectAll" })
    props.doClose()
  }
  return (
    <Modal isOpen={props.isOpen} onRequestClose={props.doClose} isModalInModal={true}>
      <section className={styles.container}>
        <p>
        {t("notSelectedMessage")}      
         </p>
        <div className={styles.buttonWrapper}>
          <Button color={"primary"}  variant={"primary"} onClick={() => {
          props.updateData()
            props.doClose()
            }}>{t("sendAnyway")}</Button>
          {/* <Button color={"secondary"} variant={"primary"} onClick={handleClick}>{t("selectAll")}</Button> */}
          <Button color={"secondary"} variant={"primary"} onClick={props.doClose}>{t("close")}</Button>
        </div>
      </section>
    </Modal>
  )
}

export default WarningModal
