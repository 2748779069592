import * as React from "react"
import * as styles from "./styles.scss"
import { useTranslation, withTranslation, WithTranslation } from "react-i18next"
import { GetReviewCaseFolder_caseFolder_documents_orders_mediaOrder_preview_material_basePage_size } from "schema"

export const Size: React.FC<{
  size: GetReviewCaseFolder_caseFolder_documents_orders_mediaOrder_preview_material_basePage_size | null
}> = (props) => {
  const { t } = useTranslation()
  return (
    <div className={styles.size}>
      <b>{t("size")}</b>
      <SizeContent height={props.size?.height} width={props.size?.width} />
    </div>
  )
}

export const SizeContent: React.FC<{ width?: number; height?: number }> = (
  props
) => {
  return (
    <span>
      {props.height && props.width
        ? `${props.width.toFixed(1)}mm x ${props.height.toFixed(1)}mm`
        : "N/A"}
    </span>
  )
}

export default Size
