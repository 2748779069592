import gql from "graphql-tag"

const GET_PROOF_MEMORIZ_CANDLES_THEME = gql`
  mutation GetProofMemorizCandlesThemes($orderID: ID!) {
    getProofMemorizCandlesThemes(orderId: $orderID) {
      id
      name
      imageUrl
      description
    }
  }
`

export default GET_PROOF_MEMORIZ_CANDLES_THEME
