import gql from "graphql-tag"

const GET_ADMIN_ACTIONS = gql`
  query GetAdminActions($orderId: ID, $documentId: ID, $customerId: ID) {
    adminActions(
      orderId: $orderId
      documentId: $documentId
      customerId: $customerId
    ) {
      actionId
      name
      menuPath
      documentId
      parameters {
        id
        description
        validateRegEx
        value
      }
      confirmMessage
    }
  }
`

export default GET_ADMIN_ACTIONS
