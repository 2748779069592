import * as React from "react"
import { Switch, Redirect, Route } from "react-router-dom"
import DashboardPage from "pages/DashboardPage"

export const DashboardRoutesPaths = {
  DashboardWithCaseFolderAndInEditMode:
    "/dashboard/c/:caseFolderId/:inEditMode",
  DashboardWithCaseFolder: "/dashboard/c/:caseFolderId",
  Dashboard: "/dashboard",
  DashboardWithAction: "/dashboard/c/:caseFolderId/:action",
}

const DashboardRoutes: React.FC<{}> = () => {
  const {
    DashboardWithCaseFolderAndInEditMode,
    Dashboard,
    DashboardWithCaseFolder,
    DashboardWithAction,
  } = DashboardRoutesPaths
  return (
    <Switch>
      <Route
        path={DashboardWithCaseFolderAndInEditMode}
        component={DashboardPage}
      />
      <Route path={DashboardWithCaseFolder} component={DashboardPage} />
      <Route path={Dashboard} component={DashboardPage} exact={true} />
      <Route path={DashboardWithAction} component={DashboardPage} />
      <Redirect to={Dashboard} />
    </Switch>
  )
}

export default DashboardRoutes
