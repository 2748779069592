import * as React from "react"
import {
  GetPackages_orderInitiatorPackagesByCurrentUser_items,
  GetPackages_orderInitiatorPackagesByCurrentUser,
} from "schema"
import PackageMediaItem from "components/PackageMediaItem"
import ConfirmationModal from "components/ConfirmationModal"
import { useTranslation } from "react-i18next"
import { produce } from "immer"

function MediaItem(props: MediaItemProps) {
  const [t] = useTranslation("agencySettings")
  const [deleteModalIsOpen, setDeleteModalIsOpen] = React.useState(false)

  const removeOrderFromPackage = () => {
    const packages = produce(props.package, draft => {
      if (draft.items) {
        const index = draft.items.findIndex(
          e =>
            e.inputId.documentFormatInitiatorId ===
              props.item.inputId.documentFormatInitiatorId &&
            e.inputId.mediaId === props.item.inputId.mediaId
        )
        if (index !== -1) {
          draft.items.splice(index, 1)
        }
      }
    })

    props.onUpdatePackage(packages)
  }

  const openModal = () => setDeleteModalIsOpen(true)

  const closeModal = () => setDeleteModalIsOpen(false)

  const mediaItem = {
    name: props.item.media.friendlyName,
    documentType:
      props.item.documentFormat.documentType.name,
  }
  
  return (
    <React.Fragment>
      <PackageMediaItem item={mediaItem} onDeleteClick={openModal} />
      <ConfirmationModal
        onOKClick={removeOrderFromPackage}
        isOpen={deleteModalIsOpen}
        title={t("deletePackageMedia")}
        okButtonText={t("deletePackageMediaModalOkBtn")}
        text={t("deletePackageMediaModalText")}
        onRequestClose={closeModal}
      />
    </React.Fragment>
  )
}

interface MediaItemProps {
  item: GetPackages_orderInitiatorPackagesByCurrentUser_items
  package: GetPackages_orderInitiatorPackagesByCurrentUser
  onUpdatePackage: (packageInput: any) => Promise<any>
}

export default MediaItem
