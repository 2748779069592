import * as React from "react"
import { withRouter, RouteComponentProps } from "react-router-dom"
import { parse } from "query-string"
import { Mutation } from "react-apollo"
import LOGIN from "graphql/mutations/login"
import InnerMagicLinkAuthenticator from "./InnerMagicLinkAutenticator"
import { Login } from "schema"

class MagicLinkAuthenticator extends React.PureComponent<RouteComponentProps> {
  render() {
    const tempKey = parse(this.props.location.search).tempUser

    if (!tempKey) return null

    return (
      <Mutation<Login>
        mutation={LOGIN}
        variables={{ userCredentialInput: { tempKey } }}
      >
        {login => (
          <InnerMagicLinkAuthenticator
            redirectTo={this.props.location.pathname}
            onLogin={login}
          />
        )}
      </Mutation>
    )
  }
}

export default withRouter(MagicLinkAuthenticator)
