import gql from "graphql-tag"

const DELETE_ORDER = gql`
  mutation DeleteOrder($input: MutationDeleteOrderInput!) {
      deleteOrder(input: $input) {
        isError
        errorReason
          orders {
              id
              isDeleted
          }
      }
  }
`

export default DELETE_ORDER
