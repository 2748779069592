import * as download from "downloadjs"

export const notEmpty = <TValue>(
  value: TValue | null | undefined
): value is TValue => value !== null && value !== undefined

export const omitProps = (object: object, propNames: string[]) =>
  JSON.parse(JSON.stringify(object), (key: string, value: any) =>
    propNames.indexOf(key) !== -1 ? undefined : value
  )

export const omitTypename = (object: object) =>
  omitProps(object, ["__typename"])

export const groupBy = (array: any[], props: string[]) =>
  array.reduce((groups, item) => {
    const val = props.reduce((prev, curr) => prev[curr], item)
    groups[val] = groups[val] || []
    groups[val].push(item)
    return groups
  }, {})

const downloadFile = (url: string, filename: string, type: string): void => {
  const req = new XMLHttpRequest()
  req.open("GET", url, true)
  req.responseType = "blob"
  req.onload = () => {
    const blob = new Blob([req.response], { type })
    download(blob, filename, type)
  }
  req.send()
}

export const fetchDownloadPng = (url:string, filename: string):void => {
  fetch(url)
  .then((response) => response.blob())
  .then((blob) => {
    const objectURL = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = objectURL;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
  })
  .catch(error => console.error('Error downloading image:', error));
}

export const downloadPdf = (url: string, filename: string): void =>
  downloadFile(url, filename, "application/pdf")

export const downloadPng = (url: string, filename: string): void =>
  downloadFile(url, filename, "image/png")

export const pluralize = (
  count: number,
  singular: string,
  plural: string
): string => (count === 1 ? singular : plural)

export const chunk = (array: any[], chunkCount: number): any[] =>
  array.reduce((resultArray, item, index) => {
    const chunkIndex = Math.floor(index / chunkCount)

    if (!resultArray[chunkIndex]) resultArray[chunkIndex] = []

    resultArray[chunkIndex].push(item)

    return resultArray
  }, [])

export const getInfoFromUrl = (path: string): UrlInfo | null => {
  const caseFolderIdAndOrderIdRegex = /.*c\/(.*)(.*?\/o\/)(.*)/
  const caseFolderIdRegex = /.*c\/(.*)/
  let result = path.match(caseFolderIdAndOrderIdRegex)

  if (!result) result = path.match(caseFolderIdRegex)
  return result
    ? result.length === 4
      ? { caseFolderId: result[1], orderId: result[3] }
      : { caseFolderId: result[1] }
    : null
}

export const containsOnlyWhitespace = (input: string): boolean =>
  !input.replace(/\s/g, "").length

export const blurOnEnter = (e: React.KeyboardEvent) => {
  if (e.keyCode === 13) (e.target as HTMLElement).blur()
}

export const copyToClipboard = (
  element: string | React.RefObject<HTMLInputElement>,
  callback?: () => void
) => {
  if (typeof element === "string") {
    const copyText = document.getElementById(element)
    if (!copyText) return console.error("No element found to copy text.")
    const textArea = document.createElement("textarea")
    textArea.value = copyText.textContent ? copyText.textContent : ""
    document.body.appendChild(textArea)
    textArea.select()
    document.execCommand("copy")
    textArea.remove()
  } else if (element.current) {
    element.current.select()
    document.execCommand("copy")
  }

  if (callback) callback()
}


export const getImage = (url: string) => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      const img = new Image()
      img.onload = () => resolve(img)
      img.onerror = (err) => reject(err)
      img.src = url
    }, 500)
  })
}


interface UrlInfo {
  caseFolderId: string
  orderId?: string
}
