import gql from "graphql-tag"

const GET_AVAILIABLE_DOCUMENT_FORMATS = gql`
  query GetAvaiableDocumentFormats(
    $orderId: ID!
    $documentFormatCollectionId: ID
  ) {
    orders(ids: [$orderId]) {
      id
      mediaOrder {
        documentFormat {
          id
          availableStyleTemplates2 {
            id
            name
            color
          }
          documentFormatCollection {
            id
            name
          }
        }
        availableDocumentFormats(
          documentFormatCollectionId: $documentFormatCollectionId
        ) {
          id
          name:friendlyName(isShownWithMedia:false)
          thumbnailUrl
          thumbnailWidth
          thumbnailHeight
          availableStyleTemplates2 {
            id
            color
            name
          }
        }
      }
    }
  }
`

export default GET_AVAILIABLE_DOCUMENT_FORMATS
