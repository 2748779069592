import * as React from "react"
import * as styles from "./styles.scss"
import Tabs from "./Tabs"
import cx from "classnames"
import NavigationButton from "./NavigationButton"
import {
  GetEditOrderPageCaseFolder_caseFolder_editTabs_orders_alerting,
  GetEditOrderPageCaseFolder_caseFolder_editTabs_orders_mediaOrder_status,
  StatusColors,
} from "schema"
import { produce } from "immer"

const SelectionSlider: React.FC<SelectionSliderProps> = (props) => {
  const STEP_SIZE = 300
  const slider = React.useRef<HTMLDivElement | null>(null)
  const prevCount = usePrevious(props.selectedDocumentId)
  const [state, setState] = React.useState<SelectionSliderState>({
    distanceScrolled: 0,
    maxScrollDistance: 0,
    showNavButtons: false,
  })

  const scrollLeft = () => {
    const newDistanceScrolled = state.distanceScrolled - STEP_SIZE
    setState(
      produce(state, (draft) => {
        draft.distanceScrolled =
          newDistanceScrolled < 0 ? 0 : newDistanceScrolled
      })
    )
  }

  const scrollRight = () => {
    const newDistanceScrolled = state.distanceScrolled + STEP_SIZE

    setState(
      produce(state, (draft) => {
        draft.distanceScrolled =
          newDistanceScrolled > state.maxScrollDistance
            ? state.maxScrollDistance
            : newDistanceScrolled
      })
    )
  }

  const setShowNavButtons = (value: boolean) => {
    if (value !== state.showNavButtons) {
      setState(
        produce(state, (draft) => {
          draft.showNavButtons = value
        })
      )
    }
  }

  const setMaxScrollDistance = (maxScrollDistance: number) => {
    setState(
      produce(state, (draft) => {
        draft.maxScrollDistance = maxScrollDistance
      })
    )
  }

  React.useEffect(() => {
    if (prevCount !== props.selectedDocumentId) {
      setState(
        produce(state, (draft) => {
          draft.distanceScrolled = 0
        })
      )
    }
  }, [props.selectedDocumentId])

  const {
    hasAddButton,
    className,
    showStatusDots,
    renderRightContent: ExtraContent,
    ...rest
  } = props

  const variant = props.variant ?? "primary"

  return (
    <div
      className={cx(styles.slider, styles[`variant-${variant}`], className)}
      ref={slider}
    >
      {state.showNavButtons && (
        <NavigationButton
          icon="arrow-left"
          onClick={scrollLeft}
          disabled={state.distanceScrolled === 0}
          variant={variant!}
        />
      )}
      <Tabs
        distanceScrolled={state.distanceScrolled}
        onSetMaxScrollDistance={setMaxScrollDistance}
        variant={variant!}
        onSetShowNavButtons={setShowNavButtons}
        showNavButtons={state.showNavButtons}
        showStatusDots={showStatusDots}
        {...rest}
      />
      {state.showNavButtons && (
        <NavigationButton
          icon="arrow-right"
          onClick={scrollRight}
          disabled={state.distanceScrolled >= state.maxScrollDistance}
          variant={variant!}
          className={styles.nextButton}
        />
      )}
      {ExtraContent && (
        <div className={styles.renderedContent}>
          <ExtraContent />
        </div>
      )}
    </div>
  )
}

interface SelectionSliderState {
  distanceScrolled: number
  maxScrollDistance: number
  showNavButtons: boolean
}

export interface Tab {
  id: string
  value: any
  label: string

  statusColor?: StatusColors
  status?: GetEditOrderPageCaseFolder_caseFolder_editTabs_orders_mediaOrder_status
  alerting?: GetEditOrderPageCaseFolder_caseFolder_editTabs_orders_alerting
}

interface SelectionSliderProps {
  tabs: Tab[]
  className?: string
  variant?: string
  tabClassName?: string
  selectedTabClassName?: string
  onClick: (value: any) => void
  renderRightContent?: (() => JSX.Element | null) | React.ComponentClass
  hasAddButton?: boolean
  selectedId?: string
  selectedDocumentId?: string
  showStatusDots?: boolean
}

function usePrevious(value: any) {
  const ref = React.useRef()

  React.useEffect(() => {
    ref.current = value
  }, [value])

  return ref.current
}

export default SelectionSlider
