import * as React from "react"
import { useState, useContext } from "react"
import * as styles from "./styles.scss"
import { useTranslation } from "react-i18next"
import { withRouter, RouteComponentProps } from "react-router-dom"
import Button from "components/Button"
import { useQuery, useApolloClient } from "react-apollo-hooks"
import GET_ORDER_PROOF from "graphql/queries/getOrderProof"
import Header from "components/Header"
import { downloadPdf } from "utils"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import ProofModal from "components/ProofModal"
import {
  GetOrderProof,
  GetOrderProofVariables,
  GetReviewCaseFolder_caseFolder_documents_orders,
} from "schema"
import cx from "classnames"
import { useLazyQuery } from "@apollo/react-hooks"
// import { GlobalErrorContex } from "GlobalErrorContext"
// import { ApolloError } from "apollo-client"
import { error } from "appLog"
// import ErrorMessage from "components/ErrorMessage"

function ReviewHeaderRow(props: ReviewHeaderRowProps & RouteComponentProps) {
  const [t] = useTranslation(["review", "proof"])
  const [proofModalIsOpen, setProofModalIsOpen] = React.useState(false)
  const orderIds = props.orders.map((order) => order.id)
  const [proofUrl, setProofUrl] = useState<undefined | null | string>(undefined)
  const client = useApolloClient()
  // const { showMessage } = useContext(GlobalErrorContex)

  const [runGetOrderProof, { called, loading, data }] = useLazyQuery<
    GetOrderProof,
    GetOrderProofVariables
  >(GET_ORDER_PROOF, {
    client,
    variables: {
      orderIds: orderIds
        .filter((e) => e !== null && e !== undefined)
        .map(toString),
    },
    onCompleted: (response) => {
      console.log("co jest?", response.orderCollection.material.proof.url)
      if (
        data &&
        data.orderCollection &&
        data.orderCollection.material &&
        data.orderCollection.material.proof &&
        data.orderCollection.material.proof.url
      ) {
        // console.error("??", data.orderCollection.material.proof.url)
        downloadPdf(data.orderCollection.material.proof.url, "full_proof.pdf")
        // setProofUrl(data.orderCollection.material.proof.url)
      } else {
        error("no response url")
        // setProofUrl(null)
      }
    },
    // onError: (error: ApolloError) => {
    //   // console.log("err", error)
    //   if (showMessage) {
    //     showMessage({ message: error.toString(), error })
    //   }
    // },
  })

  // const { data, loading } = useQuery(GET_ORDER_PROOF, {
  //   variables: { orderIds: props.orders.map(order => order.id) },
  // })
  // const url = loading ? undefined : data!.orderCollection.material.proof.url

  const handleDownloadClick = () => {
    if (loading === true) {
      return
    }
    runGetOrderProof()
    // @TODO: REMOVE DOUBLE CLICK
    // if (proofUrl === undefined) {
    //   if (called === false) {
    //     runGetOrderProof()
    //     // trigger download after promise end
    //   }
    // } else if (proofUrl === null) {
    //   console.error("handleDownloadClick: no proof url")
    // } else {
    //   downloadPdf(proofUrl, "full_proof.pdf")
    // }
  }

  const handlePrintClick = () => {
    if (proofUrl === undefined) {
      if (called === false) {
        runGetOrderProof()
      }
    } else if (proofUrl === null) {
      console.error("handlePrintClick: no proof url")
    } else {
      window.open(proofUrl)
    }
  }

  const goToEditOrderPage = () =>
    props.history.push(`/editOrder/c/${props.caseFolderId}/o/${props.orderId}`)

  const openProofModal = () => setProofModalIsOpen(true)

  const closeProofModal = () => setProofModalIsOpen(false)

  return (
    <React.Fragment>
      <div className={styles.headerRow}>
        <Header title={t("reviewHeader")} className={styles.header} />
        <div className={styles.buttonsContainer}>
          <Button onClick={goToEditOrderPage}>{t("backToEdit")}</Button>
          <span className={styles.completeProof}>{t("completeProof")}:</span>
          <div
            className={cx(styles.downloadButton, {
              [styles.disabled]: loading,
            })}
            onClick={handleDownloadClick}
          >
            <FontAwesomeIcon icon="download" />
          </div>
          <div
            className={cx(styles.printButton, { [styles.disabled]: loading })}
            onClick={handlePrintClick}
          >
            <FontAwesomeIcon icon="print" />
          </div>
          <Button onClick={openProofModal}>
            {t("proof:mediateToCustomer")}
          </Button>
        </div>
      </div>
      <ProofModal
        isOpen={proofModalIsOpen}
        onRequestClose={closeProofModal}
        // productOrders={props.orders as any[]}
      />
    </React.Fragment>
  )
}

interface ReviewHeaderRowProps {
  caseFolderId: string
  orderId: string
  orders: GetReviewCaseFolder_caseFolder_documents_orders[]
}

export default withRouter(ReviewHeaderRow)
