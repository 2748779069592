import gql from "graphql-tag"

const UPDATE_PUBLISH_CHANNEL = gql`
  mutation UpdatePublishChannel(
    $input: MutationUpdateOrderPublishChannelsInput!
  ) {
    updateOrderPublishChannels(input: $input) {
      isError
      errorReason
      order {
        id
        caseFolder {id}
        mediaOrder {
          publishChannels {
            inFamiljesidan
            inMinnesrummet
            inAgency
          }
          preview {
            price(withVAT: true, withRounding:STANDARD, priceView: WITH_SERVICE_ORDER) {
              endCustomer {pricePayRounded}
            }
          }          
        }
      }
    }
  }
`

export default UPDATE_PUBLISH_CHANNEL
