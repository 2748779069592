import * as React from "react"
import OfficeSelectionForm from "./SelectionForm"
import LoginLayout from "layouts/LoginLayout"

class OfficeSelectionPage extends React.Component {
  render() {
    return (
      <LoginLayout>
        <OfficeSelectionForm />
      </LoginLayout>
    )
  }
}

export default OfficeSelectionPage
