import * as React from "react"
import { ApolloClient } from "apollo-client"
import { withRouter, RouteComponentProps, useHistory } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { SessionStorageKeys } from "environmentUtils"
import { useApolloClient } from "react-apollo-hooks"
import Spinner from "components/Spinner"
// import { withTranslation, WithTranslation } from "react-i18next"

// class LogoutButton extends React.PureComponent<
//   LogoutButtonProps & WithTranslation & RouteComponentProps
// > {
//   handleClick = () => {
//     this.props.client.resetStore()
//     localStorage.removeItem("token")
//     this.props.history.push("/login")
//   }

//   render() {
//     return <li onClick={this.handleClick}>{this.props.t("logOut")}</li>
//   }
// }

// export default withTranslation("navbar")(withRouter(LogoutButton))

const LogoutButton: React.FC<LogoutButtonProps & RouteComponentProps> = (
  props
) => {
  const [t] = useTranslation("navbar")

  const onClick = () => {
    props.history.push("/logout")
  }

  return <li onClick={onClick} className={"logoutButton"}>{t("logOut")}</li>
}

interface LogoutButtonProps {
  client: ApolloClient<any>
}

export const LogOutPage: React.FC<{}> = (props) => {
  const client = useApolloClient()
  const history = useHistory()

  React.useEffect(() => {
    client.clearStore().then(() => {
      sessionStorage.removeItem(SessionStorageKeys.AUTH_TOKEN)
      localStorage.removeItem(SessionStorageKeys.AUTH_TOKEN);
      window.location.href = "/login"
      // history.push("/login")
    })
  }, [])

  return (
    <>
      <Spinner fullscreen={true} />
    </>
  )
}

export default withRouter(LogoutButton)
