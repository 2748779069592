import * as React from "react"
import * as styles from "./styles.scss"
import TextAlignSelector from "./TextAlignSelector"
import ColumnsButton from "./ColumnsButton"
import { AppContext } from "App"
import AdminSectionToggler from "../../AdminSectionToggler"

const Toolbar: React.FC<{
  adminSectionIsExpanded: boolean
  onEnterColumnEdit: () => void
  toggleAdminSectionIsExpanded: () => void
}> = (props) => {
  const { currentUserSession } = React.useContext(AppContext)

  return (
    <div className={styles.toolbar}>
      <div className={styles.buttonsContainer}>
        <TextAlignSelector />
      </div>

      {currentUserSession.user!.isSupportUser && (
        <AdminSectionToggler
          onClick={props.toggleAdminSectionIsExpanded}
          isExpanded={props.adminSectionIsExpanded}
        />
      )}
    </div>
  )
}

export default Toolbar
