import * as React from "react"
import * as styles from "./styles.scss"
import TextAlignment from "./TextAlignment"
import { OrderEditorAreaContext } from "../../.."
import { TextAlignments } from "schema"

const ALIGN_PRIO = {
  LEFT: 1,
  CENTER: 2,
  RIGHT: 3,
  JUSTIFY_LAST_LEFT: 4,
  CENTER_LEFT: 5,
}

class TextAlignSelector extends React.PureComponent {
  sortByAlignPrio = (a: string, b: string) =>
    ALIGN_PRIO[a] >= ALIGN_PRIO[b] ? 1 : -1

  getIcon = (value: string) => {
    switch (value) {
      case "LEFT":
        return "align-left"
      case "CENTER":
        return "align-center"
      case "RIGHT":
        return "align-right"
      case "JUSTIFY_LAST_LEFT":
        return "align-justify"
      default:
        return "align-right"
    }
  }

  render() {
    return (
      <OrderEditorAreaContext.Consumer>
        {({ editor, setTextAlignment, contentRows }) => {
          if (!contentRows) {
            return null
          }
          if (contentRows.length === 0) {
            return null
          }
          if (!editor) {
            return null
          }

          if (!editor.text) {
            return null
          }

          if (editor.text.allowedTextAdjustments === undefined) {
            return null
          }

          if (editor.text.allowedTextAdjustments.length === 0) {
            return null
          }
          const defaultTextAligment =
            editor.text.defaultTextAdjustment ?? TextAlignments.DEFAULT
          let textAlignment = ""
          if (
            contentRows &&
            contentRows[0] &&
            contentRows[0].columns &&
            contentRows[0].columns[0] &&
            contentRows[0].columns[0].item &&
            contentRows[0].columns[0].item.text &&
            contentRows[0].columns[0].item.text.textAlignment !== undefined &&
            contentRows[0].columns[0].item.text.textAlignment !== null
          ) {
            textAlignment = contentRows[0].columns[0].item.text.textAlignment
          }

          return (
            <div className={styles.icons}>
              {editor.text.allowedTextAdjustments
                .sort(this.sortByAlignPrio)
                .map((value) => {
                  const selectedTextAlignment =
                    textAlignment === TextAlignments.DEFAULT
                      ? defaultTextAligment
                      : textAlignment

                  return (
                    <TextAlignment
                      key={value}
                      value={value}
                      onClick={setTextAlignment}
                      icon={this.getIcon(value)}
                      active={value === selectedTextAlignment}
                    />
                  )
                })}
            </div>
          )
        }}
      </OrderEditorAreaContext.Consumer>
    )
  }
}

export default TextAlignSelector
