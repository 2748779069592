import gql from "graphql-tag"

const GET_ALL_NEWS = gql`
  query GetAllNews {
    news {
      id
      isPopup
      isNewslist
      header
      summary
      text
      timeFrom
      timeTo
      isFullContent
    }
  }
`

export default GET_ALL_NEWS
