import gql from "graphql-tag"

const GET_AVAILABLE_PUBLISH_TIMES = gql`
  query GetAvailablePublishTimes($orderId: ID) {
    mediaPublishCalendarDays(orderId: $orderId) {
      timeDay
      isEnabled
      timeBooking
      comment
    }
  }
`

export default GET_AVAILABLE_PUBLISH_TIMES
