import * as React from "react"
import * as styles from "./styles.scss"
import Dot from "./Dot"
// import posed, { PoseGroup } from "react-pose1"
import { motion, AnimatePresence } from "framer-motion"

// const DotPose = posed.div({
//   enter: { scale: 1 },
//   exit: { scale: 0, transition: { duration: 0 } },
// })

const variants = {
  enter: { scale: 1 },
  exit: { scale: 0, transition: { duration: 0 } },
}

class Dots extends React.PureComponent<DotsProps> {
  render() {
    const { slides, currentSlideIndex, goToSlide, isDotDisabled } = this.props

    return (
      <div className={styles.dots}>
        <AnimatePresence>
          {React.Children.map(slides, (child, index) => (
            <motion.div
              // variants={variants}
              initial={{ scale: 0 }}
              animate={{ scale: 1 }}
              exit={{ scale: 0, transition: { duration: 0 } }}
            >
              <Dot
                index={index}
                isActive={index === currentSlideIndex}
                isDisabled={isDotDisabled ? isDotDisabled(index) : false}
                onClick={goToSlide}
              />
            </motion.div>
          ))}
        </AnimatePresence>
      </div>
    )
  }
}

interface DotsProps {
  slides: React.ReactNode
  currentSlideIndex: number
  goToSlide?: (index: number) => void
  isDotDisabled?: (index: number) => boolean
}

export default Dots
