import React from 'react'
import { symbolName } from 'typescript'


export const PdfViewer: React.FC<{
  symboles: any
  className?: any
}> = (props) => {
    return (
        <div className={props.className} id={"pdfViewer"} >
            <ul>
                {
                    props.symboles.map((s:any , i: number) => {
                        return(
          
                            <li key={i}>
                                <img src={s.thumbnail100x80Url} alt="" />
                                <span>{s.publicId}</span>
                            </li>

                        )
                    })
                }
            </ul>
        </div>
    )
}

