import * as React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import * as styles from "./styles.scss"
import cx from "classnames"
import { OrderEditorAreaContext } from "../../.."

class VisibilityToggler extends React.PureComponent<VisibilityTogglerProps> {
  render() {
    const { disabled } = this.props

    return (
      <OrderEditorAreaContext.Consumer>
        {({ toggleVisibility, visible }) => (
          <div
            className={cx(styles.container, {
              [styles.adstateView__conatainer]: this.props.isFEUser,
              [styles.disabled]: disabled,
            })}
            onClick={disabled ? undefined : toggleVisibility}
          >
            <FontAwesomeIcon
              className={cx(styles.icon, {
                [styles.visible]: !visible
              })}
              icon={visible ? "eye" : "eye-slash"}
            />
          </div>
        )}
      </OrderEditorAreaContext.Consumer>
    )
  }
}

interface VisibilityTogglerProps {
  disabled?: boolean
  isFEUser: boolean
}

export default VisibilityToggler
