import * as React from "react"
import * as styles from "./styles.scss"
import { useTranslation, withTranslation, WithTranslation } from "react-i18next"

export const Price: React.FC<{
  price: number | null
  currency: string
}> = (props) => {
  const { t } = useTranslation("review")

  return (
    <div className={styles.price}>
      <b>{t("price")} </b>
      <PriceContent
        price={props.price ?? undefined}
        currency={props.currency}
      />
    </div>
  )
}

export const PriceContent: React.FC<{ price?: number; currency: string }> = (
  props
) => {
  const { t } = useTranslation("review")
  return (
    <span>
      {props.price
        ? `${Math.round(props.price)} ${props.currency}`
        : t("noPrice")}
    </span>
  )
}

export default Price
