import gql from "graphql-tag"

const UPDATE_CURRENT_USER_SESSION = gql`
  mutation UpdateCurrentUserSession(
    $input: MutationUpdateCurrentUserSessionInput!
  ) {
    updateCurrentUserSession(input: $input) {
      userSession {
        id
        authorizationToken
        customer {
          id
        }
      }
    }
  }
`

export default UPDATE_CURRENT_USER_SESSION
