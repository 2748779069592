import * as React from "react"
import * as styles from "./styles.scss"
import cx from "classnames"
import { blurOnEnter } from "utils"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faMinusCircle } from "@fortawesome/free-solid-svg-icons"
import { UnitTypes } from "schema"
import _ from "lodash"

class DraggableNumberInput extends React.Component<
  DraggableNumberInputProps,
  DraggableNumberInputState
> {
  static getDerivedStateFromProps(
    nextProps: DraggableNumberInputProps,
    prevState: DraggableNumberInputState
  ) {
    if (!prevState || prevState.value !== nextProps.initialValue) {
      return {
        value: nextProps.initialValue,
        tempValue: nextProps.initialValue,
      }
    }
    return {}
  }

  state = {
    isMouseDown: false,
    tempValue: this.props.initialValue ?? null,
    value: this.props.initialValue ?? null,
    mouseDownPosition: 0,
    readOnly: true,
  }

  // handleMouseMove = (e: MouseEvent) => {
  //   e.preventDefault()
  //   const { min, max } = this.props
  //   const difference = e.clientX - this.state.mouseDownPosition
  //   const tempValue = Math.round((this.state.value ?? 0) + difference / 20) / 2

  //   if (
  //     (min !== undefined && tempValue < min) ||
  //     (max !== undefined && tempValue > max)
  //   )
  //     return

  //   this.setState({ tempValue })
  // }

  // handleDoubleClick = () => this.setState({ readOnly: false })

  // handleBlur = () => {
  //    this.updateValue()
  // }

  handleClick = () => {
    document.addEventListener("keydown", (e) => {
      if (e.code === "Enter" || e.code === "NumpadEnter") {
        this.updateValue()
      }
    })
  }

  handleInputBlur = () => {
    this.updateValue()
  }

  updateValue = () => {
    const { onChangeComplete } = this.props
    const { tempValue, value } = this.state

    if (value !== tempValue) {
      this.setState({ value: tempValue }, () => {
        if (onChangeComplete) {
          onChangeComplete(tempValue)
        }
      })
    }
  }

  // handleMouseUp = () => {
  //   document.removeEventListener("mousemove", this.handleMouseMove)
  //   document.removeEventListener("mouseup", this.handleMouseUp)

  //   this.updateValue()
  // }

  handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    try {
      const val: number = e.target.valueAsNumber
      // const defaultValue: number = Number(this.props.defaultValue)
      // this.setState({tempValue: val})
      if (isNaN(val)) {
        this.setState({ tempValue: null })
      } else {
        this.setState({tempValue: val})
        // @basheer this function use to make a value equal to default value when click on arrow up/down
        // @ comment out this because there are conflict with minus value
        // this.setState((prev) => {
        //   if (prev.tempValue === null && defaultValue && !isNaN(val)) {
        //     return { tempValue: defaultValue }
        //   }
        //   return { tempValue: val }
        // })
      }
    } catch (e) {
      this.setState({ tempValue: null })
    }
    // if (isNaN(value) === false) {
    //   this.setState({ tempValue: value })
    // }
    document.addEventListener("keydown", this.handleBackSpaceClick)
  }

  // handleMouseDown = (e: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
  //   document.addEventListener("mousemove", this.handleMouseMove)
  //   document.addEventListener("mouseup", this.handleMouseUp)
  //   this.setState({ mouseDownPosition: e.clientX })
  // }

  handleBackSpaceClick = (e: any) => {
    if (e.code === "Backspace" && this.state.tempValue === 0) {
      e.preventDefault()
      this.setState({ tempValue: null })
    }
  }

  onClear = () => {
    this.setState({ tempValue: null })
    if (this.props.onChangeComplete) {
      this.props.onChangeComplete(null)
    }
  }

  render() {
    const { tempValue } = this.state
    return (
      <div className={styles.wrapper}>
        {this.state.tempValue !== null ? (
          <div className={styles.removeIcon} onClick={this.onClear}>
            <FontAwesomeIcon icon={faMinusCircle} color={"#e6e6e6"} />
          </div>
        ) : null}

        <input
          type="number"
          // onMouseDown={this.handleMouseDown}
          className={cx(
            styles.draggableNumberInput,
            this.props.className,
            this.props.disabled !== undefined && this.props.disabled === true
              ? styles.disabled
              : styles.active
          )}
          value={
            (Number.isInteger(tempValue) ? tempValue : tempValue?.toFixed(1)) ??
            ""
          }
          // readOnly={this.state.readOnly}
          onChange={this.handleChange}
          onClick={this.handleClick}
          step={0.5}
          // onDoubleClick={this.handleDoubleClick}
          onBlur={this.handleInputBlur}
          // onKeyDown={blurOnEnter}
          placeholder={this.props.defaultValue}
        />

        {this.props.type !== undefined && this.props.type !== null ? (
          <React.Fragment>
            {this.props.type === UnitTypes.MILLIMETER ? <span>mm</span> : ""}
          </React.Fragment>
        ) : null}
      </div>
    )
  }
}

interface DraggableNumberInputProps {
  initialValue?: number | null
  min?: number
  max?: number
  className?: string
  onChangeComplete?: (value: number | null) => void
  disabled?: boolean
  type: UnitTypes
  defaultValue: any
}

interface DraggableNumberInputState {
  isMouseDown: boolean
  tempValue: number | null
  value: number | null
  mouseDownPosition: number
  readOnly: boolean
}

export default DraggableNumberInput

// import * as React from "react"
// import * as styles from "./styles.scss"
// import cx from "classnames"
// import { blurOnEnter } from "utils"
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
// import { faMinusCircle } from "@fortawesome/free-solid-svg-icons"
// import { UnitTypes } from "schema"
// import produce from "immer"

// // let mouseDownPosition: number = 0

// const DraggableNumberInput: React.FC<Props> = (props) => {
//   const defaultState: State = {
//     isMouseDown: false,
//     inputValue: props.initialValue ?? null,
//     // value: props.initialValue ?? null,
//     mouseDownPosition: 0,
//     readOnly: true,
//   }
//   const dragHeadRef = React.useRef<HTMLInputElement | null>(null)
//   const [state, setState] = React.useState<State>(defaultState)
//   //const [value, setValue, ref] = useStateRef(props.initialValue)

//   const handleMouseMove = (e: MouseEvent) => {
//     console.log("handleMouseMove ", state.isMouseDown, state.mouseDownPosition)
//     e.preventDefault()

//     // const { min, max } = props
//     const difference = e.clientX - state.mouseDownPosition
//     const newValue = (state.inputValue ?? 0) + difference
//     const isInRange =
//       (props.min !== undefined && newValue < props.min) ||
//       (props.max !== undefined && newValue > props.max)

//     console.log("handleMouseMove ", e.clientX, state.isMouseDown, isInRange)

//     if (state.isMouseDown === false) {
//       return
//     }

//     if (isInRange === false) {
//       return
//     }

//     setState(
//       produce(state, (draft) => {
//         draft.inputValue = newValue
//       })
//     )
//   }
//   const canDrag = React.useRef<boolean>(true)

//   const handleDoubleClick = () => {
//     console.log("handleDoubleClick")
//     // setState(
//     //   produce(state, (draft) => {
//     //     draft.readOnly = false
//     //   })
//     // )
//     canDrag.current = false
//     // setCanDrag(false)
//   }

//   const handleBlur = () => {
//     console.log("handleBlur")
//     canDrag.current = true
//     // const newState = produce(state, (draft) => {
//     //   draft.readOnly = true
//     // })

//     // if (!state.readOnly) {
//     //   updateValueWithState(newState)
//     // } else {
//     //   setState(newState)
//     // }
//   }

//   const updateValueWithState = (newState: State) => {
//     // if (newState.value !== newState.tempValue) {
//     console.log("zmienilo sie??", newState.inputValue, state.inputValue)
//     setState(
//       produce(newState, (draft) => {
//         // draft.value = newState.inputValue

//         draft.readOnly = true
//       })
//     )
//     if (props.onChangeComplete) {
//       props.onChangeComplete(newState.inputValue)
//     }
//     // }
//   }

//   const handleMouseUp = (e: MouseEvent) => {
//     if (state.isMouseDown === false) {
//       return
//     }
//     // document.removeEventListener("mousemove", handleMouseMove)
//     // document.removeEventListener("mouseup", handleMouseUp)
//     // if (state.isMoved === false) {
//     //   return
//     // }
//     // mouseDownPosition = 0
//     const newState = produce(state, (draft) => {
//       draft.isMouseDown = false
//       draft.mouseDownPosition = 0
//     })
//     updateValueWithState(newState)
//     // setState(newState)
//   }

//   const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
//     try {
//       // const value = Number(e.target.value)
//       const val = parseInt(e.target.value, 10)
//       if (isNaN(val) === true) {
//         setState(
//           produce(state, (draft) => {
//             draft.inputValue = null
//           })
//         )
//       } else {
//         setState(
//           produce(state, (draft) => {
//             draft.inputValue = val
//           })
//         )
//       }
//     } catch (e) {
//       setState(
//         produce(state, (draft) => {
//           draft.inputValue = null
//         })
//       )
//     }
//     // if (isNaN(value) === false) {
//     //   this.setState({ tempValue: value })
//     // }
//   }

//   // const scrolling = React.useRef(false)
//   // const inputRef = React.useRef<HTMLInputElement | null>(null)

//   const handleMouseDown = (
//     // e: React.MouseEvent<HTMLInputElement, MouseEvent>
//     e: React.MouseEvent<HTMLInputElement>
//   ) => {
//     // console.log("handleMouseDown ", e.clientX)
//     // document.addEventListener("mousemove", handleMouseMove)
//     // document.addEventListener("mouseup", handleMouseUp)
//     // setState(
//     //   produce(state, (draft) => {
//     //     draft.isMouseDown = true
//     //     draft.readOnly = true
//     //     draft.mouseDownPosition = e.clientX
//     //   })
//     // )
//     // mouseDownPosition = e.clientX
//   }

//   const onClear = () => {
//     setState(
//       produce(state, (draft) => {
//         draft.inputValue = null
//       })
//     )
//     if (props.onChangeComplete) {
//       props.onChangeComplete(null)
//     }
//   }

//   React.useEffect(() => {
//     if (props.initialValue !== state.inputValue) {
//       console.log("new value from effect...", props.initialValue ?? null)
//       setState(
//         produce(state, (draft) => {
//           draft.inputValue = props.initialValue ?? null
//           // draft.value = props.initialValue ?? null
//         })
//       )
//     }
//   }, [props.initialValue])

//   // React.useEffect(() => {
//   //   document.addEventListener("mousemove", handleMouseMove)
//   //   document.addEventListener("mouseup", handleMouseUp)
//   //   return () => {
//   //     document.removeEventListener("mousemove", handleMouseMove)
//   //     document.removeEventListener("mouseup", handleMouseUp)
//   //   }
//   // }, [])

//   // React.useEffect(() => {
//   //   const handler = () => {
//   //     if (scrolling.current === false) {
//   //       scrolling.current = true
//   //     }
//   //   }
//   //   window.addEventListener("mousemove", handler)
//   //   window.addEventListener("mouseup", handler)
//   //   return () => {
//   //     window.removeEventListener("mousemove", handler)
//   //     window.removeEventListener("mouseup", handler)
//   //   }
//   // }, [])

//   const isDragging = React.useRef<boolean>(false)
//   const diff = React.useRef<number>(0)

//   // const [startPosition, setPosition] = React.useState(0)

//   const onMouseDown = React.useCallback((e) => {
//     if (
//       canDrag.current === true &&
//       dragHeadRef.current !== null &&
//       dragHeadRef.current.contains(e.target)
//     ) {
//       // console.log("onMouseDown", e.clientX)
//       diff.current = e.clientX
//       isDragging.current = true
//       // setPosition(e.clientX)
//     }
//   }, [])

//   const onMouseUp = React.useCallback((e) => {
//     if (isDragging.current) {
//       isDragging.current = false
//       if (dragHeadRef.current) {
//         const difference = (diff.current - e.clientX) * -1
//         const newValue = (state.inputValue ?? 0) + difference
//         // console.log(
//         //   "na koniec zmiana??",
//         //   startPosition,
//         //   dragHeadRef.current.value,
//         //   newValue
//         // )
//         const isInRange =
//           (props.min !== undefined && newValue < props.min) ||
//           (props.max !== undefined && newValue > props.max)

//         if (isInRange === false) {
//           return
//         }
//         setState(
//           produce(state, (draft) => {
//             draft.inputValue = newValue

//             // draft.value = props.initialValue ?? null
//           })
//         )
//         diff.current = 0
//       }
//     }
//   }, [])

//   const onMouseMove = React.useCallback((e) => {
//     if (isDragging.current === true) {
//       // console.log("onMouseMove", isDragging.current, canDrag.current)
//       // const newPosition = position + e.movementX;
//       // setPosition(newPosition)
//       e.preventDefault()

//       // const { min, max } = props
//       const difference = (diff.current - e.clientX) * -1
//       const newValue = (state.inputValue ?? 0) + difference
//       const isInRange =
//         (props.min !== undefined && newValue < props.min) ||
//         (props.max !== undefined && newValue > props.max)

//       // console.log(
//       //   "handleMouseMove ",
//       //   difference,
//       //   newValue,
//       //   isInRange,
//       //   props.min,
//       //   props.max
//       // )

//       // if (state.isMouseDown === false) {
//       //   return
//       // }

//       if (isInRange === false) {
//         return
//       }

//       setState(
//         produce(state, (draft) => {
//           draft.inputValue = newValue
//         })
//       )
//       if (dragHeadRef.current) {
//         dragHeadRef.current.value = newValue.toString()
//       }
//     }
//   }, [])

//   React.useEffect(() => {
//     document.addEventListener("mouseup", onMouseUp)
//     document.addEventListener("mousedown", onMouseDown)
//     document.addEventListener("mousemove", onMouseMove)
//     return () => {
//       document.removeEventListener("mouseup", onMouseUp)
//       document.removeEventListener("mousedown", onMouseDown)
//       document.removeEventListener("mousemove", onMouseMove)
//     }
//   }, [onMouseMove, onMouseDown, onMouseUp])

//   // console.log("render...")

//   return (
//     <div className={styles.wrapper}>
//       {state.inputValue !== null ? (
//         <div className={styles.removeIcon} onClick={onClear}>
//           <FontAwesomeIcon icon={faMinusCircle} color={"#e6e6e6"} />
//         </div>
//       ) : null}

//       {/* <span>scrolling: {scrolling}</span> */}

//       <input
//         // ref={inputRef}
//         ref={dragHeadRef}
//         // onMouseDown={handleMouseDown}
//         // onMouseMove={handleMouseMove}
//         // onMouseUp={handleMouseUp}
//         className={cx(
//           styles.draggableNumberInput,
//           props.className,
//           props.disabled !== undefined && props.disabled === true
//             ? styles.disabled
//             : styles.active
//         )}
//         value={state.inputValue ?? ""}
//         readOnly={canDrag.current === true}
//         onChange={handleChange}
//         onDoubleClick={handleDoubleClick}
//         onBlur={handleBlur}
//         onKeyDown={blurOnEnter}
//       />

//       {props.type !== undefined && props.type !== null ? (
//         <React.Fragment>
//           {props.type === UnitTypes.MILLIMETER ? <span>mm</span> : ""}
//         </React.Fragment>
//       ) : null}
//     </div>
//   )
// }

// interface Props {
//   initialValue?: number | null
//   min?: number
//   max?: number
//   className?: string
//   onChangeComplete?: (value: number | null) => void
//   disabled?: boolean
//   type: UnitTypes
// }

// interface State {
//   isMouseDown: boolean
//   inputValue: number | null
//   // value: number | null
//   mouseDownPosition: number
//   readOnly: boolean
// }

// export default DraggableNumberInput
