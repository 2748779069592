import * as React from "react"
import * as styles from "./styles.scss"
import ColumnIcon from "components/ColumnIcon"
import { useTranslation } from "react-i18next"

function InfoRow(props: InfoRowProps) {
  const [t] = useTranslation("editOrder")
  const { columnCount, index, rowIndex } = props

  function getHightlightedColumn() {
    if (index === 0) return "left"
    if (index === 2 || (columnCount === 2 && index === 1)) return "right"
    return "middle"
  }

  const highlightedColumn = getHightlightedColumn()

  return (
    <div className={styles.infoRow}>
      <ColumnIcon columns={columnCount} activeColumn={highlightedColumn} />
      <span className={styles.text}>
        {t("row")} {rowIndex + 1}{" "}
        {columnCount > 1 && `- ${t(highlightedColumn)}`}
      </span>
    </div>
  )
}

interface InfoRowProps {
  index: number
  rowIndex: number
  columnCount: number
}

export default InfoRow
