import * as React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowCircleLeft, faEdit } from "@fortawesome/free-solid-svg-icons"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"
import styled from "styled-components"
import { generatePath } from "react-router"
import { DashboardRoutesPaths } from "routes/DashboardRoutes"

export const TopSidebarCaseInfoBlock: React.FC<{
  name: string
  caseId: string
}> = (props) => {
  const history = useHistory()
  const { t } = useTranslation(["common", "editOrder"])
  const { DashboardWithCaseFolder, DashboardWithAction } = DashboardRoutesPaths
  const urlHome = generatePath(DashboardWithCaseFolder, {
    caseFolderId: props.caseId,
  })
  const urlEdit = generatePath(DashboardWithAction, {
    caseFolderId: props.caseId, action: "true",
  })
  const onClickBackEdit = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    e.preventDefault()
    history.push(urlEdit)
  }
  const onClickBackHome = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    e.preventDefault()
    history.push(urlHome)
  }
  return (
    <Styles>
      <div className={"topSidebarInfoBlock"}>
        <div className={"editAndCaseFolder"}>
          <a href={urlEdit} onClick={onClickBackEdit}>
            <span>
              {t("common:caseFolder")}{" "}
              {/* <FontAwesomeIcon icon={faEdit} color={"#A4A4A4"} size={"xs"} /> */}
            </span>
            <strong>{props.name}</strong>
          </a>
        </div>

        <div className={"backToHomePage"}>
          <a href={urlHome} onClick={onClickBackHome}>
            <div className={"icon"}>
              <FontAwesomeIcon
                icon={faArrowCircleLeft}
                color={"#E4701E"}
                size={"lg"}
              />
            </div>
            <div className={"text"}>{t("editOrder:backToHomePage")}</div>
          </a>
        </div>
      </div>
    </Styles>
  )
}

const Styles = styled.div`
  .topSidebarInfoBlock {
    background: #ffffff;
    box-shadow: 0px 3px 6px #0000001a;
    border-radius: 3px;
    border-top: 3px solid #e4701e;
    padding-top: ${(props) => props.theme["--spacing"]};
    padding-bottom: ${(props) => props.theme["--spacing"]};
    padding-left: ${(props) => props.theme["--spacing"]} ;
    padding-right: ${(props) => props.theme["--spacing"]};
    margin-bottom: 28px;
    margin-left: ${(props) => props.theme["--spacing"]};
    cursor: pointer;
    display: grid;
    grid-template-columns: auto 115px;

    .editAndCaseFolder {
      display: flex;
      align-self: center;
      a {
        display: grid;
        grid-template-columns: auto;
        grid-template-rows: auto auto;
        row-gap: 2px;
        text-decoration: none;
      }

      span {
        font-size: 14px;
        line-height: 17px;
        color: #161616;
      }

      strong {
        font-size: 18px;
        line-height: 29px;
        color: #000000;
      }
    }
    .backToHomePage {
      display: flex;
      align-self: center;
      a {
        text-decoration: none;
        display: grid;
        grid-template-columns: 20px auto;
        column-gap: 8px;
      }

      .icon {
        line-height: 27px;
      }

      .text {
        color: #161616;
        font-size: 14px;
        line-height: 27px;
      }
    }
  }
`
