import * as React from "react"
import * as styles from "./styles.scss"
import FieldWrapper, {
  FieldWrapperFormik,
  makeValidate,
} from "components/FieldWrapper"
import TextInput from "components/TextInput"
import Button from "components/Button"
import CheckboxField, { CheckboxFieldFormik } from "components/CheckboxField"
import TextArea from "components/TextArea"
import { useTranslation } from "react-i18next"
import { useMutation } from "react-apollo-hooks"
import MEDIATE_MATERIAL from "graphql/mutations/mediateMeterial"
import { AttachmentsObj , IProofSetting} from ".."
import { useAppStoreContextState } from "App"
import { MediateMaterial, MediateMaterialVariables } from "schema"
import { array, boolean, object, string } from "yup"
import { useFormik } from "formik"
import { FieldReactMutliEmails } from "components/ReactMultiEmail"
import { ErrorText } from "components/ErrorMessage"

function MediateToCustomerForm(props: MediateToCustomerFormProps) {
  const [t] = useTranslation(["proof", "common", "errorMessages"])
  const [isLoading, setLoading] = React.useState(false)
  const mediateMaterialMutation = useMutation<
    MediateMaterial,
    MediateMaterialVariables
  >(MEDIATE_MATERIAL)
  const appContext = useAppStoreContextState()
  const emailOffice =
    appContext.currentCustomer.inheritSettings.emailCustomer ?? appContext.currentCustomer.inheritSettings.emailOffice

  const emailReplyAddress: string = emailOffice ? emailOffice : ""

  interface FormData {
    isSendEmailReplyCopy: boolean
    emailToAddress: string[]
    emailReplyAddress: string | undefined
    message: string | undefined
  }

  const schema = object().shape<FormData>({
    isSendEmailReplyCopy: boolean(),
    emailToAddress: array()
      .of(string().email())
      .required(t("errorMessages:emailReceiverMissing"))
      .min(1)
      .max(20) as any,
    emailReplyAddress: string().email(
      t("errorMessages:emailReplyAddressMissing")
    ),
    message: string(),
    // message: string().max(10),
  })

  interface FormikData {
    emailToAddress: string[]
    emailReplyAddress: string
    message: string
    isSendEmailReplyCopy: boolean
  }

  const formik = useFormik<FormikData>({
    initialValues: {
      isSendEmailReplyCopy: true,
      emailToAddress: [],
      emailReplyAddress,
      message: "",
    },
    validateOnMount: false,
    validateOnChange: true,
    validateOnBlur: true,
    validationSchema: schema,
    onSubmit: (values) => {
      setLoading(true)
      const emailToAddress = values.emailToAddress.join(",")
      mediateMaterialMutation({
        variables: {
          input: {
            ...values,
            emailToAddress,
            ...props.attachments,
            orderIds: props.products,
            proofSetting: {
              ...props.proofSetting
            }
            
          },
        },
      })
        .then((response) => {
          setLoading(false)

          if (response.errors) {
            formik.setStatus(response.errors.map((e) => e.message).join(", "))
            return
          }

          if (!response.data) {
            formik.setStatus(t("errorMessages:unknowError"))
            return
          }

          if (response.data.messagingOrdersMaterials.isError === true) {
            formik.setStatus(
              response.data.messagingOrdersMaterials.errorReason ??
                t("errorMessages:unknowError")
            )
            return
          }

          formik.setStatus(
            t("proof:success", {
              emails: formik.values.emailToAddress.join(", "),
            })
          )
        })
        .catch((err) => {
          setLoading(false)
          formik.setStatus(err.toString())
        })
    },
  })

  return (
    <form onSubmit={formik.handleSubmit} className={styles.form}>
      <div className={styles.fields}>
        <div className={styles.emails}>
          <FieldWrapperFormik
            value={formik.values.emailToAddress}
            onChange={(values: any) => {
              formik.setFieldValue("emailToAddress", values)
            }}
            errors={
              formik.touched.emailToAddress && formik.errors.emailToAddress
            }
            label={t("emailReceiver")}
            placeholder={t("emailReceiverPlaceholder")}
            component={FieldReactMutliEmails}
            name="emailToAddress"
          />

          <FieldWrapperFormik
            value={formik.values.emailReplyAddress}
            onChange={(e: any) => {
              formik.setFieldValue("emailReplyAddress", e.target.value)
            }}
            errors={
              formik.touched.emailReplyAddress &&
              formik.errors.emailReplyAddress
            }
            label={t("replyAddress")}
            placeholder={t("replyAddressPlaceholder")}
            type="email"
            component={TextInput}
            className={styles.replyAddressField}
            name="emailReplyAddress"
          />
        </div>
        <div className={styles.textAreaContainer}>
          <FieldWrapperFormik
            value={formik.values.message}
            onChange={(e: any) => {
              formik.setFieldValue("message", e.target.value)
            }}
            errors={formik.touched.message && formik.errors.message}
            label={t("message")}
            placeholder={t("messagePlaceholder")}
            className={styles.message}
            name="message"
          >
            <textarea />
          </FieldWrapperFormik>
        </div>
      </div>

      <div className={styles.buttonContainer}>
        <FieldWrapperFormik
          value={formik.values.isSendEmailReplyCopy}
          onChange={(e: any) => {
            formik.setFieldValue("isSendEmailReplyCopy", e.target.checked)
          }}
          errors={
            formik.touched.isSendEmailReplyCopy &&
            formik.errors.isSendEmailReplyCopy
          }
          label={t("copy")}
          showFieldWrapperLabel={false}
          className={styles.replyCopy}
          type="checkbox"
          name="isSendEmailReplyCopy"
          component={CheckboxFieldFormik}
        />
        <Button
          variant="primary"
          color="primary"
          type="submit"
          disabled={props.products.length === 0}
          isWorking={isLoading}
          className={styles.submitBtn}
        >
          {t("common:send")}
        </Button>
      </div>

      {formik.status && formik.status.length > 0 ? (
        <div style={{color: '#1EE498'}}>
          {formik.status}
          {/* <ErrorText></ErrorText> */}
        </div>
      ) : null}
    </form>
  )
}

interface MediateToCustomerFormProps {
  products: any
  attachments: AttachmentsObj
  proofSetting: IProofSetting
}

export default MediateToCustomerForm
