import * as React from "react"
import * as styles from "./styles.scss"
import * as cx from "classnames"
import Button from "components/Button"
import Slider from "react-rangeslider"
import "react-rangeslider/lib/index.css"
import AvatarEditor from "react-avatar-editor"
import detectPointerEvents from "./detectPointerEvents"
import { useTranslation } from "react-i18next"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

export const ImageEditor: React.FC<{
  imageUrl: string
  onSave?: any
  onClose?: any
}> = (props) => {
  const { imageUrl, onSave, onClose } = props
  const isMobile = false
  const [rotate, setRotate] = React.useState(0)
  const [scale, setScale] = React.useState(1)
  const [scaleSlider, setScaleSlider] = React.useState(1)
  const [imageUrlHelper, setImageHelper] = React.useState(imageUrl)
  const initEditorImageWidth = isMobile ? 320 : 320 * 2
  const initEditorImageHeight = isMobile ? 180 : 180 * 2
  const [editorImageWidth, setEditorImageWidth] = React.useState(
    initEditorImageWidth
  )
  const [editorImageHeight, setEditorImageHeight] = React.useState(
    initEditorImageHeight
  )
  const {t} = useTranslation()
  const editorContainerWidth = initEditorImageWidth
  const editorContainerHeight = initEditorImageHeight

  const zoomIn = () => {
    let newScale = scale - 0.1
    if (newScale <= 1) {
      newScale = 1
    }
    setScale(newScale)
  }
  const zoomOut = () => {
    let newScale = scale + 0.1
    if (newScale >= 4) {
      newScale = 4
    }
    setScale(newScale)
  }
  const rotateLeft = () => {
    let newRotate = rotate + 90
    if (newRotate === 360) {
      newRotate = 0
    }
    setRotate(newRotate)
  }
  const rotateRight = () => {
    let newRotate = rotate - 90
    if (newRotate === -360) {
      newRotate = 0
    }
    setRotate(newRotate)
  }
  const refContainer = React.useRef(null)
  const onSaveImage = (event?: any) => {
    if (event && event.preventDefault) {
      event.preventDefault()
    }

    if (
      refContainer !== undefined &&
      refContainer !== null &&
      refContainer.current !== undefined &&
      refContainer.current !== null
    ) {
      const canvasScaled = (refContainer.current as any).getImageScaledToCanvas()
      canvasScaled.toBlob((blob: any) => {
        if (onSave) {
          onSave(blob)
        }
      })
    }
    return false
  }

  const onSliderChange = (value: any) => {
    setScale(value)
    setScaleSlider(value)
  }


  return (
    <div className={styles.wrapCss}>
      <div className={styles.editorWrapperCss}>
        <div className={styles.editorTitleCss}>{t("editOrder:editImage")}</div>
        <div
          className={styles.editorCss}
          style={{
            width: `${editorContainerWidth}px`,
            height: `${editorContainerHeight}px`,
          }}
        >
          <ImageCropOverlay />
          <div style={{ pointerEvents: "auto" }}>
            <AvatarEditor
              ref={refContainer}
              image={imageUrlHelper}
              width={editorImageWidth}
              height={editorImageHeight}
              border={0}
              color={[255, 255, 255, 0.6]}
              scale={scale}
              rotate={rotate}
            />
          </div>
        </div>

        <ImageToolbar
          onSliderChange={onSliderChange}
          onZoomIn={zoomIn}
          onZoomOut={zoomOut}
          onRotateLeft={rotateLeft}
          onRotateRight={rotateRight}
          sliderValue={scaleSlider}
        />
      </div>

      <BottomButtons onAbort={onClose} onSave={onSaveImage} />
    </div>
  )
}

const ImageCropOverlay: React.FC<{}> = (props) => {
  const bgSmall = `transparent
      linear-gradient(
        180deg,
        #ffffff00 0%,
        #ffffff00 15%,
        #ffffff4d 35%,
        #ffffffd9 60%,
        #ffffff 100%
      )
      0% 0% no-repeat padding-box`
  const bgBig = `transparent
      linear-gradient(
        180deg,
        #ffffff00 0%,
        #ffffff00 32%,
        #ffffff4d 74%,
        #ffffffd9 90%,
        #ffffff 100%
      )
      0% 0% no-repeat padding-box`
  const pointerEventsEnabled = detectPointerEvents.hasApi === true

  const { t } = useTranslation("editOrder")
  return (
    <div
      className={styles.editorBottomLabelCss}
      style={{
        height: pointerEventsEnabled ? "100%" : "80px",
        background: pointerEventsEnabled ? bgBig : bgSmall,
      }}
      onClick={(e) => e.preventDefault()}
    >
      <FontAwesomeIcon
        icon={"arrows-alt"}
        className={styles.editorBottomIconCss}
      />
      {/* <FaArrowsAlt color={FontColor.ICON_DEFAULT} size={FontSize.SIZE_22} /> */}
      <span className={styles.editorBottomTextCss}>
        <span>{t("editOrder:changePosition")}</span>
      </span>
    </div>
  )
}

const ImageToolbar: React.FC<{
  onZoomIn: any
  onZoomOut: any
  onRotateLeft: any
  onRotateRight: any
  sliderValue: any
  onSliderChange: any
}> = (props) => {
 
  return (
    <div className={styles.toolbarwrapCss}>
      <div className={styles.buttonsCss}>
        <div className={styles.sliderCss}>
          <div className={styles.iconLeftCss} onClick={props.onZoomIn}>
            <FontAwesomeIcon
              icon={"image"}
              className={styles.iconImageSmallCss}
              size={"1x"}
            />
            {/* <FaImage color={FontColor.PRIMARY} size={FontSize.SIZE_14} /> */}
          </div>

          <div className={styles.sliderWrapCss}>
            <Slider
              // style={{ width: 115 }}

              min={1}
              max={5}
              step={0.1}
              value={props.sliderValue}
              tooltip={false}
              onChange={props.onSliderChange}
            />
          </div>

          <div className={styles.iconRightCss} onClick={props.onZoomOut}>
            <FontAwesomeIcon
              icon={"image"}
              className={styles.iconImageBigCss}
              size={"1x"}
            />
          </div>
        </div>

        <div className={styles.rotateWrapCss}>
          <div className={styles.rotateWrapItemCss}>&nbsp;</div>

          <div
            className={styles.rotateIconWrapperCss}
            onClick={props.onRotateLeft}
          >
            <FontAwesomeIcon icon={"undo"} className={styles.colorPrimary} />
            
          </div>

          <div
            className={styles.rotateIconWrapperAltCss}
            onClick={props.onRotateRight}
          >
            <FontAwesomeIcon
              icon={"undo"}
              className={styles.rotateIconAltCss}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

const BottomButtons: React.FC<{
  onAbort: () => void
  onSave: () => void
}> = (props) => {
  const { t } = useTranslation(["common"])
  return (
    <div className={styles.bottomsButtonsWrapper}>
      <Button variant={"secondary"} onClick={props.onAbort}>
        {t("common:cancel")}
      </Button>

      <Button variant={"primary"} onClick={props.onSave} id={"save-cropped-button"}>
        {t("common:save")}
      </Button>
    </div>
  )
}
