import * as React from "react"
import { useTranslation } from "react-i18next"
import ReactTable from "react-table-6"
import * as styles from "./styles.scss"
import Status from "components/Status"
import {
  GetCaseFolders_findCaseFoldersByCurrentCustomer_caseFolders,
  TouchCaseFolder,
  TouchCaseFolderVariables,
} from "schema"
import CaseFolderTableHeaderRow from "./CaseFolderTableHeaderRow"
import cx from "classnames"
import ConfirmationModal from "components/ConfirmationModal"
import { useApolloClient, useMutation } from "react-apollo-hooks"
import TOUCH_CASE_FOLDER from "graphql/mutations/touchCaseFolder"
import { useHistory, useParams } from "react-router-dom"
import { AppContext } from "App"

interface CaseFolderTableProps {
  selectedCaseFolderId?: string
  caseFolders: GetCaseFolders_findCaseFoldersByCurrentCustomer_caseFolders[]
  onRowClick: (newId: React.ReactText) => void
  deleteCaseFolder: any
  onDeleteClick: any
  afterDeletedCaseFolder: any
  openCreateCaseFolderForm: () => void
  isCreateMode: boolean
}

const CaseFolderTable: React.FC<CaseFolderTableProps> = (props) => {
  const { t } = useTranslation(["dashboard", "statuses", "common"])
  const [isOpen, setIsOpen] = React.useState(false)
  const [selectedCaseId, setSelectedCaseId] = React.useState("")
  const client = useApolloClient()
  const history = useHistory()
  // const params = useParams<{ caseFolderId: string }>()
  const { currentUserSession, isShowModeShow } = React.useContext(AppContext)
  const isMediaUser = currentUserSession.user?.isMediaUser

  const triggerTouchCaseFolder = useMutation<
    TouchCaseFolder,
    TouchCaseFolderVariables
  >(TOUCH_CASE_FOLDER, {
    client,
    variables: { id: "" },
    context: {
      skipErrorModal: true,
    },
  })

  React.useEffect(() => {
    if (
      history.location.pathname === "/dashboard" &&
      props.caseFolders[0]?.id != undefined && 
      !isShowModeShow
    ) {
      history.push(`/dashboard/c/${props.caseFolders[0].id}`)
    }
  }, [history.location.pathname, isShowModeShow])

  const onClickTouchCaseFolder = (caseFolderId: string) => {
    triggerTouchCaseFolder({
      variables: {
        id: caseFolderId,
      },
    })
  }
  const onDeleteBtnClick = (caseId: string) => {
    setIsOpen(true)
    setSelectedCaseId(caseId)
    props.onDeleteClick(caseId)
  }
  const onClose = () => {
    setIsOpen(false)
  }
  const handleDelete = () => {
    props.deleteCaseFolder({
      variables: { input: { caseFolderId: selectedCaseId } },
    })
    setIsOpen(false)
  }
  const handleOnClick = (caseFolderId: string) => {
    onClickTouchCaseFolder(caseFolderId)
    if (props.onRowClick) {
      props.onRowClick(caseFolderId)
    }
  }

  return (
    <div className={styles.tableContainer}>
      <CaseFolderTableHeaderRow
        openCreateCaseFolderForm={props.openCreateCaseFolderForm}
        isCreateMode={props.isCreateMode}
      />
      <ConfirmationModal
        isOpen={isOpen}
        onRequestClose={onClose}
        okButtonText={t("delete")}
        text={t("deleteModalText")}
        title={t("delete")}
        onOKClick={handleDelete}
      />
      <ReactTable
        className={cx("-striped", styles.caseFolderTable)}
        data={props.caseFolders}
        getTrProps={(state: any, rowInfo: any) =>
          rowInfo && rowInfo.original.id === props.selectedCaseFolderId
            ? { className: styles.selectedRow, "data-selected": true }
            : {}
        }
        columns={[
          {
            Header: t("common:caseFolder"),
            accessor: "name",
            width: 150,
          },
          {
            Header: t("orders"),
            accessor: "summary.countOrders",
            style: {
              display: "flex",
              justifyContent: "center",
            },
          },
          {
            Header: t("common:caseFolderNumber"),
            accessor: "propertiesFuneralStandard.externalId",
            show: !isMediaUser,
          },
          {
            Header: t("caseWorker"),
            Cell: (_props) => {
              return <div>{_props.original.customer.name}</div>
            },
            show: isMediaUser,
          },
          {
            Header: t("status"),
            accessor: "summary",
            Cell: (_props) => (
              <Status variant={_props.value.mostCriticalColor}>
                {/* {t(`statuses:${props.value.mostCriticalStatus}`)} */}
              </Status>
            ),
            style: {
              display: "flex",
              justifyContent: "center",
            },
            width: 100,
          },
        ]}
        getTdProps={(state: any, rowInfo: any, column: any) => ({
          style: { cursor: "pointer" },
          onClick: (e: string, handleOriginal: any) => {
            if (column.id === "deleteRow") {
              onDeleteBtnClick(rowInfo.original.id)
            } else {
              handleOnClick(rowInfo.original.id)
            }
          },
        })}
        showPagination={false}
        noDataText={t("common:noResults")}
        pageSize={props.caseFolders.length}
        getTbodyProps={() => ({ className: styles.tbody })}
        getTrGroupProps={() => ({ className: styles.tRow })}
      />
    </div>
  )
}

export default CaseFolderTable
