import * as React from "react"
import * as styles from "./styles.scss"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

function AddRowButton(props: AddRowButtonProps) {
  return (
    <p className={styles.addRowButton} onClick={props.onClick}>
      <FontAwesomeIcon className={styles.icon} icon="plus-circle" /> Lägg till
      rad
    </p>
  )
}

interface AddRowButtonProps {
  onClick: () => void
}

export default AddRowButton
