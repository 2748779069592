import React from "react"
import styled from "styled-components"
import { faChevronDown } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const TitleWithDropDown: React.FC<{
  title: string
  listItem: JSX.Element
  className?: string
}> = (props) => {
  const ul_listRef = React.useRef<HTMLUListElement | null>(null)
  const [isDropDown, setDropDown] = React.useState(false)

  React.useEffect(() => {
    const onClickOutSide = (evt: any) => {
      if (ul_listRef.current && !ul_listRef.current.contains(evt.target)) {
        setDropDown(false)
      }
    }
    document.addEventListener("mousedown", onClickOutSide)
    return () => document.addEventListener("mousedown", onClickOutSide)
  }, [ul_listRef])

  const handleToggleDropDown = () => {
    setDropDown(!isDropDown)
  }
  return (
    <TitleWithDropDownStyle className={props.className}>
      <div className="titleContainer">
        <div className={"title"} onClick={handleToggleDropDown}>
          <h3>{props.title}</h3>
          <FontAwesomeIcon icon={faChevronDown} className={"icon"} />
        </div>
        <ul
            className={"dropdown"}
            style={isDropDown ? { display: "block" } : { display: "none" }}
            ref={ul_listRef}
          >
            {props.listItem}
            
          </ul>
      </div>
    </TitleWithDropDownStyle>
  )
}

export default TitleWithDropDown

const TitleWithDropDownStyle = styled.div`
  display: flex;
  justify-content: space-between;
  .titleContainer {
    position: relative;
    display: inline-block;
    .title {
      display: flex;
      align-items: center;
      cursor: pointer;
      .icon {
        color:  #1289a7;
      }
    }
    .dropdown {
      display: block;
      position: absolute;
      top: 25px;
      left: 40px;
      border: 1px solid $gray;
      border-radius: 3px;
      min-width: 160px;
      box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
      z-index: 1;
      padding: 15px 10px;
      background-color: white;
      width: 170px;
      li {
        display: flex;
        justify-content: space-between;
      }
    }
  }
`
