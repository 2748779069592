import * as React from "react"
import * as styles from "./styles.scss"

import * as cx from "classnames"
import GET_STAT_ORDERS from "graphql/queries/getStatOrders"
import { useApolloClient, useQuery } from "react-apollo-hooks"
import {
  GetStatOrders,
  GetStatOrdersVariables,
  CustomerScope,
  StatsOrderPeriods,
  StatsOrderSort,
} from "schema"
import Spinner from "components/Spinner"
import ErrorMessage, { ErrorText } from "components/ErrorMessage"

import { HoverPeriod, SelectedCustomer, SelectedProduct } from "customSchema.interface"
import Filters from "./Filters/index"
import DateFilter from "./DateFilter"
import TableWithData from "./StatisticTable"
import { useTranslation } from "react-i18next"


const StatisticsTab: React.FC<{}> = (props) => {
  const [selectedProducts, setSelectedProducts] = React.useState<
    SelectedProduct[]
  >([])
  
  const [periodScope, setPeriodScope] = React.useState(StatsOrderPeriods.CUSTOM)
  const [periodHover, setPeriodHover] = React.useState(HoverPeriod.LAST_MONTH)
  const [customPeriodDayLength, setCustomPeriodDayLength] = React.useState(7)
  const [customPeriodStart, setCustomPeriodStart] = React.useState(new Date())
  const [startIndex, setStartIndex] = React.useState(0)
  const [page, setPage] = React.useState(0)
  const [pageSize, setPageLength] = React.useState(10)
  const [sortField, setSortField] = React.useState<StatsOrderSort>(
    StatsOrderSort.CUSTOMER
  )
  const [sortDescending, setSortDescending] = React.useState<boolean>(false)
  const [selectedScope, setSelectedScope] = React.useState<CustomerScope>(
    CustomerScope.COMPANY
  )
  const [selectedCustomers, setSelectedCustomers] = React.useState<
    SelectedCustomer[]
  >([])

  const { loading, data, error, refetch } = useQuery<
    GetStatOrders,
    GetStatOrdersVariables
  >(GET_STAT_ORDERS, {
    variables: {
      input: {
        startIndex,
        pageLength: pageSize,
        customerScope:  selectedScope,
        periodScope,
        customPeriodStart:
          periodScope === StatsOrderPeriods.CUSTOM
            ? customPeriodStart.toISOString()
            : undefined,
        customPeriodDayLength:
          periodScope === StatsOrderPeriods.CUSTOM
            ? customPeriodDayLength
            : undefined,
        sortField,
        sortDescending,
        productIds: selectedProducts.map((prod:any) => (prod.value)),
          
        customerId:
          selectedCustomers.length === 0
            ? undefined
            : selectedCustomers[0].value,
      },
    },
  })

  // React.useEffect(() => {
  //   refetch()
  // }, [
  //   selectedScope,
  //   selectedCustomers,
  //   periodScope,
  //   sortField,
  //   sortDescending,
  //   selectedProducts,
  //   startIndex,
  // ])



  // const changePeriodScope = (newValue: StatsOrderPeriods) => {
  //   setPeriodScope(newValue)
  // }
  // const changeDayLength = (newValue: number) => {
  //   setCustomPeriodDayLength(newValue)
  // }
  // const onChangeProducts = (newValue: SelectedProduct[]) => {
  //   console.log(newValue)
  //   // setSelectedProducts(newValue)
  // }
  // const changeCustomPeriodStart = (newValue: Date) => {
  //   setCustomPeriodStart(newValue)
  // }
  const changeStartIndex = (newValue: number) => {
    setStartIndex(newValue)
  }
  const changePageLength = (newValue: number) => {
    setPageLength(newValue)
  }
  const changeSortDescending = (newValue: boolean) => {
    console.log(newValue)
    setSortDescending(newValue)
  }
  const changeSortField = (newValue: StatsOrderSort) => {
    setSortField(newValue)
  }
  const onSelectedScope = (newValue: CustomerScope) => {
    setSelectedScope(newValue)
    setSelectedCustomers([])
  }
  const onChangeSelectedCustomers = (newValue: SelectedCustomer) => {
    setSelectedCustomers([newValue])
  }

  return (
    <div className={styles.container}>
      {loading ? (
        <div className={styles.loadingContainer}>
          <Spinner />
        </div>
      ) : null}
      {error ? <ErrorMessage message={error.message} /> : null} 
      {data ? (
        <div>
          <div className={styles.topFiltersContainer}>
            <DateFilter
              // periodScope={periodScope}
              // customPeriodDayLength={customPeriodDayLength}
              // customPeriodStart={customPeriodStart}
              // changeDuration={changePeriodScope}
              // changeDayLength={changeDayLength}
              // changeCustomPeriodStart={changeCustomPeriodStart}
              setCustomPeriodStart={setCustomPeriodStart}
              setCustomPeriodDayLength={setCustomPeriodDayLength}
              periodHover={periodHover}
              setPeriodHover={setPeriodHover}
            />

            <div className={styles.topNumbersContainer}>
              <SummaryProducts
                productCount={data?.statsOrders?.summery.countOrders}
                casesCount={data?.statsOrders?.summery.countCaseFolders}
                productPerCaseRatio={
                  data?.statsOrders?.summery.avarageOrdersPerCaseFolder
                }
              />

              <SummaryMoney
                revenue={
                  data?.statsOrders?.summery?.price?.endCustomer?.pricePay
                }
                profit={
                  data?.statsOrders?.summery?.price?.customer.priceEarnings
                }
              />
            </div>
          </div>

          <Filters
            selectedProducts={selectedProducts}
            setSelectedProducts={setSelectedProducts}
            // onChangeProducts={onChangeProducts}
            selectedScope={selectedScope}
            onSelectedScope={onSelectedScope}
            selectedCustomers={selectedCustomers}
            onChangeSelectedCustomers={onChangeSelectedCustomers}
            
          />

          {loading === false &&
          data !== undefined &&
          data?.statsOrders !== undefined &&
          data?.statsOrders?.count !== undefined &&
          data?.statsOrders?.caseFolders !== undefined ? (
            <TableWithData
             caseFolders={data.statsOrders.caseFolders}
              count={data?.statsOrders?.summery?.countCaseFolders}
              loading={loading}
              page={page}
              setPage={setPage}
              refetch={refetch}
              startIndex={startIndex}
              pageSize={pageSize}
              sortDescending={sortDescending}
              setSortDescending={setSortDescending}
              sortField={sortField}
              changeStartIndex={changeStartIndex}
              changePageLength={changePageLength}
              changeSortDescending={changeSortDescending}
              changeSortField={changeSortField}
            />
          ) : null}
        </div>
      ) : null}
    </div>
  )
}

const SummaryProducts: React.FC<{
  productCount: number
  casesCount: number
  productPerCaseRatio: number
}> = (props) => {
  const { t } = useTranslation(["agencySettings:overviewProducts"])
  return (
    <div className={styles.row} style={{ marginRight: 20 }}>
      <div className={styles.label}>
        {t("agencySettings:StatisticsTab_overview")}
        <br />
        <b>{t("agencySettings:StatisticsTab_products")}</b>
      </div>

      <div className={styles.countButtonsWrapper}>
        <div className={cx(styles.countButton)}>
          <div className={cx(styles.countNumber)}>{props.productCount}</div>
          <div>
            <b>{t("agencySettings:totalProducts")}</b>
          </div>
        </div>

        <div className={cx(styles.countButton)}>
          <div className={cx(styles.countNumber)}>{props.casesCount}</div>
          <div>
            <b>{t("agencySettings:NumbersOfFolders")}</b>
          </div>
        </div>

        <div className={cx(styles.countButton)}>
          <div className={cx(styles.countNumber)}>
            {props.productPerCaseRatio}
          </div>
          <div>
            <b>{t("agencySettings:ProdukterPerFolder")}</b>
          </div>
        </div>
      </div>
    </div>
  )
}

const SummaryMoney: React.FC<{
  revenue: number
  profit: number
}> = (props) => {
  const {t} = useTranslation(["agencySettings"])
   return (
    <div className={styles.row}>
      <div className={styles.label}>
        Översikt <br />
        <b> Ekonomi</b>
      </div>

      <div className={styles.countButtonsWrapper}>
        <div className={cx(styles.countButton, styles.countButtonOrange)}>
          <div className={cx(styles.countNumber, styles.countNumberOrange)}>
            {Number(props.revenue).toFixed(0)} kr
          </div>
          <div>
            <b>{t("revenue")}</b>
          </div>
        </div>

        <div className={cx(styles.countButton, styles.countButtonOrange)}>
          <div className={cx(styles.countNumber, styles.countNumberOrange)}>
            {Number(props.profit).toFixed(0)} kr
          </div>
          <div>
            <b>{t("profit")}</b>
          </div>
        </div>
      </div>
    </div>
  )
}

export default StatisticsTab

{
  /* <BottomSummary
            revenue={data?.statsOrders?.summery?.price?.endCustomer?.pricePay}
            profit={data?.statsOrders?.summery?.price?.customer.priceEarnings}
          /> */
}

// const BottomSummary: React.FC<{
//   revenue: number | undefined
//   profit: number | undefined
// }> = (props) => {
//   return (
//     <div className={styles.row} style={{ marginRight: 20, marginTop: 50 }}>
//       <div className={styles.label}>
//         <b>Sammanfattning/Total:</b>
//       </div>

//       <div className={styles.countButtonsWrapper}>
//         <div className={cx(styles.countButton, styles.countButtonOrange)}>
//           <div className={cx(styles.countNumber, styles.countNumberOrange)}>
//             {Number(props.revenue).toFixed(2)}:-
//           </div>
//           <div>
//             <b>Omsättning</b>
//           </div>
//         </div>

//         <div className={cx(styles.countButton, styles.countButtonOrange)}>
//           <div className={cx(styles.countNumber, styles.countNumberOrange)}>
//             {Number(props.profit).toFixed(2)}:-
//           </div>
//           <div>
//             <b>Vinst</b>
//           </div>
//         </div>
//       </div>
//     </div>
//   )
// }
