import * as React from "react"
import * as styles from "./styles.scss"
import NewsPreview from "components/NewsPreview"
import Whiteframe from "components/Whiteframe"
import NewsProvider from "components/NewsPreview/NewsProvider"
import DashboardNewsHeaderRow from "./DashboardNewsHeaderRow"
import NoNews from "./NoNews"

class DashboardNews extends React.PureComponent {
  render() {
    return (
      <Whiteframe className={styles.container}>
        <DashboardNewsHeaderRow />
        <div className={styles.containerNewsList}>
          <NewsProvider
            render={newsItems => {
              return newsItems.length > 0 ? (
                newsItems.map(newsItem => (
                  <NewsPreview key={newsItem.id} {...newsItem}  />
                ))
              ) : (
                <NoNews />
              )
            }}
          />
        </div>
        {/* <DataTest /> */}
      </Whiteframe>
    )
  }
}


export default DashboardNews
