import produce from "immer"
import * as styles from "./styles.scss"
import * as React from "react"
import { useTranslation } from "react-i18next"
import isEmailFn from "./isEmail"

export interface ReactMultiEmailProps {
  emails?: string[]
  onChange?: (emails: string[]) => void
  noClass?: boolean
  validateEmail?: (email: string) => boolean
  style?: object
  getLabel: (
    email: string,
    index: number,
    removeEmail: (index: number) => void
  ) => React.ReactChild
  className?: string
  classNameLabels?: string
  placeholder?: string | undefined
}

export interface ReactMultiEmailState {
  focused?: boolean
  propsEmails?: string[]
  inputValue?: string
  error: string | undefined
}

const ReactMultiEmail: React.FC<ReactMultiEmailProps> = props => {
  const {
    style,
    getLabel,
    className = "",
    noClass,
    placeholder,
    classNameLabels,
  } = props
  const [stateEmails, setStateEmails] = React.useState<string[]>(
    props.emails ? props.emails : []
  )
  const [state, setState] = React.useState<ReactMultiEmailState>({
    focused: false,
    inputValue: "",
    error: undefined,
  })
  const emailInputRef = React.useRef<HTMLInputElement | null>()
  const [t] = useTranslation("errorMessages")
  const classNameValue = `${className} ${noClass ? "" : "react-multi-email"} ${
    state.focused ? "focused" : ""
  } ${state.inputValue === "" && stateEmails.length === 0 ? "empty" : ""}`

  const findEmailAddress = (value: string, isEnter?: boolean) => {
    const { validateEmail } = props
    const validEmails: string[] = []
    let inputValue: string = ""
    const re = /[ ,;]/g
    const isEmail = validateEmail || isEmailFn
    let error: string | undefined

    const addEmails = (email: string) => {
      const emails: string[] = stateEmails
      for (let i = 0, l = emails.length; i < l; i++) {
        if (emails[i] === email) {
          return false
        }
      }
      validEmails.push(email)
      return true
    }

    if (value !== "") {
      if (re.test(value)) {
        const splitData = value.split(re).filter(n => {
          return n !== "" && n !== undefined && n !== null
        })

        const arr = [...splitData]

        do {
          if (isEmail("" + arr[0])) {
            addEmails("" + arr.shift())
          } else {
            if (arr.length === 1) {
              inputValue = "" + arr.shift()
            } else {
              arr.shift()
            }
          }
        } while (arr.length)
      } else {
        if (isEnter) {
          if (isEmail(value)) {
            addEmails(value)
          } else {
            inputValue = value
            error = t("invalidEmail")
          }
        } else {
          inputValue = value
        }
      }
    }

    setStateEmails([...stateEmails, ...validEmails])
    setState(
      produce(state, draft => {
        draft.inputValue = inputValue
        draft.error = error
      })
    )
  }

  const onChangeInputValue = (value: string) => {
    findEmailAddress(value)
  }

  const removeEmail = (index: number) => {
    if (stateEmails[index]) {
      setStateEmails([
        ...stateEmails.slice(0, index),
        ...stateEmails.slice(index + 1),
      ])
    }
  }

  const handleOnKeydown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    switch (e.which) {
      case 13:
      case 9:
        e.preventDefault()
        break
      case 8:
        if (!e.currentTarget.value) {
          removeEmail(stateEmails.length - 1)
        }
        break
      default:
    }
  }

  const handleOnKeyup = (e: React.KeyboardEvent<HTMLInputElement>) => {
    switch (e.which) {
      case 13:
      case 9:
        findEmailAddress(e.currentTarget.value, true)
        break
      default:
    }
  }

  const handleOnChange = (e: React.SyntheticEvent<HTMLInputElement>) => {
    onChangeInputValue(e.currentTarget.value)
  }

  const handleOnBlur = (e: React.SyntheticEvent<HTMLInputElement>) => {
    setState(
      produce(state, draft => {
        draft.focused = false
      })
    )
    findEmailAddress(e.currentTarget.value, true)
  }

  const handleOnFocus = () => {
    setState(
      produce(state, draft => {
        draft.focused = true
      })
    )
  }

  React.useEffect(() => {
    if (props.onChange) {
      props.onChange(stateEmails)
    }
  }, [stateEmails])

  return (
    <div
      className={classNameValue}
      style={style}
      onClick={() => {
        if (emailInputRef.current) {
          emailInputRef.current.focus()
        }
      }}
    >
      {stateEmails && stateEmails.length > 0 ? (
        <div className={classNameLabels}>
          {stateEmails.map((value, index) => {
            const label = getLabel(value, index, removeEmail)
            return <div key={value}>{label}</div>
          })}
        </div>
      ) : null}

      <input
        ref={ref => (emailInputRef.current = ref)}
        type="text"
        value={state.inputValue}
        placeholder={placeholder}
        onFocus={handleOnFocus}
        onBlur={handleOnBlur}
        onChange={handleOnChange}
        onKeyDown={handleOnKeydown}
        onKeyUp={handleOnKeyup}
      />

      {state.error && state.error.length > 0 ? (
        <div className={styles.errortext}>{state.error}</div>
      ) : null}
    </div>
  )
}

export default ReactMultiEmail
