import * as React from "react"
import { useState, useContext } from "react"
import * as styles from "./styles.scss"
import AppRoutes from "routes/AppRoutes"
import TabRow from "./TabRow"
import TabContainer from "./TabContainer"
import { withRouter, RouteComponentProps } from "react-router-dom"
import useInvokeAction from "components/InvokeAction/useInvokeAction"
import InvokeActionResultModal from "./InvokeActionResultModal"
import {
  GetAdminActions,
  GetAdminActionsVariables,
  GetAdminActions_adminActions,
  GetAdminCaseFolders_findCaseFoldersByConditions_caseFolders_documents_orders_mediaOrder_status,
} from "schema"
import { useMutation, useApolloClient } from "react-apollo-hooks"
import LOGIN_AS_CUSTOMER from "graphql/mutations/loginAsCustomer"
import useQueriesToRefetchOnTokenChange from "./useQueriesToRefetchOnTokenChange"
import Spinner from "components/Spinner"
import { AppContext } from "App"
import { useLazyQuery } from "@apollo/react-hooks"
import GET_ADMIN_ACTIONS from "graphql/queries/getAdminActions"
import { SessionStorageKeys } from "environmentUtils"

export const SupportViewContext = React.createContext(
  {} as SupportViewContextValues
)
export type AvailableAction = {
  name: string
  actionId: string
  menuPath: string
  documentId: string
  parameters: any[]
  confirmMessage: string | null
}

function SupportView(props: RouteComponentProps) {
  const [openTabs, setOpenTabs] = useState([] as OpenTab[])
  const [queriesLoading, setQueriesLoading] = useState(false)
  const [selectedTab, setSelectedTab] = useState(undefined as any)
  const [selectedDetailOrder, setSelectedDetailOrder] = useState(
    undefined as any
  )
  const {
    // availableActions,
    dispatch,
    isErrorModal,
    resultModalText,
    resultModalIsOpen,
    onCloseModal,
  } = useInvokeAction({
    orderId: selectedTab ? selectedTab.id : undefined,
  })

  const { showSupportView } = useContext(AppContext)
  const loginAsCustomer = useMutation(LOGIN_AS_CUSTOMER)
  const client = useApolloClient()
  const refetchQueriesOnTokenChange = useQueriesToRefetchOnTokenChange(client)

  const [getAdminActionsQuery, getAdminActionsQueryOptions] = useLazyQuery<
    GetAdminActions,
    GetAdminActionsVariables
  >(GET_ADMIN_ACTIONS, { client })

  const availableActions = getAdminActionsQueryOptions.data?.adminActions ?? []

  // const getDispatchObject = (actionId: string): InvokeActionDispatchObj => ({
  //   type: "ORDER",
  //   id: selectedTab.id,
  //   actionId,
  // })

  const invokeAction = (
    actionId: string,
    tab: OpenTab,
    refetch?: () => void
  ) => {
    // console.log('invoke?', actionId, tab)
    // if (selectedTab){
    //   dispatch(getDispatchObject(actionId), refetch)
    // }
    dispatch(
      {
        input: {
          action: {
            actionId,
            customerId: tab.customerId,
            orderId: tab.orderId,
            documentId: tab.documentId,
          },
        },
      },
      refetch
    )
  }

  const notAlreadyOpen = (tabId: string) =>
    openTabs.map((tab) => tab.orderId).indexOf(tabId) === -1

  const getRedirectUrl = (tab: OpenTab) => {
    switch (tab.type) {
      case "order":
        return `/editOrder/c/${tab.caseFolderId}/o/${tab.orderId}`
      case "customer":
        return "/archive"
      default:
        return "/dashboard"
    }
  }

  const selectTab = (tab: OpenTab) => {
    if (selectedTab && selectedTab.id === tab.orderId) return

    setQueriesLoading(true)
    setSelectedTab(tab)
    sessionStorage.setItem(SessionStorageKeys.AUTH_TOKEN, tab.authToken!)
    refetchQueriesOnTokenChange().then(() => {
      setQueriesLoading(false)
      props.history.push(getRedirectUrl(tab))
    })
  }

  const updateOpenTabsAndSelectTab = (newTab: OpenTab) => {
    setOpenTabs([...openTabs, newTab])
    selectTab(newTab)
  }

  const login = (tab: OpenTab, tempSessionToken: string) =>
    loginAsCustomer({ variables: { credential: { tempSessionToken } } })
      .then((res: any) => {
        const authToken = res.data.loginByCredential.authorizationToken
        const newTab = { ...tab, authToken }
        updateOpenTabsAndSelectTab(newTab)
      })
      .catch((err) => console.error("Error while thrying to login: ", err))

  const getLoggedInCustomerAuthToken = (customerId: string) => {
    const loggedInCustomer = openTabs.find(
      (openOrder) => openOrder.customerId === customerId
    )
    return loggedInCustomer ? loggedInCustomer.authToken : undefined
  }

  const openNewTab = (tab: OpenTab, tempSessionToken: string) => {
    const authToken = getLoggedInCustomerAuthToken(tab.customerId)
    return authToken
      ? updateOpenTabsAndSelectTab({ ...tab, authToken })
      : login(tab, tempSessionToken)
  }

  const selectExistingTab = (tabId: string) =>
    selectTab(openTabs.find((tab) => tab.orderId === tabId)!)

  const openTab = (tab: OpenTab, tempSessionToken: string) =>
    notAlreadyOpen(tab.orderId)
      ? openNewTab(tab, tempSessionToken)
      : selectExistingTab(tab.orderId)

  const closeTab = (tabId: string) => {
    setOpenTabs(openTabs.filter((tab) => tab.orderId !== tabId))

    if (selectedTab && selectedTab.id === tabId) setSelectedTab(undefined)
    if (selectedDetailOrder && selectedDetailOrder.id === tabId)
      setSelectedDetailOrder(undefined)
  }

  const showOrderDetails = (order: OpenTab) => {
    setSelectedDetailOrder(order)
    getAdminActionsQuery({
      variables: {
        orderId: order.orderId,
        customerId: order.customerId,
      },
    })
  }

  return (
    <SupportViewContext.Provider
      value={{
        availableActions,
        openTab: openTab,
        closeTab: closeTab,
        onTabClick: selectTab,
        onInvokeAction: invokeAction,
        openTabs: openTabs,
        selectedOpenTab: selectedTab,
        selectedDetailOrder,
        openOrderDetails: showOrderDetails,
      }}
    >
      <div className={styles.supportContainer}>
        <aside
          className={styles.tabContainer}
          style={{ display: showSupportView ? "block" : "none" }}
        >
          <TabContainer />
        </aside>
        <div className={styles.mainContainer}>
          <TabRow openTabs={openTabs} />
          {queriesLoading ? (
            <Spinner />
          ) : (
            <div className={styles.mainWrapper}>
              <AppRoutes />
            </div>
          )}
        </div>
        <InvokeActionResultModal
          isOpen={resultModalIsOpen}
          onRequestClose={onCloseModal}
          text={resultModalText}
          isError={isErrorModal}
        />
      </div>
    </SupportViewContext.Provider>
  )
}

export interface OpenTab {
  caseFolderId: string
  documentId: string
  orderId: string
  customerId: string
  type: TabType
  customerFriendlyId: string
  status?: GetAdminCaseFolders_findCaseFoldersByConditions_caseFolders_documents_orders_mediaOrder_status
  authToken?: string
}

interface SupportViewContextValues {
  openTabs: OpenTab[]
  selectedOpenTab?: OpenTab
  selectedDetailOrder?: OpenTab
  availableActions?: GetAdminActions_adminActions[]
  openTab: (tab: OpenTab, tempSessionToken: string) => void
  closeTab: (tabId: string) => void
  onTabClick: (tab: OpenTab) => void
  onInvokeAction: (actionId: string, tab: OpenTab, refetch?: () => void) => void
  openOrderDetails: (order: OpenTab) => void
}

type TabType = "order" | "customer"

export default withRouter(SupportView)
