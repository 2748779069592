import * as React from "react"
import { GetPackages_orderInitiatorPackagesByCurrentUser_items } from "schema"
import PackageMediaItem from "components/PackageMediaItem"

class MediaItem extends React.Component<MediaItemProps> {
  handleDeleteOrder = () => this.props.onDeleteOrder(this.props.index)

  render() {
    const { mediaItem } = this.props
    const item = {
      name: mediaItem.media.friendlyName,
      documentType:
        mediaItem.documentFormat.documentType.name,
    }


    return (
      <PackageMediaItem
        item={item}
        onDeleteClick={this.handleDeleteOrder}
        deleteConfirmation={true}
      />
    )
  }
}

interface MediaItemProps {
  mediaItem: GetPackages_orderInitiatorPackagesByCurrentUser_items
  index: number
  onDeleteOrder: (index: number) => void
}

export default MediaItem
