import * as React from "react"
import * as styles from "./styles.scss"
import { PublishOrderContext } from "../../.."
import styled from "styled-components"
import { every } from "lodash"
import { AppContext } from "App"

const ConversationInput: React.FC<{
  name: string
  placeholder: any | string
  toggleOpen: () => void
  value: string
  setValue: any
  codeLate?: string
}> = (props) => {
  const { publishOrder, dispatch } = React.useContext(PublishOrderContext)
  const isSupport = React.useContext(AppContext).currentUserSession.user
    ?.isSupportUser

  const handleClick = () => {
    dispatch({
      type: "updateValue",
      payload: {
        id: publishOrder.order.id,
        name: props.name,
        newValue: props.value,
      },
    })
    props.toggleOpen()
  }

  const handleCodeLateChange = () => {
    if (props.value === props.codeLate) {
      dispatch({
        type: "updateValue",
        payload: {
          id: publishOrder.order.id,
          name: props.name,
          newValue: props.value,
        },
      })
      props.toggleOpen()
    }
  }

  const handleMediaCustomerSubriberNumberChange = () => {
    props.toggleOpen()
  }

  React.useEffect(() => {
    if (props.name === "codeLate") {
      handleCodeLateChange()
    }

    if (props.name === "mediaCustomerSubriberNumber") {
      if (props.value.length !== 0) {
        dispatch({
          type: "updateValue",
          payload: {
            id: publishOrder.order.id,
            name: props.name,
            newValue: props.value,
          },
        })
      }
    }
  }, [props.value.length])

  function textAreaAdjust(element:any) {
    console.log(element)
    element.style.height = "1px";
    element.style.height = (25+element.scrollHeight)+"px";
  }

  return (
    <MessageBtn>
      {props.name === "codeLate" && (
        <div className={"input"}>
          <input
            type="text"
            placeholder={props.placeholder}
            value={props.value}
            onChange={(e) => props.setValue(e.target.value)}
          />
          {isSupport && props.codeLate && (
            <span className={"codelate"}>{props.codeLate}</span>
          )}
        </div>
      )}

      {props.name === "mediaCustomerSubriberNumber" && (
        <input
          type="text"
          onBlur={handleClick}
          placeholder={props.placeholder}
          value={props.value}
          onChange={(e) => props.setValue(e.target.value)}
        />
      )}
      {props.name !== "mediaCustomerSubriberNumber" &&
        props.name !== "codeLate" &&
        props.name !== "messageToMedia" && (
          <div className={"input"}>
            <input
              type="text"
              onBlur={handleMediaCustomerSubriberNumberChange}
              placeholder={props.placeholder}
              value={props.value}
              onChange={(e) => props.setValue(e.target.value)}
            />
          </div>
        )}
      {props.name === "messageToMedia" && (
        <textarea
        // onKeyUp={(e) => textAreaAdjust(e.target.)}
          onBlur={handleClick}
          placeholder={props.placeholder}
          value={props.value}
          onChange={(e) => props.setValue(e.target.value)}
          className={"textarea"}
        />
      )}
    </MessageBtn>
  )
}

const MessageBtn = styled.div`
  .input {
    width: 200px;
    position: relative;
    .codelate {
      font-size: 13px;
      margin-left: 3px;
      position: absolute;
      top: 1px;
      right: 3px;
      color: gray;
    }
  }
  .message {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .textarea {
    resize: none;
    min-height: 18px !important;
    padding: 5px;
    overFlow: hidden;
  }
`

export default ConversationInput

// import * as React from "react"
// import * as styles from "./styles.scss"
// import Modal from "components/Modal"
// import Header from "components/Header"
// import Button from "components/Button"
// import { PublishOrderContext } from "../../.."
// import { useTranslation } from "react-i18next"

// function ConversationModal(props: ConversationModalProps) {
//   // this conversationModals which should remove it
//   const { title, subtitle, ...rest } = props
//   const { publishOrder, dispatch } = React.useContext(PublishOrderContext)
//   const [value, setValue] = React.useState(publishOrder[props.name])
//   const [t] = useTranslation()

//   function handleClick() {
//     dispatch({
//       type: "updateValue",
//       payload: { id: publishOrder.order.id, name: props.name, newValue: value },
//     })
//     props.onRequestClose()
//   }

//   const handleChange = (e: any) => setValue(e.target.value)

//   return (
//     <Modal {...rest} className={styles.conversationModal} isModalInModal={true}>
//       <Header title={title} subtitle={subtitle} />
//       {props.isTextArea ? (
//         <textarea
//           value={value}
//           onChange={handleChange}
//           className={styles.message}
//           placeholder={props.placeholder}
//         />
//       ) : (
//         <input
//           value={value}
//           onChange={handleChange}
//           placeholder={props.placeholder}
//         />
//       )}
//       <div className={styles.buttonContainer}>
//         <Button variant="primary" color="primary" onClick={handleClick}>
//           {t("save")}
//         </Button>
//       </div>
//     </Modal>
//   )
// }

// interface ConversationModalProps {
//   isOpen: boolean
//   title: string
//   subtitle: string
//   name: string
//   isTextArea?: boolean
//   placeholder: string
//   onRequestClose: () => void
// }

// export default React.memo(ConversationModal)
