import * as React from "react"
import * as styles from "./styles.scss"
import cx from "classnames"
import { AppContext } from "App"

const Spinner: React.FC<SpinnerProps> = (props) => {
  const { featureFlags } = React.useContext(AppContext)
  const { fullscreen, size, innerBallSize, color } = props
  const width = size ?? 78
  const height = size ?? 78
  const innerBallHeight = innerBallSize ?? 10
  const innerBallWidth = innerBallSize ?? 10
  let bgColor: string

  if (color) {
    bgColor = color
  } else if (featureFlags?.isFEUser) {
    bgColor = "#3C5E25"
  } else {
    bgColor = "#1289a7"
  }

  return (
    <div
      className={cx(styles.outerContainer, {
        [styles.fullscreen]: fullscreen,
      })}
      style={{ minHeight: height * 2 }}
    >
      <div className={styles.container} style={{ width, height }}>
        <div
          className={styles.ball}
          style={{ width, height }}
          id={styles.ball_1}
        >
          <div
            className={styles.innerBall}
            style={{
              width: innerBallWidth,
              height: innerBallHeight,
              background: bgColor,
            }}
          />
        </div>
        <div
          className={styles.ball}
          style={{ width, height }}
          id={styles.ball_2}
        >
          <div
            className={styles.innerBall}
            style={{
              width: innerBallWidth,
              height: innerBallHeight,
              background: bgColor,
            }}
          />
        </div>
        <div
          className={styles.ball}
          style={{ width, height }}
          id={styles.ball_3}
        >
          <div
            className={styles.innerBall}
            style={{
              width: innerBallWidth,
              height: innerBallHeight,
              background: bgColor,
            }}
          />
        </div>
        <div
          className={styles.ball}
          style={{ width, height }}
          id={styles.ball_4}
        >
          <div
            className={styles.innerBall}
            style={{
              width: innerBallWidth,
              height: innerBallHeight,
              background: bgColor,
            }}
          />
        </div>
        <div
          className={styles.ball}
          style={{ width, height }}
          id={styles.ball_5}
        >
          <div
            className={styles.innerBall}
            style={{
              width: innerBallWidth,
              height: innerBallHeight,
              background: bgColor,
            }}
          />
        </div>
      </div>
    </div>
  )
}

interface SpinnerProps {
  fullscreen?: boolean
  size?: number
  innerBallSize?: number
  color?: string
}

export default Spinner
