import * as React from "react"
import * as styles from "./styles.scss"
import { WithTranslation, withTranslation } from "react-i18next"
import Button from "components/Button"
import { DashboardContext } from "pages/DashboardPage"
import GeneralHeaderRow from "components/HeaderRow"
import { MutationFn } from "react-apollo"
import ConfirmationModal from "components/ConfirmationModal"

class HeaderRow extends React.PureComponent<
  HeaderRowProps & WithTranslation,
  HeaderRowState
> {
  state = { modalIsOpen: false }

  openModal = () => this.setState({ modalIsOpen: true })

  closeModal = () => this.setState({ modalIsOpen: false })

  handleDelete = () =>
    this.props
      .onDelete({
        variables: {
          input: {
            caseFolderId: this.props.selectedCaseFolderId,
          },
        },
      }).catch(err => console.log(err))

  render() {
    const { t, isLoading } = this.props
    return (
      <DashboardContext.Consumer>
        {({ exitEditMode, selectedCaseFolder }) => (
          <React.Fragment>
            <GeneralHeaderRow
              title={selectedCaseFolder.name}
              renderActionButton={() => (
                <Button
                  variant="primary"
                  color="primary"
                  type="submit"
                  isWorking={isLoading}
                >
                  {t("save")}
                </Button>
              )}
              renderCancelButton={() => (
                <Button
                  className={styles.cancelButton}
                  onClick={exitEditMode}
                  disabled={isLoading}
                >
                  {t("cancel")}
                </Button>
              )}
              renderDeleteButton={() => (
                <Button
                  onClick={this.openModal}
                  disabled={isLoading}
                  variant="tertiary"
                  icon="trash-alt"
                >
                  {t("delete")}
                </Button>
              )}
            />
            <ConfirmationModal
              isOpen={this.state.modalIsOpen}
              onRequestClose={this.closeModal}
              okButtonText={t("delete")}
              text={t("deleteModalText")}
              title={t("delete")}
              onOKClick={this.handleDelete}
            />
          </React.Fragment>
        )}
      </DashboardContext.Consumer>
    )
  }
}

interface HeaderRowProps {
  onDelete: MutationFn
  selectedCaseFolderId: string
  isLoading: boolean
}

interface HeaderRowState {
  modalIsOpen: boolean
}

export default withTranslation("dashboard")(HeaderRow)
