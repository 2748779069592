import gql from "graphql-tag"
import PROPERTIES_FUNERAL_STANDARD_FRAGMENT from "./PropertiesFuneralStandardFragment"

const DASHBOARD_CASE_FOLDER_FRAGMENT = gql`
  fragment DashboardCaseFolderFragment on CaseFolder {
    id
    internalId
    name
    customer {
      id
      name
    }
    propertiesFuneralStandard {
      ...PropertiesFuneralStandardFragment
    }
    summary {
      countOrders
      mostCriticalColor
      mostCriticalStatus
    }
    productPackages {
      productPackage {
        id
        name
      }
      summary {
        mostCriticalColor
        mostCriticalStatus
        isMostCriticalTimeBookingWarning
      }
      documents {
        id
        internalId
        documentFormat {
          id
          documentType {
            friendlyName
            id
            name
          }
        }
        orders {
          id
          customerFriendlyId
          document {
            id
          }
          alerting {
            text
            smallText
          }
          mediaOrder {
            isCriticalTimeBookingWarning
            isLocked
            media {
              id
              friendlyName
            }
           documentFormat {
              id
              mediaAdaptedName : friendlyName(isShownWithMedia:true) 
            }
            publishTimes {
              time
            }
            status {
              status
              color
            }

          }
        }
      }
    }
  }
  ${PROPERTIES_FUNERAL_STANDARD_FRAGMENT}
`

export default DASHBOARD_CASE_FOLDER_FRAGMENT
