import * as React from "react"
import { useQuery } from "react-apollo-hooks"
import GET_ADMIN_CUSTOMERS from "graphql/queries/getAdminCustomers"
import * as styles from "./styles.scss"
import Spinner from "components/Spinner"
import NoResults from "../NoResults"
import Companies from "./Companies"

function CustomerView(props: CustomerViewProps) {
  const { data, loading } = useQuery(GET_ADMIN_CUSTOMERS, {
    variables: { name: props.searchValue },
    skip: props.searchValue === "",
  })

  if (loading) return <Spinner />

  const hasResults = () =>
    data &&
    data.customerTreesByFreeSearch &&
    data.customerTreesByFreeSearch.companies.length

  return (
    <div className={styles.container}>
      {hasResults() ? (
        <Companies companies={data.customerTreesByFreeSearch.companies} />
      ) : (
        <NoResults />
      )}
    </div>
  )
}

interface CustomerViewProps {
  searchValue: string
}

export default CustomerView
