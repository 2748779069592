import gql from "graphql-tag"
import PROPERTIES_FUNERAL_STANDARD_FRAGMENT from "./PropertiesFuneralStandardFragment"

const UPDATE_CASE_FOLDER_RESPONSE_FRAGMENT = gql`
  fragment UpdateCaseFolderResponseFragment on CaseFolder {
    id
    internalId
    name
    customer {
      id
      name
    }
    productPackages {
      productPackage {
        id
      }
    }
    propertiesFuneralStandard {
      ...PropertiesFuneralStandardFragment
    }
  }
  ${PROPERTIES_FUNERAL_STANDARD_FRAGMENT}
`

export default UPDATE_CASE_FOLDER_RESPONSE_FRAGMENT
