import React from "react"
import styled from "styled-components"
import {
  SelectOrderInitiatorView_selectOrderInitiatorView_columns,
  SelectOrderInitiatorView_selectOrderInitiatorView_options,
} from "schema"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowRight } from "@fortawesome/free-solid-svg-icons"

const LayoutTable: React.FC<{
  columns: SelectOrderInitiatorView_selectOrderInitiatorView_columns[]
  options: SelectOrderInitiatorView_selectOrderInitiatorView_options[]
  onSelectItem: (selector: string) => void
}> = ({ columns, options, onSelectItem }) => {
  return (
    <LayoutTableStyleWrapper>
      <table className="table">
        <thead className={"table-header"}>
          <tr className="table-row">
            {columns.map((col, index) => {
              return (
                <th className="table-cell" key={index}>
                  {col.name}
                </th>
              )
            })}
            <th className="table-cell"></th>
          </tr>
        </thead>
        {options.map((opt, index) => {
          return (
            <tbody
              key={index}
              className={"table-body"}
              onClick={() => onSelectItem(opt.selector)}
            >
              <tr className="table-row">
                {opt.columns.map((col, index) => {
                  return (
                    <td className="table-cell" key={index}>
                      {" "}
                      {col.text}
                    </td>
                  )
                })}
                <td className="table-cell">
                  <FontAwesomeIcon icon={faArrowRight} className={"icon"} />
                </td>
              </tr>
            </tbody>
          )
        })}
      </table>
    </LayoutTableStyleWrapper>
  )
}

const LayoutTableStyleWrapper = styled.div`
  margin: 50px 20px 10px 20px;
  .table {
    border: 1px solid #eee;
    border-radius: 6px;
    width: 100%;
    height: auto;
    .table-header {
      background: transparent linear-gradient(180deg, #ffffff 0%, #f8f8f8 100%)
        0% 0% no-repeat padding-box;
      height: 35px;
    }
    .table-body {
      border: 1px solid red;
      .table-row {
        cursor: pointer;
        height: 30px;
        &:nth-child(even) {
          background-color: ${(props) => props.theme["--light-gray"]};
        }
        &:hover {
          background-color: ${(props) => props.theme["--light-gray"]};
        }
        .table-cell {
          text-align: center;
          .icon {
            color: ${(props) => props.theme["--secondary"]};
          }
        }
      }
    }
  }
`

export default LayoutTable
