import * as React from "react"
import * as styles from "./styles.scss"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

class Warning extends React.PureComponent {
  render() {
    return (
      <div className={styles.warning}>
        <FontAwesomeIcon icon="exclamation-triangle" /> {this.props.children}
      </div>
    )
  }
}

export default Warning
