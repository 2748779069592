import gql from "graphql-tag"

const RECTANGLE_FRAGMENT = gql`
  fragment RectangleFragment on EditModelAdvertArea {
    rectangle(unit: PIXEL, resolution: 192) {
      x
      y
      w
      h
    }
  }
`

export default RECTANGLE_FRAGMENT
