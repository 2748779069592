import * as React from "react"
import * as styles from "./styles.scss"
import Slides from "./Slides"
import Navigation from "./Navigation"

class Slider extends React.PureComponent<SliderProps> {
  render() {
    const { currentSlideIndex, children } = this.props
    return (
      <div className={styles.sliderContainer}>
        <Slides currentSlideIndex={currentSlideIndex} slides={children} />
        <Navigation slides={children} {...this.props} />
      </div>
    )
  }
}

interface SliderProps {
  currentSlideIndex: number
  nextSlide?: any
  prevSlide?: any
  goToSlide?: (index: number) => void
  isDotDisabled?: (index: number) => boolean
  isNextButtonDisabled?: (slideCount: number) => boolean
  disableDots?: boolean
  orderLength?: number
}

export default Slider
