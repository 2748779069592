import * as React from "react"
import * as styles from "./styles.scss"
import * as cx from "classnames"
import Button from "components/Button"
import Slider from "react-rangeslider"
import AvatarEditor from "react-avatar-editor"
import detectPointerEvents from "./detectPointerEvents"
import { useTranslation } from "react-i18next"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

export const ImageEditor: React.FC<{
  imageUrl: string
  onSave?: any
  onClose?: any
}> = (props) => {
  //   return <div className={styles.test}>
  //       <Button onClick={props.onSave}>Save</Button>
  //   </div>
  // }
  const {t} = useTranslation("editOrder")

  const { imageUrl, onSave, onClose } = props
  // const windowWidth = useWindowWidth(0, { wait: 100 });
  // const isMobile = windowWidth < 760;
  const isMobile = false
  const [rotate, setRotate] = React.useState(0)
  const [scale, setScale] = React.useState(1)
  const [scaleSlider, setScaleSlider] = React.useState(1)
  const [imageUrlHelper, setImageHelper] = React.useState(imageUrl)
  const initEditorImageWidth = isMobile ? 280 : 420
  const initEditorImageHeight = isMobile ? 280 : 420
  const [editorImageWidth, setEditorImageWidth] = React.useState(
    initEditorImageWidth
  )
  const [editorImageHeight, setEditorImageHeight] = React.useState(
    initEditorImageHeight
  )
  const editorContainerWidth = initEditorImageWidth
  const editorContainerHeight = initEditorImageHeight

  const zoomIn = () => {
    let newScale = scale - 0.1
    if (newScale <= 1) {
      newScale = 1
    }
    setScale(newScale)
  }
  const zoomOut = () => {
    let newScale = scale + 0.1
    if (newScale >= 4) {
      newScale = 4
    }
    setScale(newScale)
  }
  const rotateLeft = () => {
    let newRotate = rotate + 90
    if (newRotate === 360) {
      newRotate = 0
    }
    setRotate(newRotate)
  }
  const rotateRight = () => {
    let newRotate = rotate - 90
    if (newRotate === -360) {
      newRotate = 0
    }
    setRotate(newRotate)
  }
  const refContainer = React.useRef(null)
  const onSaveImage = (event?: any) => {
    if (event && event.preventDefault) {
      event.preventDefault()
    }

    if (
      refContainer !== undefined &&
      refContainer !== null &&
      refContainer.current !== undefined &&
      refContainer.current !== null
    ) {
      const canvasScaled = (refContainer.current as any).getImageScaledToCanvas()
      canvasScaled.toBlob((blob: any) => {
        if (onSave) {
          onSave(blob)
        }
      })
    }
    return false
  }

  const onSliderChange = (value: any) => {
    setScale(value)
    setScaleSlider(value)
  }

  // const editorCss = css`
  //   border: 1px solid #e1e1e1;
  //   border-radius: 4px;
  //   overflow: hidden;
  //   background: #e1e1e1;
  //   position: relative;
  //   width: ${editorContainerWidth}px;
  //   height: ${editorContainerHeight}px;
  //   margin-top: 0px;

  //   input {
  //     min-width: 1px;
  //     height: 100%;
  //   }
  // `;
  // const editorWrapperCss = css`
  //   max-width: 100%;
  //   position: relative;

  //   @media all and (max-width: 400px) {
  //     max-width: 330px;
  //   }
  // `;
  // const wrapCss = css`
  //   display: flex;
  //   flex-direction: column;
  //   justify-content: flex-start;
  //   width: 100%;
  // `;

  return (
    <div className={styles.wrapCss}>
      <div className={styles.editorWrapperCss}>
        <div className={styles.editorTitleCss}>{t("editImage")}</div>
        <div
          className={styles.editorCss}
          style={{
            width: `${editorContainerWidth}px`,
            height: `${editorContainerHeight}px`,
          }}
        >
          <ImageCropOverlay />
          <div style={{ pointerEvents: "auto" }}>
            <AvatarEditor
              ref={refContainer}
              image={imageUrlHelper}
              width={editorImageWidth}
              height={editorImageHeight}
              border={0}
              color={[255, 255, 255, 0.6]}
              scale={scale}
              rotate={rotate}
            />
          </div>
        </div>

        <ImageToolbar
          onSliderChange={onSliderChange}
          onZoomIn={zoomIn}
          onZoomOut={zoomOut}
          onRotateLeft={rotateLeft}
          onRotateRight={rotateRight}
          sliderValue={scaleSlider}
        />
      </div>

      <BottomButtons onAbort={onClose} onSave={onSaveImage} />
    </div>
  )
}

const ImageCropOverlay: React.FC<{}> = (props) => {
  const bgSmall = `transparent
      linear-gradient(
        180deg,
        #ffffff00 0%,
        #ffffff00 15%,
        #ffffff4d 35%,
        #ffffffd9 60%,
        #ffffff 100%
      )
      0% 0% no-repeat padding-box`
  const bgBig = `transparent
      linear-gradient(
        180deg,
        #ffffff00 0%,
        #ffffff00 32%,
        #ffffff4d 74%,
        #ffffffd9 90%,
        #ffffff 100%
      )
      0% 0% no-repeat padding-box`
  const pointerEventsEnabled = detectPointerEvents.hasApi === true
  // const editorBottomLabelCss = css`
  //   position: absolute;
  //   bottom: -2px;
  //   left: 0;
  //   width: 100%;
  //   height: ${pointerEventsEnabled ? "100%" : "80px"};
  //   background: transparent;
  //   border-radius: 0 0 0 0;
  //   padding-left: 15px;
  //   padding-bottom: 10px;
  //   padding-top: 20px;
  //   padding-right: 15px;
  //   text-align: center;
  //   display: flex;
  //   justify-content: center;
  //   align-items: flex-end;
  //   box-sizing: border-box;
  //   text-shadow: 1px 1px 2px #ffffff;
  //   pointer-events: none;
  //   background: ${pointerEventsEnabled ? bgBig : bgSmall};

  //   span {
  //     padding-left: 15px;
  //     color: #000;
  //     font-size: 14px;
  //   }
  // `;
  const { t } = useTranslation()
  return (
    <div
      className={styles.editorBottomLabelCss}
      style={{
        height: pointerEventsEnabled ? "100%" : "80px",
        background: pointerEventsEnabled ? bgBig : bgSmall,
      }}
      onClick={(e) => e.preventDefault()}
    >
      <FontAwesomeIcon
        icon={"arrows-alt"}
        className={styles.editorBottomIconCss}
      />
      {/* <FaArrowsAlt color={FontColor.ICON_DEFAULT} size={FontSize.SIZE_22} /> */}
      <span className={styles.editorBottomTextCss}>
        <span>{t("editOrder:changePosition")}</span>
      </span>
    </div>
  )
}

const ImageToolbar: React.FC<{
  onZoomIn: any
  onZoomOut: any
  onRotateLeft: any
  onRotateRight: any
  sliderValue: any
  onSliderChange: any
}> = (props) => {
  // const buttonsCss = css`
  //   display: flex;
  //   align-items: center;
  //   justify-content: space-between;
  //   padding-top: 14px;
  //   padding-bottom: 24px;
  //   padding-right: 0;
  //   padding-left: 0px;
  //   max-width: 320px;
  // `;
  // const sliderCss = css`
  //   position: relative;
  //   display: flex;
  //   width: 190px;
  //   height: 35px;
  //   align-items: center;
  // `;
  // const rotateWrapCss = css`
  //   display: flex;
  //   width: auto;
  //   align-content: center;
  //   padding-left: 0px;
  //   padding-right: 0px;
  //   padding-top: 0px;
  //   padding-bottom: 0;
  //   justify-content: flex-start;
  //   align-content: center;
  // `;
  // const rotateIconAltCss = css`
  //   transform: rotateY(180deg);
  // `
  // const rotateIconWrapperCss = css`
  //   margin-right: 16px;
  // `
  // const rotateIconWrapperAltCss = css``

  // const iconLeftCss = css`
  //   position: absolute;
  //   z-index: 6;
  //   left: -2px;
  //   top: 10px;
  // `
  // const iconRightCss = css`
  //   position: absolute;
  //   z-index: 6;
  //   right: 1px;
  //   top: 9px;
  // `
  // const sliderWrapCss = css`
  //   position: absolute;
  //   z-index: 6;
  //   left: 20px;
  //   top: 5px;
  //   width: 138px;
  //   left: 23px;
  //   top: -4px;

  //   .rangeslider-horizontal {
  //     background: ${FontColor.ICON_DEFAULT};
  //     opacity: 0.35;
  //     border-radius: 3px;
  //     height: 5px;
  //   }
  //   .rangeslider,
  //   .rangeslider .rangeslider__fill {
  //     box-shadow: none;
  //   }
  //   .rangeslider .rangeslider__handle {
  //     box-shadow: none;
  //   }
  //   .rangeslider .rangeslider__handle {
  //     background: transparent;
  //     border: none;
  //   }
  //   .rangeslider-horizontal .rangeslider__handle:after {
  //     background: ${FontColor.PRIMARY};
  //     opacity: 1;
  //     border-radius: 50px;
  //     width: 12.7px;
  //     height: 12.7px;
  //     top: 0;
  //     left: 0;
  //     outline: none;
  //   }
  //   .rangeslider-horizontal .rangeslider__fill {
  //     background: ${FontColor.ICON_DEFAULT};
  //     opacity: 0.35;
  //     border-radius: 3px;
  //   }

  //   .rangeslider-horizontal .rangeslider__handle {
  //     width: 12.7px;
  //     height: 12.7px;
  //   }
  // `
  // const wrapCss = css`
  //   display: flex;
  //   justify-content: center;
  // `;
  return (
    <div className={styles.toolbarwrapCss}>
      <div className={styles.buttonsCss}>
        <div className={styles.sliderCss}>
          <div className={styles.iconLeftCss} onClick={props.onZoomIn}>
            <FontAwesomeIcon
              icon={"image"}
              className={styles.iconImageSmallCss}
              size={"1x"}
            />
            {/* <FaImage color={FontColor.PRIMARY} size={FontSize.SIZE_14} /> */}
          </div>

          <div className={styles.sliderWrapCss}>
            <Slider
              // style={{ width: 115 }}

              min={1}
              max={5}
              step={0.1}
              value={props.sliderValue}
              tooltip={false}
              onChange={props.onSliderChange}
            />
          </div>

          <div className={styles.iconRightCss} onClick={props.onZoomOut}>
            <FontAwesomeIcon
              icon={"image"}
              className={styles.iconImageBigCss}
              size={"1x"}
            />
            {/* <FaImage color={FontColor.PRIMARY} size={FontSize.SIZE_18} /> */}
          </div>
        </div>

        <div className={styles.rotateWrapCss}>
          <div className={styles.rotateWrapItemCss}>&nbsp;</div>

          <div
            className={styles.rotateIconWrapperCss}
            onClick={props.onRotateLeft}
          >
            <FontAwesomeIcon icon={"undo"} className={styles.colorPrimary} />
            {/* <FaUndo
              onClick={onRotateLeft}
              color={FontColor.PRIMARY}
              size={FontSize.SIZE_16}
            /> */}
          </div>

          <div
            className={styles.rotateIconWrapperAltCss}
            onClick={props.onRotateRight}
          >
            <FontAwesomeIcon
              icon={"undo"}
              className={styles.rotateIconAltCss}
            />
            {/* <FaUndo
              css={rotateIconAltCss}
              onClick={onRotateRight}
              color={FontColor.PRIMARY}
              size={FontSize.SIZE_16}
            /> */}
          </div>
        </div>
      </div>
    </div>
  )
}

const BottomButtons: React.FC<{
  onAbort: () => void
  onSave: () => void
}> = (props) => {
  const { t } = useTranslation(["common"])
  return (
    <div className={styles.bottomsButtonsWrapper}>
      <Button variant={"secondary"} onClick={props.onAbort}>
        {t("common:cancel")}
      </Button>

      <Button variant={"primary"} onClick={props.onSave}>
        {t("common:save")}
      </Button>
    </div>
  )
}
