import gql from "graphql-tag"

const GET_GALLERY_IMAGE_INFORMATION = gql`
  query GetGalleryImageInformation($ids: [ID]!) {
    galleryImages(ids: $ids) {
      id
      description
      groupName
      thumbnail100x80Url
      publicId
      repository
      tagGroups {
        tags
      }
    }
  }
`

export default GET_GALLERY_IMAGE_INFORMATION
