import * as React from "react"
import * as styles from "./styles.scss"
import Navbar from "components/Navbar"
import ZendeskChat from "components/ZendeskChat"
import { AppContext } from "App"

// class MainLayout extends React.Component<MainLayoutProps> {
//   static defaultProps = { showUtilityBar: true }

//   render() {
//     return (
//       <main className={styles.main}>
//         <Navbar  />
//         {this.props.showUtilityBar && <UtilityBar />}
//         <section className={styles.contentContainer}>
//           {this.props.children}
//         </section>
//       </main>
//     )
//   }
// }

type MainLayoutProps = {
  showUtilityBar: boolean
  isFEUser?: boolean
}

const MainLayout: React.FunctionComponent<MainLayoutProps> = (props) => {
  const { currentUserSession, featureFlags } = React.useContext(AppContext)
  return (
    <main className={styles.main}>
      {!props.isFEUser && <Navbar showUtilityBar={props.showUtilityBar} />}
      <section
        className={styles.contentContainer}
        style={props.isFEUser ? { paddingTop: "33px" } : {}}
      >
        {props.children}
      </section>
      {featureFlags && !featureFlags.isFEUser && (
        <ZendeskChat currentUserSession={currentUserSession} />
      )}
    </main>
  )
}

export default MainLayout
