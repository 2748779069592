import * as React from "react"
import {
  GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor,
  GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value,
  GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows,
  TextAlignments,
  GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas,
  GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_common_margin,
  GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns,
  EditModelAdvertAreaContentEditorTypes,
  UpdateOrderContentNoValuesVariables,
  EditModelAdvertAreaContentValueInput,
  GetEditOrderPageCaseFolder_caseFolder_editTabs,
  UnitTypes,
  GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_text,
  GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item,
  GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_common,
  GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_common_size,
  EditModelAdvertAreaContentRepository,
  GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_common_marginLeftRightOffset,
  RelativeTypes,
  GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_fields,
} from "schema"
import { MutationFn } from "react-apollo"
import { omitTypename } from "utils"
import Area from "./Area"
import HtmlInput from "./HtmlInput"
import ImageControl from "./ImageControl"
import SeparatorControl from "./SeparatorControl"
import _, { cloneDeep, throttle } from "lodash"
import { produce } from "immer"
import { AppContext } from "App"
import { error } from "appLog"
import { MeasurementInputTYPE } from "customSchema.interface"

export const OrderEditorAreaContext = React.createContext(
  // tslint:disable-next-line: no-object-literal-type-assertion
  {} as OrderEditorAreaContextValues
)

// tslint:disable-next-line: max-line-length
type textKeys = keyof GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_text

const fontFamilyKey: textKeys = "fontFamily"
const fontSizeKey: textKeys = "fontSize"
const letterSpacingKey: textKeys = "letterSpacing"
const lineHeightKey: textKeys = "lineHeight"
const lineHeightEmptyKey: textKeys = "lineHeightEmpty"
const textAlignmentKey: textKeys = "textAlignment"
const textNoWrapKey: textKeys = "textNoWrap"
const textScaleWidthKey: textKeys = "textScaleWidth"
const fontStyleKey: textKeys = "fontStyle"
const fontWeightKey: textKeys = "fontWeight"
const textScaleWidthOffsetKey: textKeys = "textScaleWidthOffset"

// const xmlTextKey: textKeys = "xmlText"

type mainKeys = keyof GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item
// const mainTextKey: mainKeys = "text"
// const mainSeparatorKey: mainKeys = "separator"
// const mainImageKey: mainKeys = "image"

// tslint:disable-next-line: max-line-length
type marginPositionsKeys = keyof Omit<
  GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_common_margin,
  "type"
>
// const marginBottomKey: marginKeys = "bottom"
// const marginLeftKey: marginKeys = "left"
// const marginRightKey: marginKeys = "right"
// const marginTopKey: marginKeys = "top"
// const marginTypeKey: marginKeys = "type"

type Common = GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_common
type Margin = GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_common_margin
type Size = GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_common_size
type MarginLeftRightOffset = GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_common_marginLeftRightOffset
type fieldsType = GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_fields | null

const defaultMargin: Margin = {
  bottom: null,
  left: null,
  right: null,
  top: null,
  type: UnitTypes.MILLIMETER,
}

const defaultSize: Size = {
  type: UnitTypes.MILLIMETER,
  minWidth: null,
  minHeight: null,
  maxWidth: null,
  maxHeight: null,
  fixedWidth: null,
  fixedHeight: null,
}

const defaultMarginLeftRightOffset: MarginLeftRightOffset | null = {
  type: RelativeTypes.INTERVAL,
  value: 0,
}
/**
 * @TODO:Refactor
 */
class AreaContainer extends React.PureComponent<
  AreaContainerProps,
  AreaContainerState
> {
  // static getDerivedStateFromProps(
  //   nextProps: AreaContainerProps,
  //   prevState: AreaContainerState
  // ) {
  //   let margin = prevState.margin
  //   if (
  //     nextProps &&
  //     nextProps.area &&
  //     nextProps.area.content &&
  //     nextProps.area.content.value.rows &&
  //     nextProps.area.content.value.rows[0] &&
  //     nextProps.area.content.value.rows[0].columns &&
  //     nextProps.area.content.value.rows[0].columns[0].item !== undefined &&
  //     nextProps.area.content.value.rows[0].columns[0].item !== null &&
  //     nextProps.area.content.value.rows[0].columns[0].item.common !==
  //       undefined &&
  //     nextProps.area.content.value.rows[0].columns[0].item.common !== null &&
  //     nextProps.area.content.value.rows[0].columns[0].item.common.margin !==
  //       undefined &&
  //     nextProps.area.content.value.rows[0].columns[0].item.common.margin !==
  //       null
  //   ) {
  //     console.log("leci zmiana...", prevState, nextProps)
  //     margin =
  //       nextProps.area.content.value.rows[0].columns[0].item.common.margin

  //     return {
  //       margin,
  //     }
  //   }

  // if (!prevState || prevState.margin?.left !== nextProps.) {
  //   console.log("leci zmiana...", prevState, nextProps)
  //   return {
  //     value: nextProps.initialValue,
  //   }
  // }
  //   return {}
  // }

  timeout: any = undefined

  state: AreaContainerState = {
    contentRows: [],
    visible: true,
    isExpandedAreaOverflowHidden: true,
    // margin: {
    //   bottom: null,
    //   left: null,
    //   right: null,
    //   top: null,
    //   type: UnitTypes.MILLIMETER,
    // },
  }

  handleUpdateThrottled: (callback?: any) => void

  constructor(props: AreaContainerProps) {
    super(props)
    this.state = {
      contentRows: [],
      visible: true,
      isExpandedAreaOverflowHidden: true,
      // margin: {
      //   bottom: null,
      //   left: null,
      //   right: null,
      //   top: null,
      //   type: UnitTypes.MILLIMETER,
      // },
    }
    this.handleUpdateThrottled = throttle(this.updateContentValues, 16 * 40)
  }

  getTextProperty = (
    propertyName: string,
    column: GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns
  ) => {
    let result = null
    if (
      column &&
      column.item &&
      column.item.text !== undefined &&
      column.item.text !== null &&
      column.item.text[propertyName] !== undefined &&
      column.item.text[propertyName] !== null
    ) {
      result = column.item.text[propertyName]
    }
    return result
  }

  initContentRows = () => {
    const { area } = this.props
    return area.content.value.rows.map((row) => ({
      columns: row.columns.map((column) => {
        return {
          item: {
            ...column.item,

            text: {
              ...column.item.text!,
              [fontSizeKey]: this.getTextProperty(fontSizeKey, column),
              [fontFamilyKey]: this.getTextProperty(fontFamilyKey, column),
              [lineHeightKey]: this.getTextProperty(lineHeightKey, column),
              [lineHeightEmptyKey]: this.getTextProperty(
                lineHeightEmptyKey,
                column
              ),
              [letterSpacingKey]: this.getTextProperty(
                letterSpacingKey,
                column
              ),
              [textScaleWidthKey]: this.getTextProperty(
                textScaleWidthKey,
                column
              ),
              [fontStyleKey]: this.getTextProperty(fontStyleKey, column),
              [fontWeightKey]: this.getTextProperty(fontWeightKey, column),
            },
          },
        }
      }),
    }))
  }

  initSeparatorContentRows = () => {
    const { area } = this.props
    return area.content.value.rows.map((row) => ({
      columns: row.columns.map((column) => ({
        item: {
          ...column.item,
          text: null,
        },
      })),
    }))
  }

  componentDidMount = () => {
    const { editor, area } = this.props
    const value = area.content.value
    const visible = value.visible

    if (this.props.isSeparatorRowAdded) {
      this.toggleVisibility()
      this.props.setIsSeparatorRowAdded(false)
    }
    // let margin: AreaContainerState["margin"] = this.state.margin
    // if (
    //   value &&
    //   value.rows &&
    //   value.rows[0] &&
    //   value.rows[0].columns &&
    //   value.rows[0].columns[0].item !== undefined &&
    //   value.rows[0].columns[0].item !== null &&
    //   value.rows[0].columns[0].item.common !== undefined &&
    //   value.rows[0].columns[0].item.common !== null &&
    //   value.rows[0].columns[0].item.common.margin !== undefined &&
    //   value.rows[0].columns[0].item.common.margin !== null
    // ) {
    //   margin = value.rows[0].columns[0].item.common.margin
    // }

    const {
      GALLERY_IMAGE,
      TEXT_HTML,
      SEPARATOR,
    } = EditModelAdvertAreaContentEditorTypes

    let state: AreaContainerState = {
      visible,
      // margin,
      contentRows: [],
    }

    if (editor.type === GALLERY_IMAGE) {
      state = {
        ...state,
        contentRows: area.content.value.rows.map((row) => ({
          columns: row.columns.map((column) => ({
            item: {
              ...column.item,
            },
          })),
        })),
      }
    } else if (editor.type === TEXT_HTML) {
      state = {
        ...state,
        contentRows: this.initContentRows(),
      }
    } else if (editor.type === SEPARATOR) {
      state = {
        ...state,
        contentRows: this.initSeparatorContentRows(),
      }
    }

    this.setState(state)
  }

  private lastContentRowsJSON: string = ""
  componentDidUpdate = (prevProps: AreaContainerProps) => {
    const { editor, area } = this.props
    const value = area.content.value

    const newContentRows = this.initContentRows()
    var newContentRowsJSON = JSON.stringify(newContentRows)
    if (newContentRowsJSON != this.lastContentRowsJSON) {
      this.setState({ contentRows: newContentRows as any })
      this.lastContentRowsJSON = newContentRowsJSON
    }
  }

  handleXmlTextChange = (
    newText: string,
    rowIndex: number,
    colIndex: number
  ) => {
    const debug = false
    if (debug) console.log("handleXmlTextChange " + newText)
    const newState = produce(this.state, (draft) => {
      const item = draft.contentRows[rowIndex]?.columns[colIndex]?.item
      if (item) {
        if (item?.text) item.text.xmlText = newText
        item.isDemoValue = false
      }
    })
    // Changes Kenneth 20210510 : No throttling. The debounce is taking care of apollo debounce
    this.setState(newState, this.updateContentValues)
    return newState
  }

  setFontSize = (value: number | null) => {
    this.updateAdminProperty(fontSizeKey, value)
  }

  setLineHeight = (value: number | null) => {
    this.updateAdminProperty(lineHeightKey, value)
  }

  setLineHeightEmpty = (value: number | null) => {
    this.updateAdminProperty(lineHeightEmptyKey, value)
  }

  setLetterSpacing = (value: number | null) => {
    this.updateAdminProperty(letterSpacingKey, value)
  }

  setTextScaleWidthOffset = (value: number | null) => {
    this.updateAdminProperty(textScaleWidthOffsetKey, value)
  }

  setTextScale = (value: number | null) => {
    this.updateAdminProperty(textScaleWidthKey, value)
  }

  setTextAlignment = (value: TextAlignments | null) => {
    this.updateAdminProperty(textAlignmentKey, value)
  }

  setFontFamily = (value: string | null) => {
    this.updateAdminProperty(fontFamilyKey, value)
  }

  setFontStyle = (value: string | null) => {
    this.updateAdminProperty(fontStyleKey, value)
  }

  setFontWeight = (value: string | null) => {
    this.updateAdminProperty(fontWeightKey, value)
  }

  manuallySetContentRows = (contentRows: any[]) => {
    this.setState({ contentRows }, this.handleUpdateThrottled)
  }

  setFields = (list: Array<fieldsType>, callback?: () => void) => {
    this.setState(
      produce(this.state, (draft) => {
        draft.contentRows[0].columns[0].item.fields = list
      }),
      () => {
        this.handleUpdateThrottled()
      }
    )
  }

  
  toggleIsTextEditorReadOnly = (value: boolean) => {
    this.setState(
      produce(this.state, (draft) => {
        draft.contentRows[0].columns[0].item.text!.isXmlTextReadonly = value
      }),
      () => {
        this.handleUpdateThrottled()
      }
    )
  }

  toggleNoWrap = () => {
    const value =
      this.state?.contentRows?.[0]?.columns?.[0].item?.text?.textNoWrap ?? false
    this.updateAdminProperty(textNoWrapKey, !value)
  }

  updateAdminProperty = (
    key: string,
    newValue: any,
    mainKey: mainKeys | undefined = "text"
  ) => {
    const { type } = this.props.editor

    const {
      TEXT_HTML,
      GALLERY_IMAGE,
      SEPARATOR,
      CUSTOM,
      NONE,
    } = EditModelAdvertAreaContentEditorTypes

    if (type === TEXT_HTML) {
      mainKey = "text"
    } else if (type === SEPARATOR) {
      mainKey = undefined
    } else if (type === GALLERY_IMAGE) {
      mainKey = undefined
    } else if (type === CUSTOM) {
      mainKey = undefined
    } else if (type === NONE) {
      mainKey = undefined
    }

    const contentRows = this.state.contentRows.map((row) => {
      return {
        columns: row.columns.map((col) => {
          if (mainKey === undefined) {
            return col
          }
          const haveDefaultText =
            this.props.area.style &&
            this.props.area.style[mainKey] !== undefined &&
            this.props.area.style[mainKey] !== null
          const haveDefaultTextKey =
            haveDefaultText && this.props.area.style[mainKey][key] !== undefined
          const haveItemText =
            col.item[mainKey] !== undefined && col.item[mainKey] !== null
          const haveOldValue =
            haveItemText && col.item[mainKey]![key] !== undefined
          const oldValue = haveOldValue ? col.item[mainKey]![key] : null
          let value = {}
          if (newValue === null) {
            value = {
              [key]: null,
            }
          } else {
            if (key === fontFamilyKey || key === textNoWrapKey) {
              value = {
                [key]: newValue,
              }
            } else if (key === "textAlignment" || key === textNoWrapKey)
              value = { [key]: newValue }
            else if (key === "fontStyle" || key === textNoWrapKey)
              value = { [key]: newValue }
            else if (key === "fontWeight" || key === textNoWrapKey)
              value = { [key]: newValue }
            else {
              value = {
                [key]: {
                  ...(haveItemText
                    ? col.item[mainKey]![key]
                    : haveDefaultTextKey
                    ? this.props.area.style[mainKey][key]
                    : undefined),
                  value: newValue,
                },
              }
              if (oldValue === null) {
                if (haveDefaultTextKey) {
                  value = {
                    [key]: _.merge({}, this.props.area.style[mainKey][key], {
                      value: newValue,
                    }),
                  }
                }
              }
            }
          }
          return {
            item: {
              ...col.item,
              [mainKey]: {
                ...(col.item[mainKey] as any),
                ...value,
              },
            },
          }
        }),
      }
    })
    this.setState({ contentRows }, this.handleUpdateThrottled)
  }

  setBlockSize = (
    newValue: number | null,
    type: string,
    callback?: () => void
  ) => {
    this.setState(
      produce(this.state, (draft) => {
        if (draft.contentRows[0].columns[0].item.common === null) {
          draft.contentRows[0].columns[0].item.common = {
            blockStyleAreaNameId: null,
            margin: cloneDeep(defaultMargin),
            size: cloneDeep(defaultSize),
            marginLeftRightOffset: cloneDeep(defaultMarginLeftRightOffset),
          }
        }
        if (
          type === MeasurementInputTYPE.MAX_HEIGHT ||
          type === MeasurementInputTYPE.MIN_HEIGHT ||
          type === MeasurementInputTYPE.MAX_WIDTH ||
          type === MeasurementInputTYPE.MIN_WIDTH ||
          type === MeasurementInputTYPE.FIXED_HEIGHT ||
          type === MeasurementInputTYPE.FIXED_WIDTH
        )
          draft.contentRows[0].columns[0].item.common!.size![type] = newValue
      }),
      () => {
        this.handleUpdateThrottled()
      }
    )
  }

  setMarginLeftRightOffset = (value: number | null, callback?: () => void) => {
    this.setState(
      produce(this.state, (draft) => {
        if (draft.contentRows[0].columns[0].item.common === null) {
          draft.contentRows[0].columns[0].item.common = {
            blockStyleAreaNameId: null,
            margin: cloneDeep(defaultMargin),
            size: cloneDeep(defaultSize),
            marginLeftRightOffset: cloneDeep(defaultMarginLeftRightOffset),
          }
        }
        if (value === null || value === undefined) {
          draft.contentRows[0].columns[0].item.common!.marginLeftRightOffset = null
        } else {
          draft.contentRows[0].columns[0].item.common!.marginLeftRightOffset = {
            type: RelativeTypes.INTERVAL,
            value: Number(value),
          }
        }
      }),

      () => {
        this.handleUpdateThrottled()
      }
    )
  }

  setMargin = (position: marginPositionsKeys, newValue: number) => {
    this.setState(
      produce(this.state, (draft) => {
        if (
          position === "top" ||
          position === "left" ||
          position === "bottom" ||
          position === "right"
        ) {
          if (draft.contentRows[0].columns[0].item.common === null) {
            draft.contentRows[0].columns[0].item.common = {
              blockStyleAreaNameId: null,
              margin: cloneDeep(defaultMargin),
              size: cloneDeep(defaultSize),
              marginLeftRightOffset: cloneDeep(defaultMarginLeftRightOffset),
            }
          }
          draft.contentRows[0].columns[0].item.common!.margin![
            position
          ] = newValue
        }
        // else if (position === "bottom" || position === "right") {
        //   const maxRow = draft.contentRows.length - 1
        //   const maxColumn = draft.contentRows[maxRow].columns.length - 1
        //   if (
        //     draft.contentRows[maxRow].columns[maxColumn].item.common === null
        //   ) {
        //     draft.contentRows[maxRow].columns[maxColumn].item.common = {
        //       blockStyleAreaNameId: null,
        //       margin: cloneDeep(defaultMargin),
        //     }
        //   }
        //   draft.contentRows[maxRow].columns[maxColumn].item.common!.margin![
        //     position
        //   ] = newValue
        // }
      }),
      () => {
        this.handleUpdateThrottled()
      }
    )

    // this.setState(
    //   {
    //     margin: {
    //       ...(this.state.margin as any),
    //       [position]: newValue,
    //     },
    //   },
    //   this.handleUpdateThrottled
    // )
  }

  toggleVisibility = () => {
    this.setState({ visible: !this.state.visible }, this.handleUpdateThrottled)
  }

  onSeparatorImageChanged = (
    symbolId: string,
    imagePublicId: string,
    imageThumbnaill100x80Url: string,
    repository: EditModelAdvertAreaContentRepository,
    callback?: () => void
  ) => {
    this.setState(
      produce(this.state, (draft) => {
        if (draft.contentRows[0].columns[0].item.separator) {
          if (symbolId)
            draft.contentRows[0].columns[0].item.separator!.imageId = symbolId
          if (repository)
            draft.contentRows[0].columns[0].item.separator.repository = repository
        }
      }),
      () => {
        this.handleUpdateThrottled(callback)
      }
    )
  }

  onSymbolChanged = (
    symbolId: string,
    imagePublicId: string,
    imageThumbnaill100x80Url: string,
    repository: EditModelAdvertAreaContentRepository,
    callback?: () => void
  ) => {
    this.setState(
      produce(this.state, (draft) => {
        if (draft.contentRows[0].columns[0].item.image) {
          if (symbolId) {
            draft.contentRows[0].columns[0].item.image.imageId = symbolId
          }
          if (imagePublicId) {
            draft.contentRows[0].columns[0].item.image.imagePublicId = imagePublicId
          }
          if (imageThumbnaill100x80Url) {
            draft.contentRows[0].columns[0].item.image.imageThumbnaill100x80Url = imageThumbnaill100x80Url
          }
          if (repository) {
            draft.contentRows[0].columns[0].item.image.repository = repository
          }
        }
      }),
      () => {
        this.handleUpdateThrottled(callback)
      }
    )
  }

  onEditImageUpload = (
    fileData: any,
    fileName?: string,
    callback?: () => void
  ) => {
    this.setState(
      produce(this.state, (draft) => {
        if (draft.contentRows[0].columns[0].item.image) {
          if (fileData) {
            draft.contentRows[0].columns[0].item.image.uploadData = fileData
          }
          if (fileName) {
            draft.contentRows[0].columns[0].item.image.uploadFilename = fileName
          }
        }
      }),
      () => {
        this.handleUpdateThrottled(callback)
      }
    )
  }

  onOriginalImageUpload = (
    originalImage: any,
    fileName?: string,
    comment?: string,
    callback?: () => void
  ) => {
    this.setState(
      produce(this.state, (draft) => {
        if (draft.contentRows[0].columns[0].item.image) {
          if (originalImage) {
            draft.contentRows[0].columns[0].item.image.uploadDataSource = originalImage
          }
          if (fileName) {
            draft.contentRows[0].columns[0].item.image.uploadFilename = fileName
          }
          if (comment) {
            draft.contentRows[0].columns[0].item.image.uploadComment = comment
          }
        }
      }),
      () => {
        this.handleUpdateThrottled(callback)
      }
    )
  }

  onSymbolUploadHeight = (value: number, callback?: () => void) => {
    this.setState(
      produce(this.state, (draft) => {
        if (draft.contentRows[0].columns[0].item.image) {
          if (value) {
            draft.contentRows[0].columns[0].item.image.uploadPreferedHeightMM = Number(
              value
            )
          }
        }
      }),
      () => {
        this.handleUpdateThrottled(callback)
      }
    )
  }

  toggleFreeSize = (value: boolean, callback?: () => void) => {
    this.setState(
      produce(this.state, (draft) => {
        if (draft.contentRows[0].columns[0].item.image) {
          if (value === undefined || value === null) {
            draft.contentRows[0].columns[0].item.image.isSizeFree = null
          } else {
            draft.contentRows[0].columns[0].item.image.isSizeFree = value
          }
        }
      }),
      () => {
        this.handleUpdateThrottled(callback)
      }
    )
  }

  onSymbolResized = (symbolSize: number | null, callback?: () => void) => {
    this.setState(
      produce(this.state, (draft) => {
        if (draft.contentRows[0].columns[0].item.image) {
          if (symbolSize === undefined || symbolSize === null) {
            draft.contentRows[0].columns[0].item.image.heightMM = null
          } else {
            draft.contentRows[0].columns[0].item.image.heightMM = Number(
              symbolSize
            )
          }
        }
      }),
      () => {
        this.handleUpdateThrottled(callback)
      }
    )
  }

  getVariablesUpdateContenValues = () => {
    const { selectedOrderId } = this.props
    const area: AreaContainerProps["area"] = JSON.parse(
      JSON.stringify(this.props.area)
    )

    this.props.onTogglePreviewIsUpdatingOn()

    const contentRows = this.state.contentRows
    const value = area.content.value
    const editor = area.content.editor
    // let blockStyleAreaNameId: string | null = null

    // if (
    //   value &&
    //   value.rows &&
    //   value.rows[0] &&
    //   value.rows[0].columns &&
    //   value.rows[0].columns[0].item !== undefined &&
    //   value.rows[0].columns[0].item !== null &&
    //   value.rows[0].columns[0].item.common !== undefined &&
    //   value.rows[0].columns[0].item.common !== null
    // ) {
    //   if (
    //     value.rows[0].columns[0].item.common.blockStyleAreaNameId !==
    //       undefined &&
    //     value.rows[0].columns[0].item.common.blockStyleAreaNameId !== null &&
    //     value.rows[0].columns[0].item.common.blockStyleAreaNameId.length > 0
    //   ) {
    //     blockStyleAreaNameId =
    //       value.rows[0].columns[0].item.common.blockStyleAreaNameId
    //   }
    // }

    // tslint:disable-next-line: max-line-length
    const defaultCommon: Common = {
      margin: cloneDeep(defaultMargin),
      blockStyleAreaNameId: null,
      size: cloneDeep(defaultSize),
      marginLeftRightOffset: cloneDeep(defaultMarginLeftRightOffset),
    }
    let rows: EditModelAdvertAreaContentValueInput["rows"] = [
      {
        columns: [
          { item: { separator: value.rows[0].columns[0].item.separator } },
        ],
      },
    ]

    const {
      TEXT_HTML,
      SEPARATOR,
      GALLERY_IMAGE,
    } = EditModelAdvertAreaContentEditorTypes

    if (editor) {
      if (editor.type === TEXT_HTML) {
        rows = produce(contentRows, (draft) => {
          draft.forEach((row, rI) => {
            row.columns.forEach((col, cI) => {
              const common = cloneDeep(defaultCommon)
              if (
                col.item.common !== undefined &&
                col.item.common !== null &&
                col.item.common.blockStyleAreaNameId !== undefined &&
                col.item.common.blockStyleAreaNameId !== null
              ) {
                common.blockStyleAreaNameId =
                  col.item.common.blockStyleAreaNameId
              }
              if (
                col.item.common !== undefined &&
                col.item.common !== null &&
                col.item.common.margin !== undefined &&
                col.item.common.margin !== null
              ) {
                common.margin = col.item.common.margin
              }
              if (
                col.item.common !== undefined &&
                col.item.common !== null &&
                col.item.common.size !== undefined &&
                col.item.common.size !== null
              ) {
                common.size = common.size
              }
              if (
                col.item.common !== undefined &&
                col.item.common !== null &&
                col.item.common.marginLeftRightOffset !== undefined &&
                col.item.common.marginLeftRightOffset !== null
              ) {
                common.marginLeftRightOffset = common.marginLeftRightOffset
              }
            })
          })
        })
      } else if (editor.type === SEPARATOR) {
        rows = produce(contentRows, (draft) => {
          draft.forEach((row) => {
            row.columns.forEach((col) => {
              col.item.text = null
              const propsSeparator =
                value?.rows[0]?.columns[0]?.item?.separator ?? undefined
              const item: any = {
                separator: {
                  ...propsSeparator,
                  imageId: propsSeparator?.imageId ?? undefined,
                  repository: propsSeparator?.repository ?? undefined,
                },
                common: cloneDeep(defaultCommon),
              }
              if (
                col.item.common !== undefined &&
                col.item.common !== null &&
                col.item.common.blockStyleAreaNameId !== undefined &&
                col.item.common.blockStyleAreaNameId !== null
              ) {
                item.common.blockStyleAreaNameId =
                  col.item.common.blockStyleAreaNameId
              }
              if (
                col.item.common !== undefined &&
                col.item.common !== null &&
                col.item.common.margin !== undefined &&
                col.item.common.margin !== null
              ) {
                item.common.margin = col.item.common.margin
              }
              if (col.item.separator?.imageId) {
                item.separator.imageId = col.item.separator.imageId
              }
              if (col.item.separator?.repository) {
                item.separator.repository = col.item.separator.repository
              }
              rows = [{ columns: [{ item }] }]
            })
          })
        })
        if (value) {
          value.isEditorVisible = this.state.visible
        }
      } else if (editor.type === GALLERY_IMAGE) {
        rows = produce(contentRows, (draft) => {
          draft.forEach((row) => {
            row.columns.forEach((col) => {
              col.item.text = null
              const propsImage =
                value?.rows[0]?.columns[0]?.item?.image ?? undefined
              const item: any = {
                image: {
                  ...propsImage,
                  imageId: propsImage?.imageId ?? undefined,
                  imagePublicId: propsImage?.imagePublicId ?? undefined,
                  heightMM: null,
                  isSizeFree: null,
                },
                common: cloneDeep(defaultCommon),
              }
              if (col.item.common && value?.rows[0]?.columns[0]?.item.common) {
                item.common.blockStyleAreaNameId =
                  value?.rows[0]?.columns[0]?.item.common.blockStyleAreaNameId
              }
              if (col.item.common && col.item.common.margin) {
                item.common.margin = col.item.common.margin
              }
              const stateImage =
                contentRows?.[0]?.columns?.[0]?.item?.image ?? undefined

              if (stateImage?.heightMM) {
                item.image.heightMM = stateImage.heightMM
              }

              if (stateImage?.imageId) {
                item.image.imageId = stateImage.imageId
              }

              if (stateImage?.imagePublicId) {
                item.image.imagePublicId = stateImage.imagePublicId
              }

              if (stateImage?.isSizeFree) {
                item.image.isSizeFree = stateImage.isSizeFree
              }

              rows = [{ columns: [{ item }] }]
            })
          })
        })
      }
    }

    const editModelAdvertAreaContentValues: EditModelAdvertAreaContentValueInput = omitTypename(
      [
        {
          ...value,
          rows,
          visible: this.state.visible,
        },
      ]
    )

    const variables: UpdateOrderContentNoValuesVariables = {
      input: {
        orderId: selectedOrderId,
        editModel: {
          editModelAdvertAreaContentValues: editModelAdvertAreaContentValues as any,
        },
      },
    }

    return { variables }
  }

  triggerContentValues = (callback?: () => void) => {
    return this.handleUpdateThrottled(callback)
  }

  updateContentValues = (
    callback?: () => void,
    errCallback?: (err: any) => void
  ) => {
    if (this.props.callbackAfterUpdate) {
      this.props.callbackAfterUpdate()
    }
    const { variables } = this.getVariablesUpdateContenValues()
    return this.props
      .onUpdateOrderContentValues({ variables })
      .then(callback)

      .catch((err) => {
        if (errCallback) {
          errCallback(err)
        }
        error(err)
        this.props.togglePreviewIsUpdatingOff()
        if (this.props.callbackAfterUpdate) {
          this.props.callbackAfterUpdate()
        }
      })
      .finally(() => {
        this.props.togglePreviewIsUpdatingOff()
        if (this.props.callbackAfterUpdate) {
          this.props.callbackAfterUpdate()
        }
      })
  }

  handleAreaOverFlowChange = (value: boolean) => {
    this.setState({ isExpandedAreaOverflowHidden: value })
  }

  handleMouseEnter = () => this.props.onSetHoveredAreaId(this.props.id)

  handleMouseLeave = () => this.props.onSetHoveredAreaId(undefined)

  handleClick = () => {
    this.props.onClick(this.props.id)
  }

  // setVerseInExpandedArea = ({ textXml, id, name }: any) => {
  //   const contentRows = this.state.contentRows.map((row) => ({
  //     columns: row.columns.map((col) => ({
  //       item: {
  //         ...col.item,
  //         text: {
  //           ...col.item.text!,
  //           xmlText: textXml,
  //         },
  //       },
  //     })),
  //   }))
  //   this.setState({ contentRows }, this.handleUpdateThrottled)
  // }
  onSelectBlockStyle = (
    value: string | null,
    rowIndex: number,
    columnIndex: number
  ) => {
    const newState = produce(this.state, (draft) => {
      draft.contentRows.forEach((row, i) => {
        row.columns.forEach((col, j) => {
          if (rowIndex === i && columnIndex === j) {
            if (!col.item.common) {
              col.item.common = {
                margin: cloneDeep(defaultMargin),
                blockStyleAreaNameId: null,
                size: cloneDeep(defaultSize),
                marginLeftRightOffset: cloneDeep(defaultMarginLeftRightOffset),
              }
            }
            draft.contentRows[i].columns[
              j
            ].item.common!.blockStyleAreaNameId = value
          }
        })
      })
    })
    this.setState(
      { contentRows: newState.contentRows },
      this.handleUpdateThrottled
    )
  }

  render() {
    const { label, editor, isExpanded, area, selectedOrderId } = this.props
    // const isEnabledGallery =
    //   area.content.editor?.text?.isEnabledGallery ?? false
    const { type } = this.props.editor

    const {
      TEXT_HTML,
      GALLERY_IMAGE,
      SEPARATOR,
    } = EditModelAdvertAreaContentEditorTypes

    return (
      <AppContext.Consumer>
        {({ currentUserSession }) => {
          {
            return (
              <OrderEditorAreaContext.Provider
                value={{
                  contentRows: this.state.contentRows,
                  visible: this.state.visible,
                  // margin: this.state.margin,
                  editor,
                  setTextAlignment: this.setTextAlignment,
                  setFontFamily: this.setFontFamily,
                  setFontStyle: this.setFontStyle,
                  setFontWeight: this.setFontWeight,
                  setLineHeight: this.setLineHeight,
                  setLineHeightEmpty: this.setLineHeightEmpty,
                  setFontSize: this.setFontSize,
                  setLetterSpacing: this.setLetterSpacing,
                  setTextScale: this.setTextScale,
                  setMargin: this.setMargin,
                  toggleNoWrap: this.toggleNoWrap,
                  toggleVisibility: this.toggleVisibility,
                  onXmlTextChange: this.handleXmlTextChange,
                  manuallySetContentRows: this.manuallySetContentRows,
                  onSymbolSave: this.onSymbolChanged,
                  onEditImageUpload: this.onEditImageUpload,
                  onOriginalImageUpload: this.onOriginalImageUpload,
                  onSymbolUploadHeight: this.onSymbolUploadHeight,
                  onSymbolResize: this.onSymbolResized,
                  selectedOrderId,
                  contentValues: area.content.value,
                  triggerContentValues: this.triggerContentValues,
                  onToggleFreeSize: this.toggleFreeSize,
                  area: this.props.areaExpanded,
                  setBlockSize: this.setBlockSize,
                  isLocked: this.props.isLocked,
                  maskImageUrl: editor.image?.mask?.imageUrl,
                  templateAreaId: area.content.value.templateAreaId,
                  documentFormatId: this.props.documentFormatId,
                  mediaId: this.props.mediaId,
                  setTextScaleWidthOffset: this.setTextScaleWidthOffset,
                  setMarginLeftRightOffset: this.setMarginLeftRightOffset,
                  setFields: this.setFields,
                  handleAreaOverFlowChange: this.handleAreaOverFlowChange,
                  toggleIsTextEditorReadOnly: this.toggleIsTextEditorReadOnly,
                  // setTextBlockSize: this.setTextBlockMeasurement
                }}
              >
                <Area
                  label={label}
                  onClick={this.handleClick}
                  onMouseEnter={this.handleMouseEnter}
                  onMouseLeave={this.handleMouseLeave}
                  isExpanded={isExpanded}
                  type={editor.type}
                  editTabs={this.props.editTabs}
                  validateErrors={this.props.area.content.validateErrors}
                  isFEUser={this.props.isFEUser}
                  isOverflowHidden={this.state.isExpandedAreaOverflowHidden!}
                  help={editor.help}
                >
                  {type === TEXT_HTML ? (
                    <HtmlInput
                      key={`HtmlInput_${this.props.area.id}`}
                      isVerseGalleryEnabled={
                        editor.text?.isEnabledGallery ?? false
                      }
                      manuallySetContentRows={this.manuallySetContentRows}
                      onXmlTextChange={this.handleXmlTextChange}
                      contentRows={this.state.contentRows}
                      editorBehaviour={editor.behaviour}
                      symbols={this.props.editor.text?.allowedCharacterGalleries
                        .filter(
                          (g) =>
                            g.isAdvancedUser === false ||
                            currentUserSession.user?.isSupportUser
                        )
                        .map((x) => x.items)
                        .reduce((a, b) => a.concat(b))}
                      paragraphClasses={
                        this.props.editor.text?.allowedParagraphClasses!
                      }
                      blockStyles={editor.common?.blockStyles ?? []}
                      onSelectBlockStyle={this.onSelectBlockStyle}
                      isExpanded={isExpanded}
                      isLocked={this.props.isLocked}
                      isEnabledMultiColumns={
                        editor.common?.isEnabledMultiColumns
                      }
                      isFEUser={this.props.isFEUser}
                    />
                  ) : null}

                  {type === GALLERY_IMAGE ? (
                    <ImageControl
                      key={`ImageControl_${this.props.area.id}`}
                      contentRows={editor.text?.isEnabledGallery ?? false}
                      blockStyles={editor.common?.blockStyles ?? []}
                      onSelectBlockStyle={this.onSelectBlockStyle}
                      templateAreaId={
                        this.props.area.content.value.templateAreaId!
                      }
                      documentFormatId={this.props.documentFormatId!}
                      behaviour={editor.behaviour}
                      mediaId={this.props.mediaId}
                      documentCollectionName={this.props.documentCollectionName}
                      selectedOrderId={this.props.selectedOrderId}
                    />
                  ) : null}

                  {type === SEPARATOR && this.state.visible === true ? (
                    <SeparatorControl
                      key={`SeparatorControl_${this.props.area.id}`}
                      blockStyles={editor.common?.blockStyles ?? []}
                      templateAreaId={
                        this.props.area.content.value.templateAreaId
                      }
                      documentFormatId={this.props.documentFormatId}
                      onSave={this.onSeparatorImageChanged}
                    />
                  ) : null}

                  {/* <ValidateAreaError validateErrors={this.props.area.content.validateErrors} /> */}
                </Area>

                {/* {isExpanded && isEnabledGallery ? (
          <VerseEditingModal
            setVerseInExpandedArea={this.setVerseInExpandedArea}
          />
        ) : null} */}
              </OrderEditorAreaContext.Provider>
            )
          }
          return null
        }}
      </AppContext.Consumer>
    )
  }
}

interface AreaContainerState {
  contentRows: GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows[]
  visible: boolean
  /** @deprecated */
  letterSpacing?: any
  /** @deprecated */
  textScale?: any
  // tslint:disable-next-line: max-line-length
  // margin: GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_common_margin | null
  isExpandedAreaOverflowHidden?: boolean;
}

interface OrderEditorAreaContextValues extends AreaContainerState {
  selectedOrderId: string
  toggleVisibility: () => void
  onXmlTextChange: (newText: string, rowIndex: number, colIndex: number) => void
  setTextAlignment: (textAlignment: TextAlignments, _?: any) => void
  setFontFamily: (fontFamily: string | null, _?: any) => void
  setMargin: (position: string, value: number) => void
  setLineHeight: (lineHeight: number | null, _?: any) => void
  setLineHeightEmpty: (lineHeightEmpty: number | null, _?: any) => void
  setFontSize: (fontSize: number | null, _?: any) => void
  setLetterSpacing: (letterSpacing: number | null, _?: any) => void
  setTextScale: (textScale: number | null, _?: any) => void
  setFontStyle: (fontSize: string | null, _?: any) => void
  setFontWeight: (fontSize: string | null, _?: any) => void
  setTextScaleWidthOffset: (value: number | null, _?: any) => void
  toggleNoWrap: () => void
  manuallySetContentRows: (contentRows: any[]) => void
  triggerContentValues: (
    callback?: () => void,
    errCallback?: (err: any) => void
  ) => void
  onSymbolSave: (
    symbolId: string,
    symbolPublicId: string,
    imageThumbnaill100x80Url: string,
    repository: EditModelAdvertAreaContentRepository,
    callback?: () => void
  ) => void
  onEditImageUpload: (
    fileData: any,
    fileName?: string,
    callback?: () => void
  ) => void
  onOriginalImageUpload: (
    fileData: any,
    fileName?: string,
    comment?: string,
    callback?: () => void
  ) => void
  onSymbolUploadHeight: (value: number, callback?: () => void) => void
  onSymbolResize: (symbolSize: number | null, callback?: () => void) => void
  editor: GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor
  contentValues: GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value
  onToggleFreeSize: (value: boolean) => void
  area:
    | GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas
    | undefined
  setBlockSize: (
    newValue: number | null,
    type: string,
    callback?: () => void
  ) => void;
  // setTextBlockSize: (newValue: number | null, type: string, callback?: () => void) => void
  isLocked: boolean;
  maskImageUrl: string | null | undefined;
  templateAreaId: string | null;
  documentFormatId: string | undefined;
  mediaId: string | undefined | null;
  setMarginLeftRightOffset: (
    value: number | null,
    callback?: () => void
  ) => void;
  setFields: (list: Array<fieldsType>, callback?: () => void) => void;
  toggleIsTextEditorReadOnly: (arg: boolean) => void
  handleAreaOverFlowChange: (arg: boolean) => void
}

interface AreaContainerProps {
  id: string
  isExpanded: boolean
  label: string
  editor: GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor
  area: GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas
  selectedOrderId: string
  onUpdateOrderContentValues: MutationFn
  onTogglePreviewIsUpdatingOn: () => void
  togglePreviewIsUpdatingOff: () => void
  onClick: (id: string) => void
  onSetHoveredAreaId: (id: string | undefined) => void
  callbackAfterUpdate?: () => void
  editTabs: GetEditOrderPageCaseFolder_caseFolder_editTabs[]
  isLocked: boolean
  containerRef?: React.MutableRefObject<HTMLDivElement | null>
  isAreaToAddVisible?: boolean
  isSeparatorRowAdded: boolean
  setIsSeparatorRowAdded: (arg: boolean) => void
  documentFormatId: string | undefined
  mediaId: string | undefined | null
  documentCollectionName: string | undefined
  areaExpanded:
    | GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas
    | undefined;
  isFEUser: boolean
}

export default AreaContainer
// setTextBlockMeasurement = (newValue: number | null, type: string, callback?: () => void) => {
//   this.setState(
//     produce(this.state, (draft) => {
//       if (draft.contentRows[0].columns[0].item.common === null) {
//         draft.contentRows[0].columns[0].item.common = {
//           blockStyleAreaNameId: null,
//           margin: cloneDeep(defaultMargin),
//           size: cloneDeep(defaultSize),
//         }
//       }
//       if (type === MeasurementInputTYPE.MAX_HEIGHT){
//         console.log("mh",newValue)
//         draft.contentRows[0].columns[0].item.common.size!.maxHeight = newValue
//       }

//       if (type === MeasurementInputTYPE.MIN_HEIGHT)
//         draft.contentRows[0].columns[0].item.common!.size!.minHeight = newValue
//       if (type === MeasurementInputTYPE.MAX_WIDTH)
//         draft.contentRows[0].columns[0].item.common!.size!.maxWidth = newValue
//       if (type === MeasurementInputTYPE.MIN_WIDTH)
//         draft.contentRows[0].columns[0].item.common!.size!.minWidth = newValue
//       if (type === MeasurementInputTYPE.FIXED_HEIGHT)
//         draft.contentRows[0].columns[0].item.common!.size!.fixedHeight = newValue
//       if (type === MeasurementInputTYPE.FIXED_WIDTH)
//         draft.contentRows[0].columns[0].item.common!.size!.fixedWidth = newValue
//     }),
//     () => {
//       this.handleUpdateThrottled()
//     }
//   )
// }
