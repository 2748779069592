import gql from "graphql-tag"

const GET_CURENT_CUSTOMER_ALL_PRODUCTS = gql`
query GetCurrentCustomerAllProducts {
  currentCustomer {
    id
    office {
      id
      name
      company {
        id
        customerContract {
          id
          name
          customerProducts(isFillAllProducts:true,  isFillStandardProducts:true, isLimitStandardProducts:false, isLimitVisibleProducts:true) {
            product {
              id
              name
              isStandardProduct : isInCollection(collectionFlag:2)
            }
            contractCustomer {
              price
              isStored
            }
            contractEndCustomer {
              price
              isStored
            }
          }
        }
      }
    }
  }
}
`

export default GET_CURENT_CUSTOMER_ALL_PRODUCTS
