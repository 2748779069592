import * as React from "react"
import * as styles from "./styles.scss"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { MutationFn } from "react-apollo"
import { motion, AnimatePresence } from "framer-motion"
import cx from "classnames"
import { useTranslation, withTranslation, WithTranslation } from "react-i18next"
import { error } from "appLog"
import produce from "immer"
import Tooltip from "components/Tooltip"
import { AppContext } from "App"

const UpdateAllFieldsButton: React.FC<{
  onClick: any
  text: any
  disabled?: boolean
  type?: "primary" | "secondary"
  isTooltipActive: boolean
  isAreaInner: boolean
}> = (props) => {
  const { t } = useTranslation(["editOrder"])
  const {featureFlags} = React.useContext(AppContext)
  const [state, setState] = React.useState<{
    isLoading: boolean
    showSuccess: boolean
  }>({
    showSuccess: false,
    isLoading: false,
  })

  const handleClick = () => {
    if (state.isLoading) {
      return
    }

    setState(
      produce(state, (draft) => {
        draft.isLoading = true
      })
    )

    props
      .onClick()
      .then(() => {
        setState(
          produce(state, (draft) => {
            draft.isLoading = false
            draft.showSuccess = true
          })
        )

        setTimeout(() => {
          setState(
            produce(state, (draft) => {
              draft.showSuccess = false
            })
          )
        }, 5000)
      })
      .catch((err: string) => {
        error(err)
      })
  }

  const convertedString = props.text.split(",").filter((t:string) => t != "false")

  return (
    <div className={cx(styles.updateAllContainer,{
      [styles.areaUpdateWrapper]: props.isAreaInner,
      [styles.feView__updateAllContainer]: featureFlags?.isFEUser!
    })}>
      <div
        className={cx(styles.updateAll, styles[props.type ?? "secondary"], {
          [styles.disabled]:  state.isLoading,
          [styles.loading]: state.isLoading,
        })}
        data-test-id="update_all_fields_in_container"
        onClick={handleClick}
      >
        {props.isTooltipActive ? (
          <>
            <Tooltip className={styles.updateAllTooltip}  text={convertedString[0]} id={"updateAll"}>
              <FontAwesomeIcon icon="sync-alt" className={styles.icon}  />
            </Tooltip>
          </>
        ) : (
          <>
            <FontAwesomeIcon icon="sync-alt" className={styles.icon} />
            <span>{props.text}</span>
          </>
        )}
      </div>

      <AnimatePresence>
        {state.showSuccess && (
          <motion.p
            key="success"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -30 }}
            className={styles.success}
          >
            {t("fieldsUpdated")}
          </motion.p>
        )}
      </AnimatePresence>
    </div>
  )
}

export default UpdateAllFieldsButton
