import React from "react"
import * as styles from "./styles.scss"
import {
  faArrowCircleLeft,
  faArrowCircleRight,
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { DateTime } from "luxon"

import { useTranslation } from "react-i18next"
import ReactTable, { SortingRule } from "react-table-6"
import { GetStatOrders_statsOrders, StatsOrderSort } from "schema"
import cx from "classnames"
import ExpandedTable from "./ExpandedTable"
import { background } from "pages/NewsPage/styles.scss"

const TableWithData: React.FC<{
  startIndex: number
  count: number
  loading: boolean
  page: number
  pageSize: number
  caseFolders: GetStatOrders_statsOrders["caseFolders"]
  sortDescending: boolean
  sortField: StatsOrderSort
  changeStartIndex: (newValue: number) => void
  changePageLength: (newValue: number) => void
  changeSortDescending: (newValue: boolean) => void
  changeSortField: (newValue: StatsOrderSort) => void
  setSortDescending: any
  refetch: any
  setPage: any
}> = (props) => {
  const {
    caseFolders,
    pageSize,
    page,
    sortDescending,
    sortField,
    changeStartIndex,
    changePageLength,
    changeSortDescending,
    changeSortField,
    count,
    loading,
    refetch,
    setPage,
    startIndex,
  } = props
  const { t } = useTranslation([
    "archive",
    "dashboard",
    "statuses",
    "common",
    "dateFormats",
    "agencySettings",
  ])
  const [expandedRowIndex, setExpandedRowIndex] = React.useState<any>(undefined)

  const handleExpandRow = (_: any, index: number[]) => {
    setExpandedRowIndex(expandedRowIndex === index[0] ? undefined : index[0])
  }

  // console.log(props.caseFolders)

  return (
    <div className={styles.table}>
      <ReactTable
        className={"static  -highlight"}
        data={caseFolders}
        manual={true}

        columns={[
          {
            expander: true,
            Header:" ",
            Expander: ({ isExpanded, ...rest }) => (
              <div>
                {/* {isExpanded ? (
                  <span>&#x2299;</span>
                ) : (
                  <span>&#x2295;</span>
                )} */}
              </div>
            ),
            style: {
              backgroundColor: "#ffffff00",
            },
          },
          {
            id: "name",
            Header: t("agencySettings:folder"),
            accessor: "name",
            sortable: true,
          },
          {
            id: "order",
            Header: "Produkter",
            accessor: "orders.length",
            sortable: true,
          },
          {
            id: "pricePay",
            Header: t("agencySettings:revenue"),
            sortable: true,
            accessor: (row) =>
              Number(
                row.orders.reduce(
                  (sum: number, price: any) =>
                    sum + price.lockedPrice.endCustomer.pricePay,
                  0
                )
              ).toFixed(0) + " kr",
          },
          {
            id: "priceEarnings",
            Header: t("agencySettings:officeCredit"),
            accessor: (row) =>
              Number(
                row.orders.reduce(
                  (sum: number, price: any) =>
                    sum + price.lockedPrice.customer?.priceEarnings,
                  0
                )
              ).toFixed(0) + " kr",
            sortable: false,
          },
        ]}
        SubComponent={(row: any) => {
          return <ExpandedTable data={row.original.orders} />
        }}
        getTrGroupProps={(_: any, b: any) => ({
          className: cx(styles.trGroup, {
            [styles.expanded]:  b && b.index === expandedRowIndex,
          }),
        })}
        PreviousComponent={(page: any) => {
          return (
            <>
              <span onClick={page.onClick}>
                <FontAwesomeIcon icon={faArrowCircleLeft} />
              </span>
            </>
          )
        }}
        NextComponent={(page: any) => {
          return (
            <>
              <span onClick={page.onClick}>
                <FontAwesomeIcon icon={faArrowCircleRight} />
              </span>
            </>
          )
        }}
        sortable={true}
        ofText={t("common:of")}
        pageText={t("common:page")}
        loadingText={t("common:loading")}
        rowsText={t("common:rows")}
        noDataText={t("common:noResults")}
        showPagination={true}
        showPaginationBottom={true}
        showPaginationTop={false}
        showPageSizeOptions={false}
        onPageChange={(newPage: number) => {
          changeStartIndex(pageSize * newPage)
          setPage(newPage)
        }}
        expanded={{
          [expandedRowIndex as any]: true,
        }}
        onExpandedChange={handleExpandRow}
        defaultSorted={[
          {
            id: "order",
            desc: true
          }
        ]}
        defaultPageSize={10}
        // onSortedChange={(
        //   newSorted: SortingRule[],
        //   column: any,
        //   additive: boolean
        // ) => {
        //   if (newSorted[0].id === "name") {
        //     changeSortField(StatsOrderSort.CASE_FOLDER)
        //     changeSortDescending(newSorted[0].desc)
        //   }
        //   if (newSorted[0].id === "date") {
        //     changeSortField(StatsOrderSort.ORDER_DATE)
        //     changeSortDescending(newSorted[0].desc)
        //   }
        //   if (newSorted[0].id === "product") {
        //     changeSortField(StatsOrderSort.PRODUCT)
        //     changeSortDescending(newSorted[0].desc)
        //   }
        // }}

        loading={loading}
        pages={count === undefined ? 0 : Math.floor(count / pageSize) +1}
        page={page}
        pageSize={pageSize}
      />

      {/* <ReactTable
          className={"static -striped -highlight"}
          data={orders}
          manual={true}
          
          columns={[
            {
              id: "name",
              Header: t("agencySettings:folder"),
              accessor: (row) => row ?? "-",
              sortable: true,
            },
            {
              id: "date",
              Header: t("agencySettings:date"),
              sortable: true,
              accessor: (row) => {
                if (
                  row.mediaOrder?.publishTimes &&
                  row.mediaOrder?.publishTimes[0] &&
                  row.mediaOrder?.publishTimes[0].time
                ) {
                  return DateTime.fromJSDate(
                    new Date(row.mediaOrder?.publishTimes[0].time)
                  ).toFormat(t("dateFormats:date"))
                }
                return "-"
              },
            },
            {
              id: "product",
              Header: t("agencySettings:StatisticsTab_products"),
              accessor: (row) => row.product?.name ?? "-",
            },
            {
              id: "pricePay",
              Header: t("agencySettings:revenue"),
              accessor: (row) =>
                `${
                  Number(row.lockedPrice?.endCustomer?.pricePay).toFixed(2) ?? 0
                }:-`,
              sortable: false,
            },
            {
              id: "priceEarnings",
              Header: t("agencySettings:officeCredit"),
              accessor: (row) =>
                `${
                  Number(row.lockedPrice?.customer?.priceEarnings).toFixed(2) ?? 0
                }:-`,
              sortable: false,
            },
            
          ]}
          PreviousComponent={(page: any) => {
            return (
              <>
                <span onClick={page.onClick}>
                  <FontAwesomeIcon icon={faArrowCircleLeft} />
                </span>
              </>
            )
          }}
          NextComponent={(page: any) => {
            return (
              <>
                <span onClick={page.onClick}>
                  <FontAwesomeIcon icon={faArrowCircleRight} />
                </span>
              </>
            )
          }}
          
          // sortable={ true}
          // nextText={t("common:next")}
          // previousText={t("common:previous")}
          ofText={t("common:of")}
          pageText={t("common:page")}
          loadingText={t("common:loading")}
          rowsText={t("common:rows")}
          noDataText={t("common:noResults")}
          showPagination={true}
          showPaginationBottom={true}
          showPaginationTop={false}
          // multiSort={false}
          showPageSizeOptions={false}
          // showFilters={true}
          onPageChange={(newPage: number) => {
            changeStartIndex(pageSize * newPage)
            setPage(newPage)
          }}

          onSortedChange={(
            newSorted: SortingRule[],
            column: any,
            additive: boolean,
     
          ) => {
            if (newSorted[0].id === "name") {
              changeSortField(StatsOrderSort.CASE_FOLDER)
              props.setSortDescending(!props.sortDescending)
            }
            if (newSorted[0].id === "date") {
              changeSortField(StatsOrderSort.ORDER_DATE)
              props.setSortDescending(!props.sortDescending)
            }
            if (newSorted[0].id === "product") {
              changeSortField(StatsOrderSort.PRODUCT)
              props.setSortDescending(!props.sortDescending)
            }
          }}
          loading={loading}
          pages={count === undefined ? 1 : Math.floor(count / pageSize) + 1}
          page={page}
          pageSize={pageSize}
        /> */}
    </div>
  )
}

export default TableWithData
