import * as React from "react"
import ErrorMessage from "components/ErrorMessage"
import { useApolloClient, useQuery } from "react-apollo-hooks"
import {
  GetEditOrderPageCaseFolder_caseFolder_editTabs_orders,
  GetOrderByIdLiveStream,
  GetOrderByIdLiveStreamVariables,
} from "schema"
import Loading from "../Loading"
import GET_ORDER_BY_ID_LIVE_STREAM from "graphql/queries/getOrderByIdLivestream"
import { StageBeforeActivated } from "./StageBeforeActivated"
import { StageActivated } from "./StageActivated"
import { AppContext } from "App"

export const OrderEditoryLiveStream: React.FC<{
  orderId: string
  orders: GetEditOrderPageCaseFolder_caseFolder_editTabs_orders[]
  caseFolderId: string
  caseFolderName: string
}> = (props) => {
  const [orderResult, setOrderResult] = React.useState<any>(null)
  const isSupportUser = React.useContext(AppContext).currentUserSession.user
  ?.isSupportUser
  const client = useApolloClient()
  const { loading, data, error, refetch } = useQuery<
    GetOrderByIdLiveStream,
    GetOrderByIdLiveStreamVariables
  >(GET_ORDER_BY_ID_LIVE_STREAM, {
    client,
    variables: {
      id: props.orderId,
    },
    context: {
      debounceKey: "livestream",
      debounceTimeout: 250,
    },
    fetchPolicy: "network-only",
  })


  if (loading) {
    return <Loading />
  }


  if (!(data && data.orders && data.orders.length > 0)) {
    return <Loading />
  }
  if (!data?.orders?.[0].mediaOrder?.edit?.editModelLiveStream) {
   return <Loading />
  }

  const stageIsActivated =
    data.orders[0].mediaOrder.isLocked ?? false

  const waitImageUrl =
    data.orders[0].mediaOrder?.edit.editModelLiveStream.waitImageUrl ??
    undefined

  const publishTimes =
    data.orders[0].mediaOrder.publishTimes?.[0]?.time ?? undefined

  return (
    <div>
      {stageIsActivated === false ? (
        <StageBeforeActivated
          orderId={props.orderId}
          orders={props.orders}
          caseFolderId={props.caseFolderId}
          caseFolderName={props.caseFolderName}
          waitImageUrl={waitImageUrl}
          publishTimes={publishTimes}
          order={data.orders[0]}
          refetchQuery={refetch}
          isSupportUser={isSupportUser!}
          orderResult={orderResult}
          setOrderResult={setOrderResult}
        />
      ) : (
        <StageActivated
          orderId={props.orderId}
          orders={props.orders}
          caseFolderId={props.caseFolderId}
          caseFolderName={props.caseFolderName}
          waitImageUrl={waitImageUrl}
          publishTimes={publishTimes}
          order={data.orders[0]}
          refetchQuery={refetch}
          isSupportUser={isSupportUser!}
          orderResult={orderResult}
          setOrderResult={setOrderResult}
        />
      )}
    </div>
  )
}
