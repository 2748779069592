import gql from "graphql-tag"

const GET_ORDER_PROOF = gql`
  query GetOrderProof($orderIds: [ID!]!) {
    orderCollection(ids: $orderIds) {
      material {
        proof {
          url
        }
      }
    }
  }
`

export default GET_ORDER_PROOF
