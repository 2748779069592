import * as React from "react"
import * as styles from "./styles.scss"
import Header from "components/Header"
import * as Background from "images/image.png"
import Whiteframe from "components/Whiteframe"
import NewsProvider from "components/NewsPreview/NewsProvider"
import NewsPreview from "components/NewsPreview"
import { useTranslation} from "react-i18next"
import BackgroundImage from "components/BackgroundImage"
import MainLayout from "layouts/MainLayout"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {faArrowAltCircleLeft} from "@fortawesome/free-solid-svg-icons"
import {useHistory} from "react-router-dom"

const NewsPage: React.FC<any> = (props) =>  {
  const {t} = useTranslation(["news"])
  const history = useHistory()

 const handleBackBtn =  () => {
      history.push("/dashborad")
  } 

    return (
      <MainLayout showUtilityBar={false}>
        <BackgroundImage src={Background} />
        <NewsProvider
          render={newsItems => (
            <Whiteframe className={styles.content}>
              <div className={styles.newsHeader}>
              <Header title={t("news")} subtitle={t("subtitle")}/>
              <button onClick={handleBackBtn}>
              <FontAwesomeIcon icon={faArrowAltCircleLeft}/>
               <span>{t("back")}</span>
                </button>
              </div>
              
              <div className={styles.newsList}>
                {newsItems.map(item => (
                  <NewsPreview
                    key={item.id}
                    {...item}
                    className={styles.newsPreview}
                  />
                ))}
              </div>
            </Whiteframe>
          )}
        />
      </MainLayout>
    )
  }


export default NewsPage
