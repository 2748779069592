import * as React from "react"
import * as styles from "./styles.scss"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { OpenTab, SupportViewContext } from "components/SupportView"
import cx from "classnames"

function Tab(props: TabProps) {
  const { closeTab, onTabClick, selectedOpenTab } = React.useContext(
    SupportViewContext
  )

  const handleRemove = (e: React.MouseEvent) => {
    e.stopPropagation()
    closeTab(props.tab.orderId)
  }

  const handleClick = () => onTabClick(props.tab)

  const isSelected = () =>
    selectedOpenTab && selectedOpenTab.orderId === props.tab.orderId

  return (
    <div
      className={cx(styles.tab, { [styles.selected]: isSelected() })}
      onClick={handleClick}
    >
      <span>{props.tab.customerFriendlyId}</span>
      <div className={styles.iconContainer} onClick={handleRemove}>
        <FontAwesomeIcon icon="times-circle" />
      </div>
    </div>
  )
}

interface TabProps {
  tab: OpenTab
}

export default React.memo(Tab)
