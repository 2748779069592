import gql from "graphql-tag"
import USER_INTERFACE_FRAGMENT from "graphql/fragments/UserInterfaceFragment"

const GET_ORDER_BY_ID_LIVE_STREAM = gql`
query GetOrderByIdLiveStream($id: ID!) {
  orders(ids: [$id]) {
    id
    customerFriendlyId
    mediaOrder {
      order {
        customerFriendlyId
        mediaOrder {
          
          preview {
            price(withVAT: true, withRounding:STANDARD, priceView: WITH_SERVICE_ORDER) {
              currency
              endCustomer {
                pricePayRounded
              }
            }
          }
          status {
            color
            status
          }
        }
      }

      timeBookingStop
      isLocked
      publishChannels {
				inFamiljesidan
				inMinnesrummet
				inAgency
				__typename
			}
      publishTimes {
        time
        __typename
      }
      edit {
        userInterface {
          ...userInterfaceFragment
        }
        editModelType
        editModelLiveStream {
          isStored
          isActivated
          timeLive
          waitImageUrl
          ceremonyAgendaUrl
          advanced {
            playerUrl
            ingestionKey
            ingestionUrl
            streamName
            RecorderLarix500: recorderUrl(
              recorder: LARIX
              platform: IOS
              bitRate: 500
            )
            RecorderLarix1000: recorderUrl(
              recorder: LARIX
              platform: IOS
              bitRate: 1000
            )
            RecorderLarix1500: recorderUrl(
              recorder: LARIX
              platform: IOS
              bitRate: 1500
            )
            RecorderLarix2000: recorderUrl(
              recorder: LARIX
              platform: IOS
              bitRate: 2000
            )
            GoCoderIOS: recorderUrl(
              recorder: GOCODER
              platform: IOS
              bitRate: 2000
            )
            GoCoderAndriod: recorderUrl(
              recorder: GOCODER
              platform: ANDRIOD
              bitRate: 2000
            )
            qrRecorderLarix500: qrUrl(
              recorder: LARIX
              platform: IOS
              bitRate: 500
            )
            qrRecorderLarix1000: qrUrl(
              recorder: LARIX
              platform: IOS
              bitRate: 1000
            )
            qrRecorderLarix1500: qrUrl(
              recorder: LARIX
              platform: IOS
              bitRate: 1500
            )
            qrRecorderLarix2000: qrUrl(
              recorder: LARIX
              platform: IOS
              bitRate: 2000
            )
            qrGoCoderIOS: qrUrl(recorder: GOCODER, platform: IOS, bitRate: 2000)
            qrGoCoderAndriod: qrUrl(
              recorder: GOCODER
              platform: ANDRIOD
              bitRate: 2000
            )
            GoCoderAndroid: recorderUrl(
              recorder: GOCODER
              platform: ANDRIOD
              bitRate: 2000
            )
            __typename
          }
          __typename
        }
        __typename
      }
      __typename
    }
    __typename
  }
}
${USER_INTERFACE_FRAGMENT}
`

export default GET_ORDER_BY_ID_LIVE_STREAM
