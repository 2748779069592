import gql from "graphql-tag"

const GET_GALLERY_IMAGE_TAGS_BY_TAG_GROUPS = gql`
  query GetGalleryImageTagsByTagGroup($input: GalleryImageTagsInput!) {
    galleryImagesTagsByTagGroups(input: $input) {
      tags
    }
  }
`

export default GET_GALLERY_IMAGE_TAGS_BY_TAG_GROUPS
