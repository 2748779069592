import gql from "graphql-tag"

const CREATE_IMAGE_TAG = gql`
  mutation CreateImageTag($input: MutationCreateGalleryImageTagsInput!) {
    createGalleryImageTags(input: $input) {
      errorReason
      tags
    }
  }
`

export default CREATE_IMAGE_TAG
