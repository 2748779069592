import * as React from "react"
import Searchfield from "components/Searchfield"
import OptionDropdown from "components/OptionDropdown"
import { DropdownOption } from "components/DropdownButton"
import * as styles from "./styles.scss"
import { useTranslation, withTranslation, WithTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowAltCircleLeft } from "@fortawesome/free-solid-svg-icons"
import { useHistory } from "react-router"
import TitleWithDropDown from "components/TitleWithDropdown"
import Toggler from "components/Toggler"
import { AppContext } from "App"

const Searchbar: React.FC<SearchbarProps> = (props) => {
  const { t } = useTranslation(["archive", "statuses"])
  const { isShowModeShow, toggleShowModeView } = React.useContext(AppContext)
  const history = useHistory()
  const {
    caseFoldersFilterOptions,
    statusOptions: statusesOptions,
    selectedStatusOption,
    selectedCaseFolderOption,
    onCaseFolderFilterChange,
    onStatusFilterChange,
    onSearchFieldChange,
  } = props

  const handleToHomePage = () => {
    history.push("/dashboard")
  }

  return (
    <div className={styles.searchbar}>
      {/* <h3 className={styles.fileName}>{t("advertFile")}</h3> */}
      <TitleWithDropDown
        className={styles.titleWithDropdown}
        title={t("advertFile")}
        listItem={
          <li>
            <span>Visa / Dölj</span>
            <Toggler onChange={toggleShowModeView} checked={isShowModeShow} />
          </li>
        }
      />
      <Searchfield
        onChange={onSearchFieldChange}
        placeholder={t("search")}
        className={styles.searchField}
      />

      <div className={styles.dropDownWrapper}>
        <span className={styles.filterLabel}>{t("filter")}: </span>

        <OptionDropdown
          options={caseFoldersFilterOptions}
          onChange={onCaseFolderFilterChange}
          selectedLabel={t(selectedCaseFolderOption.label)}
          className={styles.caseFolderOptionDropdown}
          togglerClassName={styles.caseFolderOptionDropdownToggler}
        />
      </div>

      <OptionDropdown
        options={statusesOptions}
        onChange={onStatusFilterChange}
        selectedLabel={t(`statuses:${selectedStatusOption.label}`)}
        className={styles.optionDropdown}
        togglerClassName={styles.caseFolderOptionDropdownToggler}
      />
      <div className={styles.linkWrapper} onClick={handleToHomePage}>
        <FontAwesomeIcon icon={faArrowAltCircleLeft} size={"lg"} />
        <span>{t("toHomePage")}</span>
      </div>
    </div>
  )
}

interface SearchbarProps {
  caseFoldersFilterOptions: DropdownOption[]
  statusOptions: DropdownOption[]
  selectedStatusOption: DropdownOption
  selectedCaseFolderOption: DropdownOption
  onStatusFilterChange: (option: DropdownOption) => void
  onCaseFolderFilterChange: (option: DropdownOption) => void
  onSearchFieldChange: (event: React.ChangeEvent) => void
}

export default Searchbar
