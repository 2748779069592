import gql from "graphql-tag"

const INVOKE_ACTION = gql`
  mutation InvokeAction($input: MutationInvokeActionInput!) {
    invokeAction(input: $input) {
      errorReason
      result {
        responseAction
        text
        success
      }
    }
  }
`

export default INVOKE_ACTION
