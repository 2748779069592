import { SessionStorageKeys } from "environmentUtils"
import * as React from "react"
import { MutationFn } from "react-apollo"
import { withRouter, RouteComponentProps } from "react-router-dom"

class InnerMagicLinkAuthenticator extends React.PureComponent<
  InnerMagicLinkAuthenticatorProps & RouteComponentProps
> {
  componentDidMount = () =>
    this.props
      .onLogin()
      .then((res: any) => {
        const cred = res.data.loginByCredential
        sessionStorage.setItem(
          SessionStorageKeys.AUTH_TOKEN,
          cred.authorizationToken
        )
        this.props.history.push(this.props.redirectTo)
      })
      .catch(() => console.error("An error occured when trying to log in."))

  render() {
    return null
  }
}

interface InnerMagicLinkAuthenticatorProps {
  onLogin: MutationFn
  redirectTo: string
}

export default withRouter(InnerMagicLinkAuthenticator)
