import * as React from "react"
import * as styles from "./styles.scss"
import Button from "components/Button"
import Dots from "./Dots"
import { WithTranslation, withTranslation } from "react-i18next"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {faArrowCircleRight, faArrowCircleLeft} from "@fortawesome/free-solid-svg-icons"

class Navigation extends React.PureComponent<
  NavigationProps & WithTranslation
> {
  render() {
    const {
      nextSlide,
      prevSlide,
      isNextButtonDisabled,
      t,
      goToSlide,
      isDotDisabled,
      slides,
      currentSlideIndex,
      disableDots,
      orderLength,
    } = this.props

    return (
      <div className={styles.navigationContainer}>
        {orderLength && orderLength > 3 ? (
          <>
            <Button
              onClick={prevSlide}
              disabled={currentSlideIndex === 0}
              color="gray"
            >
              <FontAwesomeIcon icon={faArrowCircleLeft} />
            </Button>
            {disableDots ? (
              <div className={styles.dotReplacement} />
            ) : (
              <Dots
                slides={slides}
                goToSlide={goToSlide}
                isDotDisabled={isDotDisabled}
                currentSlideIndex={currentSlideIndex}
              />
            )}
            <Button
              onClick={nextSlide}
              color="gray"
              disabled={
                isNextButtonDisabled
                  ? isNextButtonDisabled(
                      React.Children.count(this.props.slides)
                    )
                  : undefined
              }
            >
              {/* {t("next")} */}
              <FontAwesomeIcon icon={faArrowCircleRight} />
            </Button>
          </>
        ) : null}
      </div>
    )
  }
}

interface NavigationProps {
  currentSlideIndex: number
  slides: React.ReactNode
  disableDots?: boolean
  nextSlide?: () => void
  prevSlide?: () => void
  goToSlide?: (index: number) => void
  isDotDisabled?: (index: number) => boolean
  isNextButtonDisabled?: (slideCount: number) => boolean
  orderLength?: number
}

export default withTranslation()(Navigation)
