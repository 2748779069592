import * as React from "react"
import * as styles from "./styles.scss"
import * as cx from "classnames"
import CaseFolderContainer from "./CaseFolderContainer"
import Details from "./Details"
import Spinner from "components/Spinner"
import { useQuery } from "react-apollo-hooks"
import GET_ADMIN_CASE_FOLDERS from "graphql/queries/getAdminCaseFolders"
import { SupportViewContext } from "components/SupportView"
import { GetAdminCaseFolders_findCaseFoldersByConditions_caseFolders } from "schema"
import NoResults from "../NoResults"
import { DateTime } from "luxon"

const OrderView: React.FC<OrderViewProps> = props => {
  const { selectedDetailOrder } = React.useContext(SupportViewContext)

  const getDateFromDaysBack = () =>
    DateTime.fromJSDate(new Date())
      .minus({ days: props.daysBack })
      .toFormat("yyyy-MM-dd")

  const { data, loading, refetch } = useQuery(GET_ADMIN_CASE_FOLDERS, {
    variables: {
      timeFrom: getDateFromDaysBack(),
      limit: props.maxResults,
      condition: {
        type: "AND",
        childConditions: [
          { type: "CASE_FOLDER_PERSON_NAME", compareValue: props.searchValue },
        ],
      },
    },
    skip: props.searchValue === "",
  })

  if (loading) return <Spinner />

  const caseFolders: GetAdminCaseFolders_findCaseFoldersByConditions_caseFolders[] =
    data && data.findCaseFoldersByConditions
      ? data.findCaseFoldersByConditions.caseFolders
      : []

  return (
    <div>
      {selectedDetailOrder && (
        <Details onRefetch={refetch} tab={selectedDetailOrder} />
      )}
      <div
        className={cx(
          styles.container,
          selectedDetailOrder ? styles.containerWithDetails : undefined
        )}
      >
        <div className={styles.caseFolders}>
          {caseFolders.length ? (
            caseFolders.map(caseFolder => (
              <CaseFolderContainer
                caseFolder={caseFolder}
                key={caseFolder.idString!}
              />
            ))
          ) : (
            <NoResults />
          )}
        </div>
      </div>
    </div>
  )
}

interface OrderViewProps {
  searchValue: string
  daysBack: number
  maxResults: number
}

export default React.memo(OrderView)
