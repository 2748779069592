import * as React from "react"
import * as styles from "./styles.scss"
import Whiteframe from "components/Whiteframe"
import Button from "components/Button"
import ProofColumn from "./ProofColumn"
import { GetReviewCaseFolder_caseFolder_documents } from "schema"
import Header from "components/Header"
import TotalPrice from "./TotalPrice"
import ProofModal from "components/ProofModal"
import { notEmpty } from "utils"
import OrderInformation from "./OrderInformation"
import { WithTranslation, withTranslation } from "react-i18next"
import PublishOrdersModal from "./PublishOrdersModal"
import AdvertTotalPrice from '../DocumentTypeCard/PublishOrdersModal/AdvertsTotalPrice'

class DocumentTypeCard extends React.PureComponent<
  DocumentTypeCardProps & WithTranslation,
  DocumentTypeCardState
> {
  state = { publishOrdersModalIsOpen: false }

  openPublishOrdersModal = () =>
    this.setState({ publishOrdersModalIsOpen: true })

  closePublishOrdersModal = () =>
    this.setState({ publishOrdersModalIsOpen: false })

  render() {
    const { document, t } = this.props
    
    const { publishOrdersModalIsOpen } = this.state

    if (!document.orders[0]) return null

    const orders = document.orders.filter(notEmpty)

    
    return (
      <Whiteframe className={styles.documentTypeCard}>
        <Header title={document.documentFormat.documentType.name} />
        <div className={styles.content}>
          <ProofColumn
            orders={orders}
            imageUrl={orders[0].mediaOrder!.preview.material.pages[0]!.png.url}
          />
          <div className={styles.orderInformation}>
            <Header
              title={t("insertionAndStatus")}
              subtitle={t("insertionAndStatusDescription")}
            />
            {orders.map(order => (
              <OrderInformation key={order.id!} order={order} />
            ))}
            <TotalPrice
              price={document.summary.price.endCustomer.pricePayRounded}
              onClick={this.openPublishOrdersModal}
            />
          </div>
          {/* <PublishOrdersModal
            isOpen={publishOrdersModalIsOpen}
            orders={orders}
            onClose={this.closePublishOrdersModal}
            totalPrice={document.summary.price.endCustomer.pricePay}
    
          /> */}
        </div>
      </Whiteframe>
    )
  }
}

interface DocumentTypeCardProps {
  document: GetReviewCaseFolder_caseFolder_documents
}

interface DocumentTypeCardState {
  publishOrdersModalIsOpen: boolean
}

export default withTranslation("review")(DocumentTypeCard)
