import gql from "graphql-tag"
import PREVIEW_IMAGE_FRAGMENT from "graphql/fragments/PreviewImageFragment"

const GET_PUBLISH_ORDER = gql`
query GetPublishOrders($caseFolderId: ID!, $documentId: ID) {
    caseFolder(id: $caseFolderId) {
      id
      documents(ids:[$documentId]) {
        caseFolder {
          id
        }
        id
        summary {
          price(priceView: WITH_SERVICE_ORDER, withVAT: true, withRounding:STANDARD) {
            endCustomer {
              pricePay
            }
          }
        }
        orders {
          id
          customerFriendlyId
          caseFolder {
            id
          }
          mediaOrder {
            isLocked
            media {
              id
              friendlyName
            }
            publishConversation {
              messageFromMedia
              codeLate
              isCodeLate
              isMessageToMedia
              isMediaSystemOrderNumber
              isMediaCustomerSubriberNumber
              publishReadyStatus
              isBookingSent
              validateErrorTexts
            }
            publishChannels {
              inFamiljesidan
              inMinnesrummet
              inAgency
            }
            preview {
              ...PreviewImageFragment
              price(withVAT: true, priceView: WITH_SERVICE_ORDER, withRounding:STANDARD) {
                currency
                endCustomer {
                  pricePayRounded
                }
              }
            }
            documentFormat {
              id
              sizeColumns
            }
            publishTimes {
              time
            }
            status {
              color
              status
            }
          }
        }
        documentFormat {
          id
          documentType {
            id
            name
          }
        }
      }
    }
  }
  
  fragment PreviewImageFragment on MediaOrderPreview {
    material {
      basePage {
        size(unit: MILLIMETER) {
          width
          height
        }
      }
      pages {
        png(resolution: 192, resolutionDeep:1.4) {
          url
        }
      }
    }
  }
  ${PREVIEW_IMAGE_FRAGMENT}
`

export default GET_PUBLISH_ORDER
