import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Calendar from "components/Calendar"
import React from "react"
import { StatsOrderPeriods } from "schema"
import styles from "../styles.scss"
import cx from "classnames"
import { useTranslation } from "react-i18next"
import moment from "moment"
import { HoverPeriod } from "customSchema.interface"
import Button from "components/Button"

const TimeFilter: React.FC<{
  // periodScope: string
  // customPeriodDayLength: number
  // customPeriodStart: Date
  // changeDuration: (newValue: StatsOrderPeriods) => void
  // changeDayLength: (newValue: number) => void
  // changeCustomPeriodStart: (newValue: Date) => void
  setCustomPeriodStart: any
  setCustomPeriodDayLength: any
  periodHover: HoverPeriod
  setPeriodHover: (arg: HoverPeriod) => void
}> = (props) => {
  const { t } = useTranslation(["agencySettings"])
  const [startDate, setStartDate] = React.useState<Date | null>(null)
  const [endDate, setEndDate] = React.useState<Date | null>(null)
  const FORMAT = "YYYY-MM-DD"
  const todayDate = moment(new Date())

  React.useEffect(() => selectLastMonth(), [])

  const selectYearToday = () => {
    props.setPeriodHover(HoverPeriod.YEAR_DAY)
    const startDate = moment(todayDate).startOf("year").format(FORMAT)
    const diff = todayDate.diff(startDate, "days") + 1
    props.setCustomPeriodStart(new Date(startDate))
    props.setCustomPeriodDayLength(diff)
  }

  const selectLastMonth = () => {
    props.setPeriodHover(HoverPeriod.LAST_MONTH)
    const lastMonth = moment(todayDate).subtract(1, "month")
    const startDate = lastMonth.startOf("month").format(FORMAT)
    const prevMonthLastDay = lastMonth.endOf("month").format(FORMAT)
    const diff = moment(prevMonthLastDay).diff(startDate, "days") + 1
    props.setCustomPeriodStart(new Date(startDate))
    props.setCustomPeriodDayLength(diff)
  }

  const selectLastThreeMonth = () => {
    props.setPeriodHover(HoverPeriod.LAST_THREE_MONTH)
    const startDate = moment(todayDate)
      .subtract(3, "month")
      .startOf("month")
      .format(FORMAT)
    const prevMonthLastDay = moment(todayDate)
      .subtract(1, "month")
      .endOf("month")
      .format(FORMAT)
    const diff = moment(prevMonthLastDay).diff(startDate, "days") + 1
    props.setCustomPeriodStart(new Date(startDate))
    props.setCustomPeriodDayLength(diff)
  }

  const selectLastYear = () => {
    props.setPeriodHover(HoverPeriod.LAST_YEAR)
    const lastYear = moment(todayDate).subtract(1, "year")
    const startDate = lastYear.startOf("year").format(FORMAT)
    const preYearLastDay = lastYear.endOf("year").format(FORMAT)
    const diff = moment(preYearLastDay).diff(startDate, "days") + 1
    props.setCustomPeriodStart(new Date(startDate))
    props.setCustomPeriodDayLength(diff)
  }

  const handleOpenCalender = () => {
    props.setPeriodHover(HoverPeriod.CUSTOM_PERIOD)
  }

  const updateQueryByFilterInput = () => {
    const start_date = moment(startDate).format(FORMAT)
    const end_date = moment(endDate).format(FORMAT)
    const diff = moment(end_date).diff(start_date, "days") + 1
    props.setCustomPeriodStart(new Date(start_date))
    props.setCustomPeriodDayLength(diff)
  }

  return (
    <div className={styles.customFilterRow}>
      <div className={styles.label}>
        <b>{t("selectPeriod")}</b>
      </div>

      <div className={styles.topButtonsWrapper}>
        <div
          className={cx(styles.topButton, {
            [styles.topButtonSelected]:
              props.periodHover === HoverPeriod.YEAR_DAY,
          })}
          onClick={selectYearToday}
        >
          {t("Year-Today")}
        </div>

        <div
          className={cx(styles.topButton, {
            [styles.topButtonSelected]:
              props.periodHover === HoverPeriod.LAST_MONTH,
          })}
          onClick={selectLastMonth}
        >
          {t("lastMonth")}
        </div>

        <div
          className={cx(styles.topButton, {
            [styles.topButtonSelected]:
              props.periodHover === HoverPeriod.LAST_THREE_MONTH,
          })}
          onClick={selectLastThreeMonth}
        >
          {t("lastThreeMonths")}
        </div>

        <div
          className={cx(styles.topButton, {
            [styles.topButtonSelected]:
              props.periodHover === HoverPeriod.LAST_YEAR,
          })}
          onClick={selectLastYear}
        >
          {t("lastYear")}
        </div>

        <div
          className={cx(styles.topButton, {
            [styles.topButtonSelected]:
              props.periodHover === HoverPeriod.CUSTOM_PERIOD,
          })}
          onClick={handleOpenCalender}
        >
          <FontAwesomeIcon icon="calendar-alt" />
          {t("customPeriod")}
        </div>
      </div>

      {props.periodHover === HoverPeriod.CUSTOM_PERIOD && (
        <div className={styles.calenderRow}>
          <Calendar
            selected={startDate}
            onChange={(date: Date) => setStartDate(date)}
            startDate={startDate}
            endDate={endDate}
          />
          {startDate && (
            <Calendar
              selected={endDate}
              onChange={(date: Date) => setEndDate(date)}
              endDate={endDate}
              minDate={startDate}
            />
          )}
          {startDate && endDate && (
            <Button
              className={styles.filterButton}
              onClick={updateQueryByFilterInput}
            >
              {t("filter")}
            </Button>
          )}
        </div>
      )}
    </div>
  )
}

export default TimeFilter
