const prefixMap = {
  pointerdown: "MSPointerDown",
  pointerup: "MSPointerUp",
  pointercancel: "MSPointerCancel",
  pointermove: "MSPointerMove",
  pointerover: "MSPointerOver",
  pointerout: "MSPointerOut",
  pointerenter: "MSPointerEnter",
  pointerleave: "MSPointerLeave",
  gotpointercapture: "MSGotPointerCapture",
  lostpointercapture: "MSLostPointerCapture",
  maxTouchPoints: "msMaxTouchPoints",
}

const detectPointerEvents: {
  hasApi: boolean
  requiresPrefix: boolean | undefined
  hasTouch: boolean | undefined
  maxTouchPoints: number
} = {
  hasApi: false,
  requiresPrefix: false,
  hasTouch: false,
  maxTouchPoints: 0,
}

const prefix = (value: any) => {
  return (detectPointerEvents.requiresPrefix && prefixMap[value]) || value
}

const update = () => {
  if (typeof window !== "undefined") {
    // reference for detection https://msdn.microsoft.com/en-us/library/dn433244(v=vs.85).aspx
    if ("PointerEvent" in window) {
      detectPointerEvents.hasApi = true
      detectPointerEvents.requiresPrefix = false

      // reference for detection https://msdn.microsoft.com/library/hh673557(v=vs.85).aspx
    } else if (window.navigator && "msPointerEnabled" in window.navigator) {
      detectPointerEvents.hasApi = true
      detectPointerEvents.requiresPrefix = true
    } else {
      detectPointerEvents.hasApi = false
      detectPointerEvents.requiresPrefix = undefined
    }
    detectPointerEvents.maxTouchPoints =
      (detectPointerEvents.hasApi &&
        window.navigator &&
        window.navigator[prefix("maxTouchPoints")]) ||
      undefined
    detectPointerEvents.hasTouch = detectPointerEvents.hasApi
      ? detectPointerEvents.maxTouchPoints > 0
      : undefined
  }
}

update()

export default detectPointerEvents
