import * as React from "react"
import * as styles from "./styles.scss"
import TextAlignment from "./TextAlignment"
import { OrderEditorAreaContext } from "../../.."
import { TextAlignments } from "schema"
import cx from 'classnames'

const ALIGN_PRIO = {
  LEFT: 1,
  CENTER: 2,
  RIGHT: 3,
  JUSTIFY_LAST_LEFT: 4,
  CENTER_LEFT: 5,
}
const sortByAlignPrio = (a: string, b: string) => {
  return ALIGN_PRIO[a] >= ALIGN_PRIO[b] ? 1 : -1
}

const TextAlignSelector: React.FC<{
  isFEUser: boolean
}> = (props) => {
  return (
    <OrderEditorAreaContext.Consumer>
      {({ editor, setTextAlignment, contentRows }) => {
        if (!contentRows) {
          return null
        }
        if (contentRows.length < 1) {
          return null
        }
        if (!editor) {
          return null
        }
        if (!editor.text) {
          return null
        }
        if (!editor.text.allowedTextAdjustments) {
          return null
        }
        return (
          <div className={cx("", {[styles.icons]: editor.text.allowedTextAdjustments.length > 0}) }>
            {editor.text.allowedTextAdjustments
              .filter((e) => e)
              .sort(sortByAlignPrio)
              .map((value) => {
                const textAlignment = contentRows[0].columns[0].item.text!
                  .textAlignment
                const selectedTextAlignment =
                  textAlignment === "DEFAULT"
                    ? editor.text?.defaultTextAdjustment
                    : textAlignment
                const getIcon = (selected: TextAlignments) => {
                  switch (selected) {
                    case TextAlignments.LEFT:
                      return "align-left"
                    case TextAlignments.CENTER:
                      return "align-center"
                    case TextAlignments.RIGHT:
                      return "align-right"
                    case TextAlignments.JUSTIFY_LAST_LEFT:
                      return "align-justify"
                    default:
                      return "align-left"
                  }
                }
                return (
                  <TextAlignment
                    key={value}
                    value={value}
                    onClick={setTextAlignment}
                    icon={getIcon(value)}
                    active={value === selectedTextAlignment}
                    isFEUser={props.isFEUser}
                  />
                )
              })}
          </div>
        )
      }}
    </OrderEditorAreaContext.Consumer>
  )
}

export default TextAlignSelector
