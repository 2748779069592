import * as React from "react"
import { SelectOrderInitiatorView_selectOrderInitiatorView_options } from "schema"
import { ErrorText } from "components/ErrorMessage"
import { useTranslation } from "react-i18next"
import { ItemWithImageAndDropdown } from "./ItemWithImageAndDropdown"
import ReactTooltip from "react-tooltip"
import styled from "styled-components"
import cx from "classnames"

export const LayoutTextboxLine: React.FC<{
  caseFolderId: string
  orderId?: string
  options: SelectOrderInitiatorView_selectOrderInitiatorView_options[]
  onCloseModal: any
  onSelectItem: (selector: string) => void
}> = (props) => {
  const [t] = useTranslation(["common"])
  return (
    <div className={"layout-TEXTBOX_LINE"}>
      {props.options.length === 0 ? (
        <div className={"error-wrapper"}>
          <ErrorText>{t("common:noResults")}</ErrorText>
        </div>
      ) : null}

      <div
        className={"items"}
        style={{
          gridTemplateColumns: `repeat(${props.options.length}, 1fr)`,
        }}
      >
        {props.options.map((option, index) => {
          if (!option) {
            return
          }
          const onSelectItem = (selector: string) => {
            props.onSelectItem(selector)
          }
          return (
            <DivContainer key={index}>
              <a
                data-tip={option.hoverText && option.hoverText}
                data-textid={option.name}
              >
                <div className={cx("option", {
                    ["disable"]: !option.isEnabled,
                  })}>
                  <ItemWithImageAndDropdown
                    key={`${index}_${option.selector}`}
                    caseFolderId={props.caseFolderId}
                    id={option.selector}
                    img={option.imageUrl ?? ""}
                    title={option.name ?? " [NO TITLE] "}
                    haveOptions={option.subView.options.length > 0}
                    hoverText={option.hoverText}
                    options={(option.subView.options ?? []).map((e) => {
                      return {
                        id: e.selector ?? "",
                        label: e.name ?? "",
                        isEnabled: e.isEnabled && e.isEnabled,
                        hoverText: e.hoverText,
                      }
                    })}
                    onSelectItem={onSelectItem}
                  />
                </div>
              </a>
              <ReactTooltip place="bottom" type="info" effect="solid" />
            </DivContainer>
          )
        })}
      </div>
    </div>
  )
}
const DivContainer = styled.div`
  .options {
    overflow: hidden !important;
  }
  .disable {
    pointer-events: none;
    color: gray !important;
  }
`
