import * as React from "react"
import * as styles from "./styles.scss"
import ViewSelectRow from "./ViewSelectRow"
import OrderView from "./OrderView"
import Searchfield from "components/Searchfield"
import { useTranslation } from "react-i18next"
import CustomerView from "./CustomerView"
import { useDebouncedCallback } from "use-debounce"
import AdvancedContainer from "./AdvancedContainer"
import AdvancedOrderContent from "./AdvancedOrderContent"

const DEBOUNCE_TIME = 1000
const DEFAULT_DAYS_BACK = 14
const DEFAULT_MAX_RESULTS = 20

function SearchTab() {
  const [t] = useTranslation("supportView")
  const [searchValue, setSearchValue] = React.useState("")
  const [showAdvanced, setShowAdvanced] = React.useState(false)
  const [daysBack, setDaysBack] = React.useState(DEFAULT_DAYS_BACK)
  const [maxResults, setMaxResults] = React.useState(DEFAULT_MAX_RESULTS)
  const [debounced, setDebounced] = React.useState("")
  const [currentView, setCurrentView] = React.useState(
    "order" as AvailableViews
  )
  const [debouncedCallback] = useDebouncedCallback(
    value => setDebounced(value),
    DEBOUNCE_TIME
  )

  const handleSearchfieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value)
    debouncedCallback(e.target.value)
  }

  const handleSetCurrentView = (view: AvailableViews) => {
    setSearchValue("")
    setShowAdvanced(false)
    setDebounced("")
    setCurrentView(view)
  }

  const toggleShowAdvanced = () => setShowAdvanced(!showAdvanced)

  const getSearchFieldPlaceholder = () =>
    `${t(currentView === "order" ? "searchOrder" : "searchCustomer")}...`

  const renderAdvancedOrderContent = () => (
    <AdvancedOrderContent
      initialDaysBack={daysBack}
      initialMaxResults={maxResults}
      setDaysBack={(value: number) => setDaysBack(value)}
      setMaxResults={(value: number) => setMaxResults(value)}
    />
  )

  return (
    <div className={styles.searchTabContainer}>
      <div className={styles.searchTabFieldContainer}>
        <Searchfield
          onChange={handleSearchfieldChange}
          value={searchValue}
          placeholder={getSearchFieldPlaceholder()}
        />
        <ViewSelectRow
          currentView={currentView}
          toggleShowAdvanced={toggleShowAdvanced}
          showAdvanced={showAdvanced}
          onChangeCurrentView={handleSetCurrentView}
        />
        {currentView === "order" && (
          <AdvancedContainer
            isExpanded={showAdvanced}
            content={renderAdvancedOrderContent}
          />
        )}
      </div>
      
      <div className={styles.searchTabResultContainer}>
        {currentView === "order" && (
          <OrderView
            searchValue={debounced}
            daysBack={daysBack}
            maxResults={maxResults}
          />
        )}
        {currentView === "customer" && <CustomerView searchValue={debounced} />}
      </div>
    </div>
  )
}

export type AvailableViews = "order" | "customer"

export default SearchTab
