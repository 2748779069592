import * as React from "react"
import * as styles from "./styles.scss"
import * as cx from "classnames"

const Grayframe: React.FC<GrayframeProps> = (props) => {
  const { className, children, elRef, id, ...rest } = props

  return (
    <div id={id} ref={elRef!} className={cx(styles.grayframe, className)} {...rest}>
      {children}
    </div>
  )
}

interface GrayframeProps {
  className?: string
  style?: React.CSSProperties
  onMouseOver?: () => void
  onMouseLeave?: () => void
  onMouseEnter?: () => void
  elRef?: any
  id?: string
}

export default Grayframe
