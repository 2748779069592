import * as React from "react"
import * as styles from "./styles.scss"
import OptionDropdown from "components/OptionDropdown"
import { useTranslation } from "react-i18next"
import { SupportViewContext, OpenTab } from "components/SupportView"
import { DropdownOption } from "components/DropdownButton"
import Status from "components/Status"

function Details(props: DetailsProps) {
  const [t] = useTranslation(["supportView", "statuses"])
  const {
    onInvokeAction,
    availableActions,
  } = React.useContext(SupportViewContext)

  const getDropdownOptions = () =>
    availableActions
      ? availableActions.map(action => ({
          value: action.actionId,
          label: action.name,
        }))
      : []

  const handleSelectAction = (option: DropdownOption) => {
    onInvokeAction(option.value, props.tab, props.onRefetch)
  }

  // const onSendToMemoriz = () => onInvokeAction("OrderSendToMemoriz")

  return (
    <div className={styles.details}>
      <div className={styles.detailsWrapper}>
        <div className={styles.labelRow}>
          <b>{t("details")}</b>
          <OptionDropdown
            onChange={handleSelectAction}
            options={getDropdownOptions()}
            selectedLabel={`${t("chooseAction")}...`}
            className={styles.statusDropdown}
            contentClassName={styles.dropdownContent}
          />
        </div>
        <div>
          <span>{t("order")}: </span>
          <b>{props.tab.customerFriendlyId}</b>
        </div>
        <Status
          showStatusLabel={true}
          variant={props.tab.status!.color}
          className={styles.status}
        >
          {t(`statuses:${props.tab.status!.status}`)}
        </Status>
        {/* <div>
          <button className={styles.sendToMemoriz} onClick={onSendToMemoriz}>
            {t("sendToMemoriz")}
          </button>
          <button className={styles.showBookingPage}>
            {t("showBookingPage")}
          </button>
        </div> */}
      </div>
    </div>
  )
}

interface DetailsProps {
  onRefetch: () => void
  tab: OpenTab
}

export default React.memo(Details)
