import * as React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons"
import { DropdownWithOptions } from "./DropdownWithOptions"
import useWindowSize from "useHook/useWindowSize"


export const ItemWithImageAndDropdown: React.FC<{
  caseFolderId: string
  id: any
  title: string
  img?: string
  haveOptions: boolean,
  hoverText:string|null,
  options: { id: string; label: string; isEnabled: boolean; hoverText?: string | any  }[]
  onSelectItem: (id: string) => void
}> = (props) => {
  const [isDropdownOpen, setIsOpen] = React.useState<boolean>(false)
  const {height} = useWindowSize()
  const haveDropdown = props.haveOptions === true && props.options.length > 0
  const onClickName = () => {
    if (haveDropdown) {
      setIsOpen(!isDropdownOpen)
    } else {
      onSelect(props.id)
    }
  }
  const onSelect = (id: string) => {
    if (haveDropdown) {
      setIsOpen(false)
    }
    if (props.onSelectItem) {
      props.onSelectItem(id)
    }
  }



  return (
    <div className={"item"} title={props.hoverText ?? undefined}>
      {props.img ? (
        <div className={"img"} >
          <img src={props.img} onClick={onClickName} data-testid={"select-advert-dropdown"} />
        </div>
      ) : null}

      <div className={"bottom"}>
        <div className={"title"} onClick={onClickName}>
          <div className={"value"}>{props.title}</div>

          {haveDropdown ? (
            <div className={"arrow"}>
              {isDropdownOpen === false ? (
                <FontAwesomeIcon icon={faChevronDown} size={"sm"} />
              ) : null}

              {isDropdownOpen === true ? (
                <FontAwesomeIcon icon={faChevronUp} size={"sm"} />
              ) : null}
            </div>
          ) : null}
        </div>

        {haveDropdown && isDropdownOpen === true ? (
          <DropdownWithOptions
            options={props.options}
            onClose={onClickName}
            onSelect={onSelect}
            isScreenHightTooShort={height <= 740}
          />
        ) : null}
      </div>
    </div>
  )
}
