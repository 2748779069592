import React from "react"
import * as styles from "./styles.scss"
import { useTranslation } from "react-i18next"
import { OrderEditorAreaContext } from "../.."
import MeasurementInput from "./MeasurementInput"
import produce from "immer"
import { MeasurementInputTYPE } from "customSchema.interface"

const BlockMeasurement = () => {
  const { contentRows, area, setBlockSize } = React.useContext(
    OrderEditorAreaContext
  )
  const [state, setState] = React.useState<IState | null>({
    maxHeight: null,
    minHeight: null,
    fixedHeight: null,
    maxWidth: null,
    minWidth: null,
    fixedWidth: null,
  })

  let maxHeightValue: number | null

  if (
    contentRows !== undefined &&
    contentRows[0] !== undefined &&
    contentRows[0].columns[0] !== undefined &&
    contentRows[0].columns[0].item !== undefined &&
    contentRows[0].columns[0].item.common !== undefined &&
    contentRows[0].columns[0].item.common?.size !== undefined &&
    contentRows[0].columns[0].item.common?.size?.maxHeight !== undefined
  ) {
    maxHeightValue = contentRows[0].columns[0].item.common?.size?.maxHeight
  }

  let minHeightValue: number | null 
  if (
    contentRows !== undefined &&
    contentRows[0] !== undefined &&
    contentRows[0].columns[0] !== undefined &&
    contentRows[0].columns[0].item !== undefined &&
    contentRows[0].columns[0].item.common !== undefined &&
    contentRows[0].columns[0].item.common?.size !== undefined &&
    contentRows[0].columns[0].item.common?.size?.minHeight !== undefined
  ) {
    minHeightValue = contentRows[0].columns[0].item.common?.size?.minHeight
  }

  let fixedHeightValue: number | null

  if (
    contentRows !== undefined &&
    contentRows[0] !== undefined &&
    contentRows[0].columns[0] !== undefined &&
    contentRows[0].columns[0].item !== undefined &&
    contentRows[0].columns[0].item.common !== undefined &&
    contentRows[0].columns[0].item.common?.size !== undefined &&
    contentRows[0].columns[0].item.common?.size?.fixedHeight !== undefined
  ) {
    fixedHeightValue = contentRows[0].columns[0].item.common?.size?.fixedHeight
  }

  let maxWidthValue: number | null
  if (
    contentRows !== undefined &&
    contentRows[0] !== undefined &&
    contentRows[0].columns[0] !== undefined &&
    contentRows[0].columns[0].item !== undefined &&
    contentRows[0].columns[0].item.common !== undefined &&
    contentRows[0].columns[0].item.common?.size !== undefined &&
    contentRows[0].columns[0].item.common?.size?.maxWidth !== undefined
  ) {
    maxWidthValue = contentRows[0].columns[0].item.common?.size?.maxWidth
  }

  let minWidthValue: number | null

  if (
    contentRows !== undefined &&
    contentRows[0] !== undefined &&
    contentRows[0].columns[0] !== undefined &&
    contentRows[0].columns[0].item !== undefined &&
    contentRows[0].columns[0].item.common !== undefined &&
    contentRows[0].columns[0].item.common?.size !== undefined &&
    contentRows[0].columns[0].item.common?.size?.minWidth !== undefined
  ) {
    minWidthValue = contentRows[0].columns[0].item.common?.size?.minWidth
  }

  let fixedWidthValue: number | null 

  if (
    contentRows !== undefined &&
    contentRows[0] !== undefined &&
    contentRows[0].columns[0] !== undefined &&
    contentRows[0].columns[0].item !== undefined &&
    contentRows[0].columns[0].item.common !== undefined &&
    contentRows[0].columns[0].item.common?.size !== undefined &&
    contentRows[0].columns[0].item.common?.size?.fixedWidth !== undefined
  ) {
    fixedWidthValue = contentRows[0].columns[0].item.common?.size?.fixedWidth
  }

  let maxHeightPlaceHolder: number | null
  let minHeightPlaceHolder: number | null
  let fixedHeightPlaceHolder: number | null
  let maxWidthPlaceHolder: number | null
  let minWidthPlaceHolder: number | null
  let fixedWidthPlaceHolder: number | null

  if(area?.style.content !== undefined && area?.style.content.size !== undefined) {
     maxHeightPlaceHolder = area?.style.content.size?.maxHeight!
     minHeightPlaceHolder = area?.style.content.size?.minHeight!
     fixedHeightPlaceHolder = area?.style.content.size?.fixedHeight!
     maxWidthPlaceHolder = area?.style.content.size?.maxWidth! 
     minWidthPlaceHolder = area?.style.content.size?.minWidth! 
     fixedWidthPlaceHolder = area?.style.content.size?.fixedWidth!
  }



  const handleMaxHeight = (e: any) => {
    let value = Number(e.target.value)
    if (value === undefined || value === null) {
      setState(
        produce(state, (draft: IState) => {
          draft.maxHeight = null
        })
      )
    }
    setState(
      produce(state, (draft: IState) => {
        draft.maxHeight = value
      })
    )
    // setBlockSize(value === 0 ? null : value, MeasurementInputTYPE.MAX_HEIGHT)
  }

  const handleMinHeight = (e: any) => {
    let value = Number(e.target.value)
    if (value === undefined || value === null) {
      setState(
        produce(state, (draft: IState) => {
          draft.minHeight = null
        })
      )
    }
    setState(
      produce(state, (draft: IState) => {
        draft.minHeight = value
      })
    )
    // setBlockSize(value === 0 ? null : value, MeasurementInputTYPE.MIN_HEIGHT)
  }

  const handleFixedHeight = (e: any) => {
    let value = Number(e.target.value)
    if (value === undefined || value === null) {
      setState(
        produce(state, (draft: IState) => {
          draft.fixedHeight = null
        })
      )
    }
    setState(
      produce(state, (draft: IState) => {
        draft.fixedHeight = value
      })
    )
    // setBlockSize(value === 0 ? null : value, MeasurementInputTYPE.FIXED_HEIGHT)
  }

  const handleMaxWidth = (e: any) => {
    let value = Number(e.target.value)
    if(value === undefined || value === null){
      setState(
        produce(state, (draft: IState) => {
          draft.maxWidth = null
        })
      )
    }
    setState(
      produce(state, (draft: IState) => {
        draft.maxWidth = value
      })
    )
    // setBlockSize(value === 0 ? null : value, MeasurementInputTYPE.MAX_WIDTH)
  }

  const handleMinWidth = (e: any) => {
    let value = Number(e.target.value)
    if (value === undefined || value === null) {
      setState(
        produce(state, (draft: IState) => {
          draft.minWidth = null
        })
      )
    }
    setState(
      produce(state, (draft: IState) => {
        draft.minWidth = value
      })
    )
    // setBlockSize(value === 0 ? null : value, MeasurementInputTYPE.MIN_WIDTH)
  }

  const handleFixedWidth = (e: any) => {
    let value = Number(e.target.value)
    if (value === undefined || value === null) {
      setState(
        produce(state, (draft: IState) => {
          draft.fixedWidth = null
        })
      )
    }
    setState(
      produce(state, (draft: IState) => {
        draft.fixedWidth = value
      })
    )
    // setBlockSize(value === 0 ? null : value, MeasurementInputTYPE.FIXED_WIDTH)
  }

  const onClear = (type:MeasurementInputTYPE) => {
    if(type === MeasurementInputTYPE.MAX_HEIGHT) {
      setState(
        produce(state, (draft: IState) => {
          draft.maxHeight = null
        })
      )
      setBlockSize(null, MeasurementInputTYPE.MAX_HEIGHT)
    }
    if(type === MeasurementInputTYPE.MIN_HEIGHT) {
      setState(
        produce(state, (draft: IState) => {
          draft.minHeight = null
        })
      )
      return setBlockSize(null, MeasurementInputTYPE.MIN_HEIGHT)
    }
    if(type === MeasurementInputTYPE.FIXED_HEIGHT) {
      setState(
        produce(state, (draft: IState) => {
          draft.fixedHeight = null
        })
      )
      return setBlockSize(null, MeasurementInputTYPE.FIXED_HEIGHT)
    }
    if(type === MeasurementInputTYPE.MAX_WIDTH) {
      setState(
        produce(state, (draft: IState) => {
          draft.maxWidth = null
        })
      )
      return setBlockSize(null, MeasurementInputTYPE.MAX_WIDTH)
    }
    if(type === MeasurementInputTYPE.MIN_WIDTH) {
      setState(
        produce(state, (draft: IState) => {
          draft.minWidth = null
        })
      )
      return setBlockSize(null, MeasurementInputTYPE.MIN_WIDTH)
    }
    if(type === MeasurementInputTYPE.FIXED_WIDTH) {
      setState(
        produce(state, (draft: IState) => {
          draft.fixedWidth = null
        })
      )
      return setBlockSize(null, MeasurementInputTYPE.FIXED_WIDTH)
    }
  }

  
  
  return (
    <div className={styles.container}>
      <div className={styles.sizeBlocks}>
        <div className={styles.minSizeBlock}>
          <span className={styles.blockTitle}>Min</span>
          <div>
          <MeasurementInput
              type={MeasurementInputTYPE.MIN_WIDTH}
              onChange={handleMinWidth}
              onClear={onClear}
              label={"B"}
              unit={"mm"}
              placeholder={minWidthPlaceHolder!}
              value={
                state?.minWidth === null || state?.minWidth === undefined
                  ? minWidthValue!
                  : state.minWidth
              }
              setBlockSize={setBlockSize}
            />
            <MeasurementInput
              type={MeasurementInputTYPE.MIN_HEIGHT}
              onChange={handleMinHeight}
              onClear={onClear}
              label={"H"}
              unit={"mm"}
              placeholder={minHeightPlaceHolder!}
              value={
                state?.minHeight === null || state?.minHeight === undefined
                  ? minHeightValue!
                  : state.minHeight
              }
              setBlockSize={setBlockSize}
            />

          </div>
        </div>
        <div className={styles.MaxSizeBlock}>
          <span className={styles.blockTitle}>Max</span>
          <div>
          <MeasurementInput
              type={MeasurementInputTYPE.MAX_WIDTH}
              onChange={handleMaxWidth}
              onClear={onClear}
              label={"B"}
              unit={"mm"}
              placeholder={maxWidthPlaceHolder!}
              value={
                state?.maxWidth === null || state?.maxWidth === undefined
                  ? maxWidthValue!
                  : state.maxWidth
              }
              setBlockSize={setBlockSize}
            />
            <MeasurementInput
              type={MeasurementInputTYPE.MAX_HEIGHT}
              onChange={handleMaxHeight}
              onClear={onClear}
              label={"H"}
              unit={"mm"}
              placeholder={maxHeightPlaceHolder!}
              value={
                state?.maxHeight === null || state?.maxHeight === undefined
                  ? maxHeightValue!
                  : state.maxHeight
              }
              setBlockSize={setBlockSize}
            />

          </div>
        </div>
        <div className={styles.fixedSizeBlock}>
          <span className={styles.blockTitle}>Fast</span>
          <div>
          <MeasurementInput
              type={MeasurementInputTYPE.FIXED_WIDTH}
              onChange={handleFixedWidth}
              onClear={onClear}
              label={"B"}
              unit={"mm"}
              placeholder={fixedWidthPlaceHolder!}
              value={
                state?.fixedWidth === null || state?.fixedWidth === undefined
                  ? fixedWidthValue!
                  : state.fixedWidth
              }
              setBlockSize={setBlockSize}
            />
            <MeasurementInput
              type={MeasurementInputTYPE.FIXED_HEIGHT}
              onChange={handleFixedHeight}
              onClear={onClear}
              label={"H"}
              unit={"mm"}
              placeholder={fixedHeightPlaceHolder!}
              value={
                state?.fixedHeight === null || state?.fixedHeight === undefined
                  ? fixedHeightValue!
                  : state.fixedHeight
              }
              setBlockSize={setBlockSize}
            />

          </div>
        </div>
      </div>
    </div>
  )
}

interface IState {
  maxHeight?: number | null
  minHeight?: number | null
  fixedHeight?: number | null
  maxWidth?: number | null
  minWidth?: number | null
  fixedWidth?: number | null
}



export default BlockMeasurement
