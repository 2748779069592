import * as React from "react"
import * as styles from "./styles.scss"
import Toolbar from "./Toolbar"
import { OrderEditorAreaContext } from ".."
import { HtmlInputAdminSection } from "../HtmlInputAdminSection"
import { AppContext } from "App"
import {
  EditModelAdvertAreaContentEditorBehaviours,
  EditModelAdvertAreaContentRepository,
} from "schema"
import ImageSelectorModal from "components/ImageSelectorModal"
import Button from "components/Button"
import Modal from "components/Modal"
import { format } from "path"
import { useTranslation } from "react-i18next"
// import ColumnEditor from "./ColumnEditor"
// import ColumnView from "./ColumnView"
// import { AppContext } from "App"
// import AdminSection from "../AdminSection"
// import AdminMargin from "../AdminMargin"
// import ColumnView from "./ColumnView"
// import HtmlInputAdminSection from "../HtmlInput/ColumnView/HtmlInputAdminSection"

const SeparatorControl: React.FC<{
  blockStyles: any[]
  templateAreaId: string | null
  documentFormatId: string | undefined
  onSave: (
    symbolId: string,
    imagePublicId: string,
    imageThumbnaill100x80Url: string,
    repository: EditModelAdvertAreaContentRepository,
    callback?: () => void
  ) => void
}> = (props) => {
  const {
    contentRows,
    editor,
    manuallySetContentRows,
    onXmlTextChange,
    onSymbolSave,
  } = React.useContext(OrderEditorAreaContext)
  const { t } = useTranslation(["editOrder"])
  const [inColumnEditMode, setInColumnEditMode] = React.useState(false)
  const [columnEditorRows, setColumnEditorRows] = React.useState(contentRows!)
  const [tempColumnEditorRows, setTempColumnEditorRows] = React.useState(
    contentRows!
  )
  const [isModalOpen, setModalOpen] = React.useState<boolean>(false)
  const [adminSectionIsExpanded, setAdminSectionIsExpanded] = React.useState(
    false
  )

  const { GALLERY_SEPARATOR } = EditModelAdvertAreaContentEditorBehaviours

  const getNewRowItem = () => {
    const { xmlText, ...rest } = contentRows[0].columns[0].item.text!

    return {
      item: {
        text: { xmlText: "", ...rest },
        image: null,
        separator: null,
        common: null,
      },
    }
  }

  React.useEffect(() => {
    setTempColumnEditorRows(contentRows!)
    setColumnEditorRows(contentRows!)
  }, [contentRows])

  const enterColumnEditMode = () => setInColumnEditMode(true)

  const exitColumnEditMode = () => {
    setTempColumnEditorRows(columnEditorRows)
    setInColumnEditMode(false)
  }

  const addRow = () =>
    setTempColumnEditorRows([
      ...tempColumnEditorRows,
      { columns: [getNewRowItem() as any] },
    ])

  const removeRow = (index: number) => {
    const rows = [...tempColumnEditorRows]
    rows.splice(index, 1)
    setTempColumnEditorRows(rows)
  }

  const saveEditorRows = () => {
    setColumnEditorRows(tempColumnEditorRows)
    manuallySetContentRows(tempColumnEditorRows)
    setInColumnEditMode(false)
  }

  const handleOpenModal = () => setModalOpen(true)

  const handleCloseModal = () => setModalOpen(false)

  const changeColumn = (index: number, columns: number) => {
    const rows = [...tempColumnEditorRows]
    const row = rows[index]

    if (row.columns.length < columns) {
      const difference = columns - row.columns.length
      for (let i = 0; i < difference; i++)
        row.columns.push(getNewRowItem() as any)
    } else if (row.columns.length > columns) {
      const difference = row.columns.length - columns
      for (let i = 0; i < difference; i++) row.columns.pop()
    }

    setTempColumnEditorRows(rows)
  }

  const handleTextChange = (event: any, rowIndex: number, colIndex: number) => {
    const rows = [...tempColumnEditorRows]
    rows[rowIndex].columns[colIndex].item.text!.xmlText = event.target.value

    setTempColumnEditorRows(rows)
    onXmlTextChange(event, rowIndex, colIndex)
  }

  const toggleAdminSectionIsExpanded = () => {
    setAdminSectionIsExpanded(!adminSectionIsExpanded)
  }

  return (
    <div className={styles.container}>
      {editor.behaviour === GALLERY_SEPARATOR && (
        <div className={styles.separatorButtonContainer}>
          <Button variant="secondary" onClick={handleOpenModal} icon="search">
            {t("selectLine")}
          </Button>
        </div>
      )}

      <Toolbar
        onEnterColumnEdit={enterColumnEditMode}
        toggleAdminSectionIsExpanded={toggleAdminSectionIsExpanded}
        adminSectionIsExpanded={adminSectionIsExpanded}
      />

      <React.Fragment>
        <HtmlInputAdminSection
          isExpanded={adminSectionIsExpanded}
          type={editor.type}
        />
      </React.Fragment>

      {isModalOpen && (
        <ImageSelectorModal
          onSave={props.onSave}
          onRequestClose={handleCloseModal}
          isOpen={isModalOpen}
          editor={editor}
          templateAreaId={props.templateAreaId}
          documentFormatId={props.documentFormatId}
          repository={contentRows[0].columns[0].item.separator?.repository}
        />
      )}

      {/* <ColumnView
        onTextChange={handleTextChange}
        adminSectionIsExpanded={adminSectionIsExpanded}
      /> */}
    </div>
  )
}

export default SeparatorControl
