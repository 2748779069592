import React from 'react'
import { AddProductGroupOrderModal } from 'components/AddProductGroupOrderModal/AddProductGroupOrderModal'
import { useHistory } from 'react-router'

const SelectProduct = () => {
    const history = useHistory()
    React.useEffect(() => {
        const { search } = window.location
        const urlParams = new URLSearchParams(search)
        urlParams.set("action", "add_product_casefolder")
        const newSearch = urlParams.toString()
        history.push({
          search: newSearch,
        })
    },[]) 
  return (
    <AddProductGroupOrderModal  />
  )
}

export default SelectProduct