import gql from "graphql-tag"
import USER_INTERFACE_FRAGMENT from "graphql/fragments/UserInterfaceFragment"

const GET_ORDER_BY_ID_MEMORY_ROOM = gql`
  query GetOrderByIdMemoryRoom($id: ID!) {
    orders(ids: [$id]) {
      id
      customerFriendlyId
      mediaOrder {
        isLocked
        publishChannels {
          inFamiljesidan
          inMinnesrummet
          inAgency
        }
        publishTimes {
          time
        }
        status {
          color
          status
          isBookingSent
        }
        preview {
          price(withVAT: true, withRounding:STANDARD, priceView: WITH_SERVICE_ORDER) {
            currency
            endCustomer {
              pricePayRounded
            }
          }
        }
        edit {
          userInterface {
            ...userInterfaceFragment
          }
          editModelType
          editModelMemoryRoom {
            id
            entries {
              memoryRoomUrl
              voucherKey
            }
            case {
              personFirstname
              personLastname
              personBornLastname
              personCityDied
              personTimeBorn {
                value
              }
              personTimeDied {
                value
              }
              personBornLastname
              personCityDied
            }
            features {
              isLightningCandle
            }
            contents {
              # avatarImageFilename
              # avatarImageId
              # backgroundImageId
              # fundId
              # funds {
              #   id
              #   name
              # }
              welcomeText
              avatarImage {
                value {
                  id
                  imageUrl
                  name
                }
              }
              images {
                id
                imageUrl
                name
              }
            }
            funeral {
              ceremonies {
                lastBookingTime {
                  value
                }
                time {
                  value
                }
                locationText
                infoText
              }
            }
          }
        }
      }
    }
  }
  ${USER_INTERFACE_FRAGMENT}
`

export default GET_ORDER_BY_ID_MEMORY_ROOM
