import React from "react"
import * as styles from "./styles.scss"
import { useTranslation } from "react-i18next"
import cx from "classnames"
import Tooltip from "components/Tooltip"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faMinus, faPlus, faTimes } from "@fortawesome/free-solid-svg-icons"
import { SvgMarginLeftRightIcon } from "asset/svg/SvgComponents"
import _ from "lodash"
import { OrderEditorAreaContext } from "pages/EditOrderPage/OrderEditorContainer/OrderEditoryAdvert/AreasContainer/Areas/AreaContainer"

const scaleTextLogo = () => {
  return React.cloneElement(<SvgMarginLeftRightIcon />, {
    size: "30px",
    color: "#ACACAC",
  })
}

const MarginLeftRightInput:React.FC<{
  isFEUser: boolean
}> = (props) => {
  const [t] = useTranslation("editOrder")
  const { setMarginLeftRightOffset, editor, contentRows } = React.useContext(
    OrderEditorAreaContext
  )

  const {
    isMarginLeftRightOffset,
    allowedMarginLeftRightOffsetInterval,
  } = editor.common!

  const [value, setValue] = React.useState<number>(
    contentRows[0].columns[0].item.common?.marginLeftRightOffset?.value ?? 0
  )

  const [isExpand, setExpand] = React.useState<boolean>(false)

  React.useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (
        isExpand &&
        !_.isEmpty(event.target) &&
        !event.target.closest(`.${styles.marginLeftRightInputContainer}`)
      ) {
        setExpand(false)
      }
    }
    document.addEventListener("mousedown", handleClickOutside)
    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [isExpand])

  const handleIncrease = () => {
    if (value < allowedMarginLeftRightOffsetInterval!.maxValue) {
      handleChange(value + 1)
    }
  }

  const handleDecrease = () => {
    if (value > allowedMarginLeftRightOffsetInterval!.minValue) {
      handleChange(value - 1)
    }
  }

  const handleChange = (value: number) => {
    if (value === 0) {
      setMarginLeftRightOffset(null)
      setValue(0)
      return
    } else {
      setMarginLeftRightOffset(value!)
      setValue(value)
    }
  }

  const handleReset = () => {
    setMarginLeftRightOffset(null)
    setValue(0)
  }
  const handleExpand = () => setExpand(true)
  const handleShrank = () => setExpand(false)

  return (
    <>
      {isMarginLeftRightOffset &&
      allowedMarginLeftRightOffsetInterval !== null ? (
        <React.Fragment>
          <div className={styles.buttonHolder}>
            {value !== 0 && (
              <div className={styles.resetButton} onClick={handleReset}>
                <FontAwesomeIcon icon={faMinus} />
              </div>
            )}
            {!isExpand ? (
              <Tooltip
                id={"ScalaTextInput"}
                text={t("marginWidth")}
                bgColor={"#fff"}
                className={styles.tooltip}
                delayShow={750}
              >
                <div className={cx(styles.iconWrapper,{
                   [styles.feView__iconWrapper]: props.isFEUser
                })} onClick={handleExpand}>
                  {scaleTextLogo()}
                </div>
              </Tooltip>
            ) : (
              <div className={cx(styles.iconWrapper,{
                [styles.feView__iconWrapper]: props.isFEUser
              })} onClick={handleShrank}>
                <FontAwesomeIcon icon={faTimes} />
              </div>
            )}
          </div>
          <div
            className={cx(styles.marginLeftRightInputContainer, {
              [styles.isVisible]: isExpand,
              [styles.feView__scalaTextInput__container]: props.isFEUser
            })}
          >
            <button
              type="button"
              className={cx(styles.btn, {
                [styles.btnDisabled]:value === allowedMarginLeftRightOffsetInterval.minValue
              }) }
              onClick={handleDecrease}
              disabled={value === allowedMarginLeftRightOffsetInterval.minValue}
            >
              <FontAwesomeIcon icon={faMinus} />
            </button>
            <input
              className={styles.textInput}
              type="number"
              value={value}
              onChange={(e) => handleChange(Number(e.currentTarget.value))}
              min={allowedMarginLeftRightOffsetInterval.minValue}
              max={allowedMarginLeftRightOffsetInterval.maxValue}
              step={allowedMarginLeftRightOffsetInterval.intervalValue}
              readOnly
            />
            <button
              type="button"
              className={cx(styles.btn, {
                [styles.btnDisabled]:value === allowedMarginLeftRightOffsetInterval.maxValue
              }) }
              onClick={handleIncrease}
              disabled={value === allowedMarginLeftRightOffsetInterval.maxValue}
            >
              <FontAwesomeIcon icon={faPlus} />
            </button>
          </div>
        </React.Fragment>
      ) : null}
    </>
  )
}

export default MarginLeftRightInput
