import * as React from "react"
import CheckboxColumn from "components/CheckboxColumn"
import { withTranslation, WithTranslation } from "react-i18next"
import { AttachmentsObj } from ".."
import Attachment from "./Attachment"

class Attachments extends React.PureComponent<
  AttachmentsProps & WithTranslation
> {
  render() {
    const { t, values, onChange } = this.props
    return (
      <CheckboxColumn title={t("attachments")}>
        {Object.keys(values).map(key => (
          <Attachment
            key={key}
            label={t(key)}
            checked={values[key]}
            onChange={onChange}
            name={key}
          />
        ))}
      </CheckboxColumn>
    )
  }
}

interface AttachmentsProps {
  values: AttachmentsObj
  onChange: (name: string) => void
}

export default withTranslation("proof")(Attachments)
