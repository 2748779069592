import * as React from "react"
import * as styles from "./styles.scss"
import Header from "components/Header"
import { withTranslation, WithTranslation } from "react-i18next"
import { GetReviewCaseFolder_caseFolder_documents_orders } from "schema"
import EnlargeImageModal from "./EnlargeImageModal"
import CompareModal from "./CompareModal"

class ProofColumn extends React.Component<
  ProofColumnProps & WithTranslation,
  ProofColumnState
> {
  state = { compareModalIsOpen: false, enlargeModalIsOpen: false }

  openCompareModal = () => this.setState({ compareModalIsOpen: true })

  closeCompareModal = () => this.setState({ compareModalIsOpen: false })

  openEnlargeModal = () => this.setState({ enlargeModalIsOpen: true })

  closeEnlargeModal = () => this.setState({ enlargeModalIsOpen: false })

  render() {
    const { t, imageUrl, orders } = this.props
    const { compareModalIsOpen, enlargeModalIsOpen } = this.state

    return (
      <div className={styles.proofColumn}>
        <Header
          title={t("proof:proof")}
          subtitle={t("proofImageDescription")}
        />
        <img
          src={imageUrl}
          alt=""
          className={styles.proofImage}
          onClick={this.openEnlargeModal}
        />
        <p className={styles.compare} onClick={this.openCompareModal}>
          {t("compareOrders")}
        </p>
        <EnlargeImageModal
          onClick={this.openEnlargeModal}
          onRequestClose={this.closeEnlargeModal}
          isOpen={enlargeModalIsOpen}
          imageUrl={imageUrl}
        />
        <CompareModal
          onRequestClose={this.closeCompareModal}
          isOpen={compareModalIsOpen}
          orders={orders}
        />
      </div>
    )
  }
}

interface ProofColumnProps {
  imageUrl: string
  orders: GetReviewCaseFolder_caseFolder_documents_orders[]
}

interface ProofColumnState {
  compareModalIsOpen: boolean
  enlargeModalIsOpen: boolean
}

export default withTranslation(["review", "proof"])(ProofColumn)
