import React from "react"
import styles from "./styles.scss"
import {
  GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_fields,
  EditModelAdvertAreaContentItemFieldItemEditors,
} from "schema"
import { useFormik } from "formik"
import produce from "immer"
import moment from "moment"
import TextInput from "./TextInput"
import DatePickerInput from "./CalenderInput"
import { useCustomDebounce } from "useHook/useCustomDebounce"
import { OrderEditorAreaContext } from "pages/EditOrderPage/OrderEditorContainer/OrderEditoryAdvert/AreasContainer/Areas/AreaContainer"
import cx from "classnames"

type fieldsType = GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_fields | null

const FieldsContainer: React.FC<{
  isXmlTextReadonly: boolean
  isFEUser: boolean
}> = (props) => {
  const {
    contentRows,
    setFields,
    handleAreaOverFlowChange,
  } = React.useContext(OrderEditorAreaContext)
  const {
    NONE,
    TEXT_SINGLE_LINE,
    CALENDER,
  } = EditModelAdvertAreaContentItemFieldItemEditors
  let fields: Array<fieldsType> = []
  const [values, setValues] = React.useState<Array<fieldsType>>([])

  if (
    contentRows &&
    contentRows[0].columns[0] &&
    contentRows[0].columns[0].item &&
    contentRows[0].columns[0].item.fields &&
    contentRows[0].columns[0].item.fields.length > 0
  ) {
    fields = contentRows[0].columns[0].item.fields
  }

  if (fields.length <= 0) {
    return null
  }

  const initialValues = fields.reduce((obj: any, item: fieldsType) => {
    return Object.assign(obj, {
      [item?.name!]: item?.value,
    })
  }, {})

  const formik = useFormik({
    initialValues,
    onSubmit: (values) => {
      alert(JSON.stringify(values, null, 2))
    },
  })

  React.useEffect(() => {
    setValues(fields)
  }, [])

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    formik.setFieldValue(e.target.name, e.target.value)
    setValues(
      produce(values, (draft) => {
        draft.map((item, index) => {
          if (item?.name === e.target.name) {
            draft[index]!.value = `${e.target.value!}`
          }
        })
      })
    )
    debouncedRequest()
  }

  const handleDateInputChange = (name: string, date: Date) => {
    formik.setFieldValue(name, date)
    setValues(
      produce(values, (draft) => {
        draft.map((item, index) => {
          if (item?.name === name) {
            draft[index]!.value = date
              ? `${moment(date).format("YYYY-MM-DD")}`
              : null
          }
        })
      })
    )
    debouncedRequest()
  }

  const sendRequest = () => {
    setFields(values)
  }

  const debouncedRequest = useCustomDebounce(() => {
    sendRequest()
  })



  return (
    <div className={cx(styles.fieldsContainer, {
      [styles.FEView___fieldsContainer]: props.isFEUser
    })}>
      <div className={styles.form}>
        {fields.map((field, index) => {
          return (
            <div key={index} className={styles.input_wrapper}>
              <label>{field?.header}</label>
              {field?.editor === TEXT_SINGLE_LINE && (
                <TextInput
                  className={styles.textInput}
                  type={"text"}
                  name={field.name}
                  value={formik.values[field!.name]}
                  onChange={handleInputChange}
                  disabled={!props.isXmlTextReadonly}
                />
              )}

              {field?.editor === CALENDER && (
                <div>
                  <DatePickerInput
                    publishTime={new Date()}
                    disabled={!props.isXmlTextReadonly}
                    className={styles.calenderInput}
                    name={field.name}
                    value={
                      formik.values[field!.name]
                        ? new Date(formik.values[field!.name])
                        : null
                    }
                    handleDateInputChange={handleDateInputChange}
                    handleAreaOverFlowChange={handleAreaOverFlowChange}
                    isFEUser={props.isFEUser}
                  />
                </div>
              )}
              {field?.editor === NONE && null}
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default FieldsContainer
