import gql from "graphql-tag"

const SELECT_ORDER_INITIATOR_VIEW = gql`
fragment OrderInitiatorOptionFragment on OrderInitiatorSelectOption {
  selector
  name
  imageUrl
  isSelected
  isEnabled
  hoverText
  orderInitiatorId {
    mediaId
    documentFormatInitiatorId
  }
  columns {
  text
  }
}

fragment OrderInitiatorViewFragment on OrderInitiatorSelectView {
  orderInitiatorId {
    mediaId
    documentFormatInitiatorId
    customerDocumentFormatInitiatorId
  }
  isFilterEnabled
  selectType
  layout
  view
  header
  isNavigateToEdit
  navigateRoute
  jsInvokeStatement
  columns {
  name
  }
}

mutation SelectOrderInitiatorView($selector: String!, $caseFolderId: ID!, $createOrder: Boolean = true, $documentId: ID, $orderId: ID, $fromOrderId: ID, $documentTypeId:ID, $mediaId: ID, $route: OrderInitiatorSelectRoutes) {
  selectOrderInitiatorView(selector: $selector, caseFolderId: $caseFolderId, documentId: $documentId, createOrder: $createOrder, orderId:$orderId, fromOrderId:$fromOrderId, documentTypeId:$documentTypeId, mediaId:$mediaId, route: $route) {
    ...OrderInitiatorViewFragment
    previousSelector
    subViews {
      ...OrderInitiatorViewFragment
      options {
        ...OrderInitiatorOptionFragment
      }
    }
    options {
      ...OrderInitiatorOptionFragment
      subView {
        ...OrderInitiatorViewFragment
        options {
          ...OrderInitiatorOptionFragment
        }
      }
    }
    order {
      id
      client {
        route {
          editPageRoute {
            absUrl
            relUrl
            taps5IframeAbsUrl
            taps5RelUrl
          }
          editPageUrl(baseUrl: "")
        }
      }
    }
  }
}

`

export default SELECT_ORDER_INITIATOR_VIEW
