import * as React from "react"
import * as styles from "../styles.scss"
import { SelectedCustomer, SelectedProduct } from "customSchema.interface"
import {
  CustomerScope,
  GetCurrentCustomerColleagues,
  GetCurrentCustomerColleaguesVariables,
  getProducts,
} from "schema"
import { useTranslation } from "react-i18next"
// import Select from "components/Select"
import { useApolloClient, useQuery } from "react-apollo-hooks"
import GET_CURRENT_CUSTOMER_COLLEAGUES from "graphql/queries/getCurrentCustomerColleagues"
import GET_PRODUCTS from "graphql/queries/getProducts"
import { ErrorText } from "components/ErrorMessage"
import Select, { components } from "react-select"
import { printIntrospectionSchema } from "graphql"
import { FontawesomeObject } from "@fortawesome/fontawesome-svg-core"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { any } from "prop-types"

const Filters: React.FC<{
  selectedProducts: SelectedProduct[]
  onChangeProducts?: any
  selectedScope: CustomerScope
  onSelectedScope: any
  selectedCustomers: SelectedCustomer[]
  onChangeSelectedCustomers: any
  setSelectedProducts: any
}> = (props) => {
  const { t } = useTranslation(["agencySettings"])

  return (
    <div style={{ marginBottom: 30, display: "flex" }}>
      <div className={styles.row} style={{ marginRight: 20, marginTop: 10 }}>
        <div className={styles.label}>
          <b>{t("filter")}</b>
        </div>
      </div>

      <div style={{ maxWidth: 300, minWidth: 200, marginRight: 15 }}>
        <ProductsSelect
          selectedProducts={props.selectedProducts}
          onChangeProducts={props.onChangeProducts}
          setSelectedProducts={props.setSelectedProducts}
        />
      </div>

      <div style={{ maxWidth: 300, minWidth: 200, marginRight: 15 }}>
        <CustomersSelect
          selectedScope={props.selectedScope}
          onSelectedScope={props.onSelectedScope}
        />
      </div>
      {props.selectedScope === "CUSTOMER" && (
        <div style={{ maxWidth: 300, minWidth: 200 }}>
          <CustomersAccounts
            selectedScope={props.selectedScope}
            selectedCustomers={props.selectedCustomers}
            onChangeSelectedCustomers={props.onChangeSelectedCustomers}
          />
        </div>
      )}
    </div>
  )
}

const customStyles = {
  option: (
    provided: any,
    state: { isSelected: any; isDisabled: any; isFocused: any }
  ) => {
    return {
      padding: 5,
      color: state.isSelected ? "#1289A7" : "black",
      fontWeight: 500,
      // backgroundColor: state.isFocused ? "red" : "blue",
      cursor: "pointer",
      display: "block",
      beforeAll: "adsasdjp",
    }
  },
  multiValue: (base: any) => ({
    display: "none",
  }),
}
const ProductsSelect: React.FC<{
  selectedProducts: SelectedProduct[]
  onChangeProducts: any
  setSelectedProducts: any
}> = (props) => {
  const { t } = useTranslation(["common", "agencySettings"])

  const valueRef = React.useRef(props.selectedProducts)
  valueRef.current = props.selectedProducts

  const selectAllOption = {
    value: "<SELECT_ALL>",
    label: t("agencySettings:StatisticsTab_all"),
  }

  const { data, loading, error } = useQuery<getProducts>(GET_PRODUCTS)



  const options = loading
    ? []
    : data?.currentCustomer?.office.company.customerContract.customerProducts
        .map((e) => ({
          value: e.product.id,
          label: e.product.name,
        }))
        .filter((option: any) => option.label !== "")

  const isSelectAllSelected = () => valueRef.current.length === options?.length

  const isOptionSelected = (option: any) =>
    valueRef.current.some(({ value }) => value === option.value) ||
    isSelectAllSelected()

  const getOptions = () => [selectAllOption, ...options!]

  const getValue = () =>
    isSelectAllSelected() ? [selectAllOption] : props.selectedProducts

  const onChange = (newValue: SelectedProduct[], actionMeta: any) => {
    const { action, option, removedValue } = actionMeta

    if (action === "select-option" && option.value === selectAllOption.value) {
      props.setSelectedProducts(options, actionMeta)
    } else if (
      (action === "deselect-option" &&
        option.value === selectAllOption.value) ||
      (action === "remove-value" &&
        removedValue.value === selectAllOption.value)
    ) {
      props.setSelectedProducts([], actionMeta)
    } else if (
      actionMeta.action === "deselect-option" &&
      isSelectAllSelected()
    ) {
      props.setSelectedProducts(
        options?.filter(({ value }) => value !== option.value),
        actionMeta
      )
    } else {
      props.setSelectedProducts(newValue || [], actionMeta)
    }
  }

  return (
    <>
      {loading ? <div>{t("common:loading")}</div> : null}
      <Select
        isOptionSelected={isOptionSelected}
        placeholder={t("agencySettings:StatisticsTab_products")}
        label="Single select"
        value={getValue()}
        options={getOptions()}
        isLoading={loading}
        style={{ minWidth: 200 }}
        onChange={onChange}
        isClearable={false}
        styles={customStyles}
        closeMenuOnSelect={false}
        hideSelectedOptions={false}
        isMulti
      />
      {error ? (
        <div style={{ maxHeight: 100, overflow: "auto" }}>
          <ErrorText>{error.message}</ErrorText>
        </div>
      ) : null}
    </>
  )
}

const CustomersSelect: React.FC<{
  selectedScope: CustomerScope
  onSelectedScope: (newValue: CustomerScope) => void
}> = (props) => {
  const { t } = useTranslation(["agencySettings"])
  const tCompany = t("agencySettings:StatisticsTab_company")
  const tOffice = t("agencySettings:StatisticsTab_office")
  const tCustomer = t("agencySettings:StatisticsTab_admin")
  const tKontor = t("agencySettings:StatisticsTab_account")

  const onChange = (selected: any) => 
    props.onSelectedScope(selected.value)
 
  // const isAllSuported = false


  return (
    <Select
      styles={customStyles}
      placeholder={tKontor}
      value={{
        value: props.selectedScope,
        
        label:
          props.selectedScope === CustomerScope.OFFICE
            ? tOffice
            : props.selectedScope === CustomerScope.COMPANY
            ? tCompany
            : props.selectedScope === CustomerScope.CUSTOMER
            ? tCustomer
            : "",
      }}
      options={[
        { value: CustomerScope.CUSTOMER, label: tCustomer },
        { value: CustomerScope.OFFICE, label: tOffice },
        { value: CustomerScope.COMPANY, label: tCompany },
      ]}
      style={{ minWidth: 200 }}
      onChange={onChange}
    />
  )
}

const CustomersAccounts: React.FC<{
  selectedScope: CustomerScope
  selectedCustomers: SelectedCustomer[]
  onChangeSelectedCustomers: any
}> = (props) => {
  const { t } = useTranslation(["common", "agencySettings"])
  const client = useApolloClient()
  const { data, loading, error, refetch } = useQuery<
    GetCurrentCustomerColleagues,
    GetCurrentCustomerColleaguesVariables
  >(GET_CURRENT_CUSTOMER_COLLEAGUES, {
    client,
    variables: {
      input: CustomerScope.OFFICE,
    },
  })

  React.useEffect(() => {
    refetch({
      input: props.selectedScope === "CUSTOMER" ? CustomerScope.OFFICE : props.selectedScope
    })
  }, [props.selectedScope])

  const options = loading
    ? []
    : data?.currentCustomer?.colleagues
        .map((e) => ({
          value: e?.id,
          label: e?.name,
        }))
        .filter((option: any) => option.label !== "") ?? []

  const onChange = (values: any[]) => {
    props.onChangeSelectedCustomers(values)
  }

  return (
    <>
      {loading ? <div>{t("common:loading")}</div> : null}

      {options.length > 0 ? (
        <Select
          placeholder={t("agencySettings:StatisticsTab_admin")}
          // onMenuClose={false}
          styles={customStyles}
          value={props.selectedCustomers}
          options={options}
          isLoading={loading}
          style={{ minWidth: 200 }}
          onChange={onChange}
          // onInputChange={props.selectedCustomers}
        />
      ) : null}

      {error ? (
        <div style={{ maxHeight: 100, overflow: "auto" }}>
          <ErrorText>{error.message}</ErrorText>
        </div>
      ) : null}
    </>
  )
}

export default Filters
