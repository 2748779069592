const ENV = process.env.REACT_APP_ENVIRONMENT

export const getGQLEndpoint = () => {
  let result = process.env.REACT_APP_TAPS5_BACKEND
  if(result == undefined) {
    console.log("REACT_APP_TAPS5_BACKEND is missing")
    return undefined
  }
  if(result?.indexOf("localhost") >= 0)
    return "http://" + result
  else
    return "https://" + result
}

export const getWSGQLEndpoint = () => {
  let result = process.env.REACT_APP_TAPS5_BACKEND
  if(result == undefined) {
    console.log("REACT_APP_TAPS5_BACKEND is missing")
    return undefined
  }
  if(result?.indexOf("localhost") >= 0)
    return "ws://" + result
  else
    return "wss://" + result
}

export const SessionStorageKeys = {
  AUTH_TOKEN: "taps5_jwt_token",
  CLIENT_SESSION_ID: "taps5_client_session_id",
}

export const LocalStorageKeys = {
  SHOW_SUPPORT_VIEW: "taps5_showSupportView",
  SHOW_MODE_VIEW: "showModeView"
}


export const envKeys = {
  REACT_APP_ENVIRONMENT: process.env.REACT_APP_ENVIRONMENT
}