import gql from "graphql-tag"
import ORDER_FRAGMENT from "graphql/fragments/OrderFragment"

const GET_ORDER_BY_ID = gql`
  query GetOrderById($id: ID!) {
    orders(ids: [$id]) {
      ...OrderFragment
    }
  }
  ${ORDER_FRAGMENT}
`

export default GET_ORDER_BY_ID
