import * as React from "react"
import NavbarUserDropdown from "./NavbarUserDropdown"
import NavbarChangeOfficeModal from "./NavbarChangeOfficeModal"
import { AppContext } from "App"

class NavbarUser extends React.PureComponent<{ url: string }, NavbarUserState> {
  state = { modalIsOpen: false }
  isCancelled = false

  onOpenOfficeModal = () => this.setState({ modalIsOpen: true })

  closeModal = () => !this.isCancelled && this.setState({ modalIsOpen: false })

  componentWillUnmount = () => (this.isCancelled = true)

  render() {
    return (
      <React.Fragment>
        <AppContext.Consumer>
          {({ currentUserSession }) => {
            let canChangeOffice = false
            let canToggleSupportView = false
            let canChangeOfficeSettings = false
            let canLogout = false
            let username = ""
            let officeName
            if (currentUserSession.user) {
              if (currentUserSession.user.customers) {
                if (currentUserSession.user.customers.length > 1) {
                  canChangeOffice = true
                }
              }
              canChangeOfficeSettings =
                currentUserSession.user.isPowerUserOffice ||
                currentUserSession.user.isPowerUserCompany ||
                currentUserSession.user.isSupportUser
              canToggleSupportView = currentUserSession.user.isSupportUser
              canLogout = true

            }

            if (currentUserSession.customer) {
              username = currentUserSession.customer.name

              if (currentUserSession.customer.office) {
                officeName = currentUserSession.customer.office.name
              }
            }


            return (
              <>
                <NavbarUserDropdown
                  onOpenOfficeModal={this.onOpenOfficeModal}
                  canChangeOffice={canChangeOffice}
                  canToggleSupportView={canToggleSupportView}
                  canChangeOfficeSettings={canChangeOfficeSettings}
                  canLogout={canLogout}
                  username={username}
                  officeName={officeName}
                  isSupportUser={currentUserSession.user?.isSupportUser}
                />
                {canChangeOffice && (
                  <NavbarChangeOfficeModal
                    isOpen={this.state.modalIsOpen}
                    onRequestClose={this.closeModal}
                  />
                )}
              </>
            )
          }}
        </AppContext.Consumer>
      </React.Fragment>
    )
  }
}

interface NavbarUserState {
  modalIsOpen: boolean
}

export default NavbarUser
