import * as React from "react"
import * as styles from "./styles.scss"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import cx from "classnames"

class CalendarIcon extends React.PureComponent<CalendarIconProps> {
  render() {
    return (
      <div
        className={cx(styles.calendarButtonContainer, {
          [styles.disabled]: this.props.disabled,
        })}
      >
        <FontAwesomeIcon icon="calendar-alt" />
      </div>
    )
  }
}

interface CalendarIconProps {
  disabled?: boolean
}

export default CalendarIcon
