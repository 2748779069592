import * as React from "react"
import { useReducer, useEffect, useState } from "react"
import Modal from "components/Modal"
import Header from "components/Header"
import { useTranslation } from "react-i18next"
import cx from "classnames"
import { AnyObject } from "final-form"
import * as styles from "./styles.scss"
import OrderRows from "./OrderRows"
import SubmitButton from "./SubmitButton"
import { omitProps } from "utils"
import { useMutation } from "react-apollo-hooks"
import PUBLISH_ORDERS from "graphql/mutations/publishOrders"
import AdvertTotalPrice from "./AdvertsTotalPrice"

import { useParams } from "react-router-dom"
import { useQuery, useApolloClient } from "react-apollo-hooks"
import GET_PUBLISH_ORDER from "graphql/queries/getPublishOrder"
import {
  GetPublishOrders,
  GetPublishOrdersVariables,
  GetPublishOrders_caseFolder_documents_orders,
  GetPublishOrders_caseFolder_documents_summary_price_endCustomer,
  PublishOrders,
  PublishOrdersVariables,
  PublishOrders_publishOrders_resultOrders,
  // PublishOrders_publishOrders,
  // PublishOrders_publishOrders_orders,
} from "schema"
import { EditOrderPageParams } from "routes/EditOrderPageRoutes"
import Spinner from "components/Spinner"
import { notEmpty } from "utils"
import MultiSelection from "./MutliSelection"
import { ModalCompareAdverts } from "../../../../EditOrderPage/OrderEditorContainer/PublishOrderBlock/CompareModal"
import Button from "components/Button"
import produce from "immer"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { setTimeout } from "timers"
import {
  faExclamationCircle,
  faInfoCircle,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons"
import { isError } from "lodash"
import { IResultOrders } from "customSchema.interface"
import WarningModal from "./WarningModal"

enum EResultIcon {
  NONE = "NONE",
  INFORMATION = "INFORMATION",
  WARNING = "WARNING",
  ERROR = "ERROR",
}

export const OrderModal: React.FC<{
  isOpen: boolean
  onClose: () => void
  documentId: string
  setIsLoading: any
}> = (props) => {
  const params = useParams<EditOrderPageParams>()
  const caseFolderId = params.caseFolderId
  const orderId = params.orderId ?? ""
  const client = useApolloClient()

  const { data, loading, error } = useQuery<
    GetPublishOrders,
    GetPublishOrdersVariables
  >(GET_PUBLISH_ORDER, {
    client,
    variables: {
      caseFolderId: caseFolderId,
      documentId: props.documentId,
    },
    context: {},
    fetchPolicy: "network-only",
  })

  return (
    <>
      {
        loading && loading && props.setIsLoading(loading)
        // (
        //   <div className={styles.container}>
        //     <div className={styles.whiteframe}>
        //       <div className={styles.loadingContainer}>
        //         <Spinner size={25} innerBallSize={5} />
        //       </div>
        //     </div>
        //   </div>
        // )
      }

      {error && (
        <div className={styles.container}>
          <div className={styles.whiteframe}>
            <div>{error.message}</div>
          </div>
        </div>
      )}

      {data &&
        data.caseFolder &&
        data.caseFolder.documents.map((d) => {
          if (!d) {
            return null
          }
          if (!d.id) {
            return null
          }
          let order
          d.orders.map((o) => {
            if (!o) {
              return
            }
            if (!o.id) {
              return
            }
            if (o.id === orderId) {
              order = o
            }
          })
          if (order === undefined) {
            return
          }
          props.setIsLoading(false)
          return (
            <PublishOrdersModal
              key={d.id}
              orders={d.orders.filter(notEmpty)}
              isOpen={props.isOpen}
              onClose={props.onClose}
              totalPrice={d.summary.price.endCustomer}
            />
          )
        })}
    </>
  )
}

const PublishOrdersModal: React.FC<PublishOrdersModalProps> = (props) => {
  const [orders, dispatch] = useReducer(reducer, {})
  const [fakeProgressStarted, setFakeProgressStarted] = React.useState(false)
  const [t] = useTranslation(["review", "common"])
  const publishOrders = useMutation(PUBLISH_ORDERS)
  const [isLoading, setIsLoading] = React.useState(false)
  const [isOrdersSelected, setOrderSelected] = React.useState<boolean>(false)
  const [globalMsg, setGlobalMsg] = React.useState<{
    resultText: string | null
    resultIcon: EResultIcon
    show: boolean
  }>({
    resultText: null,
    resultIcon: EResultIcon.NONE,
    show: false,
  })
  const [modalCompareAdvertsOpen, setModalCompareAdvertsOpen] = React.useState(
    false
  )
  const [resultOrders, setResultOrders] = React.useState<any>(null)
  const [memmoryRoomMsg, setMemmoryRoomMsg] = React.useState<
    PublishOrders_publishOrders_resultOrders | any
  >(null)

  useEffect(() => {
    dispatch({ type: "initPublishOrders" })
  }, [props.orders])

  function initPublishOrders() {
    return props.orders.reduce(
      (acc, order) => ({
        ...acc,
        [order.id!]: {
          orderId: order.id!,
          order,
          isSelected: false,
          errors: [],
          messageToMedia: "",
          codeLate: "",
          mediaSystemOrderNumber: "",
          mediaCustomerSubriberNumber: "",
        },
      }),
      {}
    )
  }

  function toggleSelect(state: PublishOrderKeyValueMap, action: ReducerAction) {
    const p = action.payload!
    return {
      ...state,
      [p.id]: {
        ...state[p.id],
        isSelected: state[p.id].errors.length === 0 && !state[p.id].isSelected,
      },
    }
  }

  function toggleSelectAll(state: any, action: ReducerAction) {
    const orders = props.orders
    return orders.reduce((acc, order) => {
      let isCodeLate = order.mediaOrder?.publishConversation.isCodeLate
      let isSub =
        order.mediaOrder?.publishConversation.isMediaCustomerSubriberNumber
      let publishTime = order.mediaOrder?.publishTimes[0]
      return {
        ...acc,
        [order.id!]: {
          ...state[order.id!],
          isSelected: !isCodeLate && publishTime && !isSub && true,
        },
      }
    }, {})
  }

  function updateValue(state: PublishOrderKeyValueMap, action: ReducerAction) {
    const p = action.payload!
    return {
      ...state,
      [p.id]: {
        ...state[p.id],
        [p.name]: p.newValue,
      },
    }
  }

  function appendTooltip(
    state: PublishOrderKeyValueMap,
    action: ReducerAction
  ) {
    const p = action.payload!
    const order: PublishOrder = state[p.id]

    if (p.errors === order.errors) return state

    return {
      ...state,
      [p.id]: {
        ...state[p.id],
        errors: p.errors,
      },
    }
  }

  function reducer(state: PublishOrderKeyValueMap, action: ReducerAction) {
    switch (action.type) {
      case "initPublishOrders":
        return initPublishOrders()
      case "toggleSelect":
        return toggleSelect(state, action)
      case "toggleSelectAll":
        return toggleSelectAll(state, action)
      case "updateValue":
        return updateValue(state, action)
      case "appendTooltip":
        return appendTooltip(state, action)
      default:
        return state
    }
  }

  const orderAndSelectedOrder = () => {
    const inputOrders = Object.keys(orders).map((orderId) => {
      let order: any = { ...orders[orderId] }
      if (order.messageToMedia === "") order.messageToMedia = null
      if (order.mediaSystemOrderNumber === "")
        order.mediaSystemOrderNumber = null
      if (order.mediaCustomerSubriberNumber === "")
        order.mediaCustomerSubriberNumber = null
      if (order.codeLate === "") order.codeLate = null

      return order
    })
    return {
      _orders: inputOrders,
      selectedOrders: inputOrders.filter((po) => po.isSelected && !po.errors),
      // unsentOrders:  inputOrders.filter((_o) => (!_o.order.mediaOrder.publishConversation.isBookingSent))
    }
  }

  const updateData = () => {
    setFakeProgressStarted(false)
    const { selectedOrders } = orderAndSelectedOrder()
    publishOrders({
      variables: {
        input: {
          orders: omitProps(selectedOrders, ["order", "isSelected", "errors"]),
        },
      },
    })
      .then((result) => {
        let data: any = result.data
        let isErorr: boolean = data?.publishOrders.isError
        return new Promise((resolve) => {
          if (data) {
            setGlobalMsg(
              produce(globalMsg, (draft) => {
                draft.resultText = data.publishOrders.resultText
                draft.resultIcon = data.publishOrders.resultIcon
                draft.show = data.publishOrders.resultText && true
              })
            )
            setResultOrders([...data.publishOrders.resultOrders])
            if (data.publishOrders.resultOrders) {
              selectedOrders.map((orderId) => {
                data.publishOrders.resultOrders.filter(
                  (ro: PublishOrders_publishOrders_resultOrders) =>
                    ro && ro.order.id != orderId && setMemmoryRoomMsg(ro)
                )
              })
            }

            setTimeout(() => {
              if (isErorr) {
                resolve(props.onClose())
              }
              if (globalMsg.show) {
                setGlobalMsg(
                  produce(globalMsg, (draft) => {
                    draft.resultText = null
                    draft.resultIcon = EResultIcon.NONE
                    draft.show = false
                  })
                )
                resolve(props.onClose())
              }
            }, 2000)
          }
          setIsLoading(false)
          setFakeProgressStarted(false)
        })
      })
      .catch((err) => console.log("publishSubmit", err))

    setIsLoading(true)
  }

  function handleSubmit() {
    const { selectedOrders, _orders } = orderAndSelectedOrder()
    if (selectedOrders.length === _orders.length) {
      updateData()
    } else {
      setOrderSelected(true)
    }
  }

  function checkButtonDisabled() {
    const selected = Object.keys(orders).map((id) => orders[id].isSelected)
    return selected.indexOf(true) === -1
  }

  const onPressModalCompareAdvertsOpen = () => {
    setModalCompareAdvertsOpen(!modalCompareAdvertsOpen)
  }

  const onPressModalCompareAdvertsClose = () => {
    setModalCompareAdvertsOpen(false)
  }

  return (
    <>
      <Modal
        isOpen={props.isOpen}
        onRequestClose={props.onClose}
        large={true}
        className={styles.publishOrdersModal}
      >
        <div>
          <Header
            title={t("common:send")}
            subtitle={t("publishModalDescription")}
          />
          <OrderRows
            orders={orders}
            onDispatch={dispatch}
            fakeProgressStarted={fakeProgressStarted}
            resultOrders={resultOrders}
          />
          {memmoryRoomMsg && (
            <div
              className={cx(styles.memoryRoomMsg, {
                [styles.info]:
                  memmoryRoomMsg.resultIcon === EResultIcon.INFORMATION,
              })}
            >
              <span>
                {memmoryRoomMsg.resultIcon === EResultIcon.INFORMATION && (
                  <FontAwesomeIcon icon={faInfoCircle} />
                )}
              </span>
              <span>{memmoryRoomMsg.resultText}</span>
            </div>
          )}
          {isLoading && (
            <div className={styles.localSpinner}>
              <Spinner />
            </div>
          )}

          <div className={styles.row2}>
            <MultiSelection onDispatch={dispatch} orders={orders} />
            {/* <AdvertTotalPrice totalPrice={props.totalPrice} /> */}
          </div>
        </div>

        <div className={styles.row3}>
          <Button
            onClick={onPressModalCompareAdvertsOpen}
            variant={"tertiary"}
            icon={"search-plus"}
          >
            <span>{t("review:compare")}</span>
          </Button>

          <SubmitButton
            onSubmit={handleSubmit}
            disabled={checkButtonDisabled()}
          />
        </div>
        {globalMsg.show && (
          <div
            className={cx(styles.resultMsg, {
              [styles.info]: globalMsg.resultIcon === EResultIcon.INFORMATION,
              [styles.warning]: globalMsg.resultIcon === EResultIcon.WARNING,
              [styles.error]: globalMsg.resultIcon === EResultIcon.ERROR,
            })}
          >
            <span>
              {(globalMsg.resultIcon === EResultIcon.INFORMATION && (
                <FontAwesomeIcon icon={faInfoCircle} />
              )) ||
                (globalMsg.resultIcon === EResultIcon.WARNING && (
                  <FontAwesomeIcon icon={faExclamationCircle} />
                )) ||
                (globalMsg.resultIcon === EResultIcon.ERROR && (
                  <FontAwesomeIcon icon={faTimesCircle} />
                ))}
            </span>
            <span>{globalMsg.resultText}</span>
          </div>
        )}
      </Modal>
      {modalCompareAdvertsOpen === true ? (
        <ModalCompareAdverts
          isOpen={modalCompareAdvertsOpen}
          onClose={onPressModalCompareAdvertsClose}
          orders={props.orders}
        />
      ) : null}
      <WarningModal
        isOpen={isOrdersSelected}
        doClose={() => setOrderSelected(false)}
        onDispatch={dispatch}
        updateData={updateData}
      />
    </>
  )
}

export interface PublishOrderKeyValueMap {
  [id: string]: PublishOrder
}
export interface PublishOrderValueMap {
  PublishOrder: any
}
export interface ReducerAction {
  type:
    | "initPublishOrders"
    | "toggleSelect"
    | "toggleSelectAll"
    | "updateValue"
    | "appendTooltip"
  payload?: AnyObject
}

export interface PublishOrder {
  orderId: string
  order: GetPublishOrders_caseFolder_documents_orders
  isSelected: boolean
  errors: string
  mediaCustomerSubriberNumber: string
  mediaSystemOrderNumber: string
  codeLate: string
  messageToMedia: string
}

interface PublishOrdersModalProps {
  isOpen: boolean
  onClose: () => void
  orders: GetPublishOrders_caseFolder_documents_orders[]
  totalPrice: GetPublishOrders_caseFolder_documents_summary_price_endCustomer
}

export default OrderModal
