import * as React from "react"
import { useContext } from "react"
import * as styles from "./styles.scss"
import { PublishOrderContext } from "../.."
import { useTranslation } from "react-i18next"

const PublishDate = () => {
  const { publishOrder } = useContext(PublishOrderContext)
  const [t] = useTranslation()
  const publishDate = publishOrder.order.mediaOrder!.publishTimes[0]

  

  const dateFormated = (): JSX.Element | any => {
    const days = [
      "Sön",
      "Mån",
      "Tis",
      "Ons",
      "Tors",
      "Fre",
      "Lör",
    ]
    const months = [
      "Januari",
      "Februari",
      "Mars",
      "April",
      "Maj",
      "Juni",
      "Juli",
      "Augusti",
      "September",
      "Oktober",
      "November",
      "December",
    ]
    const date = new Date(publishDate?.time)
    const day: string | any = days[date.getDay()]
    const month: string | any = months[date.getMonth()]
    const month_number: number | any = date.getDate()

    if (date) {
      return (
        <div>
          <b>
            {day} {month_number} {month}
          </b>
        </div>
      )
    } else <span>N/A</span>
  }

  return (
    <div className={styles.publishDate}>
      <span>{t("publishDate")}:</span>
      {/* {publishDate ? publishDate.time : "N/A"} */}
      {dateFormated()}
    </div>
  )
}

export default PublishDate
