import * as React from "react"
import * as styles from "./styles.scss"
import Modal from "components/Modal"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useTranslation } from "react-i18next"
import { copyToClipboard } from "utils"

function LiveViewModal(props: LiveViewModalProps) {
  const [isShowingCopiedText, setIsShowingCopiedText] = React.useState(false)
  const [t] = useTranslation("liveView")
  const inputRef: React.RefObject<HTMLInputElement> = React.createRef()

  const handleCopyToClipboard = (e: React.MouseEvent) =>
    copyToClipboard(inputRef, () => {
      setIsShowingCopiedText(true)
      setTimeout(() => setIsShowingCopiedText(false), 3000)
    })

  return (
    <Modal {...props} className={styles.liveViewModal}>
      <div className={styles.content}>
        <FontAwesomeIcon icon={["far", "dot-circle"]} className={styles.icon} />
        <p>{t("liveViewModalDescription")}</p>
        <input
          readOnly={true}
          value="http://timecut.se/live/123"
          ref={inputRef}
          onClick={handleCopyToClipboard}
        />
        <span>{isShowingCopiedText && t("copiedToClipboard")}</span>
        <p className={styles.turnOffDescription}>
          {t("liveViewModalTurnOffDescription")}
        </p>
      </div>
    </Modal>
  )
}

interface LiveViewModalProps {
  isOpen: boolean
  onRequestClose: () => void
}

export default LiveViewModal
