import * as React from "react"
import * as styles from "./styles.scss"
import Header from "components/Header"
import moment from "moment"
import { faIdCard } from "@fortawesome/free-solid-svg-icons"

class HeaderRow extends React.PureComponent<HeaderRowProps> {
  render() {
    const {
      renderActionButton,
      renderCancelButton,
      renderDeleteButton,
      title,
      funeralDetails,
    } = this.props

    return (
      <div className={styles.header}>
        <Header
          title={title}
          className={styles.title}
          icon={funeralDetails && faIdCard}
          subtitle={
            funeralDetails && (
              <div className={styles.funeralDetails}>
                <span>
                  &#9733;&ensp;
                  {moment(funeralDetails.personBorn).format("yyyy-MM-DD")}
                </span>
                -
                <span>
                  {moment(funeralDetails.personDied).format("yyyy-MM-DD")}
                </span>
              </div>
            )
          }
        />
        <div>
          {renderDeleteButton && renderDeleteButton()}
          {renderCancelButton && renderCancelButton()}
          {renderActionButton()}
        </div>
      </div>
    )
  }
}

interface HeaderRowProps {
  renderActionButton: () => JSX.Element
  renderCancelButton?: () => JSX.Element
  renderDeleteButton?: () => JSX.Element
  title: string
  funeralDetails?: { personBorn: string; personDied: string } | any
}

export default HeaderRow
