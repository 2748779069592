import * as React from "react"
import * as styles from "./styles.scss"
import { useTranslation } from "react-i18next"
import { OrderEditorContext } from "./../../../OrderEditoryAdvert"
import { useMutation } from "react-apollo-hooks"
import UNDO_CHANGE from "graphql/mutations/undoChange"
import { useContextSelector } from "use-context-selector"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faUndo } from "@fortawesome/free-solid-svg-icons"
import Spinner from "components/Spinner"
import cx from "classnames"

const UndoButton:React.FC<{
  isFEUser?: boolean
}> = (props) => {
  const [t] = useTranslation("editOrder")
  // const { selectedOrder } = React.useContext(OrderEditorContext)
  const { undoDisabled, orderId } = useContextSelector(
    OrderEditorContext,
    (s) => {
      return {
        orderId: s.selectedOrder.id ?? "",
        undoDisabled:
          s.selectedOrder.mediaOrder?.edit.history.countStepBack === 0,
      }
    }
  )
  const [isWorking, setIsWorking] = React.useState(false)
  const undo = useMutation(UNDO_CHANGE)
  // const stepsBack = selectedOrder.mediaOrder!.edit.history.countStepBack

  function handleUndoChange() {
    if (undoDisabled === true) return

    setIsWorking(true)

    undo({
      variables: {
        input: {
          orderId,
          offset: -1,
        },
      },
    }).then(() => setIsWorking(false))
  }

  return (
    <div className={cx(styles.undoContainer,{
      [styles.feView__undoContainer]: props.isFEUser!,
    })}>
      <button
        className={styles.undoButton}
        disabled={undoDisabled === true}
        onClick={handleUndoChange}
      >
        <span style={isWorking ? {marginRight: "3px"} : {}}>
          {isWorking ? (
            <Spinner size={11} innerBallSize={4}   />
          ) : (
            <FontAwesomeIcon icon={faUndo} />
          )}
        </span>

        <span>{t("undoChange")}</span>
      </button>
    </div>
  )
}

export default UndoButton
