import gql from "graphql-tag"
import PREVIEW_IMAGE_FRAGMENT from "graphql/fragments/PreviewImageFragment"
import AREA_FRAGMENT from "graphql/fragments/AreaFragment"
import ORDER_FRAGMENT from "graphql/fragments/OrderFragment"

const UPDATE_DOCUMENT_FORMAT = gql`
  mutation UpdateDocumentFormat(
    $input: MutationUpdateOrderDocumentFormatInput!
  ) {
    updateOrderDocumentFormat(input: $input) {
      order {
        ...OrderFragment
      }
    }
  }
  ${ORDER_FRAGMENT}
  ${PREVIEW_IMAGE_FRAGMENT}
  ${AREA_FRAGMENT}
`

export default UPDATE_DOCUMENT_FORMAT
