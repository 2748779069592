import * as React from "react"
import { SelectOrderInitiatorView_selectOrderInitiatorView_options } from "schema"
import { ErrorText } from "components/ErrorMessage"
import { useTranslation } from "react-i18next"
import Select from "components/Select"
import ReactSelect from "react-select"

export const LayoutMultiDropdownLine: React.FC<{
  caseFolderId: string
  orderId?: string
  options: SelectOrderInitiatorView_selectOrderInitiatorView_options[]
  onCloseModal: any
  onSelectItem: (selector: string) => void
}> = (props) => {
  // const { t } = useTranslation(["editOrder"])
  const [t] = useTranslation(["common"])
  return (
    <div className={"layout-MULTI_DROPDOWN_LINE"}>
      {props.options.length === 0 ? (
        <div className={"error-wrapper"}>
          <ErrorText>{t("common:noResults")}</ErrorText>
        </div>
      ) : null}

      <ReactSelect
        // label={t("selectNewspaper")}
        options={props.options.map((e) => {
          return { id: e.selector ?? "", label: e.name ?? "" }
        })}
        value={null}
        className={"select"}
        onChange={(option: { id: string; label: string }) => {
          const selector = option.id
          props.onSelectItem(selector)
        }}
        menuPlacement={"auto"}
        id={"select-news-dropdown"}
      />
    </div>
  )
}
