import * as React from "react"
import * as styles from "./styles.scss"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import cx from "classnames"
import { AppContext } from "App"


const AdminSectionToggler: React.FC<{
  isExpanded: boolean
  onClick: () => void
}> = (props) => {
  const {featureFlags} = React.useContext(AppContext)
  return (
    <div onClick={props.onClick} className={cx(styles.adminSectionToggler, {
      [styles.feView_adminSectionToggler]: featureFlags?.isFEUser
    } ) }>
      <FontAwesomeIcon
        icon="caret-down"
        className={cx(styles.caret, { [styles.expanded]: props.isExpanded })}
      />
      <FontAwesomeIcon icon="edit" />
    </div>
  )
}

export default AdminSectionToggler
