import * as React from "react"
import MainLayout from "layouts/MainLayout"
import BackgroundImage from "components/BackgroundImage"
import image from "images/image.png"
import ReviewContainer from "./ReviewContainer"
import { WithTranslation, withTranslation } from "react-i18next"
import { RouteComponentProps } from "react-router-dom"

class ReviewPage extends React.Component<
  WithTranslation & RouteComponentProps
> {
  render() {
    return (
      <MainLayout showUtilityBar={true}>
        <BackgroundImage src={image} />
        <ReviewContainer />
      </MainLayout>
    )
  }
}

export default withTranslation("review")(ReviewPage)
