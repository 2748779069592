import * as React from "react"
import * as styles from "./styles.scss"
import { WithTranslation, withTranslation } from "react-i18next"

class Price extends React.PureComponent<PriceProps & WithTranslation> {
  render() {
    const { price, t } = this.props

    return (
      <div className={styles.priceContainer}>
        <span className={styles.price}>
          {price ? `${t("commonPrice")} ${price}:-` : t("priceNotSpecified")}
        </span>
      </div>
    )
  }
}

interface PriceProps {
  price: string
}

export default withTranslation("agencySettings")(Price)
