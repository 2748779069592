import * as React from "react"
import AdminSection from "../../AdminSection"
import AdminMargin from "../../AdminMargin"
import * as styles from "./styles.scss"
import DownloadButton from "./DownloadButton"
import EnlargeButton from "./EnlargeButton"
import BlockMeasurement from "../../HtmlInputAdminSection/BlockMeasurement"

function ImageControlAdminSection(props: ImageControlAdminSectionProps) {
  return (
    <AdminSection isExpanded={props.isExpanded}>
      <DownloadButton selectedSymbolUrl={props.selectedSymbolUrl} />
      {/* <EnlargeButton /> */}
      <hr className={styles.divider} />
      <AdminMargin />
      <hr className={styles.divider} />
      <BlockMeasurement />
    </AdminSection>
  )
}

interface ImageControlAdminSectionProps {
  selectedSymbolUrl?: string
  isExpanded: boolean
}

export default ImageControlAdminSection
