import * as React from "react"
import * as styles from "./styles.scss"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useTranslation } from "react-i18next"
import NewPackageModal from "./NewPackageModal"

function AddPackageButton() {
  const [t] = useTranslation("agencySettings")
  const [modalIsOpen, setModalIsOpen] = React.useState(false)

  const openModal = () => setModalIsOpen(true)

  const closeModal = () => setModalIsOpen(false)

  return (
    <React.Fragment>
      <p className={styles.addPackageButton} onClick={openModal}>
        <span>{t("createPackage")}</span>
        <FontAwesomeIcon icon="plus-circle" />
      </p>
      <NewPackageModal isOpen={modalIsOpen} onRequestClose={closeModal} />
    </React.Fragment>
  )
}

export default AddPackageButton
