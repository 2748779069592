import * as React from "react"
import * as styles from "./styles.scss"
import OfficeContainer from "./OfficeContainer"
import { notEmpty } from "utils"
import { AppContext } from "App"

class Offices extends React.PureComponent<OfficesProps> {
  render() {
    return (
      <AppContext.Consumer>
        {({ currentUserSession }) => (
          <div className={styles.offices}>
            {currentUserSession
              .user!.customers!.filter(notEmpty)
              .map(customer => (
                <OfficeContainer
                  {...this.props}
                  key={customer.id!}
                  customer={customer}
                />
              ))}
          </div>
        )}
      </AppContext.Consumer>
    )
  }
}

interface OfficesProps {
  redirectToAfterClick?: string
  redirectToOrigin?: boolean
  afterClick?: () => void
}

export default Offices
