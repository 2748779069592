import * as React from "react"
import { WithTranslation, withTranslation } from "react-i18next"
import HeaderRow from "components/HeaderRow"
import { Link } from "react-router-dom"
import Button from "components/Button"

class DashboardNewsHeaderRow extends React.PureComponent<WithTranslation> {
  render() {
    const { t } = this.props

    return (
      <HeaderRow
        title={t("news")}
        renderActionButton={() => (
          <Link to="/news">
            <Button>{t("common:showAll")}</Button>
          </Link>
        )}
      />
    )
  }
}

export default withTranslation(["news", "common"])(DashboardNewsHeaderRow)
