import gql from "graphql-tag"

const GET_GALLERY_TEXT_GROUPS_BY_TAGS = gql`
  query getGalleryTextGroupsByTags($input: GalleryTextItemGroupInput!) {
    galleryTextGroupsByTags(input: $input) {
      count
      startIndex

      texts {
        id
        name
	    	textXml
        author
        tags
        internalId
        isEditable
        __typename
      }
      __typename
    }
  }
`

export default GET_GALLERY_TEXT_GROUPS_BY_TAGS

// textGroups {
// 	items {
// 	  id
// 	  name
// 	  textPlain
// 	  textXml
// 	  author
// 	  tags
// 	}
//   }