import * as React from "react"
import * as styles from "./styles.scss"
import cx from "classnames"
import MarginField from "./MarginField"
import { OrderEditorAreaContext } from ".."
import { useTranslation } from "react-i18next"
import {
  GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_common_margin,
  GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_style,
  GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_style_margin,
} from "schema"
import produce from "immer"

type Margin = GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_common_margin | null

type IDefaultValues  = GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_style_margin | null

const AdminMargin: React.FC<{}> = () => {
  const { contentRows, area } = React.useContext(OrderEditorAreaContext)
  const [t] = useTranslation("editOrder")
  let margin: Margin = null
  let defaultValues: IDefaultValues | null = null

  if (
    contentRows &&
    contentRows[0] &&
    contentRows[0].columns &&
    contentRows[0].columns[0].item !== undefined &&
    contentRows[0].columns[0].item !== null &&
    contentRows[0].columns[0].item.common !== undefined &&
    contentRows[0].columns[0].item.common !== null &&
    contentRows[0].columns[0].item.common.margin !== undefined &&
    contentRows[0].columns[0].item.common.margin !== null
  ) {
    margin = contentRows[0].columns[0].item.common.margin
  }

  if (area && area.style !== null && area.style !== undefined) {
    defaultValues = area.style.margin
  }

  if (!margin) {
    return null
  }
  return (
    <div className={styles.adminMargin}>
      <div className={styles.row}>
        <div className={cx(styles.cell, styles.topLeft)} />
        <div className={styles.cell}>
          <MarginField
            direction="top"
            value={margin.top}
            type={margin.type}
            defaultValue={
              defaultValues?.top !== null ? defaultValues?.top!.toFixed(1)! : null
            }
          />
        </div>
        <div className={cx(styles.cell, styles.topRight)} />
      </div>
      <div className={styles.row}>
        <div className={styles.cell}>
          <MarginField
            direction="left"
            value={margin.left}
            type={margin.type}
            defaultValue={
              defaultValues?.left !== null
                ? defaultValues?.left!.toFixed(1)!
                : null
            }
          />
        </div>
        <div className={cx(styles.cell, styles.center)}>
          <b>{t("margin")}</b>
        </div>
        <div className={styles.cell}>
          <MarginField
            direction="right"
            value={margin.right}
            type={margin.type}
            defaultValue={
              defaultValues?.right !== null
                ? defaultValues?.right!.toFixed(1)!
                : null
            }
          />
        </div>
      </div>
      <div className={styles.row}>
        <div className={cx(styles.cell, styles.bottomLeft)} />
        <div className={styles.cell}>
          <MarginField
            direction="bottom"
            value={margin.bottom}
            type={margin.type}
            defaultValue={
              defaultValues?.bottom !== null
                ? defaultValues?.bottom!.toFixed(1)!
                : null
            }
          />
        </div>
        <div className={cx(styles.cell, styles.bottomRight)} />
      </div>
    </div>
  )
}

export default AdminMargin
