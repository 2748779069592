import * as React from "react"
import * as styles from "./styles.scss"
import UnitValueInput from "../UnitValueInput"
import svg from "images/text-kerning-icon.svg"
import { OrderEditorAreaContext } from "../.."
import { useTranslation } from "react-i18next"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faMinusCircle } from "@fortawesome/free-solid-svg-icons"

const LetterSpacingInput: React.FC<{}> = (props) => {
  const [t] = useTranslation("editOrder")
  const { setLetterSpacing, contentRows } = React.useContext(
    OrderEditorAreaContext
  )

  const [inputValue, setInputValue] = React.useState<number | null>(null)



  React.useEffect(() => {
    let currentLetterSpacing: number | null = null
    if (
      contentRows !== undefined &&
      contentRows[0] !== undefined &&
      contentRows[0].columns !== undefined &&
      contentRows[0].columns[0] !== undefined &&
      contentRows[0].columns[0].item !== undefined &&
      contentRows[0].columns[0].item.text !== undefined &&
      contentRows[0].columns[0].item.text !== null &&
      contentRows[0].columns[0].item.text.letterSpacing !== undefined &&
      contentRows[0].columns[0].item.text.letterSpacing !== null &&
      contentRows[0].columns[0].item.text.letterSpacing.value !== undefined &&
      contentRows[0].columns[0].item.text.letterSpacing.value !== null
    ) {
      currentLetterSpacing =
        contentRows[0].columns[0].item.text.letterSpacing.value
      if (inputValue !== currentLetterSpacing) {
        setInputValue(currentLetterSpacing)
      }
    }
  }, [contentRows])

  const handleChange = (value: string | undefined) => {
    if (value === undefined) {
      setInputValue(null)
      setLetterSpacing(null)
    } else {
      const newValue = isNaN(Number(value)) ? 0 : Number(value)
      setInputValue(newValue)
      // setLetterSpacing(newValue)
    }
  }

  const updateValue = () => {
    if (inputValue === null) return;
    setLetterSpacing(inputValue)
  }

  const handleClick = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.code === "Enter" || e.code === "NumpadEnter") {
      updateValue()
    }
  }

  const onClear = () => {
    setInputValue(null)
    setLetterSpacing(null)
  }

  return (
    <div className={styles.wrapper}>
      {inputValue !== null ? (
        <div className={styles.removeIcon} onClick={onClear}>
          <FontAwesomeIcon icon={faMinusCircle} color={"#e6e6e6"} />
        </div>
      ) : null}

      <UnitValueInput
        svg={svg}
        tooltipText={t("kerning")}
        value={
          inputValue === undefined || inputValue === null ? "" : inputValue
        }
        onChange={handleChange}
        unit="pt"
        active={inputValue === null}
        onBlur={updateValue}
        onKeyDown={handleClick}
      />
    </div>
  )
}

export default LetterSpacingInput
