import * as React from "react"
import * as styles from "./styles.scss"
import Item from "./Item"
import { useTranslation } from "react-i18next"

function ParalellPublishing() {
  const [t] = useTranslation()

  return (
    <div className={styles.paralellPublishing}>
      <b>{t("parallelPublishing")}</b>
      <Item text={t("officeWebsite")} />
      <Item text={t("memorialRoom")} />
      <Item text={t("familyPage")} />
    </div>
  )
}

export default ParalellPublishing
