import { NodeBuilderFlags, TextRange, TextSpan } from "typescript"
import { containsOnlyWhitespace } from "utils"
import { HtmlEditor, ISelection } from "./HtmlEditor"

const debugVerbose = false


export type Caret = {
  currentPos:number, previousPos:number, pos:number|undefined, offset:number, getDone:boolean, setDone:boolean
}|null|undefined

const tagCarets = ["P","IMG"]

// get the cursor position from .editor start
export function getCaret(parent:Element, selection:ISelection|null, caret:Caret=null, childOffset:number = 0):Caret {
  if(!selection) return null;

  if(caret == null) {
    if(debugVerbose) console.log("getCursorPosition init", selection)
    caret = {currentPos: 0, previousPos:0, pos:undefined, offset:(selection.anchorOffset ?? 0), getDone: false, setDone:false}
  } 
  if (caret.getDone) return caret;

  let length = 0;
  if(  tagCarets.some(q=>q == parent?.tagName) ) {
    length = 1 // Test
    if(parent.tagName == "BR" && parent.parentElement?.firstChild == parent) {  length=0; }
    caret.currentPos += length // Why this extra is needed is not sorted out, but it work
    if(debugVerbose) console.log(`   CursorPosition #${parent?.tagName} : ${caret.currentPos} `)
  } 

  if(parent.nodeType == Node.TEXT_NODE) {
    length = (parent as any).textContent.length;
    if(debugVerbose) console.log(`   CursorPosition #text : ${ caret.currentPos}`)
  }

  if (parent === selection.anchorNode && (parent.nodeType == Node.TEXT_NODE || selection.anchorOffset == childOffset)) {
    caret.pos = caret.currentPos + (selection.anchorOffset ?? 0);
    caret.currentPos = 0; // + (currentNode as any).textContent.length;
    caret.getDone = true;
    if(debugVerbose) console.log(`getCursorPosition done : ${ caret.pos }`)
    return caret;
  }

  caret.currentPos += length


  for (var i = 0; i < parent.childNodes.length && !caret.getDone; i++) {
    let currentNode = parent.childNodes[i] as Element
    getCaret(currentNode, selection, caret, i);
  }

  return caret;
}

export function setCaret(parent:Element, caret:Caret ) {
  if(!parent || !caret) return null;
  if (caret.setDone) return caret;
  if (!caret.getDone) return caret;

  if(caret.currentPos == 0)
    if(debugVerbose) console.log("setCursorPosition init", caret)

  caret.previousPos = caret.currentPos
  let length = 0, noLength=false
  if(  tagCarets.some(q=>q == parent?.tagName) ) {
    length = 1 // Test
    noLength = true
    if(parent.tagName == "BR" && parent.parentElement?.firstChild == parent) { noLength = false; length=0; }
    caret.currentPos += length // Why this extra is needed is not sorted out, but it work
    if(debugVerbose) console.log(`   CursorPosition #${parent?.tagName} : ${caret.currentPos} `)
  } 
  if(parent.nodeType == Node.TEXT_NODE) {
    length = (parent as any).textContent.length;
    if(debugVerbose) console.log(`   CursorPosition #text : ${ caret.currentPos}`)
  }

  if (caret.pos && (caret.currentPos+length) >= caret.pos) {
    const pos = noLength && false ? 0 : caret.pos-caret.currentPos;
    if(debugVerbose) console.log(`setCursorPosition done : ${ caret.currentPos + pos }`)
    caret.currentPos = 0; // + (currentNode as any).textContent.length;
    caret.setDone = true;

    const range = document.createRange()
    if(noLength)
      range.selectNodeContents(parent)
    else
      range.setStart(parent, pos);
    const winSelector = window.getSelection()
    if(winSelector && range) {
      if(range.startContainer != winSelector.anchorNode || range.startOffset != winSelector.anchorOffset ) {
        if(debugVerbose) console.log(`setCursor at nodeType${range.startContainer.nodeType}:${range.startOffset}`)
        winSelector?.removeAllRanges();
        range.collapse(false);
        winSelector.addRange(range);
      }
    }

    return caret;
  }

  caret.currentPos += length

  for (var i = 0; i < parent.childNodes.length && !caret.setDone; i++) {
    let currentNode = parent.childNodes[i] as Element;
    setCaret(currentNode, caret);
  }

  return caret;
}




function splitWordsIntoSpan(editable: HTMLElement,caret:Caret|null=null):boolean {

  let isChanged = false

  const styles = [
    "personRelative1",
    "personRelative2",
    "personRelative3",
    "personRelative4",
    "personRelative5",
    "personRelative6",
    "personRelativeElse",
    "personRelative1B",
    "personRelative2B",
    "personRelative3B",
    "personRelative4B",
    "personRelative5B",    
    "personRelative6B",
  ]
  const andRows = [/^och$/gi, /^og$/gi, /\&/gi]

  let line = editable.firstChild as HTMLElement
  while (line != null) {
    if (
      line.nodeName === "P" &&
      styles.some(s => line.getAttribute("class") === s)
    ) {
      let textNode: Node | null = line.firstChild
      let alignNode: HTMLElement|null = document.createElement("span")

      if (textNode?.nextSibling) {
        line.insertBefore(alignNode, textNode.nextSibling)
      } else {
        line.appendChild(alignNode)
      }
      let lastInsertNode = alignNode

      while (textNode != null) {
        if (textNode.nodeType === Node.TEXT_NODE) {
          let text = textNode.textContent
          
          while (text !== "") {
            const match = /^(\;|\||\++|\s+|[^;|\+\s]+)/gi.exec((text && text) || "")
            let sentence = (match && match[1]) || ""
            text = (text && text.substring(sentence.length)) || ""
            let type = "personRelativeName"
            if (/\s+/.exec(sentence)) {
              type = "personRelativeSpace"
              sentence = sentence.replace(" ", " ") // replace space with &#160; 
            } else if (/^(\||\+\+)/.exec(sentence)) {
              type = "personRelativeDivider"
              // sentence="";
              alignNode = document.createElement("span")
              if(caret != null && caret.pos != null) caret.pos -= sentence.length-1
              sentence="|"
              if (lastInsertNode?.nextSibling) {
                line.insertBefore(alignNode, lastInsertNode.nextSibling)
              } else {
                line.appendChild(alignNode)
              }
              lastInsertNode = alignNode
            } else if (andRows.some(s => s.exec(sentence) != null)) {
              type = "personRelativeAnd"              
            } else {
              switch (sentence.toLowerCase()) {
                case "och":
                case "o":
                case "&":
                case "&amp;":
                case "ja": // Finska
                  type = "personRelativeAnd";
                    break;
                case "med":
                case "i":
                case "många":
                case "familj":
                case "familjer":
                case "vän":
                case "vänner":
                case "släkt":
                  type = "personRelativeOther";
                    break;
              }              
            }
            const spanElement = document.createElement("span") as HTMLElement
            spanElement.textContent = sentence
            spanElement.setAttribute("class", type)
            spanElement.setAttribute("xml:preserve", "whitespace")
            spanElement.setAttribute("xml:space", "preserve")
            alignNode.append(spanElement)

            if(type == "personRelativeDivider") {
              alignNode = document.createElement("span")
              if (lastInsertNode?.nextSibling) {
                line.insertBefore(alignNode, lastInsertNode.nextSibling)
              } else {
                line.appendChild(alignNode)
              }
              lastInsertNode = alignNode
            }
          }
          const removeNode = textNode
          textNode = textNode.nextSibling
          if (removeNode.parentElement)
            removeNode.parentElement.removeChild(removeNode)
        } else {
          const elementNode = textNode as HTMLElement
          textNode = textNode.nextSibling
          if(["BR","IMG"].some(q=>q == elementNode?.tagName)) {
            elementNode.parentElement?.removeChild(elementNode)
            alignNode.append( document.importNode(elementNode, true))
            }
        }
      }

      if( line.childNodes.length >= 2 ) {
        (line.childNodes[0] as HTMLElement).setAttribute("class", "columnL");
        (line.childNodes[0] as HTMLElement).setAttribute("aType", "columnL");
        (line.childNodes[1] as HTMLElement).setAttribute("class", "columnM");
        (line.childNodes[1] as HTMLElement).setAttribute("aType", "columnM");
        (line.childNodes[2] as HTMLElement).setAttribute("class", "columnR");
        (line.childNodes[2] as HTMLElement).setAttribute("aType", "columnR");
      }

      isChanged = true;
    }

    line = line.nextSibling as HTMLElement
  }

  return isChanged
}

function cleanLevelP(pElement:HTMLElement,caret:Caret|null=null):boolean {
  // flattern P-tag so it only contains of text-node or br,img-tags  span-tags disappear
  let currentNode = pElement.firstChild as Element
  let isChanged = false;


  if(currentNode == null) {
    // put BR in empty P tag
    if(debugVerbose) console.log("create br-tag")
    pElement.append(window.document.createElement("br"))    
    isChanged = true;
  } else {
    while(currentNode != null) {
      let nextCurrentNode = currentNode.nextElementSibling as Element
  
      if( currentNode.nodeType == Node.ELEMENT_NODE) {
        if( ["BR", "IMG"].indexOf(currentNode.tagName) < 0 ) {
          // Move the content in the "span" tag inside the P-tag
          let inlineElementChild = currentNode.firstChild as Element|null
          let insertBefore = currentNode.nextSibling
          while(inlineElementChild != null) {
            let inlineElementChildNext = null
            if(inlineElementChild.firstChild != null) {
              inlineElementChildNext = inlineElementChild.firstChild as Element
            } else if(inlineElementChild.nextSibling != null ) {
              inlineElementChildNext = inlineElementChild.nextSibling as Element
            } else {
              inlineElementChildNext=inlineElementChild.parentElement as Element
              while( inlineElementChildNext.nextSibling == null && inlineElementChildNext != currentNode ) inlineElementChildNext = inlineElementChildNext.parentElement as Element;
              if(inlineElementChildNext == currentNode ) inlineElementChildNext=null
              inlineElementChildNext = inlineElementChildNext?.nextSibling as Element
            }


            if(inlineElementChild.nodeType == Node.TEXT_NODE || ["BR","IMG"].some(q=>q == inlineElementChild?.tagName) ) {
              if(insertBefore == null)
                currentNode.parentElement?.appendChild(inlineElementChild)
              else {
                currentNode.parentElement?.insertBefore(inlineElementChild, insertBefore)
              }
              isChanged = true
            }

            inlineElementChild = inlineElementChildNext
          }
          nextCurrentNode = currentNode.nextSibling as Element
          currentNode.parentElement?.removeChild(currentNode)
          isChanged = true
        }
      }
      if(currentNode.nodeType == Node.TEXT_NODE) {
        if( /\n$|\r$/gi.test(currentNode?.textContent ?? "")) {
          currentNode.textContent = currentNode?.textContent?.replace(/\r\n$|\n$|\r$/gi, "") ?? ""
          //if(nextCurrentNode != null) {
            currentNode.parentElement?.insertBefore(window.document.createElement("br"), nextCurrentNode);
          //}
        }
        while( /\n|\r/gi.test(currentNode?.textContent ?? "")) {
          var s = (currentNode?.textContent ?? "").split(/\n|\r/gi, 2)
          currentNode.textContent = s[0].replace(/\r|\n/, "");
          currentNode.parentElement?.insertBefore(window.document.createElement("br"), nextCurrentNode);
          var textNode = window.document.createTextNode(s[1]);
          currentNode.parentElement?.insertBefore(textNode, nextCurrentNode);
          currentNode=(textNode as Node) as Element;
        }
      }
      if(currentNode.nodeType == Node.TEXT_NODE && currentNode.previousSibling != null && currentNode.previousSibling?.nodeType == Node.TEXT_NODE) {
        nextCurrentNode = currentNode.nextSibling as Element
        currentNode.previousSibling.textContent = (currentNode.previousSibling.textContent ?? "") + currentNode.textContent
        currentNode.parentElement?.removeChild(currentNode)
        isChanged = true;
      }
  
      currentNode = nextCurrentNode;
    }
  }

  return isChanged;
}

function cleanLevelRoot(editable: HTMLElement, selection : ISelection|null, caret:Caret|null=null):boolean {
  // ensure it always is P tags in root
  if(debugVerbose) console.log("cleanLevelRoot")
  let isChanged = false;

  if(selection?.anchorNode && caret == null) {
      caret = getCaret(editable, selection);
  }

  let currentNode = editable.firstChild as HTMLElement

  if(currentNode == null) {
    // set p-tag if missing
    var pElement = window.document.createElement("p")
    var brElement = window.document.createElement("br")
    pElement.append(brElement)
    editable.append(pElement)
    if(caret) caret.pos = 2
  } else {
    while(currentNode != null) {
      let nextCurrentNode = currentNode.nextSibling as HTMLElement
  
  
      if( currentNode.nodeType == Node.ELEMENT_NODE ) {
        if(currentNode.nodeName == "P") {
    
        } else {
          // create p tag and put all inside
          var pElement = window.document.createElement("p")
          currentNode.parentElement?.insertBefore(pElement, currentNode)
  
          let elementChild: HTMLElement | null = currentNode
          elementChild.tagName
          while(elementChild != null && elementChild.tagName != "P") {
            elementChild.tagName
            pElement.append(elementChild)
            // 
            elementChild = elementChild.nextSibling as  HTMLElement
          }
          currentNode = pElement
          nextCurrentNode = currentNode.nextElementSibling as HTMLElement
          isChanged = true
        }
      } else if(currentNode.nodeType == Node.TEXT_NODE) {
        // create P-tag is missing
        var pElement = window.document.createElement("p")
        currentNode.parentElement?.replaceChild(pElement, currentNode)
        pElement.appendChild(currentNode)
        isChanged = true
      }
  
      if(currentNode.tagName == "P") {
        isChanged = cleanLevelP(currentNode, caret) || isChanged
      }
  
      currentNode = nextCurrentNode;
    }
  }



  if(debugVerbose) console.debug("isChanged", isChanged)

  isChanged = splitWordsIntoSpan(editable, caret) || isChanged

  // restore the position
  if(caret && isChanged) {
    setCaret(editable, caret);
  }

  return isChanged
}

export function clean(editableElement: HTMLElement|null, selection : ISelection|null, caret:Caret|null=null): void {
  //if (editableElement) alwaysInitState(editableElement as HTMLObjectElement)
  if (editableElement) cleanLevelRoot(editableElement, selection, caret)
}

