import * as React from "react"
import * as styles from "./styles.scss"
import * as cx from "classnames"
import ErrorMessage from "components/ErrorMessage"
import GET_ORDER_BY_ID_MEMORY_ROOM from "graphql/queries/getOrderByIdMemoryRoom"
import { useApolloClient, useQuery, useMutation } from "react-apollo-hooks"
import {
  GetEditOrderPageCaseFolder_caseFolder_editTabs_orders,
  GetOrderByIdMemoryRoom,
  GetOrderByIdMemoryRoomVariables,
  PublishOrders,
  PublishOrdersVariables,
  UpdatePublishTime,
  UpdatePublishTimeVariables,
  UpdateOrderContent,
  UpdateOrderContentVariables,
  UnPublishOrders,
  UnPublishOrdersVariables,
  ReActivateUnPublishOrders,
  ReActivateUnPublishOrdersVariables,
} from "schema"
import Loading from "../Loading"
import { TopSidebarCaseInfoBlock } from "../OrderEditoryAdvert/TopSidebarCaseInfoBlock"
import { useTranslation } from "react-i18next"
import TextInput from "components/TextInput"
import Toggler from "components/Toggler"
import Dropzone, { useDropzone } from "react-dropzone"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import produce from "immer"
import Modal from "components/Modal"
import { ImageEditor } from "./ImageEditor"
import { useFormik } from "formik"
import { FieldWrapperFormik } from "components/FieldWrapper"
import  {
  FormikDateAndTimeField,
} from "components/DateAndTimeField"
import MutationUpdateOrderContent from "graphql/mutations/updateOrderContentValues"
import MemoryRoomForm from "./MemoryRoomForm"
import styled from "styled-components"
import moment from "moment"
import PUBLISH_ORDERS from "graphql/mutations/publishOrders"
import UPDATE_PUBLISH_TIME from "graphql/mutations/updatePublishTime"
import UNPUBLISH_ORDERS from "graphql/mutations/unPublishOrders"
import * as Yup from "yup"

import GET_ORDER_BY_ID_Memory_Room from "graphql/queries/getOrderByIdMemoryRoom"
import UPDATE_ORDER_CONTENT from "graphql/mutations/updateOrderContentValues"
import Spinner from "components/Spinner"
import {
  faMinusCircle,
  faChevronUp,
  faChevronDown,
  faKey,
  faPrint,
  faTimes,
  faLink,
} from "@fortawesome/free-solid-svg-icons"
import Status from "components/Status"
import _ from "lodash"
import Menu from "./Menu"
import ParallelPublishing from "pages/ReviewPage/ReviewContainer/DocumentTypeCard/OrderInformation/ParallelPublishing"
import LockerBox from "components/LockerBox"
import REACTIVE_ORDERS from "graphql/mutations/reactiveOrders"
import CandlesModal from "./CandlesModal"
import {
  Editor,
  Toolbar,
  BtnBold,
  BtnItalic,
  BtnUnderline,
  BtnLink,
  BtnClearFormatting,
} from "react-simple-wysiwyg"
import { AppContext } from "App"

interface ImageSelectDialogFile {
  fileFormInput: any
  name: any
  type: any
  lastModified: any
  lastModifiedDate: any
  size: any
  path: any
  preview: any
}

interface NullableImage {
  value: Image | null
}

interface Image {
  id: string
  src: string
  alt: string
}
export const OrderEditoryMemoryRoom: React.FC<{
  orderId: string
  orders: GetEditOrderPageCaseFolder_caseFolder_editTabs_orders[]
  caseFolderId: string
  caseFolderName: string
}> = (props) => {
  const { t } = useTranslation(["common", "editOrder"])
  const client = useApolloClient()
  const [waiting, setWaiting] = React.useState(false)
  const [showDetails, setShowDetalis] = React.useState<boolean>(false)
  const [isSubmitError, setIsSubmitError] = React.useState<boolean>(false)
  const [isCandlesModalOpen, setIsCandlesModalOpen] = React.useState(false)
  const isMemoryRoomUrlEnabled = React.useContext(AppContext).currentCustomer
    .inheritSettings.isMemoryRoomUrlEnabled
  const isSupportUser = React.useContext(AppContext).currentUserSession.user
    ?.isSupportUser

  const updateMemoryRoomContent = useMutation<
    UpdateOrderContent,
    UpdateOrderContentVariables
  >(MutationUpdateOrderContent, {
    client,
    context: {
      debounceKey: "memory-room",
      debounceTimeout: 1000,
    },
  })

  const mutationPublishOrders = useMutation<
    PublishOrders,
    PublishOrdersVariables
  >(PUBLISH_ORDERS, { client })

  const mutationUpdatePublishTime = useMutation<
    UpdatePublishTime,
    UpdatePublishTimeVariables
  >(UPDATE_PUBLISH_TIME, {
    client,
    context: {
      debounceKey: "publish-time",
      debounceTimeout: 1000,
    },
    refetchQueries: [
      {
        query: GET_ORDER_BY_ID_MEMORY_ROOM,
        variables: { id: props.orderId },
      },
    ],
  })

  const { loading, data, error, refetch } = useQuery<
    GetOrderByIdMemoryRoom,
    GetOrderByIdMemoryRoomVariables
  >(GET_ORDER_BY_ID_MEMORY_ROOM, {
    client,
    variables: {
      id: props.orderId,
    },

    context: {},
    fetchPolicy: "network-only",
  })

  const schema = Yup.object().shape({
    personFirstName: Yup.string().required("Required"),
    personLastname: Yup.string().required("Required"),
    personBorn: Yup.date().required("Required"),
    personDied: Yup.date().required("Required"),
    memoryRoomPublishDate: Yup.date().required("Required"),
  })

  interface FormData {
    personFirstName: string | any
    personLastname: string | any
    personBorn: Date | undefined | any
    personDied: Date | undefined | any
    welcomeText: string | any
    ceremoniesText: string | any
    ceremoniesTime: Date | undefined
    ceremoniesLastBookingTime: Date | undefined
    ceremoniesLocationText: string | undefined | any
    memoryRoomPublishDate: Date | undefined
    published: Date | any
    personHomeTown: string
    personBornLastname: string
    isLightCandle: boolean | undefined
  }

  const mCase =
    data?.orders && data.orders?.[0].mediaOrder?.edit.editModelMemoryRoom?.case
  const mContent =
    data?.orders &&
    data.orders?.[0].mediaOrder?.edit.editModelMemoryRoom?.contents
  const mFuneral =
    data?.orders &&
    data.orders?.[0].mediaOrder?.edit.editModelMemoryRoom?.funeral.ceremonies[0]
  const mediaOrder = data?.orders && data.orders[0].mediaOrder

  const fname = mCase?.personFirstname ? mCase?.personFirstname : ""
  const lname = mCase?.personLastname ? mCase?.personLastname : ""
  const pborn = mCase?.personTimeBorn && mCase.personTimeBorn.value
  const pdied = mCase?.personTimeDied && mCase?.personTimeDied.value
  const city = mCase?.personCityDied ? mCase?.personCityDied : ""
  const bornName = mCase?.personBornLastname ? mCase?.personBornLastname : ""
  const intro = mContent?.welcomeText ? mContent?.welcomeText : ""
  const location = mFuneral ? mFuneral?.locationText : ""
  const cerTime = mFuneral && mFuneral.time.value
  const cerdate = mFuneral && mFuneral.lastBookingTime.value
  const cerText = mFuneral ? mFuneral?.infoText : ""
  const publishTime = mediaOrder && mediaOrder.publishTimes[0]?.time
  const isLightningCandle =
    mediaOrder &&
    mediaOrder.edit.editModelMemoryRoom?.features.isLightningCandle

  const formik = useFormik<FormData>({
    initialValues: {
      ceremoniesLastBookingTime: cerdate ? moment(cerdate).toDate() : undefined,
      ceremoniesLocationText: location ? location : "",
      ceremoniesTime: cerTime ? moment(cerTime).toDate() : undefined,
      memoryRoomPublishDate: publishTime
        ? moment(publishTime).toDate()
        : undefined,
      personFirstName: fname,
      personLastname: lname,
      personBorn: pborn ? moment(pborn).toDate() : undefined,
      personDied: pdied ? moment(pdied).toDate() : undefined,
      welcomeText: intro,
      ceremoniesText: cerText,
      personHomeTown: city,
      personBornLastname: bornName,
      published: "",
      isLightCandle: isLightningCandle!,
    },
    validationSchema: schema,
    validateOnBlur: false,
    validateOnChange: false,
    enableReinitialize: true,
    onSubmit: (values) => {
      //   window.alert(`Submit with values ${JSON.stringify(values)}`)
    },
  })

  const mainAvatarResponse =
    data?.orders?.[0].mediaOrder?.edit.editModelMemoryRoom?.contents
      .avatarImage ?? null

  const mainImagesResponse =
    data?.orders?.[0].mediaOrder?.edit.editModelMemoryRoom?.contents.images ??
    null
  const [mainAvatar, setMainAvatar] = React.useState<NullableImage | null>(
    mainAvatarResponse?.value == null
      ? null
      : {
          value: {
            id: mainAvatarResponse!.value.id,
            src: mainAvatarResponse!.value.imageUrl,
            alt: mainAvatarResponse!.value.name,
          },
        }
  )

  const [cropImageType, setCropImageType] = React.useState<
    "avatar" | "images" | null
  >(null)
  const [isCropImageModalVisible, setIsCropImageModalVisible] = React.useState<
    boolean
  >(false)
  const [
    cropImageSelected,
    setCropImageSelected,
  ] = React.useState<ImageSelectDialogFile | null>(null)

  const [mutationImageLoading, setMutationImageLoading] = React.useState<
    boolean
  >(false)

  const [mutationImageError, setMutationImageError] = React.useState<
    string | undefined
  >(undefined)

  const mutationUpdateOrderContent = useMutation<
    UpdateOrderContent,
    UpdateOrderContentVariables
  >(UPDATE_ORDER_CONTENT, {
    client,
    context: {},
    refetchQueries: [
      {
        query: GET_ORDER_BY_ID_MEMORY_ROOM,
        variables: { id: props.orderId },
      },
    ],
  })

  const mutationUnpublishOrder = useMutation<
    UnPublishOrders,
    UnPublishOrdersVariables
  >(UNPUBLISH_ORDERS, {
    client,
    context: {},
    refetchQueries: [
      {
        query: GET_ORDER_BY_ID_MEMORY_ROOM,
        variables: { id: props.orderId },
      },
    ],
  })

  const mutationReActiveOrder = useMutation<
    ReActivateUnPublishOrders,
    ReActivateUnPublishOrdersVariables
  >(REACTIVE_ORDERS, {
    client,
    context: {},
    refetchQueries: [
      {
        query: GET_ORDER_BY_ID_MEMORY_ROOM,
        variables: { id: props.orderId },
      },
    ],
  })

  type MutationNullableImage = {
    value: null | MutationImage
  }

  type MutationImage = {
    id: string | null
    name: string
    inputContentBase64Encoded: string | null
  }

  const triggerMutationImage = (
    contents:
      | {
          avatarImage: MutationNullableImage | null
        }
      | {
          images: MutationImage[]
        }
  ) => {
    setMutationImageLoading(true)
    setMutationImageError(undefined)
    const editModelMemoryRoomId =
      data?.orders[0].mediaOrder?.edit.editModelMemoryRoom?.id ?? ""
    mutationUpdateOrderContent({
      variables: {
        input: {
          orderId: props.orderId,
          editModel: {
            editModelMemoryRoom: {
              id: editModelMemoryRoomId,
              contents,
            },
          },
        },
      },
    })
      .then((response) => {
        setMutationImageLoading(false)

        if (response.errors) {
          setMutationImageError(
            response.errors.map((e) => e.message).join(", ")
          )
          return
        }

        if (!response.data) {
          setMutationImageError(t("errorMessages:unknowError"))
          return
        }

        if (response.data.updateOrderContent.isError === true) {
          setMutationImageError(
            response.data.updateOrderContent.errorReason ??
              t("errorMessages:unknowError")
          )
          return
        }

        setMutationImageError(undefined)
      })
      .catch((err) => {
        setMutationImageLoading(false)
        setMutationImageError(err.toString())
      })
  }

  const defaultImages: Image[] =
    mainImagesResponse?.map((e) => {
      return {
        id: e.id,
        src: e.imageUrl,
        alt: e.name,
      }
    }) ?? []
  const [images, setImages] = React.useState(defaultImages)

  React.useEffect(() => {
    if (data?.orders?.[0].mediaOrder?.edit.editModelMemoryRoom?.contents) {
      const newMainAvatarResponse =
        data?.orders?.[0].mediaOrder?.edit.editModelMemoryRoom?.contents
          .avatarImage ?? null

      setMainAvatar(
        newMainAvatarResponse?.value == null
          ? {
              value: null,
            }
          : {
              value: {
                id: newMainAvatarResponse.value.id,
                src: newMainAvatarResponse.value.imageUrl,
                alt: newMainAvatarResponse.value.name,
              },
            }
      )

      const newMainImagesResponse =
        data?.orders?.[0].mediaOrder?.edit.editModelMemoryRoom?.contents
          .images ?? null

      const newImages: Image[] =
        newMainImagesResponse?.map((e) => {
          return {
            id: e.id,
            src: e.imageUrl,
            alt: e.name,
          }
        }) ?? []

      setImages(newImages)
    }
  }, [data])

  if (error) {
    return <ErrorMessage message={error.message} />
  }

  if (loading) {
    return <Loading />
  }

  if (!(data && data.orders && data.orders.length > 0)) {
    return <ErrorMessage message={"no data"} />
  }

  const ReactiveOrder = () => {
    setWaiting(true)
    mutationReActiveOrder({
      variables: {
        input: {
          orders: [
            {
              orderId: props.orderId,
            },
          ],
        },
      },
    })
      .then(() => setWaiting(false))
      .catch((error) => console.log("err", error))
  }

  const onUnpublishOrder = () => {
    setWaiting(true)
    mutationUnpublishOrder({
      variables: {
        input: {
          orders: [
            {
              orderId: props.orderId,
              messageToMedia: null,
              codeLate: null,
            },
          ],
        },
      },
    })
      .then(() => setWaiting(false))
      .catch((error) => console.log("err", error))
  }
  const autoStorage = (arg: any) => {
    setWaiting(true)
    updateMemoryRoomContent({
      variables: {
        input: {
          orderId: props.orderId,
          editModel: {
            editModelMemoryRoom: {
              id: props.orderId,
              ...arg,
            },
          },
        },
      },
    })
      .then(() => {
        setWaiting(false)
      })
      .catch((error) => console.log("err", error))
  }

  const updateContentTime = (e: any) => {
    setWaiting(true)
    formik.setFieldValue("memoryRoomPublishDate", e)
    mutationUpdatePublishTime({
      variables: {
        input: {
          orderId: props.orderId,
          publishTimes: [e],
        },
      },
    })
      .then(() => setWaiting(false))
      .catch((error) => console.log(error))
  }

  const onSubmit = () => {
    setWaiting(true)
    formik.handleSubmit()
    return new Promise((resolve) => {
      setTimeout(() => {
        if (data.orders[0].mediaOrder?.publishTimes[0]?.time)
          if (
            formik.values.personFirstName &&
            formik.values.personLastname &&
            formik.values.personBorn &&
            formik.values.personDied
          )
            mutationPublishOrders({
              context: {},
              variables: {
                input: {
                  orders: [{ orderId: props.orderId }],
                },
              },
              refetchQueries: [
                {
                  query: GET_ORDER_BY_ID_Memory_Room,
                  variables: { id: props.orderId },
                },
              ],
            })
              .then((result) => {
                refetch()
                setWaiting(false)
              })
              .catch((err) => (formik.errors.published = err))
          else {
            setIsSubmitError(true)
            setWaiting(false)
          }
        else setWaiting(false)
      }, 300)
    })
  }

  // const onChangeEmpty = () => {
  //   //
  // }

  // const onToggleActive = () => setIsToggleActive(!istoggleActive)

  const onRemoveImage = (id: string) => {
    let index = -1
    images.forEach((img, i1) => {
      if (img && img.id && img.id === id) {
        index = i1
      }
    })
    const newImages = produce(images, (draft) => {
      draft.splice(index, 1)
    })
    setImages(newImages)
    triggerMutationImage({
      images: newImages.map((e) => {
        return {
          id: e.id,
          name: e.alt,
          inputContentBase64Encoded: null,
        }
      }),
    })
  }

  const onDropImageFile = (file: ImageSelectDialogFile) => {
    setIsCropImageModalVisible(true)
    setCropImageSelected(file)
    setCropImageType("images")
  }

  const onDropAvatarFile = (file: ImageSelectDialogFile) => {
    setIsCropImageModalVisible(true)
    setCropImageSelected(file)
    setCropImageType("avatar")
  }

  const onCloseCropModal = () => {
    setIsCropImageModalVisible(false)
    setCropImageSelected(null)
    setCropImageType(null)
  }

  const onSuccessCropFromModal = (blob: any) => {
    if (cropImageType === "images") {
      const preview = URL.createObjectURL(blob)
      const reader = new FileReader()
      reader.onloadend = () => {
        const base64data = reader.result?.toString() ?? undefined
        if (!base64data) {
          setMutationImageError(t("errorMessages:unknowError"))
          return
        }
        const prevImages =
          data.orders[0].mediaOrder?.edit.editModelMemoryRoom?.contents.images.map(
            (e) => {
              return {
                id: e.id,
                name: e.name,
                inputContentBase64Encoded: null,
              }
            }
          ) ?? []
        triggerMutationImage({
          images: [
            ...prevImages,
            {
              id: null,
              name: "images",
              inputContentBase64Encoded: base64data,
            },
          ],
        })
      }
      reader.readAsDataURL(blob)
      setImages(
        produce(images, (draft) => {
          draft.push({
            id: (Math.random() * 10000).toString(),
            src: preview,
            alt: "",
          })
        })
      )
    } else if (cropImageType === "avatar") {
      const preview = URL.createObjectURL(blob)
      const reader = new FileReader()
      reader.onloadend = () => {
        const base64data = reader.result?.toString() ?? undefined
        if (!base64data) {
          setMutationImageError(t("errorMessages:unknowError"))
          return
        }
        triggerMutationImage({
          avatarImage: {
            value: {
              id: null,
              name: "avatarImage",
              inputContentBase64Encoded: base64data,
            },
          },
        })
      }
      reader.readAsDataURL(blob)
      setMainAvatar({
        value: {
          id: (Math.random() * 10000).toString(),
          src: preview,
          alt: "",
        },
      })
    }
    setIsCropImageModalVisible(false)
    setCropImageSelected(null)
  }

  const onClearAvatar = () => {
    triggerMutationImage({
      avatarImage: {
        value: null,
      },
    })
    setMainAvatar(null)
  }

  const handleShowDetails = () => setShowDetalis(!showDetails)
  const handleOpenCandlesModal = () => setIsCandlesModalOpen(true)
  const handleCloseCandlesModal = () => setIsCandlesModalOpen(false)

  const handleChangeFuneralInfo = (e: any) => {
    // const div = document.createElement("div")
    // div.innerHTML = e.target.value

    // const text = div.textContent || div.innerText || ""
    autoStorage({
      funeral: {
        ceremonies: [
          {
            id: props.orderId,
            infoText: e.target.value,
          },
        ],
      },
    })
    formik.setFieldValue("ceremoniesText", e.target.value)
  }

  const handleChangeWelcomeText = (e: any) => {
    autoStorage({
      contents: {
        welcomeText: e.target.value,
      },
    })
    formik.setFieldValue("welcomeText", e.target.value)
  }

  const handleEditorPaste = (evt: any) => {
    evt.preventDefault()
    const html = evt.clipboardData.getData("text")
    if (document.queryCommandSupported("insertText")) {
      document.execCommand("insertHTML", false, html)
    } else {
      document.execCommand("paste", false, html)
    }
  }

  const onMemoryRoomUrlChange = (evt: any) => {
    evt.preventDefault()
    autoStorage({
      entries: {
        memoryRoomUrl: evt.target.value,
      },
    })
  }

  const friendlyId = data.orders[0].customerFriendlyId
  const status = data.orders[0].mediaOrder?.status
  const price = data.orders[0].mediaOrder?.preview.price
  const voucherKey =
    data.orders[0].mediaOrder?.edit.editModelMemoryRoom?.entries?.voucherKey
  const memoryRoomUrl =
    data.orders[0].mediaOrder?.edit.editModelMemoryRoom?.entries?.memoryRoomUrl

  const userInterface = data.orders[0].mediaOrder?.edit.userInterface
  return (
    <div>
      {isCropImageModalVisible ? (
        <ImageSelectDialog
          isDialogOpen={isCropImageModalVisible}
          onRequestClose={onCloseCropModal}
          onSuccessCrop={onSuccessCropFromModal}
          file={cropImageSelected}
        />
      ) : null}

      <div className={styles.orderEditor}>
        <div className={styles.leftBlock}>
          <div
            className={cx(styles.leftBlockContent, {
              [styles.isLock]: data.orders[0].mediaOrder?.isLocked,
            })}
          >
            {userInterface?.isUnPublishButton ||
            userInterface?.isUnlockButton ||
            userInterface?.isReactivateButton ? (
              <Menu
                orderId={props.orderId}
                refetchQuery={refetch}
                isLock={data.orders[0].mediaOrder?.isLocked}
                isUnPublish={userInterface?.isUnPublishButton}
                unPublishText={userInterface?.unPublishButtonText!}
                onUnPublishOrder={onUnpublishOrder}
                isUnlockButton={userInterface?.isUnlockButton!}
                unlockButtonText={userInterface?.unlockButtonText!}
                isReactivateButton={userInterface?.isReactivateButton!}
                reactiveButtonText={userInterface?.reactivateButtonText!}
                orderEntityNameSingular={userInterface?.orderEntityNameSingular}
                orderEntityNameSingularDetermined={
                  userInterface?.orderEntityNameSingularDetermined
                }
                isCopyFuneralInfoToMemoryRoom={
                  userInterface.isCopyFuneralInfoToMemoryRoom
                }
                copyFuneralInfoToMemoryRoomText={
                  userInterface.copyFuneralInfoToMemoryRoomText
                }
                onReactiveOrder={ReactiveOrder}
              />
            ) : null}

            {userInterface?.editStatusText && (
              <LockerBox
                color={userInterface.editStatusColor}
                text={userInterface.editStatusText}
                className={
                  userInterface.editStatusIcon === "LOCKED"
                    ? "memoryroomLockerBox"
                    : "unlockBox"
                }
                loading={waiting}
                icon={userInterface.editStatusIcon}
              />
            )}

            <div className={styles.caseFormContainer}>
              <MemoryRoomForm
                isActive={true}
                columnCount={3}
                autoStorage={autoStorage as any}
                formik={formik}
                orderId={props.orderId}
              />
            </div>

            <div className={styles.memoryRoomFormContainer}>
              <div className={styles.firstRowMemoryRoom}>
                <div>
                  <strong className={styles.simpleLabel}>
                    {t("common:displayImage")}
                  </strong>
                  <MainAvatarBlock
                    mainAvatar={mainAvatar?.value}
                    loading={mutationImageLoading}
                    onDropAvatarFile={onDropAvatarFile}
                    onRemoveItem={onClearAvatar}
                  />
                </div>

                <div>
                  <strong className={styles.simpleLabel}>
                    {t("common:moreImage")}
                  </strong>
                  <div className={styles.imagesWrapper}>
                    {images.map((img) => {
                      if (!img) {
                        return null
                      }
                      return (
                        <ImageBlock
                          key={img.id}
                          id={img.id}
                          src={img.src}
                          alt={img.alt}
                          onRemoveItem={onRemoveImage}
                        />
                      )
                    })}

                    <div className={styles.imagesUploadWrapper}>
                      <UploadAvatarButton
                        onDrop={onDropImageFile}
                        loading={mutationImageLoading}
                      />
                    </div>
                  </div>
                </div>

                <div className={styles.textAreaWrapper}>
                  <span className={styles.label}>
                    {t("editOrder:introduction")}
                  </span>
                  <br />
                  <Editor
                    id={"introduction-textEditor"}
                    onChange={handleChangeWelcomeText}
                    value={formik.values.welcomeText}
                    className={styles.welcomeTextEditor}
                    draggable={false}
                    onPaste={handleEditorPaste}
                  >
                    <Toolbar
                      style={{
                        backgroundColor: "white",
                        border: "none",
                      }}
                      className="toolbar"
                    >
                      <BtnBold title="Fet" />
                      <BtnItalic />
                      <BtnUnderline title="Understryka" />
                      <BtnLink title="Länk" />
                      <BtnClearFormatting title="Radera Formatering" />
                    </Toolbar>
                  </Editor>
                  {/* <FieldWrapperFormik
                    value={formik.values.welcomeText}
                    onChange={(e: any) => {
                      formik.setFieldValue("welcomeText", e.target.value)
                      autoStorage({
                        contents: {
                          welcomeText: e.target.value,
                        },
                      })
                    }}
                    errors={
                      formik.touched.welcomeText && formik.errors.welcomeText
                    }
                    name="welcomeText"
                    label={t("editOrder:introduction")}
                    placeholder={""}
                    autoFocus={false}
                  >
                    <TextArea />
                  </FieldWrapperFormik> */}
                </div>
              </div>

              <div className={styles.begravningRow}>
                <div>
                  <strong className={styles.begravningTitle}>
                    {t("editOrder:funeral")}
                  </strong>
                </div>

                <div className={styles.begravningRowCols}>
                  <div className={styles.textAreaWrapper}>
                    <p className={styles.label}>{t("editOrder:funeralInfo")}</p>
                    <Editor
                      id={t("funeral-info-textEditor")}
                      onChange={handleChangeFuneralInfo}
                      value={formik.values.ceremoniesText}
                      className={styles.Editor}
                      draggable={false}
                      onPaste={handleEditorPaste}
                    >
                      <Toolbar
                        style={{
                          backgroundColor: "white",
                          border: "none",
                        }}
                        className="toolbar"
                      >
                        <BtnBold title="Fet" />
                        <BtnItalic />
                        <BtnUnderline title="Understryka" />
                        <BtnLink title="Länk" />
                        <BtnClearFormatting title="Radera Formatering" />
                      </Toolbar>
                    </Editor>
                  </div>

                  <div className={styles.begravningDateInputs}>
                    <div className={styles.inputWrapper}>
                      <FieldWrapperFormik
                        id={"funeral-date-time"}
                        value={formik.values.ceremoniesTime}
                        onChange={(e: any) => {
                          formik.setFieldValue("ceremoniesTime", e)
                          autoStorage({
                            funeral: {
                              ceremonies: [
                                {
                                  id: props.orderId,
                                  time: {
                                    value: e,
                                  },
                                },
                              ],
                            },
                          })
                        }}
                        errors={
                          formik.touched.ceremoniesTime &&
                          formik.errors.ceremoniesTime
                        }
                        name="ceremoniesTime"
                        label={t("editOrder:TimeForFuneral")}
                        placeholder={"..."}
                        autoFocus={false}
                      >
                        <FormikDateAndTimeField
                          showTimeSelect={true}
                          className={"memmoryRoomTimePicker"}
                        />
                      </FieldWrapperFormik>
                      {formik.values.ceremoniesTime && (
                        <div
                          className={styles.reset}
                          onClick={() => {
                            formik.setFieldValue("ceremoniesTime", "")
                            autoStorage({
                              funeral: {
                                ceremonies: [
                                  {
                                    id: props.orderId,
                                    time: {
                                      value: null,
                                    },
                                  },
                                ],
                              },
                            })
                          }}
                        >
                          <FontAwesomeIcon icon={faTimes} />
                        </div>
                      )}
                    </div>

                    <div className={styles.inputWrapper}>
                      <FieldWrapperFormik
                      id={"memorial-date"}
                        value={formik.values.ceremoniesLastBookingTime}
                        onChange={(e: any) => {
                          formik.setFieldValue("ceremoniesLastBookingTime", e)
                          autoStorage({
                            funeral: {
                              ceremonies: [
                                {
                                  id: props.orderId,
                                  lastBookingTime: {
                                    value: e,
                                  },
                                },
                              ],
                            },
                          })
                        }}
                        errors={
                          formik.touched.ceremoniesLastBookingTime &&
                          formik.errors.ceremoniesLastBookingTime
                        }
                        name="ceremoniesLastBookingTime"
                        label={t("editOrder:applyForMemorialService")}
                        placeholder={"..."}
                        autoFocus={false}
                      >
                        <FormikDateAndTimeField  showTimeSelect={false} />
                      </FieldWrapperFormik>

                      {formik.values.ceremoniesLastBookingTime && (
                        <div
                          className={styles.reset}
                          onClick={() => {
                            formik.setFieldValue(
                              "ceremoniesLastBookingTime",
                              ""
                            )
                            autoStorage({
                              funeral: {
                                ceremonies: [
                                  {
                                    id: props.orderId,
                                    lastBookingTime: {
                                      value: null,
                                    },
                                  },
                                ],
                              },
                            })
                          }}
                        >
                          <FontAwesomeIcon icon={faTimes} />
                        </div>
                      )}
                    </div>
                  </div>
                  <div className={styles.adressWrapper}>
                    <strong className={styles.simpleLabel}>
                      {t("common:address")}
                    </strong>
                    <TextInput
                      value={formik.values.ceremoniesLocationText}
                      onChange={(e: any) => {
                        formik.setFieldValue(
                          "ceremoniesLocationText",
                          e.target.value
                        )
                        autoStorage({
                          funeral: {
                            ceremonies: [
                              {
                                id: props.orderId,
                                locationText: e.target.value,
                              },
                            ],
                          },
                        })
                      }}
                      placeholder={"Kungsgatan 123, Stockholm"}
                    />

                    <MapContainer>
                      <div
                        className={cx("", {
                          [styles.hide]: !formik.values.ceremoniesLocationText,
                        })}
                      >
                        <div className={"map"}>
                          <iframe
                            src={`https://maps.google.com/maps?q=${
                              formik.values.ceremoniesLocationText
                                ? formik.values.ceremoniesLocationText
                                : "sweden"
                            }&t=&z=${
                              formik.values.ceremoniesLocationText ? "11" : "3"
                            }&ie=UTF8&iwloc=&output=embed`}
                            width="100%"
                            height="250"
                            frameBorder="0"
                            aria-hidden="false"
                          />
                        </div>
                      </div>
                    </MapContainer>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className={styles.saveBtnContainer}>
              <button onClick={onSave}>{t("common:save")}</button>
              <span>{msg}</span>
            </div> */}
          </div>

          <div className={styles.bottom_wrapper}>
            <div className={styles.publishContainer}>
              <div className={styles.show_more} onClick={handleShowDetails}>
                <FontAwesomeIcon
                  icon={showDetails ? faChevronUp : faChevronDown}
                />
                <span>{t("editOrder:showDetails")}</span>
              </div>

              <div className={styles.publishbtn}>
                <div className={styles.datePicker}>
                  <p>{t("common:datePublish")}</p>
                  <div>
                    <FieldWrapperFormik
                      id={"ceremony-Booking-time"}
                      value={formik.values.memoryRoomPublishDate}
                      onChange={updateContentTime}
                      errors={
                        formik.errors?.memoryRoomPublishDate
                          ? t("errorMessages:publishDateMissing")
                          : ""
                      }
                      name="ceremoniesLastBookingTime"
                      label={""}
                      placeholder={"..."}
                      autoFocus={false}
                    >
                      <FormikDateAndTimeField
                        disabled={
                          data.orders[0].mediaOrder?.isLocked ||
                          !userInterface?.isPublishButton
                        }
                        showTimeSelect={false}
                      />
                    </FieldWrapperFormik>
                  </div>
                </div>
                <div>
                  <div className={styles.btnWrapper}>
                    <button
                      type="submit"
                      className={styles.btn}
                      onClick={onSubmit}
                      disabled={!userInterface?.isPublishButton}
                    >
                      {!waiting ? (
                        userInterface?.publishButtonText != null ? (
                          userInterface?.publishButtonText === "Publicerad" ? (
                            <>
                              <FontAwesomeIcon icon="check-circle" />
                              <span style={{ marginLeft: "3px" }}>
                                {userInterface?.publishButtonText}
                              </span>
                            </>
                          ) : (
                            <span>{userInterface?.publishButtonText}</span>
                          )
                        ) : null
                      ) : (
                        <Spinner size={12} innerBallSize={2.5} color={"#fff"} />
                      )}
                    </button>
                  </div>
                  {isSubmitError ? (
                    <span style={{ color: "red", fontSize: "12px" }}>
                      Validerning Fel !
                    </span>
                  ) : null}
                </div>
              </div>
            </div>

            <div
              className={cx(styles.details, {
                [styles.isHide]: !showDetails,
              })}
            >
              <div className={styles.details_content}>
                <table>
                  <thead>
                    <tr>
                      <th>{t("common:order")}</th>
                      <th>{t("common:Pris")}</th>
                      <th>{t("common:statusLabel")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{friendlyId}</td>
                      <td>
                        {price?.endCustomer.pricePayRounded
                          ? `${price.endCustomer.pricePayRounded} ${price.currency}`
                          : t("editOrder:noPrice")}
                      </td>
                      <td>
                        <Status
                          variant={status!.color}
                          className={styles.status}
                        >
                          {t(`statuses:${status?.status}`)}
                        </Status>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div className={styles.rightBlock}>
                  <div className={styles.publishBlock}>
                    <ParallelPublishing
                      orderId={data.orders[0].id!}
                      caseFolderId={props.caseFolderId}
                      isLocked={data.orders[0].mediaOrder!.isLocked}
                      publishChannels={
                        data.orders[0].mediaOrder!.publishChannels
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={styles.sidebarBlock}>
          <TopSidebarCaseInfoBlock
            name={props.caseFolderName}
            caseId={props.caseFolderId}
          />
          <div className={styles.sidebarInfoBlock}>
            <div className={styles.sidebarInfoBlockHeader}>
              <b>{t("editOrder:KeyForAdministration")}</b>
            </div>
            <div className={styles.sidebarInfoBlockElement}>
              <div className={styles.sidebarInput}>
                <TextInput
                  defaultValue={voucherKey}
                  // onChange={onChangeEmpty}
                  placeholder={"Nyckel"}
                  disabled={true}
                />
                <div className={styles.keyIcon}>
                  <FontAwesomeIcon icon={faKey} />
                </div>
              </div>

              {isMemoryRoomUrlEnabled && (
                <>
                  <div className={styles.sidebarInfoBlockHeader}>
                    <b>{t("editOrder:ExternalMemoryPage")}</b>
                  </div>
                  <div className={styles.sidebarInput}>
                    <TextInput
                      defaultValue={memoryRoomUrl}
                      onChange={onMemoryRoomUrlChange}
                      placeholder={"Länk"}
                    />
                    <div className={styles.keyIcon}>
                      <FontAwesomeIcon icon={faLink} />
                    </div>
                  </div>
                </>
              )}
            </div>

            {/*  <Button
                  icon={"user"}
                  variant={"tertiary"}
                  className={styles.sidebarInfoBlockAdminBtn}
                >
                  Bjud in administratör
                </Button>  */}
            {/* <div className={styles.sidebarInfoBlockElementLevel2}>
              <div className={styles.sidebarInfoBlockLabel}>
                <strong>Privat läge</strong>
              </div> */}
            {/* <div className={styles.privateMode}>
                <div className={styles.toggle}>
                  <Toggler checked={istoggleActive} onChange={onToggleActive} />
                  <span>Lås minnesrum</span>
                </div>
                <div className={cx(styles.passwordInput)}>
                  {istoggleActive && (
                    <>
                      <TextInput
                        // value={""}
                        // onChange={onChangeEmpty}
                        placeholder={"Lösenord"}
                        disabled={false}
                      />
                      <div className={styles.keyIcon}>
                        <FontAwesomeIcon icon={faKey} />
                      </div>
                    </>
                  )}
                </div>
              </div> 
            </div>*/}

            {/* <Button className={styles.sidebarInfoBlockSubmit}>
              Förhandsgranska minnesrum
            </Button> */}
          </div>
          <div className={styles.candleBlock}>
            <div>
              <div className={styles.title}>
                <b>{t("editOrder:lightCandles")}</b>
              </div>
              <div className={styles.candleCheckbox}>
                <Toggler
                  name={"isLightningCandle"}
                  checked={formik.values.isLightCandle !== undefined && formik.values.isLightCandle}
                  onChange={(e: any) => {
                    formik.setFieldValue("isLightCandle", e.target.checked)
                    autoStorage({
                      features: {
                        [e.target.name]: e.target.checked!,
                      },
                    })
                  }}
                />
                <span>{t("editOrder:off/on")}</span>
              </div>
            </div>

            <div
              className={styles.candleModalBtn}
              onClick={handleOpenCandlesModal}
            >
              <FontAwesomeIcon icon={faPrint} />
              <span>{t("editOrder:print")}</span>
            </div>
          </div>
        </div>
      </div>
      {isCandlesModalOpen && (
        <CandlesModal
          isOpen={isCandlesModalOpen}
          onClose={handleCloseCandlesModal}
          orderId={data.orders[0].id!}
        />
      )}
    </div>
  )
}

const MainAvatarBlock: React.FC<{
  mainAvatar: Image | null | undefined
  loading: boolean
  onDropAvatarFile: any
  onRemoveItem?: any
}> = (props) => {
  const { t } = useTranslation(["common"])
  const [isPopupVisible, setIsPopupVisible] = React.useState(false)

  const onAbort = () => {
    setIsPopupVisible(false)
  }

  const onSuccess = () => {
    setIsPopupVisible(false)
    if (props.onRemoveItem) {
      props.onRemoveItem()
    }
  }

  const onClickShowPopup = () => {
    setIsPopupVisible(true)
  }
  return (
    <div>
      <div className={styles.mainAvatarWrapperWithClose}>
        {isPopupVisible === true ? (
          <RemoveItemPopup
            text={t("areYouSure")}
            onAbort={onAbort}
            onSuccess={onSuccess}
          />
        ) : null}

        {props.mainAvatar !== null ? (
          <div className={styles.removeIconAvatar} onClick={onClickShowPopup}>
            <FontAwesomeIcon icon={faMinusCircle} color={"#e6e6e6"} />
          </div>
        ) : null}

        <div className={styles.mainAvatarWrapper}>
          {props.loading === true ? (
            <div className={styles.loadingBlock}>
              <Spinner size={40} />
            </div>
          ) : null}

          <div className={styles.avatarUploadWrapper}>
            <UploadAvatarButton
              onDrop={props.onDropAvatarFile}
              // className={styles.avatarUploadButton}
              loading={false}
            />
          </div>

          <div className={styles.avatarGradient}>
            <span>Byt bild</span>
          </div>

          {props.mainAvatar != null ? (
            <img
              src={props.mainAvatar.src}
              alt={props.mainAvatar.alt}
              className={styles.selectedAvatar}
            />
          ) : (
            <div className={styles.defaultAvatar}>&nbsp;</div>
          )}
        </div>
      </div>
    </div>
  )
}

const ImageBlock: React.FC<{
  id: string
  src: string
  alt: string
  onRemoveItem?: (id: any) => void
}> = (props) => {
  const { t } = useTranslation(["common"])
  const [isMouseOver, setIsMouseOver] = React.useState(false)
  const [isSelected, setIsSelected] = React.useState(false)
  const [isPopupVisible, setIsPopupVisible] = React.useState(false)

  const onAbort = () => {
    setIsPopupVisible(false)
    setIsMouseOver(false)
    setIsSelected(false)
  }

  const onSuccess = () => {
    setIsPopupVisible(false)
    setIsMouseOver(false)
    setIsSelected(false)
    if (props.onRemoveItem) {
      props.onRemoveItem(props.id)
    }
  }

  const onClickShowPopup = () => {
    setIsPopupVisible(true)
  }

  const onMouseEnter = () => {
    if (isSelected === false) {
      setIsMouseOver(true)
    }
  }

  const onMouseLeave = () => {
    if (isSelected === false) {
      setIsMouseOver(false)
    }
  }

  const onClick = () => {
    setIsSelected(!isSelected)
  }

  return (
    <>
      <div className={styles.imageBlockContainer}>
        {isPopupVisible === true ? (
          <RemoveItemPopup
            text={t("areYouSure")}
            onAbort={onAbort}
            onSuccess={onSuccess}
          />
        ) : null}

        <div className={styles.removeIconImage} onClick={onClickShowPopup}>
          <FontAwesomeIcon icon={faMinusCircle} color={"#e6e6e6"} />
        </div>

        <div
          className={styles.imageBlockWrapper}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          onClick={onClick}
        >
          <div
            className={cx(styles.imageBlockSelected, {
              [styles.hideImageBlock]:
                (isMouseOver === true || isSelected === true) === false,
              [styles.showImageBlock]:
                isMouseOver === true || isSelected === true,
            })}
          >
            <div
              className={styles.imageBlockRemoveVisible}
              onClick={onClickShowPopup}
            >
              <FontAwesomeIcon
                icon={"times"}
                size={"sm"}
                className={styles.imageBlockWrapperRemoveIcon}
              />
            </div>
          </div>

          <img
            src={props.src}
            alt={props.alt}
            className={styles.imageBlockWrapperImageSrc}
          />
        </div>
      </div>
    </>
  )
}

const UploadAvatarButton: React.FC<{
  onDrop?: (file: ImageSelectDialogFile) => void
  loading: boolean
  // className?: any
}> = (props) => {
  const [error, setError] = React.useState("")
  const onDrop = React.useCallback((acceptedFiles) => {
    setError("")
    const file = acceptedFiles[0]
    const mimeTypes = ["image/jpeg", "image/png"]
    const incorrectMimetype = mimeTypes.indexOf(file.type) === -1

    if (incorrectMimetype) {
      setError("Incorrect file type")
      return
    }

    const houndedKb = 1 * 1024
    const maxSize = 20 * 1024 * 1024
    const incorectFileSize = file.size < houndedKb || file.size > maxSize

    if (incorectFileSize) {
      setError("Incorrect file size. Exceeded size MAX 4MB or MIN 1KB")
      return
    }

    if (props.onDrop) {
      const imageSelectDialogFile: ImageSelectDialogFile = {
        fileFormInput: file,
        name: file.name,
        type: file.type,
        lastModified: file.lastModified,
        lastModifiedDate: file.lastModifiedDate,
        size: file.size,
        path: file.path,
        preview: URL.createObjectURL(file),
      }
      props.onDrop(imageSelectDialogFile)
    }
  }, [])

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: false,
    noKeyboard: true,
    noDrag: true,
    accept: "image/*",
  })

  return (
    <UploadAvatarButtonStyles>
      <div className={"selectImageBtnCss"}>
        {props.loading === true ? (
          <div className={"loadingBlock"}>
            <Spinner size={40} />
          </div>
        ) : null}

        <div {...getRootProps({ className: "dropzone" })}>
          <input {...getInputProps()} />
          <div className={"selectImageBtnIconCss"}>
            <FontAwesomeIcon
              size={"lg"}
              className={"selectNewImageIcon"}
              icon={"plus-circle"}
            />
          </div>
        </div>

        {error && error.length > 0 ? (
          <div className={"selectedImageError"}>{error}</div>
        ) : null}
      </div>
    </UploadAvatarButtonStyles>
  )
}

const UploadAvatarButtonStyles = styled.div`
  width: 100%;
  height: 100%;

  .selectImageBtnCss {
    background: none;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border: 1px solid #d2d2d2;
    width: 100%;
    height: 100%;
    border-radius: 3px;
    position: relative;
    .dropzone {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      input {
        width: 100%;
        height: 100%;
      }
    }
  }
  .selectedImageError {
    color: ${(props) => props.theme["--error-red"]};
    position: absolute;
    top: 0;
    left: 0;
    background: #fff;
    padding: ${(props) => props.theme["--spacing"]};
    width: 200px;
    top: 50px;
  }
  .selectImageBtnIconCss {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
  }
  .selectNewImageIcon {
    color: ${(props) => props.theme["--secondary"]};
  }
  .loadingBlock {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
    background: rgba(255, 255, 255, 0.8);
  }
`

const RemoveItemPopup: React.FC<{
  text: string
  onAbort: () => void
  onSuccess: () => void
}> = (props) => {
  return (
    <div className={styles.removeItemPopupWrapper}>
      <div className={styles.removeItemPopupLabel}>
        {props.text && props.text.length > 0 ? props.text : "Är du säker?"}
      </div>

      <div className={styles.removeItemPopupIconWrapper}>
        <div
          className={styles.removeItemPopupLeftIcon}
          onClick={props.onSuccess}
        >
          <FontAwesomeIcon
            icon={"check"}
            className={styles.removeItemPopupLeftIconOk}
          />
        </div>
      </div>

      <div className={styles.removeItemPopupIconWrapper}>
        <div
          className={styles.removeItemPopupRightIcon}
          onClick={props.onAbort}
        >
          <FontAwesomeIcon
            icon={"times"}
            size={"lg"}
            className={styles.removeItemPopupLeftIconRemove}
          />
        </div>
      </div>
    </div>
  )
}

const ImageSelectDialog: React.FC<{
  isDialogOpen: boolean
  onRequestClose: () => void
  onSuccessCrop?: (blob: any) => void
  file: ImageSelectDialogFile | null
}> = (props) => {
  return (
    <Modal
      large={false}
      isLoading={false}
      onRequestClose={props.onRequestClose}
      isOpen={props.isDialogOpen}
    >
      {props.file !== null ? (
        <div className={styles.imageCropModal}>
          <ImageEditor
            imageUrl={props.file.preview}
            onClose={props.onRequestClose}
            onSave={(blob: any) => {
              if (props.onSuccessCrop) {
                props.onSuccessCrop(blob)
              }
            }}
          />
        </div>
      ) : null}
    </Modal>
  )
}

const MapContainer = styled.div`
  .map {
    margin-top: 10px;
    iframe {
      border-radius: 5px;
    }
  }
`

// const onSave = () => {
//   const formikErrors = validate(formik.values)
//   setErrors(formikErrors)
//   return new Promise((resolve) => {
//     setTimeout(() => {
//       resolve(
//         updateMemoryRoomContent({
//           variables: {
//             input: {
//               orderId: props.orderId,
//               editModel: {
//                 editModelMemoryRoom: {
//                   id: props.orderId,
//                   case: {
//                     personFirstname: !formik.values.personFirstName
//                       ? ""
//                       : formik.values.personFirstName,
//                     personLastname: !formik.values.personLastname
//                       ? ""
//                       : formik.values.personLastname,
//                     personTimeBorn: {
//                       value: formik.values.personBorn,
//                     },
//                     personTimeDied: {
//                       value: formik.values.personDied,
//                     },
//                   },
//                   contents: {
//                     welcomeText: formik.values.welcomeText,
//                   },
//                   funeral: {
//                     ceremonies: [
//                       {
//                         infoText: formik.values.ceremoniesText,
//                         locationText: formik.values.ceremoniesLocationText,
//                         lastBookingTime: {
//                           value: formik.values.ceremoniesLastBookingTime,
//                         },
//                         time: {
//                           value: formik.values.ceremoniesTime,
//                         },
//                       },
//                     ],
//                   },
//                 },
//               },
//             },
//           },
//         })
//           .then((result) => {
//             console.log(result)
//           })
//           .catch((err) => console.log(err))
//       )
//     }, 100)
//   })
// }
