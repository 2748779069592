import * as React from "react"
import * as styles from "./styles.scss"
import Grayframe from "components/Grayframe"
import { GetReviewCaseFolder_caseFolder_documents_orders } from "schema"
import ParallelPublishing from "./ParallelPublishing"
import Price from "./Price"
import MediaOrderInfo from "./MediaOrderInfo"
import Size from "./Size"
import PublishTimeSelector from "components/PublishTimeSelector"
import { withTranslation, WithTranslation } from "react-i18next"

class OrderInformation extends React.PureComponent<
  OrderInformationColumnProps & WithTranslation
> {
  render() {
    const { order, t } = this.props
    const isLocked = order.mediaOrder!.isLocked

    
    return (
      <Grayframe className={styles.grayframe}>
        <div className={styles.row}>
          <MediaOrderInfo
            mediaOrder={order.mediaOrder!}
            customerOrderId={order.customerFriendlyId}
          />
          <div>
            <b>{t("publishDate")}</b>
            <PublishTimeSelector
              selectedOrderId={order.id}
              publishTime={order.mediaOrder!.publishTimes[0] ?? undefined}
              disabled={isLocked}
              className={styles.publishTime}
            />
          </div>
          <Size size={order.mediaOrder!.preview.material.basePage.size} />
          <Price
            price={order.mediaOrder!.preview.price?.endCustomer.pricePayRounded}
            currency={order.mediaOrder!.preview.price?.currency ?? "SEK"}
          />
        </div>
        <ParallelPublishing
          orderId={order.id!}
          caseFolderId={order.caseFolder.id}
          isLocked={isLocked}
          publishChannels={order.mediaOrder!.publishChannels}
        />
      </Grayframe>
    )
  }
}

interface OrderInformationColumnProps {
  order: GetReviewCaseFolder_caseFolder_documents_orders
}

export default withTranslation()(OrderInformation)
