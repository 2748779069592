import * as React from "react"
import * as styles from "./styles.scss"
import Button from "components/Button"
import { WithTranslation, withTranslation } from "react-i18next"

class CommandBar extends React.PureComponent<
  CommandBarProps & WithTranslation
> {
  render() {
    const {
      okButtonText,
      cancelButtonText,
      onCancelClick,
      onOKClick,
      t,
    } = this.props

    return (
      <div className={styles.commandBar}>
        <Button onClick={onCancelClick} variant="secondary">
          {cancelButtonText ? cancelButtonText : t("cancel")}
        </Button>
        <Button
          onClick={onOKClick}
          variant="primary"
          className={styles.okButton}
        >
          {okButtonText ? okButtonText : "OK"}
        </Button>
      </div>
    )
  }
}

interface CommandBarProps {
  onOKClick: () => void
  onCancelClick: () => void
  okButtonText?: string
  cancelButtonText?: string
}

export default withTranslation()(CommandBar)
