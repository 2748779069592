import React from "react"
import styles from "./styles.scss"
import { useTranslation } from "react-i18next"
import { OrderEditorAreaContext } from "../.."
import UnitValueInput from "../UnitValueInput"
import OptionDropdown from "components/OptionDropdown"
import { DropdownOption } from "components/DropdownButton"
import AdminSectionTooltip from "../AdminSectionTooltip"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faItalic, faMinusCircle } from "@fortawesome/free-solid-svg-icons"
import Button from "components/Button"

const FontStyleDropdown: React.FC = () => {

  const [t] = useTranslation("editOrder")
  const { setFontStyle, contentRows, area, editor } = React.useContext(
    OrderEditorAreaContext
  )

  let defaultFontStyle: string | undefined = undefined

  if (area && area?.style && area.style.text && area.style.text.fontStyle) {
    defaultFontStyle = area.style.text.fontStyle
  }

  let fontStyleValue: string | null = null

  if (
    contentRows !== undefined &&
    contentRows[0] !== undefined &&
    contentRows[0].columns !== undefined &&
    contentRows[0].columns[0] !== undefined &&
    contentRows[0].columns[0].item !== undefined &&
    contentRows[0].columns[0].item.text !== undefined &&
    contentRows[0].columns[0].item.text !== null &&
    contentRows[0].columns[0].item.text.fontStyle !== undefined &&
    contentRows[0].columns[0].item.text.fontStyle !== null
  ) {
    fontStyleValue = contentRows[0].columns[0].item.text.fontStyle
  }

  let fontStyles: string[] = []
  const options: { label: string; value: string }[] = []
  if (
    editor &&
    editor.text !== undefined &&
    editor.text !== null &&
    editor.text.allowedFontStyles !== undefined &&
    editor.text.allowedFontStyles !== null
  ) {
    editor.text.allowedFontStyles.forEach((val) => {
      if (val !== undefined && val !== null) {
        options.push({
          label: val.toString(),
          value: val,
        })
        fontStyles.push(val)
      }
    })
  }

  const handleDropdownChange = (option: DropdownOption) => {
    setFontStyle(option.value)
  }

  const onClear = () => {
    setFontStyle(null)
  }

  const renderToggler = () => (
    <AdminSectionTooltip id="fontStyle" text={t("fontStyle")}>
            {fontStyleValue !== null ? (
        <div className={styles.removeIcon} onClick={onClear}>
          <FontAwesomeIcon icon={faMinusCircle} color={"#e6e6e6"} />
        </div>
      ) : null}
      <Button
        variant="primary"
        className={styles.fontStyleButton}
        color={fontStyleValue === null ? "mediumGray" : "secondary"}
      >
        <FontAwesomeIcon icon={faItalic} />
      </Button>
    </AdminSectionTooltip>
  )

  return (
    <div className={styles.wrapper}>
      <UnitValueInput
        jsx={
          <OptionDropdown
            options={options}
            onChange={handleDropdownChange}
            renderToggler={renderToggler}
            optionListClassName={styles.contentList}
            defaultValue={defaultFontStyle!}
            selectedValue={fontStyleValue}
            listWidth={"100px"}
          />
        }
        isInputDisable={true}
        inputType={"string"}
        onChange={() => console.log}
        value={fontStyleValue}
        placeholder={defaultFontStyle}
       className={styles.fontStyleInput}
      />
    </div>
  )
}

export default FontStyleDropdown
