import * as React from "react"
import * as styles from "./styles.scss"
import cx from "classnames"

class CheckboxColumn extends React.PureComponent<CheckboxColumnProps> {
  render() {
    const { title, className, children } = this.props

    return (
      <div className={cx(styles.checkboxColumn, className)}>
        <b className={styles.title}>{title}</b>
        {React.Children.map(children, child => (
          <div className={styles.checkboxWrapper}>{child}</div>
        ))}
      </div>
    )
  }
}

interface CheckboxColumnProps {
  title: string
  className?: string
}

export default CheckboxColumn
