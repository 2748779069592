import * as React from "react"
import * as styles from "./styles.scss"
import cx from "classnames"
import TextAlignSelector from "./TextAlignSelector"
import ColumnsButton from "./ColumnsButton"
import { AppContext } from "App"
import AdminSectionToggler from "../../AdminSectionToggler"
// import {
//   useVerseEditingContextState,
//   useVerseEditingContextDispatch,
// } from "./../../../../../VerseEditingModal/VerseModalContext"
// import Button from "components/Button"
// import { OrderEditorAreaContext } from "../.."

const Toolbar: React.FC<ToolbarProps> = (props) => {

  return (
    <div className={styles.toolbar}>
      <div className={styles.buttonsContainer}>

        {/* {props.isVerseGalleryEnabled === false ? <SearchVerseButton /> : null} */}

        {/* <ColumnsButton onClick={props.onEnterColumnEdit} /> */}
        {/* <div style={{fontWeight: 500}}>{"Innehåll"}</div> */}

        {/* <TextAlignSelector /> */}
      </div>

      <AppContext.Consumer>
        {({ currentUserSession }) => {
          if (
            currentUserSession &&
            currentUserSession.user &&
            currentUserSession.user.isSupportUser &&
            currentUserSession.user.isSupportUser === true
          ) {
            return (
              <AdminSectionToggler
                onClick={props.toggleAdminSectionIsExpanded}
                isExpanded={props.adminSectionIsExpanded}
              />
            )
          }
          return null
        }}
      </AppContext.Consumer>
    </div>
  )
}

// const SearchVerseButton: React.FC<{}> = () => {
//   const ref = React.useRef<HTMLDivElement | null>(null)
//   const isModalVisible = useVerseEditingContextState(
//     (state) => state.data.isModalVisible
//   )
//   const dispatch = useVerseEditingContextDispatch()
//   const onPressSearchVerse = () => {
//     if (ref.current) {
//       const rect = ref.current.getBoundingClientRect()
//       dispatch({ type: "toggleModalWithRect", payload: { rect } })
//     } else {
//       dispatch({ type: "toggleModal" })
//     }
//   }
//   return (
//     <div className={styles.buttonSearchWrapper} ref={(e) => (ref.current = e)}>
//       <Button
//         variant="primary"
//         onClick={onPressSearchVerse}
//         icon="search"
//         className={cx(styles.buttonSearch, {
//           [styles.buttonSearchActive]: isModalVisible === true,
//         })}
//       />
//     </div>
//   )
// }

interface ToolbarProps {
  // isVerseGalleryEnabled: boolean
  adminSectionIsExpanded: boolean
  // onEnterColumnEdit: () => void
  toggleAdminSectionIsExpanded: () => void
}

export default Toolbar
