import GET_CURRENT_USER_SESSION from "graphql/queries/getCurrentUserSession"
import GET_CURRENT_CUSTOMER from "graphql/queries/getCurrentCustomer"
import GET_PACKAGES from "graphql/queries/getPackages"
import GET_DASHBOARD_CASE_FOLDERS from "graphql/queries/getDashboardCaseFolders"
import ApolloClient from "apollo-client"

const useQueriesToRefetchOnTokenChange = (client: ApolloClient<any>) => {
  const buildQuery = (query: any) =>
    client.query({ query, fetchPolicy: "network-only" })

  return async () =>
    buildQuery(GET_CURRENT_USER_SESSION).then(() =>
      buildQuery(GET_CURRENT_CUSTOMER).then(() =>
        Promise.all([
          buildQuery(GET_PACKAGES),
          buildQuery(GET_DASHBOARD_CASE_FOLDERS),
        ])
      )
    )
}

export default useQueriesToRefetchOnTokenChange
