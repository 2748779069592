import * as React from "react"
import * as styles from "./styles.scss"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import cx from "classnames"
import { PublishOrderContext } from "../.."
import Tooltip from "components/Tooltip"
import { useTranslation } from "react-i18next"
import { error } from "console"

interface IParams {
  caseFolderId: string
  orderId: string
}

const Toggler: React.FC<{
  params: IParams
}> = (props) => {
  const { dispatch, publishOrder } = React.useContext(PublishOrderContext)
  const [t] = useTranslation("errorMessages")
  const {isBookingSent, validateErrorTexts} = publishOrder.order.mediaOrder?.publishConversation!
  
  if (!isBookingSent) validate()
  const isLocked = !isBookingSent && publishOrder.errors.length > 0

  React.useEffect(() => {
    validate()
    setTimeout(() => {
      if (!publishOrder.errors) {
        if (publishOrder.order.id === props.params.orderId) {
          return dispatch({
            type: "toggleSelect",
            payload: { id: props.params.orderId },
          })
        }
      }
    }, 500)
    return () => validate()
  }, [publishOrder.errors])

  function handleToggle() {
    if (isBookingSent || isLocked) return
    dispatch({ type: "toggleSelect", payload: { id: publishOrder.order.id } })
  }

  function appendErrorString(prevString: string, text: string) {
    return prevString === "" ? text : `${prevString}<br>${text}`
  }

  function validate() {
    const mediaOrder = publishOrder.order.mediaOrder!
    const pc = mediaOrder.publishConversation
    let newErrors = ""
    if (!mediaOrder.publishTimes[0]) newErrors = t("publishDateMissing")
    if (validateErrorTexts.length > 0) newErrors = validateErrorTexts.map((err) => err).join("<br>")
    if (mediaOrder.publishConversation.publishReadyStatus === "WRONG_STATE")
      newErrors = appendErrorString(newErrors, t("wrongState"))
    if (pc.isCodeLate && publishOrder.codeLate === "")
      newErrors = appendErrorString(newErrors, t("codeLateMissing"))
    else if (pc.isCodeLate && pc.codeLate !== publishOrder.codeLate)
      newErrors = appendErrorString(newErrors, t("codeLateMismatch"))
    if (
      pc.isMediaCustomerSubriberNumber &&
      publishOrder.mediaCustomerSubriberNumber.length === 0
    )
      newErrors = appendErrorString(newErrors, t("subscriberCode"))
    dispatch({
      type: "appendTooltip",
      payload: { id: publishOrder.orderId, errors: newErrors },
    })
  }

  return (
    <div
      onClick={handleToggle}
      className={cx(styles.toggler, {
        [styles.published]: isBookingSent,
        [styles.locked]: isLocked,
      })}
    >
      {isLocked ? (
        <Tooltip id="toggler" text={publishOrder.errors}>
          <FontAwesomeIcon
            icon="exclamation-triangle"
            className={cx(styles.icon, styles.lock)}
          />
        </Tooltip>
      ) : (
        <>
          {isBookingSent ? (
            <>
            &nbsp; &nbsp; &nbsp; &nbsp;
            </>
          ) : (
            <FontAwesomeIcon
              icon="check-circle"
              className={cx(styles.icon, styles.check, {
                [styles.selected]: publishOrder.isSelected,
              })}
            />
          )}
        </>
      )}

      <span>{publishOrder.order.mediaOrder!.media.friendlyName}</span>
    </div>
  )
}

export default Toggler

// React.useEffect(() => {
//   if (
//     !isCodeLate &&
//     !mediaSubscriber &&
//     publishOrder.order.mediaOrder?.publishTimes[0] != undefined &&
//     props.orderID === props.currentDevId
//   ) {
//     return dispatch({
//       type: "toggleSelect",
//       payload: { id: publishOrder.order.id },
//     })
//   }
// }, [props.currentDevId])

// console.log(publishOrder.orderId === "a3d52c9f-91a0-4121-8b99-e09b3da598c2" && publishOrder)
