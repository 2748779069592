import * as React from "react"
import * as styles from "./styles.scss"
import { OrderSubscription, OrderSubscriptionVariables } from "schema"
import { useApolloClient, useSubscription } from "react-apollo-hooks"
import ORDER_SUBSCRIPTION from "graphql/subscriptions/orderSubscription"
import Grayframe from "components/Grayframe"
import { useTranslation } from "react-i18next"

export const SubscriptionHandler: React.FC<{ orderId: string }> = (props) => {
  const [isVisible, setIsVisible] = React.useState<boolean>(false)
  const [visibleTimeout, setVisibleTimeout] = React.useState<ReturnType<
    typeof setTimeout
  > | null>(null)
  const { t } = useTranslation("editOrder")
  const client = useApolloClient()
  useSubscription<OrderSubscription, OrderSubscriptionVariables>(
    ORDER_SUBSCRIPTION,
    {
      client,
      fetchPolicy: "network-only",
      variables: {
        id: props.orderId,
      },
      onSubscriptionData: (option) => {
        setIsVisible(true)
        if (visibleTimeout !== null) {
          clearTimeout(visibleTimeout)
        }
        setVisibleTimeout(
          setTimeout(() => {
            setIsVisible(false)
            setVisibleTimeout(null)
          }, 10000)
        )
      },
    }
  )

  return (
    <React.Fragment>
      {isVisible ? (
        <Grayframe className={styles.infoSubscriptionContainer}>
          <div className={styles.infoSubscription}>
            {t("orderUpdateFromAnotherClient")}
          </div>
        </Grayframe>
      ) : null}
    </React.Fragment>
  )
}
