import React from "react"
import styled from "styled-components"
import Dropdown from "components/Dropdown"
import { produce } from "immer"
import LockOrderModal from "../../OrderPreview/Toolbar/Hamburger/LockOrderModal"
import { useTranslation } from "react-i18next"
import UnpublishModal from "../../../../../components/UnpublishModal"
import { AppContext } from "App"
import UserLogModal from "components/UserLogsModal"
import { useMutation } from "react-apollo-hooks"
import { CopyOrderFuneralInfoToMemoryRoom, CopyOrderFuneralInfoToMemoryRoomVariables } from "schema"
import COPY_ORDER_FUNERAL_INFO_MEMORY_ROOM from "graphql/mutations/CopyOrderFuneralInfoMemoryRoom"

const Menu: React.FC<{
  orderId: any
  refetchQuery: () => void
  isLock?: boolean
  isUnPublish?: boolean
  unPublishText?: string
  onUnPublishOrder: () => void
  isUnlockButton: boolean
  unlockButtonText: string
  isReactivateButton: boolean
  reactiveButtonText: string
  onReactiveOrder?: () => void
  orderEntityNameSingularDetermined?: string | null
  orderEntityNameSingular?: string | null
  isCopyFuneralInfoToMemoryRoom?: boolean | null
  copyFuneralInfoToMemoryRoomText?: string | null
}> = (props) => {
  const _isMounted = React.useRef(true)
  const { t } = useTranslation("editOrder")
  const isSupportUser = React.useContext(AppContext).currentUserSession.user
    ?.isSupportUser
  const [isOpenLogger, setIsLoggerOpen] = React.useState(false)

  const [state, setState] = React.useState({
    lockModalIsOpen: false,
    deleteModalIsOpen: false,
    isUnpublishOpen: false,
  })

//   const mutationCopyOrderFuneralInfoToMemoryRoom = useMutation<
//   CopyOrderFuneralInfoToMemoryRoom,
//   CopyOrderFuneralInfoToMemoryRoomVariables
// >(COPY_ORDER_FUNERAL_INFO_MEMORY_ROOM)

  React.useEffect(() => {
    return () => {
      _isMounted.current = false
    }
  }, [])

  const openLockModal = () => {
    if (_isMounted.current)
      setState(
        produce(state, (draft) => {
          draft.lockModalIsOpen = true
        })
      )
  }

  const closeLockModal = () => {
    if (_isMounted.current) {
      setState(
        produce(state, (draft) => {
          draft.lockModalIsOpen = false
        })
      )
      if (props.refetchQuery) {
        props.refetchQuery()
      }
    }
  }

  const openUnpublishModal = () => {
    setState(
      produce(state, (draft) => {
        draft.isUnpublishOpen = true
      })
    )
  }

  const closeUnpublishModal = () => {
    setState(
      produce(state, (draft) => {
        draft.isUnpublishOpen = false
      })
    )
  }

  const onOpenLoggerModal = () => {
    setIsLoggerOpen(true)
  }

  const onCloseLoggerModal = () => {
    setIsLoggerOpen(false)
  }

  // const handleCopyOrderFuneralInfoToMemoryRoom = () => {
  //   mutationCopyOrderFuneralInfoToMemoryRoom({
  //     variables: {
  //       id: props.orderId!,
  //     },
  //   })
  // }

  return (
    <MenuContainer>
      <div className="toolbar">
        <Dropdown
          dropdownClassName={"dropdown"}
          contentClassName={"dropdown-content"}
        >
          <ul>
          {/* {props.isCopyFuneralInfoToMemoryRoom && (
            <li onClick={handleCopyOrderFuneralInfoToMemoryRoom}>
              {props.copyFuneralInfoToMemoryRoomText}
            </li>
          )} */}

            <li>
              {props.isUnlockButton && (
                <div onClick={openLockModal}>{props.unlockButtonText}</div>
              )}
            </li>
            <li>
              {props.isUnPublish && (
                <div onClick={openUnpublishModal}>{props.unPublishText}</div>
              )}
            </li>
            <li>
              {props.isReactivateButton && (
                <div onClick={openUnpublishModal}>
                  {props.reactiveButtonText}
                </div>
              )}
            </li>
            <li>{isSupportUser && <div onClick={onOpenLoggerModal}> {t("show_logs")} </div>}</li>

          </ul>
        </Dropdown>
        <LockOrderModal
          isOpen={state.lockModalIsOpen}
          isLocked={true}
          title={`${props.unlockButtonText} ${props.orderEntityNameSingular?.toLocaleLowerCase()}`}
          body={`Du är på väg att låsa upp ${props.orderEntityNameSingularDetermined?.toLocaleLowerCase()}.Det avbokar inte ordern. Kontakta supporten för att avboka ordern.`}
          okButtonText={""}
          onRequestClose={closeLockModal}
          documentId={props.orderId}
          orderEntityNameSingular={props.orderEntityNameSingular}
        />
        <UnpublishModal
          isOpen={state.isUnpublishOpen}
          onClose={closeUnpublishModal}
          isUnPublishButton={props.isUnPublish}
          unpublishText={props.unPublishText!}
          onUnPublishOrder={props.onUnPublishOrder}
          isReactivateButton={props.isReactivateButton!}
          reactiveButtonText={props.reactiveButtonText!}
          onReactiveOrder={props.onReactiveOrder!}
         
        />
        {isOpenLogger && (<UserLogModal isOpen={isOpenLogger} onClose={onCloseLoggerModal} orderId={props.orderId} />)}
        
      </div>
    </MenuContainer>
  )
}

export default Menu

const MenuContainer = styled.div`
  .toolbar {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: flex-end;

    .dropdown {
      position: relative;
      z-index: 4;
      button {
        border-radius: 100%;
        width: 30px;
      }
    }
    .dropdown-content {
      position: absolute;
      width: max-content;
      padding: 2px 8px;
      top: 40px;
      right: 5px;
      box-shadow: 0 2px 5px 1px rgb(0 0 0 / 7%);
      background-color: white;
      div {
        width: 180px;
        color: #292f36b3;
        cursor: pointer;
        padding: 8px;
        font-size: 15px;
        font-family: "Roboto", sans-serif;
        font-weight: 400;
        &:hover {
          color: #1289a7;
        }
        
      }
    }
    ul {
      li {
        margin: 8px 0;
      }
    }
  }
`
