import * as React from "react"
import * as styles from "./styles.scss"
import { OrderEditoryAdvert } from "./OrderEditoryAdvert"
import {
  EditModelTypes,
  GetEditOrderPageCaseFolder_caseFolder_editTabs_orders,
} from "schema"
import Grayframe from "components/Grayframe"
import { OrderEditoryMemoryRoom } from "./OrderEditoryMemoryRoom"
import { OrderEditoryLiveStream } from "./OrderEditoryLiveStream"
import ProgramCard from "./ProgramCard"

const OrderEditorContainer: React.FC<{
  editTabs: any[]
  documentId: string
  orderId: string
  orders: GetEditOrderPageCaseFolder_caseFolder_editTabs_orders[]
  caseFolderId: string
  caseFolderName: string
}> = (props) => {
  const order =
    props.orders.filter((e) => e.id === props.orderId)[0] ?? undefined

  if (order === undefined) {
    return <></>
  }

  const { ADVERT, LIVE_STREAM, MEMORY_ROOM, PRINT } = EditModelTypes
  const editModelType = order.mediaOrder?.edit.editModelType


  if (editModelType === ADVERT) {
    return (
      <OrderEditoryAdvert
        orderId={props.orderId}
        orders={props.orders}
        editTabs={props.editTabs}
        documentId={props.documentId}
        caseFolderId={props.caseFolderId}
        caseFolderName={props.caseFolderName}
      />
    )
  } else if (editModelType === MEMORY_ROOM) {
    return (
      <OrderEditoryMemoryRoom
        orderId={props.orderId}
        orders={props.orders}
        caseFolderId={props.caseFolderId}
        caseFolderName={props.caseFolderName}
      />
    )
  } else if (editModelType === LIVE_STREAM) {
    return (
      <OrderEditoryLiveStream
        orderId={props.orderId}
        orders={props.orders}
        caseFolderId={props.caseFolderId}
        caseFolderName={props.caseFolderName}
      />
    )
  } else if (order.client?.route.editPageRoute.clientApp === "TAPS_PK_4") {
    return <ProgramCard route={order.client.route}/>
  } else {
    return (
      <div className={styles.orderEditor}>
        <div className={styles.leftBlock}>
          <Grayframe>
            Unknow type of product === {order.mediaOrder?.edit.editModelType}
          </Grayframe>
        </div>
        <div className={styles.sidebarBlock}>&nbsp;</div>
      </div>
    )
  }
}

export default OrderEditorContainer
