import * as React from "react"
import InputField from "../../AdvancedContainer/InputField"
import { useTranslation } from "react-i18next"

function DaysBackField(props: DaysBackFieldProps) {
  const [t] = useTranslation("supportView")
  const [value, setValue] = React.useState(props.initialValue.toString())

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setValue(e.target.value)

  const handleBlur = (e: React.ChangeEvent<HTMLInputElement>) => {
    const numberVal = Number(e.target.value)

    if (isNaN(numberVal) || e.target.value === "")
      return setValue(props.initialValue.toString())

    props.setDaysBack(numberVal)
    setValue(e.target.value)
  }

  return (
    <InputField
      label={t("searchDaysBack")}
      onBlur={handleBlur}
      placeholder={t("daysBack")}
      value={value}
      onChange={handleChange}
    />
  )
}

interface DaysBackFieldProps {
  initialValue: number
  setDaysBack: (value: number) => void
}

export default DaysBackField
