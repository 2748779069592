import * as React from "react"
import * as styles from "./styles.scss"
import Dropdown from "components/Dropdown"
import Button from "components/Button"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useTranslation } from "react-i18next"
import ProofModal from "components/ProofModal"
import { OrderEditorContext } from "./../../../OrderEditoryAdvert"
import LockOrderModal from "./LockOrderModal"
import cx from "classnames"
import LiveViewModal from "./LiveViewModal"
import { useContextSelector } from "use-context-selector"
import produce from "immer"
import { useHistory, useParams } from "react-router"
import {
  SEARCH_KEY,
  SEARCH_KEY_VALUE,
} from "pages/EditOrderPage/DeleteOrderModalBlock"
import UnpublishModal from "components/UnpublishModal"
import {
  CopyOrderFuneralInfoToMemoryRoom,
  CopyOrderFuneralInfoToMemoryRoomVariables,
  CustomerScope,
  GetOrderById_orders_mediaOrder_edit_userInterface,
  ReActivateUnPublishOrders,
  ReActivateUnPublishOrdersVariables,
  SetExhibitOrder,
  SetExhibitOrderVariables,
  UnPublishOrders,
  UnPublishOrdersVariables,
  UpdateCustomerDocumentContentInitiatorFromOrder,
  UpdateCustomerDocumentContentInitiatorFromOrderVariables,
} from "schema"
import UNPUBLISH_ORDERS from "graphql/mutations/unPublishOrders"
import { useApolloClient, useMutation } from "react-apollo-hooks"
import GET_ORDER_BY_ID from "graphql/queries/getOrderById"
import REACTIVE_ORDERS from "graphql/mutations/reactiveOrders"
import SET_EXHIBIT_ORDER from "graphql/mutations/setExhibitOrder"
import { IParams } from "customSchema.interface"
import UserLogModal from "components/UserLogsModal"
import { AppContext } from "App"
import COPY_ORDER_FUNERAL_INFO_MEMORY_ROOM from "graphql/mutations/CopyOrderFuneralInfoMemoryRoom"
import UPDATE_CUSTOMER_DOCUMENT_CONTENT_INITIATOR_FROM_ORDER from "graphql/mutations/updateCustomerDocumentContentInitiatorFromOrder"

const Hamburger: React.FC<{
  isVisible: boolean
  onClick: () => void
  userInterface: GetOrderById_orders_mediaOrder_edit_userInterface | undefined
}> = (props) => {
  const { t } = useTranslation(["editOrder"])
  const history = useHistory()
  const params = useParams<IParams>()
  const client = useApolloClient()
  const [OpenLogModal, setOpenLogModal] = React.useState(false)
  const {isSupportUser, isMediaUser } = React.useContext(AppContext).currentUserSession.user!;
  const { OFFICE } = CustomerScope

  const {
    selectedCaseFolderId,
    isLocked,
    publishTime,
    orderId,
    friendlyName,
    firstOrder,
  } = useContextSelector(OrderEditorContext, (s) => {
    return {
      selectedCaseFolderId: s.selectedCaseFolderId,
      isLocked: s.isLocked,
      orderId: s.selectedOrder?.id ?? "",
      publishTime: s.selectedOrder?.mediaOrder?.publishTimes?.[0] ?? null,
      friendlyName:
        s.selectedOrder?.mediaOrder?.media.friendlyName ?? "ett media",
      firstOrder: s.orders[0].id,
    }
  })

  const UpdateCustomerDocumentContentInitiatorFromOrderMutate = useMutation<
    UpdateCustomerDocumentContentInitiatorFromOrder,
    UpdateCustomerDocumentContentInitiatorFromOrderVariables
  >(UPDATE_CUSTOMER_DOCUMENT_CONTENT_INITIATOR_FROM_ORDER)

  const mutationCopyOrderFuneralInfoToMemoryRoom = useMutation<
    CopyOrderFuneralInfoToMemoryRoom,
    CopyOrderFuneralInfoToMemoryRoomVariables
  >(COPY_ORDER_FUNERAL_INFO_MEMORY_ROOM)

  const mutationUnpublishOrder = useMutation<
    UnPublishOrders,
    UnPublishOrdersVariables
  >(UNPUBLISH_ORDERS, {
    client,
    context: {},
    refetchQueries: [
      {
        query: GET_ORDER_BY_ID,
        variables: { id: orderId },
      },
    ],
  })

  const mutationSetExhibitOrder = useMutation<
    SetExhibitOrder,
    SetExhibitOrderVariables
  >(SET_EXHIBIT_ORDER, { client })

  const mutationReactiveOrder = useMutation<
    ReActivateUnPublishOrders,
    ReActivateUnPublishOrdersVariables
  >(REACTIVE_ORDERS, {
    client,
    context: {},
    refetchQueries: [
      {
        query: GET_ORDER_BY_ID,
        variables: { id: orderId },
      },
    ],
  })

  const [state, setState] = React.useState<HamburgerState>({
    deleteModalIsOpen: false,
    proofModalIsOpen: false,
    lockModalIsOpen: false,
    liveViewModalIsOpen: false,
    isUnpublishOpen: false,
  })

  const openDeleteModal = () => {
    const { search } = window.location
    const urlParams = new URLSearchParams(search)
    urlParams.set(SEARCH_KEY, SEARCH_KEY_VALUE)
    const newSearch = urlParams.toString()
    history.push({
      search: newSearch,
    })
  }

  const onUnpublishOrder = () => {
    mutationUnpublishOrder({
      variables: {
        input: {
          orders: [
            {
              orderId: orderId!,
              messageToMedia: null,
              codeLate: null,
            },
          ],
        },
      },
    })
      .then(() => console.log)
      .catch((error) => console.log("err", error))
  }

  const reactiveOrder = () => {
    mutationReactiveOrder({
      variables: {
        input: {
          orders: [
            {
              orderId: orderId!,
            },
          ],
        },
      },
    })
  }

  const handleCopyOrderFuneralInfoToMemoryRoom = () => {
    mutationCopyOrderFuneralInfoToMemoryRoom({
      variables: {
        id: orderId!,
      },
    })
  }

  const openProofModal = () =>
    setState(
      produce(state, (draft) => {
        draft.proofModalIsOpen = true
      })
    )
  const closeProofModal = () =>
    setState(
      produce(state, (draft) => {
        draft.proofModalIsOpen = false
      })
    )
  const openLiveViewModal = () =>
    setState(
      produce(state, (draft) => {
        draft.liveViewModalIsOpen = true
      })
    )
  const closeLiveViewModal = () =>
    setState(
      produce(state, (draft) => {
        draft.liveViewModalIsOpen = false
      })
    )

  const openLockModal = () => {
    if (!publishTime) return
    setState(
      produce(state, (draft) => {
        draft.lockModalIsOpen = true
      })
    )
  }

  const closeLockModal = () =>
    setState(
      produce(state, (draft) => {
        draft.lockModalIsOpen = false
      })
    )

  const openUnpublishModal = () => {
    setState(
      produce(state, (draft) => {
        draft.isUnpublishOpen = true
      })
    )
  }

  const closeUnpublishModal = () => {
    setState(
      produce(state, (draft) => {
        draft.isUnpublishOpen = false
      })
    )
  }

  const handleSetExhibitOrder = () => {
    mutationSetExhibitOrder({
      variables: {
        caseFolderId: params.caseFolderId,
        orderId: params.orderId,
      },
    })
      .then((res) => {
        if (res.data?.setExhibitOrder) {
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const handleOpenLogModal = () => setOpenLogModal(true)

  const handleCloseLogModal = () => setOpenLogModal(false)

  const updateraTemplateContent = () => {
    UpdateCustomerDocumentContentInitiatorFromOrderMutate({
      variables: {
        orderId: orderId,
        customerScopeForDocumentContent: OFFICE,
        replaceLowerCustomerScopeInitiors: false,
      },
    }).catch((err) =>
      console.log("UpdateCustomerDocumentContentInitiatorFromOrderMutate", err)
    )
  }

  const canDelete = true
  const canLockOrUnlock = true
  const canDownloadMaterial = true
  const canDownloadProof = true
  const canShowLivestream = false
  const canShowGridlines = false

  return (
    <React.Fragment>
      <Dropdown
        contentClassName={styles.content}
        dropdownClassName={styles.dropdown}
        renderToggler={() => (
          <Button variant="primary" color="primary" className={styles.toggler}>
            <FontAwesomeIcon icon="bars" />
          </Button>
        )}
      >
        <ul className={styles.list}>
          <li>
            <div onClick={props.onClick}>
              {" "}
              {`${props.isVisible ? t("hide") : t("show")} ${t(
                "toolbarLower"
              )}`}
            </div>
          </li>
          {canDownloadProof ? (
            <li onClick={openProofModal}>{t("proof")}</li>
          ) : null}

          {canShowGridlines ? <li>{t("gridlines")}</li> : null}

          {canShowLivestream ? (
            <li onClick={openLiveViewModal}>{t("liveView")}</li>
          ) : null}

          {canDownloadMaterial ? (
            <li onClick={openProofModal}>{t("downloadMaterialMenu")}</li>
          ) : null}

          {props.userInterface?.isCopyFuneralInfoToMemoryRoom && !isMediaUser  && (
            <li onClick={handleCopyOrderFuneralInfoToMemoryRoom}>
              {props.userInterface.copyFuneralInfoToMemoryRoomText}
            </li>
          )}

          {canLockOrUnlock ? (
            <>
              {props.userInterface?.isLockButton && (
                <li
                  className={cx({
                    [styles.disabled]: !publishTime,
                  })}
                  onClick={openLockModal}
                >
                  {props.userInterface?.lockButtonText}
                </li>
              )}

              {props.userInterface?.isUnlockButton && (
                <li
                  className={cx({
                    [styles.disabled]: !publishTime,
                  })}
                  onClick={openLockModal}
                >
                  {props.userInterface?.unlockButtonText}
                </li>
              )}
            </>
          ) : null}

          {canDelete ? (
            <li onClick={openDeleteModal}>{t("deleteItem")}</li>
          ) : null}

          {props.userInterface?.isUnPublishButton && (
            <li onClick={openUnpublishModal}>
              {props.userInterface?.unPublishButtonText}
            </li>
          )}
          {props.userInterface?.isReactivateButton && (
            <li onClick={openUnpublishModal}>
              {props.userInterface?.reactivateButtonText}
            </li>
          )}
          {props.userInterface?.isSetExhibitOrder && !isMediaUser && (
            <li onClick={handleSetExhibitOrder}>
              <div className={styles.exhibit}>
                <span>{props.userInterface.setExhibitOrderText}</span>
              </div>
            </li>
          )}
          {props.userInterface?.isUpdateCustomerDocumentContentInitiator && (
            <li onClick={updateraTemplateContent}>
              <div>
                <span>
                  {
                    props.userInterface
                      ?.updateCustomerDocumentContentInitiatorText
                  }
                </span>
              </div>
            </li>
          )}

          {isSupportUser && (
            <li onClick={handleOpenLogModal}>
              <div>
                <span>Visa loggar</span>
              </div>
            </li>
          )}
        </ul>
      </Dropdown>

      {OpenLogModal && (
        <UserLogModal
          isOpen={OpenLogModal}
          onClose={handleCloseLogModal}
          orderId={orderId}
        />
      )}

      <UnpublishModal
        isOpen={state.isUnpublishOpen}
        onClose={closeUnpublishModal}
        onUnPublishOrder={onUnpublishOrder}
        onReactiveOrder={reactiveOrder}
        isUnPublishButton={props.userInterface?.isUnPublishButton!}
        unpublishText={props.userInterface?.unPublishButtonText!}
        isReactivateButton={props.userInterface?.isReactivateButton!}
        reactiveButtonText={props.userInterface?.reactivateButtonText!}
      />
      {canShowLivestream ? (
        <LiveViewModal
          isOpen={state.liveViewModalIsOpen}
          onRequestClose={closeLiveViewModal}
        />
      ) : null}

      {canLockOrUnlock ? (
        <LockOrderModal
          isOpen={state.lockModalIsOpen}
          isLocked={isLocked}
          onRequestClose={closeLockModal}
          documentId={orderId}
        />
      ) : null}

      {canDownloadProof ? (
        <ProofModal
          isOpen={state.proofModalIsOpen}
          onRequestClose={closeProofModal}
        />
      ) : null}
    </React.Fragment>
  )
}

interface HamburgerState {
  deleteModalIsOpen: boolean
  proofModalIsOpen: boolean
  lockModalIsOpen: boolean
  liveViewModalIsOpen: boolean
  isUnpublishOpen: boolean
}

export default Hamburger
