import * as React from "react"
import * as styles from "./styles.scss"
import Button from "components/Button"
import { WithTranslation, withTranslation } from "react-i18next"

class SubmitButton extends React.PureComponent<
  SubmitButtonProps & WithTranslation
> {
  render() {
    return (
      <Button
        id={this.props.id}
        variant="primary"
        color="primary"
        type="submit"
        isWorking={this.props.isWorking}
        className={styles.submitButton}
      >
        {this.props.t("logIn")}
      </Button>
    )
  }
}

interface SubmitButtonProps {
  isWorking: boolean
  id?: string
}

export default withTranslation("login")(SubmitButton)
