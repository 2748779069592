import * as React from "react"
import * as styles from "./styles.scss"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import cx from "classnames"



function Favorite(props: FavoriteProps) {
  const handleClick = () => props.onSelect(props.name)

  const capitalize = (arg: string) => {
    const str = arg.toLowerCase()
      return str.charAt(0).toUpperCase() + str.slice(1)
  } 

  return (
    <div
      onClick={handleClick}
      className={cx(styles.favorite, { 
        [styles.selected]: props.isSelected,
        [styles.FEView__favorite]: props.isFEUser
      })}
    >
      <FontAwesomeIcon icon="check" className={styles.checkIcon} />
      <span>{capitalize(props.name)}</span>
    </div>
  )
}

interface FavoriteProps {
  name: string
  isSelected: boolean
  onSelect: (favorite: string) => void
  isFEUser: boolean
}

export default Favorite
