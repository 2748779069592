import React, { useState } from "react"
import * as styles from "./styles.scss"
import Modal from "react-modal"
import CloseIcon from "components/Modal/CloseIcon"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { ApolloError } from "apollo-client"
import { useTranslation } from "react-i18next"

const ErrorMessageInfo: React.FC<{
  message: string
  error?: any
}> = (props) => {
  const [t] = useTranslation(["errorMessages"])
  const [extraInfoIsShown, setExtraInfoIsShown] = useState<boolean>(false)
  const [allInfoIsShown, setAllInfoIsShown] = useState<boolean>(false)
  const showExtraInfo = () => setExtraInfoIsShown(true)
  const showAllInfo = () => setAllInfoIsShown(true)

  const detailObject = () => {
    return props.error.errorDetail ?? props.error.errorAll;                  
  }

  return (
    <div className={styles.errorWrapper}>
      <div className={styles.errorContainer}>
        <div className={styles.iconContainer}>
          <div onDoubleClick={showExtraInfo}>
            <FontAwesomeIcon
              icon="exclamation-circle"
              className={styles.icon}
            />
          </div>
          <span>OOPS!</span>
        </div>
        <div className={styles.message}>
          <span>{props.message}</span>
          {!extraInfoIsShown && <h4>{t("contactSupport")}</h4>}
          {extraInfoIsShown && detailObject() && 
            <div>
                <h4>Detail :</h4>
                {Object.keys(detailObject()).map(key=><p>{key} : {detailObject()[key]}</p>)}                              
                <div style={{paddingTop:"20px"}}>
                <a onClick={showAllInfo}>More information</a>
                </div>
            </div>
          }
          {(allInfoIsShown || detailObject()==null) && props.error?.errorAll && 
            <div>
                <h4>Error :</h4>
                {Object.keys(props.error.errorAll).map(key=><p>{key} : {JSON.stringify(props.error.errorAll[key])}</p>)}
            </div>
          }
        </div>
      </div>
    </div>
  )
}

export const GlobalErrorModal: React.FC<{
  message: string
  modalIsOpen: boolean
  closeModal: any
  afterOpenModal: any
  error?: any
}> = (props) => {
  return (
    <React.Fragment>
      <Modal
        isOpen={props.modalIsOpen}
        onAfterOpen={props.afterOpenModal}
        onRequestClose={props.closeModal}
        style={{
          overlay: {
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(255, 255, 255, 0.75)",
            zIndex: 9999,
          },
          content: {
            border: "0px solid transparent",
            background: "transparent",
            overflow: "auto",
            borderRadius: "0px",
            outline: "none",
            zIndex: 9999 + 1,
          },
        }}
        contentLabel="Error Modal"
      >
        <div className={styles.contentCenter}>
          <div className={styles.contentWrapper}>
            <div className={styles.closeBtn}>
              <CloseIcon onClick={props.closeModal} />
            </div>

            <ErrorMessageInfo message={props.message} error={props.error} />
          </div>
        </div>
      </Modal>
    </React.Fragment>
  )
}
