import * as React from "react"
import { GetOrderById_orders_mediaOrder_documentFormat_documentFormatCollection } from "schema"
import styled from "styled-components"
import FormatButton from "./FormatButton"

const FormatDropdownContainer: React.FC<FormatDropdownContainerProps> = (
  props
) => {
  return (
    <ChangeTemplateButton>
      <FormatButton
        orderId={props.selectedOrderId}
        formatCollection={props.formatCollection}
        currentFormatName={props.selectedDocumentFormat}
        className={"FormatButton"}
        selectedDocumentFormatId={props.selectedDocumentFormatId}
      />
    </ChangeTemplateButton>
  )
}

interface FormatDropdownContainerProps {
  selectedOrderId: string
  selectedDocumentFormat: string
  togglePreviewIsUpdatingOn: any
  togglePreviewIsUpdatingOff: any
  formatCollection: GetOrderById_orders_mediaOrder_documentFormat_documentFormatCollection
  selectedDocumentFormatId: string | undefined
}

const ChangeTemplateButton = styled.div`
  .FormatButton {
    background-color: white;
    width: 150px;
    height: 35px;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #ccc;
    border-radius: 3px;
    margin: 0;
    .button {
      cursor: pointer;
      width: 100%;
      height: 100%;
      padding: 5px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: #72767a;
      span {
        font-weight: 500;
        font-size: 14px;
      }
      svg {
        font-size: 18px;
        margin-right: 4px;
        color: #1289a7;

      }
    }
    &:hover {
      border: 1px solid #1778e4;
      svg,
      span {
        color: #1778e4;
      }
    }
  }
`

export default FormatDropdownContainer
