import React from "react"
import styled from "styled-components"
import { ReducerAction } from ".."
import Button from "components/Button"
import checkIcon from "images/select_all_icon.svg"
import cx from "classnames"
import { useTranslation } from "react-i18next"

const MultiSelection: React.FC<{
  onDispatch: React.Dispatch<ReducerAction>
  orders: any
}> = (props) => {
  const [t] = useTranslation('review')
  const handleClick = () => props.onDispatch({ type: "toggleSelectAll" })
  const [isHover, setIsHover] = React.useState(false)

  const handleHover = () => {
    setIsHover(true)
  }
  const handleClearHover = () => {
    setIsHover(false)
  }
  return (
    <>
      <DivContainer>
        <div className="container"           onMouseEnter={handleHover}
          onMouseLeave={handleClearHover}>
          <Button
            onClick={handleClick}
            variant={"tertiary"}
            className={cx('downloadButton', {
              ['hoverBlue']: isHover,
            })}
          >
            <img
              src={checkIcon}
              alt="Taps5"
              width="20px"
              height="20px"
              className={cx("", { ["filterBlue"]: isHover })}
            />
            <span>{t("selectAll")}</span>
          </Button>
        </div>
      </DivContainer>
    </>
  )
}

export default MultiSelection

const DivContainer = styled.div`
  .container {
    display: flex;
    button {
      display: flex;
      justify-content: space-between;
      span {
        margin-left: 7px;
      }
    }
  }

  .hoverBlue {
    color: #1778e4;
  }
  .filterBlue {
    filter: invert(53%) sepia(77%) saturate(4649%) hue-rotate(193deg)
      brightness(91%) contrast(96%);
  }
`
