import React from "react"
import styles from "./styles.scss"
import {
  EditModelAdvertAreaContentEditorAccessStatuses,
  GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas,
} from "schema"
import { useContextSelector } from "use-context-selector"
import { OrderEditorContext } from "../../.."
import { notEmpty } from "utils"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import cx from "classnames"
import { popupWrapperUserInfo } from "components/Navbar/styles.scss"

interface IOption {
  label: string
  value: any
  isVisible: boolean
  isExclusive: EditModelAdvertAreaContentEditorAccessStatuses
}

const AddArea: React.FC<{
  areas: (GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas | null)[]
  disabled?: boolean
  onOptionClick: (option: IOption) => void
  onSetExpandedAreaId: (id: string) => void
  setIsVisible: any
  isFEUser: boolean
}> = (props) => {
  const handleLineClick = (option: IOption) => {
    props.onOptionClick(option)
    props.onSetExpandedAreaId(option.value)
    props.setIsVisible(false)
  }

  const getDropdownOptions = () =>
    props.areas
      .filter(notEmpty)
      .filter((x) => !x.content.value.isEditorVisible)
      .map((area) => ({
        value: area.id!,
        label: area.header!,
        isVisible: area.content.editor?.access?.add!,
        isExclusive: area.content.editor?.access?.statusAdd!,
      }))

  const { setHoveredAreaId } = useContextSelector(OrderEditorContext, (s) => {
    return {
      setHoveredAreaId: s.setHoveredAreaId,
    }
  })

  return (
    <div
      className={cx(
        styles.areaContainer,{
          [styles.isAdstateView]: props.isFEUser
        }
      )}
    >
      <Lines
        options={getDropdownOptions()}
        handleLineClick={handleLineClick}
        onAreaHover={setHoveredAreaId}
      />
    </div>
  )
}

// Lines Component //
const Lines: React.FC<{
  options: IOption[]
  handleLineClick: (option: IOption) => void
  onAreaHover?: (id?: string) => void
}> = (props) => {
  return (
    <ul>
      {props.options &&
        props.options.map((option) => {
          return (
            <Line
              key={option.value + option.label}
              option={option}
              handleLineClick={props.handleLineClick}
              onAreaHover={props.onAreaHover}
            />
          )
        })}
    </ul>
  )
}

// Line Component //
const Line: React.FC<{
  option: IOption
  handleLineClick: (option: IOption) => void
  onAreaHover?: (id?: string) => void
}> = (props) => {
  const { EXCLUSIVE } = EditModelAdvertAreaContentEditorAccessStatuses
  const [isHover, setIsHover] = React.useState(false)

  const hoverOver = () => {
    setIsHover(true)
    if (props.onAreaHover) props.onAreaHover(props.option.value)
  }
  const hoverLeave = () => {
    setIsHover(false)
    if (props.onAreaHover) props.onAreaHover(undefined)
  }

  const handleClick = () => props.handleLineClick(props.option)
  return props.option.isVisible ? (
    <li
      className={cx(styles.hr, {
        [styles.hoverLine]: isHover,
        [styles.isExclusive]: props.option.isExclusive === EXCLUSIVE,
      })}
      onMouseOver={hoverOver}
      onMouseLeave={hoverLeave}
      onClick={handleClick}
    >
      <div
        className={cx(styles.plus, {
          [styles.hoverIcon]: isHover,
        })}
      >
        <FontAwesomeIcon icon={"plus-circle"} />
        <span>{props.option.label}</span>
      </div>
    </li>
  ) : null
}

export default AddArea

{
  /* <Tooltip text={''} id={"addArea"}>
<FontAwesomeIcon icon={"plus-circle"} /> <span>{props.option.label}</span> 
</Tooltip> */
}
