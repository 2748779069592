import * as React from "react"
import * as styles from "./styles.scss"
import Button from "components/Button"
import { OrderEditorAreaContext } from "../.."
import AdminSectionTooltip from "../AdminSectionTooltip"
import { useTranslation } from "react-i18next"
import largerTextIcon from "images/larger-text-icon.svg"
import smallerTextIcon from "images/smaller-text-icon.svg"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFont, faMinusCircle } from "@fortawesome/free-solid-svg-icons"
import { DropdownOption } from "components/DropdownButton"
import OptionDropdown from "components/OptionDropdown"
import UnitValueInput from "../UnitValueInput"

export const FontSizeButtons: React.FC<{}> = () => {
  const [t] = useTranslation("editOrder")
  const [inputValue, setInputValue] = React.useState<number | null>(null)
  const { setFontSize, contentRows, editor, area } = React.useContext(
    OrderEditorAreaContext
  )

  let defaultFontSize: number | undefined = undefined
  if (area && area?.style && area.style.text && area.style.text.fontSize) {
    defaultFontSize = Math.round(area.style.text.fontSize.value)
  }

  let fontSizeValue: number | null = null
  if (
    contentRows !== undefined &&
    contentRows[0] !== undefined &&
    contentRows[0].columns !== undefined &&
    contentRows[0].columns[0] !== undefined &&
    contentRows[0].columns[0].item !== undefined &&
    contentRows[0].columns[0].item.text !== undefined &&
    contentRows[0].columns[0].item.text !== null &&
    contentRows[0].columns[0].item.text.fontSize !== undefined &&
    contentRows[0].columns[0].item.text.fontSize !== null &&
    contentRows[0].columns[0].item.text.fontSize.value !== undefined &&
    contentRows[0].columns[0].item.text.fontSize.value !== null
  ) {
    fontSizeValue = contentRows[0].columns[0].item.text.fontSize.value
  }

  let fontSizes: number[] = []
  let options: { label: string; value: number }[] = []
  if (
    editor &&
    editor.text !== undefined &&
    editor.text !== null &&
    editor.text.allowedFontSizes !== undefined &&
    editor.text.allowedFontSizes !== null &&
    editor.text.allowedFontSizes.values !== undefined &&
    editor.text.allowedFontSizes.values !== null
  ) {
    editor.text.allowedFontSizes.values.forEach((num) => {
      if (num !== undefined && num !== null && Number.isNaN(num) === false) {
        options.push({
          label: num.toString(),
          value: num,
        })
        fontSizes.push(num)
      }
    })
  }

  const findClosestNr = (arr: Array<number>, num: any) => {
    if (arr === null) {
      return
    }
    return arr.reduce((prev, current) =>
      Math.abs(current - num) < Math.abs(prev - num) ? current : prev
    )
  }

  const absolutCurrentValue = findClosestNr(fontSizes, defaultFontSize)

  const handleDropdownChange = (option: DropdownOption) => {
    setInputValue(option.value)
    setFontSize(option.value)
  }

  const handleInputChange = (value: string | undefined) => {
    if (value === undefined) {
      setInputValue(null)
      setFontSize(null)
    } else {
      const newValue = isNaN(Number(value)) ? 0 : Number(value)
      if (newValue === 0) {
        return
      } else {
        setInputValue(newValue)
      }
    }
  }

  const updateValue = () => {
    if(inputValue === null) return;
    return setFontSize(inputValue)
  }

  
  const handleClick = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.code === "Enter" || e.code === "NumpadEnter") {
     return updateValue()
    }
  }

  const onClear = () => {
    setInputValue(null)
    setFontSize(null)
  }

  const renderToggler = () => (
    <AdminSectionTooltip id="smallerText" text={t("smallerText")}>
      {fontSizeValue !== null ? (
        <div className={styles.removeIcon} onClick={onClear}>
          <FontAwesomeIcon icon={faMinusCircle} color={"#e6e6e6"} />
        </div>
      ) : null}
      <Button
        variant="primary"
        className={styles.smallerFontButton}
        color={fontSizeValue === null ? "mediumGray" : "secondary"}
      >
        <FontAwesomeIcon icon={faFont} className={styles.icon} />
      </Button>
    </AdminSectionTooltip>
  )

  return (
    <div className={styles.fontSizeButtons}>
      <UnitValueInput
        jsx={
          <OptionDropdown
            renderToggler={renderToggler}
            options={options}
            onChange={handleDropdownChange}
            defaultValue={absolutCurrentValue}
            selectedValue={fontSizeValue}
          />
        }
        onChange={handleInputChange}
        placeholder={absolutCurrentValue}
        unit={"px"}
        value={
          inputValue === undefined || inputValue === null ? fontSizeValue : inputValue
        }
        active={inputValue === null}
        onBlur={updateValue}
        onKeyDown={handleClick}
      />
    </div>
  )
}

// export const FontSizeButtons: React.FC<{}> = () => {
//   const [t] = useTranslation("editOrder")

//   const { setFontSize, contentRows, editor, area } = React.useContext(
//     OrderEditorAreaContext
//   )

//   let defaultFontSize: number | undefined = undefined
//   if (area && area?.style && area.style.text && area.style.text.fontSize) {
//     defaultFontSize = Math.round(area.style.text.fontSize.value)
//   }

//   let fontSizeValue: number | null = null
//   if (
//     contentRows !== undefined &&
//     contentRows[0] !== undefined &&
//     contentRows[0].columns !== undefined &&
//     contentRows[0].columns[0] !== undefined &&
//     contentRows[0].columns[0].item !== undefined &&
//     contentRows[0].columns[0].item.text !== undefined &&
//     contentRows[0].columns[0].item.text !== null &&
//     contentRows[0].columns[0].item.text.fontSize !== undefined &&
//     contentRows[0].columns[0].item.text.fontSize !== null &&
//     contentRows[0].columns[0].item.text.fontSize.value !== undefined &&
//     contentRows[0].columns[0].item.text.fontSize.value !== null
//   ) {
//     fontSizeValue = contentRows[0].columns[0].item.text.fontSize.value
//   }

//   const allowedFontSizes: number[] = []
//   if (
//     editor &&
//     editor.text !== undefined &&
//     editor.text !== null &&
//     editor.text.allowedFontSizes !== undefined &&
//     editor.text.allowedFontSizes !== null &&
//     editor.text.allowedFontSizes.values !== undefined &&
//     editor.text.allowedFontSizes.values !== null
//   ) {
//     editor.text.allowedFontSizes.values.forEach((num) => {
//       if (num !== undefined && num !== null && Number.isNaN(num) === false) {
//         allowedFontSizes.push(num)
//       }
//     })
//   }
//   const currentFontSizeIndex = allowedFontSizes.findIndex(
//     (e) => e === fontSizeValue
//   )

//   const findClosestNr = (arr: Array<number>, num: any) => {
//     if (arr === null) {
//       return
//     }
//     return arr.reduce((prev, current) =>
//       Math.abs(current - num) < Math.abs(prev - num) ? current : prev
//     )
//   }

//   const increase = () => {
//     if (currentFontSizeIndex + 1 <= 0) {
//       let closetNr = findClosestNr(allowedFontSizes, defaultFontSize!)
//       let closetNrIndex = allowedFontSizes.indexOf(closetNr!)
//       setFontSize(allowedFontSizes[closetNrIndex + 1])
//     } else {
//       if (currentFontSizeIndex + 1 < allowedFontSizes.length) {
//         setFontSize(allowedFontSizes[currentFontSizeIndex + 1])
//       }
//     }
//   }

//   const decrease = () => {
//     if (currentFontSizeIndex === -1) {
//       setFontSize(allowedFontSizes[0])
//     }

//     if (currentFontSizeIndex > 0) {
//       setFontSize(allowedFontSizes[currentFontSizeIndex - 1])
//     }
//   }

//   const onClear = () => {
//     setFontSize(null)
//   }

//   return (
//     <div className={styles.fontSizeButtons}>
//       {fontSizeValue !== null ? (
//         <div className={styles.removeIcon} onClick={onClear}>
//           <FontAwesomeIcon icon={faMinusCircle} color={"#e6e6e6"} />
//         </div>
//       ) : null}

//       <AdminSectionTooltip id="biggerText" text={t("biggerText")}>
//         <Button
//           variant="primary"
//           className={styles.biggerFontButton}
//           onClick={increase}
//           disabled={currentFontSizeIndex === allowedFontSizes.length - 1}
//           color={fontSizeValue === null ? "mediumGray" : "secondary"}
//         >
//           <img src={largerTextIcon} className={styles.icon} alt="" />
//         </Button>
//       </AdminSectionTooltip>
//       {fontSizeValue && (
//         <div className={styles.valueViewer}>
//           <span>{fontSizeValue}</span>
//         </div>
//       )}

//       <AdminSectionTooltip id="smallerText" text={t("smallerText")}>
//         <Button
//           variant="primary"
//           className={styles.smallerFontButton}
//           onClick={decrease}
//           disabled={currentFontSizeIndex === 0}
//           color={fontSizeValue === null ? "mediumGray" : "secondary"}
//         >
//           <img src={smallerTextIcon} className={styles.icon} alt="" />
//         </Button>
//       </AdminSectionTooltip>
//     </div>
//   )
// }
