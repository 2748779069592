import * as React from "react"
import DaysBackField from "./DaysBackField"
import MaxResultsField from "./MaxResultsField"

function AdvancedOrderContent(props: AdvancedOrderContentProps) {
  return (
    <React.Fragment>
      <DaysBackField
        initialValue={props.initialDaysBack}
        setDaysBack={props.setDaysBack}
      />
      <MaxResultsField
        initialValue={props.initialMaxResults}
        setMaxResults={props.setMaxResults}
      />
    </React.Fragment>
  )
}

interface AdvancedOrderContentProps {
  initialDaysBack: number
  initialMaxResults: number
  setDaysBack: (value: number) => void
  setMaxResults: (value: number) => void
}

export default AdvancedOrderContent
