import * as React from "react"
import * as styles from "./styles.scss"
import Modal from "components/Modal"
import CommandBar from "./CommandBar"
import { ErrorText } from "components/ErrorMessage"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

class ConfirmationModal extends React.PureComponent<ConfirmationModalProps> {
  render() {
    const {
      onRequestClose,
      isOpen,
      title,
      text,
      error,
      isLoading,
      loadingText,
      isModalInModal,
      onCancelClick = this.props.onRequestClose,
      ...rest
    } = this.props

    return (
      <Modal
        onRequestClose={onRequestClose}
        isOpen={isOpen}
        isLoading={isLoading}
        className={styles.modal}
        loadingText={loadingText}
        isModalInModal={isModalInModal}
      >
        <div className={styles.content}>
          {title && <h2>{title}</h2>}
          <div className={styles.description}>
            <FontAwesomeIcon icon={"exclamation-circle"} />
             <p>{text && text}</p>
          </div>
          {error && <ErrorText>{error}</ErrorText>}
        </div>
        <CommandBar onCancelClick={onCancelClick} {...rest} />
      </Modal>
    )
  }
}

interface ConfirmationModalProps {
  onRequestClose: () => void
  onOKClick: () => void
  onCancelClick?: () => void
  okButtonText?: string
  cancelButtonText?: string
  title?: string
  text?: string
  error?: string
  isOpen: boolean
  isLoading?: boolean
  loadingText?: string
  isModalInModal?: boolean
}

export default ConfirmationModal
