import * as React from "react"
import * as styles from "./styles.scss"
import { WithTranslation, withTranslation } from "react-i18next"
import { DateTime } from "luxon"
import moment from "moment"
import 'moment/locale/sv';
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

class SelectedDate extends React.Component<
  SelectedDateProps & WithTranslation
> {
  componentDidUpdate() {
    this.props.selectedWeek
  }
  render() {
    const month = this.props.t(`${moment(this.props.selectDay).locale("sv").format("MMM")}`)
    return (
      <>
        <div className={styles.selectedDateContainer}>
          {/* <FontAwesomeIcon className={styles.calendarIcon} icon="calendar-alt" /> */}
          {this.props.isWeekSelected ? (
            <b>
              {this.props.t("publishings")} {this.props.t("week")}{" "}
              { this.props.selectedWeek}
            </b>
          ) : (
            <b>
              {this.props.t("selectedDate")}{" "}
              {moment(this.props.selectDay).locale("sv").format("DD")}{" "}
              {month.charAt(0).toLocaleUpperCase() + month.slice(1)}
            </b>
          )}
        </div>
      </>
    )
  }
}

interface SelectedDateProps {
  selectedWeek?: number | any
  selectDay?: Date
  isWeekSelected: boolean
}

export default withTranslation(["dashboard","dateFormats"])(SelectedDate)
