import { GetGalleryImages_galleryImagesByTags_images } from "schema"

export interface Custom_GetGalleryImages_galleryImagesByTags_images
  extends GetGalleryImages_galleryImagesByTags_images {
  isSelected?: boolean
}

export interface SliderType {
  value: any
  scale: number | null
}

export interface SelectedProduct {
  value: string
  label: string
  isSelected?: boolean
}


export interface SelectedCustomer {
  value: string
  label: string
}

export enum HoverPeriod {
  YEAR_DAY = 'YEAR_DAY',
  LAST_MONTH = 'LAST_MONTH',
  LAST_YEAR = 'LAST_YEAR',
  LAST_THREE_MONTH = "LAST_THREE_MONTH",
  CUSTOM_PERIOD = "CUSTOM_PERIOD"
}

export interface IParams {
  caseFolderId: string
  orderId: string
}

export interface IPaginationsOptions {
  startIndex: number
  PAGE_LENGTH: number
  totalImageCount: number
  actuallyPageLength: number
}

export interface ImageSelectDialogFile {
  fileFormInput: any
  name: any
  type: any
  lastModified: any
  lastModifiedDate: any
  size: any
  path: any
  preview: any
}


export enum MeasurementInputTYPE {
  MAX_HEIGHT = "maxHeight",
  MIN_HEIGHT = "minHeight",
  FIXED_HEIGHT = "fixedHeight",
  MAX_WIDTH = "maxWidth",
  MIN_WIDTH = "minWidth",
  FIXED_WIDTH = "fixedWidth"
}


export interface IResultOrders {
  success: any
  resultIcon: any
  resultText: any
}
