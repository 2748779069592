import * as React from "react"
import * as styles from "./styles.scss"
import { GetAdminCustomers_customerTreesByFreeSearch_companies } from "schema"
import Office from "./Office"

function Company(props: CompanyProps) {
  return (
    <div className={styles.company}>
      <b className={styles.companyName}>{props.company.name}</b>
      <div className={styles.offices}>
        {props.company.offices.map(office => (
          <Office office={office} key={office.idString!} />
        ))}
      </div>
    </div>
  )
}

interface CompanyProps {
  company: GetAdminCustomers_customerTreesByFreeSearch_companies
}

export default Company
