import gql from "graphql-tag"

const GET_STAT_ORDERS = gql`
query GetStatOrders($input: StatsOrderInput!) {
  statsOrders(input: $input) {
    count
    summery {
      countOrders
      countCaseFolders
      avarageOrdersPerCaseFolder
      price(withVAT: false, priceView: DEFAULT) {
        endCustomer {
          pricePay
        }
        customer {
          priceEarnings
        }
      }
    }
    caseFolders {
      name
      orders {
        customerFriendlyId
        orderDate
        caseFolder {
          name
        }
        customer {
          name
          office {
            name
          }
        }
        mediaOrder {
          publishTimes {
            time
            __typename
          }
          media {
            name
            isPrintMedia
            __typename
          }
          documentFormat {
            mediaAdaptedName: friendlyName(isShownWithMedia: true)
          }
        }
        product {
          name
        }
        lockedPrice(withVAT: false, priceView: USER) {
          endCustomer {
            pricePay
          }
          customer {
            priceEarnings
          }
        }
      }
    }
  }
}

`

export default GET_STAT_ORDERS
