import * as React from "react"
import * as styles from "./styles.scss"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

function RemoveIcon(props: RemoveIconProps) {
  return (
    <div onClick={props.onClick}>
      <FontAwesomeIcon icon="times-circle" className={styles.removeIcon} />
    </div>
  )
}

interface RemoveIconProps {
  onClick: () => void
}

export default RemoveIcon
