import gql from "graphql-tag"

const GET_ADMIN_CUSTOMERS = gql`
  query GetAdminCustomers($name: String) {
    customerTreesByFreeSearch(name: $name) {
      companies {
        idString: id
        name
        offices {
          idString: id
          name
          customers {
            idString: id
            internalId
            name
            tempSessionToken
            primaryUser {
              username
            }
          }
        }
      }
    }
  }
`

export default GET_ADMIN_CUSTOMERS
