import React from "react"
import styles from "./styles.scss"
import Zendesk, { ZendeskAPI } from "react-zendesk"
import { GetCurrentUserSession_currentUserSession } from "schema"

const ZENDESK_KEY = process.env.REACT_APP_ZENDESK_KEY!;


const ZendeskChat: React.FC<{
  currentUserSession: GetCurrentUserSession_currentUserSession
}> = ({ currentUserSession }) => {

  const setting = {
    color: {
      theme: "#fe6b0b",
    },
    position: {
      horizontal: "left",
      vertical: "bottom",
    },
    launcher: {
      chatLabel: {
        "en-US": "chat",
        "sv-SE": "chatta",
        "no-nb": "chat",
      },
    },
  }

  const onLoaded = () => {
    ZendeskAPI(
      "webWidget",
      "setLocale",
      currentUserSession.user?.cultureCode === "sv-SE"
        ? "sv"
        : currentUserSession.user?.cultureCode
    )
    ZendeskAPI("webWidget", "updatePath")
    if (currentUserSession.customer) {
      ZendeskAPI("webWidget", "identify", {
        name: currentUserSession.customer.name,
        email:
          currentUserSession.customer.settings.emailCustomer ??
          currentUserSession.user?.username,
        phone: currentUserSession.customer.settings.contactPhone ?? null,
        note: "kontor: " + currentUserSession.customer.office.name,
      })
    }
  }
  return (
    <div className={styles.container}>
      <Zendesk
        defer
        zendeskKey={ZENDESK_KEY}
        {...setting}
        onLoaded={onLoaded}
      />
    </div>
  )
}

export default ZendeskChat
