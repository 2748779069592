import * as React from "react"
import { Query } from "react-apollo"
import {
  GetCaseFolders,
  GetCaseFolders_findCaseFoldersByCurrentCustomer_caseFolders,
} from "schema"
import Spinner from "components/Spinner"
import GET_DASHBOARD_CASE_FOLDERS from "graphql/queries/getDashboardCaseFolders"
import ErrorMessage from "components/ErrorMessage"
import { notEmpty } from "utils"

interface IState {
  caseFolderId: string
}

class CaseFolderProvider extends React.Component<
  CaseFolderProviderProps,
  IState
> {
  constructor(props: CaseFolderProviderProps) {
    super(props)
    this.state = {
      caseFolderId: "",
    }
  }

  componentDidMount() {
    if (this.props.caseFoldetId)
      this.setState({ caseFolderId: this.props.caseFoldetId })
  }

  render() {
    return (
      <Query<GetCaseFolders>
        query={GET_DASHBOARD_CASE_FOLDERS}
        variables={{
          touchCaseFolderIds: this.state.caseFolderId
            ? [this.state.caseFolderId]
            : [],
        }}
        fetchPolicy="network-only"
      >
        {({ loading, error, data, refetch }) => {
          if (error) return <ErrorMessage message={error.message} />

          if (loading) return <Spinner fullscreen={true} />

          const caseFolders = data!.findCaseFoldersByCurrentCustomer.caseFolders!.filter(
            notEmpty
          )

          return this.props.children({ caseFolders, refetch})
        }}
      </Query>
    )
  }
}

export interface CaseFolderProviderProps {
  children: (props: FuncAsAChildProps) => JSX.Element
  caseFoldetId?: any
}

interface FuncAsAChildProps {
  caseFolders: GetCaseFolders_findCaseFoldersByCurrentCustomer_caseFolders[]
  refetch:any
}

export default CaseFolderProvider
