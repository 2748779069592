import * as React from "react"
import * as styles from "./styles.scss"
import { useTranslation, withTranslation, WithTranslation } from "react-i18next"
import { GetReviewCaseFolder_caseFolder_documents_orders_mediaOrder_preview_material_basePage_size } from "schema"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import cx from "classnames"
import Marquee from "react-fast-marquee"

const Info: React.FC<InfoProps> = (props) => {
  const { t } = useTranslation(["review", "common"])
  const [expand, setExpand] = React.useState<boolean>(true)
  const { name, price, size } = props

  const handleExpandPrice = () => {
    setExpand(!expand)
  }

  return (
    <div className={styles.orderInfo}>
      <div className={cx(styles.mediaName, { [styles.setBorder]: expand })}>
        {name.length > 20 ? (
          <Marquee
            gradient={false}
            style={{
              width: "200px",
            }}
          >
            <span style={{ color: "black" }}>
              {name && name + new Array(1 + 1).join("   \u25CF   ")}
            </span>
          </Marquee>
        ) : (
          <span>{name}</span>
        )}

        <span
          onClick={handleExpandPrice}
          className={cx("", { [styles.selected]: expand })}
        >
          <FontAwesomeIcon icon={"info-circle"} />
        </span>
      </div>
      <div className={cx(styles.sizePriceRow, { [styles.selected]: expand })}>
        <div className={styles.size}>
          <span>{t("common:size")}</span>
          <span>
            {size
              ? `${size.width.toFixed(1)}mm x ${size.height.toFixed(1)}mm`
              : "N/A"}
          </span>
        </div>
        <div className={styles.price}>
          <span>{t("price")}</span>
          <span>{price ? `${Math.round(price)}:-` : "N/A"}</span>
        </div>
      </div>
    </div>
  )
}

interface InfoProps {
  name: string
  price: number | null
  size: GetReviewCaseFolder_caseFolder_documents_orders_mediaOrder_preview_material_basePage_size | null
}

export default Info
