import * as React from "react"
import * as styles from "./styles.scss"
import UnitValueInput from "../UnitValueInput"
import svg from "images/text-scale-icon.svg"
import { useTranslation } from "react-i18next"
import { OrderEditorAreaContext } from "../.."
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faMinusCircle } from "@fortawesome/free-solid-svg-icons"

function ScaleInput() {
  const [t] = useTranslation("editOrder")
  const { setTextScale, contentRows } = React.useContext(OrderEditorAreaContext)

  const [inputValue, setInputValue] = React.useState<number | null>(null)

  // const getTextScaleValue = (value: any) => {
  //   const numberVal = Number(value)
  //   if (
  //     value === null ||
  //     value === undefined ||
  //     value === "" ||
  //     isNaN(numberVal)
  //   ) {
  //     return 100
  //   }
  //   if (numberVal <= 0) {
  //     return 1
  //   }
  //   if (numberVal > 100) {
  //     return 100
  //   }
  //   return numberVal
  // }

  React.useEffect(() => {
    let currentTextScaleWidth: number | null = null
    if (
      contentRows !== undefined &&
      contentRows[0] !== undefined &&
      contentRows[0].columns !== undefined &&
      contentRows[0].columns[0] !== undefined &&
      contentRows[0].columns[0].item !== undefined &&
      contentRows[0].columns[0].item.text !== undefined &&
      contentRows[0].columns[0].item.text !== null &&
      contentRows[0].columns[0].item.text.textScaleWidth !== undefined &&
      contentRows[0].columns[0].item.text.textScaleWidth !== null &&
      contentRows[0].columns[0].item.text.textScaleWidth.value !== undefined &&
      contentRows[0].columns[0].item.text.textScaleWidth.value !== null
    ) {
      currentTextScaleWidth =
        contentRows[0].columns[0].item.text.textScaleWidth.value
      setInputValue(currentTextScaleWidth)
    }
  }, [contentRows])


  const handleChange = (value: string | undefined) => {
    try {
      const val: number = Number(value)
      if (isNaN(val) && val !== undefined && val !== null) {
        return setInputValue(null)
      } else {
        return setInputValue(val)
      }
    } catch (e) {
      setInputValue(null)
    }
  }

  const updateValue = () => {
    if (inputValue === null) return;
    setTextScale(inputValue)
  }

  const handleClick = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.code === "Enter" || e.code === "NumpadEnter") {
      updateValue()
    }
  }

  const onClear = () => {
    setInputValue(null)
    setTextScale(null)
  }

  return (
    <div className={styles.wrapper}>
      {inputValue !== null ? (
        <div className={styles.removeIcon} onClick={onClear}>
          <FontAwesomeIcon icon={faMinusCircle} color={"#e6e6e6"} />
        </div>
      ) : null}

      <UnitValueInput
        className={styles.scaleInput}
        tooltipText={t("textScale")}
        svg={svg}
        value={
          inputValue === undefined || inputValue === null ? "" : inputValue
        }
        onChange={handleChange}
        onBlur={updateValue}
        onKeyDown={handleClick}
        unit="%"
        active={inputValue === null}
      />
    </div>
  )
}

export default ScaleInput
