
import React from 'react'
import styles from "../styles.scss"
import { AppContext } from 'App';
import { GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_help } from 'schema';

const HelpInfo:React.FC<{
    help: GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_help | null
}> = (props) => {
    const { currentUserSession } = React.useContext(AppContext);
  return (
    <div className={styles.staticContainer}>
    {props.help?.html && (
        <p dangerouslySetInnerHTML={{ __html: props.help?.html! }} />
    )}
    {currentUserSession.user?.isSupportUser && props.help?.supportHtml && (
        <React.Fragment>
            <h5>support:</h5>
            <p dangerouslySetInnerHTML={{ __html: props.help?.supportHtml! }} />
        </React.Fragment>
    )}
</div>
  )
}

export default HelpInfo