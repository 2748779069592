import * as React from "react"
import Modal from "components/Modal"
import styled, { createGlobalStyle } from "styled-components"
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons"
import { useHistory, useLocation } from "react-router"
import "@brainhubeu/react-carousel/lib/style.css"
import { ModalContent } from "./ModalContent"
import { createContext, useContextSelector } from "use-context-selector"
import { SelectOrderInitiatorView_selectOrderInitiatorView_orderInitiatorId } from "schema"

export const SEARCH_KEY_VALUE_GROUP = "add_product_group"
export const SEARCH_KEY_VALUE_PRODUCT = "add_product_casefolder"
export const SEARCH_KEY = "action"

export const AddProductGroupOrderModalWithoutRouting: React.FC<{
  caseFolderId?: string
  showModal: boolean
  onCloseModal?: any
  onSelectOrderInitiatorView?: (
    value: SelectOrderInitiatorView_selectOrderInitiatorView_orderInitiatorId
  ) => void
}> = (props) => {
  if (props.showModal === false) {
    return null
  }

  return (
    <Modal
      onRequestClose={props.onCloseModal}
      isOpen={props.showModal}
      isLoading={false}
      isModalInModal={false}
      showCloseIcon={true}
      className={"add_product_group_modal"}
    >
      <GlobalModalStyle />
      <ModalContent
        caseFolderId={props.caseFolderId}
        documentId={undefined}
        shouldCreateOrder={false}
        onCloseModal={props.onCloseModal}
        onSelectOrderInitiatorView={props.onSelectOrderInitiatorView}
      />
    </Modal>
  )
}

const GlobalModalStyle = createGlobalStyle`
  .add_product_group_modal {
    overflow: visible;
    min-height: 450px;
    max-width: 75vw;
  }
`
