import * as React from "react"
import * as styles from "./styles.scss"
import { Mutation, Query } from "react-apollo"
import UPDATE_CASE_FOLDER from "graphql/mutations/updateCaseFolder"
import Spinner from "components/Spinner"
import ErrorMessage from "components/ErrorMessage"
import CaseFolderForm from "components/CaseFolderForm"
import CaseFolderHeaderRow from "../CaseFolderHeaderRow"
import SubmitButton from "./SubmitButton"
import GET_NEW_CASE_FOLDER_PAGE_CASE_FOLDER from "graphql/queries/getNewCaseFolderPageCaseFolder"
import {
  GetNewCaseFolderPageCaseFolder,
  GetCaseFolders_findCaseFoldersByCurrentCustomer_caseFolders,
  UpdateCaseFolder,
} from "schema"
import { Redirect } from "react-router-dom"
import PackagesContainer from "./PackagesContainer"
// import posed from "react-pose1"
import { motion } from "framer-motion"
import BaseRow from "./BaseRow"

// const PosedContainer = posed.div({
//   enter: { height: "auto" },
//   exit: { height: 67 },
// })
const variants = {
  enter: { height: "auto" },
  exit: { height: 67 },
}

class EditCaseFolderForm extends React.Component<
  EditCaseFolderFormProps,
  EditCaseFolderFormState
> {
  state = { isExpanded: false }

  toggleIsExpanded = () => this.setState({ isExpanded: !this.state.isExpanded })

  collapse = () => this.setState({ isExpanded: false })


  render() {
    return (
      <React.Fragment>
        <PackagesContainer
          caseFolderId={this.props.caseFolderId}
          isActive={this.props.isActive}
          showBackButton={true}
          onClickBackButton={this.props.onBack}
          isShowBorder={this.props.isShowBorder}
        />
      </React.Fragment>
    )
  }
}

interface EditCaseFolderFormProps {
  isActive: boolean
  caseFolderId?: string | any
  onComplete: () => void
  onBack: () => void
  isShowBorder?: boolean
}

interface EditCaseFolderFormState {
  isExpanded: boolean
}

export default EditCaseFolderForm

  // renderBottomSubmitButton = () => (
  //   <SubmitButton
  //     onToggleMutationInProgressOn={this.props.onToggleMutationInProgressOn}
  //     mutationInProgress={this.props.mutationInProgress}
  //   />
  // )

        {/* <Mutation<UpdateCaseFolder>
          mutation={UPDATE_CASE_FOLDER}
          onCompleted={this.props.onToggleMutationInProgressOff}
          onError={this.props.onToggleMutationInProgressOff}
        >
          {(updateCaseFolder) => (
            <Query<GetNewCaseFolderPageCaseFolder>
              query={GET_NEW_CASE_FOLDER_PAGE_CASE_FOLDER}
              variables={{ id: this.props.caseFolderId }}
            >
              {({ loading, error, data }) => {
                if (loading) return <Spinner />

                if (error) return <ErrorMessage message={error.message} />

                if (data!.caseFolder.productPackages.length > 0)
                  return <Redirect to="/dashboard" />

                return (
                  <motion.div
                    animate={this.state.isExpanded ? "enter" : "exit"}
                    className={styles.container}
                    variants={variants}
                  >
                    <BaseRow
                      caseFolder={data!.caseFolder}
                      onToggle={this.toggleIsExpanded}
                    />
                    <CaseFolderForm
                      renderHeaderRow={CaseFolderHeaderRow}
                      columnCount={4}
                      renderBottomRightSubmitButton={
                        this.renderBottomSubmitButton
                      }
                      onSubmit={updateCaseFolder}
                      afterSubmit={this.collapse}
                      selectedCaseFolder={
                        data!
                          .caseFolder as GetCaseFolders_findCaseFoldersByCurrentCustomer_caseFolders
                      }
                      onToggleLoadingOff={
                        this.props.onToggleMutationInProgressOff
                      }
                    />
                  </motion.div>
                )
              }}
            </Query>
          )}
        </Mutation> */}

          // mutationInProgress: boolean
  // onToggleMutationInProgressOn: () => void
  // onToggleMutationInProgressOff: () => void