import * as React from "react"
import * as styles from "./styles.scss"
import * as cx from "classnames"

class Dot extends React.PureComponent<DotProps> {
  handleClick = () => {
    if (this.props.onClick) this.props.onClick(this.props.index)
  }

  render() {
    const { isActive, isDisabled } = this.props

    return (
      <div
        onClick={!isDisabled ? this.handleClick : undefined}
        className={cx(styles.dot, {
          [styles.isActive]: isActive,
          [styles.isDisabled]: isDisabled,
        })}
      />
    )
  }
}

interface DotProps {
  index: number
  isActive: boolean
  isDisabled: boolean
  onClick?: (index: number) => void
}

export default Dot
