import React from "react"
import * as styles from "./styles.scss"
import * as cx from "classnames"
import TextInput from "components/TextInput"
import { useTranslation } from "react-i18next"
import { MutationFn } from "react-apollo"
import { FormikDateAndTimeField } from "components/DateAndTimeField"
import { FieldWrapperFormik } from "components/FieldWrapper"
import { FieldError } from "components/FieldWrapper"
import { Formik } from "formik"
import moment from "moment"




const MemmoryRoom: React.FC<{
  isActive: boolean
  columnCount?: 2 | 3 | 4
  autoStorage: (args: any) => MutationFn
  formik: any
  orderId: string
}> = (props) => {
  const { t } = useTranslation(["errorMessages", "dateFormats", "agencySettings"])
  const columnCount = props.columnCount
  const isError = props.formik.errors



  return (
    <div
      className={cx("", {
        ["styles.inactive"]: !props.isActive,
      })}
    >
      <div className={styles.container}>
        <div
          className={cx(styles.inputsContainer, {
            [styles.isEditCaseFolder]: columnCount === 2,
          })}
        >
          <div className={styles.inputHolder}>
            <label>{t("agencySettings:firstName")} *</label>
            <TextInput
              value={props.formik.values.personFirstName}
              onChange={(e: any) => {
                props.formik.setFieldValue("personFirstName", e.target.value)
                props.autoStorage({
                  case: {
                    personFirstname: e.target.value,
                  },
                })
              }}
              placeholder={t("agencySettings:firstName")}
            />
            <div>
              <FieldError
                text={isError.personFirstName ? t("firstNameMissing") : ""}
              />
            </div>
          </div>
          <div className={styles.inputHolder}>
            <label>{t("agencySettings:lastName")} *</label>
            <TextInput
              value={props.formik.values.personLastname}
              onChange={(e: any) => {
                props.formik.setFieldValue("personLastname", e.target.value)
                props.autoStorage({
                  case: {
                    personLastname: e.target.value,
                  },
                })
              }}
              placeholder={t("agencySettings:lastName")}
            />
            <div>
              <FieldError
                text={isError.personLastname ? t("lastNameMissing") : ""}
              />
            </div>
          </div>
          <div className={styles.datePicker}>
            <FieldWrapperFormik
              id="personBornDate"
              value={props.formik.values.personBorn}
              onChange={(e: any) => {
                // I turned the clock back 12 hours avoid daylight saving time. which make the date turn a day back on local winter date
                const dateUtc = moment.utc(e)
                const loaclDate = dateUtc.local().set({h:12, m:0})
                props.formik.setFieldValue("personBorn", e)
                props.autoStorage({
                  case: {
                    personTimeBorn: {
                      value: loaclDate ,
                    },
                  },
                })
              }}
              errors={
                ""
              }
              name="personBorn"
              label={t("agencySettings:birthday")}
              placeholder={"..."}
              autoFocus={false}
            >
              <FormikDateAndTimeField showTimeSelect={false} />
            </FieldWrapperFormik>

            <div>
              <FieldError
                text={
                  isError.personBorn
                    ? t("personBornMissing")
                    : "" || isError.futureDateError
                    ? t("futureDateError")
                    : "" || isError.personDiedLaterThanBornError
                    ? t("personDiedLaterThanBornError")
                    : ""
                }
              />
            </div>
          </div>
          <div className={styles.datePicker}>
            <FieldWrapperFormik
            id="personDeathDate"
              value={props.formik.values.personDied}
              onChange={(e: any) => {
                props.formik.setFieldValue("personDied", e)
                // I turned the clock back 12 hours avoid daylight saving time. which make the date turn a day back on local winter date
                const dateUtc = moment.utc(e)
                const loaclDate = dateUtc.local().set({h:12, m:0})
                props.autoStorage({
                  case: {
                    personTimeDied: {
                      value: loaclDate,
                    },
                  },
                })
              }}
              errors={
                ""
              }
              name="personDied"
              label={t("agencySettings:dayOfdeath")}
              placeholder={"..."}
              autoFocus={false}
            >
              <FormikDateAndTimeField showTimeSelect={false} />
            </FieldWrapperFormik>
            <div>
              <FieldError
                text={
                  isError.personDied
                    ? t("personDiedMissing")
                    : "" || isError.futureDateError
                    ? t("futureDateError")
                    : "" || isError.personDiedLaterThanBornError
                    ? t("personDiedLaterThanBornError")
                    : ""
                }
              />
            </div>
          </div>
          <div className={styles.inputHolder}>
            <label>{t("agencySettings:HomeTown")}</label>
            <TextInput
              value={props.formik.values.personHomeTown}
              onChange={(e: any) => {
                props.formik.setFieldValue("personHomeTown", e.target.value)
                props.autoStorage({
                  case: {
                    personCityDied: e.target.value
                  }
                })
              }}
              placeholder={t("agencySettings:HomeTown")}
            />
          </div>
          <div className={styles.inputHolder}>
            <label>{t("agencySettings:birtdayName")}</label>
            <TextInput
              value={props.formik.values.personBornLastname}
              onChange={(e: any) => {
                props.formik.setFieldValue("personBornLastname", e.target.value)
                props.autoStorage({
                  case: {
                    personBornLastname: e.target.value
                  }
                })
              }}
              placeholder={t("agencySettings:birtdayName")}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default MemmoryRoom
