import * as React from "react"
import * as styles from "./styles.scss"
import Whiteframe from "components/Whiteframe"
import * as cx from "classnames"
import Button from "components/Button"

class Card extends React.PureComponent<CardProps> {
  render() {
    const {
      image,
      onClick,
      actionText,
      children,
      renderButton,
      className,
      contentClassName,
    } = this.props

    return (
      <Whiteframe padding={false}  className={cx(styles.container, className)}>
        <div>
          {image && <img src={image} className={styles.image} />}
          <div className={cx(styles.contentContainer, contentClassName)}>
            {children}
          </div>
        </div>

        {renderButton && renderButton()}
        {onClick && !renderButton && (
          <Button variant="primary" onClick={onClick} className={styles.button}>
            {actionText}
          </Button>
        )}
      </Whiteframe>
    )
  }
}

interface CardProps {
  image?: string
  onClick?: () => void
  actionText?: string
  className?: string
  contentClassName?: string
  renderButton?: () => JSX.Element
}

export default Card
