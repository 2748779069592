import * as React from "react"
import Button from "components/Button"
import { WithTranslation, withTranslation } from "react-i18next"

class SearchSymbolButton extends React.PureComponent<
  SearchSymbolButtonProps & WithTranslation
> {
  render() {
    const { onClick, t } = this.props

    return (
      <Button variant="primary" onClick={onClick} icon="search" dataTestId={"symbol-button"}>
        {t("select")} Symbol
      </Button>
    )
  }
}

interface SearchSymbolButtonProps {
  onClick: () => void
}

export default withTranslation()(SearchSymbolButton)
