import * as React from "react"
import * as styles from "./styles.scss"
import Tab from "./Tab"
import { Tab as TabInterface } from "../"
import cx from "classnames"
import ResizeObserver from "resize-observer-polyfill"
import { OrderAlertingType } from "schema"

class Tabs extends React.Component<TabsProps> {
  tabs: React.RefObject<HTMLDivElement> = React.createRef()
  tabsWrapper: React.RefObject<HTMLDivElement> = React.createRef()
  ro = new ResizeObserver(() => this.handleResize())

  handleResize = () => {
    const t = this.tabs.current
    const tw = this.tabsWrapper.current

    this.props.onSetMaxScrollDistance(t ? t.scrollWidth - t.offsetWidth : 0)

    this.props.onSetShowNavButtons(
      t && tw && tw.clientWidth > t.clientWidth ? true : false
    )
  }

  componentDidMount = () => this.ro.observe(this.tabs.current!)

  componentDidUpdate = () => this.handleResize()

  componentWillUnmount = () => this.ro.unobserve(this.tabs.current!)

  render() {
    const {
      distanceScrolled,
      tabs,
      onClick,
      selectedId,
      tabClassName,
      selectedTabClassName,
      variant,
      showStatusDots,
    } = this.props

    return (
      <div
        className={cx(styles.tabs, styles[`variant-${variant}`])}
        ref={this.tabs}
        style={{ transform: `translateX(-${distanceScrolled}px)` }}
      >
        <div className={styles.tabsWrapper} ref={this.tabsWrapper}>
          {tabs.map((tab) => (
            <Tab
              key={tab.id}
              tab={tab}
              variant={variant}
              isSelected={tab.id === selectedId}
              selectedClassName={selectedTabClassName}
              className={tabClassName}
              onClick={onClick}
              showStatusDots={showStatusDots}
              alerting={tab.alerting}
            />
          ))}
        </div>
      </div>
    )
  }
}

// const Tabs: React.FC<TabsProps> = (props) => {
//   const tabs = React.useRef<HTMLDivElement | null>(null)
//   const tabsWrapper = React.useRef<HTMLDivElement | null>(null)
//   const ro = new ResizeObserver(() => handleResize())

//   const handleResize = () => {
//     const t = tabs.current
//     const tw = tabsWrapper.current

//     props.onSetMaxScrollDistance(t ? t.scrollWidth - t.offsetWidth : 0)

//     props.onSetShowNavButtons(
//       t && tw && tw.clientWidth > t.clientWidth ? true : false
//     )
//   }

//   React.useEffect(() => {
//     ro.observe(tabs.current!)
//   }, [])

//   React.useEffect(() => {
//     handleResize()
//     return () => {
//       ro.unobserve(tabs.current!)
//     }
//   }, [props])

//   const {
//     distanceScrolled,
//     onClick,
//     selectedId,
//     tabClassName,
//     selectedTabClassName,
//     variant,
//     showStatusDots,
//   } = props

//   return (
//     <div
//       className={cx(styles.tabs, styles[`variant-${variant}`])}
//       ref={tabs}
//       style={{ transform: `translateX(-${distanceScrolled}px)` }}
//     >
//       <div className={styles.tabsWrapper} ref={tabsWrapper}>
//         {props.tabs.map((tab) => (
//           <Tab
//             key={tab.id}
//             tab={tab}
//             variant={variant}
//             isSelected={tab.id === selectedId}
//             selectedClassName={selectedTabClassName}
//             className={tabClassName}
//             onClick={onClick}
//             showStatusDots={showStatusDots}
//           />
//         ))}
//       </div>
//     </div>
//   )
// }

interface TabsProps {
  tabs: TabInterface[]
  tabClassName?: string
  selectedTabClassName?: string
  distanceScrolled: number
  variant: string
  showNavButtons: boolean
  onClick: (value: any) => void
  onSetMaxScrollDistance: (maxScrollOffset: number) => void
  selectedId?: string
  onSetShowNavButtons: (value: boolean) => void
  showStatusDots?: boolean
}

export default Tabs
