import * as React from "react"
import * as styles from "./styles.scss"
import ReactSelect from "react-select"
import { useTranslation } from "react-i18next"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { DropdownOption } from "components/DropdownButton"
import cx from "classnames"
import { AppContext } from "App"
import { FEselectStyles, selectStyles } from "./stylesConfig"

const Select: React.FC<any> = (props) => {
  const { t } = useTranslation()
  const { featureFlags } = React.useContext(AppContext)
  const {
    label,
    className,
    noOptionsMessage,
    placeholder: ph,
    isLoading,
    tags,
    ...rest
  } = props
  const noOptionMsg = isLoading
    ? `${t("loading")}...`
    : noOptionsMessage
    ? noOptionsMessage
    : t("noResults")
  const placeholder = ph ? ph : `${t("search")}...`

  const [textValue, setTextValue] = React.useState<string>()
  const [lastTextValue, setLastTextValue] = React.useState<string>("")

  const onInputTextChanged = (text: string) => {
    // get inputChanges
    if (
      lastTextValue?.split(" ").length != text.split(" ").length ||
      lastTextValue?.split("\r").length != text.split("\r").length
    ) {
      // when space och return add tag
      var tagsNew = tags as DropdownOption[]
      tagsNew.push({
        label: lastTextValue.toUpperCase(),
        value: lastTextValue.toUpperCase(),
      })
      rest.onTagUpdate(tagsNew)
      // reset text
      text = ""
    }

    setLastTextValue(text)
    setTextValue(text)
    rest.onInputChange(text)
  }

  return (
    <div
      className={cx(className, {
        [styles.feView__select]: featureFlags?.isFEUser,
      })}
    >
      {props.searchIcon ? (
        <div className={styles.headerContainer}>
          <FontAwesomeIcon icon={"search"} size={"1x"} />
          {label && <b className={styles.label}>{label}</b>}
        </div>
      ) : (
        <>{label && <b className={styles.label}>{label}</b>}</>
      )}

      <ReactSelect
        {...rest}
        styles={featureFlags?.isFEUser ? FEselectStyles : selectStyles}
        noOptionsMessage={() => noOptionMsg}
        placeholder={placeholder}
        inputValue={textValue}
        onInputChange={onInputTextChanged}
        id={props.id}
      />
    </div>
  )
}

export default Select
