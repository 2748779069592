import * as React from "react"
import * as styles from "./styles.scss"
import * as cx from "classnames"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const Header: React.FC<HeaderProps> = (props) => {
  const {
    title,
    subtitle,
    className,
    renderSubtitle: CustomSubtitle,
    icon,
    ...rest
  } = props

  return (
    <div className={cx(styles.container, className)}>
      {title &&
        (icon ? (
          <div className={styles.headerWithIcon}>
            <FontAwesomeIcon icon={icon} />
            <h3 className={styles.title}>{title}</h3>
          </div>
        ) : (
          <h3 className={styles.title}>{title}</h3>
        ))}
      {CustomSubtitle && <CustomSubtitle />}
      {subtitle &&
        !CustomSubtitle &&
        (typeof subtitle === "string" ? (
          <p className={styles.subtitle}>{subtitle}</p>
        ) : (
          <div>{subtitle}</div>
        ))}
    </div>
  )
}

interface HeaderProps {
  title?: string
  renderSubtitle?: () => JSX.Element
  subtitle?: string | JSX.Element
  className?: string
  isImageModal?: boolean
  icon?: any
}

export default Header
