import * as React from "react"
import * as styles from "./styles.scss"
import cx from "classnames"

class CheckboxField extends React.PureComponent<CheckboxFieldProps> {
  render() {
    const { label, input, value, checked, className, ...rest } = this.props

    let isChecked = false
    if (checked !== undefined) {
      isChecked = checked
    }
    if (value !== undefined) {
      isChecked = checked
    }
    return (
      <label
        className={cx(styles.container, className, {
          [styles.disabled]: this.props.disabled,
        })}
      >
        <input type="checkbox" {...rest} {...input} checked={isChecked} />
        <span className={styles.checkmark} />
        {label}
      </label>
    )
  }
}

interface CheckboxFieldProps {
  label: string
  checked: boolean
  value?: boolean
  input?: any
  disabled?: boolean
  className?: string
  onChange: () => void
}

export const CheckboxFieldFormik: React.FC<CheckboxFieldFormikProps> = (
  props
) => {
  return (
    <label
      className={cx(styles.container, props.className, {
        [styles.disabled]: props.disabled,
      })}
    >
      <input
        type="checkbox"
        checked={props.value}
        onChange={props.onChange}
      />
      <span className={styles.checkmark} />
      {props.label}
    </label>
  )
}
interface CheckboxFieldFormikProps {
  label: string
  value?: boolean
  disabled?: boolean
  className?: string
  onChange: () => void
}

export default CheckboxField
