import * as React from "react"
import * as styles from "./styles.scss"
import { Form } from "react-final-form"
import FieldWrapper from "components/FieldWrapper"
import TextInput from "components/TextInput"
import SubmitButton from "../SubmitButton"
import { useTranslation } from "react-i18next"
import { useMutation } from "react-apollo-hooks"
import UPDATE_AGENCY_SETTINGS from "graphql/mutations/updateAgencySettings"
import GET_CURRENT_CUSTOMER from "graphql/queries/getCurrentCustomer"

function ContactSettings(props: ContactSettingsProps) {
  const [t] = useTranslation("agencySettings")
  const updateSettings = useMutation(UPDATE_AGENCY_SETTINGS)
  const [isWorking, setIsWorking] = React.useState(false)

  function handleSubmitClick(values: any) {
    //setIsWorking(true)
    //props.changeSetting()
    updateSettings({
      refetchQueries: [{ query: GET_CURRENT_CUSTOMER }],
      variables: {
        input: {
          customerScopeSettings: [
            {
              customerScope: "OFFICE",
              settings: {
                ...props.settings.office,
                ...values.office,
              },
            },
            {
              customerScope: "COMPANY",
              settings: {
                ...props.settings.company,
                ...values.company,
              },
            },
          ],
        },
      },
    })
    //.then(() => setIsWorking(false))
  }

  return (
    <Form onSubmit={handleSubmitClick} initialValues={props.settings}>
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <div className={styles.inputsWrapper}>

          
          <FieldWrapper
            label={t("address")}
            name="office.postAddressStreet"
            parseNullAsWhitespace={true}
            component={TextInput}
            marginTop={true}
          />
          <FieldWrapper
            label={t("contactEmail")}
            parseNullAsWhitespace={true}
            name="office.emailOffice"
            component={TextInput}
            marginTop={true}
          />
          <div className={styles.postalCodeRow}>
            <FieldWrapper
              label={t("zipCode")}
              parseNullAsWhitespace={true}
              name="office.postAddressPostcode"
              component={TextInput}
              className={styles.zipCode}
            />
            <FieldWrapper
              label={t("city")}
              parseNullAsWhitespace={true}
              name="office.postAddressCity"
              component={TextInput}
              className={styles.city}
            />
          </div>
          
          <FieldWrapper
            label={t("memorialReportEmail")}
            parseNullAsWhitespace={true}
            name="office.emailOrderMemoriz"
            component={TextInput}
            marginTop={true}
          />
          <FieldWrapper
            label={t("phone")}
            parseNullAsWhitespace={true}
            name="office.contactPhone"
            component={TextInput}
            marginTop={true}
          />
          
          <FieldWrapper
            label={t("invoicingEmail")}
            parseNullAsWhitespace={true}
            name="company.emailInvoicing"
            component={TextInput}
            marginTop={true}
          />
          <FieldWrapper
            label={t("website")}
            parseNullAsWhitespace={true}
            name="office.agencyHomepageName"
            component={TextInput}
            marginTop={true}
          />
          </div>
          <div className={styles.buttonContainer}>
          <SubmitButton isWorking={isWorking} />
          </div>  
          

        </form>
        
      )}
      
    </Form>
  )
}

interface ContactSettingsProps {
  settings: any
  changeSetting: any
}

export default ContactSettings
