import * as React from "react"
// import { GetOrderInitiatorRootNode_orderInitiatorRootNode_children } from "schema"
// import { RouteComponentProps, withRouter } from "react-router-dom"
// import AddOrderModal from "components/AddOrderModal"
// import {
//   SEARCH_KEY,
//   SEARCH_KEY_VALUE_PRODUCT,
// } from "components/AddProductGroupOrderModal/AddProductGroupOrderModal"
// import { useHistory } from "react-router-dom"
// import { useLocation } from "react-router"

const AddOrderButton: React.FC<{
  onClick: any
  // preSelectedDocumentTypeId?: string
  // renderHtml: React.ComponentClass | (() => JSX.Element)
  className?: string
  // action?: string
  // onCompleted?: (res: any) => void
  // onAddOrder: (
  //   node: GetOrderInitiatorRootNode_orderInitiatorRootNode_children
  // ) => Promise<any>
}> = (props) => {
  // const history = useHistory()
  // const location = useLocation()
  // const openModalFromUrl =
  //   props.action !== undefined && props.action === "add_product"
  // const [modalIsOpen, setModalIsOpen] = React.useState(openModalFromUrl)
  // const [isLoading, setIsLoading] = React.useState(false)
  // const { renderHtml: ButtonContent } = props

  // const toggleLoadingOn = () => {
  //   setIsLoading(true)
  // }

  // const openModal = () => {
  // props.
  // setModalIsOpen(true)
  // }

  // const closeModal = () => {
  //   const urlParams = new URLSearchParams(location.search)
  //   urlParams.set(SEARCH_KEY, SEARCH_KEY_VALUE_PRODUCT)
  //   const newSearch = urlParams.toString()
  //   history.push({
  //     search: newSearch,
  //   })
  //   // setModalIsOpen(false)
  // }

  // const handleAddOrder = (
  //   node: GetOrderInitiatorRootNode_orderInitiatorRootNode_children
  // ) => {
  //   props.onAddOrder(node).then((res) => {
  //     // setIsLoading(false)
  //     // setModalIsOpen(false)
  //     if (props.onCompleted) {
  //       props.onCompleted(res.data)
  //     }
  //   })
  // }

  return (
    <React.Fragment>
      <div onClick={props.onClick} className={props.className}>
        {props.children}
        {/* <ButtonContent /> */}
      </div>
      {/* <AddOrderModal
        isOpen={modalIsOpen}
        onAddOrder={handleAddOrder}
        onRequestClose={closeModal}
        toggleAddingOrderOn={toggleLoadingOn}
        openModalFromUrl={openModalFromUrl}
        preSelectedDocumentTypeId={props.preSelectedDocumentTypeId}
        isAddingOrder={isLoading}
      /> */}
    </React.Fragment>
  )
}

export default AddOrderButton
