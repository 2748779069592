import React from "react"
import * as styles from "./styles.scss"
import { faTimes } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { ReactMultiEmail } from "./../index"
import { FieldError, FieldLabel } from "components/FieldWrapper"

const FieldReactMutliEmails: React.FC<{
  value: string[] | undefined
  onChange: any
  errors?: any
  label?: string
  placeholder?: string
}> = props => {
  return (
    <div>
      <FieldLabel text={props.label} />
      <ReactMultiEmail
        emails={props.value}
        className={styles.reactMultiEmail}
        classNameLabels={styles.reactMultiEmailLabels}
        placeholder={props.placeholder}
        onChange={props.onChange}
        getLabel={(
          email: string,
          index: number,
          removeEmail: (index: number) => void
        ) => {
          const onClickRemove = () => removeEmail(index)
          return (
            <div key={index} className={styles.emailLabel}>
              <span className={styles.emailLabelValue}>{email}</span>

              <span className={styles.removeEmail} onClick={onClickRemove}>
                <FontAwesomeIcon icon={faTimes} />
              </span>
            </div>
          )
        }}
      />
      <FieldError text={props.errors} />
    </div>
  )
}

export default FieldReactMutliEmails
