import {
  faArrowAltCircleDown,
  faChevronDown,
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { AppContext } from "App"
import Button from "components/Button"
import TitleWithDropDown from "components/TitleWithDropdown"
import Toggler from "components/Toggler"
import React from "react"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import { GetCaseFolders_findCaseFoldersByCurrentCustomer_caseFolders } from "schema"
import SelectedCaseFolderView from "../../SelectedCaseFolderView"
import styles from "../../styles.scss"

const ShowMode: React.FC<{
  selectedCaseFolder?: GetCaseFolders_findCaseFoldersByCurrentCustomer_caseFolders
  inEditMode: boolean
  onExitEditMode: () => void
  deleteCaseFolder: any
  isCreateMode: boolean
  exitCreateCaseFolder: () => void
  onBackFromPackages: () => void
  enterCreateCaseFolder: () => void
}> = (props) => {
  const { t } = useTranslation("dashboard")
  const { isShowModeShow, toggleShowModeView } = React.useContext(AppContext)

  return (
    <div className={styles.showModeContainer}>
      <div className={styles.showModeContainer__header}>
        <TitleWithDropDown
          title={t("CaseFolders")}
          listItem={
            <li>
              <span>{t("ShowHide")}</span>
              <Toggler onChange={toggleShowModeView} checked={isShowModeShow} />
            </li>
          }
        />

        <div className={styles.showModeContainer__links}>
          <Link to="/archive" className={styles.link}>
            <FontAwesomeIcon icon="folder-open" className={styles.icon} />
            {t("FileArchive")}
          </Link>
          <Button
            disabled={props.isCreateMode}
            onClick={props.enterCreateCaseFolder}
            color={"secondary"}
            className={styles.link}
          >
            {t("createCaseFolder")}
          </Button>
        </div>
      </div>

      <div style={{ width: "100%" }}>
        <SelectedCaseFolderView
          deleteCaseFolder={props.deleteCaseFolder}
          selectedCaseFolder={props.selectedCaseFolder}
          inEditMode={props.inEditMode}
          onExitEditMode={props.onExitEditMode}
          isCreateMode={props.isCreateMode}
          exitCreateCaseFolder={props.exitCreateCaseFolder}
          onBackFromPackages={props.onBackFromPackages}
        />
      </div>
    </div>
  )
}

export default ShowMode
