import * as React from "react"
import { useTranslation } from "react-i18next"
import Button from "components/Button"
import { Link } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import styled from "styled-components"
import Toggler from "components/Toggler"
import { AppContext } from "App"
import TitleWithDropDown from "components/TitleWithDropdown"
import { envKeys } from "environmentUtils"
import MessageModel from "components/MessageModel"

const CaseFolderTableHeaderRow: React.FC<{
  openCreateCaseFolderForm: () => void
  isCreateMode: boolean
}> = (props) => {
  const { t } = useTranslation("dashboard")
  const { featureFlags } = React.useContext(AppContext)
  const { isShowModeShow, toggleShowModeView } = React.useContext(AppContext)
  const [isMessageModalOpen, setMessageModalOpen] = React.useState<boolean>(
    false
  )
  const BITNET_PROD_NEW_FUNERALCASE_URL = "https://app.bit-net.se/arende/ny"
  const BITNET_STAGING_URL = "https://staging.bit-net.se/arende/ny"
  const bitnetUrl =
    envKeys.REACT_APP_ENVIRONMENT === "prod"
      ? BITNET_PROD_NEW_FUNERALCASE_URL
      : BITNET_STAGING_URL

  const isBitnetModalOpen = localStorage.getItem("isBitnetMessageModalOpen")

  const handleOpenMessageModal = () => setMessageModalOpen(true)
  const handleCloseMessageModal = () => setMessageModalOpen(false)

  const handleMoveToBitnet = () => {
    window.location.href = bitnetUrl
  }

  const handleCreateCaseClick = () => {
    if (featureFlags?.isBitnetUser) {
      if (!isBitnetModalOpen) {
        localStorage.setItem("isBitnetMessageModalOpen", "true")
        handleOpenMessageModal()
      } else { 
        handleMoveToBitnet()
      }
      handleOpenMessageModal()
    } else {
      props.openCreateCaseFolderForm()
    }
  }

  return (
    <ContainerDiv>
      <TitleWithDropDown
        title={t("CaseFolders")}
        listItem={
          <li>
            <span>{t("ShowHide")}</span>
            <Toggler onChange={toggleShowModeView} checked={isShowModeShow} />
          </li>
        }
      />
      <div className={"showModeContainer__links"}>
        <Link to="/archive" className={"link"}>
          <FontAwesomeIcon icon="folder-open" className={"icon"} />
          {t("FileArchive")}
        </Link>
        <Button
          disabled={props.isCreateMode}
          onClick={handleCreateCaseClick}
          color={"secondary"}
          className={"link"}
          dataTestId={"createCaseFolder"}
        >
          {featureFlags?.isBitnetUser ? "Skapa akt i Bitnet" : t("createCaseFolder")}
          
        </Button>
      </div>
      {isMessageModalOpen && (
        <MessageModel
          isOpen={isMessageModalOpen}
          doClose={handleCloseMessageModal}
          message={t("dashboard:bitnetMessage")}
          closeButtonText={t("common:close")}
          linkText={"Bitnet"}
          link={bitnetUrl}
        />
      )}
    </ContainerDiv>
  )
}

export default CaseFolderTableHeaderRow

const ContainerDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  .showModeContainer__links {
    .link {
      margin-left: 10px;
      .icon {
        margin-right: 5px;
      }
    }
  }
`
