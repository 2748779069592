import React from "react"
import * as styles from "./styles.scss"
import {
  faArrowAltCircleLeft,
  faArrowAltCircleRight,
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import produce from "immer"
import { DOTS, usePagination } from "./usePagination"
import { IPaginationsOptions } from "customSchema.interface"
import cx from "classnames"
import { AppContext } from "App"

const Pagination: React.FC<{
  className: string
  currentPage: number
  totalCount: number
  pageSize: number
  siblingCount?: number
  onPageChange: (page: any) => void
  paginationsOptions?: IPaginationsOptions
  setPaginationsOptions?: (arg: any) => void
  isVerse?: boolean
}> = (props) => {
  const {featureFlags} = React.useContext(AppContext)
  const {
    onPageChange,
    totalCount,
    siblingCount = 1,
    currentPage,
    pageSize,
    className,
    paginationsOptions,
    setPaginationsOptions,
  } = props

  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  })

  const onNext = () => {
    onPageChange(currentPage + 1)
    setPaginationsOptions!(
      produce(paginationsOptions, (draft) => {
        draft!.startIndex =
          paginationsOptions!.startIndex + paginationsOptions!.PAGE_LENGTH
      })
    )
  }

  const onPrevious = () => {
    onPageChange(currentPage - 1)
    setPaginationsOptions!(
      produce(paginationsOptions, (draft) => {
        draft!.startIndex =
          paginationsOptions!.startIndex - paginationsOptions!.PAGE_LENGTH
      })
    )
  }

  const onRangeSelect = (pageNum: number) => {
    onPageChange(pageNum)
    setPaginationsOptions!(
      produce(paginationsOptions, (draft) => {
        draft!.startIndex = props.isVerse ? pageNum  : pageNum * 100 - 100
      })
    )
  }

  let lastPage = paginationRange[paginationRange.length - 1]

  return (
    <div className={cx(styles.paginationContainer, className, {
      [styles.feView__paginationContainer]: featureFlags?.isFEUser
    })}>
      <div className={styles.pagination}>
        <button
          className={styles.prev}
          onClick={onPrevious}
          disabled={currentPage === 1}
        >
          <FontAwesomeIcon icon={faArrowAltCircleLeft} />
        </button>
        <ul className={styles.counter}>
          {paginationRange.map((num: number, index: number) => {
            if (num.toString() === DOTS) {
              return <li key={index}>&#8230;</li>
            }
            return (
              <li
                key={index}
                onClick={() => onRangeSelect(num)}
                className={cx("", { [styles.selected]: num === currentPage })}
              >
                <span>
                {num}
                </span>
               
              </li>
            )
          })}
        </ul>
        <button
          className={styles.next}
          onClick={onNext}
          disabled={currentPage === lastPage}
        >
          <FontAwesomeIcon icon={faArrowAltCircleRight} />
        </button>
      </div>
    </div>
  )
}

export default Pagination
