import gql from "graphql-tag"

const GET_SYSTEM_INFO = gql`
  query getSystemInfo {
    systemInfo {
      build {
        branch
        tag
        time
      }
      configuration {
        websocketEnabled
      }
      environment {
        name
      }
    }
  }
`

export default GET_SYSTEM_INFO
