import * as React from "react"
import * as styles from "./styles.scss"
import ReactTooltip from "react-tooltip"
import cx from "classnames"
import { AppContext } from "App"

const Tooltip:React.FC<TooltipProps> = (props) =>  {
    const {featureFlags} = React.useContext(AppContext)
    const { children, text, delayShow, className, id, bgColor , globalEventOff} = props

    return (
      <div data-tip={text} data-for={id} className={cx(styles.container, {
        [styles.feView__container]: featureFlags?.isFEUser!
      }) }>
        <ReactTooltip
          id={id}
          className={cx(styles.tooltip, className)}
          effect="solid"
          delayShow={delayShow}
          multiline={true}
          backgroundColor={bgColor}
          globalEventOff={globalEventOff}
          
        />
        {children}
      </div>
    )
  
}

interface TooltipProps {
  text: any
  id: string
  delayShow?: number
  className?: string
  bgColor?:string
  globalEventOff?: any
}

export default Tooltip
