import React from "react"
import styles from "../styles.scss"
import { motion } from "framer-motion"
import { Istate, IState_Avatar } from ".."
import cx from "classnames"
import RangeInput from "./RangeInput"
import { faAdjust, faPalette, faSync } from "@fortawesome/free-solid-svg-icons"
import RangeSlider from "components/RangeSlider"
import { ImageSelectDialogFile } from "customSchema.interface"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import AvatarEditor from "./AvatarEditor"
import Button from "components/Button"
import produce from "immer"
import { useTranslation } from "react-i18next"
import { GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_image_adjustment } from "schema"
import { getImage } from "utils"
import { stat } from "fs"


// tslint:disable-next-line: max-line-length
type textKeys = keyof GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_image_adjustment

const brightnessKey: textKeys = "brightness"
const contrastKey: textKeys = "contrast"
const rotateKey: textKeys = "rotate"

const ImageEditor: React.FC<{
  state: Istate
  handleImageColor: (value: number) => void
  handleSaturation: (value: number) => void
  handleScaleByRange: (value: number) => void
  handleScale: (value: number) => void
  imageDetails: ImageSelectDialogFile | null
  editorRef: any
  handleRotate: (arg: string) => void
  handleFlip: () => void
  handleToggleToOriginal_image: () => void
  handleReset: () => void
  onSaveCanvasImage: () => void
  canvas: any
  mask: string | null | undefined
  handleLightness: (value: number) => void
  handleContrast: (value: number) => void
  avatarSize: IState_Avatar
  setAvatarSize: (arg: IState_Avatar) => void
  adjustmentValues:
    | GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_image_adjustment
    | null
    | undefined
  comment: string;
  setComment: (arg: string) => void;
  isStandardAdvert: boolean;
  isFEUser: boolean
}> = ({
  state,
  handleScaleByRange,
  handleScale,
  imageDetails,
  editorRef,
  handleRotate,
  handleFlip,
  handleReset,
  onSaveCanvasImage,
  handleLightness,
  handleContrast,
  canvas,
  mask,
  avatarSize,
  setAvatarSize,
  adjustmentValues,
  comment,
  setComment,
  isStandardAdvert,
  isFEUser
}) => {
  const { t } = useTranslation()
  const visibleEditorRef = React.useRef<any>(null)
  const [position, setPosition] = React.useState({ x: 0, y: 0 })

  React.useLayoutEffect(() => {
    resizeCanvasBaseOnMaskWidth()
    if (isStandardAdvert) {
      resizeCanvasBaseOnImageSize()
    }
  }, [mask])

  React.useEffect(() => {
    if (editorRef.current && editorRef.current.state && editorRef.current.state.image) {
      setPosition({
        x:editorRef.current.state.image.x , 
        y:editorRef.current.state.image.y })
    }
  }, [editorRef.current])


  //avantgard solution
  const resizeCanvasBaseOnMaskWidth = async () => {
    const img = (await getImage(mask!)) as HTMLImageElement
    if (img.naturalWidth && img.naturalWidth > 400) {
      setAvatarSize(
        produce(avatarSize, (draft) => {
          draft.width = img.naturalWidth * 0.8
          draft.height = img.naturalHeight * 0.8
        })
      )
    } else {
      setAvatarSize(
        produce(avatarSize, (draft) => {
          draft.width = img.naturalWidth * 1.3
          draft.height = img.naturalHeight * 1.3
        })
      )
    }
  }
  //standard solution
  const resizeCanvasBaseOnImageSize = async () => {
    const img = (await getImage(imageDetails?.preview!)) as HTMLImageElement
    if (img.naturalWidth && img.naturalHeight) {
        const w = 500
        const h = w * (img.naturalHeight / img.naturalWidth);
        setAvatarSize(
          produce(avatarSize, (draft) => {
            draft.width = w
            draft.height = h
          })
        )
      
    }
    return;
  }

  return (
    <motion.div
      className={cx(styles.editMode, {
        [styles.feView__editMode]: isFEUser,
      })}
      initial={{
        height: 0,
      }}
      animate={{
        height: state.isEditMode  ? "100%" : 0,
        display: state.isEditMode ? "flex" : "none",
      }}
      transition={{
        type: "spring",
        stiffness: 260,
        damping: 20,
      }}
    >
      <div className={cx(styles.tools)}>
        <div className={styles.rangeWrapper}>
          <RangeInput
            max={adjustmentValues![brightnessKey].maxValue}
            min={adjustmentValues![brightnessKey].minValue}
            step={adjustmentValues![brightnessKey].intervalValue}
            icon={faAdjust}
            title={t(`editOrder:${brightnessKey}`)}
            value={state.lightness.toFixed(0)}
            onChange={handleLightness}
            isFEUser={isFEUser}
          />
          <RangeInput
            max={adjustmentValues![contrastKey].maxValue}
            min={adjustmentValues![contrastKey].minValue}
            step={adjustmentValues![contrastKey].intervalValue}
            icon={faAdjust}
            title={t(`editOrder:${contrastKey}`)}
            value={state.contrast.toFixed(0)}
            onChange={handleContrast}
            isFEUser={isFEUser}
          />

          <RangeInput
            max={adjustmentValues![rotateKey].maxValue}
            min={adjustmentValues![rotateKey].minValue}
            step={adjustmentValues![rotateKey].intervalValue}
            icon={faPalette}
            title={t(`editOrder:${rotateKey}`)}
            value={state.rotate}
            onChange={handleScaleByRange}
            isFEUser={isFEUser}
          />
        </div>
        <div className={cx(styles.messageWrapper, {
          [styles.feView__messageWrapper]: isFEUser
        })}>
          <div className={styles.header}>
            <h6 className={styles.title}>{t("editOrder:message")}</h6>
            <p className={styles.description}>
              {t("editOrder:imageEditorMessageDescription")}
            </p>
          </div>
          <textarea
            className={styles.textArea}
            id={"imageEditor-textarea"}
            rows={10}
            value={comment}
            onChange={(e) => setComment(e.currentTarget.value)}
          />
        </div>
      </div>
      <div className={styles.viewer}>
        <div className={styles.editorContainer}>
        <div
            className={cx(styles.editorWrapper)}
            style={{ maxWidth: avatarSize.width * 0.70, height: avatarSize.height * 0.70 }}
          >
            <AvatarEditor
              ref={visibleEditorRef}
              image={imageDetails?.preview}
              width={avatarSize.width * 0.70}
              height={avatarSize.height * 0.70}
              border={0}
              color={[255, 255, 255, 0.6]}
              scale={state.scale}
              rotate={state.rotate}
              // className={styles.editor}
              maskImageUrl={mask}
              showGrid={false}
              gridColor={"#afafaf"}
              crossOrigin={"anonymous"}
              disableBoundaryChecks={true}
              hue={state.image_color}
              saturation={state.saturation}
              lightness={state.lightness}
              contrast={state.contrast}
              transparent={state.transparent}
              backgroundColor={"transparent"}
              isOriginalImage={state.isOriginal_image}
              //setPosition={setPosition}
              onPositionChange={(position) => {
                setPosition(position)
              }}
            />
          </div>

          <div
            className={cx(
              styles.invisibleEditorContiner
              )}
            style={{ width: avatarSize.width, height: avatarSize.height }}
          >
            <AvatarEditor
              ref={editorRef}
              image={imageDetails?.preview}
              width={avatarSize.width}
              height={avatarSize.height}
              border={0}
              color={[255, 255, 255, 0.6]}
              scale={state.scale}
              rotate={state.rotate}
              // className={styles.editor}
              maskImageUrl={mask}
              showGrid={false}
              gridColor={"#afafaf"}
              crossOrigin={"anonymous"}
              disableBoundaryChecks={true}
              hue={state.image_color}
              saturation={state.saturation}
              lightness={state.lightness}
              contrast={state.contrast}
              transparent={state.transparent}
              backgroundColor={"transparent"}
              isOriginalImage={state.isOriginal_image}
              position={{
                x:   position.x, 
                y:  position.y 
     
              }}
            />
          </div>
          <RangeSlider
            min={1}
            max={2.5}
            step={0.1}
            value={state.scale}
            onChange={handleScale}
            onChangeComplete={() => console.log}
            rightIcon="image"
            leftIcon="image"
            rightIconStyle={styles.rightIcon}
            className={ cx(styles.slider, {
              [styles.feView__slider]:isFEUser
            }) }
            tooltip={false}
          />

          <div className={cx(styles.rotateBtn, {
            [styles.feView__rotateBtn]: isFEUser
          })}>
            <span onClick={() => handleRotate("right")}>
              <FontAwesomeIcon icon={"undo"} />
            </span>
            <span onClick={() => handleFlip}></span>
            <span onClick={() => handleRotate("left")}>
              <FontAwesomeIcon icon={"undo"} />
            </span>
          </div>

          <div className={styles.btnWrapper}>
            <div
              className={cx(styles.loadUpWrapper, {
                [styles.onEditMode]: state.isEditMode,
              })}
            >
              <Button
                id={"upload-image-button"}
                className={styles.loadUp}
                onClick={onSaveCanvasImage}
                variant={isFEUser ? "feView" :"primary"}
                color={"primary"}
                // disabled={canvas === null}
              >
                {t("editOrder:uploadPdf")}
              </Button>
              <div className={styles.settingGroup}>
                {state.isEditMode && (
                  <Button
                    className={styles.reset}
                    icon={faSync}
                    variant={isFEUser ? "feView" :"primary"}
                    onClick={handleReset}
                  >
                    {t("editOrder:reset")}
                  </Button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  )
}

export default ImageEditor
