import React from "react"
import { GetStatOrders_statsOrders } from "schema"
import ReactTable, { SortingRule } from "react-table-6"
import { useTranslation } from "react-i18next"
import { DateTime } from "luxon"

const ExpandedTable: React.FC<{
  data: any
}> = (props) => {
  const { t } = useTranslation(["archive"])

  return (
    <ReactTable
      className={"statistic_expand"}
      data={props.data}
      showPagination={false}
      showPaginationBottom={false}
      pageSize={props.data.length}
      columns={[
        // {
        //   Header: "",
        //   accessor: "none",
        //   width: 60
        // },
        {
            id: "date",
            Header: t("agencySettings:date"),
            accessor: (row:any) => {
                
              if (
                row.mediaOrder?.publishTimes &&
                row.mediaOrder?.publishTimes[0] &&
                row.mediaOrder?.publishTimes[0].time
              ) {
                return DateTime.fromJSDate(
                  new Date(row.mediaOrder?.publishTimes[0].time)
                ).toFormat(t("dateFormats:date"))
              }
              return "-"
            },
          },
        {
          Header: "",
          accessor: "product.name",
        },
        {
            Header: "",
            accessor: "lockedPrice",
            Cell: (props) => Number(props.original.lockedPrice.endCustomer.pricePay).toFixed(0)  + ' kr'
            
          },
          {
            Header: "",
            accessor: "lockedPrice",
            Cell: (props) => Number(props.original.lockedPrice.customer?.priceEarnings).toFixed(0)  + ' kr'
            
          },
      ]}
    />
  )
}

export default ExpandedTable
