import * as React from "react"
import * as styles from "./styles.scss"
import TimePicker, { TimePickerProps } from "react-time-picker"
import RCTimePicker, {
  TimePickerProps as RcTimePickerProps,
} from "rc-time-picker"
import "rc-time-picker/assets/index.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import moment from "moment"
import * as cx from "classnames"

const TimeField: React.FC<TextInputProps & TimePickerProps> = (props) => {
  return (
    <TimePicker
      format={"HH:mm"}
      maxTime={"23:59:59"}
      minTime={"00:00:00"}
      hourPlaceholder={"hh"}
      minutePlaceholder={"mm"}
      className={styles.timepicker}
      isOpen={false}
      disableClock={true}
      {...props}
      onChange={props.input.onChange}
      value={props.input.value}
    />
  )
}

export const HourField: React.FC<TextInputProps & TimePickerProps> = (
  props
) => {
  return (
    <TimePicker
      format={"HH"}
      maxTime={"23:59:59"}
      minTime={"00:00:00"}
      hourPlaceholder={"hh"}
      minutePlaceholder={"mm"}
      className={styles.timepicker}
      isOpen={false}
      disableClock={true}
      {...props}
      onChange={props.input.onChange}
      value={props.input.value}
    />
  )
}
export const RcHourField: React.FC<TextInputProps & RcTimePickerProps> = (
  props
) => {

  return (
    <RCTimePicker
      format={"HH:mm"}
      allowEmpty={true}
      showHour={true}
      showMinute={true}
      showSecond={false}
      use12Hours={false}
      placeholder={"hh:mm"}
      minuteStep={15}
      inputIcon={
        <FontAwesomeIcon
          icon={"clock"}
          color={" #f2f2f2"}
          className={styles.timeIcon}
        />
      }
      clearIcon={
        <FontAwesomeIcon
          icon={"times"}
          color={" #f2f2f2"}
          className={styles.clearIcon}
        />
      }
      className={styles.rctimepicker}
      // {...props}
  
      onChange={props.input.onChange}
      value={ 
        moment(props.input.value).isValid() === false
          ? undefined
          : moment(props.input.value).set('second', 0)
      }
    />
  )
}

export const RcHourFieldFormik: React.FC<TextInputProps & RcTimePickerProps> = (
  props
) => {
  return (
    <RCTimePicker
      format={"HH:mm"}
      allowEmpty={true}
      showHour={true}
      showMinute={true}
      showSecond={false}
      use12Hours={false}
      minuteStep={15}
      placeholder={"hh:mm"}
      inputIcon={
        <FontAwesomeIcon
          icon={"clock"}
          color={" #f2f2f2"}
          className={styles.timeIcon}
        />
      }
      clearIcon={
        <FontAwesomeIcon
          icon={"times"}
          color={" #f2f2f2"}
          className={styles.clearIcon}
        />
      }
      {...props}
      className={cx(styles.rctimepicker, props.className)}
      onChange={props.onChange}
      value={
        moment(props.value).isValid() === false
          ? undefined
          : moment(props.value).set("second", 0)
      }
    />
  )
}

export interface TextInputProps {
  ref?: React.RefObject<any>
  placeholder?: string
  type?: string
  input?: any
  value?: string
  name?: string
  autoFocus?: boolean
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
}

export default TimeField
