import React from "react"
import styles from "./styles.scss"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faMinus, faPlus, faTimes } from "@fortawesome/free-solid-svg-icons"
import { OrderEditorAreaContext } from "pages/EditOrderPage/OrderEditorContainer/OrderEditoryAdvert/AreasContainer/Areas/AreaContainer"
import { useTranslation } from "react-i18next"
import _ from "lodash"
import Tooltip from "components/Tooltip"
import cx from "classnames"
import { SvgTextScaleIcon } from "asset/svg/SvgComponents"

const scaleTextLogo = () => {
  return React.cloneElement(<SvgTextScaleIcon />, {
    size: "30px",
    color: "#ACACAC",
  })
}

const ScalaTextInput:React.FC<{
  isFEUser: boolean
}> = (props) => {
  const [t] = useTranslation("editOrder")
  const { setTextScaleWidthOffset, editor, contentRows } = React.useContext(
    OrderEditorAreaContext
  )
  const {
    isTextScaleWidthOffset,
    allowedTextScaleWidthOffsetInterval,
  } = editor.text!

  const [isExpand, setExpand] = React.useState<boolean>(false)
  const [value, setValue] = React.useState<number>(
    contentRows[0].columns[0].item.text?.textScaleWidthOffset?.value ===
      undefined
      ? 0
      : contentRows[0].columns[0].item.text?.textScaleWidthOffset?.value
  )

  React.useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (
        isExpand &&
        !_.isEmpty(event.target) &&
        !event.target.closest(`.${styles.scalaTextInput__container}`)
      ) {
        setExpand(false)
      }
    }
    document.addEventListener("mousedown", handleClickOutside)
    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [isExpand])

  const handleIncrease = () => {
    if (value < allowedTextScaleWidthOffsetInterval!.maxValue) {
      handleChange(value + 1)
    }
  }

  const handleDecrease = () => {
    if (value > allowedTextScaleWidthOffsetInterval!.minValue) {
      handleChange(value - 1)
    }
  }

  const handleReset = () => {
    setTextScaleWidthOffset(null)
    setValue(0)
  }

  const handleChange = (value: number) => {
    if (value === 0) {
      setTextScaleWidthOffset(null)
      setValue(0)
      return
    } else {
      setTextScaleWidthOffset(value)
      setValue(value)
    }
  }

  const handleExpand = () => setExpand(true)
  const handleShrank = () => setExpand(false)

  return (
    <>
      {isTextScaleWidthOffset &&
      allowedTextScaleWidthOffsetInterval !== null ? (
        <React.Fragment>
          <div className={styles.buttonHolder}>
            {value !== 0 && (
              <div className={styles.resetButton} onClick={handleReset}>
                <FontAwesomeIcon icon={faMinus} />
              </div>
            )}

            {!isExpand ? (
              <>
                <Tooltip
                  id={"ScalaTextInput"}
                  text={t("compressText")}
                  bgColor={"#fff"}
                  className={styles.tooltip}
                  delayShow={750}
                >
                  <div className={cx(styles.iconWrapper, {
                    [styles.feView__iconWrapper]: props.isFEUser
                  }) } onClick={handleExpand}>
                    {scaleTextLogo()}
                  </div>
                </Tooltip>
              </>
            ) : (
              <div className={cx(styles.iconWrapper,{
                [styles.feView__iconWrapper]: props.isFEUser
              })} onClick={handleShrank}>
                <FontAwesomeIcon icon={faTimes} />
              </div>
            )}
          </div>

          <div
            className={cx(styles.scalaTextInput__container, {
              [styles.isVisible]: isExpand,
              [styles.feView__scalaTextInput__container]: props.isFEUser
            })}
          >
            <button
              type="button"
              className={cx(styles.btn, {
                [styles.btnDisabled]:
                  value === allowedTextScaleWidthOffsetInterval.minValue,
              })}
              onClick={handleDecrease}
              disabled={value === allowedTextScaleWidthOffsetInterval.minValue}
            >
              <FontAwesomeIcon icon={faMinus} />
            </button>

            <input
              className={styles.textInput}
              type="number"
              value={value}
              onChange={(e) => handleChange(Number(e.currentTarget.value))}
              min={allowedTextScaleWidthOffsetInterval.minValue}
              max={allowedTextScaleWidthOffsetInterval.maxValue}
              step={allowedTextScaleWidthOffsetInterval.intervalValue}
              readOnly
            />
            <button
              type="button"
              className={cx(styles.btn, {
                [styles.btnDisabled]:
                  value === allowedTextScaleWidthOffsetInterval.maxValue,
              })}
              onClick={handleIncrease}
              disabled={value === allowedTextScaleWidthOffsetInterval.maxValue}
            >
              <FontAwesomeIcon icon={faPlus} />
            </button>
          </div>
        </React.Fragment>
      ) : null}
    </>
  )
}

export default ScalaTextInput
