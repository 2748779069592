import * as React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import * as styles from "./styles.scss"
import cx from "classnames"

function Order(props: OrderProps) {
  return (
    <div>
      <FontAwesomeIcon
        icon="check"
        className={cx(styles.icon, { [styles.active]: props.isActive })}
      />{" "}
      <span>{props.name}</span>
    </div>
  )
}

interface OrderProps {
  id: string
  name: string
  isActive: boolean
}

export default Order
