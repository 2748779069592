import gql from "graphql-tag"
import ORDER_FRAGMENT from "graphql/fragments/OrderFragment"

const ORDER_SUBSCRIPTION = gql`
  subscription OrderSubscription($id: ID!) {
    order(id: $id) {
      ...OrderFragment
    }
  }
  ${ORDER_FRAGMENT}
`

export default ORDER_SUBSCRIPTION
