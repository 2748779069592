import gql from "graphql-tag"

const PRINT_SYMBOLS = gql`
  mutation PrintGalleryImages($input: GalleryImageAvailableImagesInput!) {
    printGalleryImagesByTags(input: $input) {
      publicUrl
    }
  }
`
export default PRINT_SYMBOLS
