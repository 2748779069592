import * as React from "react"
import { Query } from "react-apollo"
import { RouteComponentProps, withRouter } from "react-router-dom"
import Spinner from "components/Spinner"
import ErrorMessage from "components/ErrorMessage"
import { GetReviewCaseFolder } from "schema"
import DocumentTypeCard from "./DocumentTypeCard"
import Whiteframe from "components/Whiteframe"
import GET_REVIEW_CASE_FOLDER from "graphql/queries/getReviewCaseFolder"
import { withTranslation, WithTranslation } from "react-i18next"
import ReviewHeaderRow from "./ReviewHeaderRow"
import { notEmpty } from "utils"

class ReviewContainer extends React.Component<
  RouteComponentProps & WithTranslation
> {
  render() {
    const params: any = this.props.match.params

    return (
      <Query<GetReviewCaseFolder>
        query={GET_REVIEW_CASE_FOLDER}
        variables={{ id: params.caseFolderId }}
        fetchPolicy={"network-only"}
      >
        {({ loading, error, data }) => {
          if (loading)
            return (
              <Whiteframe>
                <Spinner />
              </Whiteframe>
            )

          if (error) return <ErrorMessage message={error.message} />

          const allOrders = data!.caseFolder.documents.reduce(
            (acc, document) => acc.concat(document!.orders.filter(notEmpty)),
            [] as any[]
          )

          const documents = data!.caseFolder.documents.filter(notEmpty)

          return (
            <React.Fragment>
              <ReviewHeaderRow
                caseFolderId={params.caseFolderId}
                orderId={documents[0].orders.filter(notEmpty)[0].id!}
                orders={allOrders}
              />
              {documents.map(document => (
                <DocumentTypeCard key={document.id!} document={document} />
              ))}
            </React.Fragment>
          )
        }}
      </Query>
    )
  }
}

export default withTranslation("review")(withRouter(ReviewContainer))
