import * as React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import styled from "styled-components"

export const RemoveItemPopup: React.FC<{
  text?: string
  onAbort?: () => void
  onSuccess?: () => void
}> = (props) => {
  return (
    <RemoveItemPopupStyles>
      <div className={"removeItemPopupWrapper"}>
        <div className={"removeItemPopupLabel"}>
          {props.text && props.text.length > 0 ? props.text : "Är du säker?"}
        </div>

        <div className={"removeItemPopupIconWrapper"}>
          <div className={"removeItemPopupLeftIcon"} onClick={props.onSuccess}>
            <FontAwesomeIcon
              icon={"check"}
              className={"removeItemPopupLeftIconOk"}
            />
          </div>
        </div>

        <div className={"removeItemPopupIconWrapper"}>
          <div className={"removeItemPopupRightIcon"} onClick={props.onAbort}>
            <FontAwesomeIcon
              icon={"times"}
              size={"lg"}
              className={"removeItemPopupLeftIconRemove"}
            />
          </div>
        </div>
      </div>
    </RemoveItemPopupStyles>
  )
}

const RemoveItemPopupStyles = styled.div`
  .removeItemPopupWrapper {
    position: absolute;
    top: 5px;
    right: 5px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    height: 64px;
    width: 250px;
    border: 1px solid #eee;
    box-shadow: 2px 2px 35px #0000001a;
    z-index: 10;
    background: #fff;
    border-radius: 6px;
  }

  .removeItemPopupLabel {
    font-size: 18px;
    color: #000;
    margin-left: 18px;
    margin-right: 40px;
    line-height: 64px;
    margin-bottom: 0;
    white-space: nowrap;
    font-weight: 500;
  }

  .removeItemPopupIconWrapper {
    display: flex;
    margin-right: 2px;
  }

  .removeItemPopupLeftIcon {
    width: 40px;
    height: 42px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fff;
    border: 1px solid #fff;
    cursor: pointer;
    border-radius: 4px;
    transition: all 0.4s ease-in-out;
    box-sizing: border-box;

    &:hover {
      background: ${(props) => props.theme["--secondary"]};
      border: 1px solid ${(props) => props.theme["--secondary"]};

      svg path {
        fill: #fff;
      }
    }
  }

  .removeItemPopupRightIcon {
    width: 40px;
    height: 42px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fff;
    border: 1px solid #fff;
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.4s ease-in-out;
    box-sizing: border-box;

    &:hover {
      background: ${(props) => props.theme["--error-red"]};
      border: 1px solid ${(props) => props.theme["--error-red"]};

      svg path {
        fill: #fff;
      }
    }
  }

  .removeItemPopupLeftIconRemove {
    color: #000;
  }

  .removeItemPopupLeftIconOk {
    color: ${(props) => props.theme["--primary"]};
  }
`
