import * as React from "react"
import * as styles from "./styles.scss"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import cx from "classnames"
import { useTranslation } from "react-i18next"

function PackageMediaItem(props: PackageMediaItemProps) {
  const [t] = useTranslation("newCaseFolder")
  const { item, deleteConfirmation, onDeleteClick } = props
  const [deleteInitiated, setDeleteInitiated] = React.useState(false)
  const [isHover, setIsHover] = React.useState(false)

  const handleDeleteClick = () => {
    if (deleteConfirmation) {
      return deleteInitiated ? onDeleteClick() : setDeleteInitiated(true)
    }

    onDeleteClick()
  }

  const handleMouseLeave = () => {
    if (deleteConfirmation) setDeleteInitiated(false)
  }

  const handleMouseOverToHover = () => {
    setIsHover(true)
  }
  const handleMouseLeaveHover = () => {
    setIsHover(false)
  }

  return (
    <div className={cx(styles.mediaItemHolder)}>
    <div
      onMouseLeave={handleMouseLeave}
      className={cx(styles.mediaItem, { [styles.initiated]: deleteInitiated,
        [styles.isLong]: item.name.length >= 40,
        [styles.hoverBgColor]: isHover
      })}
    >
      <div
        className={cx(styles.textContainer, {
        
        })}
      >
        <span>{item.name} </span>
        {item.documentType && (
          <span className={styles.documentName}>{item.documentType}</span>
        )}
      </div>
      {deleteConfirmation && (
        <span className={styles.deleteConfirmation}>{t("clickToConfirm")}</span>
      )}

    </div>
    <div
        onClick={handleDeleteClick}
        className={styles.trashIconContainer}
        data-test-id="delete_package_media_item"
        onMouseEnter={handleMouseOverToHover}
        onMouseLeave={handleMouseLeaveHover}
      >
        <FontAwesomeIcon icon="trash" />
      </div>
    </div>
  )
}

interface PackageMediaItemProps {
  item: Item
  deleteConfirmation?: boolean
  onDeleteClick: () => void
}

interface Item {
  name: string
  documentType?: string
}

export default PackageMediaItem
