import * as React from "react"
import * as styles from "./styles.scss"
import { FieldRenderProps } from "react-final-form"
import { DropdownOption } from "components/DropdownButton"
import OptionDropdown from "components/OptionDropdown"

interface DropdownProps {
  placeholder: string
  onChange?: any
  label?: string
  dropdownOptions: DropdownOption[]
}

class DropdownInput extends React.PureComponent<
  DropdownProps & FieldRenderProps<any, any>
> {
  render() {
    const { dropdownOptions, input } = this.props

    return (
      <OptionDropdown
        onChange={input.onChange}
        options={dropdownOptions}
        selectedLabel={input.value.label}
        togglerClassName={styles.caseWorkerDropdownToggler}
      />
    )
  }
}

export const DropdownInputFormik: React.FC<{
  dropdownOptions: DropdownOption[]
  onChange?: any
  value?: string
  placeholder: string
}> = props => {
  return (
    <OptionDropdown
      onChange={props.onChange}
      options={props.dropdownOptions}
      selectedLabel={props.value}
      togglerClassName={styles.caseWorkerDropdownToggler}
    />
  )
}

export default DropdownInput
