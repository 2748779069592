import * as React from "react"
import * as styles from "./styles.scss"
import { withTranslation, WithTranslation } from "react-i18next"
import Header from "components/Header"

class CaseFolderHeaderRow extends React.Component<WithTranslation> {
  render() {
    return (
      <Header
        title={this.props.t("newCase")}
        className={styles.header}
      />
    )
  }
}

export default withTranslation("newCaseFolder")(CaseFolderHeaderRow)
