import gql from "graphql-tag"
import EDIT_ORDER_PAGE_CASE_FOLDER_FRAGMENT from "graphql/fragments/EditOrderPageCaseFolderFragment"
import DASHBOARD_CASE_FOLDER_FRAGMENT from "graphql/fragments/DashboardCaseFolderFragment"

const GET_EDIT_ORDER_PAGE_CASE_FOLDER = gql`
  query GetEditOrderPageCaseFolder($id: ID!) {
    caseFolder(id: $id) {
      ...DashboardCaseFolderFragment
      ...EditOrderPageCaseFolderFragment
    }
  }
  ${EDIT_ORDER_PAGE_CASE_FOLDER_FRAGMENT}
  ${DASHBOARD_CASE_FOLDER_FRAGMENT}
`

export default GET_EDIT_ORDER_PAGE_CASE_FOLDER
