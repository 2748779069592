import * as React from "react"
import * as styles from "./styles.scss"
import Tab from "./Tab"
import { OpenTab } from ".."
import { useTranslation } from "react-i18next"
import { AppContext } from "App"

function TabRow(props: TabRowProps) {
  const [t] = useTranslation("supportView")
  const { showSupportView } = React.useContext(AppContext)

  return (
    <div
      className={styles.tabRow}
      style={{ display: showSupportView ? "flex" : "none" }}
    >
      <span className={styles.title}>{t("errands")}:</span>
      {props.openTabs.map(order => (
        <Tab tab={order} key={order.orderId} />
      ))}
    </div>
  )
}

interface TabRowProps {
  openTabs: OpenTab[]
}

export default React.memo(TabRow)
