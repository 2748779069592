import * as React from "react"
import { Mutation, ApolloConsumer } from "react-apollo"
import Office from "./Office"
import UPDATE_CURRENT_USER_SESSION from "graphql/mutations/updateCurrentUserSession"
import {
  GetCurrentUserSession_currentUserSession_user_customers,
  UpdateCurrentUserSession,
} from "schema"

class OfficeContainer extends React.Component<OfficeContainerProps> {
  render() {
    return (
      <Mutation<UpdateCurrentUserSession>
        mutation={UPDATE_CURRENT_USER_SESSION}
      >
        {updateCurrentUserSession => (
          <ApolloConsumer>
            {client => (
              <Office
                {...this.props}
                onUpdateCurrentUserSession={updateCurrentUserSession}
                client={client}
              />
            )}
          </ApolloConsumer>
        )}
      </Mutation>
    )
  }
}

interface OfficeContainerProps {
  customer: GetCurrentUserSession_currentUserSession_user_customers
  redirectToAfterClick?: string
  redirectToOrigin?: boolean
  afterClick?: () => void
}

export default OfficeContainer
