import gql from "graphql-tag"

const ORDER_EDITOR_CONTENT_VALUE_FRAGMENT = gql`
  fragment OrderEditorContentValueFragment on EditModelAdvertAreaContentValue {
    id
    visible
    hasContent
    isEditorVisible
    templateAreaId
    rows {
      columns {
        item {
          common {
            blockStyleAreaNameId
            marginLeftRightOffset {
              type
              value
            }
            margin {
              type
              left
              right
              top
              bottom
            }

            ## annonsen size
            size(unit: MILLIMETER) {
              type
              minWidth
              minHeight
              maxWidth
              maxHeight
              fixedWidth
              fixedHeight
            }
          }
          isDemoValue
          image {
            type
            imageId
            imagePublicId
            imageThumbnaill100x80Url
            heightMM
            defaultHeightMM
            isSizeFree
            uploadData
            uploadFilename
            resolution
            uploadPreferedHeightMM
            repository
            uploadDataSource
            uploadComment
          }
          text {
            fontStyle
            textScaleWidthOffset {
              type
              value
            }
            fontWeight
            textAlignment
            xmlText
            isXmlTextReadonly
            fontFamily
            fontSize {
              type
              value
            }
            lineHeight {
              type
              value
            }
            lineHeightEmpty {
              type
              value
            }
            letterSpacing {
              type
              value
            }
            textScaleWidth {
              type
              value
            }
            textNoWrap
          }
          separator {
            type
            imageId
            repository
            widthMM
            heightMM
          }
          fields {
            name
            header
            type
            editor
            value
            __typename
          }
        }
      }
    }
  }
`

export default ORDER_EDITOR_CONTENT_VALUE_FRAGMENT
