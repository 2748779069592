import * as React from "react"
import * as styles from "./styles.scss"
import {
  GetGalleryImageInformation_galleryImages,
  GetGalleryImages_galleryImagesByTags_images,
} from "schema"
import Spinner from "components/Spinner"
import SymbolContainer from "./SymbolContainer"
import cx from "classnames"

class Symbols extends React.PureComponent<SymbolsProp> {
  isFavorite = (symbol: GetGalleryImages_galleryImagesByTags_images) => {
    const { selectedFavoriteTag } = this.props
    const companyGroup = symbol.tagGroups[0]

    if (
      companyGroup &&
      selectedFavoriteTag &&
      companyGroup.tags.indexOf(selectedFavoriteTag) !== -1
    )
      return true
    return false
  }

  render() {
    const { selectedSymbol, isLoading, symbols, onSave, isFEUser } = this.props


    return isLoading ? (
      <Spinner />
    ) : (
      <div className={cx(styles.symbolsContainer, {
        [styles.feView__symbolsContainer]: isFEUser,
      }) }>
        {symbols.map((symbol) => (
          <SymbolContainer
            symbol={symbol}
            onSave={onSave}
            key={symbol.id!}
            isActive={!!selectedSymbol && symbol.id === selectedSymbol.id}
            onFetchSymbolInfo={this.props.onFetchSymbolInfo}
            selectedSymbol={selectedSymbol}
            selectedFavoriteTag={this.props.selectedFavoriteTag}
            onOpenAddToFavoritesModal={this.props.onOpenAddToFavoritesModal}
            isFavorite={this.isFavorite(symbol)}
            isSymbolGallery={this.props.isSymbolGallery}
            isFEUser={isFEUser}
          />
        ))}
      </div>
    )
  }
}

interface SymbolsProp {
  symbols: GetGalleryImages_galleryImagesByTags_images[]
  selectedSymbol: GetGalleryImageInformation_galleryImages
  isLoading: boolean
  selectedFavoriteTag?: string
  onSave: (id: string) => void
  onOpenAddToFavoritesModal: () => void
  isSymbolGallery: boolean
  onFetchSymbolInfo: (
    symbolId: string,
    preventSelect?: boolean,
    symbol?: any
  ) => void;
  isFEUser: boolean
}

export default Symbols
