import React from "react"
import * as styles from "./styles.scss"
import Button from "components/Button"
import cx from "classnames"
import { useTranslation } from "react-i18next"
import { useMutation } from "react-apollo-hooks"
import UPDATE_ORDER_PRICE from "graphql/mutations/updateOrderPrice"
import { UpdateOrderPrice, UpdateOrderPriceVariables } from "schema"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faExclamation } from "@fortawesome/free-solid-svg-icons"
import { SvgQuestionIcon } from "asset/svg/SvgComponents"


const QuestionIcon = () => (
  <div>
    {React.cloneElement(<SvgQuestionIcon />, {
      size: 23
    })}
  </div>
) 

enum Message {
  NONE = "NONE",
  SUCCESS = "successPriceSaved",
  ERROR = "errorPriceSaved",
}

const EditPriceForm: React.FC<{
  isEditPriceVisible: boolean
  setEditPriceVisible: (arg: boolean) => void
  doClose: () => void
  orderId: string | null
  priceContract: number | null
  priceContractOverride: number | null
}> = ({
  isEditPriceVisible,
  setEditPriceVisible,
  doClose,
  orderId,
  priceContract,
  priceContractOverride,
}) => {
  const DELAY = 5
  const [t] = useTranslation()
  const parentDiv = React.useRef<HTMLDivElement | null>(null)
  const [isHover,setHover] = React.useState<boolean>(false)
  const [newPrice, setPrice] = React.useState<any>(
    priceContractOverride ?? null
  )
  const [message, setMessage] = React.useState<Message>(Message.NONE)
  const [isloading, setLoading] = React.useState(false)
  let timer = React.useRef<any>(null)

  React.useEffect(() => {
    const onClickOutside = (event: any) => {
      if (parentDiv.current && !parentDiv.current.contains(event.target)) {
        handleCloseTooltip()
        setMessage(Message.NONE)
      }
    }
    document.addEventListener("mousedown", onClickOutside)
    return () => {
      document.removeEventListener("mousedown", onClickOutside)
      clearTimeout(timer.current)
    }
  }, [parentDiv])

  const mutateOrderPrice = useMutation<
    UpdateOrderPrice,
    UpdateOrderPriceVariables
  >(UPDATE_ORDER_PRICE)

  const handleCloseTooltip = () => {
    doClose()
  }

  const handlePriceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    setPrice(value ? value : null)
  }

  const handlePriceChangeKeyDown = (
    e: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (e.key === "Enter") {
      const value = e.currentTarget.value
      submitChangedOrderPrice(value ? value : null)
    }
  }

  const submitChangedOrderPrice = (value?: any) => {
    setLoading(true)
    mutateOrderPrice({
      variables: {
        input: {
          orderId: orderId!,
          price: {
            priceContractOverrideCustomer: {
              value: newPrice ?? value,
            },
          },
        },
      },
    })
      .then((res) => {
        if (res.errors) {
          return
        }
        if (res.data) {
          setMessage(Message.SUCCESS)
          timer.current = setTimeout(() => {
            setLoading(false)
            doClose()
            setMessage(Message.NONE)
          }, DELAY * 1000)
        }
      })
      .catch((err) => console.log(err))
  }
  return (
    <div
      ref={parentDiv}
      className={cx(styles.editPriceContainer, {
        [styles.isVisible]: isEditPriceVisible,
      })}
    >
      <div className={styles.form}>
        <div className={styles.inputWrapper}>
          <TooltipMessage msg={t("editOrder:designDescribeMessage")} isHover={isHover} />
          <label>
            <span>{t("editOrder:extraCharge")}</span>
            <span onMouseOver={() => setHover(true)} onMouseLeave={() => setHover(false)}>
            <FontAwesomeIcon className={styles.icon} icon={faExclamation} />
            </span>
            
          </label>
          <div className={styles.inputWithCurrency}>
            <input
              value={newPrice}
              onChange={handlePriceChange}
              placeholder={priceContract?.toString()}
              onKeyDown={handlePriceChangeKeyDown}
            />
            <span className={styles.currency}>{t("common:currency")}</span>
          </div>
        </div>
        {message === Message.NONE && (
          <div className={styles.buttonWrapper}>
            <Button
              onClick={submitChangedOrderPrice}
              variant={"secondary"}
              isWorking={isloading}
            >
              {t("common:save")}
            </Button>
          </div>
        )}

        <div
          className={cx(styles.messageWrapper, {
            [styles.success]: message === Message.SUCCESS,
            [styles.error]: message === Message.ERROR,
          })}
        >
          {t(`editOrder:${message}`)}
        </div>
      </div>
      <div
        className={cx(styles.timeLine, {
          [styles.lineRun]:
            message === Message.SUCCESS || message === Message.ERROR,
        })}
        style={{ transition: `${DELAY}s ease-out` }}
      />
      <div className={styles.triangle} />
    </div>
  )
}

const TooltipMessage: React.FC<{
  msg: string
  isHover: boolean
}> = ({ msg, isHover }) => {
  return (
    <div  className={ cx(styles.tooltipMessage,{
      [styles.visible]: isHover
    })}>
      <p>{msg}</p>
      <div className={styles.triangleSM} />
    </div>
  )
}

export default EditPriceForm
