import * as React from "react"
import * as styles from "./styles.scss"
import Tooltip from "components/Tooltip"

function AdminSectionTooltip(props: AdminSectionTooltipProps) {
  return (
    <Tooltip
      id={props.id}
      text={props.text}
      className={styles.tooltip}
      delayShow={1000}
    >
      {props.children}
    </Tooltip>
  )
}

interface AdminSectionTooltipProps {
  children: React.ReactNode
  id: string
  text: string
}

export default AdminSectionTooltip
