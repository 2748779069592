import * as React from "react"
import { useTranslation, withTranslation, WithTranslation } from "react-i18next"

const TextInput: React.FC<TextInputProps & any> = React.forwardRef(
  (props, ref) => {
    const { input, type, placeholder, ...rest } = props

    const [t] = useTranslation()

    return (
      <input
        ref={ref}
        {...input}
        {...rest}
        placeholder={placeholder ? placeholder : t("notSpecified")}
        type={type === undefined ? "text" : type}
      />
    )
  }
)

export interface TextInputProps {
  ref?: React.RefObject<any>
  placeholder?: string
  type?: string
  input?: any
  value?: string
  name?: string
  autoFocus?: boolean
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
}

export default TextInput
