import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons"
import Modal from "components/Modal"
import React from "react"
import Button from "components/Button"
import styled from "styled-components"

const UnpublishModal: React.FC<{
  isOpen: boolean
  onClose: () => void
  unpublishText?: string
  onUnPublishOrder: () => void
  isReactivateButton?: boolean
  reactiveButtonText?: string
  isUnPublishButton?: boolean
  onReactiveOrder?: () => void
}> = (props) => {
  const onUnpublishTrigger = () => {
    props.onUnPublishOrder()
    props.onClose()
  }
  const onReactiveOrderTrigger = () => {
    if(props.onReactiveOrder) props.onReactiveOrder()
    props.onClose()
  }
  return (
    <Modal
      isOpen={props.isOpen}
      onRequestClose={props.onClose}
      isModalInModal={true}
    >
      <UnPublishModalContainer>
        <div className="content">
          <FontAwesomeIcon icon={faQuestionCircle} />
          {props.isUnPublishButton && (
            <p>
              Du är på väg att {props.unpublishText} ordern vilken innebär att
              annonsen kommer att retunera. Vill du verkligen{" "}
              {props.unpublishText} denna order ?
            </p>
          )}
          {props.isReactivateButton && (
            <p>
              Du är på väg att {props.reactiveButtonText?.toLocaleLowerCase()}{" "}
              ordern vilken innebär att annonsen kommer att publisera igen. Vill
              du verkligen {props.reactiveButtonText?.toLocaleLowerCase()} denna
              order ?
            </p>
          )}
        </div>

        <div className="btnContainer">
          <Button onClick={props.onClose}>Avbryt</Button>
          {props.isUnPublishButton && (
            <Button
              color={"primary"}
              variant={"primary"}
              onClick={onUnpublishTrigger}
            >
              {props.unpublishText}
            </Button>
          )}
          {props.isReactivateButton && (
            <Button
              color={"primary"}
              variant={"primary"}
              onClick={onReactiveOrderTrigger}
            >
              {props.reactiveButtonText}
            </Button>
          )}
        </div>
      </UnPublishModalContainer>
    </Modal>
  )
}

const UnPublishModalContainer = styled.div`
  margin-top: 30px;
  .content {
    display: flex;
    align-items: center;
    column-gap: 10px;
    svg {
      color: #1289a7;
      font-size: 40px;
    }
  }
  .btnContainer {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    column-gap: 10px;
  }
`

export default UnpublishModal
