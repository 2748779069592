import * as React from "react"
import Button from "components/Button"
import * as styles from "./styles.scss"
import Row from "./Row"
import AddRowButton from "./AddRowButton"
import { useTranslation } from "react-i18next"

function ColumnEditor(props: ColumnEditorProps) {
  const [t] = useTranslation(["editOrder", "common"])

  return (
    <div className={styles.columnEditorContainer}>
      <div className={styles.title}>{t("createColumnStructure")}</div>
      <div className={styles.columnEditor}>
        {props.rows.map((row, i) => (
          <Row
            key={i}
            row={row}
            index={i}
            showRemoveIcon={props.rows.length > 1}
            onRemove={props.onRemove}
            onChange={props.onChangeColumn}
          />
        ))}
        <AddRowButton onClick={props.onAdd} />
      </div>
      <div className={styles.actionButtons}>
        <Button color="gray" onClick={props.onClose}>
          {t("common:cancel")}
        </Button>
        <Button
          variant="primary"
          className={styles.saveButton}
          onClick={props.onSave}
        >
          {t("common:save")}
        </Button>
      </div>
    </div>
  )
}

interface ColumnEditorProps {
  rows: any[]
  onAdd: () => void
  onRemove: (index: number) => void
  onClose: () => void
  onSave: () => void
  onChangeColumn: (index: number, columns: number) => void
}

export default ColumnEditor
