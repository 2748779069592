import gql from "graphql-tag"

const TOUCH_CASE_FOLDER = gql`
  mutation TouchCaseFolder($id: ID!) {
    touchCaseFolder(id: $id) {
      isError
    }
  }
`

export default TOUCH_CASE_FOLDER
