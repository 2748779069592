import gql from "graphql-tag"

const LOGIN_AS_CUSTOMER = gql`
  mutation LoginAsCustomer($credential: UserCredentialInput!) {
    loginByCredential(credential: $credential) {
      authorizationToken
    }
  }
`

export default LOGIN_AS_CUSTOMER
