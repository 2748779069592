import * as React from "react"
import * as styles from "./styles.scss"
import OrderRow from "./OrderRow"
import { PublishOrderKeyValueMap, ReducerAction, PublishOrder } from ".."
import { IResultOrders } from "customSchema.interface"
import ResultOrders from "./OrderRow/ResultOrders"
import { orderRow } from "./OrderRow/styles.scss"
import { PublishOrders_publishOrders_resultOrders } from "schema"

export const PublishOrderContext = React.createContext(
  {} as PublishOrderContextValue
)

function OrderRows(props: OrderRowsProps) {
  return (
    <div className={styles.orders}>
      {Object.keys(props.orders).map((orderId, index) => {
        const publishOrder = props.orders[orderId]
        return (
          <PublishOrderContext.Provider
            key={orderId}
            value={{
              publishOrder,
              dispatch: props.onDispatch,
              fakeProgressStarted: props.fakeProgressStarted,
              resultOrders:
                props.resultOrders &&
                props.resultOrders.map((r: PublishOrders_publishOrders_resultOrders) =>
                 (r&& r.order.id === orderId) ?  {...r} : {}
                ).filter((rf:PublishOrders_publishOrders_resultOrders) => rf.resultText != null)[0],
            }}
          >
            <>
              <OrderRow
                key={index}
                messageToMedia={publishOrder.messageToMedia}
                orderId={orderId}
              />
              <ResultOrders />
            </>
          </PublishOrderContext.Provider>
        )
      })}
    </div>
  )
}

interface OrderRowsProps {
  orders: PublishOrderKeyValueMap
  fakeProgressStarted: boolean
  onDispatch: React.Dispatch<ReducerAction>
  resultOrders: any
}

interface PublishOrderContextValue {
  publishOrder: PublishOrder
  fakeProgressStarted: boolean
  dispatch: React.Dispatch<ReducerAction>
  resultOrders: PublishOrders_publishOrders_resultOrders
}

export default React.memo(OrderRows)
