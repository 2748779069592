import React from "react"
import Modal from "components/Modal"
import * as styles from "./styles.scss"
import Header from "components/Header"
import ProofSlider from "pages/ReviewPage/ReviewContainer/DocumentTypeCard/ProofColumn/CompareModal/ProofSlider"
import { useTranslation } from "react-i18next"

export const ModalCompareAdverts: React.FC<{
  isOpen: boolean
  onClose: () => void
  orders: any[]
}> = (props) => {
  const { t } = useTranslation("review")

    return (
    <Modal
      isOpen={props.isOpen}
      onRequestClose={props.onClose}
      isCompareModal={true}
      className={styles.compareModal}
      
    >
      <Header title={t("compareOrders")} />
      <ProofSlider orders={props.orders}/>
    </Modal>
  )
}
