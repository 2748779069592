import gql from "graphql-tag"
import CUSTOMER_USER_FRAGMENT from "graphql/fragments/CustomerUserFragment"

const CREATE_CUSTOMER_USER = gql`
  mutation CreateCustomerUser($input: MutationCRUDCustomerUserInput!) {
    createCustomerUser(input: $input) {
      customer {
        ...CustomerUserFragment
      }

      errorCode
      errorReason
      errorStack
      isError
    }
  }
  ${CUSTOMER_USER_FRAGMENT}
`

export default CREATE_CUSTOMER_USER
