import gql from "graphql-tag"

const GET_AVAILIABLE_DOCUMENT_FORMATS_COLLECTIONS = gql`
  query GetAvaiableDocumentFormatCollections($orderId: ID!) {
    orders(ids: [$orderId]) {
      id
      mediaOrder {
        documentFormat {
          id
          documentFormatCollection {
            id
            name
          }
        }
        availableDocumentFormatCollections {
          id
          name
          thumbnailUrl
        }
      }
    }
  }
`

export default GET_AVAILIABLE_DOCUMENT_FORMATS_COLLECTIONS
