import * as React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import * as styles from "./styles.scss"
import cx from "classnames"

const Searchfield: React.FC<SearchfieldProps> = props => {
  const { className, ...rest } = props

  return (
    <div className={cx(styles.searchField, className)}>
      <div className={styles.iconContainer}>
        <FontAwesomeIcon icon="search" />
      </div>
      <input {...rest}  />
    </div>
  )
}

interface SearchfieldProps {
  placeholder?: string
  className?: string
  value?: string
  onChange: (event: React.ChangeEvent) => void
}

export default Searchfield
