import * as React from "react"
import * as styles from "./styles.scss"
import logo from "images/timecut-logo.png"
import { Link } from "react-router-dom"
// import { AppContext } from "App"

const Logo: React.FC = () => {

  return (
    <>
      <Link to="/dashboard" className={styles.logoContainer}>
        <img src={logo} alt="timecut-logo" className={styles.logo} />
      </Link>
    </>
  )
}

export default Logo
