import * as React from "react"
import * as styles from "./styles.scss"
import Modal from "components/Modal"
import { EditModelAdvertAreaContentRepository, GetGalleryImageInformation_galleryImages,
  GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor
} from "schema"
import SelectedSymbolColumn from "./SelectedSymbolColumn"
import { DropdownOption } from "components/DropdownButton"
import SymbolSearchColumnContainer from "./SymbolSearchColumnContainer"
import cx from "classnames"

class ImageSelectorModalInner extends React.PureComponent<
  ImageSelectorModalInnerProps,
  ImageSelectorModalInnerState
> {
  state = {
    tagsInSelect: [] as DropdownOption[],
    tagsToSearchBy: [] as DropdownOption[],
    selectedSymbol: this.props.selectedSymbol,
    searchOnlyColors: false,
  }

  resetSymbols = () => this.setState({ tagsInSelect: [], tagsToSearchBy: [] })

  handleSave = (symbolId?: string, symbol?: any) => {
    const id = symbolId ? symbolId : this.state.selectedSymbol.id!
    const publicId = this.state.selectedSymbol.publicId.toString()
    const imageThumbnaill100x80Url = this.state.selectedSymbol
      .thumbnail100x80Url
    const repository = this.state.selectedSymbol.repository
    // const publicId = symbolPublicId
    //   ? symbolPublicId
    //   : this.state.selectedSymbol.publicId.toString()
    this.props.onRequestClose()
    this.props.onSave(id, publicId, imageThumbnaill100x80Url, repository , this.resetSymbols)
  }

  handleClose = () => {
    this.props.onRequestClose()
    this.resetSymbols()
    this.setState({ selectedSymbol: this.props.selectedSymbol })
  }

  handleTagUpdate = (
    tagsToSearchBy: DropdownOption[],
    tagsInSelect: DropdownOption[]
  ) => {
    // console.log("ImageSelectorModalInner.handleTagUpdate");
    // console.log(tagsToSearchBy);
    this.setState( { tagsToSearchBy, tagsInSelect })
    this.forceUpdate()
  }

  setSelectedSymbol = (symbol: GetGalleryImageInformation_galleryImages) => {
    this.setState({ selectedSymbol: symbol })
  }

  handleToggleOnlyColors = () => {
    this.setState({ searchOnlyColors: !this.state.searchOnlyColors })
  }

  render() {
    return (
      <Modal
        isOpen={this.props.isOpen}
        large={true}
        onRequestClose={this.handleClose}
        className={cx(styles.modalContainer, {
          [styles.feView__modal]: this.props.isFEUser,
        })}
      >
        <SelectedSymbolColumn
          onSave={this.handleSave}
          selectedSymbol={this.state.selectedSymbol}
          isAdstateEnalbe={this.props.isFEUser}
        />
        <SymbolSearchColumnContainer
          {...this.state}
          onUpdateSelectedSymbol={this.setSelectedSymbol}
          onSave={this.handleSave}
          onTagUpdate={this.handleTagUpdate}
          searchOnlyColors={this.state.searchOnlyColors}
          handleToggleOnlyColors={this.handleToggleOnlyColors}
          editor={this.props.editor}
          templateAreaId={this.props.templateAreaId!}
          documentFormatId={this.props.documentFormatId!}
          repository={this.props.repository}
          mediaId={this.props.mediaId}
          isFEUser={this.props.isFEUser}

        />
      </Modal>
    )
  }
}

interface ImageSelectorModalInnerState {
  tagsToSearchBy: DropdownOption[]
  tagsInSelect: DropdownOption[]
  selectedSymbol: GetGalleryImageInformation_galleryImages
  searchOnlyColors: boolean
}

interface ImageSelectorModalInnerProps {
  editor?: GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor
  isOpen: boolean
  selectedSymbol: GetGalleryImageInformation_galleryImages
  onRequestClose: () => void
  onSave: (
    symbolId: string,
    symbolPublicId: string,
    imageThumbnaill100x80Url: string,
    repository: EditModelAdvertAreaContentRepository,
    callback?: () => void
  ) => void
  templateAreaId: string | null
  documentFormatId: string | undefined
  repository?: EditModelAdvertAreaContentRepository | null
  mediaId?: string | null | undefined
  isFEUser: boolean
}

export default ImageSelectorModalInner
