import { LocaleObject } from "yup"

// Based on https://github.com/jquense/yup/blob/2973d0a/src/locale.js
export const mixed: LocaleObject["mixed"] = {
  default: "Fältet är obligatoriskt",
  required: "Fältet är obligatoriskt",
  oneOf: "Lösenordet stämmer inte",
  notOneOf: "Lösenordet stämmer inte",
}

export const string: LocaleObject["string"] = {
  length: "${path} must be exactly ${length} characters",
  min: "Minst antal tecken ${min}",
  max: "Max antal texten ${max}",
  matches: '${path} must match the following: "${regex}"',
  email: "${path} Epostadressen stämmer inte",
  url: "${path} must be a valid URL",
  uuid: "${path} must be a valid UUID",
  trim: "${path} must be a trimmed string",
  lowercase: "${path} must be a lowercase string",
  uppercase: "${path} must be a upper case string",
}

export const number: LocaleObject["number"] = {
  min: "Minst antal tecken ${min}",
  max: "Max antal texten ${max}",
  lessThan: "${path} must be less than ${less}",
  moreThan: "${path} must be greater than ${more}",
  //   notEqual: '${path} must be not equal to ${notEqual}',
  positive: "${path} must be a positive number",
  negative: "${path} must be a negative number",
  integer: "${path} must be an integer",
}

export const date: LocaleObject["date"] = {
  min: "Minst antal tecken ${min}",
  max: "Max antal texten ${max}",
}

export const boolean: LocaleObject["boolean"] = {}

export const object: LocaleObject["object"] = {
  noUnknown: "${path} field has unspecified keys: ${unknown}",
}

export const array: LocaleObject["array"] = {
  min: "${path} field must have at least ${min} items",
  max: "${path} field must have less than or equal to ${max} items",
}
