import gql from "graphql-tag"

const CUSTOMER_USER_FRAGMENT = gql`
  fragment CustomerUserFragment on Customer {
    id
    name
    firstName
    lastName
    inheritSettings {
      contactPhone
    }
    office {
      id
      name
    }
    primaryUser {
      id
      username
      enabled
    }
  }
`

export default CUSTOMER_USER_FRAGMENT
