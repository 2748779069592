import gql from "graphql-tag"

const UPDATE_CUSTOMER_USER = gql`
  mutation UpdateCustomerUser($input: MutationCRUDCustomerUserInput!) {
    updateCustomerUser(input: $input) {
      errorCode
      errorReason
      errorStack
      isError

      customer {
        id
        name
        office {
          id
          name
        }
        primaryUser {
          id
          username
          enabled
        }
      }
    }
  }
`

export default UPDATE_CUSTOMER_USER
