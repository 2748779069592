import * as React from "react"
import { Mutation } from "react-apollo"
import EditCaseFolderForm from "./EditCaseFolderForm"
import UPDATE_CASE_FOLDER from "graphql/mutations/updateCaseFolder"
import {
  GetCaseFolders_findCaseFoldersByCurrentCustomer_caseFolders,
  UpdateCaseFolder,
} from "schema"
import DELETE_CASE_FOLDER from "graphql/mutations/deleteCaseFolder"
import { DataProxy } from "apollo-cache"
import GET_DASHBOARD_CASE_FOLDERS from "graphql/queries/getDashboardCaseFolders"

class EditCaseFolder extends React.PureComponent<
  EditCaseFolderProps,
  EditCaseFolderState
> {


  render() {
    const { selectedCaseFolder, onExitEditMode } = this.props
    return (
      <EditCaseFolderForm
        onCompleted={onExitEditMode}
        selectedCaseFolder={selectedCaseFolder}
        deleteCaseFolder={this.props.deleteCaseFolder}
      />

    )
  }
}

interface EditCaseFolderProps {
  selectedCaseFolder: GetCaseFolders_findCaseFoldersByCurrentCustomer_caseFolders
  onExitEditMode: () => void
  deleteCaseFolder: any
}

interface EditCaseFolderState {
  isLoading: boolean
}

export default EditCaseFolder

      // <Mutation<UpdateCaseFolder>
      //   mutation={UPDATE_CASE_FOLDER}
      //   onCompleted={onExitEditMode}
      // >
      //   {updateCaseFolder => (
      //     <Mutation
      //       mutation={DELETE_CASE_FOLDER}
      //       update={this.updateCacheAfterDelete}
      //       onCompleted={this.toggleLoadingOff}
      //       onError={this.toggleLoadingOff}
      //     >
      //       {(deleteCaseFolder: any) => (

      //       )}
      //     </Mutation>
      //   )}
      // </Mutation>
  // state = { isLoading: false }

  // updateCacheAfterDelete = (cache: DataProxy) => {
  //   const { findCaseFoldersByCurrentCustomer }: any = cache.readQuery({
  //     query: GET_DASHBOARD_CASE_FOLDERS,
  //   })

  //   const caseFolders: GetCaseFolders_findCaseFoldersByCurrentCustomer_caseFolders[] =
  //     findCaseFoldersByCurrentCustomer.caseFolders

  //   cache.writeQuery({
  //     query: GET_DASHBOARD_CASE_FOLDERS,
  //     data: {
  //       findCaseFoldersByCurrentCustomer: {
  //         ...findCaseFoldersByCurrentCustomer,
  //         caseFolders: caseFolders.filter(
  //           (caseFolder) => caseFolder.id !== this.props.selectedCaseFolder.id
  //         ),
  //       },
  //     },
  //   })
  // }

  // toggleLoadingOn = () => this.setState({ isLoading: true })

  // toggleLoadingOff = () => this.setState({ isLoading: false })