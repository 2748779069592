import * as React from "react"
import * as styles from "./styles.scss"
import {
  GetCalendarOrders_calendarOrdersByCurrentCustomer_orders,
  GetCalendarOrders_calendarOrdersByCurrentCustomer,
} from "schema"
import PublishingsByDateTable from "./PublishingsByDateTable"
import Calendar from "components/Calendar"

import { AppContext } from "App"

interface PublishingsProps {
  selectedDate: Date
  highlightDates: Date[] | any
  onCalendarDateChange: (date: Date) => void
  orders: GetCalendarOrders_calendarOrdersByCurrentCustomer_orders[]
  handleWeekSelect: any
  firstDayInWeek: Date | any
  lastDayInWeek: Date | any
  isWeekSelected: boolean
  calendarOrders?: GetCalendarOrders_calendarOrdersByCurrentCustomer[]
  weekNumber: number | undefined
}

const Publishings: React.FC<PublishingsProps> = (props) => {
  const {
    selectedDate,
    orders,
    onCalendarDateChange,
    highlightDates,
    calendarOrders,
    isWeekSelected,
    weekNumber,
  } = props
  const { isShowModeShow } = React.useContext(AppContext)

  const rangeOrder = []?.concat.apply(
    [],
    calendarOrders?.map((item) => item.orders)
  )

  // React.useEffect(() => {
  //   const el = document.querySelectorAll(".react-datepicker__day--in-range") as any
  //   // el?.parentNode?.classList.add("basheer")
  //   el?.parentNode?.classList.remove("weekSelected")
  // }, [isWeekSelected])

  return (
    <div className={styles.publishingsContent}>
      <Calendar
        selectedDate={selectedDate}
        onChange={onCalendarDateChange}
        highlightDates={highlightDates}
        showWeekNumbers={true}
        onWeekSelect={props.handleWeekSelect}
        startDate={props.firstDayInWeek}
        endDate={props.lastDayInWeek}
        weekLabel={"V."}
        inline
      />
      {!isShowModeShow && (
        <PublishingsByDateTable
          orders={isWeekSelected ? rangeOrder : orders}
          selectedWeek={weekNumber}
          selectDay={selectedDate}
          isWeekSelected={isWeekSelected}
        />
      )}
    </div>
  )
}

export default Publishings
