import * as React from "react"
import * as styles from "./styles.scss"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useTranslation } from "react-i18next"

function ErrorHeader() {
  const [t] = useTranslation("supportView")

  return (
    <React.Fragment>
      <FontAwesomeIcon icon="exclamation-triangle" className={styles.icon} />
      <h2 className={styles.header}>{t("errorModalHeader")}</h2>
    </React.Fragment>
  )
}

export default ErrorHeader
