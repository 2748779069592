import * as React from "react"
import * as styles from "./styles.scss"
import { useTranslation } from "react-i18next"
import Select from "components/Select"
import { DropdownOption } from "components/DropdownButton"
import GET_AVAILABLE_TAGS from "graphql/queries/getAvailableTags"
import { useQuery } from "react-apollo-hooks"
import { GetAvailableTags, GetAvailableTagsVariables } from "schema"

function TagSelect(props: TagSelectProps) {
  const [newTag, setNewTag] = React.useState("")
  const [t] = useTranslation("editOrder")

  const { data, loading } = useQuery<GetAvailableTags,GetAvailableTagsVariables>(GET_AVAILABLE_TAGS, {
    variables: {
      input: {
        newTag,
        tags: props.tags.map(tag => tag.value),
        documentFormatId: props.documentFormatId,
        templateAreaId: props.templateAreaId,
        mediaId: props.mediaId,
        tagGroupIds: [
          "00000000-0000-0000-0000-000000000010",
          "00000000-0000-0000-0000-000000000100",
        ],
      },
    },
  })

  const handleSetNewTag = (value: string) => setNewTag(value)

  const getDropdownOptions = () =>
    loading
      ? []
      : data!.galleryImagesTagsByTags.tags
          .filter((tag: string) => tag !== "FÄRG")
          .map((tag: string) => ({ value: tag, label: tag }))

  return (
    <Select
      label={t("search")}
      inputValue={newTag}
      isLoading={loading}
      value={props.tags}
      openMenuOnFocus={true}
      isMulti={true}
      options={getDropdownOptions()}
      onChange={props.onTagUpdate}
      onInputChange={handleSetNewTag}
      className={styles.symbolSelect}
      searchIcon={true}
      menuIsOpen={newTag ?  true  : false}
      onTagUpdate={props.onTagUpdate}
      tags={props.tags}
      id={"symbol-select-input"}
    />
  )
}

interface TagSelectProps {
  onlyColor: boolean
  tags: DropdownOption[]
  onTagUpdate: (
    tagsToSearchBy: DropdownOption[],
    tagsInSelect: DropdownOption[]
  ) => void
  documentFormatId: string
  templateAreaId: string
  mediaId:string|null|undefined
  isFEUser: boolean
}

export default TagSelect
