import * as React from "react"
import * as styles from "./styles.scss"
import { NavLink } from "react-router-dom"

// class NavbarLink extends React.PureComponent<NavbarLinkProps> {
//   render() {
//     const { path, text } = this.props

//     return (
//       <NavLink
//         to={path}
//         className={styles.link}
//         activeClassName={styles.active}
//       >
//         {text}
//       </NavLink>
//     )
//   }
// }

const NavbarLink = ({ path, text }: NavbarLinkProps) => {
  return (
    <NavLink to={path} className={styles.link} activeClassName={styles.active}>
      {text}
    </NavLink>
  )
}

interface NavbarLinkProps {
  path: string
  text: string
}

export default NavbarLink
