import { AppContext } from "App"
import React, { useContext } from "react"
import { GetCaseFolders_findCaseFoldersByCurrentCustomer_caseFolders } from "schema"
import CaseFolderTable from "../CaseFolderTable"
import SelectedCaseFolderView from "../SelectedCaseFolderView"
import ShowMode from "./ShowMode"


const TablesContainer: React.FC<{
  caseFolders: GetCaseFolders_findCaseFoldersByCurrentCustomer_caseFolders[]
  deleteCaseFolder: any
  setSelectedCaseFolderId: (newId: React.ReactText) => void
  setDeletededCaseFolderId: any
  caseFolderId: string
  enterCreateCaseFolder: () => void
  isCreateMode: boolean
  selectedCaseFolder: GetCaseFolders_findCaseFoldersByCurrentCustomer_caseFolders
  inEditMode: boolean
  onExitEditMode: () => void
  exitCreateCaseFolder: () => void
  onBackFromPackages: () => void
}> = (props) => {
  const { toggleShowModeView, isShowModeShow } = React.useContext(AppContext)

 

  return (
    <>
      {isShowModeShow ? (
        <ShowMode   
          deleteCaseFolder={props.deleteCaseFolder}
          selectedCaseFolder={props.selectedCaseFolder}
          inEditMode={props.inEditMode}
          onExitEditMode={props.onExitEditMode}
          isCreateMode={props.isCreateMode}
          exitCreateCaseFolder={props.exitCreateCaseFolder}
          onBackFromPackages={props.onBackFromPackages} 
          enterCreateCaseFolder={props.enterCreateCaseFolder}           
          />
      ) : (
        <>
          <CaseFolderTable
            caseFolders={props.caseFolders}
            deleteCaseFolder={props.deleteCaseFolder}
            onRowClick={props.setSelectedCaseFolderId}
            onDeleteClick={props.setDeletededCaseFolderId}
            selectedCaseFolderId={props.caseFolderId}
            afterDeletedCaseFolder={() => {}}
            openCreateCaseFolderForm={props.enterCreateCaseFolder}
            isCreateMode={props.isCreateMode}
          />
          <SelectedCaseFolderView
            deleteCaseFolder={props.deleteCaseFolder}
            selectedCaseFolder={props.selectedCaseFolder}
            inEditMode={props.inEditMode}
            onExitEditMode={props.onExitEditMode}
            isCreateMode={props.isCreateMode}
            exitCreateCaseFolder={props.exitCreateCaseFolder}
            onBackFromPackages={props.onBackFromPackages}
          />
        </>
      )}
    </>
  )
}

export default TablesContainer
