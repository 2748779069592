import * as React from "react"
import * as styles from "./styles.scss"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useTranslation } from "react-i18next"

function Navbar() {
  const [t] = useTranslation("liveView")

  return (
    <nav className={styles.navbar}>
      <div className={styles.wrapper}>
        <span className={styles.liveView}>
          <FontAwesomeIcon icon={["far", "dot-circle"]} /> {t("liveView")}
        </span>
        <span className={styles.logo}>Logo</span>
        <div className={styles.closeContainer}>
          <div className={styles.closeWrapper}>
            <FontAwesomeIcon icon="times" />
          </div>
        </div>
      </div>
    </nav>
  )
}

export default Navbar
