import * as React from "react"
import * as styles from "./styles.scss"
import * as ReactModal from "react-modal"
import * as cx from "classnames"
// import CloseIcon from "./CloseIcon"
import ModalLoadingOverlay from "./ModalLoadingOverlay"
import Button from "components/Button"
import { useTranslation } from "react-i18next"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useHistory } from "react-router-dom"

ReactModal.setAppElement("#root")

class Modal extends React.PureComponent<ModalProps> {
  static defaultProps = {
    shouldCloseOnOverlayClick: true,
    showCloseIcon: true,
    loadingText: "",
  }

  render() {
    const {
      isOpen,
      onRequestClose,
      children,
      className,
      shouldCloseOnOverlayClick,
      showCloseIcon,
      isLoading,
      loadingText,
      large,
      isModalInModal,
      openModalFromUrl,
      onClickBack,
      isCompareModal,
      isMemoryroom,
      modalStyles,
    } = this.props

    return (
      <ReactModal
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
        closeTimeoutMS={300}
        overlayClassName={cx(styles.overlay, {
          [styles.isPopUp]: this.props.isPopUp,
        })}
        className={cx(styles.modal, className, {
          [styles.large]: large,
          [styles.marginTop]: isModalInModal,
          [styles.CompareModal]: isCompareModal,
          [styles.isMemoryroom]: isMemoryroom,
          [styles.isPopUp]: this.props.isPopUp,
          [styles.feView__modal]: this.props.isFEUser
        })}
        style={modalStyles}
      >
        {isLoading && <ModalLoadingOverlay text={loadingText} />}
        {showCloseIcon && <CloseIcon onClick={onRequestClose} />}
        {openModalFromUrl && (
          <BackButton onClick={onClickBack} onRequestClose={onRequestClose} isFEUser={this.props.isFEUser!} />
        )}
        {children}
      </ReactModal>
    )
  }
}
// [data-testid="new-user-last-name-field
const CloseIcon: React.FC<{
  onClick: (props?: any) => void
}> = (props) => {
  return (
    <button id="close-news-button" className={styles.closeContainer} onClick={props.onClick}>
      <FontAwesomeIcon icon="times" className={styles.closeIcon} />
    </button>
  )
}

const BackButton: React.FC<{
  onClick?: () => void
  onRequestClose?: (arg: any) => void
  isFEUser: boolean
}> = (props) => {
  const { t } = useTranslation(["common"])
  const history = useHistory()

  const onClickBackDefault = () => {
    if (props.onRequestClose) {
      props.onRequestClose(undefined)
    }
    history.goBack()
  }

  return (
    <div className={styles.backBtnContainer}>
      <Button
        variant={props.isFEUser ?  "feView"  :"secondary"}
        color="primary"
        type="submit"
        disabled={false}
        isWorking={false}
        onClick={props.onClick ?? onClickBackDefault}
      >
        {t("common:back")}
      </Button>
    </div>
  )
}

interface ModalProps {
  isOpen: boolean
  large?: boolean
  shouldCloseOnOverlayClick: boolean
  className?: string
  openModalFromUrl?: boolean
  onRequestClose: (
    event: React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent
  ) => void
  showCloseIcon?: boolean
  isLoading?: boolean
  loadingText?: string
  isModalInModal?: boolean
  onClickBack?: () => void
  isCompareModal?: boolean
  isMemoryroom?: boolean
  modalStyles?: ReactModal.Styles
  isPopUp?: boolean;
  isFEUser?: boolean
}

export default Modal
