import React from 'react'
import styles from "../styles.scss"
import { motion } from 'framer-motion'
import { Istate } from '..'
import { ImageSelectDialogFile } from 'customSchema.interface'
import Button from 'components/Button'


const ImagePreview:React.FC<{
    state: Istate
    imageDetails: ImageSelectDialogFile | null
    handleChangeImageHeight: (e?: any) => void
    onSaveOriginalImage: () => void
    canvas: any
}> = ({
    state,
    imageDetails,
    handleChangeImageHeight,
    onSaveOriginalImage,
    canvas
}) => {
  return (
    
    <motion.div
    initial={{
      scale: 0,
    }}
    animate={{ scale: !state.isEditMode ? 1 : 0 }}
  >
    <div className={styles.ImagePreview}>
      <img src={imageDetails?.preview} alt="timecut" />
    </div>
    <div className={styles.inputsWrapper}>
      <div className={styles.inputHolder}>
        {/* <span className={styles.title}>Höjd</span> */}
        <input
          type="number"
          placeholder="höjd"
          onChange={handleChangeImageHeight}
          value={state.imageHeight! || undefined}
        />
        <span className={styles.unit}>mm</span>
      </div>
      <Button
        className={styles.loadUp}
        onClick={onSaveOriginalImage}
        variant={"primary"}
        color={"primary"}
      >
        Ladda upp
      </Button>
    </div>
  </motion.div>
  )
}

export default ImagePreview