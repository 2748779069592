import React from "react"
import { getClientPackageVersion } from "clientInfo"
import styled from "styled-components"

const VersionBox: React.FC = () => {
  const [version] = React.useState(getClientPackageVersion())

  return (
    <DivContainer>
      <div className={"container"}>
        <div className={"box"}>
          <h6>App version: {version}</h6>
        </div>
      </div>
    </DivContainer>
  )
}



export default VersionBox

const DivContainer = styled.div`
  .container {
    width: 95%;
    display: flex;
    justify-content: flex-end;
    margin: 0 auto;
    position: fixed;
    right: 20px;
    bottom: 0;
    z-index: -2;
  }

  .box {
    background-color: rgb(255, 255, 255);
    max-width: 10%;
    height: 30px;
    box-shadow: -3px -3px 5px 0px rgba(225, 224, 224, 0.75);
    -webkit-box-shadow: -3px -3px 5px 0px rgba(225, 224, 224, 0.75);
    -moz-box-shadow: -3px -3px 5px 0px rgba(225, 224, 224, 0.75);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
  }
`
