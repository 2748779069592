import React from "react"
import { debounce } from "lodash"

export const useCustomDebounce = (callback: () => void) => {
  const ref = React.useRef<any>()

  React.useEffect(() => {
    ref.current = callback
  }, [callback])

  const debouncedCallback = React.useMemo(() => {
    const func = () => {
      ref.current?.()
    }

    return debounce(func, 1000)
  }, [])

  return debouncedCallback
}

