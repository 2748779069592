import * as React from "react"
import { AppContext } from "App"
import { useTranslation } from "react-i18next"

function SupportViewToggler() {
  const [t] = useTranslation("supportView")
  const { toggleShowSupportView, showSupportView } = React.useContext(
    AppContext
  )

  return (
    <li onClick={toggleShowSupportView}>
      {showSupportView ? t("changeToUserView") : t("changeToSupportView")}
    </li>
  )
}

export default SupportViewToggler
