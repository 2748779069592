import * as React from "react"
import * as styles from "./styles.scss"
import { useTranslation } from "react-i18next"
import ConfirmationModal from "components/ConfirmationModal"
import { useMutation } from "react-apollo-hooks"
import DELETE_PACKAGE from "graphql/mutations/deletePackage"
import GET_PACKAGES from "graphql/queries/getPackages"

function DeletePackageButton(props: DeletePackageButtonProps) {
  const [t] = useTranslation(["agencySettings", "common"])
  const [modalIsOpen, setModalIsOpen] = React.useState(false)
  const deletePackage = useMutation(DELETE_PACKAGE, {
    variables: { input: { orderInitiatorPackageIds: [props.id] } },
    refetchQueries: [{ query: GET_PACKAGES }],
  })

  const openModal = () => setModalIsOpen(true)

  const closeModal = () => setModalIsOpen(false)

  return (
    <React.Fragment>
      <div className={styles.deletePackageButton}>
        <span onClick={openModal}>{t("deletePackage")}</span>
      </div>
      <ConfirmationModal
        title={t("deletePackage")}
        text={t("deletePackageModalText")}
        okButtonText={t("common:delete")}
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        onOKClick={deletePackage}
      />
    </React.Fragment>
  )
}

interface DeletePackageButtonProps {
  id: string
}

export default DeletePackageButton
