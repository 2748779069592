import gql from "graphql-tag"

const DELETE_TAG = gql`
  mutation DeleteTag(
    $input: MutationDeleteGalleryImageTagGroupTagsOnAllImageItemsInput!
  ) {
    deleteGalleryImageTagGroupTagsOnAllImageItems(input: $input) {
      errorReason
    }
  }
`

export default DELETE_TAG
