import * as React from "react"
import * as styles from "./styles.scss"
import { GetAdminCustomers_customerTreesByFreeSearch_companies_offices } from "schema"
import Customer from "./Customer"

function Office(props: OfficeProps) {
  return (
    <React.Fragment>
      <b>{props.office.name}</b>
      <div className={styles.customers}>
        {props.office.customers.map(customer => (
          <Customer customer={customer} key={customer.idString!} />
        ))}
      </div>
    </React.Fragment>
  )
}

interface OfficeProps {
  office: GetAdminCustomers_customerTreesByFreeSearch_companies_offices
}

export default Office
