import * as React from "react"
import * as styles from "./styles.scss"
import PriceContainer from "./PriceContainer"
import Button from "components/Button"
import { useTranslation } from "react-i18next"
import { Form } from "react-final-form"
import { AppContext } from "App"
import MUTATION_UPDATE_CUSTOMER_CONTRACT from "graphql/mutations/updateCustomerContract"
import {
  MutationUpdateCustomerContract,
  MutationUpdateCustomerContractVariables,
  GetCurrentCustomerColleagues_currentCustomer_colleagues,
  GetCurrentCustomerAllProducts,
  CustomerContractProductEndCustomerInput,
} from "schema"
import Spinner from "components/Spinner"
import { useQuery, useApolloClient } from "react-apollo-hooks"
import GET_CURENT_CUSTOMER_ALL_PRODUCTS from "graphql/queries/getCurentCustomerAllProducts"
import ErrorMessage from "components/ErrorMessage"
import { useMutation } from "@apollo/react-hooks"
function PricesTab() {
  const { currentCustomer } = React.useContext(AppContext)

  const { loading, data, error, refetch } = useQuery<GetCurrentCustomerAllProducts>(
    GET_CURENT_CUSTOMER_ALL_PRODUCTS
  )
  const client = useApolloClient()
  const [runMutation, mutationOptions] = useMutation<
    MutationUpdateCustomerContract,
    MutationUpdateCustomerContractVariables
  >(MUTATION_UPDATE_CUSTOMER_CONTRACT, { client })

  const onSubmit = (values: any[]) => {
    const endCustomerProducts: CustomerContractProductEndCustomerInput[] = Object.keys(
      values
    ).map(key => {
      return {
        productId: key,
        price: Number(values[key]),
      }
    })
    const customerContractId =
      data?.currentCustomer?.office.company.customerContract.id ?? ""

    runMutation({
      variables: {
        input: {
          customerContractId,
          endCustomerProducts,
        },
      },
    })
  }

  React.useEffect(() => {
    refetch()
  }, [])

  return (
    <div className={styles.container}>
      {loading || mutationOptions.loading ? (
        <div className={styles.spinner}>
          <Spinner />
        </div>
      ) : null}

      {error ? (
        <div>
          <ErrorMessage message={error.message} />
        </div>
      ) : null}

      {data !== undefined &&
      data.currentCustomer !== undefined &&
      data.currentCustomer !== null ? (
        <ShowForm currentCustomer={data.currentCustomer} onSubmit={onSubmit} />
      ) : null}
    </div>
  )
}

const ShowForm = ({
  currentCustomer,
  onSubmit,
}: {
  currentCustomer: GetCurrentCustomerAllProducts["currentCustomer"]
  onSubmit: any
}) => {
  if (currentCustomer === null) {
    return null
  }
  const products =
    currentCustomer.office.company.customerContract.customerProducts
  const initialValues = products.filter(q=>q.contractCustomer.isStored).reduce(
    (acc, product) => ({
      ...acc,
      [product.product.id!]: product.contractEndCustomer.price,
    }),
    {}
  )
  const [t] = useTranslation()

  return (
    <div>
      <Form
        onSubmit={values => {
          onSubmit(values)
        }}
        initialValues={initialValues}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit} >
            <div className={styles.priceForm}>
            {products.map(product => (
              <PriceContainer
                key={product.product.id!}
                label={product.product.name}
                name={product.product.id!}
                customerPrice={product.contractCustomer.price}
                customerEndPrice={product.contractEndCustomer.price}
                isStoredPrice={product.contractCustomer.isStored}
                isStoredEndPrice={product.contractEndCustomer.isStored}
              />
            ))}
          </div>
            <Button
              variant="primary"
              color="primary"
              type="submit"
              className={styles.submitButton}
            >
              {t("save")}
            </Button>
          </form>
        )}
      />
    </div>
  )
}

export default PricesTab
