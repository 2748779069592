import gql from "graphql-tag"

const MUTATION_UPDATE_CUSTOMER_CONTRACT = gql`
  mutation MutationUpdateCustomerContract(
    $input: MutationUpdateCustomerContractInput!
  ) {
    updateCustomerContract(input: $input) {
      customerContract {
        name
        customerProducts {
          product {
            name
          }
          contractEndCustomer {
            price
          }
        }
      }
    }
  }
`

export default MUTATION_UPDATE_CUSTOMER_CONTRACT
