import * as React from "react"
import Button from "components/Button"
import * as styles from "./styles.scss"
import { GetCurrentUserSession_currentUserSession_user_customers } from "schema"
import { MutationFn } from "react-apollo"
import { withRouter, RouteComponentProps } from "react-router-dom"
import ApolloClient from "apollo-client"
import useQueriesToRefetchOnTokenChange from "components/SupportView/useQueriesToRefetchOnTokenChange"
import { SessionStorageKeys } from "environmentUtils"
import * as cx from "classnames"

class Office extends React.PureComponent<OfficeProps & RouteComponentProps> {
  handleClick = () =>
    this.props
      .onUpdateCurrentUserSession({
        variables: {
          input: {
            customerId: this.props.customer.id,
          },
        },
      })
      .then(this.handleUpdateComplete)

  redirect = () => {
    const { redirectToOrigin, redirectToAfterClick, history } = this.props

    if (this.props.afterClick) this.props.afterClick()
    if (redirectToOrigin) return history.push(this.props.location.pathname)
    if (redirectToAfterClick) return history.push(redirectToAfterClick)
  }

  setToken = (token: string) =>
    sessionStorage.setItem(SessionStorageKeys.AUTH_TOKEN, token)

  handleUpdateComplete = ({ data: { updateCurrentUserSession } }: any) => {
    this.setToken(updateCurrentUserSession.userSession.authorizationToken)

    const refetchQueries = useQueriesToRefetchOnTokenChange(this.props.client)
    refetchQueries().then(this.redirect)
  }

  render() {
    return (
      <Button
        onClick={this.handleClick}
        className={cx(styles.office, "selectOfficeOption")}
      >
        {this.props.customer.office.name}
      </Button>
    )
  }
}

interface OfficeProps {
  customer: GetCurrentUserSession_currentUserSession_user_customers
  redirectToOrigin?: boolean
  redirectToAfterClick?: string
  afterClick?: () => void
  onUpdateCurrentUserSession: MutationFn
  client: ApolloClient<any>
}

export default withRouter(Office)
