import * as React from "react"
import * as styles from "./styles.scss"
import { GetAdminCustomers_customerTreesByFreeSearch_companies_offices_customers } from "schema"
import { SupportViewContext } from "components/SupportView"

function Customer(props: CustomerProps) {
  const { openTab } = React.useContext(SupportViewContext)
  // const { idString, primaryUser, tempSessionToken, name } = props.customer

  const handleClick = () => {
    openTab(
      {
        customerId: props.customer.idString,
        caseFolderId: "",
        customerFriendlyId: "",
        documentId: "",
        orderId: "",
        type: "customer",
        // customerId: idString!,
        // type: "customer",
        // orderId: idString!,
        // customerFriendlyId: props.customer.internalId.toString(),
      },
      props.customer.tempSessionToken
    )
  }

  return (
    <div className={styles.customer}>
      <span>{name}</span>
      {props.customer.primaryUser && (
        <span onClick={handleClick} className={styles.primaryUser}>
          {props.customer.primaryUser.username}
        </span>
      )}
    </div>
  )
}

interface CustomerProps {
  customer: GetAdminCustomers_customerTreesByFreeSearch_companies_offices_customers
}

export default Customer
