import * as React from "react"
import { Switch, Redirect, Route } from "react-router-dom"
import NewCaseFolderPage from "pages/NewCaseFolderPage"

const NewCaseFolderRoutes: React.FC = () => {
  return (
    <Switch>
      <Route
        path="/newCaseFolder/c/:caseFolderId"
        component={NewCaseFolderPage}
      />
      <Route path="/newCaseFolder" component={NewCaseFolderPage} exact={true} />
      <Redirect to="/newCaseFolder" />
    </Switch>
  )
}

export default NewCaseFolderRoutes
