import * as React from "react"
import * as styles from "./styles.scss"

class SelectedSymbol extends React.PureComponent<SelectedSymbolProps> {
  render() {
    return (
      <div className={styles.selectedSymbolContainer}>
        <div style={{backgroundImage: `url(${this.props.symbolUrl})` }} ></div>
      </div>
    )
  }
}

interface SelectedSymbolProps {
  symbolUrl?: string
}

export default SelectedSymbol
