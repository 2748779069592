import gql from "graphql-tag"

const GET_PRODUCTS = gql`
  query getProducts {
    currentCustomer {
      office {
        company {
          customerContract {
            customerProducts {
              product {
                id
                name
              }
            }
          }
        }
      }
    }
  }
`

export default GET_PRODUCTS
