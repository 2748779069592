import * as React from "react"
import GET_AVAILABLE_PUBLISH_TIMES from "graphql/queries/getAvailablePublishTimes"
import InnerPublishTimeSelector from "./InnerPublishTimeSelector"
import UPDATE_PUBLISH_TIME from "graphql/mutations/updatePublishTime"
import {
  GetAvailablePublishTimes,
  GetOrderById_orders,
  UpdatePublishTime,
  UpdatePublishTimeVariables,
  GetAvailablePublishTimesVariables,
} from "schema"
import GET_REVIEW_CASE_FOLDER from "graphql/queries/getReviewCaseFolder"
import { useRouteMatch } from "react-router"
import { useApolloClient, useMutation, useQuery } from "react-apollo-hooks"

const PublishTimeSelector: React.FC<PublishTimeSelectorProps> = (props) => {
  const match = useRouteMatch<{ caseFolderId: any }>()
  // const selectedOrder: GetOrderById_orders = props.selectedOrder
  // const publishTime = selectedOrder.mediaOrder!.publishTimes[0]
  const client = useApolloClient()
  const { loading, error, data } = useQuery<
    GetAvailablePublishTimes,
    GetAvailablePublishTimesVariables
  >(GET_AVAILABLE_PUBLISH_TIMES, {
    variables: { orderId: props.selectedOrderId },
    client,
  })
  const updatePublishTime = useMutation<
    UpdatePublishTime,
    UpdatePublishTimeVariables
  >(UPDATE_PUBLISH_TIME, {
    client,
    refetchQueries: [
      {
        query: GET_REVIEW_CASE_FOLDER,
        variables: { id: match?.params.caseFolderId },
      },
    ],
  })

  if (error) {
    return <span>{error.message}</span>
  }

  return (
    <InnerPublishTimeSelector
      onUpdatePublishTime={updatePublishTime as any}
      orderId={props.selectedOrderId}
      disabled={props.disabled}
      selectedDate={
        props.publishTime && props.publishTime.time
          ? new Date(props.publishTime.time)
          : undefined
      }
      includeDates={data?.mediaPublishCalendarDays ?? []}
      className={props.className}
    />
  )
}

interface PublishTimeSelectorProps {
  selectedOrderId: any
  publishTime?: any
  disabled?: boolean
  className?: string
}

export default PublishTimeSelector
