import { useState, useEffect } from "react"
import { useQuery, useMutation } from "react-apollo-hooks"
import GET_ADMIN_ACTIONS from "graphql/queries/getAdminActions"
import INVOKE_ACTION from "graphql/mutations/invokeAction"
import {
  InvokeAction_invokeAction,
  InvokeAction_invokeAction_result,
  GetAdminActions_adminActions,
  GetAdminActions,
  GetAdminActionsVariables,
  InvokeActionVariables,
  InvokeAction,
} from "schema"

function useInvokeAction(props: InvokeActionProps): ReturnObject {
  const [availableActions, setAvailableActions] = useState(undefined)
  const [resultModalIsOpen, setResultModalIsOpen] = useState(false)
  const [resultModalText, setResultModalText] = useState("")
  const [isErrorModal, setIsErrorModal] = useState(false)
  const { data, error } = useQuery<GetAdminActions, GetAdminActionsVariables>(
    GET_ADMIN_ACTIONS,
    { variables: props }
  )
  const invokeAction = useMutation<InvokeAction, InvokeActionVariables>(
    INVOKE_ACTION
  )

  if (error) console.error(error.stack)

  // const getInvokeActionTypeObject = (type: InvokeActionType, id: string) => {
  //   switch (type) {
  //     case "CUSTOMER":
  //       return { customerId: id }
  //     case "DOCUMENT":
  //       return { documentId: id }
  //     default:
  //       return { orderId: id }
  //   }
  // }

  // const getInvokeActionVariables = (dispatchObj: InvokeActionDispatchObj) => ({
  //   input: {
  //     action: {
  //       actionId: dispatchObj.actionId,
  //       ...getInvokeActionTypeObject(dispatchObj.type, dispatchObj.id),
  //     },
  //   },
  // })

  const openModal = (modalText: string) => {
    setResultModalIsOpen(true)
    setResultModalText(modalText)
  }

  const setErrorModal = (modalText: string) => {
    openModal(modalText)
    setIsErrorModal(true)
  }

  const closeModal = () => {
    setResultModalIsOpen(false)
    setIsErrorModal(false)
  }

  // const handleInvokeActionResponse = (
  //   result: InvokeAction_invokeAction_result
  // ) => {
  //   const text = result.text
  //   switch (result.responseAction) {
  //     case "TEXT_MODAL":
  //       return openModal(text)
  //     case "ERROR_MODAL":
  //       return setErrorModal(text)
  //     default:
  //       return
  //   }
  // }

  const handleDispatch = (
    // dispatchObj: InvokeActionDispatchObj,
    variables: InvokeActionVariables,
    callback?: () => void
  ) =>
    invokeAction({
      variables,
      // variables: getInvokeActionVariables(dispatchObj) as any,
    })
      .then(({ data: response }) => {
        if (!response) {
          setErrorModal("No data was returned in useInvokeAction.")
          return
        }
        if (!response.invokeAction) {
          setErrorModal("No data was returned in useInvokeAction.")
          return
        }
        // if (!response || !response.invokeAction)
        //   return console.error("No data was returned in useInvokeAction.")

        // const result = response.invokeAction

        if (callback) {
          callback()
        }
        
        const text = response.invokeAction.result.text
        const action = response.invokeAction.result.responseAction
        if (action === "TEXT_MODAL") {
          openModal(text)
        } else if (action === "ERROR_MODAL") {
          setErrorModal(text)
        }
        // handleInvokeActionResponse(response.invokeAction.result)
      })
      .catch(err => {
        setErrorModal(err.toString())
      })

  useEffect(() => {
    if (!error && data && data.adminActions)
      setAvailableActions(data.adminActions as any)
  }, [data])

  return {
    availableActions,
    dispatch: handleDispatch,
    resultModalIsOpen,
    resultModalText,
    isErrorModal,
    onCloseModal: closeModal,
  }
}

interface InvokeActionProps {
  orderId?: string
  documentId?: string
  customerId?: string
}

interface ReturnObject {
  availableActions?: GetAdminActions_adminActions[]
  resultModalIsOpen: boolean
  resultModalText: string
  isErrorModal: boolean
  dispatch: (
    variables: InvokeActionVariables,
    // dispatchObj: InvokeActionDispatchObj,
    callback?: () => void
  ) => void
  onCloseModal: () => void
}

// export interface InvokeActionDispatchObj {
//   type: InvokeActionType
//   payload: InvokeActionVariables
// actionId: string
// id: string
// }

type InvokeActionType = "ORDER" | "DOCUMENT" | "CUSTOMER"

export default useInvokeAction
