import * as React from "react"
// import posed from "react-pose1"
import { motion } from "framer-motion"
import * as styles from "./styles.scss"
import { useTranslation } from "react-i18next"

// const PosedContainer = posed.div({
//   enter: { height: "auto" },
//   exit: { height: 0 },
// })

const variants = {
  enter: { height: "auto" },
  exit: { height: 0 },
}

const AdvancedContainer: React.FC<AdvancedContainerProps> = props => {
  const [t] = useTranslation("supportView")
  const { content: Content, isExpanded } = props

  return (
    <motion.div
      initial="enter"
      variants={variants}
      animate={isExpanded ? "enter" : "exit"}
      className={styles.advancedContent}
    >
      <fieldset className={styles.fieldset}>
        <legend className={styles.legend}>{t("advanced")}</legend>
        <Content />
      </fieldset>
    </motion.div>
  )
}

interface AdvancedContainerProps {
  isExpanded: boolean
  content: React.ComponentClass<any> | React.FunctionComponent
}

export default AdvancedContainer
