import gql from "graphql-tag"
import SETTINGS_FRAGMENT from "graphql/fragments/SettingsFragment"
import CUSTOMER_USER_FRAGMENT from "graphql/fragments/CustomerUserFragment"

const GET_CURRENT_CUSTOMER = gql`
  query GetCurrentCustomer {
    currentCustomer {
      id
      colleagues {
        ...CustomerUserFragment
      }
      settings {
        ...SettingsFragment
      }
      inheritSettings {
        ...SettingsFragment
      }        
      office {
        id
        settings {
          ...SettingsFragment
        }
        inheritSettings {
          ...SettingsFragment
        }          
        company {
          id
          settings {
            emailInvoicing
          }
          inheritSettings {
            emailInvoicing
            emailOffice
          }
          customerContract {
            id
            customerProducts {
              product {
                id
                name
              }
              contractCustomer {
                price
              }
              contractEndCustomer {
                price
              }
            }
          }
          offices {
            id
            name
          }
        }
      }
    }
  }
  ${SETTINGS_FRAGMENT}
  ${CUSTOMER_USER_FRAGMENT}
`

export default GET_CURRENT_CUSTOMER
