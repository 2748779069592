import * as React from "react"
import * as styles from "./styles.scss"
import Header from "components/Header"
import InfoItem from "./InfoItem"
import ParalellPublishing from "./ParalellPublishing"
import Card from "components/Card"
import Button from "components/Button"
import { useTranslation } from "react-i18next"

function OrderInfo() {
  const [t] = useTranslation(["liveView", "common"])
  const renderButton = () => (
    <Button variant="primary">{t("confirmOrder")}</Button>
  )

  return (
    <Card
      className={styles.orderInfo}
      renderButton={renderButton}
      contentClassName={styles.cardContent}
    >
      <span>{t("shownRightNow")}:</span>
      <Header title="Media nummer 1" />
      <div className={styles.infoItems}>
        <InfoItem label={t("common:publishDate")} text="2018-02-31" />
        <InfoItem label={t("common:size")} text="52mm x 42mm" />
      </div>
      <ParalellPublishing />
    </Card>
  )
}

export default OrderInfo
