import * as React from "react"
import * as styles from "./styles.scss"
import Toggler from "components/Toggler"
import { AgencySettingsContext } from "pages/AgencySettings"
import { useMutation } from "react-apollo-hooks"
import UPDATE_AGENCY_SETTINGS from "graphql/mutations/updateAgencySettings"

function TogglerField(props: TogglerFieldProps) {
  const { settings, changeSetting } = React.useContext(AgencySettingsContext)
  const updateSetting = useMutation(UPDATE_AGENCY_SETTINGS)
  const setting = settings.office[props.name]

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    let isDefaultProofCaseFeeAddOnOrders = event.target.checked
    let isDefaultProofCaseFeeAddOnFirstOrder = event.target.checked
    let newSetting:any = {}

    if (props.name === "isDefaultProofCaseFeeAddOnOrders") {
      isDefaultProofCaseFeeAddOnOrders = event.target.checked
      isDefaultProofCaseFeeAddOnFirstOrder = false
      newSetting = {
        ...settings.office,
        isDefaultProofCaseFeeAddOnOrders,
        isDefaultProofCaseFeeAddOnFirstOrder,
      }

    } else if (props.name === "isDefaultProofCaseFeeAddOnFirstOrder") {
      isDefaultProofCaseFeeAddOnOrders = false
      isDefaultProofCaseFeeAddOnFirstOrder = event.target.checked
      newSetting = {
        ...settings.office,
        isDefaultProofCaseFeeAddOnOrders,
        isDefaultProofCaseFeeAddOnFirstOrder,
      }
    }else {
       newSetting = {
        ...settings.office,
        [event.target.name]: event.target.checked,
      }
    }


    updateSetting({
      variables: {
        input: {
          customerScopeSettings: [
            {
              customerScope: "OFFICE",
              settings: {
                ...newSetting
              },
            },
          ],
        },
      },
    }).then(updateState)
  }

  function updateState(res: any) {
    const resData =
      res.data.updateCurrentCustomerSettings.customer.office.inheritSettings
    changeSetting(props.name, "office", resData[props.name])
  }

  return (
    <div className={styles.togglerField}>
      <Toggler
        name={props.name}
        checked={setting === null ? false : setting}
        onChange={handleChange}
      />
      <span dangerouslySetInnerHTML={{ __html: props.label }}></span>
    </div>
  )
}

interface TogglerFieldProps {
  label: any
  name: string
}

export default TogglerField
