import gql from "graphql-tag"

const GET_AVAILABLE_TAGS = gql`
  query GetAvailableTags($input: GalleryImageAvailableTagsInput!) {
    galleryImagesTagsByTags(input: $input) {
      tags
    }
  }
`

export default GET_AVAILABLE_TAGS
