import * as React from "react"
import * as styles from "./styles.scss"
import {
  GetEditOrderPageCaseFolder_caseFolder_editTabs_orders,
  GetOrderByIdLiveStream_orders,
  MessagingOrderInfo,
  MessagingOrderInfoVariables,
  UpdateOrderContent,
  UpdateOrderContentVariables,
  UpdatePublishTime,
  UpdatePublishTimeVariables,
} from "schema"
import { TopSidebarCaseInfoBlock } from "../OrderEditoryAdvert/TopSidebarCaseInfoBlock"
import Button from "components/Button"
import { useTranslation } from "react-i18next"
import { useFormik } from "formik"
import { object, string } from "yup"
import {
  FieldError,
  FieldSuccess,
  FieldWrapperFormik,
} from "components/FieldWrapper"
import { useApolloClient, useMutation } from "react-apollo-hooks"
import UPDATE_PUBLISH_TIME from "graphql/mutations/updatePublishTime"
import Calendar from "components/Calendar"
import { RcHourFieldFormik } from "components/TimeField"
import GET_ORDER_BY_ID_LIVE_STREAM from "graphql/queries/getOrderByIdLivestream"
import moment from "moment"
import styled from "styled-components"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faCheckCircle,
  faChevronDown,
  faChevronUp,
  faTimes,
  faCopy,
  faQrcode,
  faXRay,
  faTimesCircle,
  faQuestionCircle,
  faExclamationTriangle,
} from "@fortawesome/free-solid-svg-icons"
import { motion, Variants } from "framer-motion"
import { SubMenu } from "./SubMenu"
import MESSAGING_ORDER_INFO from "graphql/mutations/messagingOrderInfo"
import UPDATE_ORDER_CONTENT from "graphql/mutations/updateOrderContentValues"
import { DropEvent, FileRejection, useDropzone } from "react-dropzone"
import { produce } from "immer"
import cx from "classnames"
import Status from "components/Status"
import LockerBox from "components/LockerBox"
import CopyValue from "components/CopyValue"
import ParallelPublishing from "pages/ReviewPage/ReviewContainer/DocumentTypeCard/OrderInformation/ParallelPublishing"

export interface SelectedPdf {
  fileFormInput: any
  name: any
  type: any
  lastModified: any
  lastModifiedDate: any
  size: any
  path: any
  preview: any
}

export const StageActivated: React.FC<{
  orderId: string
  orders: GetEditOrderPageCaseFolder_caseFolder_editTabs_orders[]
  caseFolderId: string
  caseFolderName: string
  waitImageUrl?: string
  publishTimes?: string
  order: GetOrderByIdLiveStream_orders
  isSupportUser: boolean
  refetchQuery: () => void
  orderResult: any
  setOrderResult: any
}> = (props) => {
  const client = useApolloClient()
  const [isResultOrderMsgVisible, setIsResultOrderMsgVisible] = React.useState(
    false
  )
  const isLocked = props.order.mediaOrder?.isLocked ?? undefined
  const editModelLiveStreamObject =
    props.order.mediaOrder?.edit.editModelLiveStream?.advanced
  const isActivated =
    props.order.mediaOrder?.edit.editModelLiveStream?.isActivated ?? undefined
  const [showDetails, setShowDetalis] = React.useState<boolean>(false)

  const mutationMessagingOrderInfo = useMutation<
    MessagingOrderInfo,
    MessagingOrderInfoVariables
  >(MESSAGING_ORDER_INFO, {
    client,
    context: {},
  })

  const [
    mutationOrderInfoLoading,
    setMutationOrderInfoLoading,
  ] = React.useState<boolean>(false)
  const [mutationOrderInfoError, setMutationOrderInfoError] = React.useState<
    string | undefined
  >(undefined)
  const [mutationOrderInfoStatus, setMutationOrderInfoStatus] = React.useState<
    string | undefined
  >(undefined)

  const inputValueRef = React.useRef<any>(null)
  const [copyMsg, setCopyMsg] = React.useState<string>("")

  const mutationUpdatePublishTime = useMutation<
    UpdatePublishTime,
    UpdatePublishTimeVariables
  >(UPDATE_PUBLISH_TIME, {
    client,
    context: {},
    refetchQueries: [
      {
        query: GET_ORDER_BY_ID_LIVE_STREAM,
        variables: { id: props.orderId },
      },
    ],
  })
  const [mutationTimeLoading, setMutationTimeLoading] = React.useState<boolean>(
    false
  )
  const [mutationTimeError, setMutationTimeError] = React.useState<
    string | undefined
  >(undefined)

  const { t } = useTranslation(["common", "errorMessages"])
  const schema = object().shape({
    invitationEmail: string(),
    publishDate: string().required(),
    publishTime: string().required(),
    sendAutoConfigEmail: string()
      .email(t("errorMessages:emailReplyAddressMissing"))
      .min(3)
      .max(999),
  })
  interface FormData {
    invitationEmail: string
    publishDate: Date | undefined
    publishTime: moment.Moment | string | undefined
    sendAutoConfigEmail: string
    ceremonyAgendaBase64: string
  }
  const formik = useFormik<FormData>({
    initialValues: {
      invitationEmail: "",
      publishDate:
        props.publishTimes === undefined
          ? undefined
          : new Date(props.publishTimes),
      publishTime:
        props.publishTimes === undefined
          ? ""
          : moment(new Date(props.publishTimes)),
      sendAutoConfigEmail: "",
      ceremonyAgendaBase64: "",
    },
    validationSchema: schema,
    validateOnBlur: true,
    validateOnChange: true,
    onSubmit: (values) => {
      window.alert(`Submit with values ${JSON.stringify(values)}`)
    },
  })

  const triggerMutationTime = (date: string) => {
    setMutationTimeLoading(true)
    setMutationTimeError(undefined)
    mutationUpdatePublishTime({
      variables: {
        input: {
          orderId: props.orderId,
          publishTimes: [date],
        },
      },
    })
      .then((response) => {
        setMutationTimeLoading(false)

        if (response.errors) {
          setMutationTimeError(response.errors.map((e) => e.message).join(", "))
          return
        }

        if (!response.data) {
          setMutationTimeError(t("errorMessages:unknowError"))
          return
        }

        if (response.data.updateOrderPublishTimes.isError === true) {
          setMutationTimeError(
            response.data.updateOrderPublishTimes.errorReason ??
              t("errorMessages:unknowError")
          )
          return
        }

        setMutationTimeError(undefined)
      })
      .catch((err) => {
        setMutationTimeLoading(false)
        setMutationTimeError(err.toString())
      })
  }

  const onChangeDate = (d1: Date | null) => {
    if (!d1) {
      return
    }
    const newValue = new Date(d1.setUTCMinutes(-d1.getTimezoneOffset()))
    formik.setFieldValue("publishDate", newValue)
    const newDate = moment(newValue)
      .set("hour", 0)
      .set("minute", 0)
      .set("second", 0)

    if (
      formik.values.publishTime !== undefined &&
      formik.values.publishTime !== "" &&
      typeof formik.values.publishTime !== "string"
    ) {
      newDate
        .set("hour", formik.values.publishTime.get("hour"))
        .set("minute", formik.values.publishTime.get("minute"))
        .set("second", 0)
    }
    triggerMutationTime(newDate.toISOString())
  }

  const onChangeTime = (newValue: moment.Moment) => {
    formik.setFieldValue("publishTime", newValue)
    if (formik.values.publishDate !== undefined) {
      const d2 = moment(formik.values.publishDate)
      const newDate = moment(newValue.toString())
        .set("day", d2.get("day"))
        .set("month", d2.get("month"))
        .set("year", d2.get("year"))
      triggerMutationTime(newDate.format())
    }
  }

  const onClickSubmit = () => {
    // triggerMutationPublishOrders()
    // if (mutationImageLoading === true) {
    //   return
    // }
    // if (mutationTimeLoading === true) {
    //   return
    // }
    // if (mutationPublishLoading === true) {
    //   return
    // }
    // formik.handleSubmit()
  }

  const onClickSendConfig = () => {
    const regString = /^\w+([\.-]?\w+)+@\w+([\.:]?\w+)+(\.[a-zA-Z0-9]{2,3})+$/
    const email = formik.values.sendAutoConfigEmail
    const regx = new RegExp(regString).test(email)

    if (email.length <= 2) {
      formik.setFieldError(
        "sendAutoConfigEmail",
        t("errorMessages:emailReceiverMissing")
      )
      formik.setFieldTouched("sendAutoConfigEmail", true, false)
      return
    }

    if (!regx) {
      return
    }

    setMutationOrderInfoLoading(true)
    setMutationOrderInfoError(undefined)
    setMutationOrderInfoStatus(undefined)

    mutationMessagingOrderInfo({
      variables: {
        input: {
          orderIds: [props.orderId],
          email,
        },
      },
    })
      .then((response) => {
        setMutationOrderInfoLoading(false)

        if (response.errors) {
          setMutationOrderInfoError(
            response.errors.map((e) => e.message).join(", ")
          )
          return
        }

        if (!response.data) {
          setMutationOrderInfoError(t("errorMessages:unknowError"))
          return
        }

        if (response.data.messagingOrderInfo.isError === true) {
          setMutationOrderInfoError(
            response.data.messagingOrderInfo.errorReason ??
              t("errorMessages:unknowError")
          )
          return
        }

        setMutationOrderInfoStatus(
          t("editOrder:successSendEmail", { emails: email })
        )
      })
      .catch((err) => {
        setMutationOrderInfoLoading(false)
        setMutationOrderInfoError(err.toString())
      })
  }

  const avatarUrl =
    props.order.mediaOrder?.edit.editModelLiveStream?.waitImageUrl ?? ""
  const publishTime =
    props.order.mediaOrder?.publishTimes?.[0]?.time ?? undefined
  const ceremonyAgendaUrl =
    props.order.mediaOrder?.edit.editModelLiveStream?.ceremonyAgendaUrl ??
    undefined
  const onClickDownloadPdf = () => {
    if (!ceremonyAgendaUrl) {
      return
    }
    if (ceremonyAgendaUrl.length === 0) {
      return
    }
    window.open(ceremonyAgendaUrl, "_blank")
  }

  const [selectedQr, setSelectedQr] = React.useState<{
    visible: boolean
    imgSrc: string | undefined
    qualityText?: string | undefined
    qualityNr?: string | undefined
    larix?: string | undefined
  }>({
    imgSrc: undefined,
    visible: false,
  })

  const [qrTable, setQrTable] = React.useState<
    Array<{
      title: string
      qualityText: string
      qualityNr: string
      qrLink: string | any
      isSelected: boolean
      larix?: any
    }>
  >([
    {
      title: "Qr-Kod",
      qualityText: "Låg",
      qualityNr: "500",
      qrLink: editModelLiveStreamObject?.qrRecorderLarix500,
      isSelected: false,
      larix: editModelLiveStreamObject?.RecorderLarix500,
    },
    {
      title: "Qr-Kod",
      qualityText: "Mellan",
      qualityNr: "1000",
      qrLink: editModelLiveStreamObject?.qrRecorderLarix1000,
      isSelected: false,
      larix: editModelLiveStreamObject?.RecorderLarix1000,
    },
    {
      title: "Qr-Kod",
      qualityText: "Hög",
      qualityNr: "1500",
      qrLink: editModelLiveStreamObject?.qrRecorderLarix1500,
      isSelected: false,
      larix: editModelLiveStreamObject?.RecorderLarix1500,
    },
  ])

  const onClickOpenQr = (
    url: string | null,
    qualityText?: string | null,
    qualityNr?: string | null,
    larix?: string | null
  ) => {
    if (url === null) {
      return
    }
    setSelectedQr(
      produce(selectedQr, (draft) => {
        draft.visible = true
        draft.imgSrc = url
        draft.qualityNr = qualityNr!
        draft.qualityText = qualityText!
        draft.larix = larix!
      })
    )

    onQrSelected(qualityText!)
  }
  const onClickCloseQr = () => {
    setSelectedQr(
      produce(selectedQr, (draft) => {
        draft.visible = false
        draft.imgSrc = undefined
      })
    )
  }

  const onQrSelected = (arg: string | null) => {
    setQrTable(
      produce(qrTable, (draft) => {
        draft.map((item) => {
          if (arg === item.qualityText) item.isSelected = true
          else item.isSelected = false
        })
      })
    )
  }

  React.useEffect(() => {
    if (props.orderResult === null) {
      setIsResultOrderMsgVisible(false)
    } else {
      setIsResultOrderMsgVisible(true)
    }
  })

  const handleCloseOrderMsgPopUp = () => {
    props.setOrderResult(null)
    setIsResultOrderMsgVisible(false)
  }

  const handleShowDetails = () => setShowDetalis(!showDetails)
  const friendlyId = props.order.customerFriendlyId
  const currency =
    props.order.mediaOrder?.order.mediaOrder?.preview.price?.currency
  const price =
    props.order.mediaOrder?.order.mediaOrder?.preview.price?.endCustomer
      .pricePayRounded
  const status = props.order.mediaOrder?.order.mediaOrder?.status
  const userInterface = props.order.mediaOrder?.edit.userInterface

  enum MessageBoxIcons {
    NONE = "NONE",
    INFORMATION = "INFORMATION",
    WARNING = "INFORMATION",
    ERROR = "ERROR",
  }

  const {
    inAgency,
    inFamiljesidan,
    inMinnesrummet,
  } = props.order.mediaOrder!.publishChannels

  return (
    <>
      <div className={styles.orderEditor}>
        <div className={styles.leftBlock}>
          <StylesActivated>
            <div className={styles.leftBlockContent}>
              {isResultOrderMsgVisible &&
                props.orderResult &&
                props.orderResult.resultText &&
                props.orderResult.resultIcon && (
                  <div
                    className={cx("resultOrder", {
                      ["ERORR"]:
                        props.orderResult.resultIcon === MessageBoxIcons.ERROR,
                      ["WARNING"]:
                        props.orderResult.resultIcon ===
                        MessageBoxIcons.WARNING,
                      ["INFORMATION"]:
                        props.orderResult.resultIcon ===
                        MessageBoxIcons.INFORMATION,
                    })}
                  >
                    <div className={"resultBlockHeader"}>
                      <span onClick={handleCloseOrderMsgPopUp}>
                        <FontAwesomeIcon icon={faTimes} />
                      </span>
                    </div>
                    <div className={"resultBlockContent"}>
                      {(props.orderResult.resultIcon ===
                        MessageBoxIcons.INFORMATION && (
                        <FontAwesomeIcon icon={faQuestionCircle} />
                      )) ||
                        (props.orderResult.resultIcon ===
                          MessageBoxIcons.WARNING && (
                          <FontAwesomeIcon icon={faExclamationTriangle} />
                        )) ||
                        (props.orderResult.resultIcon ===
                          MessageBoxIcons.ERROR && (
                          <FontAwesomeIcon icon={faTimesCircle} />
                        ))}
                      <span>{props.orderResult.resultText}</span>
                    </div>
                  </div>
                )}

              <div className={"top-wrapper"}>
                {userInterface?.isUnlockButton && (
                  <SubMenu
                    isLocked={isLocked}
                    documentId={props.orderId}
                    selectedCaseFolderId={props.caseFolderId}
                    publishTime={publishTime}
                    refetchQuery={props.refetchQuery}
                    isActivated={true}
                    isUnlockButton={userInterface.isUnlockButton!}
                    unlockButtonText={userInterface.unlockButtonText!}
                    isSupport={props.isSupportUser}
                    orderId={props.orderId}
                  />
                )}

                {userInterface?.editStatusText && (
                  <LockerBox
                    text={userInterface?.editStatusText}
                    className={"liveStreamLockBox"}
                    icon={userInterface.editStatusIcon}
                    color={userInterface.editStatusColor}
                  />
                )}

                <div className={"content"}>
                  <div className={"first-section"}>
                    <div className={"title-and-avatar"}>
                      <div className={"title"}>Direktsändning</div>
                      <div className={"avatar-wrapper"}>
                        {avatarUrl && avatarUrl.length > 0 ? (
                          <img src={avatarUrl} />
                        ) : null}
                      </div>
                    </div>
                    <div
                      className={"sec-section"}
                      style={
                        !ceremonyAgendaUrl
                          ? { flexDirection: "column-reverse" }
                          : {}
                      }
                    >
                      <div className={"col1"}>
                        {ceremonyAgendaUrl && (
                          <Button
                            onClick={onClickDownloadPdf}
                            color={"primary"}
                            variant={"secondary"}
                          >
                            {t("editOrder:downloadPdf")}
                          </Button>
                        )}
                      </div>

                      <div className={"col2"}>
                        <strong>Skicka autokonfiguration</strong>

                        <div>
                          <div className={"autoconfig-wrapper"}>
                            <div className={"input-wrapper"}>
                              <input
                                name="sendAutoConfigEmail"
                                value={formik.values.sendAutoConfigEmail}
                                onChange={(
                                  e: React.ChangeEvent<HTMLInputElement>
                                ) => {
                                  formik.setFieldTouched(
                                    "sendAutoConfigEmail",
                                    true
                                  )
                                  formik.setFieldValue(
                                    "sendAutoConfigEmail",
                                    e.target.value,
                                    true
                                  )
                                }}
                                disabled={isActivated !== true}
                                placeholder={"info@byra.se"}
                              />
                            </div>
                            <Button
                              onClick={onClickSendConfig}
                              color={"primary"}
                              variant={"primary"}
                              disabled={
                                isActivated !== true || mutationOrderInfoLoading
                              }
                              isWorking={mutationOrderInfoLoading}
                            >
                              Skicka
                            </Button>
                          </div>

                          <div>
                            {formik.touched.sendAutoConfigEmail &&
                              formik.errors.sendAutoConfigEmail && (
                                <FieldError
                                  text={t(
                                    `errorMessages:${formik.errors.sendAutoConfigEmail}`
                                  )}
                                />
                              )}

                            {mutationOrderInfoError &&
                              mutationOrderInfoError.length > 0 && (
                                <FieldError text={mutationOrderInfoError} />
                              )}

                            {mutationOrderInfoStatus &&
                              mutationOrderInfoStatus.length > 0 && (
                                <FieldSuccess text={mutationOrderInfoStatus} />
                              )}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className={"video-links"}>
                      <div className={"title"}>
                        <span>Strömningslänkar</span> <br />
                        <b style={{ fontSize: "13px" }}>Autokonfiguration</b>
                      </div>

                      <div className={"configration"}>
                        <div className={"video-table"}>
                          <p className="qr-title">Qr-Kod</p>
                          <ul>
                            {qrTable.map((item, index) => {
                              return (
                                <li
                                  className={item.isSelected ? "selected" : " "}
                                  key={index}
                                  onClick={() =>
                                    onClickOpenQr(
                                      item.qrLink!,
                                      item.qualityText!,
                                      item.qualityNr!,
                                      item.larix!
                                    )
                                  }
                                >
                                  {/* <p className="qr-title">{item.title}</p> */}
                                  <div className={"qr-info"}>
                                    <h3
                                      className={
                                        item.isSelected ? "selected" : " "
                                      }
                                    >
                                      {item.qualityText}
                                    </h3>
                                    <span>{item.qualityNr} kbps</span>
                                  </div>
                                </li>
                              )
                            })}
                          </ul>
                        </div>

                        {selectedQr.visible ? (
                          <div className={"qr-wrapper"}>
                            <span
                              style={{
                                marginLeft: "9px",
                                fontWeight: "bold",
                                fontSize: "12px",
                                marginBottom: "20px",
                              }}
                            >
                              QR-Kod: {selectedQr.qualityText} (
                              <span style={{ fontWeight: 400 }}>
                                {selectedQr.qualityNr}
                              </span>{" "}
                              kbps)
                            </span>
                            <div className={"qr-img-wrapper"}>
                              <img
                                src={selectedQr.imgSrc}
                                width={"200px"}
                                height={"200px"}
                              />
                            </div>
                            <div className={"infoBlock"}>
                              {selectedQr.imgSrc && (
                                <div className={"leftCol"}>
                                  <CopyValue
                                    icon={faCopy}
                                    title={"Kopiera Qr-Kod"}
                                    CopyValue={selectedQr.imgSrc!.toString()}
                                  />
                                  {/* <div
                                    className={"copy"}
                                    onClick={() =>
                                      navigator.clipboard.writeText(
                                        selectedQr.imgSrc!.toString()
                                      )
                                    }
                                  >
                                    <div className="icon">
                                      <FontAwesomeIcon icon={faCopy} />
                                      <span>Kopiera Qr-Kod</span>
                                    </div>
                                  </div> */}
                                  <div className={"oppenLink"}>
                                    <FontAwesomeIcon icon={faQrcode} />
                                    <a
                                      href={selectedQr.larix}
                                      target={"_blank"}
                                    >
                                      Öppna Larix
                                    </a>
                                  </div>
                                </div>
                              )}
                              <div className="rightCol">
                                <Button
                                  className={"closeBtn"}
                                  color={"secondary"}
                                  onClick={onClickCloseQr}
                                >
                                  Stäng
                                </Button>
                              </div>
                            </div>
                            {/* <div className={"close-icon"} onClick={onClickCloseQr} >
                          <FontAwesomeIcon
                            icon={faTimes}
                            color={"#000"}
                            size={"lg"}
                          />
                        </div> */}
                          </div>
                        ) : (
                          <div
                            style={{ width: "200px", height: "200px" }}
                          ></div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={"advance-section"}>
                <ToggledSection
                  title={"Avancerat"}
                  titleColor={"#1289A7"}
                  defaultOpen={false}
                >
                  <div className={"content"}>
                    <div>
                      <b>Strömningsnamn</b>
                      <div className="inputHolder">
                        <input
                          defaultValue={
                            editModelLiveStreamObject &&
                            editModelLiveStreamObject?.streamName?.toString()
                          }
                        />

                        <div
                          onClick={() =>
                            navigator.clipboard.writeText(
                              editModelLiveStreamObject!.streamName!.toString()
                            )
                          }
                        >
                          <FontAwesomeIcon icon={faCopy} />
                        </div>
                      </div>
                      <p style={{ marginTop: 0, color: "green" }}>
                        <small>{copyMsg}</small>
                      </p>
                    </div>
                    {editModelLiveStreamObject &&
                      editModelLiveStreamObject.ingestionUrl && (
                        <div>
                          <b>Ingestion-url</b>
                          <div className="inputHolder">
                            <input
                              defaultValue={
                                editModelLiveStreamObject &&
                                editModelLiveStreamObject?.ingestionUrl?.toString()
                              }
                            />
                            <div
                              onClick={() =>
                                navigator.clipboard.writeText(
                                  editModelLiveStreamObject!.ingestionUrl!.toString()
                                )
                              }
                            >
                              <FontAwesomeIcon icon={faCopy} />
                            </div>
                          </div>
                          <p style={{ marginTop: 0, color: "green" }}>
                            <small>{copyMsg}</small>
                          </p>
                        </div>
                      )}

                    {editModelLiveStreamObject &&
                      editModelLiveStreamObject?.ingestionKey && (
                        <div>
                          <b>Ingestion-key</b>
                          <div className="inputHolder">
                            <input
                              defaultValue={
                                editModelLiveStreamObject &&
                                editModelLiveStreamObject?.ingestionKey!.toString()
                              }
                            />
                            <div
                              onClick={() =>
                                navigator.clipboard.writeText(
                                  editModelLiveStreamObject!.ingestionKey!.toString()
                                )
                              }
                            >
                              <FontAwesomeIcon icon={faCopy} />
                            </div>
                          </div>
                          <p style={{ marginTop: 0, color: "green" }}>
                            <small>{copyMsg}</small>
                          </p>
                        </div>
                      )}

                    <div>
                      <b>Spelarlänkar</b>
                      <div className="inputHolder">
                        <input
                          defaultValue={
                            editModelLiveStreamObject &&
                            editModelLiveStreamObject?.playerUrl?.toString()
                          }
                        />
                        <div
                          onClick={() =>
                            navigator.clipboard.writeText(
                              editModelLiveStreamObject!.playerUrl!.toString()
                            )
                          }
                        >
                          <FontAwesomeIcon icon={faCopy} />
                        </div>
                      </div>
                      <p style={{ marginTop: 0, color: "green" }}>
                        <small>{copyMsg}</small>
                      </p>
                    </div>
                  </div>
                </ToggledSection>
              </div>
            </div>

            <div className={"container bottom-wrapper"}>
              <div className={"whiteframe"}>
                <div className={"content"}>
                  <div className={"top-content"}>
                    <div className={"show-more"} onClick={handleShowDetails}>
                      <FontAwesomeIcon
                        icon={showDetails ? faChevronUp : faChevronDown}
                      />
                      <span>{t("editOrder:showDetails")}</span>
                    </div>

                    <div className={"buttonDate"}>
                      <div className={"statusInfo"}>
                        <FontAwesomeIcon
                          icon={faCheckCircle}
                          color={"#1289a7"}
                        />
                        <span>Aktiverad</span>
                      </div>
                      <b>Datum</b>
                      <div>
                        <Calendar
                          className={"publishTime"}
                          selectedDate={formik.values.publishDate}
                          disabled={isLocked}
                          onChange={onChangeDate}
                          minDate={moment()
                            .add(1, "day")
                            .startOf("day")
                            .toDate()}
                        />
                        <div>
                          {formik.touched.publishDate &&
                            formik.errors.publishDate && (
                              <FieldError
                                text={t(
                                  `errorMessages:${formik.errors.publishDate}`
                                )}
                              />
                            )}
                        </div>
                      </div>
                      <b>Tid</b>
                      <div>
                        <FieldWrapperFormik
                          name="personFuneralTime"
                          errors={undefined}
                          value={formik.values.publishTime}
                          onChange={onChangeTime}
                        >
                          <RcHourFieldFormik
                            className={"input-time"}
                            showMinute={true}
                            disabled={isLocked}
                          />
                        </FieldWrapperFormik>
                        <div>
                          {formik.touched.publishTime &&
                            formik.errors.publishTime && (
                              <FieldError
                                text={t(
                                  `errorMessages:${formik.errors.publishTime}`
                                )}
                              />
                            )}
                        </div>
                      </div>
                      {userInterface?.publishButtonText && (
                        <div className={"buttonPublish"}>
                          <Button
                            onClick={onClickSubmit}
                            color={"secondary"}
                            variant={"secondary"}
                            disabled={isLocked}
                            isWorking={mutationTimeLoading}
                          >
                            {userInterface?.publishButtonText}
                          </Button>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className={"error-content"}>
                    {mutationTimeError && (
                      <FieldError text={mutationTimeError} />
                    )}
                  </div>
                  <div
                    className={cx("details", {
                      ["isHide"]: !showDetails,
                    })}
                  >
                    <div className={"details-content"}>
                      <table>
                        <thead>
                          <tr>
                            <th>Order</th>
                            <th>Pris</th>
                            <th>status</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>{friendlyId}</td>
                            <td>
                              {price
                                ? `${price}  ${currency}`
                                : t("editOrder:noPrice")}
                            </td>
                            <td>
                              <Status
                                variant={status!.color}
                                className={"status"}
                              >
                                {t(`statuses:${status?.status}`)}
                              </Status>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <div className={"publish_Block"}>
                        <ParallelPublishing
                          orderId={props.orderId!}
                          caseFolderId={props.caseFolderId}
                          isLocked={true}
                          publishChannels={
                            props.order.mediaOrder?.publishChannels!
                          }
                          isLiveStream={true}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </StylesActivated>
        </div>

        <div className={styles.sidebarBlock}>
          <TopSidebarCaseInfoBlock
            name={props.caseFolderName}
            caseId={props.caseFolderId}
          />

          {/* <div className={styles.sidebarInfoBlock}>
            <div className={styles.sidebarInfoBlockElement}>
              <div className={styles.sidebarInfoBlockLabel}>
                <strong>Skicka inbjudan via mail</strong>
              </div>
              <div className={styles.sidebarInfoBlockVoucher}>
                <input
                  name="invitationEmail"
                  value={formik.values.invitationEmail}
                  onChange={(e: any) => {
                    formik.setFieldValue("invitationEmail", e.target.value)
                  }}
                  disabled={isLocked}
                />
                {formik.touched.invitationEmail &&
                  formik.errors.invitationEmail && (
                    <FieldError
                      text={t(`errorMessages:${formik.errors.invitationEmail}`)}
                    />
                  )}
              </div>
              <div className={styles.sidebarFieldHelp}>
                Fyll i mailadresser separerade med kommatecken
              </div>
            </div>

            <Button
              className={styles.sidebarInfoBlockSubmit}
              disabled={isLocked}
            >
              Skicka
            </Button>
          </div> */}
        </div>
      </div>
    </>
  )
}

const ToggledSection: React.FC<{
  title: string
  titleColor?: string
  defaultOpen?: boolean
}> = (props) => {
  const variantsContainer: Variants = {
    open: {
      opacity: 1,
      x: 0,
      transition: { type: "tween", ease: "anticipate" },
      height: "auto",
      visibility: "visible",
    },
    closed: {
      opacity: 0,
      x: -400,
      height: 0,
      visibility: "hidden",
    },
  }
  const titleColor =
    props.titleColor === undefined ? "#272727" : props.titleColor
  const [isOpen, setIsOpen] = React.useState<boolean>(
    props.defaultOpen === undefined ? false : props.defaultOpen
  )
  const onToggle = () => {
    setIsOpen(!isOpen)
  }
  return (
    <div className={"toggle-section"}>
      <div onClick={onToggle} className="toggle-title">
        <strong style={{ color: titleColor }}>{props.title}</strong>
        <FontAwesomeIcon
          icon={isOpen === true ? faChevronUp : faChevronDown}
          color={"#1289A7"}
        />
      </div>

      <motion.div
        className="toggle-children"
        initial="closed"
        animate={isOpen ? "open" : "closed"}
        variants={variantsContainer}
      >
        {props.children}
      </motion.div>
    </div>
  )
}

const StylesActivated = styled.div`
  position: relative;
  .resultOrder {
    width: 450px;
    height: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    padding-bottom: 10px;
    box-shadow: 2px 11px 20px 0px rgba(237, 237, 237, 0.75);
    -webkit-box-shadow: 2px 11px 20px 0px rgba(237, 237, 237, 0.75);
    -moz-box-shadow: 2px 11px 20px 0px rgba(237, 237, 237, 0.75);
    border-radius: 3px;
    .resultBlockHeader {
      color: white;
      width: 100%;
      display: flex;
      justify-content: flex-end;
      font-size: 15px;
      padding: 5px;
      padding-bottom: 0px;
      cursor: pointer;
    }
    .resultBlockContent {
      padding: 5px;
      padding-bottom: 10px;
      color: white;
      display: flex;
      align-items: flex-start;
      column-gap: 10px;
      svg {
        font-size: 30px;
        padding-top: 5px;
      }
      span {
        font-size: 15px;
        line-height: 20px;
      }
    }

    &.ERORR {
      background-color: #f15e5e;
    }
    &.WARNING {
      background-color: #fea15e;
    }
    &.INFORMATION {
      background-color: ${(props) => props.theme["--secondary"]};
    }
  }
  .container {
    display: flex;
    flex-direction: row;
  }

  .whiteframe {
    flex: 2 1;
    margin-left: 0;
    padding-left: ${(props) => props.theme["--spacing"]};
    padding-right: ${(props) => props.theme["--spacing"]};
    margin-right: 0;
    border-radius: 3px;
    background-color: #fff;
    box-shadow: 0 2px 5px 1px rgba(0, 0, 0, 0.07);
    position: relative;
  }

  .top-wrapper {
    position: relative;
    min-height: 290px;

    .content {
      padding-left: 15px;
      padding-right: 15px;
      padding-bottom: 29px;
      padding-top: 31px;
      min-height: 200px;
    }
    .toggle-section {
      margin-top: 10px;
      margin-bottom: 10px;

      .toggle-children {
        max-width: 600px;
      }
    }
    .first-section {
      width: 100%;
      margin-top: 25px;
      margin-bottom: 10px;
      display: grid;
      grid-template-columns: 250px 290px auto;
      grid-template-rows: 1fr;
      column-gap: 10px;
      justify-items: stretch;
      align-items: end;
      grid-row-gap: 15px;
      column-gap: 20px;
      /* .video-links {
        & > strong {
          font-weight: 500;
          margin-bottom: 6px;
          display: block;
        }
      } */
      /* .title-and-avatar {
      } */
    }
    .title-and-avatar {
      align-self: start;
    }

    .video-links {
      .configration {
        display: flex;
        flex-direction: row;
        column-gap: 10px;
        height: 180px;
        .video-table {
          padding: 5px 10px;
          border: 1px solid #d2d2d2;
          border-radius: 6px;
          height: 140px;
          display: flex;
          align-items: center;
          position: relative;
          .qr-title {
            display: flex;
            background-color: ${(props) => props.theme["--white"]};
            padding-left: 5px;
            width: 50px;
            position: absolute;
            font-size: 12px;
            top: -10px;
            left: 50%;
            margin: 0;
            transform: translateX(-50%);
          }
          ul {
            display: flex;
            justify-content: space-around;
            align-items: center;
            column-gap: 5px;
            li {
              border: 1px solid ${(props) => props.theme["--gray"]};
              border-radius: 3px;
              width: 80px;
              height: 80px;
              cursor: pointer;
              &.selected {
                border: 1px solid ${(props) => props.theme["--secondary"]};
              }

              .qr-info {
                display: flex;
                flex-direction: column;
                align-items: center;
                h3 {
                  &.selected {
                    color: ${(props) => props.theme["--secondary"]};
                  }
                }
                span {
                  font-size: 12px;
                }
              }

              &:hover {
                border: 1px solid ${(props) => props.theme["--secondary"]};
                .qr-info {
                  h3 {
                    color: ${(props) => props.theme["--secondary"]};
                  }
                }
              }
            }
          }
        }
        .qr-wrapper {
          position: relative;
          top: -32px;
          .qr-img-wrapper {
            border: 1px solid ${(props) => props.theme["--gray"]};
            margin-top: 14px;
            img {
              border: none;
              border-radius: 3px;
            }
          }
          .infoBlock {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            margin-top: 1px;
            .leftCol {
              display: flex;
              flex-direction: column;
              row-gap: 10px;
              padding-top: 5px;
              .copy {
                padding: 0;
                .icon {
                  color: ${(props) => props.theme["--secondary"]};
                  cursor: pointer;
                  font-size: 12px;
                  display: flex;
                  align-items: center;
                  column-gap: 5px;
                  &:active {
                    color: #16e98e;
                  }
                }
              }
              .oppenLink {
                font-size: 12px;
                display: flex;
                column-gap: 5px;
                color: ${(props) => props.theme["--secondary"]};
                &:hover {
                  color: ${(props) => props.theme["--blue"]};
                  a {
                    &:hover {
                      color: ${(props) => props.theme["--blue"]};
                    }
                  }
                }
              }
            }

            .closeBtn {
              padding: 0;
              border: none;
              font-size: 12px;
            }
          }
        }
      }
    }

    /* .q
    r-wrapper {
        position: absolute;
        top: -1px;
        right: -1px;
        padding: 15px;
        padding-top: 40px;
        border-radius: 6px;
        border: 1px solid #d2d2d2;
        background: #fff;
        z-index: 999;
        min-width: 250px;
        min-height: 250px;

        .qr-img-wrapper {
          img {
            max-width: 300px;
            max-height: 300px;
          }
        }

        .close-icon {
          position: absolute;
          top: 1px;
          right: 1px;
          width: 40px;
          height: 40px;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          background: #fff;
        }
      }

      strong {
        font-weight: 500;
      }

      .title {
        font-weight: 500;
      }

      .row {
        display: grid;
        grid-template-columns: 85px 1fr 1fr 1fr 1fr;
        margin-bottom: 15px;
      }

      .item {
        display: flex;
        flex-direction: column;
        cursor: pointer;

        strong {
          font-weight: normal;

          font-size: 15px;
          line-height: 18px;
          a {
            color: black;
          }
        }

        span {
          font-weight: normal;
          font-size: 12px;
          line-height: 14px;
          border: 1px solid #d8d8d8;
          margin-right: 5px;
          margin-top: 5px;
          color: #1289a7;
          border-radius: 6px;
          padding: 1px 3px;
          &:hover {
            color: #1778e4;
            border: 1px solid #1289a7;
          }
        }
      }

      strong {
        font-weight: 500;
        font-size: 15px;
        line-height: 18px;
        color: black !important;
      }

      i {
        font-weight: 300;
        font-size: 11px;
        line-height: 13px;
      }

      .col-title {
        width: 100%;
        padding-left: 0px;
        padding-right: 15px;
      }

      .col-subtitle::before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 100px;
        height: 1px;
        width: calc(100% - 100px - 15px - 15px);
        background: #d2d2d2;
      }

      .col-subtitle {
        position: absolute;
        top: 0px;
        right: 0;
        width: 100%;
        display: grid;
        grid-template-columns: 100px 1fr 1fr 1fr;
        padding-bottom: 10px;
        padding-top: 10px;
        padding-right: 15px;
        padding-left: 0px;
      }

      .col-subtitle i:last-child {
        text-align: right;
        padding-right: 18px;
      } */

    .sec-section {
      width: 100%;
      height: 180px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      align-self: center !important;
      strong {
        font-weight: 500;
        display: block;
        margin-bottom: 10px;
      }
      .autoconfig-wrapper {
        max-width: 350px;
        display: flex;
        column-gap: 10px;
      }

      .col1 {
        button {
          background: #ffffff 0% 0% no-repeat padding-box;
          border: 1px solid #70b8ca;
          color: #1289a7;
          &:hover {
            color: #ffffff;
            background-color: #70b8ca;
            border: 1px solid #70b8ca;
          }
        }
      }

      .col2 {
        input {
          background: transparent
            linear-gradient(
              180deg,
              #ffffff 0%,
              var(--unnamed-color-f8f8f8) 100%
            )
            0% 0% no-repeat padding-box;
          border: 1px solid var(--unnamed-color-d8d8d8);
          background: transparent
            linear-gradient(180deg, #ffffff 0%, #f8f8f8 100%) 0% 0% no-repeat
            padding-box;
          border: 1px solid #d8d8d8;
        }

        button {
          background: #ffffff 0% 0% no-repeat padding-box;
          border: 1px solid #70b8ca;
          color: #1289a7;
          &:hover {
            color: #ffffff;
            background-color: #70b8ca;
            border: 1px solid #70b8ca;
          }
        }
      }
    }

    .title {
      color: #272727;
      font-size: 18px;
      line-height: 22px;
      margin-bottom: 10px;
    }

    .avatar-wrapper {
      border: 1px solid #d2d2d2;
      border-radius: 3px;
      height: 180px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  .bottom-wrapper {
    margin-top: 16px;
    .statusInfo {
      display: flex;
      color: #1289a7;
      column-gap: 5px;
      margin-right: 10px;
    }
    .content {
      padding-top: 16px;
      padding-bottom: 16px;
      padding-right: 16px;
    }
    .error-content {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: flex-end;
    }
    .top-content {
      display: flex;
      justify-content: flex-end;
      column-gap: 10px;
      align-items: center;
    }

    .buttonDate {
      column-gap: 10px;
      margin-right: ${(props) => props.theme["--spacing"]} / 2;
      margin-left: ${(props) => props.theme["--spacing"]} / 2;
      display: flex;
      flex-wrap: nowrap;
      justify-content: center;
      align-items: center;
      b {
        color: #161616;
        font-size: 15px;
        line-height: 18px;
      }

      .publishTime {
        margin-top: ${(props) => props.theme["--spacing"]} / 2;
        width: 160px;

        input {
          background-color: $white;
        }
      }

      .input-time {
        width: 100px;
      }
      svg {
        path {
          fill: #161616;
          opacity: 1;
        }
      }
    }
    .buttonPublish > button {
      padding: 11px 16px 12px;
    }
  }

  .advance-section {
    padding: 0 13px;
    width: 100%;
    .content {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      flex-wrap: wrap;
      margin-top: 10px;
      div {
        margin-top: 10px;
        margin-right: 20px;
        display: flex;
        flex-direction: column;
        .inputHolder {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          width: 400px;
          input {
            background: transparent
              linear-gradient(
                180deg,
                #ffffff 0%,
                var(--unnamed-color-f8f8f8) 100%
              )
              0% 0% no-repeat padding-box;
            border: 1px solid var(--unnamed-color-d8d8d8);
            background: transparent
              linear-gradient(180deg, #ffffff 0%, #f8f8f8 100%) 0% 0% no-repeat
              padding-box;
            border: 1px solid #d8d8d8;
            border-radius: 2px;
          }
          div {
            color: #70b8ca;
            margin-bottom: 10px;
            margin-left: 10px;
            cursor: pointer;
            &:active {
              color: #16e98e;
            }
          }
        }
      }
    }
  }
  .toggle-title {
    display: flex;
    column-gap: 5px;
    cursor: pointer;

    strong {
      font-weight: 500;
    }
  }

  .whiteframe {
    .content {
      .top-content {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        .show-more {
          font-size: 14px;
          color: #1289a7;
          span {
            margin-left: 5px;
            font-size: 14px;
            font-weight: 500;
            cursor: pointer;
          }
        }
        .buttonDate {
          .statusInfo {
            svg {
              filter: invert(37%) sepia(99%) saturate(395%) hue-rotate(145deg)
                brightness(100%) contrast(97%);
            }
          }

          div {
            .rc-time-picker {
              svg {
                filter: invert(83%) sepia(2%) saturate(9%) hue-rotate(73deg)
                  brightness(90%) contrast(93%);
              }
            }
          }
        }
      }
    }
  }

  .details {
    display: block;
    height: 100px;
    margin-top: 10px;
    padding: 10px;
    animation: showUp 1s easy-out;
    .details-content {
      padding: 5px !important;
      height: 100%;
      background-color: #f8f8f8;
      display: flex;
      justify-content: space-between;
      align-items: center;
      table {
        width: 300px;
        tbody {
          tr {
            text-align: center;
            font-size: 13px;
            td {
              .status {
                display: flex;
                justify-content: center;
              }
            }
          }
        }
      }
      .publish_Block {
        width: auto;
        display: flex;
        flex-direction: column;

        & > b {
          margin: 0;
          margin-bottom: 10px !important;
        }
      }
    }
  }
  .isHide {
    display: none;
    height: -100px;
  }

  .liveStreamLockBox {
    position: absolute;
    right: 60px;
    top: 10px;
    span {
      margin-left: 8px;
    }
  }

  @media only screen and (max-width: 1470px) {
    .first-section {
      grid-template-columns: 300px 1fr;
      grid-template-rows: 1fr 1fr;
      grid-row-gap: 10px;
    }
    .title-and-avatar {
      grid-column: 1/2;
      grid-row: 1/2;
      align-self: start;
    }

    .sec-section {
      grid-column: 1/2;
      grid-row: 2/3;
      height: 80px;
    }

    .video-links {
      grid-column: 2/3;
      grid-row: 1/2;
    }
  }
`

{
  /* <div className={"col-subtitle"}>
                          <i>&nbsp;</i>
                          <i>Lag</i>
                          <i>Kvalite</i>
                          <i>Hog</i>
                        </div> */
}
{
  /* <div className={"row"}>
                          <div className={"col-title"}>
                            <strong>Larix</strong>
                          </div>

                          <div className={"item"}>
                            {editModelLiveStreamObject &&
                            editModelLiveStreamObject.RecorderLarix500 &&
                            editModelLiveStreamObject.qrRecorderLarix500 ? (
                              <>
                                <strong>
                                  <a
                                    href={
                                      editModelLiveStreamObject.RecorderLarix500
                                    }
                                    target={"_blank"}
                                  >
                                    500
                                  </a>
                                </strong>
                                <span
                                  onClick={() =>
                                    onClickOpenQr(
                                      editModelLiveStreamObject.qrRecorderLarix500
                                    )
                                  }
                                >
                                  QR-kod
                                </span>
                              </>
                            ) : null}
                          </div>

                          <div className={"item"}>
                            {editModelLiveStreamObject &&
                            editModelLiveStreamObject.RecorderLarix1000 &&
                            editModelLiveStreamObject.qrRecorderLarix1000 ? (
                              <>
                                <strong>
                                  <a
                                    href={
                                      editModelLiveStreamObject?.RecorderLarix1000
                                    }
                                  >
                                    1000
                                  </a>
                                </strong>
                                <span
                                  onClick={() =>
                                    onClickOpenQr(
                                      editModelLiveStreamObject.qrRecorderLarix1000
                                    )
                                  }
                                >
                                  QR-kod
                                </span>
                              </>
                            ) : null}
                          </div>

                          <div className={"item"}>
                            {editModelLiveStreamObject &&
                            editModelLiveStreamObject.RecorderLarix1500 &&
                            editModelLiveStreamObject.qrRecorderLarix1500 ? (
                              <>
                                <strong>
                                  <a
                                    href={
                                      editModelLiveStreamObject?.RecorderLarix1500
                                    }
                                  >
                                    1500
                                  </a>
                                </strong>
                                <span
                                  onClick={() =>
                                    onClickOpenQr(
                                      editModelLiveStreamObject.qrRecorderLarix1500
                                    )
                                  }
                                >
                                  QR-kod
                                </span>
                              </>
                            ) : null}
                          </div>

                          <div className={"item"}>
                            {editModelLiveStreamObject &&
                            editModelLiveStreamObject.RecorderLarix2000 &&
                            editModelLiveStreamObject.qrRecorderLarix2000 ? (
                              <>
                                <strong>
                                  <a
                                    href={
                                      editModelLiveStreamObject?.RecorderLarix2000
                                    }
                                  >
                                    2000
                                  </a>
                                </strong>
                                <span
                                  onClick={() =>
                                    onClickOpenQr(
                                      editModelLiveStreamObject.qrRecorderLarix2000
                                    )
                                  }
                                >
                                  QR-kod
                                </span>
                              </>
                            ) : null}
                          </div>
                        </div> */
}

{
  /* <div className={"row"}>
                          <div className={"col-title"}>
                            <strong>Larix</strong>
                          </div>
                          <div className={"item"}>
                            <strong>500</strong>
                            <span>QR-kod</span>
                          </div>
                          <div className={"item"}>
                            <strong>1000</strong>
                            <span>QR-kod</span>
                          </div>
                          <div className={"item"}>
                            <strong>1500</strong>
                            <span>QR-kod</span>
                          </div>
                          <div className={"item"}>
                            <strong>2000</strong>
                            <span>QR-kod</span>
                          </div>
                        </div> */
}
{
  /* <div className={"row"}>
                          <div className={"col-title"}>
                            <strong>GoCoder</strong>
                          </div>

                          <div className={"item"}>
                            {editModelLiveStreamObject &&
                            editModelLiveStreamObject.GoCoderIOS &&
                            editModelLiveStreamObject.qrGoCoderIOS ? (
                              <>
                                <strong>
                                  <a
                                    href={editModelLiveStreamObject?.GoCoderIOS}
                                  >
                                    IOS
                                  </a>
                                </strong>
                                <span
                                  onClick={() =>
                                    onClickOpenQr(
                                      editModelLiveStreamObject?.qrGoCoderIOS
                                    )
                                  }
                                >
                                  QR-kod
                                </span>
                              </>
                            ) : null}
                          </div>

                          <div className={"item"}>&nbsp;</div>

                          <div className={"item"}>
                            {editModelLiveStreamObject &&
                            editModelLiveStreamObject.GoCoderAndroid &&
                            editModelLiveStreamObject.qrGoCoderAndriod ? (
                              <>
                                <strong>
                                  <a
                                    href={
                                      editModelLiveStreamObject?.GoCoderAndroid
                                    }
                                  >
                                    Android
                                  </a>
                                </strong>
                                <span
                                  onClick={() =>
                                    onClickOpenQr(
                                      editModelLiveStreamObject?.qrGoCoderAndriod
                                    )
                                  }
                                >
                                  QR-kod
                                </span>
                              </>
                            ) : null}
                          </div>

                          <div className={"item"}>&nbsp;</div>
                        </div> */
}
