import React from "react"
import * as styles from "./styles.scss"
import NavbarUser from "./NavbarUser"
import Logo from "./Logo"
import { useTranslation } from "react-i18next"
import NavbarLink from "./NavbarLinks/NavbarLink"
import NewsProvider from "components/NewsPreview/NewsProvider"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Whiteframe from "components/Whiteframe"
import PublishingsContainer from "components/PublishingsContainer"
import { Link, withRouter } from "react-router-dom"
import GET_DASHBOARD_CASE_FOLDERS from "graphql/queries/getDashboardCaseFolders"
import { GetCaseFolders } from "schema"
import Query from "react-apollo/Query"
import { notEmpty } from "utils"
import ClipLoader from "react-spinners/ClipLoader"

import { useLocation } from "react-router-dom"
import { faCopy, faPhone } from "@fortawesome/free-solid-svg-icons"
import { AppContext } from "App"
import cx from "classnames"
import CopyValue from "components/CopyValue"

type NavbarProps = {
  showUtilityBar: boolean
}

const Navbar: React.FunctionComponent<NavbarProps> = (props) => {
  const { t } = useTranslation("navbar")
  const [newsListLength, setNewsListLength] = React.useState<number>(0)
  const prevLength = React.useRef<any>()
  const [isNewItemAdded, setIsNewItemsAdded] = React.useState(false)
  const location: any = useLocation()
  const appContext = React.useContext(AppContext)

  React.useEffect(() => {
    if (newsListLength) {
      prevLength.current = newsListLength
    }

    checkNewItemNews()
  }, [])

  const checkNewItemNews = () => {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(() => {
          const prevListLength: number = prevLength.current
          if (newsListLength > prevListLength) setIsNewItemsAdded(true)
          else setIsNewItemsAdded(false)
        })
      }, 700)
    })
  }

  const {
    emailCustomer,
    emailOffice,
    contactPhone,
  } = appContext.currentCustomer.inheritSettings

  return (
    <nav className={styles.navbar}>
      <div className={styles.wrapper}>
        <div className={styles.leftWrapper}>
          <div className={styles.logoWrapper}>
            <Logo />
          </div>

          <div
            className={styles.newsLinkWrapper}
            onClick={() => setIsNewItemsAdded(false)}
          >
            <NavbarLink text={t("news")} path="/news" />

            <NewsProvider
              onChange={(newsItems) => {
                setNewsListLength(newsItems.length)
              }}
              render={() => {
                return (
                  <>
                    {isNewItemAdded && (
                      <div className={styles.newsLinkCounter}>&nbsp;</div>
                    )}
                  </>
                )
              }}
            />
          </div>

          <div className={styles.supportWrapper}>
            <NavbarLink text={t("support")} path="/support" />
            <div className={styles.popup}>
              {appContext.currentUserSession.user?.isSupportUser ? (
                <div
                  className={cx(styles.popupWrapper, {
                    [styles.popupWrapperUserInfo]:
                      appContext.currentUserSession.user?.isSupportUser,
                  })}
                >
                  <ul>
                    <li>
                      <span>Kund:</span>
                      <span>
                        {appContext.currentUserSession.customer?.name ??
                          "saknas"}
                      </span>
                    </li>

                    <li>
                      <span>Mail:</span>
                      {emailCustomer ? (
                        <span>
                          <a href={`mailto:${emailCustomer}`}>
                            {emailCustomer}
                          </a>
                          <CopyValue
                            className={styles.copy}
                            title={""}
                            CopyValue={emailCustomer}
                            icon={faCopy}
                            isTextDesc={false}
                          />
                        </span>
                      ) : (
                        <span>saknas</span>
                      )}
                    </li>

                    <li>
                      <span>Kontor Mail:</span>
                      {emailOffice ? (
                        <span>
                          <a href={`mailto:${emailOffice}`}>{emailOffice}</a>
                          <CopyValue
                            className={styles.copy}
                            title={""}
                            CopyValue={emailOffice}
                            icon={faCopy}
                            isTextDesc={false}
                          />
                        </span>
                      ) : (
                        <span>saknas</span>
                      )}
                    </li>

                    <li>
                      <span>Tel:</span>
                      {contactPhone ? (
                        <span>
                          <a href={`tel:+${contactPhone}`}>{contactPhone}</a>
                          <CopyValue
                            className={styles.copy}
                            title={""}
                            CopyValue={contactPhone}
                            icon={faCopy}
                            isTextDesc={false}
                          />
                        </span>
                      ) : (
                        <span>saknas</span>
                      )}
                    </li>

                    {/* {appContext.currentCustomer.office. && (
                      <li>
                        <span>Kontor tel:</span>
                        <span>
                          {
                            appContext.currentCustomer.inheritSettings
                              .contactPhone
                          }
                        </span>{" "}
                      </li>
                    )} */}
                  </ul>
                </div>
              ) : (
                <div className={styles.popupWrapper}>
                  <p>Vår trevliga Support finns alltid här för dig.</p>
                  <span>
                    <FontAwesomeIcon icon={faPhone} />
                    <b>
                      <a href="tel:+46264012180">026- 401 21 80</a>
                    </b>
                  </span>
                </div>
              )}
            </div>
          </div>

          {appContext.currentUserSession.user?.isSupportUser && (
            <NavbarLink text={"Om Taps"} path="/about-taps" />
          )}
        </div>

        <div className={styles.rightWrapper}>
          {props.showUtilityBar === true ? (
            <div className={styles.buttonContainer}>
              <FolderButtonWithDropdown />
            </div>
          ) : null}

          {props.showUtilityBar === true ? (
            <div className={styles.buttonContainer}>
              <CalendarButtonWithDropdown />
            </div>
          ) : null}

          <div className={styles.buttonContainer}>
            <NavbarUser url={location.pathname} />
          </div>
        </div>
      </div>
    </nav>
  )
}

const useOutsideAlerter = (ref: any, onClick: any) => {
  const handleClickOutside = (event: any) => {
    if (ref.current && !ref.current.contains(event.target)) {
      onClick()
    }
  }

  React.useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside)
    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [])
}

const FolderButtonWithDropdown = () => {
  const { t } = useTranslation("navbar")
  const [isOpen, setIsOpen] = React.useState(false)
  const buttonRef = React.useRef<HTMLDivElement>(null)
  const [offsetRight, setOffsetRight] = React.useState(0)
  const [offsetTop, setOffsetTop] = React.useState(0)
  const onClickOutsite = () => {
    setIsOpen(false)
  }
  const onMouseEnter = () => {
    setIsOpen(true)
    if (buttonRef.current) {
      const btnRect = buttonRef.current.getBoundingClientRect()
      setOffsetTop(btnRect.height + btnRect.top - 3)
      const dropdownWidth = 250
      setOffsetRight(
        window.innerWidth -
          btnRect.right +
          btnRect.width / 2 -
          dropdownWidth / 2
      )
    }
  }
  const onMouseLeave = () => {
    setIsOpen(false)
  }
  const wrapperRef = React.useRef(null)
  useOutsideAlerter(wrapperRef, onClickOutsite)

  return (
    <div onMouseLeave={onMouseLeave} className={styles.folderWrapper}>
      <div
        onMouseEnter={onMouseEnter}
        className={styles.calendarButton}
        ref={buttonRef}
      >
        <FontAwesomeIcon className={styles.archiveIcon} icon="folder" />
        <div>{t("archive")}</div>

        {isOpen === true ? (
          <span className={styles.dotIcon}>&nbsp;</span>
        ) : null}
      </div>

      <div
        className={styles.archiveDropdownWrapper}
        ref={wrapperRef}
        style={{
          top: offsetTop,
          right: offsetRight,
          display: isOpen ? "block" : "none",
        }}
      >
        <Whiteframe padding={false} className={styles.archiveDropdown}>
          <CurrentCaseInfo />
          <SwitchFolder />
        </Whiteframe>
      </div>
    </div>
  )
}

const AddNewCaseFolder = () => {
  return (
    <div className={styles.plusBtn}>
      <Link to="/newCaseFolder">
        <FontAwesomeIcon className={styles.plusIcon} icon="plus" />
        <div>Skapa ny akt</div>
      </Link>
    </div>
  )
}

const EditCurrentCase = withRouter((props) => {
  const params = props.match.params
  const url = `/dashboard/c/${params.caseFolderId}/true`
  const onClick = () => {
    if (params.caseFolderId !== undefined) {
      props.history.push(url)
    }
  }

  return (
    <div className={styles.cogWrapper}>
      <div onClick={onClick} className={styles.cogBtn}>
        <FontAwesomeIcon className={styles.cogIcon} icon="cog" />
      </div>
    </div>
  )
})

const CurrentCaseInfo = withRouter((props) => {
  return (
    <Query<GetCaseFolders> query={GET_DASHBOARD_CASE_FOLDERS}>
      {({ loading, data }) => {
        if (loading) {
          return <ClipLoader size={15} color={"#123abc"} />
        }
        if (!data || !data!.findCaseFoldersByCurrentCustomer) {
          return <></>
        }
        const params: any = props.match.params
        const caseFolders = data.findCaseFoldersByCurrentCustomer.caseFolders!.filter(
          notEmpty
        )
        const caseFolder = caseFolders.find((x) => x.id === params.caseFolderId)
        return (
          <>
            {caseFolder !== undefined ? (
              <>
                <EditCurrentCase />
                <div className={styles.currentCaseInfoWrapper}>
                  <div>
                    <div>Nuvarande akt</div>
                    <div>
                      <strong>{caseFolder.name}</strong>
                    </div>
                  </div>
                </div>
              </>
            ) : null}
          </>
        )
      }}
    </Query>
  )
})

const SwitchFolder = () => {
  return (
    <>
      <div className={styles.trashBtn}>
        <Link to={"/archive"}>
          <FontAwesomeIcon className={styles.trashIcon} icon="archive" />
          <span>Alla akter</span>
        </Link>
      </div>
    </>
  )
}

const CalendarButtonWithDropdown = () => {
  const [isOpen, setIsOpen] = React.useState(false)
  const { t } = useTranslation("navbar")
  const buttonRef = React.useRef<HTMLDivElement>(null)
  const [offsetRight, setOffsetRight] = React.useState(0)
  const [offsetTop, setOffsetTop] = React.useState(0)
  const onMouseEnter = () => {
    setIsOpen(true)
    if (buttonRef.current) {
      const btnRect = buttonRef.current.getBoundingClientRect()
      setOffsetTop(btnRect.height + btnRect.top - 3)
      const dropdownWidth = 850
      if (window.innerWidth >= 2150) {
        const dropdownRightOffset = 0
        setOffsetRight(
          window.innerWidth -
            btnRect.right +
            btnRect.width / 2 -
            dropdownWidth / 2 +
            dropdownRightOffset
        )
      } else if (window.innerWidth < 2150) {
        setOffsetRight(55)
      }
    }
  }
  const onClickOutsite = () => {
    setIsOpen(false)
  }
  const onMouseLeave = () => {
    setIsOpen(false)
  }
  const wrapperRef = React.useRef(null)
  useOutsideAlerter(wrapperRef, onClickOutsite)

  return (
    <div onMouseLeave={onMouseLeave} className={styles.calendarWrapper}>
      <div
        onMouseEnter={onMouseEnter}
        className={styles.calendarButton}
        ref={buttonRef}
      >
        <FontAwesomeIcon className={styles.calendarIcon} icon="calendar-alt" />
        <div>{t("calendar")}</div>

        {isOpen === true ? (
          <span className={styles.dotIcon}>&nbsp;</span>
        ) : null}
      </div>

      <div
        className={styles.calendarDropdownWrapper}
        ref={wrapperRef}
        style={{
          top: offsetTop,
          right: offsetRight,
          display: isOpen ? "block" : "none",
        }}
      >
        <Whiteframe>
          <PublishingsContainer />
        </Whiteframe>
      </div>
    </div>
  )
}

export default Navbar
