import React from "react"
import { GlobalErrorModal } from "components/GlobalErrorModal/GlobalErrorModal"
import { ApolloError } from "apollo-client"
import { error as appLogError } from "appLog"

export interface GlobalErrorContextProps {
  data: {
    modalVisible: boolean
    message: string
    error?: ApolloError
  }
  showMessage?: ({ message, error }: { message: string; error?: any }) => void
}

const initStateData: GlobalErrorContextProps["data"] = {
  modalVisible: false,
  message: "",
  error: undefined,
}

export const GlobalErrorContex = React.createContext<GlobalErrorContextProps>({
  data: initStateData,
  showMessage: undefined,
})

export class GlobalErrorData extends React.Component<
  { children: any },
  GlobalErrorContextProps["data"]
> {
  constructor(props: any) {
    super(props)
    this.state = initStateData
  }

  showMessage = ({
    message,
    error,
  }: {
    message: string
    error?: ApolloError
  }) => {
    this.setState({
      modalVisible: true,
      message,
      error,
    })
    // appLogError(`GlobalErrorData::showMessage: ${error}`)
  }

  closeModal = () => {
    this.setState({
      modalVisible: false,
      message: "",
    })
  }

  afterOpenModal = () => {
    //
  }

  render() {
    return (
      <React.Fragment>
        {this.state.modalVisible === true ? (
          <GlobalErrorModal
            message={this.state.message}
            modalIsOpen={this.state.modalVisible}
            closeModal={this.closeModal}
            afterOpenModal={this.afterOpenModal}
            error={this.state.error}
          />
        ) : null}

        <GlobalErrorContex.Provider
          value={{ data: this.state, showMessage: this.showMessage }}
        >
          {this.props.children}
        </GlobalErrorContex.Provider>
      </React.Fragment>
    )
  }
}
