import * as React from "react"
import * as styles from "./styles.scss"
import Button from "components/Button"
import { useTranslation } from "react-i18next"

function SubmitButton(props: SubmitButtonProps) {
  const [t] = useTranslation()

  return (
    <div className={styles.buttonContainer}>
      <Button
        variant="primary"
        disabled={props.disabled}
        color="primary"
        onClick={props.onSubmit}
        className={styles.submitButton}
      >
        {t("send")}
      </Button>
    </div>
  )
}

interface SubmitButtonProps {
  onSubmit: () => void
  disabled: boolean
}

export default SubmitButton
