import * as React from "react"
import * as styles from "./styles.scss"
import MessageButton from "./MessageButton"
import { PublishOrderContext } from "../.."
import OrderNumberButton from "./OrderNumberButton"
import SubscriberNumberButton from "./SubscriberNumberButton"
import LateCodeButton from "./LateCodeButton"

const ConversationButtons: React.FC<{
  msgToMedia: string
}> =(props) =>  {
  const { publishOrder } = React.useContext(PublishOrderContext)
  const pc = publishOrder.order.mediaOrder!.publishConversation

  // console.log(props.msgToMedia)
  // if (publishOrder.order.mediaOrder!.isLocked) {
  //   return (
  //   <div className={styles.buttonsContainer}> 
  //         {(pc.isMessageToMedia) && (
  //            <MessageButton isEdit={publishOrder.messageToMedia !== ""}  />
  //          )}
  //   </div>)
  // }
    
  return (
    <div className={styles.buttonsContainer}>
      {(pc.isMessageToMedia) && (
        <MessageButton isEdit={publishOrder.messageToMedia !== ""} msgToMedia={props.msgToMedia}/>
      )}
      {pc.isMediaSystemOrderNumber && (
        <OrderNumberButton
          isEdit={publishOrder.mediaSystemOrderNumber !== ""}
        />
      )}
      {pc.isMediaCustomerSubriberNumber && (
        <SubscriberNumberButton
          isEdit={publishOrder.mediaCustomerSubriberNumber !== ""}
        />
      )}
      {pc.isCodeLate && (
        <LateCodeButton isEdit={publishOrder.codeLate !== ""} codeLate={pc.codeLate}/>
      )}
    </div>
  )
}

export default ConversationButtons
