import gql from "graphql-tag"
import ORDER_FRAGMENT from "graphql/fragments/OrderFragment"

const UPDATE_ALL_ORDERS_BY_FIELD = gql`
  mutation UpdateAllOrdersByField(
    $input: MutationUpdateOrdersContentInput!
  ) {
    updateOrdersContent(input: $input) {
      orders {
        ...OrderFragment
      }
    }
  }
  ${ORDER_FRAGMENT}
`

export default UPDATE_ALL_ORDERS_BY_FIELD
