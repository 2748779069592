import * as React from "react"
import {
  GetPackages_orderInitiatorPackagesByCurrentUser,
  // GetOrderInitiatorRootNode_orderInitiatorRootNode_children,
  SelectPackage,
} from "schema"
import PackageCard from "./PackageCard"
import { Mutation } from "react-apollo"
import SELECT_PACKAGE from "graphql/mutations/selectPackage"

class Packages extends React.Component<PackagesProps, PackagesState> {
  state = {
    packageIdLoading: undefined,
    packageInEditModeId: "",
    orderPackages: {},
    tempOrders: [],
  }

  initiateOrderPackages = () =>
    this.setState({
      orderPackages: this.props.packages.reduce(
        (acc, orderPackage) => ({
          ...acc,
          [orderPackage.id!]: {
            ...orderPackage,
          },
        }),
        {}
      ),
    })

  componentDidMount = () => this.initiateOrderPackages()

  setPackageIdLoading = (packageId: string) =>
    this.setState({ packageIdLoading: packageId })

  // handleAddOrder = (
  //   node: GetOrderInitiatorRootNode_orderInitiatorRootNode_children,
  //   orderPackageId: string
  // ) => {
  //   const { orderPackages } = this.state
  //   this.setState({
  //     orderPackages: {
  //       ...orderPackages,
  //       [orderPackageId]: {
  //         ...orderPackages[orderPackageId],
  //         items: [
  //           ...orderPackages[orderPackageId].items,
  //           {
  //             documentFormatInitiator: {
  //               documentFormat: {
  //                 documentType: {
  //                   name: node.orderInitiator?.documentFormatInitiator?.documentFormat.documentType.name,
  //                 },
  //               },
  //             },
  //             media: {
  //               id: node.orderInitiator!.media.id,
  //               name: node.orderInitiator!.media.friendlyName,
  //             },
  //             inputId: {
  //               documentFormatInitiatorId: node.orderInitiator!.inputId
  //                 .documentFormatInitiatorId,
  //               mediaId: node.orderInitiator!.inputId.mediaId,
  //             },
  //           },
  //         ],
  //       },
  //     },
  //   })
  // }

  handleDeleteOrder = (index: number, orderPackageId: string) => {
    const { orderPackages } = this.state
    this.setState({
      orderPackages: {
        ...orderPackages,
        [orderPackageId]: {
          ...orderPackages[orderPackageId],
          items: orderPackages[orderPackageId].items.filter(
            (item: any, i: number) => index !== i
          ),
        },
      },
    })
  }

  render() {
    const { orderPackages, packageIdLoading } = this.state

    return (
      <Mutation<SelectPackage> mutation={SELECT_PACKAGE}>
        {(selectPackage) =>
          Object.keys(orderPackages).map((orderPackageId) => {
            return (
              <PackageCard
                key={orderPackageId}
                orderPackage={orderPackages[orderPackageId]}
                onSelectPackage={selectPackage}
                onSetPackageIdLoading={this.setPackageIdLoading}
                packageIdLoading={packageIdLoading}
                // onAddOrder={this.handleAddOrder}
                onDeleteOrder={this.handleDeleteOrder}
                caseFolderId={this.props.caseFolderId}
              />
            )
          })
        }
      </Mutation>
    )
  }
}

interface PackagesProps {
  packages: GetPackages_orderInitiatorPackagesByCurrentUser[]
  caseFolderId?: string
}

interface PackagesState {
  packageIdLoading?: string
  orderPackages: any
}

export default Packages
