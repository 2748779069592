import * as React from "react"
import * as styles from "./styles.scss"
import * as cx from "classnames"
import "react-datepicker/dist/react-datepicker-cssmodules.css"
import Calendar from "components/Calendar"
import { FieldRenderProps } from "react-final-form"
import DatePicker from "react-datepicker"
import CalendarIcon from "components/Calendar/CalendarIcon"
import { useTranslation } from "react-i18next"

class DateAndTimeField extends React.PureComponent<
  DateFieldProps & FieldRenderProps<any, any>
> {
  handleChange = (date: Date) => {
    const { onDateSelect, input } = this.props

    input.onChange(date)
    if (onDateSelect) onDateSelect(date)
  }

  render() {
    const { includeDates, onDateSelect, ...rest } = this.props

    return (
      <Calendar
        fieldRenderProps={rest}
        onChange={this.handleChange}
        includeDates={includeDates}
        showTimeSelect={true}
        selectedDate={
          rest.input.value === "" ? null : new Date(rest.input.value)
        }
      />
    )
  }
}

export const FormikDateAndTimeField: React.FC<{
  ref?: React.RefObject<any>
  placeholder?: string
  type?: string
  input?: any
  value?: Date | null | undefined
  name?: string
  disabled?: boolean
  autoFocus?: boolean
  showTimeSelect?: boolean
  inline?: boolean
  className?: any
  highlightDates?: any[]
  includeDates?: any[]
  id?: string
  onChange?: (event: Date) => void
}> = (props) => {
  const handleChange = (
    date: Date | null,
    event: React.SyntheticEvent<any, Event> | undefined
  ) => {
    if (props.onChange) {
      if (date) {
        props.onChange(date)
      }
    }
    // if (props.onDateSelect) {
    //   props.onDateSelect(date)
    // }
  }

  const { t } = useTranslation(["dateFormats", "common"])
  // const { includeDates, onDateSelect, input, ...rest } = props

  return (
    <div
      className={cx(styles.container, props.className, {
        [styles.inline]: props.inline,
        itIsTime: props.showTimeSelect
      })}
    >
      {React.createElement(DatePicker, {
        // ref: (datePicker: any) => (this.datePicker = datePicker),
        disabledKeyboardNavigation: true,
        inline: props.inline,
        // dateFormat: t("date"),
        locale: t("common:locale"),
        id: props.id,
        selected: props.value,
        placeholderText: t("datePlaceholder"),
        calendarClassName: cx(styles.dateField, {
          [styles.customWidth]: props.showTimeSelect === true,
          [styles.inline]: props.inline,
        }),
        className: cx(styles.dateFieldInput, {
          [styles.disabled]: props.disabled,
          withTime: props.showTimeSelect === true,
        }),
        highlightDates: props.highlightDates,
        includeDates: props.includeDates,
        onChange: handleChange,
        disabled: props.disabled,
        timeFormat:
          props.showTimeSelect && props.showTimeSelect === true
            ? "HH:mm"
            : undefined,
        timeIntervals: 15,
        showTimeSelect: props.showTimeSelect,
        // showTimeSelect && showTimeInput === true ? showTimeInput : undefined,
        timeInputLabel: "Time:",
        dateFormat: props.showTimeSelect ? t("dateAndTime") : t("date"),
      })}
      <CalendarIcon disabled={props.disabled} />
    </div>
  )
}

interface DateFieldProps {
  includeDates?: Date[]
  onDateSelect?: (date: Date) => void
}

export default DateAndTimeField
