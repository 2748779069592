import gql from "graphql-tag"
import SETTINGS_FRAGMENT from "graphql/fragments/SettingsFragment"

const UPDATE_AGENCY_SETTINGS = gql`
  mutation UpdateAgencySettings(
    $input: MutationUpdateCurrentCustomerSettingsInput!
  ) {
    updateCurrentCustomerSettings(input: $input) {
      customer {
        id
        settings {
          ...SettingsFragment
        }
        inheritSettings {
          ...SettingsFragment
        }
        office {
          id
          settings {
            ...SettingsFragment
          }
          inheritSettings {
            ...SettingsFragment
          }
          company {
            id
            settings {
              emailInvoicing
            }
            inheritSettings {
              emailInvoicing
            }
          }
        }
      }
      isError
    }
  }
  ${SETTINGS_FRAGMENT}
`

export default UPDATE_AGENCY_SETTINGS
