import * as React from "react"
import Button from "components/Button"
import { useTranslation } from "react-i18next"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { produce } from "immer"
import Dropdown from "components/Dropdown"
import DeleteOrderModal from "../OrderPreview/Toolbar/Hamburger/DeleteOrderModal"
import LockOrderModal from "../OrderPreview/Toolbar/Hamburger/LockOrderModal"
import styled from "styled-components"
import { AppContext } from "App"
import { useHistory } from "react-router-dom"
import {
  SEARCH_KEY,
  SEARCH_KEY_VALUE,
} from "pages/EditOrderPage/DeleteOrderModalBlock"
import UnpublishModal from "components/UnpublishModal"
import UserLogModal from "components/UserLogsModal"

export const SubMenu: React.FC<{
  isLocked?: boolean
  publishTime?: string
  documentId?: string
  selectedCaseFolderId: string
  refetchQuery: () => void
  isActivated: boolean
  unPublishButtonText?: string
  onUnPublishOrder?: () => void
  isUnlockButton?: boolean
  unlockButtonText?: string
  isUnpublishButton?: boolean
  isReactiveButton?: boolean
  reactiveText?: string
  onReactiveOrder?: () => void
  orderId?: string
  isSupport?: boolean
}> = (props) => {
  const history = useHistory()
  const { t } = useTranslation(["editOrder"])
  const [state, setState] = React.useState({
    lockModalIsOpen: false,
    deleteModalIsOpen: false,
    isUnpublishModalOpen: false,
  })
  const [isOpenLogger, setIsOpenLogger] = React.useState(false)

  const openDeleteModal = () => {
    const { search } = window.location
    const urlParams = new URLSearchParams(search)
    urlParams.set(SEARCH_KEY, SEARCH_KEY_VALUE)
    const newSearch = urlParams.toString()
    history.push({
      search: newSearch,
    })
  }

  const openLockModal = () => {
    if (!props.publishTime) return
    setState(
      produce(state, (draft) => {
        draft.lockModalIsOpen = true
      })
    )
  }
  const closeLockModal = () => {
    setState(
      produce(state, (draft) => {
        draft.lockModalIsOpen = false
      })
    )
    if (props.refetchQuery) {
      props.refetchQuery()
    }
  }

  const onOpenUnpublishModal = () => {
    setState(
      produce(state, (draft) => {
        draft.isUnpublishModalOpen = true
      })
    )
  }

  const onCloseUnpublishModal = () => {
    setState(
      produce(state, (draft) => {
        draft.isUnpublishModalOpen = false
      })
    )
  }

  const onOpenLoggerModal = () => {
    setIsOpenLogger(true)
  }

  const onCloseLoggerModal = () => {
    setIsOpenLogger(false)
  }

  return (
    <React.Fragment>
      <AppContext.Consumer>
        {({ currentUserSession }) => {
          const canDelete = currentUserSession.user?.isSuperUser ?? false
          const canLockOrUnlock =
            props.isActivated === true && props.isLocked !== undefined

          const showSubmenu = canDelete === true || canLockOrUnlock

          if (showSubmenu === false) {
            return <></>
          }

          return (
            <HamburgerStyles>
              <div className={"toolbar"}>
                <Dropdown
                  contentClassName={"dropdown-content"}
                  dropdownClassName={"dropdown"}
                  renderToggler={() => (
                    <Button
                      variant="primary"
                      color="primary"
                      className={"toggler"}
                    >
                      <FontAwesomeIcon icon="bars" />
                    </Button>
                  )}
                >
                  <ul className={"list"} data-test-id="edit_order_dropdown">
                    {canLockOrUnlock ? (
                      <>
                        {props.isUnlockButton && (
                          <li onClick={openLockModal}>
                            {props.unlockButtonText}
                          </li>
                        )}
                      </>
                    ) : null}

                    {props.isUnpublishButton && (
                      <li onClick={onOpenUnpublishModal}>
                        {props.unPublishButtonText}
                      </li>
                    )}

                    {props.isReactiveButton && (
                      <li onClick={onOpenUnpublishModal}>
                        {props.reactiveText}
                      </li>
                    )}
                    {canDelete ? (
                      <li data-test-id="deleteItem" onClick={openDeleteModal}>
                        {t("deleteItem")}
                      </li>
                    ) : null}
                    {props.isSupport && (
                      <li onClick={onOpenLoggerModal}>Visa loggar</li>
                    )}
                  </ul>
                </Dropdown>
                {isOpenLogger && (
                  <UserLogModal
                    onClose={onCloseLoggerModal}
                    isOpen={isOpenLogger}
                    orderId={props.orderId!}
                  />
                )}

                {canLockOrUnlock && props.isLocked !== undefined ? (
                  <LockOrderModal
                    isOpen={state.lockModalIsOpen}
                    isLocked={props.isLocked}
                    title={
                      props.isLocked === true
                        ? t("editOrder:lockLivestreamTitle")
                        : undefined
                    }
                    body={
                      props.isLocked === true
                        ? t("editOrder:lockLivestreamBody")
                        : undefined
                    }
                    okButtonText={
                      props.isLocked === true
                        ? t("editOrder:lockLivestreamBtn")
                        : undefined
                    }
                    onRequestClose={closeLockModal}
                    documentId={props.documentId}
                  />
                ) : null}
              </div>
            </HamburgerStyles>
          )
        }}
      </AppContext.Consumer>
      <UnpublishModal
        isOpen={state.isUnpublishModalOpen}
        onClose={onCloseUnpublishModal}
        onUnPublishOrder={props.onUnPublishOrder!}
        unpublishText={props.unPublishButtonText!}
        isUnPublishButton={props.isUnpublishButton!}
        reactiveButtonText={props.reactiveText!}
        isReactivateButton={props.isReactiveButton!}
        onReactiveOrder={props.onReactiveOrder!}
      />
    </React.Fragment>
  )
}

export const HamburgerStyles = styled.div`
  .toolbar {
    position: absolute;
    z-index: 2;
    top: 27px;
    right: 50px;
  }
  .dropdown {
    display: flex;
    position: absolute;
    top: 0;
    bottom: 0;
    align-items: center;
    right: ${(props) => Number(props.theme["--spacing"]) / 2};
  }

  .dropdown-content {
    width: max-content;
    right: 0;
    top: 15px;
  }

  .toggler {
    display: flex;
    justify-content: center;
    border-radius: 50%;
    height: 35px;
    width: 35px;
  }

  .list {
    color: ${(props) => props.theme["--dark-gray"]};
    background-color: $white;
    border-radius: ${(props) => props.theme["--border-radius"]};
    width: 100%;
    margin-bottom: 0;
    box-shadow: ${(props) => props.theme["--box-shadow"]};
    padding: 5px 10px;
    background-color: white;

    li {
      padding: 8px 16px;
      transition: ${(props) => props.theme["--transition-duration-short"]} all;
      cursor: pointer;
      width: 180px;

      &:hover:not(.disabled) {
        color: ${(props) => props.theme["--secondary"]};
      }
    }
  }

  li.disabled {
    color: ${(props) => props.theme["--medium-gray"]};
    cursor: default;
  }
`
