import * as React from "react"
import * as styles from "./styles.scss"
import OrderRow from "./OrderRow"
import {
  GetAdminCaseFolders_findCaseFoldersByConditions_caseFolders_documents,
  GetAdminCaseFolders_findCaseFoldersByConditions_caseFolders,
} from "schema"
import { notEmpty } from "utils"

function Document(props: DocumentProps) {
  const { document, caseFolder } = props

  return (
    <React.Fragment>
      <span className={styles.documentType}>
        {document.documentFormat.mediaAdaptedName}
      </span>
      <div className={styles.orderRows}>
        {document.orders.filter(notEmpty).map(order => (
          <OrderRow
            key={order.idString!}
            documentId={document.idString ?? ""}
            caseFolderId={caseFolder.idString ?? ""}
            orderId={order.idString ?? ""}
            customerId={caseFolder.customer.idString ?? ""}
            customerFriendlyId={order.customerFriendlyId ?? ""}
            order={order}
            caseFolder={caseFolder}
          />
        ))}
      </div>
    </React.Fragment>
  )
}

interface DocumentProps {
  document: GetAdminCaseFolders_findCaseFoldersByConditions_caseFolders_documents
  caseFolder: GetAdminCaseFolders_findCaseFoldersByConditions_caseFolders
}

export default Document
