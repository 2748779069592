import * as React from "react"
import * as styles from "./styles.scss"
import Toggler from "./Toggler"
import PublishDate from "./PublishDate"
import AdvertHeight from "./AdvertHeight"
import ConversationButtons from "./ConversationButtons"
import AdvertPrices from "./AdvertPrices"
import { PublishOrderContext } from ".."
import cx from "classnames"
import { useParams } from "react-router-dom"

interface IParams {
  caseFolderId: string
  orderId: string
}

const OrderRow: React.FC<{
  messageToMedia: string
  orderId: string
}> = (props) => {
  const { publishOrder, fakeProgressStarted } = React.useContext(
    PublishOrderContext
  )
  let params: IParams = useParams()
  let isPublished =
    publishOrder.order.mediaOrder?.publishConversation.isBookingSent

  return (
    <>
    <div
      id={props.orderId}
      className={cx(styles.orderRow, {
        [styles.selected]: publishOrder.isSelected,
        [styles.progressStarted]: fakeProgressStarted,
        [styles.published]: isPublished,
        [styles.currentOrder]: props.orderId === params.orderId,
      })}
    >
      <div className={styles.row}>
        <Toggler params={params} />
        <PublishDate />
        <AdvertHeight />
        <ConversationButtons msgToMedia={props.messageToMedia} />
        <AdvertPrices />
      </div>
      {/* <div className={styles.row}>
        <span className={styles.messageToMedia}>{props.messageToMedia}</span>
      </div> */}
    </div>
    </>
  )
}

// interface OrderRowProps {
//   messageToMedia: string
// }

export default React.memo(OrderRow)
