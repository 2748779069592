import * as React from "react"
import * as styles from "./styles.scss"
import SelectionSlider, { Tab } from "../SelectionSlider"
import {
  GetEditOrderPageCaseFolder_caseFolder_editTabs,
  GetEditOrderPageCaseFolder_caseFolder_editTabs_orders_alerting,
  GetEditOrderPageCaseFolder_caseFolder_editTabs_orders_mediaOrder_status,
  OrderAlertingType,
  StatusColors,
} from "schema"
import { useHistory, useParams } from "react-router-dom"
import Button from "components/Button"
import { useTranslation } from "react-i18next"
import { produce } from "immer"
import ReactTooltip from "react-tooltip"
import ReactDOMServer from "react-dom/server"
import { createGlobalStyle } from "styled-components"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Status from "components/Status" 

const OrderSelection: React.FC<{
  editTabs: GetEditOrderPageCaseFolder_caseFolder_editTabs[]
  caseFolderId: string
  documentId?: string
  action?: string
  refetch: () => void
}> = (props) => {
  const history = useHistory()
  const params = useParams<{ orderId?: string }>()
  const [state, setState] = React.useState<OrderSelectionState>({
    editTabs: [],
    selectedEditTab: undefined,
  })

  const initiateSelectedDocument = () => {
    setState(
      produce(state, (draft) => {
        draft.selectedEditTab = props.editTabs.find((doc) => {
          return (
            doc.orders.find((order) => order!.id === props.documentId) !==
            undefined
          )
        })
      })
    )
  }

  const currentEditTabs = props.editTabs.find(
    (tab) => tab.name === "Begravningsannons"
  )

  const orderLock = currentEditTabs?.orders.find((order) => 
      order.id === params.orderId
  )?.mediaOrder?.isLocked

  const handleDocumentTabClick = (
    document: GetEditOrderPageCaseFolder_caseFolder_editTabs
  ) => {
    setState(
      produce(state, (draft) => {
        draft.selectedEditTab = document
      })
    )
    if (document.orders[0] && document.orders[0].id) {
      handleOrderTabClick(document.orders[0].id)
    }
  }
  const handleOrderTabClick = (id: string) => {
    history.push(`/editOrder/c/${props.caseFolderId}/o/${id}`)
  }
  const renderAddMediaButton = () => (
    <AddMediaButtonWrapper show={state.selectedEditTab !== undefined} />
  )
  const renderAddProductButton = () => <AddProductButtonWrapper />

  React.useEffect(() => {
    setState(state)
    initiateSelectedDocument()
  }, [props.documentId, state.editTabs, orderLock])

  return (
    <div className={styles.selectionContainer}>
      <div className={styles.selection}>
        <div className={styles.selectionlevel1}>
          <SelectionSlider
            tabs={props.editTabs.map((document) => ({
              id: document.id!,
              value: document,
              label: document.name,
            }))}
            onClick={handleDocumentTabClick}
            selectedId={
              state.selectedEditTab ? state.selectedEditTab.id! : undefined
            }
            renderRightContent={renderAddProductButton}
          />
        </div>

        {state?.selectedEditTab?.isSubTabs && (
          <div>
            <div className={styles.selectionlevel2}>
              <SelectionSlider
                tabs={
                  state.selectedEditTab
                    ? state.selectedEditTab.orders.map((order) => {
                        return {
                          id: order!.id!,
                          value: order!.id!,
                          label: order!.mediaOrder!.media.friendlyName,
                          alerting: order.alerting,
                          status: order.mediaOrder?.status,
                          statusColor:
                            order.mediaOrder?.status.color ??
                            StatusColors.YELLOW,
                          isLocked: order.mediaOrder?.isLocked,
                        }
                      })
                    : []
                }
                showStatusDots={true}
                onClick={handleOrderTabClick}
                variant="secondary"
                selectedId={params.orderId}
                selectedDocumentId={
                  state.selectedEditTab ? state.selectedEditTab.id! : undefined
                }
                renderRightContent={renderAddMediaButton}
              />
            </div>

            <ReactTooltip
              id={"tab-alerts"}
              className={"tab-alerts"}
              html={true}
              getContent={(dataTip: string) => {
                const data = JSON.parse(dataTip) ?? {}
                return ReactDOMServer.renderToString(
                  <AlertBlock alerting={data.alerting} status={data.status} />
                )
              }}
            />
            <GlobalTooltipStyle />
          </div>
        )}
      </div>
      <div className={styles.filler} />
    </div>
  )
}

const AlertBlock: React.FC<{
  alerting?: GetEditOrderPageCaseFolder_caseFolder_editTabs_orders_alerting
  status?: GetEditOrderPageCaseFolder_caseFolder_editTabs_orders_mediaOrder_status
}> = (props) => {
  if (props.alerting === undefined) {
    return <></>
  }
  if (props.alerting === null) {
    return <></>
  }

  return (
    <div>
      {props.status !== undefined ? (
        <div className={"alert-content-wrapper"}>
          {!(
            props.alerting.type === OrderAlertingType.CRITICAL ||
            props.alerting.type === OrderAlertingType.WARNING
          ) ? (
            <Status
              circleFirst={true}
              showStatusLabel={false}
              variant={props.status.color}
            />
          ) : null}

          {props.alerting.type === OrderAlertingType.CRITICAL ? (
            <div className={"icon-wrapper"}>
              <FontAwesomeIcon icon="exclamation-triangle" color={"#FF5327"} />
            </div>
          ) : null}

          {props.alerting.type === OrderAlertingType.WARNING ? (
            <div className={"icon-wrapper"}>
              <FontAwesomeIcon icon="exclamation-triangle" color={"#fea15e"} />
            </div>
          ) : null}

          <span
            key={props?.alerting?.smallText ?? ""}
            className={`alert-messages text-color-${props.alerting.type}`}
          >
            {props.alerting.text}
          </span>
        </div>
      ) : null}
    </div>
  )
}

const GlobalTooltipStyle = createGlobalStyle`
  #tab-alerts.tab-alerts {
    background:#fff;
    border: 1px solid #d8d8d8;
    .alert-content-wrapper{
      display: flex;
    }
    .icon-wrapper{
      margin-right: 10px;
    }
    .alert-messages{
      display: flex;
    }
    .text-color-CRITICAL{
      color: #FF5327;
    }
    .text-color-WARNING{
      color: #fea15e;
    }
    .text-color-NONE{
      color: #000;
    }
    /* &:after {
        bottom: -6px !important;
        border-top-color: #fb7771 !important;
        border-top-style: solid !important;
        border-top-width: 6px !important;
        border-bottom: none !important;
        top: unset !important;
    } */
     &.place-right:before{
      border-right-color:#d8d8d8;
    }
    &.place-top:before{
      border-top-color:#d8d8d8;
    } 
  }
  
`

const AddProductButtonWrapper: React.FC<{}> = () => {
  const [t] = useTranslation(["common"])

  const history = useHistory()
  const onClick = () => {
    const { search } = window.location
    const urlParams = new URLSearchParams(search)
    urlParams.set("action", "add_product_casefolder")
    const newSearch = urlParams.toString()
    history.push({
      search: newSearch,
    })
  }
  return (
    <div className={styles.addProducButton}>
      <Button
        variant="tertiary"
        className={styles.orderButton}
        icon="plus-circle"
        onClick={onClick}
      >
        {t("common:addProduct")}
      </Button>
    </div>
  )
}

const AddMediaButtonWrapper: React.FC<{ show: boolean }> = (props) => {
  const [t] = useTranslation(["editOrder"])
  const history = useHistory()

  if (props.show === false) {
    return null
  }

  const onClick = () => {
    const { search } = window.location
    const urlParams = new URLSearchParams(search)
    urlParams.set("action", "add_product_group")
    const newSearch = urlParams.toString()
    history.push({
      search: newSearch,
    })
  }

  return (
    <Button
      variant="tertiary"
      className={styles.addOrderButton}
      icon="plus-circle"
      onClick={onClick}
    >
      <span>{t("editOrder:addMedia")}</span>
    </Button>
  )
}

interface OrderSelectionState {
  selectedEditTab?: GetEditOrderPageCaseFolder_caseFolder_editTabs
  editTabs: OrderSelectionDocument[]
}

interface OrderSelectionDocument extends Tab {
  orders: Tab[]
}

export default OrderSelection
