import * as React from "react"
import Button from "components/Button"
import { useTranslation } from "react-i18next"

function ColumnsButton(props: ColumnsButtonProps) {
  const [t] = useTranslation("editOrder")

  return (
    <Button
      onClick={props.onClick}
      narrow={true}
      icon="columns"
      className={props.className}
    >
      {t("columns")}
    </Button>
  )
}

interface ColumnsButtonProps {
  onClick: () => void
  className?: string
}

export default ColumnsButton
