import * as React from "react"
import * as styles from "./styles.scss"
import cx from "classnames"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { IconProp } from "@fortawesome/fontawesome-svg-core"

class TextAlignment extends React.PureComponent<TextAlignmentProps> {
  handleClick = () => this.props.onClick(this.props.value)

  render() {
    return (
      <div
        className={cx(styles.iconContainer, {
          [styles.active]: this.props.active,
        })}
        onClick={this.handleClick}
      >
        <FontAwesomeIcon icon={this.props.icon} />
      </div>
    )
  }
}

interface TextAlignmentProps {
  active: boolean
  onClick: (textAlignment: string) => void
  icon: IconProp
  value: string
}

export default TextAlignment
