import * as React from "react"
import * as styles from "./styles.scss"
import { AvailableViews } from ".."
import ViewItem from "./ViewItem"
import { useTranslation } from "react-i18next"
import Advanced from "./Advanced"

function ViewSelectRow(props: ViewSelectRowProps) {
  const [t] = useTranslation("supportView")

  return (
    <div className={styles.viewSelectRow}>
      <div className={styles.views}>
        <ViewItem
          view="order"
          label={t("ad")}
          icon={["far", "newspaper"]}
          {...props}
        />
        <ViewItem
          view="customer"
          label={t("customer")}
          icon="user"
          {...props}
        />
      </div>
      {props.currentView === "order" && (
        <Advanced
          showAdvanced={props.showAdvanced}
          onClick={props.toggleShowAdvanced}
        />
      )}
    </div>
  )
}

interface ViewSelectRowProps {
  currentView: AvailableViews
  showAdvanced: boolean
  onChangeCurrentView: (view: AvailableViews) => void
  toggleShowAdvanced: () => void
}

export default ViewSelectRow
