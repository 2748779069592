import * as React from "react"
import * as styles from "./styles.scss"

class LoginLayout extends React.Component {
  render() {
    return (
      <main className={styles.main}>
        <div className={styles.container}>{this.props.children}</div>
      </main>
    )
  }
}

export default LoginLayout
