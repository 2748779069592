import gql from "graphql-tag"

const DOCUMENT_FORMAT = gql`
  query documentFormat($id: ID!) {
    documentFormat(id: $id) {
      id
      name
      availableStyleTemplates2 {
        id
        name
        color
      }
    }
  }
`

export default DOCUMENT_FORMAT
