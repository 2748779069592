import * as React from "react"
import * as styles from "./styles.scss"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useTranslation} from "react-i18next"
import { AppContext } from "App"

const NoSelectedCaseFolder = () => {
  const { t } = useTranslation()
  const { isShowModeShow } = React.useContext(AppContext)

  return (
    <div className={styles.noneSelected}>
      {isShowModeShow ? (
        <p className={styles.description}>
          Dolt läge aktiverat.
          <br />
          För att skapa en ny akt, tryck på knappen 'Skapa ny akt'.
          <br /> För att arbeta i befintlig akt, tryck på Aktarkiv och sök upp
          önskad akt.
        </p>
      ) : (
        <>
          <FontAwesomeIcon
            icon={["far", "hand-point-left"]}
            className={styles.handIcon}
          />
          <p className={styles.description}>{t("dashboard:selectCaseFolder")}</p>
        </>
      )}
    </div>
  )
}

export default NoSelectedCaseFolder
