import React from "react"
import styles from "../styles.scss"
import Button from "components/Button"
import { motion } from "framer-motion"
import i18next from "i18next"
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const ExpanderText: React.FC<{
  t: i18next.TFunction
}> = (props) => {
  const [isTextExpanded, setTextExpanded] = React.useState<boolean>(false)
  const expandedTextRef = React.useRef<HTMLDivElement | null>(null)

  // React.useEffect(() => {
  //   if (expandedTextRef && expandedTextRef.current && isTextExpanded === true) {
  //     setTimeout(() => {
  //       expandedTextRef.current?.scrollIntoView({
  //         behavior: "smooth",
  //         block: "end",
  //         inline: "nearest",
  //       })
  //     }, 350)
  //   }
  // }, [isTextExpanded])

  const handleExpandInformationText = () => {
    setTextExpanded(!isTextExpanded)
  }
  return (
    <div className={styles.expanderWrapper} ref={expandedTextRef}>
      <Button
        className={styles.toggleButton}
        variant={"none"}
        onClick={handleExpandInformationText}
      >
        {isTextExpanded ? (
          <span>{props.t("common:showLess")}</span>
        ) : (
          <span>
            <FontAwesomeIcon icon={faExclamationCircle} style={{
                marginRight: "10px"
            }} />
            {props.t("common:showMoreInformation")}
          </span>
        )}
      </Button>
      <motion.div
        initial={{ height: 0 }}
        animate={isTextExpanded ? { height: 100 } : { height: 0 }}
        // transition={{
        //   type: "spring",
        //   stiffness: 260,
        //   damping: 50,
        // }}
        className={styles.toggleText}
      >
        <p dangerouslySetInnerHTML={{__html:props.t("designSuggestDescriptionAdvert") }} />
      </motion.div>
    </div>
  )
}

export default ExpanderText
