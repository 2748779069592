import React from "react"
import styles from "./styles.scss"
import Modal from "components/Modal"
import Button from "components/Button"



const MessageModel: React.FC<{
  isOpen: boolean
  doClose: () => void
  message: string
  closeButtonText: string
  linkText?: string
  link?: string
}> = (props) => {

    const handleMoveToBitnet = () => {
      window.location.href = props.link!
  }

  return (
    <Modal isOpen={props.isOpen} onRequestClose={props.doClose}>
      <div className={styles.container}>
        <article>
          <p
            dangerouslySetInnerHTML={{ __html: props.message }}
          ></p>
        </article>
        <div className={styles.buttonClose}>
          <Button onClick={handleMoveToBitnet}>Till Bitnet</Button>
        </div>
      </div>
    </Modal>
  )
}

export default MessageModel
