import ApolloClient from "apollo-client"
import * as React from "react"
import { withRouter, RouteComponentProps, Redirect } from "react-router-dom"
import { Login, LoginVariables } from "schema"
import { useMutation, useQuery } from "react-apollo-hooks"
import LOGIN from "graphql/mutations/login"
import queryString from "query-string"
import { SessionStorageKeys } from "environmentUtils"

function AutoLoginTempSessionToken(props: any) {
  const [redirectUrl, redirectUrlSet] = React.useState<
    string | string[] | null | undefined
  >(undefined)

  const loginMutation = useMutation<Login, LoginVariables>(LOGIN)

  const params = props.match.params as any
  const token = params.token

  React.useEffect(() => {
    loginMutation({
      variables: { userCredentialInput: { tempSessionToken: token } },
    }).then((data) => {
      sessionStorage.setItem(
        SessionStorageKeys.AUTH_TOKEN,
        data.data?.loginByCredential.authorizationToken ?? ""
      )
      localStorage.setItem(
        SessionStorageKeys.AUTH_TOKEN,
        data.data?.loginByCredential.authorizationToken ?? ""
      )

      var params = queryString.parse(props.location.search)
      setTimeout(()=>{
        redirectUrlSet(params.redirect)
      },100);
    })
  }, [])

  // Windows redirect
// {  setTimeout(()=>{
//     if((redirectUrl?.indexOf("autologintempsessiontoken") ?? 0) < 0) window.location.assign( redirectUrl as string );
//   }, 1000);}
  
  // This is not really important, but if we found a better solotion this is might the way to go
  return (
    <React.Fragment>
      {redirectUrl && <Redirect to={redirectUrl as string} />}
    </React.Fragment>
  )
}

export default AutoLoginTempSessionToken
