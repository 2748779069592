import {
  faExchangeAlt,
  faExternalLinkAlt,
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Button from "components/Button"
import React from "react"
import { Link } from "react-router-dom"
import { GetEditOrderPageCaseFolder_caseFolder_editTabs_orders_client_route } from "schema"
import styled from "styled-components"

const ProgramCard: React.FC<{
  route: GetEditOrderPageCaseFolder_caseFolder_editTabs_orders_client_route
}> = (props) => {
  return (
    <ProgramKortContainer>
      <iframe
        width="100%"
        height="900px"
        src={props.route.editPageRoute.taps5IframeAbsUrl!}
        frameBorder={0}
      />
      <a
        href={props.route.editPageRoute.absUrl}
        target="_blank"
        className={"openBtn"}
      >
        <span>Öppna programkort</span>
        <FontAwesomeIcon icon={faExternalLinkAlt} />
      </a>
    </ProgramKortContainer>
  )
}

const ProgramKortContainer = styled.div`
  .openBtn {
    display: flex;
    column-gap: 5px;
    font-weight: 600;
    border: 1px solid #7aa5b4;
    width: 180px;
    padding: 10px;
    border-radius: 3px;
  }
`

export default ProgramCard
