import * as React from "react"
import * as styles from "./styles.scss"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { IconProp } from "@fortawesome/fontawesome-svg-core"

function ConversationButton(props: ConversationButtonProps) {
  return (
    <div className={styles.conversationButton} onClick={props.onClick}>
      <FontAwesomeIcon icon={props.icon} className={styles.icon} />
      <span>{props.label}</span>
    </div>
  )
}

interface ConversationButtonProps {
  label: string | any
  icon: IconProp | any
  onClick: () => void
}

export default ConversationButton
