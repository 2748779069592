import gql from "graphql-tag"

const USER_LOGS = gql`
  query UserLogs($orderId: ID) {
    userLogs(orderId: $orderId) {
      time
      logType
      summeryText
      userName
      customerName
      period
      detailJson
    }
  }
`
export default USER_LOGS