import * as React from "react"
import * as styles from "./styles.scss"
import { motion } from "framer-motion"

const AdminSection: React.FC<{
  isExpanded: boolean
  children: JSX.Element | JSX.Element[]
}> = (props) => {
  const containerRef: React.RefObject<HTMLDivElement> = React.useRef(null)

  React.useEffect(() => {
    const classList = containerRef.current!.classList
    props.isExpanded
      ? classList.add(styles.overflowVisible)
      : classList.remove(styles.overflowVisible)
  }, [props.isExpanded])

  return (
    <motion.div
      animate={props.isExpanded ? "enter" : "exit"}
      ref={containerRef}
      className={styles.adminSectionContainer}
      initial={"exit"}
      variants={{
        enter: { height: "auto" },
        exit: { height: 0 },
      }}
    >
      <div className={styles.adminSection}>{props.children}</div>
    </motion.div>
  )
}

export default AdminSection
