import gql from "graphql-tag"

const GET_CALENDAR_ORDERS = gql`
  query GetCalendarOrders {
    calendarOrdersByCurrentCustomer {
      date
      summary {
        mostCriticalColor
        mostCriticalStatus
      }
      orders {
        orderDate
        id
        alerting{ smallText text type }
        mediaOrder {
          status {
            status
            color
          }
          media {
            id
            friendlyName
          }
        }
        document {
          id
          caseFolder {
            id
            name
          }
        }
      }
    }
  }
`

export default GET_CALENDAR_ORDERS
