import * as React from "react"
import * as styles from "./styles.scss"
import PackageTable from "components/PackageTable"
import EditCaseFolder from "./EditCaseFolder"
import NoPackages from "./NoPackages"
import { GetCaseFolders_findCaseFoldersByCurrentCustomer_caseFolders } from "schema"
import NoSelectedCaseFolder from "./NoSelectedCaseFolder"
import PackageTableHeaderRow from "./PackageTableHeaderRow"
import PackagesList from "./PackagesList"
import CreateCaseFolder from "./CreateCaseFolder"
import Package from "pages/NewCaseFolderPage/EditCaseFolderForm"
import { useHistory, useParams } from "react-router-dom"
import queryString from "query-string"
import { AppContext } from "App"

enum PageStates {
  addOrEditCase = "addOrEditCase",
  selectPackage = "selectPackage",
}

const SelectedCaseFolderView: React.FC<{
  selectedCaseFolder?: GetCaseFolders_findCaseFoldersByCurrentCustomer_caseFolders
  inEditMode: boolean
  onExitEditMode: () => void
  deleteCaseFolder: any
  isCreateMode: boolean
  exitCreateCaseFolder: () => void
  onBackFromPackages: () => void
}> = (props) => {
  const { useState, useEffect, useRef } = React
  const { selectedCaseFolder, inEditMode, onExitEditMode } = props
  const history = useHistory()
  const params = useParams<any>()
  const queryValue: any = queryString.parse(history.location.search)
  const tragetRef = useRef(null)
  const isMediaUser = React.useContext(AppContext).currentUserSession.user
    ?.isMediaUser

  const [pageState, setPageState] = useState<PageStates>(
    queryValue.newcasefolderid === undefined
      ? PageStates.addOrEditCase
      : PageStates.selectPackage
  )

  const onCompleteSaveCase = (caseFolderId: any) => {
    const targetDiv = document.getElementById("packagesContainer")
    if (queryValue.action === "new_case_folder") {
      history.push(`/dashboard/c/${caseFolderId}`)
    }

    setPageState(PageStates.selectPackage)
    targetDiv?.scrollIntoView({ behavior: "smooth" })
  }

  const onBackFromSelectPackage = () => {
    setPageState(PageStates.addOrEditCase)
    props.onBackFromPackages()
    selectedCaseFolder
  }

  const onCompleteSelectPackage = () => {
    // setPageState(PageStates.addOrEditCase)
  }

  return (
    <div
      className={styles.selectedCaseFolderViewContainer}
      id="selectedCaseFolderViewContainer"
    >
      {!props.isCreateMode ? (
        selectedCaseFolder ? (
          selectedCaseFolder.productPackages.length > 0 ? (
            <>
              {inEditMode ? (
                <div className={"editeMode"}>
                  <EditCaseFolder
                    selectedCaseFolder={selectedCaseFolder}
                    onExitEditMode={onExitEditMode}
                    deleteCaseFolder={props.deleteCaseFolder}
                  />
                </div>
              ) : (
                <PackageTableHeaderRow isShowFuneralDetails={true} />
              )}
              <PackagesList
                selectedCaseFolderId={selectedCaseFolder.id}
                selectedPackages={selectedCaseFolder.productPackages as any}
              />
            </>
          ) : (
            <>
              {inEditMode ? (
                <div className={"editeMode"}>
                  <EditCaseFolder
                    selectedCaseFolder={selectedCaseFolder}
                    onExitEditMode={onExitEditMode}
                    deleteCaseFolder={props.deleteCaseFolder}
                  />
                </div>
              ) : (
                <PackageTableHeaderRow isShowFuneralDetails={true} />
              )}
              {/* <NoPackages caseFolderId={selectedCaseFolder.id!} /> */}
              <Package
                isShowBorder={true}
                isActive={true}
                caseFolderId={params.caseFolderId}
                onBack={onBackFromSelectPackage}
                onComplete={onCompleteSelectPackage}
              />
            </>
          )
        ) : (
          <NoSelectedCaseFolder />
        )
      ) : (
        <div className={styles.formContainer}>
          <CreateCaseFolder
            isActive={pageState === PageStates.addOrEditCase}
            onComplete={onCompleteSaveCase}
            exitCreateCaseFolder={props.exitCreateCaseFolder}
            isCreateMode={props.isCreateMode}
            queryValue={queryValue}
            isMediaUser={isMediaUser!}
          />
          <div style={{
            visibility: isMediaUser ? "hidden" : "visible"
          }}>
            <Package
              isActive={pageState === PageStates.selectPackage}
              caseFolderId={params.caseFolderId}
              onBack={onBackFromSelectPackage}
              onComplete={onCompleteSelectPackage}
              ref={tragetRef}
            />
          </div>
        </div>
      )}
    </div>
  )
}
export default SelectedCaseFolderView

// <div className={styles.selectedCaseFolderViewContainer}>
//   {selectedCaseFolder ? (
//     inEditMode ? (
//       <EditCaseFolder
//         selectedCaseFolder={selectedCaseFolder}
//         onExitEditMode={onExitEditMode}
//       />
//     ) : selectedCaseFolder.productPackages.length > 0 ? (
//       <React.Fragment>
//         <PackageTableHeaderRow />
//         <PackagesList
//           selectedCaseFolderId={selectedCaseFolder.id}
//           selectedPackages={selectedCaseFolder.productPackages as any}
//         />
//         {/* <PackageTable
//           selectedCaseFolder={selectedCaseFolder}
//           showPackageHeaders={false}
//         /> */}
//       </React.Fragment>
//     ) : (
//       <NoPackages caseFolderId={selectedCaseFolder.id!} />
//     )
//   ) : (
//     <NoSelectedCaseFolder />
//   )}
// </div>

// interface SelectedCaseFolderViewProps {
//   selectedCaseFolder?: GetCaseFolders_findCaseFoldersByCurrentCustomer_caseFolders
//   inEditMode: boolean
//   onExitEditMode: () => void
//   deleteCaseFolder: any
// }
