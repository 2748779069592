import React from "react"
import { faStar } from "@fortawesome/free-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Button from "components/Button"
import { useTranslation } from "react-i18next"
import styled from "styled-components"
import AdvertSuggestionModal from "./AdvertSuggestionModal"
import {SvgStarsIcon}from "asset/svg/SvgComponents"

const StarsIcon = () => (
  <div>
    {React.cloneElement(<SvgStarsIcon />, {
      size: 23
    })}
  </div>
) 

const SuggestButton: React.FC<{
  orderId: string
}> = (props) => {
  const [t] = useTranslation("editOrder")
  const [isSuggestAdvertsVisible, setSuggestAdvertsVisible] = React.useState<
    boolean
  >(false)

  const handleModalOpen = () => setSuggestAdvertsVisible(true)
  const handleModalClose = () => setSuggestAdvertsVisible(false)
  return (
    <SuggestButtonStyleContainer>
      <button onClick={handleModalOpen} className="button">
        <span>{t("designSuggestion")}</span>
        {StarsIcon()}
      </button>
      {isSuggestAdvertsVisible && (
        <AdvertSuggestionModal
          isOpen={isSuggestAdvertsVisible}
          onClose={handleModalClose}
          orderId={props.orderId}
          t={t}
          />
      )}
    </SuggestButtonStyleContainer>
  )
}

const SuggestButtonStyleContainer = styled.div`
  background-color: #fff;
  .button {
    width: 150px;
    height: 35px;
    padding: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #ccc;
    font-weight: 500;
    background-color: white;
    padding: 5px;
    cursor: pointer;
    border-radius: 3px;
    span {
      padding-top: 2px;
      margin-right: 14px;
      color: #72767a;
    }
    svg {
      font-size: 18px;
      margin-right: 1px;
    }

    &:hover {
      border: 1px solid #1778e4;
      span,
      svg {
        color: #1778e4;
        path {
          fill: #1778e4;
        }
        
      }
    }
  }
`

export default SuggestButton
