import * as React from "react"
import ConfirmationModal from "components/ConfirmationModal"
import { useTranslation } from "react-i18next"
import { useMutation } from "react-apollo-hooks"
import UPDATE_ORDER_LOCK from "graphql/mutations/updateOrderLock"
// import { isLocked } from "../../../ImagePreview/styles.scss"

const LockOrderModal: React.FC<{
  isOpen: boolean
  isLocked: boolean
  documentId?: string
  onRequestClose: () => void
  title?: string
  body?: string
  okButtonText?: string
  orderEntityNameSingular?: string | null
}> = (props) => {
  const [t] = useTranslation("editOrder")
  const [isLoading, setIsLoading] = React.useState(false)
  const lockOrder = useMutation(UPDATE_ORDER_LOCK, {
    variables: {
      input: { orderId: props.documentId, isLockOrUnlock: !props.isLocked },
    },
  })

  function handleLock() {
    setIsLoading(true)
    lockOrder()
      .catch(() => setIsLoading(false))
      .then(() => {
        setIsLoading(false)
        props.onRequestClose()
      })
  }
  const defaultTitle = !props.isLocked ? t("lockOrder") : t("unlockOrder")
  const defaultBody = !props.isLocked  ? t("lockOrderModalText") : t("unlockTextModal")
  const defaultText = !props.isLocked  ? t("lock") : t("unlockTextBtn")
  const title = props.title ? props.title : defaultTitle
  const body = props.body ? props.body : defaultBody
  const okButtonText = props.okButtonText ? props.okButtonText : defaultText

  
  return (
    <ConfirmationModal
      isOpen={props.isOpen}
      title={title}
      text={body}
      okButtonText={okButtonText}
      isLoading={isLoading}
      loadingText={t("lockingOrder")}
      onRequestClose={props.onRequestClose}
      onOKClick={handleLock}
    />
  )
}

export default LockOrderModal
