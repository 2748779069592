import * as React from "react"
import * as styles from "./styles.scss"
import { withRouter, RouteComponentProps } from "react-router-dom"
import * as background from "images/image.png"
import CaseFolderProvider from "./CaseFolderProvider"
import DashboardNews from "./DashboardNews"
import Whiteframe from "components/Whiteframe"
import CaseFolderTable from "./CaseFolderTable"
import SelectedCaseFolderView from "./SelectedCaseFolderView"
import { GetCaseFolders_findCaseFoldersByCurrentCustomer_caseFolders } from "schema"
import MainLayout from "layouts/MainLayout"
import BackgroundImage from "components/BackgroundImage"
import PublishingsContainer from "components/PublishingsContainer"
import { Mutation } from "react-apollo"
import DELETE_CASE_FOLDER from "graphql/mutations/deleteCaseFolder"
import queryString from "query-string"
import NewsProvider from "components/NewsPreview/NewsProvider"
import PopupNews from "./PopupNews"
import TablesContainer from "./TablesContainer"
import { LocalStorageKeys } from "environmentUtils"
import { AppContext } from "App"

export const DashboardContext = React.createContext({} as DashboardContextValue)


interface IState {
   selectedCaseFolderId: any; 
   isCreateMode: boolean; 
   isShowMode: boolean 
}
class DashboardPage extends React.Component<
  RouteComponentProps, IState

> {
  constructor(props: any) {
    super(props)
    this.state = {
      selectedCaseFolderId: null,
      isCreateMode: false,
      isShowMode: false
    }
    this.enterCreateCaseFolder = this.enterCreateCaseFolder.bind(this)
    this.exitCreateCaseFolder = this.exitCreateCaseFolder.bind(this)
    this.onBackFromPackages = this.onBackFromPackages.bind(this)
  }

  enterCreateCaseFolder = () => {
    const targetDiv = document.getElementById("selectedCaseFolderViewContainer")
    this.setState({ isCreateMode: true })
    this.props.history.push(`/dashboard/?action=new_case_folder`)
    targetDiv?.scrollIntoView({ behavior: "auto" })
  }

  exitCreateCaseFolder = () => {
    this.setState({ isCreateMode: false })
    this.props.history.push(`/dashboard`)
  }

  enterEditMode = () => {
    const params: any = this.props.match.params
    this.props.history.push(`/dashboard/c/${params.caseFolderId}/true`)
  }

  exitEditMode = () => {
    const params: any = this.props.match.params
    this.props.history.push(`/dashboard/c/${params.caseFolderId}`)
  }

  onBackFromPackages = () => {
    const queryValue = queryString.parse(this.props.history.location.search)
    this.props.history.replace(
      `/dashboard/?action=edite_case_folder&newcasefolderid=${queryValue.newcasefolderid}`
    )
  }

  setSelectedCaseFolderId = (newId: React.ReactText) => {
    this.setState({ isCreateMode: false })
    if (newId) this.props.history.push(`/dashboard/c/${newId}`)
  }

  setDeletededCaseFolderId = (newId: string) =>
    this.setState({ selectedCaseFolderId: newId })


    componentDidUpdate(props:any, prevState:IState): void {
      const local_isShowMode = localStorage.getItem(LocalStorageKeys.SHOW_MODE_VIEW) === "true"
      if(prevState.isShowMode !== local_isShowMode)  {
        this.setState({isShowMode: local_isShowMode ? true : false})
        this.props.history.push("/dashboard")
      }
    }

  render() {
    const params: any = this.props.match.params
    // const updateCacheAfterDelete = (cache: DataProxy) => {
    //   const { findCaseFoldersByCurrentCustomer }: any = cache.readQuery({
    //     query: GET_DASHBOARD_CASE_FOLDERS,
    //   })

    //   // tslint:disable-next-line: no-shadowed-variable
    //   const caseFolders: GetCaseFolders_findCaseFoldersByCurrentCustomer_caseFolders[] =
    //     findCaseFoldersByCurrentCustomer.caseFolders
    //   if (this.state.selectedCaseFolderId !== null) {
    //     cache.writeQuery({
    //       query: GET_DASHBOARD_CASE_FOLDERS,
    //       data: {
    //         findCaseFoldersByCurrentCustomer: {
    //           ...findCaseFoldersByCurrentCustomer,
    //           caseFolders: caseFolders.filter(
    //             (caseFolder) =>
    //               caseFolder.id !== this.state.selectedCaseFolderId
    //           ),
    //         },
    //       },
    //     })
    //   }
    // }

    return (
      <CaseFolderProvider caseFoldetId={params.caseFolderId}>
        {({ caseFolders, refetch }) => {
          const selectedCaseFolder = caseFolders.find(
            (x) => x.id === params.caseFolderId
          )!

          return (
            <>
              <DashboardContext.Provider
                value={{
                  caseFolders,
                  selectedCaseFolder,
                  inEditMode: params.inEditMode === "true",
                  enterEditMode: this.enterEditMode,
                  exitEditMode: this.exitEditMode,
                  setSelectedCaseFolderId: this.setSelectedCaseFolderId,
                }}
              >
                <MainLayout showUtilityBar={false}>
                  <BackgroundImage src={background} />
                  <div className={styles.flexContainer}>
                    <Whiteframe
                      className={styles.publishingsContainer}
                      padding={false}
                    >
                      <PublishingsContainer />
                    </Whiteframe>
                    <div className={styles.newsContainer}>
                      <DashboardNews />
                    </div>
                  </div>
                  <Whiteframe className={styles.mainContent} style={this.state.isShowMode ?{flexDirection: "column"} : {}}>
                    <Mutation
                      mutation={DELETE_CASE_FOLDER}
                      refetchQueries={refetch}
                    >
                      {(deleteCaseFolder: any) => (
                        <>
                          {/* <CaseFolderTable
                            caseFolders={caseFolders}
                            deleteCaseFolder={deleteCaseFolder}
                            onRowClick={this.setSelectedCaseFolderId}
                            onDeleteClick={this.setDeletededCaseFolderId}

                            selectedCaseFolderId={params.caseFolderId}
                            afterDeletedCaseFolder={() => {}}
                            openCreateCaseFolderForm={
                              this.enterCreateCaseFolder
                            }
                            isCreateMode={this.state.isCreateMode}
                          /> */}
                          {/* <SelectedCaseFolderView
                            deleteCaseFolder={deleteCaseFolder}
                            selectedCaseFolder={selectedCaseFolder}
                            inEditMode={params.inEditMode === "true"}
                            onExitEditMode={this.exitEditMode}
                            isCreateMode={this.state.isCreateMode}
                            exitCreateCaseFolder={this.exitCreateCaseFolder}
                            onBackFromPackages={this.onBackFromPackages}
                          /> */}

                          <TablesContainer
                            caseFolders={caseFolders}
                            deleteCaseFolder={deleteCaseFolder}
                            setSelectedCaseFolderId={this.setSelectedCaseFolderId}
                            setDeletededCaseFolderId={this.setDeletededCaseFolderId}
                            caseFolderId={params.caseFolderId}
                            enterCreateCaseFolder={this.enterCreateCaseFolder}
                            isCreateMode={this.state.isCreateMode}
                            selectedCaseFolder={selectedCaseFolder}
                            inEditMode={params.inEditMode === "true"}
                            onExitEditMode={this.exitEditMode}
                            exitCreateCaseFolder={this.exitCreateCaseFolder}
                            onBackFromPackages={this.onBackFromPackages}

                          />
                        </>
                      )}
                    </Mutation>
                  </Whiteframe>
                </MainLayout>
              </DashboardContext.Provider>

              <NewsProvider
                render={(newsItems) => {
                  return (
                    <PopupNews
                      newsItems={newsItems.length > 0 ? newsItems : undefined}
                    />
                  )
                }}
              />
            </>
          )
        }}
      </CaseFolderProvider>
    )
  }
}

interface DashboardContextValue {
  caseFolders: GetCaseFolders_findCaseFoldersByCurrentCustomer_caseFolders[]
  enterEditMode: () => void
  exitEditMode: () => void
  selectedCaseFolder: GetCaseFolders_findCaseFoldersByCurrentCustomer_caseFolders
  setSelectedCaseFolderId: (newId: string) => void
  inEditMode: boolean
}

export default withRouter(DashboardPage)
