import * as React from "react"
import * as styles from "./styles.scss"
import Button from "components/Button"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import cx from "classnames"
import { IconProp } from "@fortawesome/fontawesome-svg-core"

function NavigationButton(props: NavigationButtonProps) {
  const { variant, icon, className, ...rest } = props

  return (
    <Button
      color="gray"
      className={cx(styles.navButton, className, styles[`variant-${variant}`], {
        [styles.disabled]: props.disabled,
      })}
      {...rest}
    >
      <FontAwesomeIcon icon={icon} />
    </Button>
  )
}

interface NavigationButtonProps {
  onClick: () => void
  disabled: boolean
  variant: string
  icon: IconProp
  className?: string
}

export default NavigationButton
