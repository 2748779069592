import * as React from "react"
import * as styles from "./styles.scss"
import Slide from "./Slide"

class Slides extends React.PureComponent<SlidesProps> {
  render() {
    const { currentSlideIndex, slides } = this.props

    return (
      <div className={styles.slides}>
        {React.Children.map(slides, (slide, index) => (
          <Slide
            slide={slide}
            isActive={currentSlideIndex === index}
            isNext={index > currentSlideIndex}
            isPrev={index < currentSlideIndex}
          />
        ))}
      </div>
    )
  }
}

interface SlidesProps {
  currentSlideIndex: number
  slides: React.ReactNode
}

export default Slides
