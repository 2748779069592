import * as React from "react"
import { Switch, Redirect, Route } from "react-router-dom"
import CaseFolderArchivePage from "pages/CaseFolderArchivePage"

const ArchiveRoutes:React.FC = () => {
    return (
      <Switch>
        <Route
          component={CaseFolderArchivePage}
          path="/archive/c/:caseFolderId"
        />
        <Route component={CaseFolderArchivePage} path="/archive" exact={true} />
        <Redirect to="/archive" />
      </Switch>
    )
}

export default ArchiveRoutes
