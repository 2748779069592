import * as React from "react"
import { WithTranslation, withTranslation, useTranslation } from "react-i18next"
import ReactTable from "react-table-6"
import * as styles from "./styles.scss"
import Status from "components/Status"
import {
  GetCaseFolders_findCaseFoldersByCurrentCustomer_caseFolders,
  GetCaseFolders_findCaseFoldersByCurrentCustomer_caseFolders_productPackages,
  DeleteOrder,
  DeleteOrderVariables,
} from "schema"
import cx from "classnames"
import { withRouter, RouteComponentProps, useHistory } from "react-router-dom"
import Warning from "components/Warning"
import { DateTime } from "luxon"
import { notEmpty } from "utils"
import Button from "components/Button"
import ReactTooltip from "components/Tooltip"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useMutation, useApolloClient } from "react-apollo-hooks"
import DELETE_ORDER from "graphql/mutations/deleteOrder"
import { AddProductGroupOrderModal } from "components/AddProductGroupOrderModal/AddProductGroupOrderModal"

interface ArchiveExpandedTableDataItem {
  id: string
  internalId?: string
  type: "package" | "document" | "order" | "package_summary"
  name: string
  documentFormat?: string
  publishTime?: string
  status?: any
  isTimeBookingWarning?: any
}

const initializeTableData = (
  productPackages: GetCaseFolders_findCaseFoldersByCurrentCustomer_caseFolders_productPackages[]
) =>
  productPackages.filter(notEmpty).reduce((acc, pack) => {
    const packageContent: ArchiveExpandedTableDataItem[] = pack.documents
      .filter(notEmpty)
      .reduce(
        (content, doc) =>
          content
            .concat([
              {
                type: "document",
                name: doc.documentFormat.documentType.friendlyName,
                internalId: doc.internalId.toString(),
                id: doc.id!,
              } as ArchiveExpandedTableDataItem,
            ])
            .concat(
              doc.orders.filter(notEmpty).map((order) => {
                const publishTime = order.mediaOrder!.publishTimes[0]
                return {
                  id: order.id,
                  internalId: order.customerFriendlyId,
                  type: "order",
                  name: order.mediaOrder!.media.friendlyName,
                  documentFormat: order.mediaOrder!.documentFormat
                    .mediaAdaptedName,
                  publishTime: publishTime ? publishTime.time : undefined,
                  // isTimeBookingWarning: order.mediaOrder!
                  //   .isCriticalTimeBookingWarning,
                  isLocked: order.mediaOrder?.isLocked,
                  isTimeBookingWarning: order.alerting,
                  status: order.mediaOrder!.status,
                } as ArchiveExpandedTableDataItem
              })
            ),
        [] as ArchiveExpandedTableDataItem[]
      )
    return (
      acc
        // .concat([
        //   {
        //     id: pack.productPackage.id!,
        //     type: "package",
        //     name: pack.productPackage.name,
        //     internalId: "ID",
        //     documentFormat: "Format",
        //     publishTime: "Införande",
        //     status: "Status",
        //   },
        // ])

        .concat(packageContent)
      // .concat([
      //   {
      //     id: pack.productPackage.id!,
      //     type: "package_summary",
      //     name: pack.productPackage.name,
      //     internalId: "ID",
      //     documentFormat: "Format",
      //     publishTime: "Införande",
      //     status: "Status",
      //   },
      // ])
    )
  }, [] as ArchiveExpandedTableDataItem[])

const PackagesList = ({
  selectedPackages,
  selectedCaseFolderId,
}: {
  selectedPackages: GetCaseFolders_findCaseFoldersByCurrentCustomer_caseFolders_productPackages[]
  selectedCaseFolderId: string | null
}) => {
  const packages = initializeTableData(selectedPackages)
  const history = useHistory()
  const { t } = useTranslation(["dashboard", "statuses", "common"])
  const columns = [
    {
      Header: t("Produkt/Tjänst"),
      accessor: "name",
    },
    {
      Header: t("ID"),
      accessor: "internalId",
    },
    {
      Header: t("format"),
      accessor: "documentFormat",
    },
    {
      Header: t("common:publishDate"),
      accessor: "publishTime",
    },

    {
      Header: t("status"),
      accessor: "status",
    },
    {
      Header: t(""),
      accessor: "",
    },
  ]

  const openModal = () => {
    const { search } = window.location
    const urlParams = new URLSearchParams(search)
    urlParams.set("action", "add_product_casefolder")
    const newSearch = urlParams.toString()
    history.push({
      search: newSearch,
    })
  }

  return (
    <div className={styles.container}>
      <div className={styles.topHeaderLabels}>
        {columns.map((column) => {
          return (
            <div key={column.accessor} className={styles.topHeaderLabel}>
              {column.Header}
            </div>
          )
        })}
      </div>

      <div>
        {packages.map((item, index) => {
          return (
            <div key={`${index}__${item.id}`} className={styles.packagesRows}>
              {/* {item.type === "package" ? (
                <div className={styles.rowPackage}>
                  <div className={styles.rowPackageName} data-value={item.name}>
                    {item.name}
                  </div>
                </div>
              ) : null} */}

              {item.type === "document" ? (
                <div className={styles.rowDocument}>
                  <div
                    className={styles.rowDocumentName}
                    data-value={item.name}
                  >
                    {item.name}
                  </div>
                  {/* <div className={styles.rowDocumentId}>{item.internalId}</div> */}
                </div>
              ) : null}

              {item.type === "order" ? (
                <div className={styles.rowOrder}>
                  <div className={styles.rowOrder}>
                    <OrderRow
                      item={item}
                      selectedCaseFolderId={selectedCaseFolderId}
                    />
                  </div>
                </div>
              ) : null}

              {/* {item.type === "package_summary" ? (
                <div
                className={styles.addProductBtnContainer}
                >
                  <Button icon={"plus"}  onClick={openModal}>{" Lägg till Produkt"}</Button>
                  <AddProductGroupOrderModal
                    caseFolderId={selectedCaseFolderId!}
                  />
                </div>
              ) : null} */}
            </div>
          )
        })}
        <div className={styles.addProductBtnContainer}>
          <Button icon={"plus"} onClick={openModal}>
            {t("common:addProduct")}
          </Button>
          <AddProductGroupOrderModal caseFolderId={selectedCaseFolderId!} />
        </div>
      </div>
    </div>
  )
}

// const OrderRowAddProduct = withRouter(
//   ({ history, item, selectedCaseFolderId }: any) => {
//     const onAddProduct = () => {
//       history.push(`/editOrder/c/${selectedCaseFolderId}/a/add_product`)
//     }
//     return (
//       <Button onClick={onAddProduct} icon="plus-circle" variant={"tertiary"}>
//         Lägg till produkt
//       </Button>
//     )
//   }
// )

const OrderRow = withRouter(({ history, item, selectedCaseFolderId }: any) => {
  let [isRemoveModalVisible, setIsRemoveModalVisible] = React.useState<boolean>(
    false
  )
  const client = useApolloClient()
  const mutationDeleteProduct = useMutation<DeleteOrder, DeleteOrderVariables>(
    DELETE_ORDER,
    { client }
  )
  const { t } = useTranslation(["dashboard", "statuses", "common"])
  const format = t("dateFormats:dateFullWithWeekNames")
  const onOpen = () => {
    history.push(`/editOrder/c/${selectedCaseFolderId}/o/${item.id}`)
  }
  const dateText = DateTime.fromJSDate(new Date(item.publishTime))
    .toFormat(format)
    .toString()

  const openModal = () => setIsRemoveModalVisible(true)

  const onAbort = () => setIsRemoveModalVisible(false)

  const onSuccess = () => {
    mutationDeleteProduct({
      variables: {
        input: {
          orderIds: [item.id],
        },
      },
    })
  }

  return (
    <div className={styles.rowOrderWrapper}>
      <div
        className={styles.rowOrderName}
        onClick={onOpen}
        data-for={item.name && item.name.length >= 31 ? "orderRow" : undefined}
        data-tip={item.name && item.name.length >= 31 ? item.name : undefined}
      >
        {item.name}
      </div>

      <div className={styles.rowOrderId} onClick={onOpen}>
        {item.internalId}
      </div>

      <div
        className={styles.rowOrderFormat}
        onClick={onOpen}
        data-for={
          item.documentFormat && item.documentFormat.length >= 10
            ? "orderRow"
            : undefined
        }
        data-tip={
          item.documentFormat && item.documentFormat.length >= 10
            ? item.documentFormat
            : undefined
        }
      >
        {item.documentFormat}
      </div>

      {/* <div
        className={styles.rowOrderDate}
        onClick={onOpen}
        data-for={
          item.publishTime !== undefined &&
          item.isTimeBookingWarning === true &&
          dateText.length >= 10
            ? "orderRow"
            : undefined
        }
        data-tip={
          item.publishTime !== undefined &&
          item.isTimeBookingWarning === true &&
          dateText.length >= 10
            ? dateText
            : undefined
        }
      >
        {item.publishTime !== undefined &&
        item.isTimeBookingWarning === true ? (
          <Warning>{dateText}</Warning>
        ) : null}
      </div> */}

      <div className={styles.rowOrderDate}>
        {item.publishTime != undefined && (
          <div className={styles.alerting}>
            {item.isTimeBookingWarning.text.length > 0 ? (
              <ReactTooltip
                id={"row"}
                text={
                  item.isTimeBookingWarning.text.length > 0
                    ? item.isTimeBookingWarning.text
                    : ""
                }
              >
                <div className={styles.alertMsg}>
                  <FontAwesomeIcon icon="exclamation-triangle" />
                  <span>{dateText}</span>
                </div>
              </ReactTooltip>
            ) : (
              dateText
            )}
          </div>
        )}
      </div>

      <div
        className={styles.rowOrderStatus}
        onClick={onOpen}
        data-value={item.status.status}
      >
        <Status variant={item.status.color}>
          {t(`statuses:${item.status.status}`)}
        </Status>
      </div>

      <div className={styles.rowOrderAction}>
        {isRemoveModalVisible ? (
          <div className={styles.removeItemWrapper}>
            <RemoveItemPopup
              text={""}
              onSuccess={onSuccess}
              onAbort={onAbort}
              isLocked={item.isLocked}
            />
          </div>
        ) : (
          <div className={styles.trashIconWrapper} onClick={openModal}>
            <FontAwesomeIcon icon="trash" />
          </div>
        )}
      </div>
    </div>
  )
})

const RemoveItemPopup: React.FC<{
  text: string
  onAbort: () => void
  onSuccess: () => void
  isLocked: boolean
}> = (props) => {
  const { t } = useTranslation(["common"])
  return (
    <div className={styles.removeItemPopupWrapper}>
      {!props.isLocked ? (
        <>
          <div className={styles.removeItemPopupLabel}>
            {props.text && props.text.length > 0 ? props.text : t("areYouSure") }
          </div>

          <div className={styles.removeItemPopupIconWrapper}>
            <div
              className={styles.removeItemPopupLeftIcon}
              onClick={props.onSuccess}
            >
              <FontAwesomeIcon
                icon={"check"}
                className={styles.removeItemPopupLeftIconOk}
              />
            </div>
          </div>

          <div className={styles.removeItemPopupIconWrapper}>
            <div
              className={styles.removeItemPopupRightIcon}
              onClick={props.onAbort}
            >
              <FontAwesomeIcon
                icon={"times"}
                size={"lg"}
                className={styles.removeItemPopupLeftIconRemove}
              />
            </div>
          </div>
        </>
      ) : (
        <div className={styles.productlock}>{t("productLock")}</div>
      )}
    </div>
  )
}

export default PackagesList
