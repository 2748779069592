import * as React from "react"
import * as styles from "./styles.scss"
import Modal from "components/Modal"
import Header from "components/Header"
import { FieldWrapperFormik } from "components/FieldWrapper"
import TextInput from "components/TextInput"
import cx from "classnames"
import SubmitButton from "../../SubmitButton"
import { useTranslation } from "react-i18next"
import { DropdownInputFormik } from "components/DropdownInput"
import { useMutation } from "react-apollo-hooks"
import CREATE_CUSTOMER_USER from "graphql/mutations/createCustomerUser"
import UPDATE_CUSTOMER_USER from "graphql/mutations/updateCustomerUser"
import GET_CURRENT_CUSTOMER from "graphql/queries/getCurrentCustomer"
import {
  CreateCustomerUser,
  CreateCustomerUserVariables,
  GetCurrentCustomer_currentCustomer_office_company_offices,
  UpdateCustomerUser,
  UpdateCustomerUserVariables,
} from "schema"
import { useFormik } from "formik"
import { boolean, object, string } from "yup"
import { ErrorText } from "components/ErrorMessage"

export interface UserFormData {
  id?: string
  primaryUserId?: string
  firstName: string
  lastName: string
  officeLabel: string
  office: string
  phone: string
  password: string
  username: string
  active: boolean
}

const UserModal: React.FC<UserModalProps> = (props) => {
  const [t] = useTranslation("agencySettings")
  const [load, setLoad] = React.useState<"loading" | "init">("init")
  const createUser = useMutation<
    CreateCustomerUser,
    CreateCustomerUserVariables
  >(CREATE_CUSTOMER_USER, {
    refetchQueries: [{ query: GET_CURRENT_CUSTOMER }],
  })
  const updateUser = useMutation<
    UpdateCustomerUser,
    UpdateCustomerUserVariables
  >(UPDATE_CUSTOMER_USER, {
    refetchQueries: [{ query: GET_CURRENT_CUSTOMER }],
  })
  const { initialValues, availableOffices, ...rest } = props

  // update: updateCache

  // function updateCache(cache: DataProxy, { data }: any) {
  //   const { currentCustomer }: any = cache.readQuery({
  //     query: GET_CURRENT_CUSTOMER,
  //   })

  //   cache.writeQuery({
  //     query: GET_CURRENT_CUSTOMER,
  //     data: {
  //       currentCustomer: {
  //         ...currentCustomer,
  //         colleagues: currentCustomer.colleagues.concat([
  //           data.createCustomerUser.customer,
  //         ]),
  //       },
  //     },
  //   })
  // }

  const offices = availableOffices.map((office) => ({
    value: office.id,
    label: office.name,
  }))

  const isEditUser =
    initialValues !== undefined && initialValues !== null ? true : false

  const defaultValues: UserFormData = {
    id: undefined,
    primaryUserId: undefined,
    officeLabel: offices[0].label,
    office: offices[0].value,
    firstName: "",
    lastName: "",
    password: "",
    phone: "",
    username: "",
    active: true,
  }

  const initValues: UserFormData = isEditUser ? initialValues! : defaultValues

  const handleCreateUser = (values: UserFormData) => {
    return createUser({
      variables: {
        input: {
          customer: {
            firstName: values.firstName,
            lastName: values.lastName,
            officeId: values.office,
            primaryUser: {
              username: values.username,
              password: values.password,
              isEnabled: true,
            },
          },
        },
      },
    })
  }

  const handleUpdateUser = (values: UserFormData) => {
    return updateUser({
      variables: {
        input: {
          customer: {
            id: values.id,
            firstName: values.firstName,
            lastName: values.lastName,
            primaryUser: {
              id: values.primaryUserId,
              username: values.username,
              password: values.password,
              isEnabled: values.active,
            },
            officeId: values.office,
            email: values.username
          },
        },
      },
    })
  }

  const validationSchema = object().shape<UserFormData>({
    id: string(),
    primaryUserId: string(),
    firstName: string().required().min(2).max(300),
    lastName: string().required().min(2).max(300),
    password: string().min(2).max(50) as any,
    phone: string().min(3).max(50) as any,
    username: string().required().min(2).max(100),
    officeLabel: string(),
    office: string(),
    active: boolean(),
  })

  const formik = useFormik<UserFormData>({
    initialValues: initValues,
    enableReinitialize: true,
    validationSchema,
    validateOnMount: false,
    validateOnBlur: true,
    validateOnChange: true,
    onSubmit: (values) => {
      setLoad("loading")
      if (isEditUser) {
        handleUpdateUser(values)
          .then((result) => {
            setLoad("init")
            if (result.data?.updateCustomerUser.isError === true) {
              formik.setStatus(result.data?.updateCustomerUser.errorReason)
            } else {
              if (props.onRequestClose) {
                props.onRequestClose()
              }
            }
          })
          .catch((err) => {
            setLoad("init")
            formik.setStatus(err.toString())
          })
      } else {
        handleCreateUser(values)
          .then((result) => {
            setLoad("init")
            if (result.data?.createCustomerUser.isError === true) {
              formik.setStatus(result.data?.createCustomerUser.errorReason)
            } else {
              if (props.onRequestClose) {
                props.onRequestClose()
              }
            }
          })
          .catch((err) => {
            setLoad("init")
            formik.setStatus(err.toString())
          })
      }
    },
  })

  return (
    <Modal {...rest} className={styles.userModal}>
      <Header title={isEditUser ? t("editUser") : t("addUser")} />

      <form onSubmit={formik.handleSubmit}>
        <div className={styles.row}>
          <FieldWrapperFormik
            value={formik.values.firstName}
            onChange={(e: any) => {
              formik.setFieldValue("firstName", e.target.value)
            }}
            errors={formik.touched.firstName && formik.errors.firstName}
            label={t("firstName")}
            name="firstName"
            className={cx(styles.fieldWrapper, styles.marginRight)}
            component={TextInput}
          />
          <FieldWrapperFormik
            value={formik.values.lastName}
            onChange={(e: any) => {
              formik.setFieldValue("lastName", e.target.value)
            }}
            errors={formik.touched.lastName && formik.errors.lastName}
            label={t("lastName")}
            name="lastName"
            className={styles.fieldWrapper}
            component={TextInput}
          />
        </div>

        <div className={styles.row}>
          <FieldWrapperFormik
            value={formik.values.username}
            onChange={(e: any) => {
              formik.setFieldValue("username", e.target.value)
            }}
            errors={formik.touched.username && formik.errors.username}
            label={t("username")}
            name="username"
            className={cx(styles.fieldWrapper, styles.marginRight)}
            component={TextInput}
          />
          <FieldWrapperFormik
            value={formik.values.password}
            onChange={(e: any) => {
              formik.setFieldValue("password", e.target.value)
            }}
            errors={formik.touched.password && formik.errors.password}
            label={t("password")}
            name="password"
            className={styles.fieldWrapper}
            component={TextInput}
          />
        </div>

        <div className={styles.row}>
          <FieldWrapperFormik
            value={formik.values.phone}
            onChange={(e: any) => {
              formik.setFieldValue("phone", e.target.value)
            }}
            errors={formik.touched.phone && formik.errors.phone}
            label={t("phone")}
            name="phone"
            className={cx(styles.fieldWrapper, styles.marginRight)}
            component={TextInput}
          />

          {isEditUser ? (
            <FieldWrapperFormik
              value={formik.values.officeLabel}
              onChange={(selected: any) => {
                formik.setFieldValue("office", selected.value)
                formik.setFieldValue("officeLabel", selected.label)
              }}
              errors={formik.touched.office && formik.errors.office}
              label={t("office")}
              name="office"
              className={styles.fieldWrapper}
              component={DropdownInputFormik}
              dropdownOptions={offices}
            />
          ) : (
            <div className={styles.officeFiller} />
          )}
        </div>

        {formik.status ? <ErrorText>{formik.status}</ErrorText> : null}

        {isEditUser ? (
          <SubmitButton isWorking={load === "loading"} />
        ) : (
          <SubmitButton isWorking={load === "loading"} text={t("add")} />
        )}
      </form>
    </Modal>
  )

  // return (
  //   <Modal {...rest} className={styles.userModal}>
  //     <Header title={initialValues ? t("editUser") : t("addUser")} />
  //     <Form
  //       onSubmit={onSubmit}
  //       initialValues={initialValues ? initialValues : { office: offices[0] }}
  //       render={({ handleSubmit }) => (
  //         <form onSubmit={handleSubmit}>
  //           <div className={styles.row}>
  //             <FieldWrapper
  //               label={t("firstName")}
  //               name="firstName"
  //               className={cx(styles.fieldWrapper, styles.marginRight)}
  //               component={TextInput}
  //             />
  //             <FieldWrapper
  //               label={t("lastName")}
  //               name="lastName"
  //               className={styles.fieldWrapper}
  //               component={TextInput}
  //             />
  //           </div>
  //           <div className={styles.row}>
  //             <FieldWrapper
  //               label={t("username")}
  //               name="username"
  //               className={cx(styles.fieldWrapper, styles.marginRight)}
  //               component={TextInput}
  //             />
  //             <FieldWrapper
  //               label={t("password")}
  //               name="password"
  //               className={styles.fieldWrapper}
  //               component={TextInput}
  //             />
  //           </div>
  //           <div className={styles.row}>
  //             <FieldWrapper
  //               label={t("phone")}
  //               name="phone"
  //               className={cx(styles.fieldWrapper, styles.marginRight)}
  //               component={TextInput}
  //             />
  //             {!initialValues ? (
  //               <FieldWrapper
  //                 label={t("office")}
  //                 name="office"
  //                 className={styles.fieldWrapper}
  //                 component={DropdownInput}
  //                 dropdownOptions={offices}
  //               />
  //             ) : (
  //               <div className={styles.officeFiller} />
  //             )}
  //           </div>
  //           {initialValues ? <SubmitButton /> : <AddUserButton />}
  //         </form>
  //       )}
  //     />
  //   </Modal>
  // )
}

interface UserModalProps {
  isOpen: boolean
  initialValues?: UserFormData
  availableOffices: GetCurrentCustomer_currentCustomer_office_company_offices[]
  customerId: string
  onRequestClose: () => void
}

export default UserModal
