import * as React from "react"
import * as styles from "./styles.scss"
import SelectedSymbol from "./SelectedSymbol"
import { WithTranslation, withTranslation } from "react-i18next"
import Button from "components/Button"
import { GetGalleryImageInformation_galleryImages } from "schema"

class SelectedSymbolColumn extends React.PureComponent<
  SelectedSymbolColumnProps & WithTranslation
> {
  render() {
    const { selectedSymbol, onSave,isAdstateEnalbe, t } = this.props

    return (
      <div className={styles.selectedSymbolColumn}>
        <div className={styles.container}>
          <b className={styles.selectedSymbolTitle}>{t("selectedSymbol")}</b>
          <SelectedSymbol
            symbolUrl={selectedSymbol && selectedSymbol.thumbnail100x80Url}
          />
          {selectedSymbol?.id &&
          <Button variant={isAdstateEnalbe ?"feView" : "secondary"} onClick={onSave} id="symbol-select-button">
            {t("useSymbol")}
          </Button>
          }
        </div>
        {/* <SymbolInfo selectedSymbol={selectedSymbol} /> */}
      </div>
    )
  }
}

interface SelectedSymbolColumnProps {
  selectedSymbol?: GetGalleryImageInformation_galleryImages
  onSave: () => void
  isAdstateEnalbe?: boolean
}

export default withTranslation("editOrder")(SelectedSymbolColumn)
