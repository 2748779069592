import * as React from "react"
import * as styles from "./styles.scss"
import Loading from "./Loading"
import * as cx from "classnames"
import Rectangles from "./Rectangles"
import { GetOrderById_orders_mediaOrder_publishTimes } from "schema"

const MIN_IMAGE_HEIGHT = 215

class ImagePreview extends React.PureComponent<
  ImagePreviewProps,
  ImagePreviewState
> {
  state = { imageScale: undefined, containerHeight: undefined }

  imageContainer: React.RefObject<HTMLDivElement> = React.createRef()
  // observer: ResizeObserver = new ResizeObserver((entries) =>
  //   this.handleResize()
  // )

  handleResize = () => {
    const { zoom } = this.props
    const image = this.imageContainer.current!
    if (!image) return

    const minImageScale = MIN_IMAGE_HEIGHT / image.clientHeight
    let imageScale =
      (window.innerHeight - image.offsetTop - 140) / image.clientHeight

    imageScale = imageScale > minImageScale ? imageScale : minImageScale

    if (imageScale > 1) imageScale = 1

    const containerHeight =
      zoom === "auto"
        ? image.clientHeight * imageScale + 70
        : image.clientHeight * zoom + 70

    this.setState({
      imageScale,
      containerHeight:
        containerHeight < MIN_IMAGE_HEIGHT ? MIN_IMAGE_HEIGHT : containerHeight,
    })
  }

  componentDidMount = () => {
    // this.observer.observe(this.imageContainer.current!)
    window.addEventListener("resize", this.handleResize)
    const img = new Image()
    img.onload = () => {
      this.handleResize()
    }
    img.src = this.props.previewImageUrl
  }

  componentDidUpdate = (prevProps: ImagePreviewProps) => {
    // if (prevProps.zoom !== this.props.zoom) this.handleResize()
    const img = new Image()
    img.onload = () => {
      this.handleResize()
    }
    img.src = this.props.previewImageUrl
  }

  componentWillUnmount = () => {
    // this.observer.unobserve(this.imageContainer.current!)
    window.removeEventListener("resize", this.handleResize)
  }

  render() {
    const { zoom, toolbarIsVisible } = this.props
    const { imageScale, containerHeight } = this.state

    return (
      <div
        className={cx(styles.imagePreviewContainer, {
          // [styles.hidden]: !toolbarIsVisible,
        })}
        style={{
          height: containerHeight,
          minHeight:
            this.imageContainer.current && zoom !== "auto"
              ? this.imageContainer.current.clientHeight * zoom
              : undefined,
        }}
      >
        <Loading className={cx(styles.loading, {
          [styles.FEView__loading]: this.props.isFEUser
        })} />

          <div
            ref={this.imageContainer}
            className={styles.imageWrapper}
            style={{
              transform: `scale(${zoom === "auto" ? imageScale : zoom})`,
            }}
          >
            <div className={styles.innerImageWrapper}>
              <div className={styles.imageContainer}>
                <img
                  className={cx(styles.image, {
                    [styles.isLocked]: this.props.isLocked,
                  })}
                  src={this.props.previewImageUrl}
                />
              </div>
              {/* {!this.props.isLocked && <Rectangles />} */}

              <Rectangles isFEUser={this.props.isFEUser} />
            </div>
          </div>

      </div>
    )
  }
}

interface ImagePreviewState {
  imageScale?: number
  containerHeight?: number
}

interface ImagePreviewProps {
  zoom: any
  toolbarIsVisible: boolean
  isLocked: boolean
  previewImageUrl: string
  orderId: string
  publishTime: GetOrderById_orders_mediaOrder_publishTimes | null
  isFEUser: boolean
}

export default ImagePreview
