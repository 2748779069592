import React from "react"
import Calendar from "components/Calendar"

const DatePickerInput: React.FC<{
    publishTime?: any
    disabled?: boolean
    className?: string
    name?: string
    value: Date | null
    handleDateInputChange: (name: string, date: Date) => void
    handleAreaOverFlowChange:(arg: boolean) => void
    isFEUser?: boolean
  }> = (props) => {
    const [isCalenderOpen, setCalenderOpen] = React.useState<boolean>()
  
    const handleDateSelect = (date: Date) => {
      props.handleDateInputChange(props.name!, date)
      setCalenderOpen(false)
      props.handleAreaOverFlowChange(true)
    }

    // const handleIsCalenderDropdown = () => {
    //   setCalenderOpen(!isCalenderOpen)
    //   props.handleAreaOverFlowChange(false)
    // }

    const handleInputFocus = () => {
      setCalenderOpen(true)
      props.handleAreaOverFlowChange(false)
    }


    const handleInputBlur = () => {
      setCalenderOpen(false)
      props.handleAreaOverFlowChange(true)
    }
    return (
      <Calendar
        onChange={handleDateSelect}
        selectedDate={props.value}
        className={props.className}
        name={props.name}
        // onInputClick={handleIsCalenderDropdown}
        onBlur={handleInputBlur}
        onFocus={handleInputFocus}
        open={isCalenderOpen}
        disabled={props.disabled}
        isFEUser={props.isFEUser}
      />
    )
  }

  export default DatePickerInput