import * as React from "react"
import * as styles from "./styles.scss"
import Document from "./Document"
import { GetAdminCaseFolders_findCaseFoldersByConditions_caseFolders } from "schema"
import { notEmpty } from "utils"

function CaseFolderContainer(props: CaseFolderContainerProps) {
  const { caseFolder } = props

  return (
    <div className={styles.caseFolderContainer}>
      <div className={styles.labelRow}>
        <b>{caseFolder.name}</b>
        <span className={styles.office}>{caseFolder.customer.office.name}</span>
      </div>
      {caseFolder.documents
        .filter(notEmpty)
        .filter(doc => doc.orders.length)
        .map(document => (
          <Document
            document={document}
            key={document!.idString!}
            caseFolder={caseFolder}
          />
        ))}
    </div>
  )
}

interface CaseFolderContainerProps {
  caseFolder: GetAdminCaseFolders_findCaseFoldersByConditions_caseFolders
}

export default React.memo(CaseFolderContainer)
