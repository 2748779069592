import Modal from "components/Modal"
import { NewsItem } from "components/NewsPreview/NewsProvider"
import produce from "immer"
import React from "react"
import styled from "styled-components"

let __ISMOUNT: boolean = true

const PopupNews: React.FC<{
  newsItems: NewsItem[] | undefined
}> = (props) => {
  const [state, setState] = React.useState<IState>({
    isModalOpen: true,
  })
  const newsList =
    props.newsItems
      ?.map((n) => ({ ...n, isRead: false }))
      .filter((news) => {
        return news.isPopup === true
      }) ?? []

  const storageData: Array<ILocalStorage> = JSON.parse(
    localStorage.getItem("news")!
  )

  const storeIdsLocaly = (selectedId: number) => {
    const ids: Array<ILocalStorage> = storageData ? [...storageData] : []
    newsList.map(
      (item) =>
        item.id === selectedId &&
        ids.push({ id: item!.id, expired: item.timeTo, isRead: true })
    )
    localStorage.setItem("news", JSON.stringify(ids))
  }

  const unreadNews = storageData
    ? newsList.filter((n) => !storageData.some((s) => s.id === n.id))
    : newsList

  const handleCloseModal = () => {
    setState(
      produce(state, (draft) => {
        draft.isModalOpen = false
      })
    )
    setTimeout(() => {
      storeIdsLocaly(unreadNews[0].id)
    }, 500)
   
  }

  const ModalCustomStyle: ReactModal.Styles | undefined = 
    unreadNews[0]?.isFullContent! 
      ? {
          content: {
            width: "auto",
            padding: 0,
          },
        }
      : undefined

  
  unreadNews.length <= 0 ? (__ISMOUNT = false) : (__ISMOUNT = true)
  if (__ISMOUNT) {
    return (
      <Modal
        isModalInModal={true}
        isOpen={state.isModalOpen}
        onRequestClose={handleCloseModal}
        large={false}
        modalStyles={ModalCustomStyle}
        isPopUp={unreadNews[0]?.isFullContent ? true : false}
      >
        {/* <span style={{ fontSize: "16px", fontWeight: 600, color: "red" }}>
          Viktig!
        </span> */}
        <PopupContainer>
          <div className={"newsContainer"}>
            {!unreadNews[0]?.isFullContent! && (
               <h4>{unreadNews[0].title}</h4>
            )}
            <p
              dangerouslySetInnerHTML={{
                __html: unreadNews[0].text
                  ? unreadNews[0].text
                  : unreadNews[0].summary,
              }}
            ></p>
            {/* <span>{moment(item.date)}</span> */}
          </div>
        </PopupContainer>
      </Modal>
    )
  }
  return <></>
}

interface ILocalStorage {
  id: number
  expired: Date
  isRead: boolean
}

interface IState {
  isModalOpen: boolean
}

const PopupContainer = styled.div`
  padding-top: 0;
  .newsContainer {
    border-bottom: 1px solid #eee;
    p {
      margin: 0;
    }
  }
`

export default PopupNews

// {unreadNews!.map((item: any) => {
//   return (
//     <div key={item.id} className={"newsContainer"}>
//       <h4>{item.title}</h4>
//       <p dangerouslySetInnerHTML={{__html: item.text ? item.text : item.summary}}></p>
//       {/* <span>{moment(item.date)}</span> */}
//     </div>
//   )
// })}
