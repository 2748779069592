import * as React from "react"
import ConversationButton from "../ConversationButton"
import ConversationInput from "../ConversationInputs"
import { useTranslation } from "react-i18next"
import styled from "styled-components"

function SubscriberNumberButton(props: SubscriberNumberButtonProps) {
  const [value, setValue] = React.useState("")
  const [isOpen, setIsOpen] = React.useState(false)
  const [t] = useTranslation("review")

  const toggleOpen = () => setIsOpen(!isOpen)

  return (
    <SubscribtionBtn>
      {isOpen ? (
         <ConversationInput
         placeholder={t(props.isEdit ? "editSubscriberNumber" : "addSubscriberNumber")}
         value={value}
         setValue={setValue}
         name={"mediaCustomerSubriberNumber"}
         toggleOpen={toggleOpen}
       />
      ) : value.length > 0 ? (
        <ConversationButton
          icon="edit"
          label={value}
          onClick={toggleOpen}
        />
      ) : (
        <ConversationButton
          icon="plus"
          label={t(
            props.isEdit ? "editSubscriberNumber" : "addSubscriberNumberShort"
          )}
          onClick={toggleOpen}
        />
      )}
    </SubscribtionBtn>
  )
}

interface SubscriberNumberButtonProps {
  isEdit: boolean
}

const SubscribtionBtn = styled.div`
  .input {
    width: 130px;
  }
`

export default SubscriberNumberButton

