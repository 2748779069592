import React from "react"
import * as styles from "./styles.scss"
import { useTranslation } from "react-i18next"
import cx from "classnames"

const LanguageItem:React.FC<{
    isHover: boolean
}> = (props) => {
  const { t, i18n } = useTranslation("common")
  
 const handleLanguageChange = (lang: string) => {
    i18n.changeLanguage(lang)
 }  

  return (
    <div
      className={cx(styles.languageItemContainer, {
        [styles.ItemContainerHovered]: props.isHover,
      })}
    >
      <div className={styles.itemTitle}>
        <span>{t("language")}</span>
      </div>

      <div className={styles.dropdownList}>
        <ul>
          <li onClick={() => handleLanguageChange("sv")}>Svenska</li>
          <li onClick={() => handleLanguageChange("nb")} >Norska</li>
          <li onClick={() => handleLanguageChange("en")}>Engelska</li>
        </ul>
      </div>
    </div>
  )
}

export default LanguageItem
