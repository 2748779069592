import gql from "graphql-tag"

const GET_ADMIN_CASE_FOLDERS = gql`
  query GetAdminCaseFolders(
    $timeFrom: Date
    $limit: Int
    $condition: SearchOrderConditionInput!
  ) {
    findCaseFoldersByConditions(
      timeFrom: $timeFrom
      condition: $condition
      limit: $limit
    ) {
      caseFolders {
        idString: id
        name
        documents {
          idString: id
          documentFormat {
            mediaAdaptedName : friendlyName(isShownWithMedia:true) 
          }
          orders {
            customerFriendlyId
            idString: id
            mediaOrder {
              media {
                id
                friendlyName
              }
              status {
                color
                status
              }
            }
          }
        }
        customer {
          idString: id
          tempSessionToken
          office {
            name
          }
        }
      }
    }
  }
`

export default GET_ADMIN_CASE_FOLDERS
