import React from "react"
import * as styles from "./styles.scss"
import Modal from "components/Modal"
import { useApolloClient, useMutation, useQuery } from "react-apollo-hooks"
import {
  GetSuggestionDocumentFormats,
  GetSuggestionDocumentFormatsVariables,
  UpdateDocumentFormat,
  UpdateDocumentFormatVariables,
} from "schema"
import Get_Suggest_Document_Formats from "graphql/queries/GetSuggestDocumentFormats"
import i18next from "i18next"
import Carousel from "nuka-carousel"
import ErrorMessage from "components/ErrorMessage"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faArrowCircleLeft,
  faArrowCircleRight,
  faTimes,
} from "@fortawesome/free-solid-svg-icons"
import UPDATE_DOCUMENT_FORMAT from "graphql/mutations/updateDocumentFormat"
import ExpanderText from "./ExpanderText"
import cx from "classnames"

const AdvertSuggestionModal: React.FC<{
  isOpen: boolean
  onClose: () => void
  orderId: string
  t: i18next.TFunction
}> = (props) => {
  const client = useApolloClient()
  const SLIDER_COUNT_PER_VIEW = 3
  const [isMutateLoading, setMutateLoading] = React.useState<boolean>(false)

  const { data, loading, error } = useQuery<
    GetSuggestionDocumentFormats,
    GetSuggestionDocumentFormatsVariables
  >(Get_Suggest_Document_Formats, {
    client,
    variables: {
      id: props.orderId,
    },
    fetchPolicy: "network-only",
  })

  if (error) {
    return <ErrorMessage message={error.message} />
  }

  const mutateUpdateDocument = useMutation<
    UpdateDocumentFormat,
    UpdateDocumentFormatVariables
  >(UPDATE_DOCUMENT_FORMAT, { client })

  const selectTemplate = (tempId: string) => {
    setMutateLoading(true)
    mutateUpdateDocument({
      variables: {
        input: {
          orderId: props.orderId,
          documentFormatId: tempId,
          styleTemplate2Id: null,
        },
      },
    }).then((res) => {
      if (res.data) {
        setMutateLoading(false)
        props.onClose()
      }
    })
  }

  const suggestionDocumentFormats =
    data &&
    data.orders &&
    data.orders[0].mediaOrder &&
    data?.orders[0].mediaOrder?.suggestionDocumentFormats

  return (
    <Modal
      isOpen={props.isOpen}
      onRequestClose={props.onClose}
      isLoading={loading || isMutateLoading}
      large={true}
      className={styles.advertSuggestionModal}
      showCloseIcon={false}
    >
      {data && data.orders && (
        <div
          className={cx(styles.advertSuggestionWarpper, "suggestionWrapper")}
        >
          <div className={styles.headerWrapper}>
            <h3>{props.t("designSuggestionTitle")}</h3>
            <div className={styles.closeButton} onClick={props.onClose}>
              <FontAwesomeIcon icon={faTimes} />
            </div>
          </div>
          <hr className={styles.separator} />
          <Carousel
            className={styles.carousel}
            slidesToShow={SLIDER_COUNT_PER_VIEW}
            slidesToScroll={SLIDER_COUNT_PER_VIEW}
            renderBottomCenterControls={null}
            enableKeyboardControls={true}
            renderCenterLeftControls={({ previousSlide, previousDisabled }) => {
              if (previousDisabled) {
                return null
              }
              return (
                <div
                  onClick={previousSlide}
                  style={{
                    marginLeft: "15px",
                  }}
                  className={"arrowPrev"}
                >
                  <FontAwesomeIcon icon={faArrowCircleLeft} />
                </div>
              )
            }}
            renderCenterRightControls={({ nextSlide, nextDisabled }) => {
              if (nextDisabled) {
                return null
              }

              return (
                <div
                  onClick={nextSlide}
                  style={{
                    marginRight: "15px",
                  }}
                  className={"arrowNext"}
                >
                  <FontAwesomeIcon icon={faArrowCircleRight} />
                </div>
              )
            }}
          >
            {suggestionDocumentFormats &&
              suggestionDocumentFormats.map((item, index) => {
                return (
                  <div
                    className={cx(styles.cardWrapper, {
                      [styles.tooLongAd]:false

                    })}
                    key={index}
                  >
                    <div
                      className={styles.card}
                      onClick={() => selectTemplate(item.id)}
                    >
                      <img src={item.previewUrl} alt="timecut" />
                      <span>{item.name}</span>
                    </div>
                  </div>
                )
              })}
          </Carousel>
          <div className={styles.bottomWrapper}>
            <ExpanderText t={props.t} />
          </div>
        </div>
      )}
    </Modal>
  )
}

export default AdvertSuggestionModal
