import * as React from "react"
import * as styles from "./styles.scss"
import { blurOnEnter } from "utils"

function InputField(props: InputFieldProps) {
  const { label, ...rest } = props

  return (
    <div className={styles.inputField}>
      <b className={styles.fieldTitle}>{label}</b>
      <input onKeyDown={blurOnEnter} className={styles.field} {...rest} />
    </div>
  )
}

interface InputFieldProps {
  label: string
  value: string
  placeholder: string
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  onBlur: (e: React.ChangeEvent<HTMLInputElement>) => void
}

export default InputField
