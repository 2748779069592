import * as React from "react"
import GET_GALLERY_IMAGE_INFORMATION from "graphql/queries/getGalleryImageInformation"
import ImageSelectorModalInner from "./ImageSelectorModalInner"
import {
  EditModelAdvertAreaContentRepository,
  GetGalleryImageInformation,
  GetGalleryImageInformationVariables,
} from "schema"
import { useApolloClient, useQuery } from "react-apollo-hooks"
import {GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor} from 'schema'
import { AppContext } from "App"

const ImageSelectorModal: React.FC<{
  editor?: GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor
  isOpen: boolean
  selectedSymbolId?: string
  onRequestClose: () => void
  onSave: (
    symbolId: string,
    symbolPublicId: string,
    imageThumbnaill100x80Url: string,
    repository: EditModelAdvertAreaContentRepository,
    callback: () => void
  ) => void
  templateAreaId?: string | null
  documentFormatId?: string | undefined
  repository?: EditModelAdvertAreaContentRepository | null
  mediaId?: string | null | undefined
}> = (props) => {
  const {featureFlags} = React.useContext(AppContext)
  const client = useApolloClient()
  let ids: GetGalleryImageInformationVariables["ids"] = []
  if (
    props.selectedSymbolId === undefined ||
    props.selectedSymbolId === null ||
    props.selectedSymbolId === ""
  ) {
    //
  } else {
    if (Array.isArray(props.selectedSymbolId)) {
      ids = props.selectedSymbolId
    } else {
      ids = [props.selectedSymbolId]
    }
  }

  const { data, loading, error } = useQuery<
    GetGalleryImageInformation,
    GetGalleryImageInformationVariables
  >(GET_GALLERY_IMAGE_INFORMATION, {
    variables: { ids },
    client,
    context: {

    },
  })


  if (loading || error) {
    return <></>
  }

  if (!data) {
    return <></>
  }
  if (!data.galleryImages) {
    return <></>
  }


  return (
    <ImageSelectorModalInner
      isOpen={props.isOpen}
      onRequestClose={props.onRequestClose}
      onSave={props.onSave}
      selectedSymbol={data.galleryImages[0]}
      editor={props.editor}
      templateAreaId={props.templateAreaId!}
      documentFormatId={props.documentFormatId!}
      repository={props.repository}
      mediaId={props.mediaId}
      isFEUser={featureFlags?.isFEUser!}
      
    />
  )
}

export default ImageSelectorModal
