import { library } from "@fortawesome/fontawesome-svg-core"
import {
  faCalendarAlt,
  faTrashAlt,
  faSyncAlt,
  faCheck,
  faEllipsisV,
  faBars,
  faCircle,
  faCheckCircle,
  faAngleDown,
  faAngleUp,
  faCaretDown,
  faCaretUp,
  faCaretRight,
  faFolderOpen,
  faExclamationTriangle,
  faPlus,
  faPlusCircle,
  faUser,
  faEye,
  faEyeSlash,
  faBold,
  faAlignLeft,
  faAlignCenter,
  faAlignRight,
  faAlignJustify,
  faSearch,
  faUndo,
  faColumns,
  faTh,
  faTimes,
  faTimesCircle,
  faArrowRight,
  faArrowLeft,
  faImage,
  faDownload,
  faExclamationCircle,
  faSpinner,
  faCog,
  faPrint,
  faInbox,
  faEdit,
  faLock,
  faUnlock,
  faArrowsAlt,
  faFont,
  faExpand,
  faStar,
  faMinus,
  faInfoCircle,
  faChevronDown,
  faChevronUp,
  faChevronCircleDown,
  faChevronCircleUp,
  faUsers,
  faImages,
  faChartArea,
  faFolder,
  faTrash,
  faArchive,
  faSearchPlus,
  faClock as faClock2
} from "@fortawesome/free-solid-svg-icons"

import {
  faClock,
  faCheckCircle as faCheckCircleRegular,
  faNewspaper,
  faHandPointLeft,
  faDotCircle,
} from "@fortawesome/free-regular-svg-icons"

library.add(
  faClock2,
  faArchive,
  faTrash,
  faCalendarAlt,
  faTrashAlt,
  faSyncAlt,
  faCheck,
  faEllipsisV,
  faBars,
  faCircle,
  faCheckCircle,
  faAngleDown,
  faAngleUp,
  faCaretDown,
  faCaretUp,
  faCaretRight,
  faFolder,
  faFolderOpen,
  faClock,
  faExclamationTriangle,
  faPlusCircle,
  faPlus,
  faUser,
  faEye,
  faBold,
  faAlignLeft,
  faAlignCenter,
  faAlignRight,
  faAlignJustify,
  faEye,
  faEyeSlash,
  faSearch,
  faUndo,
  faColumns,
  faTh,
  faDotCircle,
  faTimes,
  faTimesCircle,
  faArrowRight,
  faArrowLeft,
  faImage,
  faDownload,
  faExclamationCircle,
  faSpinner,
  faCog,
  faPrint,
  faCheckCircleRegular,
  faInbox,
  faHandPointLeft,
  faNewspaper,
  faEdit,
  faLock,
  faUnlock,
  faArrowsAlt,
  faFont,
  faExpand,
  faStar,
  faMinus,
  faInfoCircle,
  faChevronDown,
  faUsers,
  faImages,
  faChartArea,
  faChevronDown,
  faChevronUp,
  faChevronCircleDown,
  faChevronCircleUp,
  faSearchPlus
)
