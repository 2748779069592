import "./wdyr" // <--- first import
import * as React from "react"
import * as ReactDOM from "react-dom"
import App from "./App"
import registerServiceWorker from "./registerServiceWorker"
import { BrowserRouter } from "react-router-dom"
import { ApolloProvider } from "react-apollo"
import { ApolloProvider as ApolloProviderHooks } from "react-apollo-hooks"
// import * as svSE from "date-fns/locale/sv"
import { enGB, sv } from "date-fns/locale"
import { registerLocale } from "react-datepicker"
import Spinner from "components/Spinner"
import { Settings } from "luxon"
import { createApolloClient } from "apolloClient"
import { getClientPackageVersion } from "clientInfo"
import { log, enableAll, info } from "appLog"
import { GlobalErrorContex, GlobalErrorData } from "GlobalErrorContext"
import "./i18n"
import { ErrorBoundary } from "react-error-boundary"

const RenderApplication = () => {
  enableAll()
  info("app version: ", getClientPackageVersion())

  log(`index: try to setup locale`)
  registerLocale("sv-SE", sv)
  registerLocale("en", enGB)
  Settings.defaultLocale = "sv-se"
  log(`index: locale registered with langcode ${Settings.defaultLocale}`)

  log(`REACT_APP_ENVIRONMENT="${process.env.REACT_APP_ENVIRONMENT}"`)
  log(`REACT_APP_TAPS5_BACKEND="${process.env.REACT_APP_TAPS5_BACKEND}"`)
  log(`NODE_ENV="${process.env.NODE_ENV}"`)

  return (
    <GlobalErrorData>
      <GlobalErrorContex.Consumer>
        {({ showMessage }) => {
          const apolloClient = createApolloClient({ showMessage })
          const myErrorHandler = (
            error: Error,
            { componentStack }: { componentStack: string }
          ) => {
            if (showMessage) {
              showMessage({
                message: error.message,
                error: componentStack.toString(),
              })
            }
          }
          function ErrorFallback({ error, resetErrorBoundary }: any) {
            return (
              <div role="alert">
                <p>Something went wrong:</p>
                <pre>{error.message}</pre>
                <button onClick={resetErrorBoundary}>Try again</button>
              </div>
            )
          }

          return (
            <ErrorBoundary
              onError={myErrorHandler}
              FallbackComponent={ErrorFallback}
            >
              <ApolloProvider client={apolloClient}>
                <ApolloProviderHooks client={apolloClient}>
                  <BrowserRouter>
                    <React.Suspense fallback={<Spinner fullscreen={true} />}>
                      <App />
                    </React.Suspense>
                  </BrowserRouter>
                </ApolloProviderHooks>
              </ApolloProvider>
            </ErrorBoundary>
          )
        }}
      </GlobalErrorContex.Consumer>
    </GlobalErrorData>
  )
}

ReactDOM.render(
  <RenderApplication />,
  document.getElementById("root") as HTMLElement
)

registerServiceWorker()
