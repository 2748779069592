import * as React from "react"
import * as styles from "./styles.scss"
import * as cx from "classnames"

class BackgroundImage extends React.PureComponent<BackgroundImageProps> {
  render() {
    return (
      <div
        className={cx(styles.background, this.props.className)}
        style={{ backgroundImage: `url(${this.props.src})` }}
      />
    )
  }
}

interface BackgroundImageProps {
  src: string
  className?: string
}

export default BackgroundImage
