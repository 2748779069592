import * as React from "react"
import * as styles from "./styles.scss"
import Modal from "components/Modal"
import Header from "components/Header"
import Attachments from "./Attachments"
import Products from "./Products"
// import ProofButtons from "./ProofButtons"
import MediateToCustomerForm from "./MediateToCustomerForm"
import { useTranslation, withTranslation, WithTranslation } from "react-i18next"
import { MediateMaterial, MediateMaterialVariables } from "schema"
import Button from "components/Button"
import { produce } from "immer"
import { useApolloClient, useMutation } from "react-apollo-hooks"
import MEDIATE_MATERIAL from "graphql/mutations/mediateMeterial"
import { ErrorText } from "components/ErrorMessage"
import { useContextSelector } from "use-context-selector"
import { OrderEditorContext } from "pages/EditOrderPage/OrderEditorContainer/OrderEditoryAdvert"
import cx from "classnames"
import doubleCheckIcon from "images/select_all_icon.svg"
import ProofSetting from "./ProofSetting"
import { AppContext } from "App"

interface ProofModalProps {
  isOpen: boolean
  // productOrders: GetEditOrderPageCaseFolder_caseFolder_documents_orders[]
  onRequestClose: () => void
}

interface ProofModalState {
  products: any
  attachments: AttachmentsObj
  proofSetting?: IProofSetting
}

export interface IProofSetting {
  isSuggestionMediaOrders: boolean
  isVoucherKey: boolean
  isCaseFeeIncludedInOrders: boolean
  isCaseFeeIncludedInFirstOrder: boolean
  isCaseFeeHide: boolean
}

export interface AttachmentsObj {
  isOrdersProof: boolean
  isOrderGalleryImages: boolean
  isOrderPreviewPng: boolean
}

const ProofModal: React.FC<ProofModalProps> = (props) => {
  const {
    isDefaultProofCaseFeeAddOnOrders,
    isDefaultProofSuggestionThanksAdvert,
    isDefaultProofCaseFeeAddOnFirstOrder,
    isDefaultProofCaseFeeHide,
  } = React.useContext(AppContext).currentCustomer.inheritSettings
  const [isHover, setIsHover] = React.useState(false)
  const client = useApolloClient()
  const [loading, setLoading] = React.useState(false)
  const [error, setError] = React.useState<undefined | string>(undefined)
  const mediateMaterialMutation = useMutation<
    MediateMaterial,
    MediateMaterialVariables
  >(MEDIATE_MATERIAL, { client })
  const { productOrders } = useContextSelector(OrderEditorContext, (s) => {
    return {
      productOrders:
        s.orders?.filter(
          (x) => x.document?.id === s.selectedOrder?.document?.id
        ) ?? [],
    }
  })

  const { t } = useTranslation(["common", "errorMessages", "proof"])
  const [state, setState] = React.useState<ProofModalState>({
    products: {},
    attachments: {
      isOrdersProof: true,
      isOrderGalleryImages: false,
      isOrderPreviewPng: false,
    },
    proofSetting: {
      isSuggestionMediaOrders: isDefaultProofSuggestionThanksAdvert!,
      isVoucherKey: true,
      isCaseFeeIncludedInOrders: isDefaultProofCaseFeeAddOnOrders!,
      isCaseFeeIncludedInFirstOrder: isDefaultProofCaseFeeAddOnFirstOrder!,
      isCaseFeeHide: isDefaultProofCaseFeeHide!,
    },
  })

  //Basheer: this will check if products include thanks advert
  //if it included thank advert will add special setting to proof setting
  //new setting add ones component trigger
  const THANKS_ADVERT_ID = "dc3163ca-40d0-43d2-b3a2-76bacd1fd92b"
  const isThankAdvert = productOrders.some((order) => {
    return order.mediaOrder?.documentFormat.documentType.id === THANKS_ADVERT_ID
  })

  // const [isVisible, setIsVisible] = React.useState(false)
  // const ToggleExpandMediate = () => setIsVisible(!isVisible)

  const onCloseModel = () => {
    props.onRequestClose()
  }

  const toggleProductCheckbox = (id: string) => {
    const products = {
      ...state.products,
      [id]: {
        ...state.products[id],
        checked: !state.products[id].checked,
      },
    }
    setState(
      produce(state, (draft) => {
        draft.products = products
      })
    )
  }

  const toggleAttachmentCheckbox = (name: string) => {
    setState(
      produce(state, (draft) => {
        draft.attachments = {
          ...state.attachments,
          [name]: !state.attachments[name],
        }
      })
    )
  }

  const toggleIsSuggestionMediaOrdersRadio = () => {
    setState(
      produce(state, (draft) => {
        draft.proofSetting!.isSuggestionMediaOrders = !draft.proofSetting
          ?.isSuggestionMediaOrders!
      })
    )
  }

  const toggleIsVoucherKeyRedio = () => {
    setState(
      produce(state, (draft) => {
        draft.proofSetting!.isVoucherKey = !draft.proofSetting?.isVoucherKey!
      })
    )
  }

  const toggleCaseFeeIncludedInOrder = () => {
    setState(
      produce(state, (draft) => {
        draft.proofSetting!.isCaseFeeIncludedInOrders = !draft.proofSetting
          ?.isCaseFeeIncludedInOrders!
        if (draft.proofSetting?.isCaseFeeIncludedInOrders) {
          draft.proofSetting!.isCaseFeeIncludedInFirstOrder = false
        }

        if (draft.proofSetting?.isCaseFeeIncludedInOrders) {
          draft.proofSetting.isCaseFeeHide = false
        }
      })
    )
  }

  const toggleIsCaseFeeIncludedInFirstOrder = () => {
    setState(
      produce(state, (draft) => {
        draft.proofSetting!.isCaseFeeIncludedInFirstOrder = !draft.proofSetting
          ?.isCaseFeeIncludedInFirstOrder!
        if (draft.proofSetting?.isCaseFeeIncludedInFirstOrder) {
          draft.proofSetting!.isCaseFeeIncludedInOrders = false
        }

        if (draft.proofSetting?.isCaseFeeIncludedInFirstOrder) {
          draft.proofSetting.isCaseFeeHide = false
        }
      })
    )
  }

  const toggleCaseFeeHide = () => {
    setState(
      produce(state, (draft) => {
        draft.proofSetting!.isCaseFeeHide = !draft.proofSetting?.isCaseFeeHide!
        if (draft.proofSetting!.isCaseFeeHide) {
          draft.proofSetting!.isCaseFeeIncludedInFirstOrder = false
          draft.proofSetting!.isCaseFeeIncludedInOrders = false
        }
      })
    )
  }

  React.useEffect(() => {
    setState(
      produce(state, (draft) => {
        draft.products = productOrders.reduce(
          (productsAcc, order) => ({
            ...productsAcc,
            [order.id!]: {
              label:
                order.mediaOrder!.media.friendlyName +
                " " +
                order.customerFriendlyId,
              checked: true,
            },
          }),
          {}
        )
        if (isThankAdvert) {
          draft.proofSetting!.isCaseFeeIncludedInFirstOrder = false
          draft.proofSetting!.isCaseFeeIncludedInOrders = false
          draft.proofSetting!.isCaseFeeHide = true
        }
      })
    )
  }, [])

  const getCheckedOrderIds = () => {
    const { products } = state
    return Object.keys(products).filter((x) => products[x].checked)
  }

  const downloadURI = (uri: any, name: string) => {
    window.open(uri, "_blank")
    return
    // const link = document.createElement("a")
    // link.download = name
    // link.href = uri
    // link.target = "_blank"
    // document.body.appendChild(link)
    // link.click()
    // document.body.removeChild(link)
  }

  const onClickDownload = () => {
    if (loading === true) {
      return
    }
    setLoading(true)
    setError(undefined)
    mediateMaterialMutation({
      variables: {
        input: {
          isOrdersProof: state.attachments.isOrdersProof,
          isOrderGalleryImages: state.attachments.isOrderGalleryImages,
          isOrderPreviewPng: state.attachments.isOrderPreviewPng,
          orderIds: getCheckedOrderIds(),
          isSendEmailReplyCopy: false,
          proofSetting: state.proofSetting,
          emailReplyAddress: undefined,
          emailToAddress: undefined,
          message: undefined,
        },
      },
      context: {},
      errorPolicy: "all",
    })
      .then(
        (response) => {
          setLoading(false)
          if (response.errors) {
            setError(response.errors.map((e) => e.message).join(", "))
            return
          }

          if (!response.data) {
            setError(t("errorMessages:unknowError"))
            return
          }

          if (response.data.messagingOrdersMaterials.isError === true) {
            setError(
              response.data.messagingOrdersMaterials.errorReason ??
                t("errorMessages:unknowError")
            )
            return
          }

          if (!response.data.messagingOrdersMaterials.isError) {
            if (state.attachments.isOrdersProof) {
              downloadURI(
                response.data.messagingOrdersMaterials.material.proof.url,
                "proof.pdf"
              )
            }

            if (state.attachments.isOrderGalleryImages) {
              response.data.messagingOrdersMaterials.material.galleryImages.map(
                (item) => {
                  downloadURI(item.url, "symbol.png")
                }
              )
            }

            if (state.attachments.isOrderPreviewPng) {
              response.data.messagingOrdersMaterials.material.orders.map(
                (item) => {
                  downloadURI(item.previewPng?.url, "annons.png")
                }
              )
            }
          } else {
            setError(
              response.data.messagingOrdersMaterials.errorReason ??
                t("errorMessages:unknowError")
            )
          }
        },
        (err) => {
          setLoading(false)
          setError(err.toString())
        }
      )
      .catch((err) => {
        setLoading(false)
        setError(err.toString())
      })
  }

  const handleHover = () => {
    setIsHover(true)
  }
  const handleClearHover = () => {
    setIsHover(false)
  }

  const handleSelectAll = () => {
    setState(
      produce(state, (draft) => {
        draft.attachments = {
          ...state.attachments,
          isOrdersProof: true,
          isOrderPreviewPng: true,
          isOrderGalleryImages: true,
        }
        draft.products = productOrders.reduce(
          (productsAcc, order) => ({
            ...productsAcc,
            [order.id!]: {
              label: order.mediaOrder?.media.friendlyName,
              checked: true,
            },
          }),
          {}
        )
      })
    )
  }

  return (
    <Modal
      className={styles.modal}
      isOpen={props.isOpen}
      onRequestClose={onCloseModel}
    >
      {/* <Header title={t("proof:proof")} subtitle={t("proof:proofDescription")} /> */}
      <Header title={t("proof:proof")} />
      <div className={styles.row}>
        <Attachments
          values={state.attachments}
          onChange={toggleAttachmentCheckbox}
        />
        <Products
          products={state.products}
          onToggleCheckbox={toggleProductCheckbox}
        />
        <ProofSetting
          settings={state.proofSetting!}
          toggleIsSuggestionMediaOrdersRadio={
            toggleIsSuggestionMediaOrdersRadio
          }
          toggleIsVoucherKeyRedio={toggleIsVoucherKeyRedio}
          toggleCaseFeeIncludedInOrder={toggleCaseFeeIncludedInOrder}
          toggleIsCaseFeeIncludedInFirstOrder={
            toggleIsCaseFeeIncludedInFirstOrder
          }
          toggleCaseFeeHide={toggleCaseFeeHide}
        />
      </div>

      <div className={styles.downloadButtonContainer}>
        <div
          className={styles.selectAll}
          onMouseEnter={handleHover}
          onMouseLeave={handleClearHover}
        >
          <Button
            onClick={handleSelectAll}
            className={cx(styles.downloadButton, {
              [styles.hoverBlue]: isHover,
            })}
          >
            <img
              src={doubleCheckIcon}
              alt="Taps5"
              width="20px"
              height="20px"
              className={cx("", { [styles.filterBlue]: isHover })}
            />
            {t("proof:selectAll")}
          </Button>
        </div>

        <Button
          onClick={onClickDownload}
          className={styles.downloadButton}
          isWorking={loading}
          disabled={loading}
          icon="download"
          id={"download-button"}
        >
          {t("proof:download")}
        </Button>
      </div>

      {error ? (
        <div>
          <ErrorText>{error}</ErrorText>
        </div>
      ) : null}

      <div className={cx(styles.mediate)}>
        <hr className={styles.line} />
        {/* <Header title={t("proof:mediateToCustomer")} /> */}
        <MediateToCustomerForm
          products={getCheckedOrderIds()}
          attachments={state.attachments}
          proofSetting={state.proofSetting!}
        />
      </div>
    </Modal>
  )
}

// class ProofModal extends React.PureComponent<
//   ProofModalProps & WithTranslation,
//   ProofModalState
// > {
//   state = {
//     products: {},
//     attachments: {
//       isOrdersProof: true,
//       isOrderGalleryImages: false,
//       isOrderPreviewPng: false,
//     },
//   }

//   toggleProductCheckbox = (id: string) => {
//     const products = {
//       ...this.state.products,
//       [id]: {
//         ...this.state.products[id],
//         checked: !this.state.products[id].checked,
//       },
//     }
//     this.setState({ products })
//   }

//   toggleAttachmentCheckbox = (name: string) =>
//     this.setState({
//       attachments: {
//         ...this.state.attachments,
//         [name]: !this.state.attachments[name],
//       },
//     })

//   componentDidMount = () => {
//     const products = this.props.productOrders.reduce(
//       (productsAcc, order) => ({
//         ...productsAcc,
//         [order.id!]: {
//           label: order.mediaOrder!.media.name,
//           checked: true,
//         },
//       }),
//       {}
//     )
//     this.setState({ products })
//   }

//   getCheckedOrderIds = () => {
//     const { products } = this.state
//     return Object.keys(products).filter(x => products[x].checked)
//   }

//   render() {
//     const { t, onRequestClose, isOpen } = this.props
//     const { products, attachments } = this.state

//     const onClickDownload = () => {
//       //
//     }

//     return (
//       <Modal
//         className={styles.modal}
//         isOpen={isOpen}
//         onRequestClose={onRequestClose}
//       >
//         <Header title={t("proof")} subtitle={t("proofDescription")} />
//         <div className={styles.row}>
//           <Attachments
//             values={attachments}
//             onChange={this.toggleAttachmentCheckbox}
//           />
//           <Products
//             products={products}
//             onToggleCheckbox={this.toggleProductCheckbox}
//           />
//         </div>

//         <div className={styles.downloadButtonContainer}>
//           <Button
//             onClick={onClickDownload}
//             className={styles.downloadButton}
//             isWorking={false}
//             disabled={false}
//             icon="download"
//           >
//             {t("download")}
//           </Button>
//         </div>

//         {/* <ProofButtons checkedOrderIds={this.getCheckedOrderIds()} /> */}

//         <hr className={styles.line} />
//         <Header title={t("mediateToCustomer")} />
//         <MediateToCustomerForm
//           products={this.getCheckedOrderIds()}
//           attachments={attachments}
//         />
//       </Modal>
//     )
//   }
// }

// export default withTranslation("proof")(ProofModal)
export default ProofModal
