import * as React from "react"
import * as styles from "./styles.scss"
import { useApolloClient, useMutation } from "react-apollo-hooks"
import {
  GetEditOrderPageCaseFolder_caseFolder_editTabs_orders,
  GetOrderByIdLiveStream_orders,
  PublishOrderLiveStream,
  PublishOrderLiveStreamVariables,
  ReActivateUnPublishOrders,
  ReActivateUnPublishOrdersVariables,
  UnPublishOrders,
  UnPublishOrdersVariables,
  UpdateOrderContent,
  UpdateOrderContentVariables,
  UpdatePublishTime,
  UpdatePublishTimeVariables,
} from "schema"
import { TopSidebarCaseInfoBlock } from "../OrderEditoryAdvert/TopSidebarCaseInfoBlock"
import Button from "components/Button"
import { useTranslation } from "react-i18next"
import { DropEvent, FileRejection, useDropzone } from "react-dropzone"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useFormik } from "formik"
import { object, string } from "yup"
import {
  FieldError,
  FieldSuccess,
  FieldWrapperFormik,
} from "components/FieldWrapper"
import GET_ORDER_BY_ID_LIVE_STREAM from "graphql/queries/getOrderByIdLivestream"
import {
  faChevronUp,
  faChevronDown,
  faPlusCircle,
  faTimes,
} from "@fortawesome/free-solid-svg-icons"
import UPDATE_ORDER_CONTENT from "graphql/mutations/updateOrderContentValues"
import Spinner from "components/Spinner"
import UPDATE_PUBLISH_TIME from "graphql/mutations/updatePublishTime"
import { RemoveItemPopup } from "./RemoveItemPopup"
import styled from "styled-components"
import Modal from "components/Modal"
import { ImageEditor } from "./ImageEditor"
import { SubMenu } from "./SubMenu"
import cx from "classnames"
import Status from "components/Status"
import LockerBox from "components/LockerBox"
import UNPUBLISH_ORDERS from "graphql/mutations/unPublishOrders"
import REACTIVE_ORDERS from "graphql/mutations/reactiveOrders"
import { FormikDateAndTimeField } from "components/DateAndTimeField"
import ParallelPublishing from "pages/ReviewPage/ReviewContainer/DocumentTypeCard/OrderInformation/ParallelPublishing"
import PUBLISH_ORDER_LIVESTREAM from "graphql/mutations/publishOrderLiveStream"

export interface ImageSelectDialogFile {
  fileFormInput: any
  name: any
  type: any
  lastModified: any
  lastModifiedDate: any
  size: any
  path: any
  preview: any
}

export interface Image {
  id: string
  src: string
  alt: string
}

export const StageBeforeActivated: React.FC<{
  orderId: string
  orders: GetEditOrderPageCaseFolder_caseFolder_editTabs_orders[]
  caseFolderId: string
  caseFolderName: string
  waitImageUrl?: string
  publishTimes?: string
  order: GetOrderByIdLiveStream_orders
  isSupportUser: boolean
  orderResult: any
  setOrderResult: any
  refetchQuery: () => void
}> = (props) => {
  const isLocked = props.order.mediaOrder?.isLocked ?? false
  const ceremonyAgendaUrl =
    props.order.mediaOrder?.edit.editModelLiveStream?.ceremonyAgendaUrl ??
    undefined
  const client = useApolloClient()
  const mutationUpdateOrderContent = useMutation<
    UpdateOrderContent,
    UpdateOrderContentVariables
  >(UPDATE_ORDER_CONTENT, {
    client,
    variables: {
      input: {
        orderId: props.orderId,
        editModel: {
          editModelLiveStream: {
            waitImageBase64: "",
          },
        },
      },
    },
    context: {},
    refetchQueries: [
      {
        query: GET_ORDER_BY_ID_LIVE_STREAM,
        variables: { id: props.orderId },
      },
    ],
  })
  const [mutationImageLoading, setMutationImageLoading] = React.useState<
    boolean
  >(false)
  const [mutationImageError, setMutationImageError] = React.useState<
    string | undefined
  >(undefined)

  const mutationPublishOrders = useMutation<
    PublishOrderLiveStream,
    PublishOrderLiveStreamVariables
  >(PUBLISH_ORDER_LIVESTREAM, {
    client,
    context: {},
    variables: {
      input: {
        orders: [{ orderId: props.orderId }],
      },
    },
    // update: (proxy, data) => {
    //   proxy.writeData({
    //     data: {
    //       publishOrders: {
    //         errorReason: null,
    //         isAddedMemoryRoom: false,
    //         isError: false,
    //         isPublishMemoryRoom: false,
    //         isPublishMemoryRoomError: false, 
    //         publishMemoryRoomErrorText: null,
    //         resultIcon: "NONE",
    //         resultText: null,
    //         resultOrders: [props.order]
    //       }
    //     }
    //   })
    // },
    refetchQueries: [
      {
        query: GET_ORDER_BY_ID_LIVE_STREAM,
        variables: { id: props.orderId },
      },
    ],
  })

  const mutationUnpublishOrder = useMutation<
    UnPublishOrders,
    UnPublishOrdersVariables
  >(UNPUBLISH_ORDERS, {
    client,
    context: {},
    refetchQueries: [
      {
        query: GET_ORDER_BY_ID_LIVE_STREAM,
        variables: { id: props.orderId },
      },
    ],
  })

  const mutationReActiveOrder = useMutation<
    ReActivateUnPublishOrders,
    ReActivateUnPublishOrdersVariables
  >(REACTIVE_ORDERS, {
    client,
    context: {},
    refetchQueries: [
      {
        query: GET_ORDER_BY_ID_LIVE_STREAM,
        variables: { id: props.orderId },
      },
    ],
  })

  const [mutationPublishLoading, setMutationPublishLoading] = React.useState<
    boolean
  >(false)
  const [mutationPublishError, setMutationPublishError] = React.useState<
    string | undefined
  >(undefined)
  const [showDetails, setShowDetalis] = React.useState<boolean>(false)
  const mutationUpdatePublishTime = useMutation<
    UpdatePublishTime,
    UpdatePublishTimeVariables
  >(UPDATE_PUBLISH_TIME, {
    client,
    context: {},
    refetchQueries: [
      {
        query: GET_ORDER_BY_ID_LIVE_STREAM,
        variables: { id: props.orderId },
      },
    ],
  })
  const [mutationTimeLoading, setMutationTimeLoading] = React.useState<boolean>(
    false
  )
  const [mutationTimeError, setMutationTimeError] = React.useState<
    string | undefined
  >(undefined)

  const { t } = useTranslation(["common", "errorMessages"])

  const schema = object().shape({
    publishDate: string().required(),
    // publishTime: string().required(),
    waitImageBase64: string().optional(),
  })
  interface FormData {
    publishDate: Date | undefined
    // publishTime: moment.Moment | string | undefined
    waitImageBase64: string | undefined
  }

  const formik = useFormik<FormData>({
    initialValues: {
      publishDate:
        props.publishTimes === undefined
          ? undefined
          : new Date(props.publishTimes),
      // publishTime:
      //   props.publishTimes === undefined
      //     ? ""
      //     : moment(new Date(props.publishTimes)),
      waitImageBase64: undefined,
    },
    validationSchema: schema,
    validateOnBlur: true,
    validateOnChange: true,
    onSubmit: (values) => {
      // window.alert(`Submit with values ${JSON.stringify(values)}`)
    },
  })

  const [mainAvatar, setMainAvatar] = React.useState<Image | null>(
    props.waitImageUrl === undefined
      ? null
      : {
          id: "0",
          alt: "",
          src: props.waitImageUrl,
        }
  )
  const [isCropImageModalVisible, setIsCropImageModalVisible] = React.useState<
    boolean
  >(false)
  const [
    cropImageSelected,
    setCropImageSelected,
  ] = React.useState<ImageSelectDialogFile | null>(null)
  const [
    removeImageDialogVisible,
    setRemoveImageDialogVisible,
  ] = React.useState<boolean>(false)
  const onAbortRemove = () => {
    setRemoveImageDialogVisible(false)
  }
  const onSuccessRemove = () => {
    setRemoveImageDialogVisible(false)
    setMainAvatar(null)
    formik.setFieldValue("waitImageBase64", undefined)
    triggerMutationImage("")
  }
  const onClickRemoveImage = () => {
    setRemoveImageDialogVisible(true)
  }

  const onCloseCropModal = () => {
    setIsCropImageModalVisible(false)
    setCropImageSelected(null)
  }

  const onSuccessCropFromModal = (blob: any) => {
    const preview = URL.createObjectURL(blob)
    const reader = new FileReader()
    reader.onloadend = () => {
      const base64data = reader.result?.toString() ?? undefined
      if (!base64data) {
        setMutationImageError(t("errorMessages:unknowError"))
        return
      }
      formik.setFieldValue("waitImageBase64", base64data)
      triggerMutationImage(base64data)
      // .replace(/^data:(.*,)?/, "")
    }
    reader.readAsDataURL(blob)
    setMainAvatar({
      id: (Math.random() * 10000).toString(),
      src: preview,
      alt: "",
    })
    setIsCropImageModalVisible(false)
    setCropImageSelected(null)
  }

  const triggerMutationImage = (base64data: string) => {
    setMutationImageLoading(true)
    setMutationImageError(undefined)
    mutationUpdateOrderContent({
      variables: {
        input: {
          orderId: props.orderId,
          editModel: {
            editModelLiveStream: {
              waitImageBase64: base64data,
            },
          },
        },
      },
    })
      .then((response) => {
        setMutationImageLoading(false)

        if (response.errors) {
          setMutationImageError(
            response.errors.map((e) => e.message).join(", ")
          )
          return
        }

        if (!response.data) {
          setMutationImageError(t("errorMessages:unknowError"))
          return
        }

        if (response.data.updateOrderContent.isError === true) {
          setMutationImageError(
            response.data.updateOrderContent.errorReason ??
              t("errorMessages:unknowError")
          )
          return
        }

        setMutationImageError(undefined)
      })
      .catch((err) => {
        setMutationImageLoading(false)
        setMutationImageError(err.toString())
      })
  }

  const { getRootProps, getInputProps } = useDropzone({
    onDropAccepted: (acceptedFiles: any[], event: DropEvent) => {
      const file = acceptedFiles[0]
      const imageSelectDialogFile: ImageSelectDialogFile = {
        fileFormInput: file,
        name: file.name,
        type: file.type,
        lastModified: file.lastModified,
        lastModifiedDate: file.lastModifiedDate,
        size: file.size,
        path: file.path,
        preview: URL.createObjectURL(file),
      }
      setIsCropImageModalVisible(true)
      setCropImageSelected(imageSelectDialogFile)
    },
    onDropRejected: (fileRejections: FileRejection[], event: DropEvent) => {
      const errors = fileRejections
        .map((file) => {
          return file.errors.map((e) => e.message).join(", ")
        })
        .join(", ")
      formik.setFieldTouched("waitImageBase64", true, false)
      formik.setFieldValue("waitImageBase64", undefined, false)
      formik.setFieldError("waitImageBase64", errors)
    },
    multiple: false,
    noKeyboard: true,
    noDrag: true,
    accept: "image/*",
    maxFiles: 1,
    maxSize: 10 * 1024 * 1024,
    minSize: 1024,
    disabled: isLocked === true,
  })

  const triggerMutationTime = (date: string) => {
    setMutationTimeLoading(true)
    setMutationTimeError(undefined)
    mutationUpdatePublishTime({
      variables: {
        input: {
          orderId: props.orderId,
          publishTimes: [date],
        },
      },
    })
      .then((response) => {
        setMutationTimeLoading(false)

        if (response.errors) {
          setMutationTimeError(response.errors.map((e) => e.message).join(", "))
          return
        }

        if (!response.data) {
          setMutationTimeError(t("errorMessages:unknowError"))
          return
        }

        if (response.data.updateOrderPublishTimes.isError === true) {
          setMutationTimeError(
            response.data.updateOrderPublishTimes.errorReason ??
              t("errorMessages:unknowError")
          )
          return
        }

        setMutationTimeError(undefined)
      })
      .catch((err) => {
        setMutationTimeLoading(false)
        setMutationTimeError(err.toString())
      })
  }

  const onChangePublishDate = (d1: Date | null) => {
    if (!d1) {
      return
    }
    const newValue = new Date(d1)
    formik.setFieldValue("publishDate", newValue)
    triggerMutationTime(newValue.toISOString())
  }

  // const onChangeDate = (d1: Date | null) => {
  //   if (!d1) {
  //     return
  //   }
  //   const newValue = new Date(d1.setUTCMinutes(-d1.getTimezoneOffset()))
  //   formik.setFieldValue("publishDate", newValue)
  //   const newDate = moment(newValue)
  //     .set("hour", 0)
  //     .set("minute", 0)
  //     .set("second", 0)

  //   if (
  //     formik.values.publishTime !== undefined &&
  //     formik.values.publishTime !== "" &&
  //     typeof formik.values.publishTime !== "string"
  //   ) {
  //     newDate
  //       .set("hour", formik.values.publishTime.get("hour"))
  //       .set("minute", formik.values.publishTime.get("minute"))
  //       .set("second", 0)
  //   }
  //   triggerMutationTime(newDate.toISOString())
  // }

  // const onChangeTime = (newValue: moment.Moment) => {
  //   formik.setFieldValue("publishTime", newValue)
  //   if (formik.values.publishDate !== undefined) {
  //     const d2 = moment(formik.values.publishDate)
  //     const newDate = moment(newValue.toString())
  //       .set("day", d2.get("day"))
  //       .set("month", d2.get("month"))
  //       .set("year", d2.get("year"))
  //     triggerMutationTime(newDate.format())
  //   }
  // }

  const triggerMutationPublishOrders = () => {
    setMutationPublishLoading(true)
    setMutationPublishError(undefined)
    mutationPublishOrders({
      variables: {
        input: {
          orders: [
            {
              orderId: props.orderId,
            },
          ],
        },
      },
    })
      .then((response) => {
        setMutationPublishLoading(false)
        if (response.errors) {
          setMutationPublishError(
            response.errors.map((e) => e.message).join(", ")
          )
          return
        }

        if (!response.data) {
          setMutationPublishError(t("errorMessages:unknowError"))
          return
        }

        if (response.data.publishOrders.isError === true) {
          setMutationPublishError(
            response.data.publishOrders.errorReason ??
              t("errorMessages:unknowError")
          )
          return
        }
        props.setOrderResult(response.data?.publishOrders.resultOrders[0])
        setMutationPublishError(undefined)
      })
      .catch((err) => {
        setMutationPublishLoading(false)
        setMutationPublishError(err.toString())
      })
  }

  const onClickSubmit = () => {
    triggerMutationPublishOrders()
    // if (mutationImageLoading === true) {
    //   return
    // }
    // if (mutationTimeLoading === true) {
    //   return
    // }
    // if (mutationPublishLoading === true) {
    //   return
    // }
    // formik.handleSubmit()
  }

  const handleShowDetails = () => setShowDetalis(!showDetails)

  const reactiveOrder = () => {
    mutationReActiveOrder({
      variables: {
        input: {
          orders: [
            {
              orderId: props.orderId,
            },
          ],
        },
      },
    })
      .then(() => console.log)
      .catch((error) => console.log("err", error))
  }

  const onUnpublishOrder = () => {
    mutationUnpublishOrder({
      variables: {
        input: {
          orders: [
            {
              orderId: props.orderId,
              messageToMedia: null,
              codeLate: null,
            },
          ],
        },
      },
    })
      .then(() => console.log)
      .catch((error) => console.log("err", error))
  }

  const friendlyId = props.order.customerFriendlyId
  const currency =
    props.order.mediaOrder?.order.mediaOrder?.preview.price?.currency
  const price =
    props.order.mediaOrder?.order.mediaOrder?.preview.price?.endCustomer.pricePayRounded
  const status = props.order.mediaOrder?.order.mediaOrder?.status
  const userInterface = props.order.mediaOrder?.edit.userInterface



  return (
    <>
      {isCropImageModalVisible ? (
        <ImageSelectDialog
          isDialogOpen={isCropImageModalVisible}
          onRequestClose={onCloseCropModal}
          onSuccessCrop={onSuccessCropFromModal}
          file={cropImageSelected}
        />
      ) : null}

      <div className={styles.orderEditor}>
        <div className={styles.leftBlock}>
          <StylesBeforeActivated>
            <div className={"container top-wrapper"}>
              <div className={"whiteframe"}>
                <SubMenu
                  isLocked={props.order.mediaOrder?.isLocked ?? false}
                  documentId={props.orderId}
                  selectedCaseFolderId={props.caseFolderId}
                  publishTime={
                    props.order.mediaOrder?.publishTimes?.[0]?.time ?? undefined
                  }
                  refetchQuery={props.refetchQuery}
                  isActivated={
                    userInterface?.isUnPublishButton! ||
                    userInterface?.isReactivateButton!
                  }
                  isUnpublishButton={userInterface?.isUnPublishButton!}
                  unPublishButtonText={userInterface?.unPublishButtonText!}
                  onUnPublishOrder={onUnpublishOrder}
                  isReactiveButton={userInterface?.isReactivateButton!}
                  reactiveText={userInterface?.reactivateButtonText!}
                  onReactiveOrder={reactiveOrder}
                  isSupport={props.isSupportUser!}
                  orderId={props.orderId}
                />

                <div className={"lockerBoxContainer"}>
                  {userInterface?.editStatusText && (
                    <LockerBox
                      text={userInterface?.editStatusText}
                      className={"liveStreamBefore"}
                      icon={userInterface.editStatusIcon}
                      color={userInterface.editStatusColor}
                    />
                  )}
                </div>

                <div className={"content"}>
                  <div className={"bgImageWrapper"}>
                    <h2>Direktsändning</h2>
                    <div className="info">
                      <label>Bakgrundsbild inför sändningen</label>
                    </div>
                    <div className={"image-placeholder"}>
                      <div className={"content-wrapper"}>
                        {mainAvatar !== null ? (
                          <div className={"close-wrapper"}>
                            {removeImageDialogVisible ? (
                              <RemoveItemPopup
                                onAbort={onAbortRemove}
                                onSuccess={onSuccessRemove}
                              />
                            ) : null}

                            <div
                              className={"close-icon"}
                              onClick={onClickRemoveImage}
                            >
                              <FontAwesomeIcon
                                icon={faTimes}
                                color={"#fff"}
                                size={"lg"}
                              />
                            </div>
                          </div>
                        ) : null}

                        {mutationImageLoading ? (
                          <div className={"loading-wrapper"}>
                            <Spinner size={20} />
                          </div>
                        ) : null}

                        <div
                          {...getRootProps()}
                          className={"selectImageBtnCss"}
                          onClick={(e) => e.stopPropagation()}
                        >
                          <div {...getRootProps({ className: "dropzone" })}>
                            <input {...getInputProps()} />
                            {mainAvatar === null ? (
                              <div className={"add-icon-with-text"}>
                                <div className={"icon"}>
                                  <FontAwesomeIcon
                                    icon={faPlusCircle}
                                    size={"lg"}
                                    color={"#1289A7"}
                                  />
                                </div>
                                <div className={"text"}>Ladda upp</div>
                              </div>
                            ) : (
                              <div className={"selected-image"}>
                                <img
                                  src={mainAvatar.src}
                                  alt={mainAvatar.alt}
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div>
                      {formik.touched.waitImageBase64 &&
                        formik.errors.waitImageBase64 && (
                          <FieldError text={formik.errors.waitImageBase64} />
                        )}

                      {mutationImageError ? (
                        <FieldError text={mutationImageError} />
                      ) : null}
                    </div>
                  </div>
                  <div className={"uploadBtnWrapper"}>
                    <UploadPdf
                      isLocked={isLocked}
                      orderId={props.orderId}
                      ceremonyAgendaUrl={ceremonyAgendaUrl}
                    />
                  </div>

                  <div className="bottom-info">
                    {/* <label>Bakrundsbild inför sändningen</label>
                    <div className={"icon"}>
                      <FontAwesomeIcon
                        icon={faArrowUp}
                        color={"#272727"}
                        size={"1x"}
                        style={{ transform: "rotate(135deg)" }}
                      />
                    </div> */}
                  </div>
                </div>
              </div>
            </div>

            <div className={"container bottom-wrapper"}>
              <div className={"whiteframe"}>
                <div className={"content"}>
                  <div className={"top-content"}>
                    <div className={"left-content"}>
                      <div className={"show-more"} onClick={handleShowDetails}>
                        <FontAwesomeIcon
                          icon={showDetails ? faChevronUp : faChevronDown}
                        />
                        <span>{t("editOrder:showDetails")}</span>
                      </div>
                      {/* for future use */}
                      {/* <div className={"proof"} >
                        <FontAwesomeIcon icon={faListUl} />
                        <span>{t("proof:proof")}</span>
                      </div> */}
                    </div>
                    <div className={"buttonDate"}>
                      <FieldWrapperFormik
                        value={formik.values.publishDate}
                        onChange={onChangePublishDate}
                        name={"publishDate"}
                        errors={formik.errors.publishDate}
                        id={"live-stream-active-date-input"}
                      >
                        <FormikDateAndTimeField showTimeSelect={true} />
                      </FieldWrapperFormik>
                      {userInterface?.publishButtonText && (
                        <div className={"buttonPublish"}>
                          <Button
                            onClick={onClickSubmit}
                            color={"primary"}
                            variant={"primary"}
                            disabled={!formik.values.publishDate}
                            isWorking={
                              mutationTimeLoading ||
                              mutationImageLoading ||
                              mutationPublishLoading
                            }
                            dataTestId={"active-button"}
                          >
                            {userInterface?.publishButtonText}
                          </Button>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className={"error-content"}>
                    {mutationTimeError && (
                      <FieldError text={mutationTimeError} />
                    )}
                    {mutationPublishError && (
                      <FieldError text={mutationPublishError} />
                    )}
                  </div>

                  <div
                    className={cx("details", {
                      ["isHide"]: !showDetails,
                    })}
                  >
                    <div className={"details-content"}>
                      <table>
                        <thead>
                          <tr>
                            <th>Order</th>
                            <th>Pris</th>
                            <th>status</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>{friendlyId}</td>
                            <td>
                              {price
                                ? `${price} ${currency}`
                                : t("editOrder:noPrice")}
                            </td>
                            <td>
                              <Status
                                variant={status!.color}
                                className={"status"}
                              >
                                {t(`statuses:${status?.status}`)}
                              </Status>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <div className={"publish_Block"}>
                        <ParallelPublishing
                          orderId={props.orderId!}
                          caseFolderId={props.caseFolderId}
                          isLocked={false}
                          publishChannels={
                            props.order.mediaOrder?.publishChannels!
                          }
                          isLiveStream={true}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </StylesBeforeActivated>
        </div>

        <div className={styles.sidebarBlock}>
          <TopSidebarCaseInfoBlock
            name={props.caseFolderName}
            caseId={props.caseFolderId}
          />
        </div>
      </div>
    </>
  )
}

const UploadPdf: React.FC<{
  ceremonyAgendaUrl?: string
  isLocked: boolean
  orderId: string
}> = (props) => {
  const { t } = useTranslation(["common", "errorMessages", "editOrder"])
  const [fileName, setFileName] = React.useState("")

  const schema = object().shape({
    ceremonyAgendaBase64: string(),
  })
  interface FormData {
    ceremonyAgendaBase64: string
  }
  const formik = useFormik<FormData>({
    initialValues: {
      ceremonyAgendaBase64: "",
    },
    validationSchema: schema,
    validateOnBlur: true,
    validateOnChange: true,
    onSubmit: (values) => {
      //
    },
  })

  const onClickDownloadPdf = () => {
    const url = props.ceremonyAgendaUrl ?? ""
    if (url.length === 0) {
      return
    }
    window.open(url, "_blank")
  }
  const { getRootProps, getInputProps } = useDropzone({
    onDropAccepted: (acceptedFiles: any[], event: DropEvent) => {
      const file = acceptedFiles[0]
      const reader = new FileReader()
      reader.addEventListener(
        "load",
        () => {
          const base64data = reader.result?.toString() ?? undefined
          if (!base64data) {
            formik.setFieldTouched("ceremonyAgendaBase64", true, false)
            formik.setFieldValue("ceremonyAgendaBase64", undefined, false)
            formik.setFieldError(
              "ceremonyAgendaBase64",
              t("errorMessages:unknowError")
            )
            return
          }
          triggerMutationPdf(file.name, base64data)
          setFileName(file.name)
        },
        false
      )
      reader.readAsDataURL(file)
    },
    onDropRejected: (fileRejections: FileRejection[], event: DropEvent) => {
      const errors = fileRejections
        .map((file) => {
          return file.errors.map((e) => e.message).join(", ")
        })
        .join(", ")
      formik.setFieldTouched("ceremonyAgendaBase64", true, false)
      formik.setFieldValue("ceremonyAgendaBase64", undefined, false)
      formik.setFieldError("ceremonyAgendaBase64", errors)
    },
    multiple: false,
    noKeyboard: true,
    noDrag: true,
    accept: "application/pdf",
    maxFiles: 1,
    maxSize: 10 * 1024 * 1024,
    minSize: 1024,
    disabled: props.isLocked === true,
  })

  const [mutationPdfLoading, setMutationPdfLoading] = React.useState<boolean>(
    false
  )
  const [mutationPdfError, setMutationPdfError] = React.useState<
    string | undefined
  >(undefined)
  const [mutationPdfInfoStatus, setMutationPdfInfoStatus] = React.useState<
    string | undefined
  >(undefined)

  const client = useApolloClient()
  const mutationUpdateOrderContent = useMutation<
    UpdateOrderContent,
    UpdateOrderContentVariables
  >(UPDATE_ORDER_CONTENT, {
    client,
    variables: {
      input: {
        orderId: props.orderId,
        editModel: {
          editModelLiveStream: {
            ceremonyAgendaBase64: "",
          },
        },
      },
    },
    context: {},
    refetchQueries: [
      {
        query: GET_ORDER_BY_ID_LIVE_STREAM,
        variables: { id: props.orderId },
      },
    ],
  })
  const triggerMutationPdf = (filename: string, base64data: string) => {
    setMutationPdfLoading(true)
    setMutationPdfError(undefined)
    setMutationPdfInfoStatus(undefined)
    mutationUpdateOrderContent({
      variables: {
        input: {
          orderId: props.orderId,
          editModel: {
            editModelLiveStream: {
              ceremonyAgendaFilename: filename,
              ceremonyAgendaBase64: base64data,
            },
          },
        },
      },
    })
      .then((response) => {
        console.log(response)
        setMutationPdfLoading(false)

        if (response.errors) {
          setMutationPdfError(response.errors.map((e) => e.message).join(", "))
          return
        }

        if (!response.data) {
          setMutationPdfError(t("errorMessages:unknowError"))
          return
        }

        if (response.data.updateOrderContent.isError === true) {
          setMutationPdfError(
            response.data.updateOrderContent.errorReason ??
              t("errorMessages:unknowError")
          )
          return
        }

        setMutationPdfError(undefined)
        setMutationPdfInfoStatus(t("editOrder:saved"))
      })
      .catch((err) => {
        setMutationPdfLoading(false)
        setMutationPdfError(err.toString())
      })
  }
  const onClickRemovePdf = () => {}

  const [removeDialogVisible, setRemoveDialogVisible] = React.useState<boolean>(
    false
  )
  const onAbortRemove = () => {
    setRemoveDialogVisible(false)
  }
  const onSuccessRemove = () => {
    setRemoveDialogVisible(false)
    triggerMutationPdf("", "")
  }
  const onClickRemoveImage = () => {
    setRemoveDialogVisible(true)
  }
  return (
    <>
      <div className={"col1"}>
        <strong>Programblad</strong>

        {props.ceremonyAgendaUrl && props.ceremonyAgendaUrl.length > 0 ? (
          <div className={"downloadpdf"}>
            <div className={"close-wrapper"}>
              {removeDialogVisible ? (
                <RemoveItemPopup
                  onAbort={onAbortRemove}
                  onSuccess={onSuccessRemove}
                />
              ) : null}
              <div className={"closeButtonWrapper"}>
                <input type="text" defaultValue={fileName} disabled />
                <Button
                  onClick={onClickRemoveImage}
                  icon={"times"}
                  disabled={props.isLocked === true}
                  isWorking={mutationPdfLoading}
                />
              </div>
            </div>
            <Button onClick={onClickDownloadPdf}>
              {t("editOrder:downloadPdf")}
            </Button>
          </div>
        ) : (
          <div
            {...getRootProps()}
            className={"selectImageBtnCss"}
            onClick={(e) => e.stopPropagation()}
          >
            <div {...getRootProps({ className: "dropzone" })}>
              <input {...getInputProps()} />
              <Button
                color={"primary"}
                variant={"primary"}
                disabled={props.isLocked === true}
                isWorking={mutationPdfLoading}
                dataTestId={"upload-pdf-button"}
              >
                {t("editOrder:uploadPdf")}
              </Button>
            </div>
          </div>
        )}

        <div>
          {formik.touched.ceremonyAgendaBase64 &&
            formik.errors.ceremonyAgendaBase64 && (
              <FieldError
                text={t(`errorMessages:${formik.errors.ceremonyAgendaBase64}`)}
              />
            )}

          {mutationPdfError && mutationPdfError.length > 0 && (
            <FieldError text={mutationPdfError} />
          )}

          {mutationPdfInfoStatus && mutationPdfInfoStatus.length > 0 && (
            <FieldSuccess text={mutationPdfInfoStatus} />
          )}
        </div>
      </div>
    </>
  )
}

const StylesBeforeActivated = styled.div`
  .container {
    display: flex;
    flex-direction: row;
    .lockerBoxContainer {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      padding: 5px;
      .liveStreamBefore {
        width: auto;
        margin-top: 7px;
        margin-right: 30px;
      }
    }
  }

  .whiteframe {
    flex: 2 1;
    margin-left: 0;
    padding-left: ${(props) => props.theme["--spacing"]};
    padding-right: ${(props) => props.theme["--spacing"]};
    margin-right: 0;
    border-radius: 3px;
    background-color: #fff;
    box-shadow: 0 2px 5px 1px rgba(0, 0, 0, 0.07);
    position: relative;
  }

  .col1 {
    margin-top: ${(props) => props.theme["--spacing"]};

    strong {
      font-weight: 500;
    }
    .downloadpdf {
      margin-bottom: ${(props) => props.theme["--spacing"]};
      display: flex;
      column-gap: 10px;
      align-items: flex-end;
    }
    .close-wrapper {
      position: relative;
      display: flex;
      .closeButtonWrapper {
        display: flex;
        position: relative;
        height: 35px;
        direction: ltr;
        input {
          width: 160px;
          background: transparent
            linear-gradient(
              180deg,
              #ffffff 0%,
              var(--unnamed-color-f8f8f8) 100%
            )
            0% 0% no-repeat padding-box;
          border: 1px solid var(--unnamed-color-d8d8d8);
          background: transparent
            linear-gradient(180deg, #ffffff 0%, #f8f8f8 100%) 0% 0% no-repeat
            padding-box;
          border: 1px solid #d8d8d8;
          border-radius: 3px 0 0 3px;
          border-right: none;
        }
        button {
          margin: 0 !important;
          border: none !important;
          width: 20px;
          text-align: center;
          padding: 0;
          background: transparent
            linear-gradient(
              180deg,
              #ffffff 0%,
              var(--unnamed-color-f8f8f8) 100%
            )
            0% 0% no-repeat padding-box !important;
          border: 1px solid var(--unnamed-color-d8d8d8);
          background: transparent
            linear-gradient(180deg, #ffffff 0%, #f8f8f8 100%) 0% 0% no-repeat
            padding-box !important;
          border: 1px solid #d8d8d8 !important;
          border-radius: 0 3px 3px 0;
          border-left: none !important;
          &:hover {
            color: #1778e4 !important;
          }
          svg {
            margin-left: 5px;
          }
        }
      }
    }
  }

  .top-wrapper {
    .content {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      padding-left: 15px;
      padding-right: 15px;
      padding-bottom: 29px;
      .uploadBtnWrapper {
        margin-top: 47px;
        margin-left: 40px;
        button {
          margin-top: 10px;
          background: #ffffff 0% 0% no-repeat padding-box;
          border: 1px solid #70b8ca;
          color: #1289a7;
          &:hover {
            color: #ffffff;
            background-color: #70b8ca;
            border: 1px solid #70b8ca;
          }
        }
      }
    }

    .image-placeholder {
      border: 1px solid #d2d2d2;
      border-radius: 3px;
      height: 180px;
      width: 320px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      .content-wrapper {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
      }

      .selectImageBtnCss {
        background: none;
        border: none;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 100%;
        cursor: pointer;
        position: relative;
      }

      .dropzone {
        width: 100%;
        height: 100%;
      }

      .add-icon-with-text {
        column-gap: 10px;
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
      }

      .selected-image {
        width: 100%;
        height: 100%;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
        }
      }

      .close-wrapper {
        position: absolute;
        top: 0px;
        right: 0px;
        z-index: 2;
      }

      .loading-wrapper {
        position: absolute;
        top: 10px;
        left: 20px;
        z-index: 3;
      }

      .close-icon {
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .text {
        color: #1289a7;
        font-size: 15px;
        line-height: 18px;
      }
    }

    h2 {
      color: #272727;
      font-size: 18px;
      line-height: 22px;
      font-weight: normal;
      margin-top: 31px;
      margin-bottom: 12px;
    }

    .info label {
      color: #272727;
      font-size: 15px;
      line-height: 18px;
      margin-bottom: 8px;
      font-weight: 500;
      display: block;
      margin-top: 5px;
    }

    .bottom-info {
      display: flex;
      flex-direction: row;
      column-gap: 10px;
      margin-top: 15px;

      label {
        color: #272727;
        font-size: 15px;
        line-height: 18px;
        margin-bottom: 8px;
        font-weight: 500;
        display: block;
        margin-top: 5px;
      }
      .icon {
        position: relative;
        top: 5px;
      }
    }
  }

  .bottom-wrapper {
    margin-top: 16px;
    .content {
      padding-top: 16px;
      padding-bottom: 16px;
      padding-right: 16px;
    }
    .error-content {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: flex-end;
    }
    .top-content {
      display: flex;
      justify-content: space-between;
      column-gap: 10px;
      align-items: center;
      .left-content {
        flex: 0.7 1;
        display: flex;
        justify-content: space-between;
        div {
          color: #1289a7;
          font-size: 14px;
          font-weight: 500;
          cursor: pointer;

          span {
            margin-left: 7px;
          }
        }
      }
    }

    .details {
      display: block;
      height: 100px;
      margin-top: 10px;
      padding: 5px;
      animation: showUp 1s easy-out;
      .details-content {
        padding: 10px;
        width: 100%;
        height: 100%;
        background-color: #f8f8f8;
        display: flex;
        justify-content: space-between;
        align-items: center;
        table {
          width: 250px;
          tbody {
            tr {
              text-align: center;
              font-size: 13px;
              td {
                .status {
                  display: flex;
                  justify-content: center;
                }
              }
            }
          }
        }
        .publish_Block {
          width: auto;
          display:flex;
          flex-direction: column;
          
          & > b {
            margin: 0;
            margin-bottom: 10px !important;


          }
        }
      }
    }

    .isHide {
      display: none;
      height: -100px;
    }

    .buttonDate {
      flex: 2 1;
      column-gap: 10px;
      margin-right: ${(props) => props.theme["--spacing"]} / 2;
      margin-left: ${(props) => props.theme["--spacing"]} / 2;
      display: flex;
      flex-wrap: nowrap;
      justify-content: flex-end;
      align-items: center;

      b {
        color: #000000;
        font-size: 15px;
        line-height: 18px;
      }

      .publishTime {
        margin-top: ${(props) => props.theme["--spacing"]} / 2;
        width: 160px;

        input {
          background-color: $white;
        }
      }

      .input-time {
        width: 100px;
      }
      svg {
        path {
          fill: #1289a7;
          opacity: 1;
        }
      }
    }
    .buttonPublish {
      button {
        padding: 11px 16px 12px;
        background: #ffffff 0% 0% no-repeat padding-box;
        border: 1px solid #70b8ca;
        color: #1289a7;
        :hover {
          color: #ffffff !important;
          background-color: #70b8ca !important;
          border: 1px solid #70b8ca !important;
        }
        &:disabled {
          background-color: #ececec;
          border: 1px solid #e7e7e7;
          color: #a8a8a8;
          &:hover {
            background-color: #ececec !important;
            border: 1px solid #e7e7e7 !important;
            color: #a8a8a8 !important;
          }
        }
      }
    }
  }
`

export const ImageSelectDialog: React.FC<{
  isDialogOpen: boolean
  onRequestClose: () => void
  onSuccessCrop?: (blob: any) => void
  file: ImageSelectDialogFile | null
}> = (props) => {
  return (
    <Modal
      large={false}
      isLoading={false}
      onRequestClose={props.onRequestClose}
      isOpen={props.isDialogOpen}
    >
      {props.file !== null ? (
        <div className={styles.imageCropModal}>
          <ImageEditor
            imageUrl={props.file.preview}
            onClose={props.onRequestClose}
            onSave={(blob: any) => {
              if (props.onSuccessCrop) {
                props.onSuccessCrop(blob)
              }
            }}
          />
        </div>
      ) : null}
    </Modal>
  )
}
