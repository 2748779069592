import * as React from "react"
import * as styles from "./styles.scss"
import * as cx from "classnames"
import { useTranslation } from "react-i18next"

const Status: React.FC<StatusProps> = (props) => {
  const {
    variant,
    children,
    showStatusLabel,
    className,
    circleFirst,
    statusValue,
  } = props
  
  return (
    <div className={cx(styles.container, className)}>
      {circleFirst && circleFirst === true ? (
        <div className={styles.container}>
          <StatusCircle variant={variant} withBorder={false} />
          <StatusLabel showStatusLabel={showStatusLabel} />
          <StatusValue statusValue={statusValue}  variant={variant}>{children}</StatusValue>
        </div>
      ) : (
        <div className={styles.container}>
          <StatusLabel showStatusLabel={showStatusLabel} />
          <StatusCircle variant={variant} withBorder={false} />
          <StatusValue statusValue={statusValue} variant={variant}>{children}</StatusValue>
        </div>
      )}
    </div>
  )
}

const StatusValue: React.FC<{
  statusValue?: string
  variant?: StatusVariants
}> = (props) => {
  const [t] = useTranslation("statuses")
  
  return (
    <span className={cx(styles.text,{
      [styles.greenText]: props.variant === "GREEN",
      [styles.blueText]: props.variant === "BLUE",
      [styles.orangeText]: props.variant === "YELLOW",
      [styles.redText]: props.variant === "RED",
      [styles.grayText]: props.variant === "GRAY",
    })}>
      {props.children
        ? props.children
        : props.statusValue
        ? t(`statuses:${props.statusValue}`)
        : null}
    </span>
  )
}

const StatusLabel: React.FC<{ showStatusLabel?: boolean }> = (props) => {
  const [t] = useTranslation("common")
  if (props.showStatusLabel && props.showStatusLabel === true) {
    return <span className={styles.statusLabel}>{t("statusLabel")}</span>
  }
  return <></>
}

export const StatusCircle: React.FC<{
  variant: StatusVariants
  withBorder?: boolean
}> = (props) => {
  return (
    <span
      className={cx(styles.circle, {
        [styles.green]: props.variant === "GREEN",
        [styles.blue]: props.variant === "BLUE",
        [styles.orange]: props.variant === "YELLOW",
        [styles.red]: props.variant === "RED",
        [styles.gray]: props.variant === "GRAY",
        [styles.withBorder]: props.withBorder,
      })}
    />
  )
}

interface StatusProps {
  variant: StatusVariants
  showStatusLabel?: boolean
  className?: string
  circleFirst?: boolean
  statusValue?: string
}

export default Status
