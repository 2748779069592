import * as React from "react"
import ConversationButton from "../ConversationButton"
import ConversationInput from "../ConversationInputs"
import { useTranslation } from "react-i18next"
import styled from 'styled-components'


const MessageButton: React.FC<{
  msgToMedia?: string
  isEdit: boolean
}> = (props) => {
  const [t] = useTranslation("review")
  const [value, setValue] = React.useState("")
  const [isOpen, setIsOpen] = React.useState(false)


  
  const toggleOpen = () => setIsOpen(!isOpen)


  return (
    <MessageContainer>
      {isOpen ? (
        <ConversationInput  
              placeholder={t(props.isEdit ? "editMessage" : "message")}
              value={value}
              setValue={setValue}
              name="messageToMedia"
              toggleOpen={toggleOpen}
        />
      ) : value.length > 0 ? (
         <span><ConversationButton icon="edit" label={ value ? value : props.msgToMedia} onClick={toggleOpen} /></span> 
      ) : (
        <ConversationButton
          icon="plus"
          label={t(
            t(props.isEdit ? "editMessage" : "message")
          )}
          onClick={toggleOpen}
        />
      )}
    </MessageContainer>
  )
}


export default MessageButton

const MessageContainer = styled.div`
display: flex;
align-items: center;
span {
  div {
    display: flex;
    span {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 85px !important;
    }
  }

}

  
`
