import gql from "graphql-tag"

const SETTINGS_FRAGMENT = gql`
  fragment SettingsFragment on CustomerSettings {
    emailOffice
    contactPhone
    agencyHomepageName
    contactPhone
    emailCustomer
    emailOrderReceipt
    emailOrderMemoriz
    emailOffice
    emailOrderMemoriz
    postAddressCity
    postAddressPostcode
    postAddressStreet
    orderPriceEndCustomerRule
    isDefaultProofSuggestionThanksAdvert
    isDefaultProofCaseFeeAddOnOrders
    isDefaultProofCaseFeeHide
    isDefaultProofCaseFeeAddOnFirstOrder
    isDefaultParallelPublishInFamiljesidan
    isDefaultParallelPublishInMinnesrummet
    isDefaultParallelPublishInAgencyHomepage
    isDefaultMyPageEnabled
    isMemoryRoomUrlEnabled
    favoriteMedias
    isAllowUpdateAddOn
  }
`

export default SETTINGS_FRAGMENT
