import React from "react"
import ReactTable from "react-table-6"
import styles from "../styles.scss"
import { UserLogs_userLogs } from "schema"

const ExpandedTable: React.FC<{
  data: UserLogs_userLogs
}> = (props) => {
  return (
    <ReactTable
      className={styles.expandedTable}
      data={[props.data]}
      columns={[
      {
        id: "firstRow",
        Header: "",
        width: 15
      },
      {
        id: "customer",
        Header: "Kund",
        accessor: (v) => v.customerName,
        width: 150
      },
      {
        id: "json",
        Header: "JSON",
        accessor: (v) => v.detailJson  
      },
    ]}
      
      showPagination={false}
      showPaginationBottom={false}
      pageSize={[props.data].length!}
    />
  )
}

export default ExpandedTable
