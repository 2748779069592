import * as React from "react"
import {
  OrderInitiatorSelectTypes,
  SelectOrderInitiatorView_selectOrderInitiatorView_options,
} from "schema"
import { ErrorText } from "components/ErrorMessage"
import { useTranslation } from "react-i18next"
import cx from "classnames"

export const LayoutImageTextThumbnail: React.FC<{
  caseFolderId: string
  orderId?: string
  options: SelectOrderInitiatorView_selectOrderInitiatorView_options[]
  onCloseModal: any
  onSelectItem: (selector: string) => void
  isFEViewEnabled: boolean
  selectType?: OrderInitiatorSelectTypes
}> = (props) => {
  const [t] = useTranslation(["common"])
  const { DOCUMENTFORMAT } = OrderInitiatorSelectTypes
  const [loading, setLoading] = React.useState(false)

  React.useEffect(() => {
    return () => {
      setLoading(false)
    }
  }, [props.selectType])

  return (
    <div
      className={cx("layout-IMAGE_TEXT_THUMBNAIL", {
        ["layout-IMAGE_TEXT_THUMBNAIL__AdstateView"]: props.isFEViewEnabled,
      })}
    >
      {props.options.length === 0 ? (
        <div className={"error-wrapper"}>
          <ErrorText>{t("common:noResults")}</ErrorText>
        </div>
      ) : null}

      <div className={"items"}>
        {props.options.map((option, index) => {
          if (!option) {
            return
          }
          const onSelectItem = (event: any) => {
            props.onSelectItem(option.selector)
          }

          return (
            <div
            className={cx("item", {
              isItemSelected: option.isSelected,
            })}
              key={`${index}_${option.selector}`}
              onClick={onSelectItem}
            >
              {!loading &&
                props.selectType === DOCUMENTFORMAT &&
                props.isFEViewEnabled && <div className="loadingFiller" />}

              {option.imageUrl ? (
                <div
                  className={cx("imageContainer", {
                    disableLoading:
                      !loading &&
                      props.selectType === DOCUMENTFORMAT &&
                      props.isFEViewEnabled,
                  })}
                >
                  <img
                    className={"image"}
                    src={option.imageUrl}
                    alt={option.name ?? ""}
                    onLoad={() => {
                      setLoading(true)
                    }}
                    onError={() => {
                      console.log("one or some images not loaded yet")
                    }}
                  />
                </div>
              ) : null}

              <div className={"name"}>{option.name ?? ""}</div>
            </div>
          )
        })}
      </div>
    </div>
  )
}
